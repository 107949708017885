import { Col, Label, Row } from 'reactstrap';
import { useEffect, useState } from 'react';
import { IoClose } from 'react-icons/io5';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { appActions, systemActions } from '../../../redux/actions';
import AgentDataTable from '../../AgentDataTable';

import './styles.scss';

const mapStateToProps = state => ({
  platform: state.appReducers.platform,
  changeList: state.systemReducers.changeList,
});

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  GetChangeList: (obj, callback) => dispatch(systemActions.GetChangeList(obj, callback)),
});

const AdditionSubtractionDetailsModal = ({ GetChangeList, changeList, updateModalAlert, data }) => {
  const { t } = useTranslation();
  const [_detailItem, setDetailItem] = useState([]);

  useEffect(() => {
    if (changeList) {
      setDetailItem(changeList.list || []);
    }
  }, [changeList]);

  useEffect(() => {
    GetChangeList({
      userId: data.userId,
      accType: data.accType,
      mtype: data.mtype,
      changeDate: data.changeDate
    });
  }, [data]);

  const tableHeader = [
    {
      label: t('OPERATE_TIME'),
    },
    {
      label: t('OPERATE_AMOUNT'),
    },
    {
      label: t('OPERATE_REMARK'),
    },
  ];

  const _content = [
    data => (<Label className="d-flex justify-content-center">{`${data?.createDate}`}</Label>),
    data => (<Label className="d-flex justify-content-center">{`${data?.change}`}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.note}</Label>),
  ];

  return (
    <div className=" additionSubtractionDetailsModalWrapperWeb">
      <Row className="modalHeader">
        <Col
          className={`${'d-flex justify-content-center align-items-center'}`}
          xs={{
            size: 4, offset: 4
          }}
        >
          {`${data.user}#`}
          {t('ADDITION_OR_SUBTRACTION')}
          {t('DETAILS')}
        </Col>
        <Col xs="4" className="d-flex justify-content-end align-items-center">
          <IoClose
            onClick={() => {
              updateModalAlert({
                visible: false
              });
              if (data && data.overBet && data.onConfirmationClick) data.onConfirmationClick();
            }}
            className="h4 mb-0 float-right closeIcon"
          />
        </Col>
      </Row>
      <Row className="modalContent d-flex p-2 justify-content-center">
        <AgentDataTable
          tableHeaderTitle={tableHeader}
          tableData={_detailItem}
          tableBodyRowProperty={_content}
        />
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AdditionSubtractionDetailsModal);