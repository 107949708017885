import { useEffect, useState } from 'react';

import { Input, Select } from 'antd';
import { Label } from 'reactstrap';
import { MdArrowBack } from 'react-icons/md';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { AgentDataTable, CommonHeader } from '../..';
import OpenResultSetting from '../../SystemSetting/OpenResultSetting';
import { systemActions } from '../../../redux/actions';

import './styles.scss';

const mapStateToProps = state => ({
  lotteryTypeInfo: state.systemReducers.lotteryTypeInfo,
  editLotteryTypeInfo: state.systemReducers.editLotteryTypeInfo,
});

const mapDispatchToProps = dispatch => ({
  GetLotteryTypeInfo: callback => dispatch(systemActions.GetLotteryTypeInfo(callback)),
  GetEditLotteryTypeInfo: (code, callback) => dispatch(systemActions.GetEditLotteryTypeInfo(code, callback)),
  SaveLotteryTypeInfo: (obj, callback) => dispatch(systemActions.SaveLotteryTypeInfo(obj, callback)),
});

const { Option } = Select;

const LotterySetting = ({ lotteryTypeInfo, GetLotteryTypeInfo, editLotteryTypeInfo, GetEditLotteryTypeInfo, SaveLotteryTypeInfo }) => {
  const { t } = useTranslation();
  const [_isQuerying, setIsQuerying] = useState(false);
  const [_pageFlag, setPageFlag] = useState(0);

  const [openInterval, setopenInterval] = useState(0);
  const [status, setStatus] = useState(1);
  const [closeInterval, setcloseInterval] = useState(0);

  useEffect(() => {
    setIsQuerying(true);
    GetLotteryTypeInfo(() => setIsQuerying(false));
  }, []);

  const saveAndEdit = () => {
    if (_pageFlag === 2) {
      const obj = {
        code: editLotteryTypeInfo.code,
        closeInterval,
        openInterval,
        status
      };
      const callback = () => {
        setPageFlag(0);
        setIsQuerying(true);
        GetLotteryTypeInfo(() => setIsQuerying(false));
      };
      SaveLotteryTypeInfo(obj, callback);
    }
  };

  useEffect(() => {
    if (_pageFlag === 0) {
      setopenInterval('');
      setStatus(1);
      setcloseInterval('');
    }
    if (_pageFlag === 2 && editLotteryTypeInfo) {
      setopenInterval(editLotteryTypeInfo?.openInterval ?? 0);
      setStatus(editLotteryTypeInfo?.status ?? 1);
      setcloseInterval(editLotteryTypeInfo?.closeInterval ?? 0);
    }
  }, [_pageFlag, editLotteryTypeInfo]);

  return (
    <div className="CompanyLotterySettingWrapper">
      <CommonHeader
        headerName={(
          <div className="d-flex align-items-center">
            {_pageFlag !== 0 && (
            <div className="d-flex align-items-center me-2 backIcon" onClick={() => setPageFlag(0)}>
              <MdArrowBack className="h5 mb-0 text-white" />
              返回
            </div>
            )}
            {`${t('LOTTERY_SETTING')} ${_pageFlag === 0 ? '' : _pageFlag === 2 ? '/ 编辑彩种' : '/ 官网默认设置'}`}
          </div>
        )}
      />
      {
        _pageFlag === 0
        && (
        <div className="addNewGameBox my-1">
          <div className="operationBtn add  d-flex align-items-center" onClick={() => setPageFlag(1)}>
            官网默认设置
          </div>
        </div>
        )
      }
      {_pageFlag === 0 && (
        <div className="mx-0 bg-white mb-3 px-0 border-1">
          <AgentDataTable
            isQuerying={_isQuerying}
            tableHeaderTitle={
            [{
              label: '彩种Code',
            }, {
              label: '名称',
            }, {
              label: '备注名称',
            }, {
              label: '开盘时间',
            }, {
              label: '开奖时间差(秒)',
            }, {
              label: '开盘时间差(秒)',
            }, {
              label: '封盘时间差(秒)',
            }, {
              label: '排序',
            }, {
              label: '状态',
            }, {
              label: t('OPERATION'),
            }]
          }
            tableHeaderStyle={
            []
          }
            tableData={lotteryTypeInfo || []}
            tableBodyRowProperty={
            [
              data => (<Label className="d-flex justify-content-center">{data.code}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.name}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.noteName}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.beginTime}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.resultInterval}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.openInterval}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.closeInterval}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.sort}</Label>),
              data => (<Label className={`d-flex justify-content-center ${data.status !== 1 && 'text-danger'}`}>{data.status === 1 ? '启用' : '暂停'}</Label>),
              data => (
                <Label className="d-flex justify-content-center align-items-center">
                  <div className="operationBtn mx-2" onClick={() => { GetEditLotteryTypeInfo(data.code, () => setPageFlag(2)); }}>编辑</div>
                </Label>
              )
            ]
          }
          />
        </div>
      )}
      {
        _pageFlag === 2 && (
          <>
            <div className="my-3 thirdPartyGamesTable">
              <div className="thirdPartyGamesTitle">公司资料</div>
              <div className="thirdPartyGamesRow">
                <div>彩种名称</div>
                <div><Input disabled value={editLotteryTypeInfo?.name} placeholder="请输入内容" /></div>
              </div>
              <div className="thirdPartyGamesRow">
                <div>彩种别名</div>
                <div><Input disabled value={editLotteryTypeInfo?.noteName} placeholder="请输入内容" /></div>
              </div>
              <div className="thirdPartyGamesRow">
                <div>开盘时间</div>
                <div><Input disabled value={editLotteryTypeInfo?.beginTime} placeholder="请输入内容" /></div>
              </div>
              <div className="thirdPartyGamesRow">
                <div>开奖时间差</div>
                <div><Input disabled value={editLotteryTypeInfo?.resultInterval} placeholder="请输入内容" /></div>
              </div>
              <div className="thirdPartyGamesRow">
                <div>开盘时间差</div>
                <div><Input value={openInterval} onChange={({ target: { value: text } }) => setopenInterval(text > -1 ? Number(text.trim()) : openInterval)} placeholder="请输入内容" /></div>
              </div>
              <div className="thirdPartyGamesRow">
                <div>封盘时间差</div>
                <div><Input value={closeInterval} onChange={({ target: { value: text } }) => setcloseInterval(text > -1 ? Number(text.trim()) : closeInterval)} placeholder="请输入内容" /></div>
              </div>
              <div className="thirdPartyGamesRow">
                <div>彩种状态</div>
                <div>
                  <Select value={status} placeholder="请选择" onChange={code => setStatus(code)}>
                    {
                  [{
                    value: 1, label: '启用'
                  }, {
                    value: 0, label: '暂停'
                  }].map(el => <Option key={el.value} value={el.value}>{el.label}</Option>)
                }
                  </Select>
                </div>
              </div>
            </div>
            <div className="thirdPartyGamesFooter">
              <div className="operationBtn  me-3" onClick={() => saveAndEdit()}>保存</div>
              <div className="operationBtn cancel me-3" onClick={() => setPageFlag(0)}>取消</div>
            </div>
            <div className="editNotes">
              <div>说明:</div>
              <div>1.开盘时间：用于计算每天第一期开奖的时间</div>
              <div>2.开盘时间差：用于计算开盘时间（开盘时间=当期开奖时间-开奖时间差+开盘时间差），单位：秒</div>
              <div>3.封盘时间差：用于计算开盘时间（封盘时间=当期开奖时间-封盘时间差），单位：秒</div>
            </div>
          </>
        )
      }
      {
        _pageFlag === 1 && <OpenResultSetting isAdmin />
      }
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LotterySetting);