import { useEffect, useState } from 'react';

import { Button, Col, Label, Row } from 'reactstrap';
import { IoClose } from 'react-icons/io5';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';


import { appActions, inTimeSheetActions } from '../../../redux/actions';
import AgentDataTable from '../../AgentDataTable';
import Pagination from '../../Content/Pagination';

import './styles.scss';

const mapStateToProps = state => ({
  inTimeSheetInfo: state.inTimeSheetReducers.inTimeSheetInfo,
  activeLottery: state.commonReducers.activeLottery,
});

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  GetAgentBet: (obj, callback) => dispatch(inTimeSheetActions.GetAgentBet(obj, callback)),
});

const BetListModal = ({ updateModalAlert, data, GetAgentBet, inTimeSheetInfo, activeLottery }) => {
  const { t } = useTranslation();
  const [_isQueryingDetail, setIsQueryingDetail] = useState(true);

  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(15);

  const [agentBet, setAgentBet] = useState();

  useEffect(() => {
    if (activeLottery && inTimeSheetInfo?.currentInstallment) {
      setIsQueryingDetail(true);
      const _obj = {
        lotteryType: activeLottery.code,
        keyCode: data.data.keyCode,
        installNo: inTimeSheetInfo?.currentInstallment?.currentInstallmentNo,
        displayName: data.isLianMa ? data.data.displayName : undefined,
        zcMode: 1,
        abType: -1,
        page,
        rows,
      };
      GetAgentBet(_obj, val => { setIsQueryingDetail(false); setAgentBet(val); });
    }
  }, []);

  const onPaginationClick = (pageNum, rows) => {
    setIsQueryingDetail(true);
    setPage(pageNum);
    setRows(rows);
    const _obj = {
      lotteryType: activeLottery.code,
      keyCode: data.data.keyCode,
      installNo: inTimeSheetInfo?.currentInstallment?.currentInstallmentNo,
      displayName: data.isLianMa ? data.data.displayName : undefined,
      zcMode: 1,
      abType: -1,
      page: pageNum,
      rows,
    };
    GetAgentBet(_obj, val => { setIsQueryingDetail(false); setAgentBet(val); });
  };

  const tableHeader = [{
    label: '单号 / 时间',
  }, {
    label: '会员 / 退水',
  }, {
    label: '盘类 / 期数',
  }, {
    label: '下注内容@赔率',
  }, {
    label: '下注金额',
  }, {
    label: '占成金额',
  }, {
    label: '退水',
  }, {
    label: '结果',
  }, {
    label: '备注'
  }
  ];

  const tableContent = [
    data => (
      <Label className="d-flex justify-content-center">
        <div>
          <div>{data.oddNo}</div>
          <div>{data.created}</div>
        </div>
      </Label>
    ),
    data => (
      <Label className="d-flex justify-content-center">
        <div>
          <div>{data.orgName}</div>
          <div>{`${data.returnValue}%`}</div>
        </div>
      </Label>
    ),
    data => (
      <Label className="d-flex justify-content-center">
        <div>
          <div>{data.oddsType}</div>
          <div>{data.installments}</div>
        </div>
      </Label>
    ),
    data => (<Label className="d-flex justify-content-center">{`${data?.placeBetContent}`}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.betAmount}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.zcAmount}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.memReturn}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.totalResult}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.note}</Label>),
  ];

  return (
    <div className="BetListModalWrapperWeb">
      <Row className="modalHeader">
        <Col />
        <Col
          className="d-flex justify-content-center align-items-center"
        >
          投注列表
        </Col>
        <Col className="d-flex justify-content-end align-items-center">
          <IoClose
            onClick={() => {
              updateModalAlert({
                visible: false
              });
              if (data && data.overBet && data.onConfirmationClick) data.onConfirmationClick();
            }}
            className="h4 mb-0 float-right closeIcon"
          />
        </Col>
      </Row>
      <Row className="modalContent d-flex justify-content-center">
        <div className="p-2 tableViewArea">
          <AgentDataTable
            isQuerying={_isQueryingDetail}
            tableHeaderTitle={tableHeader}
            tableData={agentBet ? agentBet?.betList : Array(6).fill({})}
            tableBodyRowProperty={tableContent}
          />
        </div>
        <Row className="justify-center w-full relative">
          <Pagination totalRecord={agentBet ? (agentBet.totalRecords || 0) : 0} onClick={onPaginationClick} isQuery={false} />
        </Row>
      </Row>
      <Row className="modalFooter d-flex align-items-center">
        <Button
          className={`confirmButton my-2 ${'me-3'}`}
          onClick={() => {
            updateModalAlert({
              visible: false
            });
            if (data && data.onConfirmationClick) data.onConfirmationClick();
          }}
        >
          {(data && data.confirmText) || t('CONFIRM')}
        </Button>
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BetListModal);