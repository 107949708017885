import { useEffect, useState } from 'react';

import { Input, Select } from 'antd';
import { Label } from 'reactstrap';
import { MdArrowBack } from 'react-icons/md';
import { PlusCircleOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { AgentDataTable, CommonHeader } from '../../components';
import { inTimeSheetActions } from '../../redux/actions';

import './styles.scss';

const mapStateToProps = state => ({
  activeLottery: state.commonReducers.activeLottery,
});

const mapDispatchToProps = dispatch => ({
  GetBetNumBlockList: (obj, callback) => dispatch(inTimeSheetActions.GetBetNumBlockList(obj, callback)),
  GetBlockedBetNumById: (obj, callback) => dispatch(inTimeSheetActions.GetBlockedBetNumById(obj, callback)),
  SaveBlockBetNumbers: (obj, callback) => dispatch(inTimeSheetActions.SaveBlockBetNumbers(obj, callback)),
});

const { Option } = Select;
const { TextArea } = Input;

const dropDown = [
  {
    PLAYMethod: '二星', SettingCode: 'XP2', MidCode: 'XP2'
  }, {
    PLAYMethod: '三星', SettingCode: 'XP3', MidCode: 'XP3'
  }, {
    PLAYMethod: '四星', SettingCode: 'XP4', MidCode: 'XP4'
  }, {
    PLAYMethod: '天碰二', SettingCode: 'TP', MidCode: 'TP2'
  }, {
    PLAYMethod: '天碰三', SettingCode: 'TP', MidCode: 'TP3'
  }, {
    PLAYMethod: '台号', SettingCode: 'TH', MidCode: 'TH'
  }, {
    PLAYMethod: '特尾三', SettingCode: 'TWS', MidCode: 'TWS'
  },
];

const BlockBetNumbers = ({ GetBetNumBlockList, GetBlockedBetNumById, SaveBlockBetNumbers }) => {
  const { t } = useTranslation();
  const [_isQuerying, setIsQuerying] = useState(false);
  const [_pageFlag, setPageFlag] = useState(0);
  const [list, setList] = useState();
  const [playType, setPlayType] = useState();
  const [Installment, setInstallment] = useState();
  const [LotteryType, setLotteryType] = useState('HKLHC');
  const [Id, setId] = useState();
  const [LockNumbers, setLockNumbers] = useState();

  useEffect(() => {
    if (_pageFlag === 0) {
      setIsQuerying(true);
      GetBetNumBlockList({
        LotteryType
      }, data => { setList(data?.list || []); setIsQuerying(false); });
    }
  }, [_pageFlag]);

  useEffect(() => {
    if (_pageFlag !== 2) {
      setPlayType({
        ...dropDown[0]
      });
      setInstallment();
      setLockNumbers();
      setId();
    }
  }, [_pageFlag]);

  const onClickSaveBlockBetNumbers = () => {
    if (playType && Installment && LockNumbers) {
      const lockBetNumbers = {
        LockNumbers,
        Id,
        LotteryType,
        Installment,
        ...playType
      };
      const callback = () => {
        setPageFlag(0);
      };
      SaveBlockBetNumbers({
        lockBetNumbers
      }, callback);
    }
  };

  const onClickGetBlockedBetNumById = id => {
    const callback = data => {
      if (data) {
        const temp = dropDown.find(x => x.MidCode === data.midCode);
        setPlayType({
          ...temp
        });
        setInstallment(data.installment);
        setLockNumbers(data.lockNumbers);
        setId(data.id);
        setPageFlag(2);
      }
    };
    GetBlockedBetNumById({
      id
    }, data => callback(data));
  };

  return (
    <div className="BlockBetNumbersWrapper">
      <CommonHeader
        headerName={(
          <div className="d-flex align-items-center">
            {_pageFlag !== 0 && (
            <div className="d-flex align-items-center me-2 backIcon" onClick={() => setPageFlag(0)}>
              <MdArrowBack className="h5 mb-0 text-white" />
              返回
            </div>
            )}
            {`${t('BLOCK_NUMBER_SETTING')} ${_pageFlag === 0 ? '' : _pageFlag === 1 ? '/ 新增封号' : '/ 编辑'}`}
          </div>
)}
      />
      {_pageFlag === 0 && (
      <>
        <div className="addNewGameBox my-3">
          <div className="operationBtn add  d-flex align-items-center" onClick={() => setPageFlag(1)}>
            <PlusCircleOutlined />
            <span className="ms-2">新增封号</span>
          </div>
        </div>

        <div className="mx-0 bg-white mb-3 px-0 border-1">
          <AgentDataTable
            isQuerying={_isQuerying}
            tableHeaderTitle={
            [{
              label: t('INSTALLMENTS'),
            }, {
              label: t('CATEGORY'),
            }, {
              label: t('BLOCK_NUMBER'),
            }, {
              label: t('RENEW_TIME'),
            }, {
              label: t('OPERATION'),
            }]
          }
            tableHeaderStyle={
            []
          }
            tableData={list || []}
            tableBodyRowProperty={
            [
              data => (<Label className="d-flex justify-content-center">{data.installment}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.playMethod}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.lockNumbers}</Label>),
              data => (<Label className="d-flex justify-content-center">{moment.unix(data.updateDate).format('YYYY-MM-DD HH:mm:ss')}</Label>),
              data => <Label className="d-flex justify-content-center operationBtn w-50 m-auto" onClick={() => { onClickGetBlockedBetNumById(data.id); }}>编辑</Label>
            ]
          }
          />
        </div>
      </>
      )}
      {_pageFlag !== 0 && (
        <>
          <div className="my-3 thirdPartyGamesTable">
            <div className="thirdPartyGamesTitle">{_pageFlag === 1 ? '新增封号' : '修改封号'}</div>
            <div className="thirdPartyGamesRow">
              <div>彩种: </div>
              <div><Input disabled={_pageFlag === 2} value={LotteryType} onChange={({ target: { value: text } }) => setLotteryType(text.trim())} placeholder="请输入内容" /></div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>期数: </div>
              <div><Input disabled={_pageFlag === 2} value={Installment} onChange={({ target: { value: text } }) => setInstallment(text.trim())} placeholder="请输入内容" /></div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>玩法: </div>
              <div>
                <Select
                  disabled={_pageFlag === 2}
                  value={playType?.MidCode || ''}
                  placeholder="请选择"
                  onChange={code => {
                    const temp = dropDown.find(x => x.MidCode === code);
                    setPlayType({
                      ...temp
                    });
                  }}
                >
                  {
                    dropDown.map(el => <Option key={el.MidCode} value={el.MidCode}>{el.PLAYMethod}</Option>)
                  }
                </Select>
              </div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>封号: </div>
              <div><TextArea rows={6} cols="60" value={LockNumbers} onChange={({ target: { value: text } }) => setLockNumbers(text)} placeholder="号码用 ',' 分隔; 分组（每碰）用 '|' 或者（回车Enter 换行） 分隔" /></div>
            </div>
          </div>
          <div className="thirdPartyGamesFooter">
            <div className="operationBtn editAndSave me-3" onClick={() => onClickSaveBlockBetNumbers()}>确定</div>
            <div className="operationBtn cancel" onClick={() => setPageFlag(0)}>取消</div>
          </div>
        </>
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BlockBetNumbers);