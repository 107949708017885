import gameItemIconMap from '../../GameItemIcon';
import { renderResultDateCell } from '../../utils';

const renderDice = data => {
  if (!data.openResult) return;
  const result = data.openResult.split(',');
  return result.map(item => (
    {
      image: gameItemIconMap[`CJ_DICE_${item}`]
    }
  ));
};

const generateLabel = item => {
  switch (item) {
    case '1':
      return '鱼';
    case '2':
      return '虾';
    case '3':
      return '葫芦';
    case '4':
      return '金钱';
    case '5':
      return '螃蟹';
    case '6':
      return '鸡';
    default:
      return '';
  }
};

const renderYXX = data => {
  if (!data.openResult) return;
  const result = data.openResult.split(',');
  const tempResult = [];
  result.forEach(item => {
    tempResult.push({
      value: generateLabel(item),
      className: item === '1' || item === '6' ? 'redText' : item === '2' || item === '5' ? 'greenText' : 'blueText'
    });
  });
  return tempResult;
};
const renderZH = data => {
  if (!data.openResult) return;
  let championSum = 0;
  const tempResult = [];
  const result = data.openResult.split(',');
  result.forEach(item => {
    championSum += parseInt(item, 10);
  });
  tempResult.push({
    value: championSum
  });
  tempResult.push({
    value: (result[0] === result[1] && result[1] === result[2]) ? '通吃' : championSum > 10 ? '大' : '小',
    className: `${championSum > 10 && 'redText'}`
  });
  return tempResult;
};

const K3 = {
  resultTab: {
    headerCell: [{
      attr: '期数',
      wrapperStyle: 'sscHeader'
    }, {
      attr: '日期',
      wrapperStyle: 'sscHeader'
    }, {
      attr: '彩球号码',
      wrapperStyle: 'sscHeader'
    }, {
      attr: '鱼虾蟹',
      wrapperStyle: 'sscHeader'
    }, {
      attr: '总和',
    }],
    bodyCell: [{
      attr: 'installments',
      wrapperStyle: 'd-flex justify-content-center align-items-center'
    }, {
      attr: renderResultDateCell,
      wrapperStyle: 'dateCol'
    }, {
      attr: renderDice,
      wrapperStyle: 'd-flex sscBallAndZh'
    }, {
      attr: renderYXX,
      wrapperStyle: 'd-flex sscBallAndZh'
    }, {
      attr: renderZH,
      wrapperStyle: 'd-flex sscBallAndZh'
    }],
  }
};

export default K3;