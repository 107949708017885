

import { Button, Col, Row } from 'reactstrap';
import { IoClose } from 'react-icons/io5';
import { connect } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { appActions, transactionActions } from '../../../redux/actions';

import './styles.scss';

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  SaveRefund: obj => dispatch(transactionActions.SaveRefund(obj)),
});

const MessageDetailModal = ({ updateModalAlert, data }) => {
  const { t } = useTranslation();

  return (
    <div className="MessageDetailModalModalWrapperWeb">
      <Row className="modalHeader">
        <Col
          className="d-flex align-items-center"
        >
          {t('REMINDER')}
        </Col>
        <Col className="d-flex justify-content-end align-items-center">
          <IoClose
            onClick={() => {
              updateModalAlert({
                visible: false
              });
              if (data && data.overBet && data.onConfirmationClick) data.onConfirmationClick();
            }}
            className="h4 mb-0 float-right closeIcon"
          />
        </Col>
      </Row>
      <div className="modalContent d-flex justify-content-center mx-0 p-2 ">
        <div className="orderInformationTable">
          <div className="orderInformationRowBackGroundColor d-flex justify-content-center align-items-center">交易详情</div>
          <div className="evenRowStyle">
            <div>消息类型</div>
            <div>{data && data.msgTypeName}</div>
          </div>
          <div className="evenRowStyle">
            <div>操作时间</div>
            <div>{data && moment.unix(data.createtime).format('YYYY-MM-DD HH:mm:ss')}</div>
          </div>
          <div className="evenRowStyle">
            <div>接收用户</div>
            <div>{data && data.toUserName}</div>
            <div>接收用户类型</div>
            <div>{data && (data.toUserType === 1 ? '会员' : '代理')}</div>
          </div>
          <div className="evenRowStyle">
            <div>发送用户</div>
            <div>{data && data.fromUserName}</div>
            <div>发送用户类型</div>
            <div>{data && (data.fromUserType === 1 ? '代理' : '系统')}</div>
          </div>
          <div className="evenRowStyle">
            <div>消息主题</div>
            <div>{data && data.msgTitle}</div>
          </div>
          <div className="evenRowStyle">
            <div>消息内容</div>
            <div>{data && data.msgContent}</div>
          </div>
        </div>
      </div>
      <Row className="modalFooter d-flex align-items-center ">
        <Button
          className="confirmButton my-3"
          onClick={() => {
            updateModalAlert({
              visible: false
            });
          }}
        >
          {t('CONFIRM')}
        </Button>
      </Row>
    </div>
  );
};

export default connect(null, mapDispatchToProps)(MessageDetailModal);