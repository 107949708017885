
import { renderResultDateCell } from '../../utils';


const getBallClass = value => {
  const REDBALL = ['01', '02', '07', '08', '12', '13', '18', '19', '23', '24', '29', '30', '34', '35', '40', '45', '46'];
  const BLUEBALL = ['03', '04', '09', '10', '14', '15', '20', '25', '26', '31', '36', '37', '41', '42', '47', '48'];
  const GREENBALL = ['05', '06', '11', '16', '17', '21', '22', '27', '28', '32', '33', '38', '39', '43', '44', '49'];
  if (REDBALL.includes(value)) {
    return 'circle circleRed';
  }
  if (BLUEBALL.includes(value)) {
    return 'circle circleBlue';
  }
  if (GREENBALL.includes(value)) {
    return 'circle circleGreen';
  }
};

const renderBall = data => {
  if (!data.openResult) return;
  const result = data.openResult.split(',');
  const tempResult = [[{
    value: result[0],
    className: getBallClass(result[0])
  }], [{
    value: result[1],
    className: getBallClass(result[1])
  }], [{
    value: result[2],
    className: getBallClass(result[2])
  }], [{
    value: result[3],
    className: getBallClass(result[3])
  }], [{
    value: result[4],
    className: getBallClass(result[4])
  }], [{
    value: result[5],
    className: getBallClass(result[5])
  }], [{
    value: result[6],
    className: getBallClass(result[6])
  }], [{
    value: result[7],
    className: getBallClass(result[7])
  }]];
  return tempResult;
};

const TH_DX = data => {
  if (!data.openResult) return;
  const result = data.openResult.split(',');
  const tempResult = [];
  const lastBall = parseInt(result[7], 10);
  const lastBallProcessed = parseInt(lastBall / 10, 10) + (lastBall % 10);
  const val = lastBall === 31 ? '和' : lastBallProcessed > 5 ? '大' : '小';
  tempResult.push({
    value: val,
    className: `d-flex h-100 justify-content-center align-items-center ${val === '大' && 'redText'}`
  });
  return tempResult;
};
const TH_DS = data => {
  if (!data.openResult) return;
  const result = data.openResult.split(',');
  const tempResult = [];
  const lastBall = parseInt(result[7], 10);
  const val = lastBall === 1 ? '和' : lastBall % 2 === 0 ? '双' : '单';
  tempResult.push({
    value: val,
    className: `d-flex h-100 justify-content-center align-items-center ${(val === '单') && 'redText'}`
  });
  return tempResult;
};

const Z = data => {
  if (!data.openResult) return;
  const result = data.openResult.split(',');
  let championSum = 0;
  const tempResult = [];
  result.forEach(item => championSum += parseInt(item === '' ? 0 : item, 10));
  tempResult.push({
    value: championSum,
    className: 'd-flex h-100 justify-content-center align-items-center'
  });
  return tempResult;
};

const Z_DS = data => {
  if (!data.openResult) return;
  const result = data.openResult.split(',');
  let championSum = 0;
  const tempResult = [];
  result.forEach(item => championSum += parseInt(item, 10));
  tempResult.push({
    value: championSum % 2 ? '单' : '双',
    className: `d-flex h-100 justify-content-center align-items-center ${championSum % 2 && 'redText'}`
  });
  return tempResult;
};

const Z_DX = data => {
  if (!data.openResult) return;
  const result = data.openResult.split(',');
  let championSum = 0;
  const tempResult = [];
  result.forEach(item => championSum += parseInt(item, 10));
  tempResult.push({
    value: (championSum > 174) ? '大' : '小',
    className: `d-flex h-100 justify-content-center align-items-center ${(championSum > 174) && 'redText'}`
  });
  return tempResult;
};

const BD = data => {
  if (!data.openResult) return;
  const result = data.openResult.split(',');
  let championSum = 0;
  const tempResult = [];
  const lastBall = parseInt(result[7], 10);
  result.forEach(item => championSum += parseInt(item, 10));
  tempResult.push({
    value: (lastBall === 1 || lastBall === 2 || lastBall === 7 || lastBall === 8 || lastBall === 12 || lastBall === 13 || lastBall === 18 || lastBall === 19 || lastBall === 23 || lastBall === 24 || lastBall === 29 || lastBall === 30 || lastBall === 34 || lastBall === 35 || lastBall === 40 || lastBall === 45 || lastBall === 46) ? '红' : (lastBall === 3 || lastBall === 4 || lastBall === 9 || lastBall === 10 || lastBall === 14 || lastBall === 15 || lastBall === 20 || lastBall === 25 || lastBall === 26 || lastBall === 31 || lastBall === 36 || lastBall === 37 || lastBall === 41 || lastBall === 42 || lastBall === 47 || lastBall === 48) ? '蓝' : '绿',
    className: (lastBall === 1 || lastBall === 2 || lastBall === 7 || lastBall === 8 || lastBall === 12 || lastBall === 13 || lastBall === 18 || lastBall === 19 || lastBall === 23 || lastBall === 24 || lastBall === 29 || lastBall === 30 || lastBall === 34 || lastBall === 35 || lastBall === 40 || lastBall === 45 || lastBall === 46) ? 'redBoDuan d-flex justify-content-center align-items-center w-100 h-100' : (lastBall === 3 || lastBall === 4 || lastBall === 9 || lastBall === 10 || lastBall === 14 || lastBall === 15 || lastBall === 20 || lastBall === 25 || lastBall === 26 || lastBall === 31 || lastBall === 36 || lastBall === 37 || lastBall === 41 || lastBall === 42 || lastBall === 47 || lastBall === 48) ? 'blueBoDuan  d-flex justify-content-center align-items-center w-100 h-100' : 'greenBoDuan  d-flex justify-content-center align-items-center w-100 h-100',
  });
  return tempResult;
};


const T_DS_DX_DXW = data => {
  if (!data.openResult) return;
  const result = data.openResult.split(',');
  const lastBall = parseInt(result[7], 10);
  const tempResult = [];
  tempResult.push({
    value: lastBall === 31 ? '和' : lastBall % 2 ? '单' : '双',
    className: `d-flex h-100 w-100 justify-content-center align-items-center ${(lastBall % 2) && 'redText'}`
  });
  tempResult.push({
    value: lastBall === 31 ? '和' : (lastBall > 17) ? '大' : '小',
    className: `d-flex h-100 w-100 justify-content-center align-items-center ${(lastBall % 2) && 'redText'}`
  });
  tempResult.push({
    value: (lastBall % 10) > 7 ? '大尾' : '小尾',
    className: `d-flex h-100 w-100 justify-content-center align-items-center ${(lastBall % 10) > 7 && 'redText'}`
  });
  return tempResult;
};

const F31X7 = {
  children: [
  ],
  resultTab: {
    headerCell: [{
      attr: '期数'
    }, {
      attr: '日期'
    }, {
      attr: () => [[{
        value: '彩球号码'
      }], [{
        value: '一'
      }, {
        value: '二'
      }, {
        value: '三'
      }, {
        value: '四'
      }, {
        value: '五'
      }, {
        value: '六'
      }, {
        value: '七'
      }, {
        value: '特'
      }]],
      wrapperStyle: 'hk6Header',
    },
    {
      wrapperStyle: 'hk6Header',
      attr: () => [[{
        value: '特',
      }], [{
        value: '单双',
      }, {
        value: '大小',
      }, {
        value: '大小尾',
      }]]
    }, {
      wrapperStyle: 'hk6Header',
      attr: () => [[{
        value: '特合',
        className: 'singleCol m-auto'
      }], [{
        value: '单双',
        className: 'singleCol m-auto'
      }]]
    }, {
      wrapperStyle: 'hk6Header',
      attr: () => [[{
        value: '特合',
        className: 'singleCol m-auto'
      }], [{
        value: '大小',
        className: 'singleCol m-auto'
      }]]
    }, {
      attr: '总分',
      wrapperStyle: 'singleCol m-auto p-0',
    }, {
      wrapperStyle: 'hk6Header',
      attr: () => [[{
        value: '总',
        className: 'singleCol m-auto'
      }], [{
        value: '大小',
        className: 'singleCol m-auto'
      }]]
    }, {
      wrapperStyle: 'hk6Header',
      attr: () => [[{
        value: '总',
        className: 'singleCol m-auto'
      }], [{
        value: '单双',
        className: 'singleCol m-auto'
      }]]
    },
    {
      attr: '波段',
      wrapperStyle: 'singleCol m-auto p-0',
    }],
    bodyCell: [{
      attr: 'installments',
      wrapperStyle: 'd-flex justify-content-center align-items-center'
    }, {
      attr: renderResultDateCell,
      wrapperStyle: 'dateCol'
    }, {
      attr: renderBall,
      wrapperStyle: 'd-flex hk6BallCol'
    },
    {
      attr: T_DS_DX_DXW,
      wrapperStyle: 'd-flex hk6BallCol'
    }, {
      attr: TH_DS,
      wrapperStyle: 'd-flex justify-content-center align-items-center'
    }, {
      attr: TH_DX,
      wrapperStyle: 'd-flex justify-content-center align-items-center'
    }, {
      attr: Z,
      wrapperStyle: 'd-flex justify-content-center align-items-center'
    }, {
      attr: Z_DS,
      wrapperStyle: 'd-flex justify-content-center align-items-center'
    }, {
      attr: Z_DX,
      wrapperStyle: 'd-flex justify-content-center align-items-center'
    }, {
      attr: BD,
      wrapperStyle: 'd-flex justify-content-center align-items-center hk6BallCol'
    }
    ]
  },
};

export default F31X7;