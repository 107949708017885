
import { threeNumberCom, twoNumberCom } from '../Items/combinations';

import { cowDsdx, dxds, totalSumDS, totalSumDX } from '../Items/dsdx';

import { dragonTiger, filler, peace, sscType } from '../Items/others';

import { ballsLightBlue, number1to20, shengXiaoNumbers } from '../Items/numbers';
import { cowSSC, duplicateChar, groupChoose, soha, threeCharacter, threeCharacterSumNum, twoCharacter, twoCharacterSumNum } from '../Items/variableSSC';

const CJ_SSC = [{
  displayName: '两面',
  isStaticRow: 1,
  combineStyle: 1,
  repeatPerRow: 4,
  repeatPerCol: 4,
  settingCode: 'LM,LHH,QH15,QZH3',
  display: [[...dxds, ...ballsLightBlue],
    [...dxds, ...ballsLightBlue],
    [...dxds, ...ballsLightBlue],
    [...totalSumDX, ...totalSumDS, ...dragonTiger, ...peace],
    [...dxds, ...ballsLightBlue],
    [...dxds, ...ballsLightBlue],
    [],
    [...sscType], [], [], [],
    [...sscType], [], [], [],
    [...sscType]
  ],
  displayForHLSXSSC: [[...dxds, ...shengXiaoNumbers],
    [...dxds, ...shengXiaoNumbers],
    [...dxds, ...shengXiaoNumbers],
    [...totalSumDX, ...totalSumDS, ...dragonTiger, ...peace],
    [...dxds, ...shengXiaoNumbers],
    [...dxds, ...shengXiaoNumbers],
    [],
    [...sscType], [], [], [],
    [...sscType], [], [], [],
    [...sscType]
  ],
  firstTableHeader: ['第一球', '第二球', '第三球', '总和', '第四球', '第五球', '', '前三', '', '', '', '中三', '', '', '', '后三'],
  prefix: ['B1', 'B2', 'B3', 'H', 'B4', 'B5', '', 'Q3', '', '', '', 'Z3', '', '', '', 'H3'],
  tabId: 'count_LM',
  summary: [[{
    displayName: '第一球总注额',
    code: 'item_QH1'
  }], [{
    displayName: '第二球总注额',
    code: 'item_QH2'
  }], [{
    displayName: '第三球总注额',
    code: 'item_QH3'
  }], [{
    displayName: '总和总注额',
    code: 'item_ZH'
  }], [{
    displayName: '第四球总注额',
    code: 'item_QH4'
  }], [{
    displayName: '第五球总注额',
    code: 'item_QH5'
  }],
  '', [{
    displayName: '前三总注额',
    code: 'item_Q3'
  }], '', '', '', [{
    displayName: '中三总注额',
    code: 'item_Z3'
  }], '', '', '', [{
    displayName: '后三总注额',
    code: 'item_H3'
  }]]
}, {
  displayName: '一字组合',
  settingCode: 'ZH1',
  isStaticRow: 1,
  repeatPerRow: 4,
  display: [[...number1to20.slice(0, 9)], [...number1to20.slice(0, 9)], [...number1to20.slice(0, 9)], [...number1to20.slice(0, 9)]],
  firstTableHeader: ['前三', '中三', '后三', '全五'],
  prefix: ['ZH1Q3', 'ZH1Z3', 'ZH1H3', 'ZH1Q5'],
  tabId: 'count_ZH1',
  summary: [[{
    displayName: '前三总注额',
    code: 'item_Q3'
  }], [{
    displayName: '中三总注额',
    code: 'item_Z3'
  }], [{
    displayName: '后三总注额',
    code: 'item_H3'
  }], [{
    displayName: '全五总注额',
    code: 'item_Q5'
  }]]
}, {
  displayName: '二字组合',
  isStaticRow: 1,
  combineStyle: 2,
  repeatPerRow: 1,
  repeatPerCol: 1,
  display: [[...twoNumberCom, ...filler]],
  displayRows: [14],
  tabId: 'count_ZH2',
  children: [
    {
      tabId: 'ZH2Q3',
      label: '前三',
      needHeader: 1,
      prefix: 'Q3',
      midcode: 'ZH2Q3',
    }, {
      tabId: 'ZH2Z3',
      label: '中三',
      needHeader: 1,
      midcode: 'ZH2Z3',
      prefix: 'Z3',
    }, {
      tabId: 'ZH2H3',
      label: '后三',
      needHeader: 1,
      midcode: 'ZH2H3',
      prefix: 'H3',
    }
  ]
}, {
  displayName: '三字组合',
  isStaticRow: 1,
  combineStyle: 2,
  repeatPerRow: 1,
  repeatPerCol: 1,
  display: [[...threeNumberCom]],
  displayRows: [55],
  tabId: 'count_ZH3',
  children: [
    {
      tabId: 'Q3',
      label: '前三',
      needHeader: 1,
      prefix: 'Q3',
      midcode: 'ZH3Q3',
    }, {
      tabId: 'ZH3Z3',
      label: '中三',
      needHeader: 1,
      midcode: 'ZH3Z3',
      prefix: 'Z3',
    }, {
      tabId: 'ZH3H3',
      label: '后三',
      needHeader: 1,
      midcode: 'ZH3H3',
      prefix: 'H3',
    }
  ]
}, {
  displayName: '二字定位',
  settingCode: 'DW2',
  displayRows: 14,
  firstTableHeader: ['二字定位'],
  display: [...twoCharacter],
  tabId: 'count_DW2',
}, {
  displayName: '三字定位',
  settingCode: 'DW3',
  displayRows: 14,
  firstTableHeader: ['三字定位'],
  display: [...threeCharacter],
  tabId: 'count_DW3',
},
{
  displayName: '二字和数',
  isStaticRow: 1,
  repeatPerRow: 4,
  firstTableHeader: ['万千和数', '万佰和数', '万拾和数', '万个和数', '千佰和数', '千拾和数', '千个和数', '佰拾和数', '佰个和数', '拾个和数'],
  prefix: ['WQ', 'WB', 'WS', 'WG', 'QB', 'QS', 'QG', 'BS', 'BG', 'SG'],
  display: [[...twoCharacterSumNum],
    [...twoCharacterSumNum],
    [...twoCharacterSumNum],
    [...twoCharacterSumNum],
    [...twoCharacterSumNum],
    [...twoCharacterSumNum],
    [...twoCharacterSumNum],
    [...twoCharacterSumNum],
    [...twoCharacterSumNum],
    [...twoCharacterSumNum],
    [], []],
  tabId: 'count_HS2',
  children: [
    {
      tabId: 'HS2',
      label: '和数',
      needHeader: 1,
      settingCode: 'HS2',
    }, {
      tabId: 'HS2W',
      label: '尾数',
      needHeader: 1,
      settingCode: 'HS2W',
    }
  ]
},
{
  displayName: '三字和数',
  isStaticRow: 1,
  repeatPerRow: 4,
  firstTableHeader: ['前三和数', '中三和数', '后三和数'],
  prefix: ['Q3', 'Z3', 'H3'],
  display: [[...threeCharacterSumNum],
    [...threeCharacterSumNum],
    [...threeCharacterSumNum]],
  tabId: 'count_HS3',
  children: [
    {
      tabId: 'HS3',
      label: '和数',
      needHeader: 1,
      settingCode: 'HS3',
    }, {
      tabId: 'HS3W',
      label: '尾数',
      needHeader: 1,
      settingCode: 'HS3W',
    }
  ]
}, {
  displayName: '组选三',
  isStaticRow: 1,
  repeatPerRow: 4,
  settingCode: 'ZX3',
  firstTableHeader: ['前三组选三', '中三组选三', '后三组选三'],
  prefix: ['Q3', 'Z3', 'H3'],
  display: [[...groupChoose.slice(1, 7)],
    [...groupChoose.slice(1, 7)],
    [...groupChoose.slice(1, 7)]],
  tabId: 'count_ZX3',
}, {
  displayName: '组选六',
  isStaticRow: 1,
  settingCode: 'ZX6',
  firstTableHeader: ['前三组选六', '中三组选六', '后三组选六'],
  prefix: ['Q3', 'Z3', 'H3'],
  display: [[...groupChoose.slice(0, 5)],
    [...groupChoose.slice(0, 5)],
    [...groupChoose.slice(0, 5)]],
  tabId: 'count_ZX6',
},
{
  displayName: '复式组合',
  settingCode: 'FSZH',
  display: [...duplicateChar],
  displayRows: 3,
  tabId: 'count_FSZH',
},
{
  displayName: '跨度',
  midcode: 'KDQ3,KDZ3,KDH3',
  isStaticRow: 1,
  firstTableHeader: ['前三跨度', '中三跨度', '后三跨度'],
  prefix: ['Q3', 'Z3', 'H3'],
  display: [[...number1to20.slice(0, 9)],
    [...number1to20.slice(0, 9)],
    [...number1to20.slice(0, 9)]],
  tabId: 'count_KD',
},
{
  displayName: '组合龙虎',
  isStaticRow: 1,
  repeatPerRow: 5,
  settingCode: 'ZLH',
  firstTableHeader: ['万千', '万佰', '万拾', '万个', '千佰', '千拾', '千个', '佰拾', '佰个', '拾个'],
  prefix: ['WQ', 'WB', 'WS', 'WG', 'QB', 'QS', 'QG', 'BS', 'BG', 'SG'],
  display: [[...dragonTiger, ...peace, ...dxds],
    [...dragonTiger, ...peace, ...dxds],
    [...dragonTiger, ...peace, ...dxds],
    [...dragonTiger, ...peace, ...dxds],
    [...dragonTiger, ...peace, ...dxds],
    [...dragonTiger, ...peace, ...dxds],
    [...dragonTiger, ...peace, ...dxds],
    [...dragonTiger, ...peace, ...dxds],
    [...dragonTiger, ...peace, ...dxds],
    [...dragonTiger, ...peace, ...dxds],
    [], []],
  tabId: 'count_ZLH',
},
{
  displayName: '牛牛梭哈',
  midcode: 'NN,NNLMDS,NNLMDX,SH',
  isStaticRow: 1,
  firstTableHeader: ['牛牛', '牛牛两面', '梭哈'],
  display: [[...cowSSC],
    [...cowDsdx],
    [...soha]],
  tabId: 'count_NNSH',
  summary: [[{
    displayName: '牛牛总注额',
    code: 'item_NN'
  }], [{
    displayName: '牛牛两面总注额',
    code: 'item_NNLM'
  }], [{
    displayName: '梭哈总注额',
    code: 'item_SH'
  }]]
},
];

export default CJ_SSC;