import { useEffect, useState } from 'react';

import { Button, Checkbox, Input, InputNumber, Radio, Select } from 'antd';
import { Col, Label, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { agentActions, appActions, commonActions, memberActions } from '../../../../redux/actions';
import AgentDataTable from '../../../AgentDataTable';
import { SectionToChinese } from '../../../../constants/utils';
import './styles.scss';

const mapStateToProps = state => ({
  userData: state.memberReducers.userData,
  userInfo: state.memberReducers.userInfo,
  addMemberInfoData: state.memberReducers.addMemberInfoData,
  isValidNameMsg: state.agentReducers.isValidNameMsg,
  isValidLoginIdMsg: state.agentReducers.isValidLoginIdMsg,
  accountType: state.commonReducers.accountType,
});

const mapDispatchToProps = dispatch => ({
  GetAddMemberInfo: (obj, callback) => dispatch(memberActions.GetAddMemberInfo(obj, callback)),
  SaveMember: (obj, callback) => dispatch(memberActions.SaveMember(obj, callback)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  isValidName: (userId, callback) => dispatch(agentActions.isValidName(userId, callback)),
  isExistLoginId: (userId, callback) => dispatch(agentActions.isExistLoginId(userId, callback)),
  resetReturnMsgData: () => dispatch(agentActions.resetReturnMsgData()),
  resetSubAccountInfo: () => dispatch(agentActions.resetSubAccountInfo()),
  GetAccountType: () => dispatch(commonActions.GetAccountType()),
});

const MemberAdd = ({ GetAccountType, accountType, SaveMember, GetAddMemberInfo, userInfo, callback = () => { }, onCancel = () => { }, resetReturnMsgData, resetSubAccountInfo, addMemberInfoData, data, updateModalAlert, userData, isValidName, isExistLoginId, isValidNameMsg, isValidLoginIdMsg }) => {
  const { t } = useTranslation();
  const [_password, setPassword] = useState();
  const [_loginId, setLoginId] = useState();
  const [_name, setName] = useState();
  const [_nameReturnMsg, setNameReturnMsg] = useState({});
  const [_loginIdReturnMsg, setLoginIdReturnMsg] = useState({});
  const [_passwordMsg, setPasswordMsg] = useState({});
  const [isQuery, setIsQuery] = useState(true);

  const [checkAll1, setCheckAll1] = useState(false);

  const [limitType, setLimitType] = useState(1); // 额度模式
  const [rebate, setRebate] = useState(0); // 退水

  // 彩种管理
  const [lotteryMenu, setLotteryMenu] = useState([]);
  const leftLotteryMenu = lotteryMenu.reduce((acc, item) => {
    const exist = acc.find(i => i.categoryCode === item.categoryCode);
    if (!exist) {
      acc.push({
        categoryName: item.categoryName,
        categoryCode: item.categoryCode,
      });
    }
    return acc;
  }, []).sort((a, b) => ((a.orderBy > b.orderBy) ? -1 : 1));


  const [accountInfo, setAccountInfo] = useState([]); // 只放额度

  const [zcList, setZcList] = useState([]);

  // addMemberInfoData 数据
  const [pkList, setPkList] = useState([]);

  const [pkOptions, setpkOptions] = useState([]);

  useEffect(() => {
    resetSubAccountInfo();
    resetReturnMsgData();
    if (addMemberInfoData) {
      const dataPanKou = (addMemberInfoData?.gameType || '').split('').map(item => ({
        label: item, value: item
      }));
      setpkOptions(dataPanKou);
      setPkList(dataPanKou?.length > 0 ? [dataPanKou[0].value] : []);
      setLimitType(addMemberInfoData?.orgParent?.limitType ?? 1);

      const memZcList = addMemberInfoData?.memZcList || [];
      const parentZcList = addMemberInfoData?.parentZcList || [];
      const _lotteryMenu = (addMemberInfoData?.parentLotList || []).map(item => ({
        ...item, checked: false,
      }));
      const leftLotteryMenu = lotteryMenu.reduce((acc, item) => {
        const exist = acc.find(i => i.categoryCode === item.categoryCode);
        if (!exist) {
          acc.push({
            categoryName: item.categoryName,
            categoryCode: item.categoryCode,
          });
        }
        return acc;
      }, []);

      const accountInfo = [];
      accountType.forEach(item => {
        const acc = addMemberInfoData?.orgParent?.accountInfo?.find(x => x.catagoryId === item.accountType);
        let { accName } = item;
        if (addMemberInfoData?.info?.accountOption === 0 && item.accountType === 2) {
          accName = '额度';
        }
        if (acc) {
          accountInfo.push({
            ...acc, accName, moneyLimit: 0
          });
        }
      });
      setAccountInfo(accountInfo);

      setLotteryMenu(_lotteryMenu);
      setZcList(memZcList.map(item => {
        const parentZcItem = parentZcList.find(i => i.categoryCode === item.categoryCode) || {};
        const lotteryItem = leftLotteryMenu.find(i => i.categoryCode === item.categoryCode) || {};
        return {
          ...item,
          categoryName: lotteryItem.categoryName,
          rateForMe: parentZcItem.minZc, // 初始化 占城是 parent minZc
        };
      }));
      setIsQuery(false);
    }
  }, [addMemberInfoData]);

  const currentInfo = {
    ...data,
    isChild: data?.loginId ? data.isChild : userData.isChild,
    orgId: data?.loginId ? data.orgId : userInfo.orgId,
    parentId: data?.loginId ? data.orgId : userInfo.orgId,
    uid: data?.loginId ? data.orgId : userData.id,
    level: data?.loginId ? data.level : userInfo.levelValue,
    levelName: data?.loginId ? data.levelName : userInfo.levelName,
    loginId: data?.loginId ? data.loginId : userData.loginId,
  };

  useEffect(() => {
    GetAccountType();
    GetAddMemberInfo({
      isChild: currentInfo.isChild,
      loginId: currentInfo.loginId,
      uid: currentInfo.uid,
      parentId: currentInfo.orgId,
    });
  }, []);

  useEffect(() => {
    setNameReturnMsg(isValidNameMsg);
  }, [isValidNameMsg]);

  useEffect(() => {
    setLoginIdReturnMsg(isValidLoginIdMsg);
  }, [isValidLoginIdMsg]);

  const handleNameOnBlur = text => {
    setName(text);
    if (text?.length > 1) {
      isValidName(text);
    } else {
      setNameReturnMsg({
        state: 99,
        msg: '名称只能2-12位由数字、英文字母,中文组成'
      });
    }
  };

  const handleLoginIdOnBlur = text => {
    setLoginId(text);
    if (text?.length > 3) {
      isExistLoginId(text);
    } else {
      setLoginIdReturnMsg({
        state: 99,
        msg: '帐号必须由字母数字组成, 4-12位'
      });
    }
  };

  const handlePasswordChange = text => {
    setPassword(text);
    if (text?.length > 5 && text?.length < 21 && text.match(/^[0-9a-zA-Z]+$/)) {
      if (/[0-9]/.test(text) && /[a-z]/.test(text) && /[A-Z]/.test(text)) {
        setPasswordMsg({
          state: 1,
          msg: '密码可用'
        });
      } else {
        setPasswordMsg({
          state: 99,
          msg: '密码必须由字母数字组成（区分大小写）！'
        });
      }
    } else {
      setPasswordMsg({
        state: 99,
        msg: '密码必须由字母数字组成，6-20位（区分大小写）！'
      });
    }
  };

  const saveButton = () => {
    updateModalAlert({
      type: 'confirmation',
      visible: true,
      data: {
        message: '请确认是否要设置',
        onConfirmationClick: () => changData()
      }
    });
    const changData = () => {
      const lotteryValues = lotteryMenu.filter(checkbox => checkbox.checked).map(checkbox => checkbox.id);
      if (_loginIdReturnMsg?.state === 1 && _nameReturnMsg?.state === 1 && _passwordMsg?.state === 1 && (addMemberInfoData?.lotteryAssigned === 1 ? lotteryValues?.length > 0 : true)) {
        updateModalAlert({
          type: 'loading',
          visible: true
        });
        const obj = {
          zcList: zcList.map(item => ({
            categoryCode: item.categoryCode,
            rateForMe: item.rateForMe,
            minZc: item.minZc,
            avairableRateForDown: item.avairableRateForDown,
            rateForUp: item.rateForUp,
            replenishment: item.replenishment
          })),
          isUpdate: false,
          lotteryType: lotteryValues || [],
          memInfo: {
            isChild: currentInfo.isChild,
            quickReturn: rebate, // 赚取退水
            type: 1,
            useState: 1, // 启用
            organizationId: currentInfo.orgId,
            limitType,
            loginId: _loginId,
            name: _name,
            gameType: pkList.join(''),
            password: _password,
          },
          accountInfo: accountInfo?.map(x => ({
            ...x, moneyLimit: x?.moneyLimit ?? 0
          })),
        };
        SaveMember(obj, callback);
      } else {
        updateModalAlert({
          visible: true,
          type: 'confirmation',
          data: {
            message: _loginIdReturnMsg?.state !== 1 ? '帐号必须由字母数字组成，4-12位' : _passwordMsg?.state !== 1 ? '密码必须由字母数字组成（大写+小写+数字），6-20位（区分大小写）' : _nameReturnMsg?.state !== 1 ? '名称只能2-12位由数字、英文字母,中文组成' : '请至少选择一个彩！',
            showCancelButton: false
          }
        });
      }
    };
  };

  const tableHeader = [{
    label: '主菜单',
  }, {
    label: (
      <>
        子菜单项
        <Checkbox
          className="mx-1"
          checked={checkAll1}
          onChange={e => {
            setCheckAll1(e.target.checked);
          }}
        >
          选择所有
        </Checkbox>
      </>),
  }];

  const tableContent2 = [
    data => (
      <div className="d-flex justify-content-end no-wrap m-1 p-1">
        <span className="mx-2">{data?.categoryName}</span>
        <Checkbox
          className="w-all-checkbox"
          onChange={e => {
            const { checked } = e.target;
            setLotteryMenu(
              lotteryMenu.map(item => {
                if (item.categoryCode === data.categoryCode) {
                  return {
                    ...item, checked,
                  };
                }
                return item;
              })
            );
          }}
        >
          所有
        </Checkbox>
      </div>
    ),
    data => {
      const _menus = lotteryMenu.filter(x => x.categoryCode === data.categoryCode);
      return (
        <>
          {
            _menus.map(d => (
              <p key={d.code} className="d-inline border-0 m-1">
                <Checkbox
                  className="w-single-checkbox"
                  checked={d.checked}
                  onChange={e => {
                    const index = lotteryMenu.findIndex(i => i.id === d.id);
                    const item = lotteryMenu[index];
                    setLotteryMenu([
                      ...lotteryMenu.slice(0, index),
                      {
                        ...item,
                        checked: e.target.checked
                      },
                      ...lotteryMenu.slice(index + 1),
                    ]);
                  }}
                >
                  {d.lotteryName}
                </Checkbox>
              </p>
            ))
          }
        </>
      );
    }
  ];
  const tableContent3 = [
    data => (
      <div className="d-flex justify-content-center no-wrap m-1 p-1">
        <span className="mx-2">{data?.categoryName}</span>
      </div>
    ),
    data => {
      const memZcItem = zcList.find(i => i.categoryCode === data.categoryCode) || {};
      return (
        <div className="text-red d-flex justify-content-center">
          <InputNumber
            min={0}
            value={memZcItem.rateForMe}
            onChange={value => {
              const index = zcList.findIndex(i => i.categoryCode === data.categoryCode);
              if (index !== -1) {
                setZcList([
                  ...zcList.slice(0, index),
                  {
                    ...(zcList[index] || {}),
                    rateForMe: value
                  },
                  ...zcList.slice(index + 1),
                ]);
              }
            }}
          />
        </div>
      );
    },
    data => {
      const parentZcList = addMemberInfoData?.parentZcList || [];
      const parentZcItem = parentZcList.find(i => i.categoryCode === data.categoryCode) || {};
      return (
        <div className="text-red d-flex justify-content-center">
          {
            `（上级占成范围在 ${parentZcItem.minZc || 0}%与${parentZcItem.avairableRateForDown || 0}% ）之间`
          }
        </div>
      );
    },
  ];

  return (
    <div className="MemberAddWrapper">
      <div className="mx-0 bg-white my-3  border-1">
        <Row className="titleWrapper m-0 my-2 p-2">
          账户资料
        </Row>
        <Row className="bigWrapper m-0 my-3 p-3">
          <table className="agentDataTable p-3 m-0" cellSpacing="0" cellPadding="0" border="0">
            <tbody>
              <tr>
                <td className="tableHeader col-5">
                  {currentInfo.levelName || ''}
                  帐号
                </td>
                <td>
                  <span>
                    {currentInfo.loginId}
                  </span>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-5">会员帐号</td>
                <td>
                  <span>
                    <Input
                      className="d-inline w-select"
                      value={_loginId}
                      onChange={({ target: { value: text } }) => setLoginId(text.trim())}
                      onBlur={({ target: { value: text } }) => handleLoginIdOnBlur(text.trim())}
                    />
                    <Label className={`d-inline mx-2 ${_loginIdReturnMsg?.state === 1 ? 'text-success' : 'text-danger'}`}>{_loginIdReturnMsg?.msg}</Label>
                  </span>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-5">登入密码</td>
                <td>
                  <span>
                    <Input
                      className="d-inline w-select"
                      value={_password}
                      onChange={({ target: { value: text } }) => handlePasswordChange(text.trim())}
                    />
                  </span>
                  <Label className={`d-inline mx-2 ${_passwordMsg?.state === 1 ? 'text-success' : 'text-danger'}`}>{_passwordMsg?.msg}</Label>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-5">会员名称</td>
                <td>
                  <span>
                    <Input
                      className="d-inline w-select"
                      value={_name}
                      onChange={({ target: { value: text } }) => setName(text.trim())}
                      onBlur={({ target: { value: text } }) => handleNameOnBlur(text.trim())}
                    />
                    <Label className={`d-inline mx-2 ${_nameReturnMsg?.state === 1 ? 'text-success' : 'text-danger'}`}>{_nameReturnMsg?.msg}</Label>
                  </span>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-5">可用盘类</td>
                <td>
                  {
                    addMemberInfoData?.allowMultiGameType ? (
                      <Checkbox.Group
                        options={pkOptions}
                        value={pkList}
                        onChange={values => setPkList(values)}
                      />
                    ) : (
                      <Radio.Group
                        value={pkList[0] || ''}
                        onChange={e => setPkList([e.target.value])}
                      >
                        {
                          pkOptions.map(item => <Radio value={item.value}>{item.label}</Radio>)
                        }
                      </Radio.Group>
                    )
                  }
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-5">额度模式</td>
                <td>
                  {addMemberInfoData?.orgParent?.limitType === 1
                    ? (
                      <span>
                        <Radio
                          checked={limitType === '1'}
                          onChange={() => setLimitType('1')}
                        >
                          信用模式
                        </Radio>
                        <Radio
                          checked={limitType === '2'}
                          onChange={() => setLimitType('2')}
                        >
                          现金模式
                        </Radio>
                      </span>
                    )
                    : (
                      <span>
                        <Radio checked>
                          现金模式
                        </Radio>
                      </span>
                    )}
                </td>
              </tr>
              {
                accountInfo?.length > 0
                    && accountInfo?.map(item => (
                      <tr>
                        <td className="tableHeader col-5">
                          {item?.accName}
                        </td>
                        <td>
                          <InputNumber
                            className="w-select"
                            type="number"
                            value={Number(item?.moneyLimit ?? 0).toFixed(1)}
                            onChange={value => {
                              setAccountInfo([].concat(accountInfo.map(x => ({
                                ...x, moneyLimit: x.catagoryId === item?.catagoryId ? Number(String(value).replace(/\D/g, '')) : x.moneyLimit
                              }))));
                            }}
                          />
                          <span className="mx-1">
                            <span className="text-danger mx-1">{SectionToChinese(Math.floor(item?.moneyLimit ?? 0))}</span>
                            (上级余额：
                            {item?.balance?.toFixed(2) ?? ''}
                            )
                          </span>
                        </td>
                      </tr>
                    ))
              }

              <tr>
                <td className="tableHeader col-5">赚取退水</td>
                <td>
                  <Select
                    className="w-select"
                    onChange={val => setRebate(val)}
                    value={rebate}
                    options={[
                      {
                        label: '水全退到底', value: 0
                      },
                      {
                        label: '赚取0.1%', value: 0.1
                      },
                      {
                        label: '赚取0.3%', value: 0.3
                      },
                      {
                        label: '赚取0.5%', value: 0.5
                      },
                      {
                        label: '赚取1.0%', value: 1.0
                      },
                      {
                        label: '赚取1.5%', value: 1.5
                      },
                      {
                        label: '赚取2.0%', value: 2.0
                      },
                      {
                        label: '赚取2.5%', value: 2.5
                      },
                      {
                        label: '赚取所有退水', value: 100
                      },
                    ]}
                  />

                </td>
              </tr>
            </tbody>
          </table>
        </Row>
        { addMemberInfoData && addMemberInfoData?.lotteryAssigned === 1 && (
        <>
          <Row className="titleWrapper m-0 my-2 p-2">
            彩种选择
          </Row>
          <Row className="bigWrapper m-0 my-3 p-3">
            {
            isQuery ? (
              <Row>
                <AgentDataTable
                  isQuerying={isQuery}
                  tableHeaderTitle={[]}
                  tableData={[1, 2, 3]}
                  tableBodyRowProperty={tableContent2}
                  tableHeaderStyle={['textRight px-1 col-5', 'textLeft px-1']}
                  tableBodyRowStyle={['backgroundColored p-0 mediumSmallCol', 'textLeft flex-wrap']}
                />
              </Row>
            ) : (
              <AgentDataTable
                tableHeaderTitle={[tableHeader[0]]}
                tableData={leftLotteryMenu}
                tableBodyRowProperty={tableContent2}
                tableHeaderStyle={['textRight px-1 col-5', 'textLeft px-1']}
                tableBodyRowStyle={['backgroundColored p-0 mediumSmallCol', 'textLeft flex-wrap']}
              />
            )
          }
          </Row>
        </>
        )}
        <Row className="titleWrapper m-0 my-2 p-2">
          本级占成
        </Row>
        <Row className="bigWrapper m-0 my-3 p-3">
          {
            isQuery ? (
              <Row>
                <AgentDataTable
                  isQuerying={isQuery}
                  tableHeaderTitle={[]}
                  tableData={[1, 2, 3]}
                  tableBodyRowProperty={tableContent2}
                />
              </Row>
            ) : (
              <AgentDataTable
                tableHeaderTitle={[
                  {
                    label: '类型',
                  },
                  {
                    label: '占成'
                  },
                  {
                    label: '可分配占成'
                  }
                ]}
                tableData={leftLotteryMenu}
                tableBodyRowProperty={tableContent3}
                tableHeaderStyle={['textCenter px-1', 'textCenter px-1', 'textCenter px-1']}
              />

            )
          }
        </Row>
      </div>

      <Row className="mb-5 my-2 p-2">
        <Col className="d-flex justify-content-center py-2 border-top">
          <Button className="button handle mx-1" onClick={() => saveButton()}>{t('SAVE')}</Button>
          <Button className="button find mx-1" onClick={() => onCancel?.()}>{t('CANCEL')}</Button>
        </Col>
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MemberAdd);