import { useEffect, useState } from 'react';

import { Checkbox, Input, Radio, Select } from 'antd';
import { Label } from 'reactstrap';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { appActions, oddsSettingActions } from '../../../redux/actions';
import AgentDataTable from '../../AgentDataTable';
import Skeleton from '../../Skeleton';
import mainGames from '../../../constants/QuickSetVariable/Games';
import './styles.scss';

const mapStateToProps = state => ({
  oddsDiffData: state.oddsSettingReducers.defaultWinLostData,
  userData: state.memberReducers.userData,
});

const mapDispatchToProps = dispatch => ({
  getAgentOddsCmSetting: (obj, callback) => dispatch(oddsSettingActions.getAgentOddsCmSetting(obj, callback)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  SaveAgentOddsCmSetting: (obj, callback) => dispatch(oddsSettingActions.SaveAgentOddsCmSetting(obj, callback)),
  SaveDefaultOddsCmSetting: (obj, callback) => dispatch(oddsSettingActions.SaveDefaultOddsCmSetting(obj, callback)),
});
const { Option } = Select;
const returnOptionList = [
  {
    label: '赚赔A', value: 'diffOddsA'
  },
  {
    label: '赚赔B', value: 'diffOddsB'
  },
  {
    label: '赚赔C', value: 'diffOddsC'
  },
  {
    label: '赚赔D', value: 'diffOddsD'
  }
];

const DefaultWinLost = ({ data, userData, getAgentOddsCmSetting, oddsDiffData, SaveAgentOddsCmSetting, SaveDefaultOddsCmSetting }) => {
  const { t } = useTranslation();
  const [lotType, setlotType] = useState('');
  const [_isQuerying, setIsQuerying] = useState(false);
  const [billList, setBillList] = useState();
  const [memberGame, setMemberGame] = useState(['A', 'B', 'C', 'D']);
  const [lotteryList, setLotteryList] = useState();
  const [menuList, setMenuList] = useState([]);
  const [activeKey, setActiveKey] = useState();
  const [_selectedLottery, setSelectedLottery] = useState();
  const [changAll, setChangeAll] = useState(false);
  const [commonValue, setCommonValue] = useState('');
  const [_returnOption, setReturnOption] = useState('');
  const [_changeValue, setChangeValue] = useState('');
  const [_mainGames, setMainGames] = useState('');

  const [oddsList, setOddsList] = useState();
  const [allOddsList, setAllOddsList] = useState();

  const getAllDefaultData = () => {
    const obj = {
      orgId: data ? data.memberId || data?.orgId : userData.id,
      userType: !data ? 2 : data?.orgId ? 2 : 1,
      isHK6: lotType === 1,
      isDPC: lotType === 2,
      isDefault: data ? 0 : 1
    };
    setIsQuerying(true);
    setOddsList([]);
    getAgentOddsCmSetting(obj, () => setIsQuerying(false));
  };

  useEffect(() => {
    getAllDefaultData();
  }, [lotType]);

  useEffect(() => {
    if (oddsDiffData) {
      if (JSON.stringify(oddsDiffData.lotteryList) !== JSON.stringify(lotteryList)) {
        setLotteryList(oddsDiffData.lotteryList);
      }
      if (JSON.stringify(oddsDiffData.memberGame) !== JSON.stringify(memberGame)) {
        setMemberGame(oddsDiffData.memberGame.split(''));
      }
      if (JSON.stringify(oddsDiffData.billList) !== JSON.stringify(billList)) {
        setBillList(oddsDiffData.billList);
      }

      const tempMemberGame = oddsDiffData.memberGame.split('');
      const tempMap = new Map();
      oddsDiffData.tempOddsList.forEach(x => {
        tempMap.set(`${x.lotteryCode}_${x.settingCode}_${x.oddsCode}`, {
          ...x
        });
      });

      setAllOddsList(oddsDiffData.oddsList.map(x => {
        if (tempMap.has(`${x.lotteryCode}_${x.settingCode}_${x.oddsCode}`)) {
          const tempObj = {};
          tempMemberGame.forEach(y => {
            tempObj[`tempDiffOdds${y}`] = x[`diffOdds${y}`];
            tempObj[`diffOdds${y}`] = tempMap.get(`${x.lotteryCode}_${x.settingCode}_${x.oddsCode}`)[`diffOdds${y}`];
          });
          return ({
            ...x, ...tempObj
          });
        }
        return ({
          ...x
        });
      }));
    }
  }, [oddsDiffData]);

  useEffect(() => {
    if (lotteryList) {
      const tempGames = mainGames.Games.filter(x => lotteryList.some(item => x.code.includes(item.templateCode)));
      setlotType(tempGames[0]?.value);
      setMainGames(tempGames);
    }
  }, [lotteryList]);

  useEffect(() => {
    if (lotteryList && _mainGames && _mainGames.length > 0) {
      const _tab = _mainGames.find(x => x.value === lotType);
      const content = [];
      if (_tab) {
        const temp = new Map();
        lotteryList.filter(x => _tab.code.includes(x.templateCode)).forEach(item => {
          if (temp.has(item.templateCode)) {
            const _tempArr = [...temp.get(item.templateCode), {
              ...item, lotteryName: billList.includes(item.code) ? `${item.lotteryName}[预设]` : item.lotteryName
            }];
            temp.set(item.templateCode, _tempArr);
          } else {
            temp.set(item.templateCode, [{
              ...item, lotteryName: billList.includes(item.code) ? `${item.lotteryName}[预设]` : item.lotteryName
            }]);
          }
        });
        temp.forEach((item, key) => {
          if (item.length !== 0) {
            content.push({
              label: _tab.name.find(x => x.value === key)?.label,
              key,
              children: item
            });
          }
        });
        setActiveKey(_tab.code[0]);
      }
      setMenuList(content);
    }
  }, [_mainGames, lotType]);

  useEffect(() => {
    if (activeKey && _mainGames && _mainGames.length > 0) {
      const _tab = _mainGames.find(x => x.value === lotType);
      const temp = new Map();
      if (_tab) {
        lotteryList.filter(x => _tab.code.includes(x.templateCode)).forEach(item => {
          if (temp.has(item.templateCode)) {
            const _tempArr = [...temp.get(item.templateCode), item];
            temp.set(item.templateCode, _tempArr);
          } else {
            temp.set(item.templateCode, [item]);
          }
        });
        setSelectedLottery(temp.get(activeKey)[0].code);
      }
    }
  }, [activeKey, _mainGames]);

  useEffect(() => {
    if (_selectedLottery && allOddsList) {
      const temp = Object.values(allOddsList.filter(x => x.lotteryCode === _selectedLottery).reduce((group, item) => {
        const { settingCode } = item;
        group[settingCode] = group[settingCode] ?? [];
        group[settingCode].push(item);
        return group;
      }, {}));
      setOddsList([...temp]);
    }
  }, [_selectedLottery, allOddsList]);

  const onValueChange = (text, settingCode, oddsCode, type) => {
    if (changAll) {
      const _list = menuList.find(x => x.key === activeKey).children;
      const temp = allOddsList.map(x => {
        if (_list.find(item => x.lotteryCode === item.code)) {
          if (x.settingCode === settingCode && x.oddsCode === oddsCode) {
            return ({
              ...x, [type]: Number(text || 0), [`${type}singleChange`]: false, [`${type}multiChange`]: true
            });
          }
          return ({
            ...x
          });
        }
        return ({
          ...x
        });
      });
      setAllOddsList(temp);
    } else {
      const temp = allOddsList.map(x => {
        if (x.lotteryCode === _selectedLottery) {
          if (x.settingCode === settingCode && x.oddsCode === oddsCode) {
            return ({
              ...x, [type]: Number(text || 0), [`${type}singleChange`]: true, [`${type}multiChange`]: false
            });
          }
          return ({
            ...x
          });
        }
        return ({
          ...x
        });
      });
      setAllOddsList(temp);
    }
  };

  const onClickConfirm = () => {
    if (_returnOption && (commonValue !== '' || _changeValue !== '')) {
      if (changAll) {
        const _list = menuList.find(x => x.key === activeKey)?.children;
        const temp = allOddsList.map(x => {
          if (_list.find(item => x.lotteryCode === item.code)) {
            if (_returnOption === 'diffOddsALL') {
              const objForAllDiffOdds = {};
              memberGame.forEach(item => {
                const _returnOption = `diffOdds${item}`;
                objForAllDiffOdds[_returnOption] = commonValue !== '' ? Number((Number(commonValue || 0) + Number(_changeValue || 0)).toFixed(4)) : Number((Number(_changeValue || 0) + Number(x[_returnOption] || 0)).toFixed(4));
                objForAllDiffOdds[`${_returnOption}singleChange`] = false;
                objForAllDiffOdds[`${_returnOption}multiChange`] = true;
              });
              return ({
                ...x,
                ...objForAllDiffOdds
              });
            }
            return ({
              ...x,
              [_returnOption]: commonValue !== '' ? Number((Number(commonValue || 0) + Number(_changeValue || 0)).toFixed(4)) : Number((Number(_changeValue || 0) + Number(x[_returnOption] || 0)).toFixed(4)),
              [`${_returnOption}singleChange`]: false,
              [`${_returnOption}multiChange`]: true
            });
          }
          return ({
            ...x
          });
        });
        setAllOddsList(temp);
      } else {
        const temp = allOddsList.map(x => {
          if (x.lotteryCode === _selectedLottery) {
            if (_returnOption === 'diffOddsALL') {
              const objForAllDiffOdds = {};
              memberGame.forEach(item => {
                const _returnOption = `diffOdds${item}`;
                objForAllDiffOdds[_returnOption] = commonValue !== '' ? Number((Number(commonValue || 0) + Number(_changeValue || 0)).toFixed(4)) : Number((Number(_changeValue || 0) + Number(x[_returnOption] || 0)).toFixed(4));
                objForAllDiffOdds[`${_returnOption}singleChange`] = true;
                objForAllDiffOdds[`${_returnOption}multiChange`] = false;
              });
              return ({
                ...x,
                ...objForAllDiffOdds
              });
            }
            return ({
              ...x,
              [_returnOption]: commonValue !== '' ? Number((Number(commonValue || 0) + Number(_changeValue || 0)).toFixed(4)) : Number((Number(_changeValue || 0) + Number(x[_returnOption] || 0)).toFixed(4)),
              [`${_returnOption}singleChange`]: true,
              [`${_returnOption}multiChange`]: false
            });
          }
          return ({
            ...x
          });
        });
        setAllOddsList(temp);
      }
    }
  };

  const onClickReset = () => {
    const tempMemberGame = oddsDiffData.memberGame.split('');
    const tempMap = new Map();
    oddsDiffData.tempOddsList.forEach(x => {
      tempMap.set(`${x.lotteryCode}_${x.settingCode}_${x.oddsCode}`, {
        ...x
      });
    });
    setAllOddsList(oddsDiffData.oddsList.map(x => {
      if (tempMap.has(`${x.lotteryCode}_${x.settingCode}_${x.oddsCode}`)) {
        const tempObj = {};
        tempMemberGame.forEach(y => {
          tempObj[`tempDiffOdds${y}`] = x[`diffOdds${y}`];
          tempObj[`diffOdds${y}`] = tempMap.get(`${x.lotteryCode}_${x.settingCode}_${x.oddsCode}`)[`diffOdds${y}`];
        });
        return ({
          ...x, ...tempObj
        });
      }
      return ({
        ...x
      });
    }));
    setChangeAll(false);
    setCommonValue('');
    setReturnOption('');
    setChangeValue('');
  };

  const onClickSave = () => {
    const temp = allOddsList.filter(x => Object.keys(x).find(a => a.includes('singleChange')) || Object.keys(x).find(a => a.includes('multiChange')));
    const obj = {
      memberType: !data || data?.levelName === '代理' ? 2 : 1,
      setting: temp,
      orgId: data ? data.memberId || data?.orgId : userData.id,
    };
    if (!data) {
      return SaveDefaultOddsCmSetting(obj, getAllDefaultData);
    }
    return SaveAgentOddsCmSetting(obj, getAllDefaultData);
  };

  return (
    <div className="DefaultWinLostWrapper">
      <div className="operationSection mb-2">
        <div className="categorySelect">
          <Radio.Group onChange={({ target: { value: text } }) => setlotType(text)} value={lotType} className="squareCheckStyle">
            {
              _mainGames && _mainGames.map(item => <Radio value={item.value}>{item.displayName}</Radio>)
            }
          </Radio.Group>
        </div>
        <div className="dropdownArea">
          <div className="ms-1">赚赔：</div>
          <Select
            className="dropDownList"
            value={_returnOption}
            placeholder="请选择"
            onChange={val => setReturnOption(val)}
          >
            {
            (memberGame && memberGame?.length > 0)
              ? [<Option value="diffOddsALL">赚赔全部盘</Option>].concat(returnOptionList.filter(x => memberGame.find(item => x.label.includes(item))).map(item => <Option value={item.value}>{item.label}</Option>))
              : []
          }
          </Select>
          <Select
            className="dropDownList shortvalue"
            value={_changeValue}
            placeholder="请选择"
            onChange={item => setChangeValue(Number(item))}
          >
            {
              [1, 0.5, 0.1, 0.05, 0.01, 0, -0.01, -0.05, -0.1, -0.5, -1].map(value => <Option key={value} label={value} value={value} />)
            }
          </Select>
          <div className="mx-1">统一值：</div>
          <Input className="inputStyle" value={commonValue} onChange={({ target: { value: text } }) => setCommonValue(text > -1 ? text || 0 : commonValue)} />
          <div className="operationButton generate mx-1" onClick={() => onClickConfirm()}>{t('CONFIRM')}</div>
          <div className="operationButton handle mx-1" onClick={() => onClickSave()}>{t('SAVE')}</div>
          <div className="operationButton find mx-1" onClick={() => onClickReset()}>{t('RESET')}</div>
        </div>
      </div>
      <div className="selectSection my-2">
        <div className="d-flex">
          {
            menuList.map(item => <div className={`templateStyle ${item.key === activeKey && 'selected'}`} onClick={() => setActiveKey(item.key)}>{item.label}</div>)
          }
        </div>
        <div>
          <Radio.Group value={_selectedLottery} onChange={({ target: { value: text } }) => setSelectedLottery(text)} className="squareCheckStyle">
            {menuList.find(x => x.key === activeKey) && menuList.find(x => x.key === activeKey)?.children.map(item => <Radio value={item.code}>{item.lotteryName}</Radio>)}
          </Radio.Group>
          <Checkbox className="ms-5" checked={changAll} onChange={({ target: { checked: val } }) => setChangeAll(val)}>统一设置</Checkbox>
        </div>
      </div>
      <div className="contentSection">
        <AgentDataTable
          isQuerying={_isQuerying}
          tableHeaderTitle={
            [{
              label: '种类',
            },
            ...memberGame.map(x => ({
              label: (
                <div className="panHeaderStyle">
                  <div>
                    {`${x}盘`}
                  </div>
                  <div className="d-flex">
                    <div>赚赔/上级赔率</div>
                  </div>
                </div>)
            }))]
          }
          tableHeaderStyle={
            ['firstCellStyle']
          }
          tableData={oddsList && oddsList.length > 0 && memberGame ? oddsList : []}
          tableBodyRowProperty={
            [
              data => (
                data ? (
                  <Label className="categoryWrapper">
                    <div>{data[0].name}</div>
                    <div>{data.map(item => <div>{item.subName}</div>)}</div>
                  </Label>
                ) : <Skeleton isAnimated />
              ),
              ...memberGame.map(x => (data = []) => (
                <Label className="pankouContentWarpper">
                  {data && data.map(item => {
                    const { [`odds${x}`]: odds, [`tempDiffOdds${x}`]: tempDiffOdds, [`diffOdds${x}`]: diffOdds, [`diffOdds${x}singleChange`]: singleChange, [`diffOdds${x}multiChange`]: multiChange } = item;
                    return (
                      <div>
                        {tempDiffOdds !== undefined
                      && <span className="text-danger me-1">{tempDiffOdds}</span>}
                        <Input className={`${singleChange && 'singleChange'} ${multiChange && 'multiChange'}`} value={diffOdds} onChange={({ target: { value: text } }) => onValueChange((text > -1 ? text || 0 : diffOdds), item.settingCode, item.oddsCode, `diffOdds${x}`)} />
                        <div className="text-success ms-1">
                          @
                          {odds ?? ''}
                        </div>
                      </div>
                    );
                  })}
                </Label>
              )),
            ]
          }
        />
        {billList && billList.length > 0 && (
          <div className="pt-3 text-center text-danger">
            当前用户已下注，【预设】赚赔设置将在明天开盘前生效。
          </div>
        )}
        <div className="d-flex justify-content-center align-items-center mt-3 py-3 w-100 border-top">
          <div className="operationButton handle mx-2" onClick={() => onClickSave()}>{t('SAVE')}</div>
          <div className="operationButton find mx-2" onClick={() => onClickReset()}>{t('RESET')}</div>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DefaultWinLost);