import { StatusCodes } from 'http-status-codes';

import { SET_BET_QUERY_DATA } from '../actionTypes';
import { apiService } from '../../../library';
import appActions from '../appActions';

const setBetQueryData = val => ({
  type: SET_BET_QUERY_DATA,
  value: val //
});

const GetExportBetsList = (str, calllback = () => { }) => dispatch => {
  apiService.get('/unusualBet/GetExportBetsList', str).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      return calllback(data.result);
    }
  }).catch(error => {
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};

const GetUnusualBetDetail = (calllback = () => { }) => dispatch => {
  apiService.get('/unusualBet/GetUnusualBetDetail').then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      return calllback(data.result);
    }
  }).catch(error => {
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};

const QueryBetingByCondiction = (obj, callback, catchCallback) => dispatch => {
  apiService.get('/UnusualBet/betQuery', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        dispatch(setBetQueryData(data.result));
        if (callback) {
          callback();
        }
      }
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg,
          showCancelButton: false
        }
      }));
    }
    dispatch(setBetQueryData({}));
    if (catchCallback) {
      catchCallback();
    }
    Promise.reject(error);
  });
};

const toUnusualBet = (data, callback) => dispatch => {
  apiService.post('/unusualBet/SetUnusualBet', data).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        dispatch(appActions.updateModalAlert({
          visible: false
        }));
        dispatch(appActions.updateModalAlert({
          type: 'success',
          visible: true,
          data: {
            message: data.msg,
            showCancelButton: false
          }
        }));
        if (callback) {
          callback();
        }
      }
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg,
          showCancelButton: false
        }
      }));
    }
    Promise.reject(error);
  });
};

const deleteUnusualBet = (data, callback) => dispatch => {
  apiService.post('/unusualBet/DeleteUnusualBet', data).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        dispatch(appActions.updateModalAlert({
          visible: false
        }));
        dispatch(appActions.updateModalAlert({
          type: 'success',
          visible: true,
          data: {
            message: data.msg,
            showCancelButton: false
          }
        }));
        if (callback) {
          callback();
        }
      }
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg,
          showCancelButton: false
        }
      }));
    }
    Promise.reject(error);
  });
};


export default {
  toUnusualBet,
  QueryBetingByCondiction,
  deleteUnusualBet,
  GetUnusualBetDetail,
  GetExportBetsList
};