
import { Col, Label, Row } from 'reactstrap';
import { Select } from 'antd';

import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

import moment from 'moment';

import { useTranslation } from 'react-i18next';

import IconRefresh from '../../../assets/image/icon_refresh.svg';

import { appActions, betWarningActions } from '../../../redux/actions';

import AgentDataTable from '../../AgentDataTable';
import Notifications from '../../Content/Notifications';

import './styles.scss';

const mapStateToProps = state => ({
  activeLottery: state.commonReducers.activeLottery,
  betWarningMonitorQueryData: state.betWarningReducers.betWarningMonitorQueryData,
  lotteryList: state.commonReducers.lotteryList,
});

const mapDispatchToProps = dispatch => ({
  QueryBetingWarningMonitor: (obj, callback, catchCallback) => dispatch(betWarningActions.QueryBetingWarningMonitor(obj, callback, catchCallback)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
});

const BetWarningMonitor = ({ lotteryList, betWarningMonitorQueryData, QueryBetingWarningMonitor }) => {
  const { t } = useTranslation(); // i18n
  const [_isQueryingDetail, setIsQueryingDetail] = useState([]); // loading
  const [_detailItem, setDetailItem] = useState([]); // table data
  const [_detailItemSum, setDetailItemSum] = useState([]); // table data sum
  const [_timevalue, setTimeValue] = useState('60');
  const [intervalValue, setIntervalValue] = useState('20');
  const countDownRef = useRef();
  const [_countDownTimer, setCountDownTimer] = useState('20');
  const [_lottery, setLottery] = useState(lotteryList[0]);
  const [_lotteryList, setLotterList] = useState([]); // 比lotterylist 多了sum信息
  const filterRef = useRef();

  const latestTimeOptions = [
    {
      value: '20', label: `20${t('SECOND')}`
    },
    {
      value: '40', label: `40${t('SECOND')}`
    },
    {
      value: '60', label: `60${t('SECOND')}`
    },
    {
      value: '120', label: `2${t('MINUTE')}`
    },
    {
      value: '300', label: `5${t('MINUTE')}`
    },
    {
      value: '600', label: `10${t('MINUTE')}`
    },
    {
      value: '1800', label: `30${t('MINUTE')}`
    },
    {
      value: '3600', label: `1${t('HOUR')}`
    },
    {
      value: '7200', label: `2${t('HOUR')}`
    },
  ];

  const autoUpdateTimeOptions = [
    {
      value: '-1', label: t('MANUAL')
    },
    {
      value: '10', label: `10${t('SECOND')}`
    },
    {
      value: '20', label: `20${t('SECOND')}`
    },
    {
      value: '25', label: `25${t('SECOND')}`
    },
    {
      value: '30', label: `30${t('SECOND')}`
    },
    {
      value: '40', label: `40${t('SECOND')}`
    },
    {
      value: '50', label: `50${t('SECOND')}`
    },
    {
      value: '60', label: `60${t('SECOND')}`
    },
    {
      value: '99', label: `99${t('SECOND')}`
    },
  ];

  useEffect(() => {
    setDetailItem([]);
    if (_countDownTimer !== '-1') {
      countDownRef.current = intervalValue;
      setCountDownTimer(intervalValue);
    }
    onQuery({
      LotteryType: _lottery.code
    });
  }, [_lottery]);

  useEffect(() => {
    if (!_lottery && lotteryList[0]) {
      setLottery(lotteryList[0]);
    }
  }, [lotteryList]);

  useEffect(() => {
    setLotterList(
      lotteryList.map(item => {
        const tmp = _detailItemSum.find(i => i.lotteryType === item.code);
        return {
          ...item,
          sumValue: (tmp && tmp.sum) || 0,
        };
      }).sort((a, b) => b.sumValue - a.sumValue)
    );
  }, [_detailItemSum]);

  useEffect(() => {
    if (intervalValue === '-1') {
      countDownRef.current = 0;
      setCountDownTimer('');
      return;
    }
    countDownRef.current = intervalValue;
    setCountDownTimer(countDownRef.current);
    const timer = setInterval(() => {
      // 刷新后，重新计时
      countDownRef.current -= 1;
      setCountDownTimer(countDownRef.current);
      if (countDownRef.current === 0) {
        onQuery({
          LotteryType: _lottery.code,
          Timeframe: _timevalue
        });
        countDownRef.current = intervalValue;
        setCountDownTimer(countDownRef.current);
      }
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [intervalValue, _lottery, _timevalue]);

  const tableHeaderTitle = [
    {
      label: t('ORDER_NUM'),
    },
    {
      label: t('PROJECT'),
    },
    {
      label: t('AMOUNT'),
    },
    {
      label: t('RENEW_TIME'),
    },
  ];
  const settingCodeArr = ['TMHX', 'ZXBZ', '2LX', '3LX', '4LX', '5LX', '2LXBZ',
    '3LXBZ', '4LXBZ', '5LXBZ', '2LW', '3LW', 'SLW', '2LWBZ',
    '3LWBZ', '4LWBZ', '3QZ', '3Z2', '2QZ', '2ZT', 'TC'];

  const _content = [
    (_, index) => (
      <Label>
        {
          index + 1
        }
      </Label>
    ),
    data => {
      if (settingCodeArr.includes(data.settingCode)) {
        return (
          <Label>
            {
              `${data.lotteryName}-${data.midType}`
            }
          </Label>
        );
      }
      if (['TMB', 'ZMB'].includes(data.settingCode)) {
        return (
          <Label>
            {
              `${data.lotteryName}-${data.midType?.slice(0, -1)}${data.settingCode.slice(data.settingCode.length - 1)}-${data.displayName}`
            }
          </Label>
        );
      }
      return (
        <Label>
          {
            `${data.lotteryName}-${data.midType}-${data.displayName}`
          }
        </Label>
      );
    },
    data => (
      <Label>
        {
          data.amount
        }
      </Label>
    ),
    data => (
      <Label className="px-1">
        {
          (data.firstWarnShares && moment.unix(data.firstWarnShares).format('HH:mm:ss')) || '-'
        }
      </Label>
    ),
  ];

  useEffect(() => {
    if (betWarningMonitorQueryData) {
      setDetailItem(betWarningMonitorQueryData.betWarn || []);
      setDetailItemSum(betWarningMonitorQueryData.zcSum || []);
    }
    setIsQueryingDetail(false);
  }, [betWarningMonitorQueryData]);

  const onQuery = (params = {}) => {
    setIsQueryingDetail(true);

    // 每次刷新，都主动重新计时
    countDownRef.current = intervalValue;
    setCountDownTimer(countDownRef.current);

    if (_lottery && _lottery.code) {
      QueryBetingWarningMonitor(
        {
          LotteryType: _lottery.code,
          Timeframe: _timevalue,
          ...params,
        },
        () => {
          setIsQueryingDetail(false);
        },
        () => {
          setIsQueryingDetail(false);
        }
      );
    }
  };

  const filteredDetailItem = property => _detailItem.filter(item => {
    if (!item[property]) return false;
    const updatedTime = item[property] || item.created;
    const time = new Date(updatedTime * 1000).getTime();
    const now = new Date().getTime();
    if ((now - time) <= _timevalue * 1000) return true;
    return false;
  });

  return (
    <div className="BetWarningMonitorWrapper">
      <div ref={filterRef}>
        <Col className="BetWarningMonitorFilterWrapper">
          <Col className="p-2 mb-2 filterWrapper rounded-top d-flex p-0 align-items-center">
            <div className="headerName">
              {t('BOGUS_BET_WARNING_MONITOR')}
            </div>
            <div className="d-flex p-0 align-items-center">
              <div className="d-flex p-0 align-items-center">
                <Label className="px-2">{t('SHOW_RECENT')}</Label>
                <Select
                  className="ms-2"
                  value={_timevalue}
                  placeholder={t('PLEASE_SELECT')}
                  onChange={val => setTimeValue(val)}
                  options={latestTimeOptions}
                />
                <div className="refreshButton mx-2" onClick={() => onQuery()}>{t('REFRESH')}</div>

                {
                  intervalValue !== '-1' && (
                    <div className="d-flex align-items-center ms-2 ml-2">
                      <img src={IconRefresh} alt="" className="refreshIcon" onClick={() => { }} />
                      <div className="countDownTimer">
                        {`${_countDownTimer < 10 ? `0${_countDownTimer}` : _countDownTimer} S`}
                      </div>
                    </div>
                  )
                }
                <Select
                  className="mx-2"
                  value={intervalValue}
                  onChange={val => setIntervalValue(val)}
                  options={autoUpdateTimeOptions}
                />
              </div>
              <Notifications />
            </div>

          </Col>
        </Col>
      </div>


      <Row className="mx-0 px-0 border-1">
        <div className="lotteryList">
          {
            _lotteryList.map(item => {
              const itemCode = item.code;
              return (
                <div
                  onClick={() => setLottery(item)}
                  className={`lotteryItem ${(itemCode === _lottery.code && 'active') || ''}`}
                >
                  <div>
                    {
                      item.lotteryName
                    }
                  </div>
                  <div className="mt-1">
                    {
                      item.sumValue
                    }
                  </div>
                </div>
              );
            })
          }
        </div>

      </Row>
      <Row className=" mt-2 mx-0 bg-white px-0 border-1">
        <Col className="p-0">
          <div className="mb-2">
            <div className="oddHeader ">
              {t('THE_FIRST_AMOUNT_OF_WARNING_VIRTUAL_GOODS')}
            </div>
            <AgentDataTable
              tableBodyRowStyle={
                ['backgroundColored']
              }
              isQuerying={_isQueryingDetail}
              tableHeaderTitle={tableHeaderTitle}
              tableData={filteredDetailItem('firstWarnTotal')}
              tableBodyRowProperty={[
                _content[0],
                _content[1],
                data => (
                  <Label>
                    {
                      data.xzAmount
                    }
                  </Label>
                ),
                data => (
                  <Label className="px-1">
                    {
                      (data.firstWarnTotal && moment.unix(data.firstWarnTotal).format('HH:mm:ss')) || '-'
                    }
                  </Label>
                ),
              ]}
              tableHeaderStyle={
                ['col-2']
              }
            />
          </div>
          <div className="oddHeader ">
            {t('THE_FIRST_AMOUNT_OF_WARNING_ACCOUNTED_FOR')}
          </div>
          <AgentDataTable
            tableBodyRowStyle={
              ['backgroundColored']
            }
            isQuerying={_isQueryingDetail}
            tableHeaderTitle={tableHeaderTitle}
            tableData={filteredDetailItem('firstWarnShares')}
            tableBodyRowProperty={[
              _content[0],
              _content[1],
              data => (
                <Label>
                  {
                    data.xzAmount
                  }
                </Label>
              ),
              data => (
                <Label className="px-1">
                  {
                    (data.firstWarnShares && moment.unix(data.firstWarnShares).format('HH:mm:ss')) || '-'
                  }
                </Label>
              ),
            ]}
            tableHeaderStyle={
              ['col-2']
            }
          />
        </Col>
        <Col className="">
          <div className="mb-2">
            <div className="oddHeader ">
              {t('THE_SECOND_AMOUNT_OF_WARNING_VIRTUAL_GOODS')}
            </div>
            <AgentDataTable
              tableBodyRowStyle={
                ['backgroundColored']
              }
              isQuerying={_isQueryingDetail}
              tableHeaderTitle={tableHeaderTitle}
              tableData={filteredDetailItem('loopWarnTotal')}
              tableBodyRowProperty={[
                _content[0],
                _content[1],
                data => (
                  <Label>
                    {
                      data.xzAmount
                    }
                  </Label>
                ),
                data => (
                  <Label className="px-1">
                    {
                      (data.loopWarnTotal && moment.unix(data.loopWarnTotal).format('HH:mm:ss')) || '-'
                    }
                  </Label>
                ),
              ]}
              tableHeaderStyle={
                ['col-2']
              }
            />
          </div>
          <div className="oddHeader ">
            {t('THE_SECOND_AMOUNT_OF_WARNING_ACCOUNTED_FOR')}
          </div>
          <AgentDataTable
            tableBodyRowStyle={
              ['backgroundColored']
            }
            isQuerying={_isQueryingDetail}
            tableHeaderTitle={tableHeaderTitle}
            tableData={filteredDetailItem('loopWarnShares')}
            tableBodyRowProperty={[
              _content[0],
              _content[1],
              data => (
                <Label>
                  {
                    data.amount
                  }
                </Label>
              ),
              data => (
                <Label className="px-1">
                  {
                    (data.loopWarnShares && moment.unix(data.loopWarnShares).format('HH:mm:ss')) || '-'
                  }
                </Label>
              ),
            ]}
            tableHeaderStyle={
              ['col-2']
            }
          />
        </Col>
        <Col className="p-0">
          <div className="oddHeader ">
            {t('SELECT_CATEGORY_AMOUNT_ALERT')}
          </div>
          <AgentDataTable
            tableBodyRowStyle={
              ['backgroundColored']
            }
            isQuerying={_isQueryingDetail}
            tableHeaderTitle={tableHeaderTitle}
            tableData={_detailItem}
            tableBodyRowProperty={_content}
            tableHeaderStyle={
              ['col-2']
            }
          />
        </Col>
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BetWarningMonitor);