import { SET_CLICK_RELOAD_FLAG, SET_MODAL_ALERT, SET_MODAL_MESSAGE, SET_THEME_COLOR, TOGGLE_HORIZONTAL } from '../actionTypes';

const updateHorizontal = () => ({
  type: TOGGLE_HORIZONTAL
});

const setModalAlert = modal => ({
  type: SET_MODAL_ALERT,
  value: modal
});

const setModalMessage = data => ({
  type: SET_MODAL_MESSAGE,
  value: data
});

const setThemeColor = data => ({
  type: SET_THEME_COLOR,
  value: data
});

const setClickReloadFlag = data => ({
  type: SET_CLICK_RELOAD_FLAG,
  value: data
});

const updateModalAlert = modal => dispatch => (
  dispatch(setModalAlert(modal))
);

const toggleHorizontal = () => dispatch => (
  dispatch(updateHorizontal())
);

const updateModalMessage = data => dispatch => dispatch(setModalMessage(data));

export default {
  toggleHorizontal,
  updateModalAlert,
  updateModalMessage,
  setThemeColor,
  setClickReloadFlag,
};