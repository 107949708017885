import { useEffect, useState } from 'react';

import { Checkbox, Input, Select } from 'antd';
import { Label } from 'reactstrap';
import { MdArrowBack } from 'react-icons/md';
import { PlusCircleOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { AgentDataTable, CommonHeader } from '../..';
import { companyActions } from '../../../redux/actions';

import './styles.scss';

const mapStateToProps = state => ({
  allCompanyList: state.commonReducers.allCompanyList,
  domainInfo: state.companyReducers.domainInfo,
  domainEditInfo: state.companyReducers.domainEditInfo,
});

const mapDispatchToProps = dispatch => ({
  DeleteDomainInfo: (id, callback) => dispatch(companyActions.DeleteDomainInfo(id, callback)),
  GetDomainInfo: (id, callback) => dispatch(companyActions.GetDomainInfo(id, callback)),
  SaveDomainInfo: (obj, callback) => dispatch(companyActions.SaveDomainInfo(obj, callback)),
  getEditDomainInfo: (obj, callback) => dispatch(companyActions.getEditDomainInfo(obj, callback)),
});

const { Option } = Select;

const CompanyDomainSetting = ({ data, SaveDomainInfo, getEditDomainInfo, GetDomainInfo, domainEditInfo, domainInfo, allCompanyList, DeleteDomainInfo }) => {
  const { t } = useTranslation();
  const [_isQuerying, setIsQuerying] = useState(false);
  const [_pageFlag, setPageFlag] = useState(0);
  const [_list, setList] = useState();
  const [_checkAll, setCheckAll] = useState(false);
  const [companyId, setcompanyId] = useState(data ? data?.orgId : allCompanyList && allCompanyList?.length > 0 ? allCompanyList[0].companyId : '');
  const [id, setid] = useState('');
  const [name, setname] = useState(data ? data[0]?.company?.name : allCompanyList && allCompanyList?.length > 0 ? allCompanyList[0].name : '');
  const [defaultAdmin, setdefaultAdmin] = useState(data ? data[0]?.company?.defaultAdmin : allCompanyList && allCompanyList?.length > 0 ? allCompanyList[0].defaultAdmin : '');

  const [webType, setwebType] = useState(0);
  const [domain, setdomain] = useState('');
  const [domains, setdomains] = useState(['']);

  useEffect(() => {
    if (companyId) {
      setIsQuerying(true);
      GetDomainInfo(companyId, () => setIsQuerying(false));
    }
  }, [companyId]);

  useEffect(() => {
    if (_checkAll && domainInfo) {
      const _ls = domainInfo.domainList.map(item => item.id);
      setname(domainInfo?.company?.name);
      setdefaultAdmin(domainInfo?.company?.defaultAdmin);
      setList([..._ls]);
    } else {
      setname(domainInfo?.company?.name);
      setdefaultAdmin(domainInfo?.company?.defaultAdmin);
      setList([]);
    }
  }, [_checkAll, domainInfo]);

  useEffect(() => {
    if (_pageFlag === 1 || _pageFlag === 0) {
      setid('');
      setwebType(0);
      setdomain('');
      setdomains(['']);
    }
    if (_pageFlag === 2 && domainEditInfo) {
      const { domain } = domainEditInfo;
      setid(domain?.id ?? '');
      setwebType(domain?.webType ?? 0);
      setdomain(domain?.domain ?? '');
      setname(domainEditInfo?.company?.name);
      setdefaultAdmin(domainEditInfo?.company?.defaultAdmin);
    }
  }, [_pageFlag, domainEditInfo]);

  useEffect(() => {
    if (allCompanyList && companyId) {
      const temp = [].concat(allCompanyList).find(x => x?.companyId === companyId);
      setname(temp.name);
      setdefaultAdmin(temp.defaultAdmin);
    }
  }, [companyId]);

  const saveAndEdit = () => {
    if (_pageFlag === 1 || _pageFlag === 2) {
      const obj = {
        id,
        companyId,
        webType,
        category: 1 // 1:用户端 2：服务器
      };
      const callback = () => {
        setPageFlag(0);
        setIsQuerying(true);
        GetDomainInfo(companyId, () => setIsQuerying(false));
      };
      SaveDomainInfo((_pageFlag === 1 ? {
        ...obj, domains: domains.filter(x => x.trim() !== '')
      } : {
        ...obj, domain
      }), callback);
    }
  };

  const deleteDomain = id => {
    const callback = () => {
      setIsQuerying(true);
      GetDomainInfo(companyId, () => setIsQuerying(false));
    };
    if (id) {
      DeleteDomainInfo(id, callback);
    } else {
      DeleteDomainInfo(_list.toString(), callback);
    }
  };

  return (
    <div className="CompanyDomainSettingWrapper">
      {!data
      && (
      <CommonHeader
        headerName={(
          <div className="d-flex align-items-center">
            {_pageFlag !== 0 && (
            <div className="d-flex align-items-center me-2 backIcon" onClick={() => setPageFlag(0)}>
              <MdArrowBack className="h5 mb-0 text-white" />
              返回
            </div>
            )}
            {`${t('COMPANY_HOSTNAME_SETTING')} ${_pageFlag === 0 ? '' : _pageFlag === 1 ? `/ ${defaultAdmin} / 新增` : `/ ${defaultAdmin} / 编辑`}`}
          </div>
        )}
      />
      )}
      {_pageFlag === 0 && (
      <>
        <div className="addNewGameBox my-3">
          <div className="d-flex align-items-center">
            <div
              className={`domainoperationBtn add d-flex align-items-center ${!companyId && 'noCompanyStyle'}`}
              onClick={() => {
                if (companyId) {
                  setPageFlag(1);
                }
              }}
            >
              <PlusCircleOutlined />
              <span className="ms-2">{`新增${data?.levelName || '公司'}域名`}</span>
            </div>
            <div className="mx-2">
              域名管理
              {!data
                ? (
                  <Select className="ms-2 dropdownSelectStyle" value={companyId} placeholder="请选择" onChange={code => setcompanyId(code)}>
                    {
                     allCompanyList && [].concat(allCompanyList).map(el => <Option key={el.companyId} value={el.companyId}>{`${el.name}(${el.defaultAdmin})`}</Option>)
                }
                  </Select>
                )
                : (
                  <Select className="ms-2 dropdownSelectStyle" value={companyId} placeholder="请选择" onChange={code => setcompanyId(code)}>
                    {
                   domainInfo && [].concat(domainInfo?.company).map(el => <Option key={el.companyId} value={data?.orgId}>{`${el.name}(${el.defaultAdmin})`}</Option>)
              }
                  </Select>
                )}
            </div>
          </div>
          <div className="domainoperationBtn add" onClick={() => deleteDomain()}>批量删除</div>
        </div>

        <div className="mx-0 bg-white mb-3 px-0 border-1">
          <AgentDataTable
            isQuerying={_isQuerying}
            tableHeaderTitle={
            [{
              label: (
                (<Checkbox onChange={({ target: { checked } }) => setCheckAll(checked)} checked={_checkAll}>全选</Checkbox>)
              ),
            }, {
              label: `${data?.levelName || '公司'}名称`,
            }, {
              label: `${data?.levelName || '公司'}账号`,
            }, {
              label: '站点类型',
            }, {
              label: `${data?.levelName || '公司'}域名`,
            }, {
              label: '创建时间',
            }, {
              label: t('OPERATION'),
            }]
          }
            tableHeaderStyle={
            []
          }
            tableData={(domainInfo) ? domainInfo?.domainList : []}
            tableBodyRowProperty={
            [
              data => (
                <Label className="d-flex justify-content-center">
                  <Checkbox
                    onChange={() => {
                      if (_list.includes(data.id)) {
                        setList(_list.filter(item => item !== data.id));
                      } else {
                        setList([..._list, data.id]);
                      }
                    }}
                    checked={_list.includes(data.id)}
                  />
                </Label>
              ),
              () => (<Label className="d-flex justify-content-center">{domainInfo ? domainInfo.company.name : ''}</Label>),
              () => (<Label className="d-flex justify-content-center">{domainInfo ? domainInfo.company.defaultAdmin : ''}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.webType === 1 ? '会员' : '代理'}</Label>),

              data => (<Label className="d-flex justify-content-center">{data.domain}</Label>),
              data => (<Label className="d-flex justify-content-center">{moment.unix(data.createDate).format('YYYY-MM-DD HH:mm:ss')}</Label>),
              data => (
                <Label className="d-flex justify-content-center align-items-center">
                  <div
                    className="domainoperationBtn mx-2"
                    onClick={() => {
                      getEditDomainInfo({
                        companyId, domainId: data.id
                      }, () => setPageFlag(2));
                    }}
                  >
                    编辑
                  </div>
                  <div className="domainoperationBtn cancel mx-2" onClick={() => { deleteDomain(data.id); }}>删除</div>
                </Label>
              )
            ]
          }
          />
        </div>
      </>
      )}
      {_pageFlag !== 0 && (
        <>
          <div className="my-3 editAndAddTable">
            <div className="thirdPartyGamesTitle">{_pageFlag === 1 ? `新增${data?.levelName || '公司'}域名` : `编辑${data?.levelName || '公司'}域名`}</div>
            <div className="thirdPartyGamesRow">
              <div>{`${data?.levelName || '公司'}名称`}</div>
              <div><Input disabled value={name} /></div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>账号</div>
              <div><Input disabled value={defaultAdmin} /></div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>站点</div>
              <div>
                <Select className="dropdownSelectStyle" value={webType} placeholder="请选择" onChange={code => setwebType(code)}>
                  {
                     [{
                       value: 0, label: '代理端'
                     }, {
                       value: 1, label: '会员端'
                     }].map(el => (
                       <Option key={el.value} value={el.value}>{el.label}</Option>
                     ))
                }
                </Select>
              </div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>域名</div>
              {_pageFlag === 2
                ? <div><Input value={domain} onChange={({ target: { value } }) => setdomain(value.trim())} placeholder="例如：hao123.com(不能带http://或https://,不能“/“结尾)" /></div>
                : (
                  <div className="d-block">
                    {
                    domains.map((item, index) => (
                      <div className="d-flex my-2">
                        <Input
                          value={item}
                          onChange={({ target: { value } }) => {
                            setdomains(domains.map((x, i) => (i === index ? value.trim() : x)));
                          }}
                          placeholder="例如：hao123.com(不能带http://或https://,不能“/“结尾)"
                        />
                        {
                          index === 0
                            ? (
                              <div
                                className="domainoperationBtn mx-2"
                                onClick={() => {
                                  const temp = [].concat(domains, Array(5).fill(''));
                                  setdomains(temp);
                                }}
                              >
                                添加
                              </div>
                            )
                            : (
                              <div
                                className="domainoperationBtn cancel mx-2"
                                onClick={() => {
                                  const temp = [].concat(domains.slice(0, -1));
                                  setdomains(temp);
                                }}
                              >
                                删除
                              </div>
                            )
                        }

                      </div>
                    ))
                  }
                  </div>
                )}
            </div>
          </div>
          <div className="thirdPartyGamesFooter">
            <div className="domainoperationBtn editAndSave me-3" onClick={() => saveAndEdit()}>保存</div>
            {data && <div className="domainoperationBtn cancel" onClick={() => setPageFlag(0)}>返回</div>}
          </div>
        </>
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDomainSetting);