import { StatusCodes } from 'http-status-codes';

import { SET_BIG_BET_LIMIT_QUERY_DATA } from '../actionTypes';
import { apiService } from '../../../library';
import appActions from '../appActions';


const setBetQueryData = val => ({
  type: SET_BIG_BET_LIMIT_QUERY_DATA,
  value: val //
});

const QueryBigBetLimit = (obj, callback, catchCallback) => dispatch => {
  apiService.get('/BigBetLimit/GetInfo', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        dispatch(setBetQueryData(data.result));
        if (callback) {
          callback();
        }
      }
    }
  }).catch(error => {
    if (catchCallback) {
      catchCallback();
    }
    Promise.reject(error);
  });
};

const SaveBigBetLimitSetting = (obj, callback) => dispatch => {
  apiService.post('/BigBetLimit/SaveBigBetLimitSetting', {
    ...obj
  }).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        dispatch(appActions.updateModalAlert({
          visible: false
        }));
        dispatch(appActions.updateModalAlert({
          type: 'success',
          visible: true,
          data: {
            message: data.msg,
            showCancelButton: false
          }
        }));
        if (callback) {
          callback();
        }
      }
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};

export default {
  QueryBigBetLimit,
  SaveBigBetLimitSetting,
};