
import { Button, Col, Input, Label, Row } from 'reactstrap';
import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

import { useTranslation } from 'react-i18next';

import { appActions, betWarningActions } from '../../../redux/actions';

import AgentDataTable from '../../AgentDataTable';
import { CommonHeader } from '../..';

import './styles.scss';

const mapStateToProps = state => ({
  activeLottery: state.commonReducers.activeLottery,
  setReminderAmountQueryData: state.betWarningReducers.setReminderAmountQueryData,
  lotteryList: state.commonReducers.lotteryList,
});

const mapDispatchToProps = dispatch => ({
  SaveFastBetWarnSetting: (obj, callback) => dispatch(betWarningActions.SaveFastBetWarnSetting(obj, callback)),
  SaveBetWarnSetting: (obj, callback) => dispatch(betWarningActions.SaveBetWarnSetting(obj, callback)),
  QuerySetReminderAmount: obj => dispatch(betWarningActions.QuerySetReminderAmount(obj)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
});

const SetReminderAmount = ({ SaveFastBetWarnSetting, SaveBetWarnSetting, lotteryList, setReminderAmountQueryData, QuerySetReminderAmount }) => {
  const { t } = useTranslation(); // i18n
  const [_isQueryingDetail, setIsQueryingDetail] = useState([]); // loading
  const [_detailItem, setDetailItem] = useState([]); // table data
  const [_detailItemSum, setDetailItemSum] = useState([]); // table data sum
  const [_lottery, setLottery] = useState('');
  const [_lotteryList, setLotterList] = useState([]);
  const filterRef = useRef();
  const [fastData, setFastData] = useState({
    totalFirstWarnAmount: 0,
    totalSubWarnAmount: 0,
    shareFirstWarnAmount: 0,
    shareSubWarnAmount: 0
  });

  useEffect(() => {
    setDetailItem([]);
    setDetailItemSum([]);
    setFastData({
      totalFirstWarnAmount: 0,
      totalSubWarnAmount: 0,
      shareFirstWarnAmount: 0,
      shareSubWarnAmount: 0
    });
    onQuery();
  }, [_lottery]);

  const onSave = () => {
    const settings = _detailItem.filter(i => i.edited).map(item => {
      const { id } = item;
      return {
        id,
        code: item.code,
        lotteryType: item.lotteryType,
        totalFirstWarnAmount: item.totalFirstWarnAmount,
        totalSubWarnAmount: item.totalSubWarnAmount,
        shareFirstWarnAmount: item.shareFirstWarnAmount,
        shareSubWarnAmount: item.shareSubWarnAmount
      };
    });

    SaveBetWarnSetting(
      {
        settings
      },
      onQuery
    );
  };

  const onSaveFast = () => {
    const item = fastData;
    SaveFastBetWarnSetting(
      {
        lotteryType: _lottery.code,
        totalFirstWarnAmount: item.totalFirstWarnAmount,
        totalSubWarnAmount: item.totalSubWarnAmount,
        shareFirstWarnAmount: item.shareFirstWarnAmount,
        shareSubWarnAmount: item.shareSubWarnAmount
      },
      onQuery
    );
  };

  useEffect(() => {
    if (!_lottery && lotteryList[0]) {
      setLottery(lotteryList[0]);
    }
  }, [lotteryList]);

  useEffect(() => {
    if (!_lottery && lotteryList[0]) {
      setLottery(lotteryList[0]);
    }
    setLotterList(lotteryList.map(item => {
      const tmp = _detailItemSum.find(i => i.lotteryType === item.code);
      return {
        ...item,
        sumValue: (tmp && tmp.sum) || 0,
      };
    }));
  }, [_detailItemSum]);

  const tableHeaderTitle = [
    {
      label: (
        <Label className="red">
          {t('THE_FIRST_AMOUNT_OF_WARNING')}
        </Label>
      ),
      colSpan: 1
    },
    {
      label: (
        <Label className="red">
          {t('INCREASED_AMOUNT_OF_WARNING')}
        </Label>
      ),
      colSpan: 1
    },
    {
      label: (
        <Label className="red">
          {t('THE_FIRST_AMOUNT_OF_WARNING')}
        </Label>
      ),
      colSpan: 1
    },
    {
      label: (
        <Label className="red">
          {t('INCREASED_AMOUNT_OF_WARNING')}
        </Label>
      ),
      colSpan: 1
    },
  ];

  const _content = [
    data => {
      if (!data.id) {
        return (
          <div className="hidden d-flex align-items-center justify-content-center">
            <Input
              type="number"
              className="mx-2 px-1 align-items-center"
            />
          </div>
        );
      }
      return (
        <Label>
          {
            data.projectName
          }
        </Label>
      );
    },
    data => {
      if (data.id) {
        return (
          <div className="d-flex align-items-center justify-content-center">
            <Input
              type="number"
              className={`mx-2 px-1 align-items-center ${data.isChange_totalFirstWarnAmount && 'isChange'}`}
              value={data.totalFirstWarnAmount}
              onChange={e => {
                const selectedIndex = _detailItem.findIndex(i => i.id === data.id);
                if (selectedIndex !== -1) {
                  const newData = [
                    ..._detailItem.slice(0, selectedIndex),
                    {
                      ..._detailItem[selectedIndex],
                      totalFirstWarnAmount: parseInt(e.target.value, 10),
                      edited: true,
                      isChange_totalFirstWarnAmount: true,
                    },
                    ..._detailItem.slice(selectedIndex + 1)
                  ];
                  setDetailItem(newData);
                }
              }}
            />
          </div>
        );
      }
      return '';
    },
    data => {
      if (!data.id) return '';
      return (
        <div className="d-flex align-items-center justify-content-center">
          <Input
            type="number"
            className={`mx-2 px-1 align-items-center ${data.isChange_totalSubWarnAmount && 'isChange'}`}
            value={data.totalSubWarnAmount}
            onChange={e => {
              const selectedIndex = _detailItem.findIndex(i => i.id === data.id);
              if (selectedIndex !== -1) {
                const newData = [
                  ..._detailItem.slice(0, selectedIndex),
                  {
                    ..._detailItem[selectedIndex],
                    totalSubWarnAmount: parseInt(e.target.value, 10),
                    edited: true,
                    isChange_totalSubWarnAmount: true,
                  },
                  ..._detailItem.slice(selectedIndex + 1)
                ];
                setDetailItem(newData);
              }
            }}
          />
        </div>
      );
    },
    data => {
      if (!data.id) return '';
      return (
        <div className="d-flex align-items-center justify-content-center">
          <Input
            type="number"
            className={`mx-2 px-1 align-items-center ${data.isChange_shareFirstWarnAmount && 'isChange'}`}
            value={data.shareFirstWarnAmount}
            onChange={e => {
              const selectedIndex = _detailItem.findIndex(i => i.id === data.id);
              if (selectedIndex !== -1) {
                const newData = [
                  ..._detailItem.slice(0, selectedIndex),
                  {
                    ..._detailItem[selectedIndex],
                    shareFirstWarnAmount: parseInt(e.target.value, 10),
                    edited: true,
                    isChange_shareFirstWarnAmount: true,
                  },
                  ..._detailItem.slice(selectedIndex + 1)
                ];
                setDetailItem(newData);
              }
            }}
          />
        </div>
      );
    },
    data => {
      if (!data.id) return '';
      return (
        <div className="d-flex align-items-center justify-content-center">
          <Input
            type="number"
            className={`mx-2 px-1 align-items-center ${data.isChange_shareSubWarnAmount && 'isChange'}`}
            value={data.shareSubWarnAmount}
            onChange={e => {
              const selectedIndex = _detailItem.findIndex(i => i.id === data.id);
              if (selectedIndex !== -1) {
                const newData = [
                  ..._detailItem.slice(0, selectedIndex),
                  {
                    ..._detailItem[selectedIndex],
                    shareSubWarnAmount: parseInt(e.target.value, 10),
                    edited: true,
                    isChange_shareSubWarnAmount: true,
                  },
                  ..._detailItem.slice(selectedIndex + 1)
                ];
                setDetailItem(newData);
              }
            }}
          />
        </div>
      );
    },
  ];

  const transferEqual = data => {
    if (data.length < _detailItem.length / 2) {
      return [...data, {}];
    }
    return data;
  };

  useEffect(() => {
    if (setReminderAmountQueryData) {
      setDetailItem(setReminderAmountQueryData.betList || []);
      setDetailItemSum(setReminderAmountQueryData.zcSum || []);
    }
    setIsQueryingDetail(false);
  }, [setReminderAmountQueryData]);

  const onQuery = (params = {}) => {
    setIsQueryingDetail(true);

    if (_lottery && _lottery.code) {
      QuerySetReminderAmount({
        lotteryType: _lottery.code,
        ...params,
      });
    }
  };

  return (
    <div className="SetReminderAmountWrapper">
      <div ref={filterRef}>
        <CommonHeader headerName={t('SET_SUGGESTION_AMOUNT')} />
      </div>

      <Row className="mx-0 px-0 border-1">
        <div className="lotteryList">
          {
            _lotteryList.map(item => {
              const itemCode = item.code;
              return (
                <div
                  onClick={() => setLottery(item)}
                  className={`lotteryItem ${(itemCode === _lottery.code && 'active') || ''}`}
                >
                  <div>
                    {
                      item.lotteryName
                    }
                  </div>
                </div>
              );
            })
          }
        </div>
      </Row>

      <Row className=" mt-2 mx-0 bg-white px-0 border-1">
        <Col className="p-0">
          <AgentDataTable
            rowSpanHeader={[
              {
                label: t('TYPE'),
                rowSpan: 2,
              },
              {
                label: t('VIRTUAL_GOODS'),
                colSpan: 2
              },
              {
                label: t('ACCOUNTED_FOR'),
                colSpan: 2
              },
            ]}
            tableBodyRowStyle={
              ['backgroundColored']
            }
            isQuerying={_isQueryingDetail}
            tableHeaderTitle={tableHeaderTitle}
            tableData={transferEqual(_detailItem.slice(0, Math.floor(_detailItem.length / 2)))}
            tableBodyRowProperty={_content}
          />
        </Col>
        <Col className="p-0 marginLeft1">
          <AgentDataTable
            rowSpanHeader={
              [
                {
                  label: t('TYPE'),
                  rowSpan: 2
                },
                {
                  label: t('VIRTUAL_GOODS'),
                  colSpan: 2
                },
                {
                  label: t('ACCOUNTED_FOR'),
                  colSpan: 2
                },
              ]
            }
            tableBodyRowStyle={
              ['backgroundColored']
            }
            isQuerying={_isQueryingDetail}
            tableHeaderTitle={tableHeaderTitle}
            tableData={_detailItem.slice(Math.floor(_detailItem.length / 2))}
            tableBodyRowProperty={_content}
          />
        </Col>
      </Row>

      <Row>
        <Col className="d-flex justify-content-center py-2">
          <Button
            onClick={() => onSave()}
            className="button edit mx-2"
          >
            {t('EDIT')}
          </Button>
          <Button
            onClick={() => {
              setDetailItem(setReminderAmountQueryData.betList || []);
              setFastData({
                totalFirstWarnAmount: 0,
                totalSubWarnAmount: 0,
                shareFirstWarnAmount: 0,
                shareSubWarnAmount: 0
              });
            }}
            className="button reset mx-2"
          >
            {t('RESET')}
          </Button>
        </Col>
      </Row>

      <Row className="mx-0 bg-white px-0 border-1">
        <Col className="p-0">
          <div className="oddHeader ">
            {t('FAST_SETTING')}
          </div>

          <div className="d-flex">
            <Col className="p-0">
              <div className="oddHeader px-0 br-0">
                {t('VIRTUAL_GOODS')}
              </div>
            </Col>
            <Col className="p-0">
              <div className="oddHeader px-0">
                {t('ACCOUNTED_FOR')}
              </div>
            </Col>
          </div>

          <AgentDataTable
            isQuerying={_isQueryingDetail}
            tableHeaderTitle={tableHeaderTitle}
            tableData={[fastData]}
            tableBodyRowProperty={[
              data => (
                <div className="d-flex align-items-center justify-content-center">
                  <Input
                    type="number"
                    className="mx-2 px-1 align-items-center"
                    value={data.totalFirstWarnAmount}
                    onChange={e => setFastData({
                      ...fastData,
                      totalFirstWarnAmount: parseInt(e.target.value, 10)
                    })}
                  />
                </div>
              ),
              data => (
                <div className="d-flex align-items-center justify-content-center">
                  <Input
                    type="number"
                    className="mx-2 px-1 align-items-center"
                    value={data.totalSubWarnAmount}
                    onChange={e => setFastData({
                      ...fastData,
                      totalSubWarnAmount: parseInt(e.target.value, 10)
                    })}
                  />
                </div>
              ),
              data => (
                <div className="d-flex align-items-center justify-content-center">
                  <Input
                    type="number"
                    className="mx-2 px-1 align-items-center"
                    value={data.shareFirstWarnAmount}
                    onChange={e => setFastData({
                      ...fastData,
                      shareFirstWarnAmount: parseInt(e.target.value, 10)
                    })}
                  />
                </div>
              ),
              data => (
                <div className="d-flex align-items-center justify-content-center">
                  <Input
                    type="number"
                    className="mx-2 px-1 align-items-center"
                    value={data.shareSubWarnAmount}
                    onChange={e => setFastData({
                      ...fastData,
                      shareSubWarnAmount: parseInt(e.target.value, 10)
                    })}
                  />
                </div>
              ),
            ]}
          />
        </Col>

      </Row>

      <Row>
        <Col className="d-flex justify-content-center py-2">
          <Button
            onClick={() => {
              onSaveFast();
            }}
            className="button edit mx-2"
          >
            {t('CONFIRM')}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SetReminderAmount);