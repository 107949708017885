import { useEffect, useRef, useState } from 'react';

import { FaBell, FaBellSlash, FaClipboardList } from 'react-icons/fa';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { commonActions, noticeActions } from '../../../redux/actions';
import bell from '../../../assets/audio/bell.mp3';
import './styles.scss';

const mapStateToProps = state => ({
  notifications: state.noticeReducers.notifications,
  bellMuted: state.noticeReducers.bellMuted,
});

const mapDispatchToProps = dispatch => ({
  updateSelectedSubMenu: val => dispatch(commonActions.updateSelectedSubMenu(val)),
  setBell: val => dispatch(noticeActions.setBell(val)),
});

const CommonHeader = ({ notifications, updateSelectedSubMenu, bellMuted, setBell }) => {
  const [reminderOpend, setReminderStatus] = useState(false);
  const [ls, setLs] = useState([]);
  const history = useHistory();
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = () => {
      if (!bellMuted && ls.length > 0) {
        const audio = new Audio(bell);
        audio.load();
        audio.play();
      }
    };
  });

  useEffect(() => {
    const tick = () => {
      savedCallback.current();
    };
    const id = setInterval(tick, 10000);
    return () => {
      clearInterval(id);
    };
  }, []);

  useEffect(() => {
    if (notifications) {
      let ls = [];
      Object.entries(notifications).forEach(item => {
        if (item[1].length > 0) {
          ls = ls.concat([].concat(item[1].map(x => ({
            ...x, tab: item[0]
          }))));
        }
      });
      setLs(ls);
    }
  }, [notifications]);


  const goToNewPage = item => {
    if (item.tab === 'warning') {
      updateSelectedSubMenu('IM_HISTORY_TASK');
      history.push('/agent/individual-management');
    }
    if (item.tab === 'onlineReminder') {
      updateSelectedSubMenu('');
      history.push('/agent/online-statistics');
    }
    if (item.tab === 'resultError' || item.tab === 'resultTimeout') {
      updateSelectedSubMenu('IM_HISTORY_RESULT_MANAGEMENT');
      history.push('/agent/installment-management');
    }
  };

  return (
    <div className="d-flex NotificationWrapper reminderWrapper align-items-center justify-content-end">
      <div className="bellNoti align-items-center py-0 px-1 m-0" onClick={() => setBell(!bellMuted)}>
        {
          bellMuted ? <FaBellSlash className="ml-2" /> : <FaBell className="ml-2" />
        }
      </div>
      <div className="reminderWrapper">
        <div className={`bellNotiCircle mx-1 ${(ls && ls.length > 0) && 'blink'}`} onClick={() => setReminderStatus(!reminderOpend)}>
          <FaClipboardList className="ml-2" />
        </div>
        {reminderOpend && (
          <div className="reminderBox">
            <div className="reminderContent">
              <div className="reminderHeader">待办提醒</div>
              <div className="contentScrollArea">
                {
                (ls.length > 0) ? ls.map(item => (
                  <div className="reminderRow" onClick={() => goToNewPage(item)}>
                    <div>{`${item.name}:`}</div>
                    <div>{`${item.count}个`}</div>
                  </div>
                ))
                  : (
                    <div className="pt-5 d-flex justify-content-center align-items-center noDataStyle">
                      暂无代办记录！
                    </div>
                  )
              }
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CommonHeader);