

const qsZodiac = [
  {
    label: '鼠', values: ['12', '24', '36', '48']
  }, {
    label: '牛', values: ['11', '23', '35', '47']
  }, {
    label: '虎', values: ['10', '22', '34', '46']
  }, {
    label: '兔', values: ['09', '21', '33', '45']
  }, {
    label: '龙', values: ['08', '20', '32', '44']
  }, {
    label: '蛇', values: ['07', '19', '31', '43']
  }, {
    label: '马', values: ['06', '18', '30', '42']
  }, {
    label: '羊', values: ['05', '17', '29', '41']
  }, {
    label: '猴', values: ['04', '16', '28', '40']
  }, {
    label: '鸡', values: ['03', '15', '27', '39']
  }, {
    label: '狗', values: ['02', '14', '26', '38']
  }, {
    label: '猪', values: ['01', '13', '25', '37', '49']
  }
];

const zodiacArr = ['鼠', '牛', '虎', '兔', '龙', '蛇', '马', '羊', '猴', '鸡', '狗', '猪'];

const qsColors = [
  {
    label: '红单', className: 'qsRed', values: ['01', '07', '13', '19', '23', '29', '35', '45']
  }, {
    label: '红双', className: 'qsRed', values: ['02', '08', '12', '18', '24', '30', '34', '40', '46']
  }, {
    label: '红大', className: 'qsRed', values: ['29', '30', '34', '35', '40', '45', '46']
  }, {
    label: '红小', className: 'qsRed', values: ['01', '02', '07', '08', '12', '13', '18', '19', '23', '24']
  }, {
    label: '蓝单', className: 'qsBlue', values: ['03', '09', '15', '25', '31', '37', '41', '47']
  }, {
    label: '蓝双', className: 'qsBlue', values: ['04', '10', '14', '20', '26', '36', '42', '48']
  }, {
    label: '蓝大', className: 'qsBlue', values: ['25', '26', '31', '36', '37', '41', '42', '47', '48']
  }, {
    label: '蓝小', className: 'qsBlue', values: ['03', '04', '09', '10', '14', '15', '20']
  }, {
    label: '绿单', className: 'qsGreen', values: ['05', '11', '17', '21', '27', '33', '39', '43', '49', '49']
  }, {
    label: '绿双', className: 'qsGreen', values: ['06', '16', '22', '28', '32', '38', '44']
  }, {
    label: '绿大', className: 'qsGreen', values: ['27', '28', '32', '33', '38', '39', '43', '44', '49', '49']
  }, {
    label: '绿小', className: 'qsGreen', values: ['05', '06', '11', '16', '17', '21', '22']
  }, {
    label: '红波', className: 'qsRed', values: ['01', '02', '07', '08', '12', '13', '18', '19', '23', '24', '29', '30', '34', '35', '40', '45', '46']
  }, {
    label: '蓝波', className: 'qsBlue', values: ['03', '04', '09', '10', '14', '15', '20', '25', '26', '31', '36', '37', '41', '42', '47', '48']
  }, {
    label: '绿波', className: 'qsGreen', values: ['05', '06', '11', '16', '17', '21', '22', '27', '28', '32', '33', '38', '39', '43', '44', '49', '49']
  }
];

const qsDxds = [
  {
    label: '单号', values: ['01', '03', '05', '07', '09', '11', '13', '15', '17', '19', '21', '23', '25', '27', '29', '31', '33', '35', '37', '39', '41', '43', '45', '47', '49']
  }, {
    label: '双号', values: ['02', '04', '06', '08', '10', '12', '14', '16', '18', '20', '22', '24', '26', '28', '30', '32', '34', '36', '38', '40', '42', '44', '46', '48']
  }, {
    label: '大号', values: ['25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49']
  }, {
    label: '小号', values: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24']
  }, {
    label: '合单', values: ['01', '03', '05', '07', '09', '10', '12', '14', '16', '18', '21', '23', '25', '27', '29', '30', '32', '34', '36', '38', '41', '43', '45', '47', '49']
  }, {
    label: '合双', values: ['02', '04', '06', '08', '11', '13', '15', '17', '19', '20', '22', '24', '26', '28', '31', '33', '35', '37', '39', '40', '42', '44', '46', '48']
  }, {
    label: '合大', values: ['07', '08', '09', '16', '17', '18', '19', '25', '26', '27', '28', '29', '34', '35', '36', '37', '38', '39', '43', '44', '45', '46', '47', '48', '49']
  }, {
    label: '合小', values: ['01', '02', '03', '04', '05', '06', '10', '11', '12', '13', '14', '15', '20', '21', '22', '23', '24', '30', '31', '32', '33', '40', '41', '42']
  }, {
    label: '小单', values: ['01', '03', '05', '07', '09', '11', '13', '15', '17', '19', '21', '23']
  }, {
    label: '大单', values: ['25', '27', '29', '31', '33', '35', '37', '39', '41', '43', '45', '47', '49']
  }, {
    label: '小双', values: ['02', '04', '06', '08', '10', '12', '14', '16', '18', '20', '22', '24']
  }, {
    label: '大双', values: ['26', '28', '30', '32', '34', '36', '38', '40', '42', '44', '46', '48']
  }, {
    label: '尾大', values: ['05', '06', '07', '08', '09', '15', '16', '17', '18', '19', '25', '26', '27', '28', '29', '35', '36', '37', '38', '39', '45', '46', '47', '48', '49']
  }, {
    label: '尾小', values: ['01', '02', '03', '04', '10', '11', '12', '13', '14', '20', '21', '22', '23', '24', '30', '31', '32', '33', '34', '40', '41', '42', '43', '44']
  }
];

const qsHead = [
  {
    label: '0头', values: ['01', '02', '03', '04', '05', '06', '07', '08', '09']
  }, {
    label: '1头', values: ['10', '11', '12', '13', '14', '15', '16', '17', '18', '19']
  }, {
    label: '2头', values: ['20', '21', '22', '23', '24', '25', '26', '27', '28', '29']
  }, {
    label: '3头', values: ['30', '31', '32', '33', '34', '35', '36', '37', '38', '39']
  }, {
    label: '4头', values: ['40', '41', '42', '43', '44', '45', '46', '47', '48', '49']
  }
];

const qsTail = [
  {
    label: '0尾', values: ['10', '20', '30', '40']
  }, {
    label: '1尾', values: ['01', '11', '21', '31', '41']
  }, {
    label: '2尾', values: ['02', '12', '22', '32', '42']
  }, {
    label: '3尾', values: ['03', '13', '23', '33', '43']
  }, {
    label: '4尾', values: ['04', '14', '24', '34', '44']
  }, {
    label: '5尾', values: ['05', '15', '25', '35', '45']
  }, {
    label: '6尾', values: ['06', '16', '26', '36', '46']
  }, {
    label: '7尾', values: ['07', '17', '27', '37', '47']
  }, {
    label: '8尾', values: ['08', '18', '28', '38', '48']
  }, {
    label: '9尾', values: ['09', '19', '29', '39', '49']
  }
];


const qsAll = [
  {
    label: '全部', className: '', values: ['ALL']
  }];

const qsFiller = [
  {
    label: ''
  }];

const qsBigSmall = [
  {
    label: '大小', values: ['大', '小']
  }
];
const qsSingleDouble = [
  {
    label: '单双', values: ['单', '双']
  }
];
const qsDragonTiger = [
  {
    label: '龙虎', values: ['龙', '虎']
  }
];

const qsGYH = [
  {
    label: '冠亚军指定', values: ['3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19'], keyCode: 'GYJH'
  },
  {
    label: '冠亚大小', values: ['冠亚大', '冠亚小']
  },
  {
    label: '冠亚单双', values: ['冠亚单', '冠亚双']
  },
];

const qsCar1to10 = [
  {
    label: '1-10号车', values: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'], className: 'pk10Square'
  }
];

const qsTwoFace = [
  {
    label: '两面', values: ['大', '小', '单', '双', '龙', '虎', '尾大', '尾小', '合单', '合双']
  }
];

const qsDirection = [
  {
    label: '方位', values: ['东', '南', '西', '北']
  }
];

const qs5Element = [
  {
    label: '五行', values: ['金', '木', '水', '火', '土']
  }
];


const qsWhiteHairMid = [
  {
    label: '中发白', values: ['中', '发', '白']
  }
];

const qsBall1to20 = [
  {
    label: '1-8球号', values: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20'],
  }
];

const qsSumBigSmall = [
  {
    label: '总和大小', values: ['总和大', '总和小'],
  }
];

const qsSumSingleDouble = [
  {
    label: '总和单双', values: ['总和单', '总和双'],
  }
];

const qsSumTailBigSmall = [
  {
    label: '总和尾大小', values: ['总和尾大', '总和尾小'],
  }
];

const qsBJK8 = [
  {
    label: '总和', values: ['总和大', '总和小', '总和单', '总和双', '总和810'],
  },
  {
    label: '总和过关', values: ['总和大单', '总和大双', '总和小单', '总和小双'],
  },
  {
    label: '前后和', values: ['前(多)', '後(多)', '前後(和)'],
  },
  {
    label: '单双和', values: ['单(多)', '双(多)', '单双(和)'],
  },
];

const qsFANT = [
  {
    label: '正', values: ['1正', '2正', '3正', '4正'],
  },
  {
    label: '番', values: ['1番', '2番', '3番', '4番'],
  },
  {
    label: '角', values: ['1-2角', '2-3角', '3-4角', '1-4角'],
  },
  {
    label: '念', values: ['1念2', '1念3', '1念4', '2念1', '2念3', '2念4', '3念1', '3念2', '3念4', '4念1', '4念2', '4念3'],
  },
  {
    label: '中', values: ['123中', '124中', '134中', '234中'],
  },
];

const qsLianma = [
  {
    label: '连码', values: ['任选二', '任选三', '任选四', '任选五', '选二连组', '选三前组', '任选二中二', '任选三中三', '任选四中四', '任选五中五', '任选六中五', '任选七中五', '任选八中五', '前二组选', '前三组选'],
  }
];
const qsDirectChoose = [
  {
    label: '直选', values: ['前二直选', '前三直选']
  }
];

const qsBall1to5 = [
  {
    label: '1-5球号', values: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20'], keyCode: 'B'
  }
];
const qsYZY = [
  {
    label: '一中一', values: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'], keyCode: 'YZY'
  }
];

const qsTailBigSmall = [
  {
    label: '总尾大小', values: ['总尾大', '总尾小'],
  }
];
const qsThreeColor = [
  {
    label: '1-5色波', values: ['红', '绿', '蓝'],
  }
];
const qsFortune = [
  {
    label: '1-5福禄寿喜', values: ['福', '禄', '寿', '喜'],
  }
];

const qsK3 = [
  {
    label: '三军', keyCode: 'SJ',
  }, {
    label: '大小', keyCode: 'DX',
  }, {
    label: '长牌', keyCode: 'CP',
  }, {
    label: '短牌', keyCode: 'DP',
  }, {
    label: '点数', keyCode: 'DS',
  }, {
    label: '全骰', keyCode: 'QS',
  }, {
    label: '围骰', keyCode: 'WS',
  }
];

const qsSSCLM = [
  {
    label: '两面', values: ['大', '小', '单', '双', '总和单', '总和大', '总和小', '总和双']
  }, {
    label: '龙虎和', keyCode: 'H', values: ['龙', '虎', '和'],
  }, {
    label: '前三中三后三', values: ['豹子', '顺子', '对子', '半顺', '杂六']
  }
];

const qsPCDD = [
  {
    label: '大小', values: ['大', '小']
  }, {
    label: '单双', values: ['单', '双']
  }, {
    label: '和值', keyCode: 'HZ'
  }, {
    label: '极值大小', values: ['极小', '极大']
  }, {
    label: '大小单双', values: ['大单', '大双', '小双', '小单']
  }, {
    label: '色波', values: ['红波', '绿波', '蓝波'],
  }, {
    label: '特码包三', values: ['特码包三'],
  }, {
    label: '豹子', values: ['豹子']
  }
];

export {
  qsLianma,
  qsPCDD,
  qs5Element,
  qsK3,
  qsSSCLM,
  qsThreeColor,
  qsBJK8,
  qsBall1to5,
  qsYZY,
  qsFortune,
  qsDirectChoose,
  qsTailBigSmall,
  qsSumTailBigSmall,
  qsSumSingleDouble,
  qsSumBigSmall,
  qsBall1to20,
  qsWhiteHairMid,
  qsDirection,
  qsTwoFace,
  qsGYH,
  qsCar1to10,
  qsBigSmall,
  qsSingleDouble,
  qsZodiac,
  qsDxds,
  qsHead,
  qsColors,
  qsTail,
  qsAll,
  qsFiller,
  qsDragonTiger,
  qsFANT,
  zodiacArr
};