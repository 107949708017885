

import { connect } from 'react-redux';

import './styles.scss';

const mapStateToProps = state => ({
  inTimeSheetOdds: state.inTimeSheetReducers.inTimeSheetOdds,
});

const BuHuo = ({ obj, betItemStyle, _OddsListObj }) => (
  <div className={`${betItemStyle} buHuoWrapper`}>
    <div className="d-flex justify-content-center align-items-center flex-column">
      <span className="buhuoDone">{_OddsListObj && _OddsListObj[obj.code]?.enabled === 1 ? _OddsListObj[obj.code]?.buhuoedAmount : 0}</span>
      <span>{_OddsListObj && _OddsListObj[obj.code]?.enabled === 1 ? _OddsListObj[obj.code]?.buhuoAmount : 0 }</span>
    </div>
  </div>
);

export default connect(mapStateToProps)(BuHuo);