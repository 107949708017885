import { SET_BACKUPALL_INFO, SET_BACKUP_INFO } from '../../actions/actionTypes';

const initialState = {
  backUpInfo: undefined,
  backUpAllInfo: undefined // 系统设置 备份全部
};

const autoRestockSettingReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_BACKUPALL_INFO:
      return {
        ...state, backUpAllInfo: action.value
      };
    case SET_BACKUP_INFO:
      return {
        ...state, backUpInfo: action.value
      };
    default:
      return state;
  }
};

export default autoRestockSettingReducers;