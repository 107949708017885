/* eslint-disable react/no-danger */
import { useEffect, useState } from 'react';

import { Label } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';

import { AgentDataTable, Pagination } from '../..';
import { thirdPartyGamesActions } from '../../../redux/actions';
import './styles.scss';

const mapStateToProps = state => ({
  transactionManage: state.thirdPartyGamesReducers.transactionManage,
});

const mapDispatchToProps = dispatch => ({
  GetTransactionList: (obj, callback) => dispatch(thirdPartyGamesActions.GetTransactionList(obj, callback)),
});

const AccountFlow = ({ data, callback = () => {}, transactionManage, GetTransactionList }) => {
  const _date = [moment().subtract(5, 'years').startOf('day').toDate(), moment().endOf('day').toDate()];
  const [_isQueryingDetail, setIsQueryingDetail] = useState(false);
  const { mName } = data;
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(15);
  const mType = 1;

  useEffect(() => {
    const obj = {
      mName, mType, startTime: moment(_date[0]).unix(), endTime: moment(_date[1]).unix(), page, rows
    };
    GetTransactionList(obj, () => setIsQueryingDetail(false));
  }, []);

  const onPaginationClick = (pageNum, rows) => {
    setIsQueryingDetail(true);
    const obj = {
      mName, mType, startTime: moment(_date[0]).unix(), endTime: moment(_date[1]).unix(), page: pageNum, rows
    };
    GetTransactionList(obj, () => setIsQueryingDetail(false));
    setPage(pageNum);
    setRows(rows);
  };

  return (
    <div className="accountFlowWrapper">
      <div className="mx-0 bg-white mt-3">
        <AgentDataTable
          isQuerying={_isQueryingDetail}
          tableHeaderTitle={
  [{
    label: '序号',
  }, {
    label: '交易时间',
  }, {
    label: '交易前余额',
  }, {
    label: '交易金额',
  }, {
    label: '交易后余额',
  }, {
    label: '交易描述',
  }, {
    label: '订单详情',
  }]
              }
          tableHeaderStyle={['noWrapStyle', 'noWrapStyle', 'noWrapStyle', 'noWrapStyle', 'noWrapStyle', 'noWrapStyle', 'noWrapStyle']}
          tableData={transactionManage ? transactionManage.list : []}
          tableBodyRowProperty={
 [
   data => <Label className="d-flex justify-content-center">{data.no}</Label>,
   data => <Label className="d-flex justify-content-center">{data.createTime}</Label>,
   data => <Label className="d-flex justify-content-center">{data.beforeAmount}</Label>,
   data => (<Label className="d-flex justify-content-center">{data.actionAmount}</Label>),
   data => (<Label className="d-flex justify-content-center">{data.afterAmount}</Label>),
   data => (<Label className="d-flex justify-content-center">{data.createRemark}</Label>),
   data => (
     <Label className="d-flex justify-content-center align-items-center">
       <div className="operationBtn" onClick={() => callback(data)}>详情</div>
     </Label>
   ),
 ]
              }
        />
      </div>
      <div className="justify-center w-full relative">
        <Pagination totalRecord={transactionManage ? transactionManage.sum.totalRecord : 0} onClick={(pageNum, rows) => onPaginationClick(pageNum, rows)} isQuery={false} />
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountFlow);