import { useEffect } from 'react';

import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import enUS from 'antd/es/locale/en_US';
import zhCN from 'antd/es/locale/zh_CN';

// Web Layout
import AuthLayout from './containers/Auth/AuthLayout';
import { authWebRoutes } from './routes';

// Web
import Login from './pages/Login';
import NoMatch from './pages/NoMatch';
import { appActions } from './redux/actions';

import 'moment/locale/zh-cn';

const mapStateToProps = state => ({
  userData: state.memberReducers.userData,
  themeColor: state.appReducers.themeColor,
});

const mapDispatchToProps = dispatch => ({
  setThemeColor: data => dispatch(appActions.setThemeColor(data)),
});

const App = ({ userData, setThemeColor, themeColor }) => {
  const { i18n } = useTranslation();
  useEffect(() => {
    const temp = localStorage.getItem('themeColor');
    if (temp) {
      setThemeColor(temp);
    } else {
      localStorage.setItem('themeColor', themeColor);
    }
  }, [themeColor]);

  const detectPlatform = () => {
    const sUserAgent = navigator.userAgent.toLowerCase();
    const bIsIpad = sUserAgent.match(/ipad/i) !== null;
    const bIsIphoneOs = sUserAgent.match(/iphone os/i) !== null;
    const bIsMidp = sUserAgent.match(/midp/i) !== null;
    const bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) !== null;
    const bIsUc = sUserAgent.match(/ucweb/i) !== null;
    const bIsAndroid = sUserAgent.match(/android/i) !== null;
    const bIsCE = sUserAgent.match(/windows ce/i) !== null;
    const bIsWM = sUserAgent.match(/windows mobile/i) !== null;
    if ((bIsIpad || bIsIphoneOs || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE || bIsWM)) {
      return 'Mobile';
    }
    return 'Web';
  };

  // console.log(detectPlatform());


  return (
    <ConfigProvider locale={i18n.language === 'ZH' ? zhCN : enUS}>
      <BrowserRouter>
        <Switch>
          <Route path="/" exact name="Login" render={props => <Login platform={detectPlatform()} {...props} />} />
          {
            userData && !userData?.checkPassword
              ? authWebRoutes.filter(ele => ([].concat(userData?.status === 2 ? userData.userMenu.filter(x => (x.id === '3' || x.id === '6' || x.id === '8')) : userData.userMenu).find(x => x.id === ele.id))).concat([{
                path: '/logout', name: 'Logout'
              }]).map(item => <Route path={`/agent${item.path}`} exact name={item.name} render={props => <AuthLayout {...props} />} />)
              : ''
          }
          {
            (userData && userData.levelValue === 0) && <Route path="/agent/super-user-list" exact name="Super User List" render={props => <AuthLayout {...props} />} />
          }
          <Route
            path="*"
            name="Not Found"
            render={props => (!userData ? (
              <Redirect to={{
                pathname: '/',
              }}
              />
            ) : <NoMatch {...props} />)}
          />
        </Switch>
      </BrowserRouter>
    </ConfigProvider>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(App);