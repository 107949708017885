import { SET_BATCH_DEFAULT_ODDS, SET_DEFAULT_ODDS } from '../../actions/actionTypes';

const initialState = {
  batchDefaultOddsList: undefined,
  defaultOddsData: undefined,
};

const defaultOddsReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_BATCH_DEFAULT_ODDS:
      return {
        ...state, batchDefaultOddsList: action.value
      };
    case SET_DEFAULT_ODDS:
      return {
        ...state, defaultOddsData: action.value
      };
    default:
      return state;
  }
};

export default defaultOddsReducers;