/* eslint-disable indent */
import { useEffect, useState } from 'react';

import { Button, Col, Input, Label, Row } from 'reactstrap';
import { DatePicker, Popover, Select } from 'antd';
import { FaCircle, FaEdit, FaPlusCircle } from 'react-icons/fa';
import { connect } from 'react-redux';

import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { AccountStatus, OrderBySelection, OrderSelection, UserSearchBy } from '../../../constants/SelectionItems';
import AgentDataTable from '../../AgentDataTable';
import Pagination from '../../Content/Pagination';

import { agentActions, appActions } from '../../../redux/actions';
import './styles.scss';

import MemberList from '../MemberList';
import Notifications from '../../Content/Notifications';

const mapStateToProps = state => ({
  activeLottery: state.commonReducers.activeLottery,
  agentListData: state.agentReducers.agentListData,
  userData: state.memberReducers.userData,
  userInfo: state.memberReducers.userInfo,
});

const mapDispatchToProps = dispatch => ({
  getAgentList: (agentId, levelValue, orderBy, order, page, rows, startDate, endDate, isChild, loginId, status, searchBy, minAmount, maxAmount) => dispatch(agentActions.getAgentList(agentId, levelValue, orderBy, order, page, rows, startDate, endDate, isChild, loginId, status, searchBy, minAmount, maxAmount)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  setAccountStatus: (obj, searchObj) => dispatch(agentActions.setAccountStatus(obj, searchObj)),
  setDefaultAgent: (obj, searchObj) => dispatch(agentActions.setDefaultAgent(obj, searchObj)),
  setDefaultVisitorAgent: (obj, searchObj) => dispatch(agentActions.setDefaultVisitorAgent(obj, searchObj)),
  getLoginAs: (obj, callback) => dispatch(agentActions.getLoginAs(obj, callback)),
});

const { RangePicker } = DatePicker;

const AgentList = ({ getLoginAs, userInfo, agentListData, getAgentList, userData, updateModalAlert, agentType, userLevel, setAccountStatus, setDefaultAgent, setDefaultVisitorAgent, callback = () => {}, breadcrumb }) => {
  const { t } = useTranslation();
  const [_searchText, setSearchText] = useState('');
  const [_date, setDate] = useState([]);
  const [_detailItem, setDetailItem] = useState([]);
  const [_detailItemSum, setDetailItemSum] = useState([]);
  const [_selectedStatus, setSelectedStatus] = useState('');
  const [_activeId, setActiveId] = useState();
  const [_isUpdateStatus, setIsUpdateStatus] = useState(false);
  const [_balanceLessThan, setBalanceLessThan] = useState('');
  const [_balanceMoreThan, setBalanceMoreThan] = useState('');
  const [_selectedOrderBy, setSelectedOrderBy] = useState('CreateDate');
  const [_selectedOrderType, setSelectedOrderType] = useState('DESC');
  const [_selectedSearchBy, setSelectedSearchBy] = useState('loginId');
  const [_isQueryingDetail, setIsQueryingDetail] = useState(true);
  const [_header, setHeader] = useState([]);
  const [_content, setContent] = useState([]);
  const [_isMemRedirect, setIsMemRedirect] = useState(false);
  const [_breadcrumb, setBreadcrumb] = useState([]);
  const lowLevelAgentItems = [0, 1, 2, 3, 4, 5, 7, 8, 9, 10];
  const [_page, setPage] = useState(1);
  const [_rows, setRows] = useState(15);

  useEffect(() => {
    updateModalAlert({
      visible: false,
    });
    setIsQueryingDetail(true);
    // Default search when first load
    if (!breadcrumb) {
    getAgentList(
userData?.id || '',
      userLevel,
      'CreateDate',
      'DESC',
      _page,
      _rows,
      '',
      '',
      agentType
);

      const bc = [..._breadcrumb];
      bc.push({
        label: '首页',
        selectedMemberId: userData?.id,
        selectedUserLevel: userLevel,
        selectedAgentType: agentType,
      });
      setBreadcrumb(bc);
    }
  }, []);

  useEffect(() => {
    if (breadcrumb && _breadcrumb?.length === 0) {
      setBreadcrumb(breadcrumb);
      if (!breadcrumb[breadcrumb.length - 1]?.type) {
        getAgentList(
          breadcrumb?.length > 1 ? breadcrumb[breadcrumb.length - 1]?.selectedMemberId : userData?.id || '',
          breadcrumb?.length > 1 ? breadcrumb[breadcrumb.length - 1]?.selectedUserLevel : userLevel || '',
          'CreateDate',
          'DESC',
          _page,
          _rows,
          '',
          '',
          breadcrumb?.length > 1 ? breadcrumb[breadcrumb.length - 1]?.selectedAgentType : agentType || '',
        );
      } else if (breadcrumb[breadcrumb.length - 1]?.type) {
        setIsMemRedirect({
          isDirect: true,
          agentType: breadcrumb[breadcrumb.length - 1]?.type,
          data: breadcrumb[breadcrumb.length - 1]?.data
        });
      }
    }
  }, [breadcrumb]);

  useEffect(() => {
    if (agentListData?.total) {
      if (_detailItemSum) {
        if (_isUpdateStatus) {
            let temp = _detailItem;
            agentListData?.list.forEach(curr => {
              if (curr.isDefaultValue === 1) {
                temp = _detailItem.map(item => ({
                  ...item, isDefaultValue: 0
                }));
              }
              const index = _detailItem.findIndex(item => item.orgId === curr.orgId);
              if (index !== -1) {
                temp[index].accountStatus = curr.accountStatus;
                temp[index].isVisitorUseValue = curr.isVisitorUseValue;
                temp[index].isDefaultValue = curr.isDefaultValue;
                setIsUpdateStatus(false);
              }
            });
            setDetailItem(temp);
        } else {
          setDetailItem(agentListData?.list);
          setDetailItemSum(agentListData.total);
          }
        setIsQueryingDetail(false);
      } else if (JSON.stringify(_detailItemSum) !== JSON.stringify(agentListData?.total)) {
        setDetailItem(agentListData?.list);
        setDetailItemSum(agentListData.total);
      }
  }
      // Hide most of the detail for low level agent
      if (_detailItem && Number(userData?.levelValue) !== 1) {
        const tempHead = [];
        const tempContent = [];
        lowLevelAgentItems.forEach(d => {
          tempHead.push(tableHeader[d]);
          tempContent.push(tableContent[d]);
        });
        setHeader(tempHead);
        setContent(tempContent);
      } else if (Number(userData?.isChild) === 1) {
        setHeader(tableHeader.slice(0, tableHeader.length - 2));
        setContent(tableContent.slice(0, tableContent.length - 2));
      } else {
        setHeader(tableHeader);
        setContent(tableContent);
      }
      if (!agentListData) {
        setIsQueryingDetail(false);
      }
  }, [agentListData]);

  const onSearchClick = () => {
    setIsQueryingDetail(true);
    getAgentList(
      userData?.id || '',
      userLevel,
      _selectedOrderBy,
      _selectedOrderType,
      1,
      _rows,
      (_date?.length === 0 ? '' : moment(_date[0])?.unix()),
      (_date?.length === 0 ? '' : moment(_date[1])?.unix()),
      agentType,
      _searchText,
      _selectedStatus,
      _selectedSearchBy,
      _balanceMoreThan,
      _balanceLessThan,
    );
  };

  const onButtonClick = (selectedMemberId, selectedAgentType, selectedUserLevel, loginId) => {
    setIsQueryingDetail(true);
    const bc = [..._breadcrumb];
    bc.push({
      label: `代理-${loginId}`,
      value: loginId,
      selectedMemberId,
      selectedAgentType,
      selectedUserLevel,
    });
    setBreadcrumb(bc);
    getAgentList(
      selectedMemberId,
      selectedUserLevel,
      _selectedOrderBy,
      _selectedOrderType,
      1,
      _rows,
      (_date?.length === 0 ? '' : moment(_date[0])?.unix()),
      (_date?.length === 0 ? '' : moment(_date[1])?.unix()),
      selectedAgentType,
      _searchText,
      _selectedStatus,
      _selectedSearchBy,
      _balanceMoreThan,
      _balanceLessThan,
    );
  };

  const onPaginationClick = (pageNum, rows) => {
    setIsQueryingDetail(false);
    // Page number and row number will follow the pagination component instead
    getAgentList(
      userData?.id || '',
      userLevel,
      'CreateDate',
      'DESC',
      pageNum,
      rows,
      (_date?.length === 0 ? '' : moment(_date[0]).unix()),
      (_date?.length === 0 ? '' : moment(_date[1]).unix()),
      agentType,
      _searchText,
      _selectedStatus,
      _selectedSearchBy,
      _balanceMoreThan,
      _balanceLessThan,
    );
    setPage(pageNum);
    setRows(rows);
  };

  const updateStatus = (type, userId, status, userType, loginId) => {
    setActiveId('nil');
    setIsUpdateStatus(true);
    updateModalAlert({
      type: 'loading',
      visible: true
    });
    const obj = {
      userId,
      status,
      userType,
      orgId: userId,
    };
    const searchObj = {
      userId: _breadcrumb?.length > 1 ? _breadcrumb[_breadcrumb.length - 1]?.selectedMemberId : userData?.id || '',
      userLevel: _breadcrumb?.length > 1 ? _breadcrumb[_breadcrumb.length - 1]?.selectedUserLevel : userLevel || '',
      orderBy: _selectedOrderBy,
      orderType: _selectedOrderType,
      page: 1,
      rows: _rows,
      startDate: (_date?.length === 0 ? '' : moment(_date[0]).unix()),
      endDate: (_date?.length === 0 ? '' : moment(_date[1]).unix()),
      agentType: _breadcrumb?.length > 1 ? _breadcrumb[_breadcrumb.length - 1]?.selectedAgentType : agentType || '',
      loginId
    };
    if (type === 'status') {
      setAccountStatus(obj, searchObj);
    } else if (type === 'default') {
      setDefaultAgent(userId, searchObj);
    } else if (type === 'defaultvisitor') {
      setDefaultVisitorAgent(obj, searchObj);
    }
  };

  const tableHeader = [{
    label: t('ONLINE'),
  }, {
    label: t('UPPER_LEVEL_ACCOUNT'),
  }, {
    label: t('OPERATION'),
  }, {
    label: t('USER_TYPE'),
  }, {
    label: t('ACCOUNT'),
  }, {
    label: t('ACCOUNT_BALANCE'),
  }, {
    label: t('ZC'),
  }, {
    label: t('AGENT'),
  }, {
    label: t('MEMBER'),
  }, {
    label: t('ADD'),
  }, {
    label: t('CREATE_DATE'),
  }, {
    label: t('STATUS'),
  }, {
    label: t('DEFAULT'),
  }, {
    label: t('DEFAULT_VISITOR'),
  }];

  const redirectMem = (data, type) => {
    const bc = [..._breadcrumb];
    bc.push({
      label: `会员-${data?.loginId}`,
      type,
      data,
    });
    setBreadcrumb(bc);
    setIsMemRedirect({
      isDirect: true,
      agentType: type,
      data
    });
  };


  const tableContent = [
    data => (
      <Label className={`${data.online === 1 ? 'positiveValue' : 'offline'} justify-content-center smallicon`}>
        <FaCircle className="ml-2" />
      </Label>
    ),
    data => (
      <Label
        className="positiveValue urlNoUnderline"
        onClick={() => onButtonClick(data?.upOrgId, 0, 0, data?.loginId)}
      >
        {data.upLoginId}
      </Label>
    ),
    data => (
      <Label className="positiveValue urlNoUnderline" onClick={() => setActiveId(data?.orgId)}>
        <FaEdit className="ml-2" />
      </Label>
    ),
    data => (<Label className="d-flex justify-content-center">{`${data.userType} ${data.levelName} ${(Number(userData?.levelValue) || 0) === 1 ? data.levelValue : ''}`}</Label>),
    data => (
      <>
        <Label
          className="positiveValue urlNoUnderline mx-1"
          onClick={() => (data.agentCount !== 0 ? onButtonClick(data?.orgId, 0, 0, data?.loginId) : redirectMem(data, 2))}
        >
          {data.userId}
        </Label>
        <Label>
          [
          {data.loginName}
          ]
        </Label>
      </>
    ),
    data => (<Label className="d-flex justify-content-center">{data.balance}</Label>),
    data => (
      <Label
        className="positiveValue urlNoUnderline"
        onClick={() => {
            updateModalAlert({
              type: 'sharesDetail',
              visible: true,
              data: {
                userType: 2,
                userId: data?.orgId
              }
            });
          }}
      >
        {t('DETAILS')}
      </Label>
    ),
    data => (<Label className="d-flex justify-content-center">{data.agentCount}</Label>),
    data => (
      <>
        <Label
          className="positiveValue urlNoUnderline"
          onClick={() => redirectMem(data, 2)}
        >
          {data.memCount}
        </Label>
        &nbsp;
        <Label
          className="positiveValue urlNoUnderline"
          onClick={() => redirectMem(data, 1)}
        >
          {` (${data.directMemCount})`}
        </Label>
      </>
),
    data => (
      <>
        <Label
          className="positiveValue urlNoUnderline"
          onClick={() => callback('create_agent', data, _breadcrumb)}
        >
          {t('AGENT')}
        </Label>
        {' / '}
        <Label
          className="positiveValue urlNoUnderline"
          onClick={() => callback('create_member', data, _breadcrumb)}
        >
          {t('MEMBER')}
        </Label>
      </>
    ),
    data => (
      <Label className="px-1">{moment.unix(data.epochTimeStamp).format('YYYY-MM-DD hh:mm:ss')}</Label>
    ),
    data => (
      <Label>
        <Popover
          placement="top"
          width="160"
          trigger="click"
          visible={false}
          content={(
            <div className="popButton p-0">
              <Button
                className={`mx-1 ${data.accountStatus === 1 ? 'active' : 'idle'}`}
                onClick={() => { data.accountStatus === 1 ? false : updateStatus('status', data?.orgId, 1, 2, data?.loginId); }}
              >
                {t('ACTIVE')}
              </Button>
              <Button
                className={`mx-1 ${data.accountStatus === 0 ? 'active' : 'idle'}`}
                onClick={() => { data.accountStatus === 0 ? false : updateStatus('status', data?.orgId, 0, 2, data?.loginId); }}
              >
                {t('INACTIVE')}
              </Button>
              <Button
                className={`mx-1 ${data.accountStatus === 2 ? 'active' : 'idle'}`}
                onClick={() => { data.accountStatus === 2 ? false : updateStatus('status', data?.orgId, 2, 2, data?.loginId); }}
              >
                {t('PAUSE')}
              </Button>
            </div>
          )}
        >
          <Button className={`buttonURL ${data.accountStatus === 1 ? 'installment' : 'negativeValue'}`}>{data.accountStatus === 1 ? t('ACTIVE') : (data.accountStatus === 0 ? t('INACTIVE') : t('PAUSE'))}</Button>
        </Popover>
      </Label>
    ),
    data => (
      <Label>
        <Button
          className={`buttonURL ${data.isDefaultValue === 0 ? 'installment' : 'negativeValue'}`}
          onClick={() => { data.isDefaultValue === 1 ? false : updateStatus('default', data?.orgId, '', '', data?.loginId); }}
        >
          {data.isDefaultValue === 0 ? t('SETTING') : t('DEFAULT')}
        </Button>
      </Label>
    ),
    data => (
      <Label>
        <Popover
          placement="top"
          width="160"
          trigger="click"
          visible={false}
          content={(
            <div className="popButton p-0">
              <Button
                className={`mx-1 ${data.isVisitorUseValue === 2 ? 'active' : 'idle'}`}
                onClick={() => { data.isVisitorUseValue === 2 ? false : updateStatus('defaultvisitor', data?.orgId, 2, '', data?.loginId); }}
              >
                {t('PUBLIC')}
              </Button>
              <Button
                className={`mx-1 ${data.isVisitorUseValue === 1 ? 'active' : 'idle'}`}
                onClick={() => { data.isVisitorUseValue === 1 ? false : updateStatus('defaultvisitor', data?.orgId, 1, '', data?.loginId); }}
              >
                {t('PRIVATE')}
              </Button>
              <Button
                className={`mx-1 ${data.isVisitorUseValue === 0 ? 'active' : 'idle'}`}
                onClick={() => { data.isVisitorUseValue === 0 ? false : updateStatus('defaultvisitor', data?.orgId, 0, '', data?.loginId); }}
              >
                {t('CANCEL')}
              </Button>
            </div>
          )}
        >
          <Button className={`buttonURL ${data.isVisitorUseValue === 0 ? 'installment' : 'negativeValue'}`}>{data.isVisitorUseValue === 0 ? t('SETTING') : (data.isVisitorUseValue === 2 ? t('PUBLIC') : t('PRIVATE'))}</Button>
        </Popover>
      </Label>
    )
  ];

  const openNewWindow = data => {
    const callback = () => { window.open(`${window.location.origin}/?loginId=${data.loginId}&uid=${data.orgId}&data=${data.token}`); };

    getLoginAs({
      loginId: data.loginId, uid: data.orgId, data: data.token
    }, callback);
  };

  const control = [
    data => (
      <>
        <Button className="controlButton mx-1" value={data?.orgId} onClick={() => callback(0, data, _breadcrumb)}>{t('EDIT')}</Button>
        <Button className="controlButton mx-1" value={data?.orgId} onClick={() => callback(1, data, _breadcrumb)}>{t('LOTTERY_REBATE')}</Button>
        <Button className="controlButton mx-1" value={data?.orgId} onClick={() => callback(2, data, _breadcrumb)}>{t('GAMES_REBATE')}</Button>
        {
          data && data?.level === 2 && (
            <Button className="controlButton mx-1" value={data?.orgId} onClick={() => callback(3, data, _breadcrumb)}>{t('ODDS_DIFF')}</Button>
          )
        }
        <Button
          className="controlButton mx-1"
          value={data?.orgId}
          onClick={() => {
            updateModalAlert({
              type: 'winLostNotification',
              visible: true,
              data: {
                orgId: data?.orgId
              }
            });
          }}
        >
          {t('RESULT_NOTIFICATION')}
        </Button>
        <Button className="controlButton mx-1" value={data?.orgId} onClick={() => callback(4, data, _breadcrumb)}>{t('LOGIN_LOG')}</Button>
        <Button className="controlButton mx-1" value={data?.orgId} onClick={() => callback(5, data, _breadcrumb)}>{t('AMENDED_LOG')}</Button>
        <Button className="controlButton mx-1" value={data?.orgId} onClick={() => callback(6, data, _breadcrumb)}>{t('BET_LIST')}</Button>
        <Button className="controlButton mx-1" value={data?.orgId} onClick={() => callback(7, data, _breadcrumb)}>{t('CHECK_TRANSFER_FLOW')}</Button>
        <Button className="controlButton mx-1" value={data?.orgId} onClick={() => openNewWindow(data)}>{t('LOGIN')}</Button>
        <Button className="controlButton mx-1" value={data?.orgId} onClick={() => callback(8, data, _breadcrumb)}>{t('BANK_CARD')}</Button>
      </>
    ),
  ];

  return (
    <Col className="m-0 p-0">
      <div className="breadcrumbs p-0 px-3 d-flex justify-content-start">
        {
      _breadcrumb?.length > 1 && (
      <>
          {_breadcrumb.map((item, i) => (
            <div className="d-flex pt-2" key={`breadcrumb_${item.label}_${i.toString()}`}>
              <Label
                className="mainitem"
                onClick={() => {
                  if (!item?.type) {
                    setIsMemRedirect(false);
                    getAgentList(
                      item?.selectedMemberId,
                      item?.selectedUserLevel,
                      'CreateDate',
                      'DESC',
                      1,
                      _rows,
                      '',
                      '',
                      item?.selectedAgentType,
                    );
                  } else if (item?.type) {
                    setIsMemRedirect({
                      isDirect: true,
                      agentType: item?.type,
                      data: item?.data
                    });
                  }
                const temp = [..._breadcrumb];
                temp.splice(i + 1, _breadcrumb.length - (i + 1));
                setBreadcrumb(temp);
              }}
              >
                {item.label}
              </Label>
              {(_breadcrumb.length - 1) !== i && (<Label className="mx-2 delimiter">/</Label>)}
            </div>
      ))}
      </>
      )
    }
      </div>
      {
!_isMemRedirect?.isDirect ? (

  <Col className="DirectAgentWrapper p-3">
    <Col className="p-2 mb-2 filterWrapper d-flex align-items-center w-100 rounded-top">
      <Label value={t('STATUS')} className="px-2">{t('STATUS')}</Label>
      <Select
        popper-class="select-popper"
        value={_selectedStatus}
        onChange={value => (setSelectedStatus(value))}
      >
        {
            AccountStatus.map(item => (
              <Select.Option key={`UserStatus_${item.value}`} label={item.label} value={item.value} />
            ))
          }
      </Select>
      <Label className="px-2">{t('ORDER_BY')}</Label>
      <Select
        popper-class="select-popper"
        value={_selectedOrderBy}
        onChange={value => (setSelectedOrderBy(value))}
      >
        {
            OrderBySelection.map(item => (
              <Select.Option key={`OrderBy_${item.value}`} label={item.label} value={item.value} />
            ))
          }
      </Select>
      <Select
        className="mx-1"
        popper-class="select-popper"
        value={_selectedOrderType}
        onChange={value => (setSelectedOrderType(value))}
      >
        {
            OrderSelection.map(item => (
              <Select.Option key={`OrderType_${item.value}`} label={item.label} value={item.value} />
            ))
          }
      </Select>
      <Label value={t('SEARCH')} className="px-2">{t('SEARCH')}</Label>
      <Select
        popper-class="select-popper"
        value={_selectedSearchBy}
        onChange={value => {
            setSelectedSearchBy(value);
            if (value === '-1') {
              setSearchText('');
            }
          }}
      >
        {
            UserSearchBy.map(item => (
              <Select.Option key={`CommonSearchBy${item.value}`} label={item.label} value={item.value} />
            ))
          }
      </Select>
      <Input className="mx-1" value={_searchText} onChange={({ target: { value: text } }) => setSearchText(text)} />
      <Label type="daterange" value={t('DATE')} className="px-2">{t('DATE')}</Label>
      <div>
        <RangePicker
          allowClear={false}
          value={[moment(_date[0]), moment(_date[1])]}
          separator={<span>{` ${t('TO')} `}</span>}
          ranges={{
                Today: [moment(), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
              }}
          onChange={date => {
                setDate([date[0].toDate(), date[1].toDate()]);
              }}
        />
      </div>
      <Label value={t('BALANCE_MORE_THAN')} className="px-2 marginLeft5">{t('BALANCE_MORE_THAN')}</Label>
      <Input type="number" className="mx-1" value={_balanceMoreThan} onChange={({ target: { value: text } }) => setBalanceMoreThan(text)} />
      <Label value={t('BALANCE_LESS_THAN')} className="px-2">{t('BALANCE_LESS_THAN')}</Label>
      <Input type="number" className="mx-1" value={_balanceLessThan} onChange={({ target: { value: text } }) => setBalanceLessThan(text)} />
      <Button className="searchButton marginLeft5 me-2" onClick={onSearchClick}>{t('FIND')}</Button>
      <Notifications />
    </Col>
    <Col className="subMenu mb-2 flex">
      <Button className="addButton mx-2" onClick={() => callback('create_agent', userInfo, _breadcrumb)}>
        <FaPlusCircle className="mx-1" />
        {t('ADD_AGENT')}
      </Button>
      <Button className="addButton mx-2" onClick={() => callback('create_subAccount', userData, _breadcrumb)}>
        <FaPlusCircle className="mx-1" />
        {t('ADD_SUB_AGENT')}
      </Button>
    </Col>
    <Row className="mx-0 bg-white px-0 border-1">
      <AgentDataTable
        isQuerying={_isQueryingDetail}
        tableHeaderTitle={_header}
        tableData={_detailItem || []}
        tableBodyRowProperty={_content}
        tableControl={control}
        activeControl={_activeId}
        tableFooter={[[{
            value: `${t('THIS_PAGE')}: `,
            colspan: 5,
            className: 'textRight pr-1 total tableSum'
          },
          {
            value: (_detailItemSum && _detailItemSum.result) || 0,
            colspan: 1,
            className: 'tableSum'
          },
          {
            colspan: _header.length - 5,
            className: 'tableSum'
          }], [{
            value: `${t('TOTAL_COUNT')}: `,
            colspan: 5,
            className: 'textRight pr-1 total tableSum'
          },
          {
            value: (_detailItemSum && _detailItemSum.totalResult) || 0,
            colspan: 1,
            className: 'tableSum'
          },
          {
            colspan: _header.length - 5,
            className: 'tableSum'
          }]]}
      />
    </Row>
    <Row className="justify-center w-full relative">
      <Pagination totalRecord={(_detailItemSum && _detailItemSum?.records) || 0} onClick={onPaginationClick} isQuery={_isQueryingDetail} />
    </Row>
  </Col>

) : (
  <MemberList
    key={`privateuser_${_isMemRedirect?.data?.orgId}`}
    agentType={_isMemRedirect?.agentType}
    userLevel={_isMemRedirect?.agentType === 1 ? (Number(_isMemRedirect?.data?.level) || 0) + 1 : (Number(_isMemRedirect?.data?.level) || 0) - 1}
    data={_isMemRedirect?.data}
    backToAgent={() => setIsMemRedirect(true)}
    callback={callback}
    breadcrumb={_breadcrumb}
  />
)
      }
    </Col>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(AgentList);