import gameItemIconMap from '../../GameItemIcon';

const tSkyEarth = [
  {
    displayName: '特天肖',
  }, {
    displayName: '特地肖',
  }
];

const tFrontBack = [
  {
    displayName: '特前肖',
  }, {
    displayName: '特后肖',
  }
];

const tHouseWild = [
  {
    displayName: '特家肖',
  }, {
    displayName: '特野肖',
  }
];

const dragonTiger = [
  {
    displayName: '龙',
  }, {
    displayName: '虎',
  }
];

const drawMatch = [
  {
    displayName: '和局'
  }
];

const zodiac = [
  {
    displayName: '鼠', className: 'ShengXiaoStyle color_red'
  }, {
    displayName: '牛', className: 'ShengXiaoStyle color_blue'
  }, {
    displayName: '虎', className: 'ShengXiaoStyle color_green'
  }, {
    displayName: '兔', className: 'ShengXiaoStyle color_red'
  }, {
    displayName: '龙', className: 'ShengXiaoStyle color_blue'
  }, {
    displayName: '蛇', className: 'ShengXiaoStyle color_green'
  }, {
    displayName: '马', className: 'ShengXiaoStyle color_red'
  }, {
    displayName: '羊', className: 'ShengXiaoStyle color_blue'
  }, {
    displayName: '猴', className: 'ShengXiaoStyle color_green'
  }, {
    displayName: '鸡', className: 'ShengXiaoStyle color_red'
  }, {
    displayName: '狗', className: 'ShengXiaoStyle color_blue'
  }, {
    displayName: '猪', className: 'ShengXiaoStyle color_green'
  }
];

const nineTails = [
  {
    displayName: '0尾',
  }, {
    displayName: '1尾',
  }, {
    displayName: '2尾',
  }, {
    displayName: '3尾',
  }, {
    displayName: '4尾',
  }, {
    displayName: '5尾',
  }, {
    displayName: '6尾',
  }, {
    displayName: '7尾',
  }, {
    displayName: '8尾',
  }, {
    displayName: '9尾',
  }
];

const element = [
  {
    displayName: '金',
  }, {
    displayName: '木',
  }, {
    displayName: '水',
  }, {
    displayName: '火',
  }, {
    displayName: '土',
  }];

const head = [
  {
    displayName: '0头',
  }, {
    displayName: '1头',
  }, {
    displayName: '2头',
  }, {
    displayName: '3头',
  }, {
    displayName: '4头',
  }];

const zodiac2to7 = [
  {
    displayName: '2肖',
  }, {
    displayName: '3肖',
  }, {
    displayName: '4肖',
  }, {
    displayName: '5肖',
  }, {
    displayName: '6肖',
  }, {
    displayName: '7肖',
  }];

const lianma11x5 = [
  {
    displayName: '任选二中二',
  }, {
    displayName: '任选三中三',
  }, {
    displayName: '任选四中四',
  }, {
    displayName: '任选五中五',
  }, {
    displayName: '任选六中五',
  }, {
    displayName: '任选七中五',
  }, {
    displayName: '任选八中五',
  }, {
    displayName: '前二组选',
  }, {
    displayName: '前三组选',
  }, {
    displayName: '前二直选',
  }, {
    displayName: '前三直选',
  }
];

const lianmaKL10 = [
  {
    displayName: '任选二',
  }, {
    displayName: '任选三',
  }, {
    displayName: '任选四',
  }, {
    displayName: '任选五',
  }, {
    displayName: '选二连组',
  }, {
    displayName: '选三前组',
  }
];

const sixXiao = [
  {
    displayName: '六肖'
  }
];

const total810 = [
  {
    displayName: '总和810'
  }
];

const extraFrontBack = [
  {
    displayName: '前(多)',
  }, {
    displayName: '後(多)',
  }, {
    displayName: '前後(和)',
  }
];

const direction = [
  {
    displayName: '东',
  }, {
    displayName: '南',
  }, {
    displayName: '西',
  }, {
    displayName: '北'
  }
];

const fortune = [
  {
    displayName: '福',
  }, {
    displayName: '禄',
  }, {
    displayName: '寿',
  }, {
    displayName: '喜'
  }
];

const sscType = [
  {
    displayName: '豹子',
  }, {
    displayName: '顺子',
  }, {
    displayName: '对子',
  }, {
    displayName: '半顺'
  }, {
    displayName: '杂六'
  }
];

const dot4to17 = [
  {
    displayName: '4点',
  }, {
    displayName: '5点',
  }, {
    displayName: '6点',
  }, {
    displayName: '7点',
  }, {
    displayName: '8点',
  }, {
    displayName: '9点',
  }, {
    displayName: '10点',
  }, {
    displayName: '11点',
  }, {
    displayName: '12点',
  }, {
    displayName: '13点',
  }, {
    displayName: '14点',
  }, {
    displayName: '15点',
  }, {
    displayName: '16点',
  }, {
    displayName: '17点',
  }];

const animal = [
  {
    displayName: '1/鱼', keyCode: 'SJ11', rowSpan: 3, img: gameItemIconMap.CJ_FISH,
  }, {
    displayName: '1/鱼', keyCode: 'SJ21', rowSpan: 'nil', img: gameItemIconMap.CJ_FISH,
  }, {
    displayName: '1/鱼', keyCode: 'SJ31', rowSpan: 'nil', img: gameItemIconMap.CJ_FISH,
  }, {
    displayName: '2/虾', keyCode: 'SJ12', rowSpan: 3, img: gameItemIconMap.CJ_PRAWN,
  }, {
    displayName: '2/虾', keyCode: 'SJ22', rowSpan: 'nil', img: gameItemIconMap.CJ_PRAWN,
  }, {
    displayName: '2/虾', keyCode: 'SJ32', rowSpan: 'nil', img: gameItemIconMap.CJ_PRAWN,
  }, {
    displayName: '3/葫芦', keyCode: 'SJ13', rowSpan: '3', img: gameItemIconMap.CJ_GOURD,
  }, {
    displayName: '3/葫芦', keyCode: 'SJ23', rowSpan: 'nil', img: gameItemIconMap.CJ_GOURD,
  }, {
    displayName: '3/葫芦', keyCode: 'SJ33', rowSpan: 'nil', img: gameItemIconMap.CJ_GOURD,
  }, {
    displayName: '4/金钱', keyCode: 'SJ14', rowSpan: '3', img: gameItemIconMap.CJ_COIN,
  }, {
    displayName: '4/金钱', keyCode: 'SJ24', rowSpan: 'nil', img: gameItemIconMap.CJ_COIN,
  }, {
    displayName: '4/金钱', keyCode: 'SJ34', rowSpan: 'nil', img: gameItemIconMap.CJ_COIN,
  }, {
    displayName: '5/螃蟹', keyCode: 'SJ15', rowSpan: '3', img: gameItemIconMap.CJ_CRAB,
  }, {
    displayName: '5/螃蟹', keyCode: 'SJ25', rowSpan: 'nil', img: gameItemIconMap.CJ_CRAB,
  }, {
    displayName: '5/螃蟹', keyCode: 'SJ35', rowSpan: 'nil', img: gameItemIconMap.CJ_CRAB,
  }, {
    displayName: '6/鷄', keyCode: 'SJ16', rowSpan: '3', img: gameItemIconMap.CJ_CHICKEN,
  }, {
    displayName: '6/鷄', keyCode: 'SJ26', rowSpan: 'nil', img: gameItemIconMap.CJ_CHICKEN,
  }, {
    displayName: '6/鷄', keyCode: 'SJ36', rowSpan: 'nil', img: gameItemIconMap.CJ_CHICKEN,
  }
];

const fullDice = [{
  displayName: '全骰'
}];

const middle = [{
  displayName: '中'
}];

const huat = [{
  displayName: '发'
}];

const white = [{
  displayName: '白'
}];

const peace = [{
  displayName: '和'
}];

const specialNum3 = [{
  displayName: '特码包三'
}];
const filler = [{
  displayName: '', className: 'filler',
}];

const zheng1to4 = [{
  displayName: '1正'
}, {
  displayName: '2正'
}, {
  displayName: '3正'
}, {
  displayName: '4正'
}];

const fan1to4 = [{
  displayName: '1番'
}, {
  displayName: '2番'
}, {
  displayName: '3番'
}, {
  displayName: '4番'
}];
const jiao1to4 = [{
  displayName: '1-2角'
}, {
  displayName: '2-3角'
}, {
  displayName: '3-4角'
}, {
  displayName: '1-4角'
}];
const nian1to4 = [{
  displayName: '1念2'
}, {
  displayName: '1念3'
}, {
  displayName: '1念4'
}, {
  displayName: '2念1'
}, {
  displayName: '2念3'
}, {
  displayName: '2念4'
}, {
  displayName: '3念1'
}, {
  displayName: '3念2'
}, {
  displayName: '3念4'
}, {
  displayName: '4念1'
}, {
  displayName: '4念2'
}, {
  displayName: '4念3'
}];

const men1to4 = [{
  displayName: '123中'
}, {
  displayName: '124中'
}, {
  displayName: '134中'
}, {
  displayName: '234中'
}];

export {
  drawMatch,
  zodiac,
  nineTails,
  tSkyEarth,
  tFrontBack,
  tHouseWild,
  element,
  head,
  zodiac2to7,
  dragonTiger,
  lianma11x5,
  sixXiao,
  total810,
  extraFrontBack,
  dot4to17,
  animal,
  fullDice,
  middle,
  direction,
  huat,
  white,
  lianmaKL10,
  fortune,
  peace,
  sscType,
  specialNum3,
  filler,
  zheng1to4,
  fan1to4,
  nian1to4,
  jiao1to4,
  men1to4,
};