import { useEffect, useState } from 'react';

import { Button, Col, Label, Row } from 'reactstrap';
import { Modal, message } from 'antd';
import { IoClose } from 'react-icons/io5';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import moment from 'moment';

import { appActions, thirdPartyGamesActions } from '../../../redux/actions';
import AgentDataTable from '../../AgentDataTable';

import './styles.scss';

const mapStateToProps = state => ({
  platform: state.appReducers.platform,
  thirdGamesAccount: state.thirdPartyGamesReducers.thirdGamesAccount,
  thirdGamesAmounts: state.thirdPartyGamesReducers.thirdGamesAmounts,
});

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  getGamesAccount: id => dispatch(thirdPartyGamesActions.GetGamesAccount(id)),
  getAllBalance: (memberId, array) => dispatch(thirdPartyGamesActions.GetAllBalance(memberId, array)),
  resetThirdAmount: () => dispatch(thirdPartyGamesActions.ResetThirdAmount()),
  refreshThirdAmount: obj => dispatch(thirdPartyGamesActions.RefreshThirdAmount(obj)),
  toggleTransferStatus: obj => dispatch(thirdPartyGamesActions.ToggleTransferStatus(obj)),
  transferGameAmount: (memberId, obj) => dispatch(thirdPartyGamesActions.TransferGameAmount(memberId, obj)),
});

const { confirm } = Modal;

const ThirdGamesAccountModal = ({ updateModalAlert, data, getGamesAccount, thirdGamesAccount, thirdGamesAmounts, getAllBalance, resetThirdAmount, refreshThirdAmount, toggleTransferStatus, transferGameAmount }) => {
  const { t } = useTranslation();
  const [_detailItem, setDetailItem] = useState();
  const [_amountList, setAmountList] = useState();

  useEffect(() => {
    // Default search when first load
    resetThirdAmount();
    data && data?.memberId && getGamesAccount(data?.memberId);
  }, [data]);

  useEffect(() => {
    if (thirdGamesAccount?.gamelist) {
      const temp = thirdGamesAccount?.gamelist.filter(x => x.loginData?.length > 0 && Number(x.gameStatus) === 0);
      getAllBalance(data?.memberId, [...new Set(temp.map(item => item.gameCode))]);
    }
  }, [thirdGamesAccount, data]);

  useEffect(() => {
    if (thirdGamesAccount?.gamelist) {
      setDetailItem(thirdGamesAccount?.gamelist);
    }
  }, [thirdGamesAccount]);

  useEffect(() => {
    if (thirdGamesAmounts && JSON.stringify(_amountList) !== JSON.stringify(thirdGamesAmounts)) {
      setAmountList(thirdGamesAmounts);
    } else if (JSON.stringify(_amountList) === JSON.stringify(thirdGamesAmounts)) {
      let myMsg;
      thirdGamesAmounts?.forEach(x => {
        if (x?.status === 'error') {
          myMsg = x?.msg;
        }
      });
      if (thirdGamesAmounts && myMsg) {
        callMsg(myMsg);
      }
    }
  }, [thirdGamesAmounts]);

  const callMsg = msg => {
    message.info(msg, 1);
  };

  const oneKeyRecyle = () => {
    const array = [];
    const temp = thirdGamesAccount?.gamelist.filter(x => x.loginData?.length > 0 && Number(x.gameStatus) === 0);
    [...new Set(temp.map(item => item.gameCode))].forEach(x => {
      array.push({
        gameCode: x,
        acAmount: _amountList.filter(x => x.memberId === data?.memberId && x.gameCode === x)[0]?.amount
      });
    });
    transferGameAmount(data?.memberId, array);
  };

  const tableHeader = [{
    label: t('GAME_CODE'),
  }, {
    label: t('GAME_NAME'),
  }, {
    label: t('GAME_ACCOUNT'),
  }, {
    label: t('ACCOUNT_BALANCE'),
  }, {
    label: t('REGISTER_TIME'),
  }, {
    label: t('TRANSFER_STATUS'),
  }, {
    label: t('OPERATION'),
  }
  ];

  const tableContent = [
    mydata => (<Label className="d-flex justify-content-center">{mydata?.gameCode}</Label>),
    mydata => (<Label className="d-flex justify-content-center">{mydata?.gameName}</Label>),
    mydata => (<Label className="d-flex justify-content-center">{mydata?.loginData[0]?.apiLoginName || '未注册'}</Label>),
    mydata => (mydata?.loginData?.length > 0 ? (Number(mydata?.gameStatus) === 0 ? <Label className="d-flex justify-content-center installment">{_amountList.filter(x => x.memberId === data?.memberId && x.gameCode === mydata?.gameCode)[0]?.amount || 'loading..'}</Label> : <Label className="justify-content-center installment">0</Label>) : <Label className="justify-content-center installment">0</Label>),
    mydata => (mydata?.loginData?.length > 0 ? <Label className="d-flex justify-content-center">{moment.unix(Number(mydata?.loginData[0]?.createDate)).format('YYYY-MM-DD hh:mm:ss')}</Label> : '未注册'),
    mydata => (mydata?.loginData?.length > 0
      ? (
        <Label
          className={`d-flex justify-content-center url ${Number(mydata?.loginData[0]?.transferEnable) === 0 ? 'negativeValue' : ''}`}
          onClick={() => {
            confirm({
              title: '提示',
              content: '确定更改转账状态吗？',
              okText: t('CONFIRM'),
              cancelText: t('CANCEL'),

              onOk() {
                toggleTransferStatus({
                  memberId: data?.memberId, gameCode: mydata?.gameCode
                });
              },

              onCancel() {
              },
            });
          }}
        >
          {Number(mydata?.loginData[0]?.transferEnable) === 0 ? '禁用' : '启用'}
        </Label>
      ) : '未注册'),
    mydata => (mydata?.loginData?.length > 0
      ? (Number(mydata?.gameStatus) === 0
        ? (
          <>
            <Label
              className="justify-content-center px-1 url"
              value={data?.memberId}
              onClick={() => refreshThirdAmount({
                memberId: data?.memberId, gameCode: mydata?.gameCode
              })}
            >
              {t('REFRESH')}
            </Label>
            <Label
              className="justify-content-center px-1 url"
              value={data?.memberId}
              onClick={() => transferGameAmount(data?.memberId, [{
                gameCode: mydata?.gameCode,
                acAmount: _amountList.filter(x => x.memberId === data?.memberId && x.gameCode === mydata?.gameCode)[0]?.amount
              }])}
            >
              {t('RECYLE')}
            </Label>
            <Label
              data-toggle="tooltip"
              data-placement="top"
              title="Tooltip on top"
              className="justify-content-center px-1 url"
              onClick={() => callMsg('暂未开启')}
            >
              {t('LOG_OUT')}
            </Label>
          </>
        ) : mydata?.gameStatusRemark) : '未注册'),
  ];

  return (
    <div className="thirdGamesAccountModalWrapper">
      <Row className="modalHeader">
        <Col
          className={`${'d-flex justify-content-center align-items-center'}`}
          xs={{
            size: 4, offset: 4
          }}
        >
          {t('GAME_BALANCE')}
        </Col>
        <Col xs="4" className="d-flex justify-content-end align-items-center">
          <IoClose
            onClick={() => {
              updateModalAlert({
                visible: false
              });
              if (data && data.overBet && data.onConfirmationClick) data.onConfirmationClick();
            }}
            className="h4 mb-0 float-right closeIcon"
          />
        </Col>
      </Row>
      <Row className="modalContent d-flex justify-content-center">
        <Row className="p-2 justify-content-center">
          {
            data && thirdGamesAccount && data?.memberId && (
              <Row className="p-1 m-0 justify-content-start align-items-center">
                <Col className="d-flex p-0 m-0 justify-content-start">
                  游戏余额 (账户余额): &nbsp;&nbsp;
                  {thirdGamesAccount?.moneyLimit && (
                  <b>
                    {thirdGamesAccount?.moneyLimit}
                  </b>
                  )}
                </Col>
                <Col className="d-flex p-1 m-0 justify-content-end">
                  <Button className="keyButton" onClick={() => oneKeyRecyle()}>一键收回</Button>
                </Col>
              </Row>
            )
          }
        </Row>
        <Row className="tableWrapper d-flex pb-2 justify-content-center">
          <AgentDataTable
            tableHeaderTitle={tableHeader}
            tableData={_detailItem}
            tableBodyRowProperty={tableContent}
          />
        </Row>
      </Row>
      <Row className="modalFooter d-flex align-items-center">
        <Button
          className={`confirmButton my-2 ${'me-3'}`}
          onClick={() => {
            updateModalAlert({
              visible: false
            });
            if (data && data.onConfirmationClick) data.onConfirmationClick();
          }}
        >
          {(data && data.confirmText) || t('CONFIRM')}
        </Button>
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ThirdGamesAccountModal);