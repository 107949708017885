import { renderResultDateCell } from '../../utils';

const renderQiu = data => {
  if (data && !data.openResult) return;
  const result = data.openResult.split(',');
  return result.map(item => (
    {
      value: item, className: 'circle circleBlue'
    }
  ));
};
const renderZH = data => {
  if (data && !data.openResult) return;
  const tempResult = [];
  let championSum = 0;
  const result = data.openResult.split(',');
  result.forEach(item => (championSum += parseInt(item, 10)));
  tempResult.push({
    value: championSum, className: ''
  });
  tempResult.push({
    value: championSum > 22 ? '大' : '小',
    className: championSum > 22 ? 'redText' : ''
  });
  tempResult.push({
    value: championSum % 2 ? '单' : '双', className: championSum % 2 ? 'redText' : ''
  });
  return tempResult;
};

const renderLH = data => {
  if (data && !data.openResult) return;
  const result = data.openResult.split(',');
  const tempResult = [];
  tempResult.push({
    value: (parseInt(result[0], 10)) > parseInt(result[result.length - 1], 10) ? '龙' : ((parseInt(result[0], 10)) < parseInt(result[result.length - 1], 10)) ? '虎' : '和',
    className: (parseInt(result[0], 10)) > parseInt(result[result.length - 1], 10) && 'redText'
  });
  return tempResult;
};

const generateSSCResultBySelectedArr = arr => {
  const zero = parseInt(arr[0], 10);
  const one = parseInt(arr[1], 10);
  const two = parseInt(arr[2], 10);
  if (arr[0] === arr[1] && arr[0] === arr[2] && arr[1] === arr[2]) {
    return '豹子';
  } if (arr[0] === arr[1] || arr[0] === arr[2] || arr[1] === arr[2]) {
    return '对子';
  } if ((zero === 0 && two === 9 && (one === 8 || one === 1)) || (zero === (one - 1) && one === (two - 1))) {
    return '顺子';
  } if ((zero === (one - 1) || one === (two - 1)) || (zero === 0 && (one === 9 || two === 9)) || (one === 0 && (zero === 9 || two === 9)) || (two === 0 && (zero === 9 || one === 9))) {
    return '半顺';
  }
  return '杂六';
};

const renderQ = data => {
  if (data && !data.openResult) return;
  const result = data.openResult.split(',');
  const tempResult = [];
  const selection = [result[0], result[1], result[2]];
  tempResult.push({
    value: generateSSCResultBySelectedArr(selection.sort()),
  });
  return tempResult;
};
const renderZ = data => {
  if (data && !data.openResult) return;
  const result = data.openResult.split(',');
  const tempResult = [];
  const selection = [result[1], result[2], result[3]];
  tempResult.push({
    value: generateSSCResultBySelectedArr(selection.sort()),
  });
  return tempResult;
};
const renderH = data => {
  if (data && !data.openResult) return;
  const result = data.openResult.split(',');
  const tempResult = [];
  const selection = [result[2], result[3], result[4]];
  tempResult.push({
    value: generateSSCResultBySelectedArr(selection.sort()),
  });
  return tempResult;
};

const SSC = {
  children: [
  ],
  resultTab: {
    headerCell: [{
      attr: '期数',
      wrapperStyle: 'sscHeader'
    }, {
      attr: '日期',
      wrapperStyle: 'sscHeader'
    }, {
      attr: '彩球号码',
      wrapperStyle: 'sscHeader'
    }, {
      attr: '总和',
      wrapperStyle: 'sscHeader'
    }, {
      attr: '龙虎',
      wrapperStyle: 'sscHeader'
    }, {
      attr: '前三',
      wrapperStyle: 'sscHeader'
    }, {
      attr: '中三',
      wrapperStyle: 'sscHeader'
    }, {
      attr: '后三',
      wrapperStyle: 'sscHeader'
    }],
    bodyCell: [{
      attr: 'installments',
      wrapperStyle: 'd-flex justify-content-center align-items-center'
    }, {
      attr: renderResultDateCell,
      wrapperStyle: 'dateCol'
    }, {
      attr: renderQiu,
      wrapperStyle: 'd-flex sscBallAndZh'
    }, {
      attr: renderZH,
      wrapperStyle: 'd-flex sscBallAndZh'
    }, {
      attr: renderLH,
      wrapperStyle: 'd-flex sscBallAndZh'
    }, {
      attr: renderQ,
      wrapperStyle: 'd-flex sscBallAndZh'
    }, {
      attr: renderZ,
      wrapperStyle: 'd-flex sscBallAndZh'
    }, {
      attr: renderH,
      wrapperStyle: 'd-flex sscBallAndZh'
    }],
  }
};

export default SSC;