import { useEffect, useState } from 'react';

import { Button, Input, } from 'antd';
import { Col, Row } from 'reactstrap';
import { IoClose } from 'react-icons/io5';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { agentActions, appActions, companyActions } from '../../../redux/actions';

import './styles.scss';

const mapStateToProps = state => ({
  platform: state.appReducers.platform,
  oddsDiffUpOrgsData: state.companyReducers.oddsDiffUpOrgsData,
  winLostNotificationData: state.agentReducers.winLostNotificationData,
});

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  getOddsDiffUpOrgs: (id, lotType) => dispatch(companyActions.getOddsDiffUpOrgs(id, lotType)),
  getWinLostNotification: id => dispatch(agentActions.getWinLostNotification(id)),
  saveWinLostNotification: obj => dispatch(agentActions.saveWinLostNotification(obj)),
});

const WinLostNotificationModal = ({ updateModalAlert, data, getWinLostNotification, winLostNotificationData, saveWinLostNotification }) => {
  const { t } = useTranslation();
  const [_lostLimit, setLostLimit] = useState();
  const [_winLimit, setWinLimit] = useState();

  useEffect(() => {
    // Default search when first load
    data && getWinLostNotification(data?.orgId || data?.memberId);
  }, [data]);

  useEffect(() => {
    if (winLostNotificationData) {
      setWinLimit(winLostNotificationData?.winLimit);
      setLostLimit(winLostNotificationData?.lostLimit);
    }
  }, [winLostNotificationData]);

  const saveButton = () => {
    const obj = {
      userId: data?.orgId || data?.memberId,
      winLimit: _winLimit || 0,
      loseLimit: _lostLimit || 0
    };
    saveWinLostNotification(obj);
  };

  return (
    <div className="winLostNotiModalWrapper">
      <Row className="modalHeader">
        <Col
          className={`${'d-flex justify-content-center align-items-center'}`}
          xs={{
            size: 4, offset: 4
          }}
        >
          今日输赢提示设置
        </Col>
        <Col xs="4" className="d-flex justify-content-end align-items-center">
          <IoClose
            onClick={() => {
              updateModalAlert({
                visible: false
              });
              if (data && data.overBet && data.onConfirmationClick) data.onConfirmationClick();
            }}
            className="h4 mb-0 float-right closeIcon"
          />
        </Col>
      </Row>
      <Row className="modalContent m-0 p-0 d-flex justify-content-center">
        <Row className="tableWrapper d-flex p-3 justify-content-center">
          <table className="agentDataTable p-3 m-0" cellSpacing="0" cellPadding="0" border="0">
            <thead>
              <tr className="tableHeader">
                <td colSpan="4">
                  <span>今日输赢提示</span>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="oneCharCol">{t('WIN')}</td>
                <td>
                  <span>
                    <Input
                      type="number"
                      className="justify-content-center mx-3"
                      value={_winLimit}
                      onChange={({ target: { value: text } }) => {
                        if (Number(text) > 0) {
                          setWinLimit(Number(text));
                        }
                      }}
                    />
                  </span>
                </td>
                <td className="oneCharCol">{t('LOSE')}</td>
                <td>
                  <span>
                    <Input
                      className="justify-content-center mx-3"
                      type="number"
                      value={_lostLimit}
                      onChange={({ target: { value: text } }) => {
                        if (Number(text) > 0) {
                          setLostLimit(Number(text));
                        }
                      }}
                    />
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </Row>
      </Row>
      <Row className="modalFooter d-flex align-items-center">
        <Button
          className={`confirmButton my-2 ${'me-3'}`}
          onClick={() => saveButton()}
        >
          {(data && data.confirmText) || t('CONFIRM')}
        </Button>
        <Button
          className={`confirmButton my-2 ${'me-3'}`}
          onClick={() => {
            updateModalAlert({
              visible: false
            });
          }}
        >
          {(data && data.confirmText) || t('CANCEL')}
        </Button>
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(WinLostNotificationModal);