import { useEffect, useState } from 'react';

import { Checkbox, Input, Radio, Select } from 'antd';
import { connect } from 'react-redux';

import { companyActions } from '../../../../redux/actions';

import './styles.scss';

const mapStateToProps = state => ({
  companyEditInfo: state.companyReducers.companyEditInfo,
  lotteryList: state.commonReducers.lotteryList,
});

const mapDispatchToProps = dispatch => ({
  getCompanyEditInfo: (id, callback) => dispatch(companyActions.getCompanyEditInfo(id, callback)),
  saveCompanyInfo: (obj, callback) => dispatch(companyActions.saveCompanyInfo(obj, callback)),
});

const { Option } = Select;

// For 二级公司

const CompanySetting = ({ helloFromUserManage = false, passData = {}, onCancel = () => { }, lotteryList, saveCompanyInfo, getCompanyEditInfo, companyEditInfo }) => {
  const [selectedMap, setSelectedMap] = useState(new Map());
  const [totalLottery, setTotalLottery] = useState(new Map());

  const [isUpdate, setisUpdate] = useState();
  const [active, setactive] = useState(0);
  const [defaultAdmin, setdefaultAdmin] = useState('');
  const [enableEarnOdds, setenableEarnOdds] = useState(0);
  const [logo, setlogo] = useState('');
  const [name, setname] = useState('');
  const [password, setpassword] = useState('');
  const [companyCode, setcompanyCode] = useState('');
  const [validType, setvalidType] = useState(0);
  const [accountOption, setaccountOption] = useState(1);
  const [templateAgent, settemplateAgent] = useState('');
  const [templateMember, settemplateMember] = useState('');
  const [templateMemberStyle, settemplateMemberStyle] = useState('blue');
  const [templateUpdate, settemplateUpdate] = useState(0);
  const [urlLotteryResults, seturlLotteryResults] = useState('');
  const [urlSearchEngine, seturlSearchEngine] = useState('');
  const [alsKey, setAlsKey] = useState('');

  const [allowLookup, setallowLookup] = useState();
  const [showZdLogo, setshowZdLogo] = useState();
  const [isPasswordUpper, setisPasswordUpper] = useState();

  useEffect(() => {
    if (helloFromUserManage && passData.orgId) { // 进入修改界面
      getCompanyEditInfo(passData.orgId, () => { setisUpdate(true); });
    }
  }, []);

  useEffect(() => {
    if (companyEditInfo) {
      let { company } = companyEditInfo || {};
      if (!isUpdate) {
        company = {};
      }

      setallowLookup(company?.allowLookup);
      setshowZdLogo(company?.showZdLogo);
      setisPasswordUpper(company?.isPasswordUpper);

      setactive(company?.active ?? 0);
      setdefaultAdmin(company?.defaultAdmin ?? '');
      setenableEarnOdds(company?.enableEarnOdds ?? 0);
      setlogo(company?.logo ?? '');
      setname(company?.name ?? '');
      setcompanyCode(company?.companyCode ?? '');
      setvalidType(company?.validType ?? 0);
      setaccountOption(company?.accountOption ?? 0);

      seturlLotteryResults(company?.urlLotteryResults ?? '');
      seturlSearchEngine(company?.urlSearchEngine ?? '');

      settemplateAgent(company?.templateAgent ?? '');
      settemplateMember(company?.templateMember ?? '');
      setAlsKey(company?.alsKey ?? '');

      if (lotteryList) {
        const mapAll = new Map();
        const mapSelected = new Map();
        const allLs = [].concat(lotteryList);
        if (isUpdate && companyEditInfo?.lotList) {
          const ls = [].concat(companyEditInfo.lotList);
          for (let i = 0; i < ls.length; i++) {
            const temp = allLs.find(x => (x.code === ls[i]));
            if (temp) {
              if (mapSelected.has(temp.categoryCode)) {
                const tempArr = mapSelected.get(temp.categoryCode);
                mapSelected.set(temp.categoryCode, tempArr.concat(temp));
              } else {
                mapSelected.set(temp.categoryCode, [].concat(temp));
              }
            }
          }
        }
        allLs.forEach(item => {
          if (mapAll.has(item.categoryCode)) {
            const temp = mapAll.get(item.categoryCode);
            mapAll.set(item.categoryCode, temp.concat(item));
          } else {
            mapAll.set(item.categoryCode, [].concat(item));
          }
        });
        setTotalLottery(mapAll);
        setSelectedMap(mapSelected);
      }
    }
  }, [companyEditInfo, isUpdate]);

  const toggleAllSelection = code => {
    const temp = new Map(JSON.parse(
      JSON.stringify(Array.from(selectedMap))
    ));
    if (temp.has(code)) {
      temp.delete(code);
    } else {
      const tempItem = totalLottery.get(code);
      temp.set(code, [].concat(tempItem));
    }
    setSelectedMap(temp);
  };

  const toggleSelection = item => {
    const temp = new Map(JSON.parse(
      JSON.stringify(Array.from(selectedMap))
    ));
    if (temp.has(item.categoryCode)) {
      if (temp.get(item.categoryCode).find(x => x.code === item.code)) {
        temp.set(item.categoryCode, temp.get(item.categoryCode).filter(x => x.code !== item.code));
      } else {
        temp.set(item.categoryCode, temp.get(item.categoryCode).concat(item));
      }
    } else {
      temp.set(item.categoryCode, [].concat(item));
    }
    setSelectedMap(temp);
  };

  const save = validTypeVal => {
    const lotteryTypes = [];
    if (selectedMap.size > 0) {
      selectedMap.forEach(item => {
        item.forEach(sub => {
          lotteryTypes.push(sub.code);
        });
      });
    }
    const obj = {
      id: isUpdate ? companyEditInfo.company.id : '',
      isUpdate,
      name,
      defaultAdmin,
      active,
      accountOption,
      password,
      logo,
      companyCode,
      urlLotteryResults,
      urlSearchEngine,
      templateMember,
      templateMemberStyle,
      templateAgent,
      templateUpdate,
      validType: validTypeVal === 0 ? validTypeVal : validType,
      enableEarnOdds,
      alsKey,

      lotteryTypes,
      allowLookup,
      showZdLogo,
      isPasswordUpper
    };
    saveCompanyInfo(obj, () => {});
  };

  const imageUploaded = async e => {
    const convertBase64 = file => new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = error => {
        reject(error);
      };
    });

    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setlogo(base64);
  };

  return (
    <div className="CompanySettingWrapper">
      <div className="Level2CompanyEditWrapper">
        <div className="sectionWrapper">
          <div className="tableTitleStyle">公司资料</div>
          <div className="tableSection labelCellBackground">
            <div className="rowStyle">
              <div>公司名称</div>
              <div>
                <Input
                  className="w-50"
                  value={name}
                  onChange={({ target: { value: text } }) => setname(text)}
                />
              </div>
            </div>
            <div className="rowStyle">
              <div>账号</div>
              <div>
                <Input
                  disabled={isUpdate}
                  className="w-50"
                  value={defaultAdmin}
                  onChange={({ target: { value: text } }) => setdefaultAdmin(text.trim().toLowerCase())}
                />
              </div>
            </div>
            <div className="rowStyle">
              <div>帐号状态</div>
              <div>
                <Radio.Group onChange={({ target: { value: text } }) => setactive(text)} value={active}>
                  <Radio value={1}>启用</Radio>
                  <Radio value={0}>停用</Radio>
                </Radio.Group>
              </div>
            </div>
            <div className="rowStyle">
              <div>帐户类型</div>
              <div>
                <Radio.Group disabled={isUpdate} onChange={({ target: { value: text } }) => setaccountOption(text)} value={accountOption}>
                  <Radio value={0}>单帐户额度</Radio>
                  <Radio value={1}>多帐户额度</Radio>
                </Radio.Group>
              </div>
            </div>
            <div className="rowStyle">
              <div>登入密码</div>
              <div>
                <Input className="w-50" value={password} onChange={({ target: { value: text } }) => setpassword(text.trim())} />
                <span>(默认123456)</span>
              </div>
            </div>
            <div className="rowStyle">
              <div>公司Logo</div>
              <div>
                {logo
                    && <img className="companyLogoPreview me-2" src={logo} alt="" />}
                <label htmlFor="files" className="operationBtn d-flex align-items-center">
                  上传
                  <input className="uploadBtn" type="file" name="" id="files" onChange={imageUploaded} />
                </label>
                {logo
                    && <div className="operationBtn cancel ms-2" onClick={() => setlogo('')}>删除</div>}
              </div>
            </div>
            <div className="rowStyle">
              <div>公司Code</div>
              <div>
                <Input className="w-50" value={companyCode} onChange={({ target: { value: text } }) => setcompanyCode(text.trim())} />
              </div>
            </div>
            <div className="rowStyle">
              <div>开奖网</div>
              <div>
                <Input placeholder="如：http://www.baidu.com" className="w-50" value={urlLotteryResults} onChange={({ target: { value: text } }) => seturlLotteryResults(text.trim())} />
                <span>(留空则不显示)</span>
              </div>
            </div>
            <div className="rowStyle">
              <div>导航网址</div>
              <div>
                <Input placeholder="如：http://www.baidu.com" className="w-50" value={urlSearchEngine} onChange={({ target: { value: text } }) => seturlSearchEngine(text.trim())} />
                <span>(留空则不显示)</span>
              </div>
            </div>
            <div className="rowStyle">
              <div>会员模版</div>
              <div>
                <Input className="w-50" value={templateMember} onChange={({ target: { value: text } }) => settemplateMember(text.trim())} />
              </div>
            </div>
            <div className="rowStyle">
              <div>会员样式</div>
              <div>
                <Select value={templateMemberStyle} onChange={val => settemplateMemberStyle(val)}>
                  {
                      [{
                        label: '蓝色', value: 'blue'
                      }, {
                        label: '绿色', value: 'green'
                      }, {
                        label: '红色', value: 'red'
                      }, {
                        label: '黄色', value: 'yellow'
                      }].map(el => <Option key={el.value} value={el.value}>{el.label}</Option>)
                    }
                </Select>
              </div>
            </div>
            <div className="rowStyle">
              <div>代理模版</div>
              <div>
                <Input className="w-50" value={templateAgent} onChange={({ target: { value: text } }) => settemplateAgent(text.trim())} />
              </div>
            </div>
            <div className="rowStyle">
              <div>同时更新上述模版信息</div>
              <div>
                <Select value={templateUpdate} onChange={val => settemplateUpdate(val)}>
                  {
                      [{
                        label: '同时更新模板', value: 1
                      }, {
                        label: '不更新模板', value: 0
                      }].map(el => <Option key={el.value} value={el.value}>{el.label}</Option>)
                    }
                </Select>
              </div>
            </div>
            <div className="rowStyle">
              <div>MFA验证</div>
              <div>
                {
                    validType === 1 ? (
                      <div
                        className="operationBtn reset"
                        onClick={() => save(0)}
                      >
                        重置
                      </div>
                    ) : '未设置'
                  }
              </div>
            </div>
            <div className="rowStyle">
              <div>代理赚赔功能</div>
              <div>
                <Radio.Group onChange={({ target: { value: text } }) => setenableEarnOdds(text)} value={enableEarnOdds}>
                  <Radio value={1}>启动</Radio>
                  <Radio value={0}>禁用</Radio>
                </Radio.Group>
              </div>
            </div>
            {companyEditInfo?.company?.levelValue === 2
            && (
            <div className="rowStyle">
              <div>二级公司允许外补</div>
              <div>
                <Radio.Group onChange={({ target: { value: text } }) => setallowLookup(text)} value={allowLookup}>
                  <Radio value={1}>允许</Radio>
                  <Radio value={0}>不允许</Radio>
                </Radio.Group>
              </div>
            </div>
            )}
            <div className="rowStyle">
              <div>总代支持Logo和域名</div>
              <div>
                <Radio.Group onChange={({ target: { value: text } }) => setshowZdLogo(text)} value={showZdLogo}>
                  <Radio value={0}>不支持</Radio>
                  <Radio value={1}>仅支持Logo</Radio>
                  <Radio value={2}>支持Logo和域名</Radio>
                </Radio.Group>
              </div>
            </div>
            <div className="rowStyle">
              <div>密码大小写设置</div>
              <div>
                <Radio.Group onChange={({ target: { value: text } }) => setisPasswordUpper(text)} value={isPasswordUpper}>
                  <Radio value={1}>启动</Radio>
                  <Radio value={0}>不启动</Radio>
                </Radio.Group>
              </div>
            </div>

          </div>
        </div>

        <div className="sectionWrapper">
          <div className="tableTitleStyle">彩种选择</div>
          <div className="tableSection labelCellBackground">
            {Array.from(totalLottery.values()).map(item => (
              <div className="rowStyle">
                <div>
                  <span className="me-3">{item[0].categoryName}</span>
                  <Checkbox onChange={() => toggleAllSelection(item[0].categoryCode)} checked={(selectedMap.size > 0 && selectedMap.has(item[0].categoryCode)) ? (selectedMap.get(item[0].categoryCode).length === totalLottery.get(item[0].categoryCode).length) : false}>所有</Checkbox>
                </div>
                <div>
                  {
                      item.map(ele => <Checkbox checked={selectedMap.size > 0 ? selectedMap.get(ele.categoryCode)?.find(x => x.code === ele.code) : false} onChange={() => toggleSelection(ele)} className="lotterySelection my-1">{ele.lotteryName}</Checkbox>)
                    }
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="d-flex justify-content-center py-3">
          <div className="operationBtn" onClick={() => save()}>保存</div>
          <div
            className="operationBtn ms-3 cancel"
            onClick={() => {
              if (helloFromUserManage) {
                return onCancel?.();
              }
            }}
          >
            取消
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanySetting);