import { useEffect, useState } from 'react';

import { Col, Label, Row } from 'reactstrap';
import { Button } from 'antd';
import { FaPlusCircle } from 'react-icons/fa';
import { connect } from 'react-redux';

import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { appActions, noticeActions } from '../../../redux/actions';
import AgentDataTable from '../../AgentDataTable';
import Pagination from '../../Content/Pagination';
import './styles.scss';


const mapStateToProps = state => ({
  noticeSettingData: state.noticeReducers.noticeSettingData,
  userData: state.memberReducers.userData,
  isValidNameMsg: state.agentReducers.isValidNameMsg,
  isValidLoginIdMsg: state.agentReducers.isValidLoginIdMsg,
});

const mapDispatchToProps = dispatch => ({
  GetNoticeSetting: (page, rows, callback = () => {}) => dispatch(noticeActions.GetNoticeSetting(page, rows, callback)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  updateLotteryGameStatus: (obj, callback) => dispatch(noticeActions.updateLotteryGameStatus(obj, callback)),
  deleteNotice: (obj, callback) => dispatch(noticeActions.deleteNotice(obj, callback)),
});

const SystemNotice = ({ callback = () => { }, noticeSettingData, GetNoticeSetting, data, updateModalAlert, deleteNotice }) => {
  const { t } = useTranslation();
  const [_info, setInfo] = useState([]);
  const [_page, setPage] = useState(1);
  const [_rows, setRows] = useState(15);
  const [_totalRecord, setTotalRecord] = useState(0);
  const [_isQueryingDetail, setIsQueryingDetail] = useState(true);

  useEffect(() => {
    if (noticeSettingData && JSON.stringify(noticeSettingData?.msgList) !== JSON.stringify(_info)) {
      setInfo(noticeSettingData?.msgList);
      setTotalRecord(noticeSettingData?.totalRecords);
    }
  }, [noticeSettingData]);

  useEffect(() => {
    setIsQueryingDetail(true);
    GetNoticeSetting(
      _page,
      _rows,
      () => setIsQueryingDetail(false)
    );
  }, []);

  const onPaginationClick = (pageNum, rows) => {
    setIsQueryingDetail(false);
    // Page number and row number will follow the pagination component instead
    GetNoticeSetting(
      pageNum,
      rows,
      () => setIsQueryingDetail(false)
    );
    setPage(pageNum);
    setRows(rows);
  };
  const deleteClick = id => {
    updateModalAlert({
      type: 'confirmation',
      visible: true,
      showCancelButton: true,
      data: {
        message: '确认删除',
        onConfirmationClick: () => deleteNotice(id)
      },
    });
  };

  const tableHeader = [{
    label: t('TIME'),
  }, {
    label: t('NOTICE_CONTENT'),
  }, {
    label: t('RECEIVER'),
  }, {
    label: t('SENDER'),
  }, {
    label: t('RECEIVE_METHOD'),
  }, {
    label: t('FUNCTION'),
  }];

  const tableContent = [
    data => (
      <div className="d-flex justify-content-center">
        <Label className="d-flex justify-content-center">
          {data?.date && moment.unix(data?.date).format('YYYY-MM-DD HH:mm:ss')}
        </Label>
      </div>
    ),
    data => (<Label className="d-flex justify-content-center">{data?.content}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.receiver}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.sender}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.type}</Label>),
    data => (
      <div className="d-flex justify-content-center align-items-center">
        <Button className="button handle mx-1" onClick={() => callback('publishNotices', data, true)}>{t('EDIT')}</Button>
        <Button className="button find mx-1" onClick={() => deleteClick(data?.id)}>{t('DELETE')}</Button>
      </div>
    ),
  ];

  return (
    <div className="SystemAnnoucementWrapper">
      <div className="mx-0 bg-white my-2  border-1">
        <Row className="p-2 m-0 mb-3 filterWrapper">
          <Col className="m-0 p-0 ">
            <Button
              className="button add justify-content-center"
              onClick={() => callback('publishNotices', data, false)}
            >
              <FaPlusCircle className="mb-1 mr-1" />
              <Label className="p-0 px-1">{t('PUBLISH_ANNOUCEMENT')}</Label>
            </Button>
          </Col>
        </Row>
        <Row className="bigWrapper m-0 my-1 p-3">
          <AgentDataTable
            isQuerying={_isQueryingDetail}
            tableHeaderTitle={tableHeader}
            tableData={_info || (_isQueryingDetail ? [1, 2, 3] : [])}
            tableBodyRowProperty={tableContent}
            tableBodyRowStyle={['col-1', 'col-5', 'col-1', 'col-1', 'col-1', 'col-1']}
          />
        </Row>
        <Row className="justify-center w-full relative">
          <Pagination totalRecord={_totalRecord || 0} onClick={onPaginationClick} isQuery={_isQueryingDetail} />
        </Row>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SystemNotice);