import { StatusCodes } from 'http-status-codes';

import { SET_GIFT } from '../actionTypes';
import { apiService } from '../../../library';
import appActions from '../appActions';

const setGift = data => ({
  type: SET_GIFT,
  value: data
});

const GetGift = (obj, callback = () => {}) => dispatch => {
  apiService.get('/Contest/GetGift', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      callback(false);
      return dispatch(setGift(data.result));
    }
  }).catch(error => {
    callback(false);
    dispatch(setGift());
    Promise.reject(error);
  });
};

const DelGift = (id, callback = () => {}) => dispatch => {
  apiService.post('/Contest/DelGift', id).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        return dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'success',
          data: {
            message: data.msg,
            onConfirmationClick: callback
          }
        }));
      }
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: true,
      type: 'confirmation',
      data: {
        message: error.response.data.msg
      }
    }));
    Promise.reject(error);
  });
};

const EnableGift = (giftId, callback = () => {}) => dispatch => {
  apiService.post('/Contest/EnableGift', giftId).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        return dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'success',
          data: {
            message: data.msg,
            onConfirmationClick: callback
          }
        }));
      }
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: true,
      type: 'confirmation',
      data: {
        message: error.response.data.msg
      }
    }));
    Promise.reject(error);
  });
};

const SaveEditGift = (obj, callback = () => {}) => dispatch => {
  apiService.post('/Contest/EditGift', obj).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        return dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'success',
          data: {
            message: data.msg,
            onConfirmationClick: callback
          }
        }));
      }
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: true,
      type: 'confirmation',
      data: {
        message: error.response.data.msg
      }
    }));
    Promise.reject(error);
  });
};

const SaveNewGift = (obj, callback = () => {}) => dispatch => {
  apiService.post('/Contest/SaveNewGift', obj).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        return dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'success',
          data: {
            message: data.msg,
            onConfirmationClick: callback
          }
        }));
      }
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: true,
      type: 'confirmation',
      data: {
        message: error.response.data.msg
      }
    }));
    Promise.reject(error);
  });
};

export default {
  GetGift,
  DelGift,
  SaveEditGift,
  SaveNewGift,
  EnableGift,
};