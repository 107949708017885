import { SET_BET_QUERY_RESULT, SET_BET_ZC_DETAIL } from '../../actions/actionTypes';

const initialState = {
  betQueryResult: undefined,
  betZCDetail: undefined,
};

const betListReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_BET_QUERY_RESULT:
      return {
        ...state, betQueryResult: action.value
      };
    case SET_BET_ZC_DETAIL:
      return {
        ...state, betZCDetail: action.value
      };
    default:
      return state;
  }
};

export default betListReducers;