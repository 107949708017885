
import { dxds, totalSumDS, totalSumDX, totaltailDX } from '../Items/dsdx';

import { dragonTiger, lianma11x5 } from '../Items/others';

import { number1to20 } from '../Items/numbers';

import { qsBall1to5, qsDirectChoose, qsLianma, qsSumBigSmall, qsSumSingleDouble, qsTailBigSmall, qsTwoFace, qsYZY } from '../Items/quickset';

const type11X5 = [{
  displayName: '两面',
  isStaticRow: 1,
  repeatPerRow: 5,
  settingCode: 'LM,ZHDS,ZHWDX',
  quickSet: {
    style: 2,
    variables: [[...qsTwoFace, ...qsSumBigSmall, ...qsSumSingleDouble, ...qsTailBigSmall]]
  },
  display: [[...dxds], [...dxds], [...dxds], [...dxds], [...dxds], [...totalSumDX, ...totalSumDS, ...totaltailDX, ...dragonTiger]],
  firstTableHeader: ['第一球', '第二球', '第三球', '第四球', '第五球', '总和'],
  prefix: ['B1', 'B2', 'B3', 'B4', 'B5', 'ZH']
}, {
  displayName: '单号1-5',
  settingCode: 'QH15,YZY',
  isStaticRow: 1,
  repeatPerRow: 3,
  quickSet: {
    style: 2,
    variables: [[...qsBall1to5, ...qsYZY]]
  },
  display: [[...number1to20.slice(0, 11)], [...number1to20.slice(0, 11)], [...number1to20.slice(0, 11)], [...number1to20.slice(0, 11)], [...number1to20.slice(0, 11)], [...number1to20.slice(0, 11)]],
  firstTableHeader: ['第一球', '第二球', '第三球', '第四球', '第五球', '一中一'],
  prefix: ['B1', 'B2', 'B3', 'B4', 'B5', 'YZY'],
}, {
  displayName: '连码、直选',
  settingCode: 'LIANMA,ZX',
  quickSet: {
    style: 2,
    variables: [[...qsDirectChoose, ...qsLianma]]
  },
  display: [...lianma11x5],
  horizontalDataAlign: 1,
  horizontalSet: 3,
}
];

export default type11X5;