import { useEffect, useRef, useState } from 'react';

import { Select } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import { useTranslation } from 'react-i18next';

import { GXKL10Number1to21, KL10balls, PCDD0to27, farmNumbers, number1to49, numberBlue1to80 } from '../../constants/OddsManageSetting/Items/numbers';
import { commonActions, inTimeSheetActions } from '../../redux/actions';
import IconRefresh from '../../assets/image/icon_refresh.svg';
import Notifications from '../Content/Notifications';
import Skeleton from '../Skeleton';
import gameItemIconMap from '../../constants/GameItemIcon';
import { zodiacArr } from '../../constants/OddsManageSetting/Items/quickset';
import './styles.scss';

const mapStateToProps = state => ({
  activeLottery: state.commonReducers.activeLottery,
  inTimeSheetInfo: state.inTimeSheetReducers.inTimeSheetInfo,
  userData: state.memberReducers.userData,
  userInfo: state.memberReducers.userInfo,
});

const mapDispatchToProps = dispatch => ({
  updateListOdds: val => dispatch(inTimeSheetActions.updateListOdds(val)),
  getInTimeSheetInfo: (lotteryCode, isTema, callback) => dispatch(inTimeSheetActions.getInTimeSheetInfo(lotteryCode, isTema, callback)),
  ToggleQishu: (obj, callback) => dispatch(inTimeSheetActions.ToggleQishu(obj, callback)),
  GetSubOrganizationList: callback => dispatch(commonActions.GetSubOrganizationList(callback)),
});

const zmtoptions = [{
  value: '-1',
  label: '全部'
}, {
  value: '正码特',
  label: '正码特A'
}, {
  value: '正码特B',
  label: '正码特B'
}];

const typeoptions = [{
  value: '1',
  label: '实占'
}, {
  value: '-1',
  label: '虚注'
}, {
  value: '2',
  label: '补货'
}];

const sortoptions = [{
  value: '0',
  label: '按号码排序'
}, {
  value: '1',
  label: '按盈亏排序'
}, {
  value: '2',
  label: '按金额排序'
}];

const timeoptions = [{
  value: 20,
  label: '20秒'
}, {
  value: 15,
  label: '15秒'
}, {
  value: 10,
  label: '10秒'
}];

const { Option } = Select;
momentDurationFormatSetup(moment);

const CurrentDrawPanel = ({
  userInfo,
  midSubCategory,
  setMidSubCategory,
  oddstype,
  setoddstype,
  sortMode,
  setSortMode,
  _midCode,
  ToggleQishu,
  _selectedItem,
  setSelectedItem,
  GetSubOrganizationList,
  userData,
  amountMode,
  setamountMode,
  inTimeSheetInfo,
  activeLottery,
  onRefreshClick,
  getInTimeSheetInfo,
  setAvailability = () => {}
}) => {
  const { t } = useTranslation();
  const [_timevalue, setTimeValue] = useState(20);
  const [_currentInstallment, setcurrentInstallment] = useState();

  const [drawResult, setDrawResult] = useState(Array(1).fill(<Skeleton isAnimated />));

  const savedCallback = useRef();
  const countDownRef = useRef();
  const gameInfoRef = useRef();
  const [_countDownTimer, setCountDownTimer] = useState(20);

  const [_dropdownList, setDropdownList] = useState([]);

  const generateDrawResult = () => {
    const drawResult = inTimeSheetInfo.currentInstallment.preLotteryResult.split(',');
    const preZodiacYear = inTimeSheetInfo.currentInstallment?.preZodiacYear || '';
    let Offset = 0;
    if (preZodiacYear) {
      Offset = [].concat(zodiacArr).findIndex(x => x === preZodiacYear);
    }
    let result;
    if (activeLottery.templateCode === 'BJK8') {
      const upItems = drawResult.slice(0, 10).map(item => {
        const _item = numberBlue1to80.find(x => x.displayName === item);
        return <div className={`${_item.className} mx-1`}>{item}</div>;
      });
      const downItems = drawResult.slice(10).map(item => {
        const _item = numberBlue1to80.find(x => x.displayName === item);
        return <div className={`${_item.className} mx-1`}>{item}</div>;
      });
      result = (
        <div>
          <div className="d-flex">{upItems}</div>
          <div className="d-flex">{downItems}</div>
        </div>
      );
    } else {
      result = drawResult.map((item, index, arr) => {
        let value;
        let className;
        if (activeLottery.templateCode === 'PK10') {
          className = `pk10Square square_${item}`;
          value = item;
        } else if (activeLottery.templateCode === 'SSC' || activeLottery.templateCode === '11X5') {
          if (activeLottery.code === 'HLSXSSC') {
            className = '';
            value = <img src={gameItemIconMap[`CJ_SSC_HLSX_BALL${item}`]} alt="" />;
          } else {
            className = 'sscBall ball_blue';
            value = item;
          }
        } else if (activeLottery.templateCode === 'GXK10') {
          const _item = GXKL10Number1to21.find(x => x.displayName === item);
          if (_item) {
            className = _item.className;
            value = item;
          }
        } else if (activeLottery.templateCode === 'HK6' || activeLottery.templateCode === 'F36X7' || activeLottery.templateCode === 'F31X7') {
          const _item = number1to49.find(x => Number(x.displayName) === Number(item));
          if (_item) {
            let zodiac = '';
            if (activeLottery.templateCode === 'HK6') {
              zodiac = zodiacArr[(Offset + 13 - (Number(item) % 12)) % 12];
            }

            if (index === arr.length - 1) {
              className = '';
              value = (
                <div className="d-flex align-items-center">
                  <div className="text-white fw-bold me-1">+</div>
                  <div>
                    <div className={`${_item.className}`}>{item}</div>
                    {activeLottery.templateCode === 'HK6' && <div className="text-center text-white">{zodiac}</div>}
                  </div>
                </div>
              );
            } else {
              className = '';
              value = (
                <div className="d-flex align-items-center">
                  <div>
                    <div className={`${_item.className}`}>{item}</div>
                    {activeLottery.templateCode === 'HK6' && <div className="text-center text-white">{zodiac}</div>}
                  </div>
                </div>
              );
            }
          }
        } else if (activeLottery.templateCode === 'K3') {
          className = '';
          value = <img src={gameItemIconMap[`CJ_DICE_${item}`]} alt="" />;
        } else if (activeLottery.templateCode === 'KL10') {
          if (activeLottery.code === 'CQXYNC') {
            const _item = farmNumbers.find(x => x.displayName === item);
            if (_item) {
              className = '';
              value = <img src={_item.img} alt="" />;
            }
          } else {
            const _item = KL10balls.find(x => x.displayName === item);
            if (_item) {
              className = _item.className;
              value = item;
            }
          }
        } else if (activeLottery.templateCode === 'PCDD') {
          const _item = PCDD0to27.find(x => x.displayName === item);
          if (_item) {
            className = _item.className;
            value = item;
          }
        } else if (activeLottery.templateCode === 'FANT') {
          if (index === arr.length - 1) {
            const total = [].concat(arr.slice(0, 4)).reduce((a, b) => parseInt(a, 10) + parseInt(b, 10), 0);
            className = '';
            value = (
              <div className="d-flex align-items-center">
                <div className="fantBall ball_blue">{item}</div>
                <div className="text-white fw-bold mx-1">+</div>
                <div className="fantBall ball_yellow">{total}</div>
              </div>
            );
          } else {
            className = '';
            value = <div className="underlineBalls"><div className="fantBall ball_blue">{item}</div></div>;
          }
        } else if (activeLottery.templateCode === 'QXC') {
          className = 'QXCBall';
          value = item;
        } else {
          value = item;
        }

        return <div className={`${className} mx-1`}>{value}</div>;
      });
    }
    return result;
  };

  useEffect(() => {
    if (_timevalue) {
      setCountDownTimer(_timevalue || 20);
      countDownRef.current = _timevalue || 20;
    }
  }, [_timevalue]);

  useEffect(() => {
    if (activeLottery) {
      setcurrentInstallment();
      setDrawResult(Array(1).fill(<Skeleton isAnimated />));
    }
  }, [activeLottery]);

  useEffect(() => {
    if (inTimeSheetInfo && inTimeSheetInfo?.currentInstallment) {
      const arr = generateDrawResult();
      setDrawResult([].concat(arr));
    }
  }, [inTimeSheetInfo?.currentInstallment]);

  useEffect(() => {
    if (inTimeSheetInfo) {
      setcurrentInstallment(inTimeSheetInfo.currentInstallment);
      gameInfoRef.current = inTimeSheetInfo.currentInstallment;
    } else {
      setcurrentInstallment();
      gameInfoRef.current = null;
    }
  }, [inTimeSheetInfo]);

  useEffect(() => {
    savedCallback.current = () => {
      if (_currentInstallment && _currentInstallment.openTimeStamp === 0) {
        const tempGameInfo = {
          ...gameInfoRef.current
        };
        if (_currentInstallment.status !== 3) {
          getInTimeSheetInfo(activeLottery.code, _midCode ? _midCode.midCode.indexOf('TM') > -1 ? 1 : 2 : 0, onRefreshClick);
        }
        setcurrentInstallment(tempGameInfo);
        setAvailability(false);
      }
      if (_currentInstallment) {
        if (
          ((inTimeSheetInfo?.orgCtrlSetting === 1 && userData?.levelValue === inTimeSheetInfo?.info?.zdLevel) || (userData?.levelValue < inTimeSheetInfo?.info?.zdLevel))
          && _currentInstallment && _currentInstallment.closeTimeStamp - 1 > 0 && _currentInstallment.openTimeStamp > 0 && _currentInstallment.status === 1) {
          setAvailability(true);
        } else {
          setAvailability(false);
        }
        gameInfoRef.current = {
          ..._currentInstallment,
          closeTimeStamp: _currentInstallment.closeTimeStamp - 1 < 0 ? 0 : _currentInstallment.closeTimeStamp - 1,
          openTimeStamp: _currentInstallment.openTimeStamp - 1 < 0 ? 0 : _currentInstallment.openTimeStamp - 1,
        };
        setcurrentInstallment({
          ..._currentInstallment,
          closeTimeStamp: _currentInstallment.closeTimeStamp - 1 < 0 ? 0 : _currentInstallment.closeTimeStamp - 1,
          openTimeStamp: _currentInstallment.openTimeStamp - 1 < 0 ? 0 : _currentInstallment.openTimeStamp - 1,
        });
      }

      if (_countDownTimer === 0) {
        onRefreshClick();
        setCountDownTimer(_timevalue);
        countDownRef.current = _timevalue;
      } else {
        countDownRef.current = _countDownTimer - 1;
        setCountDownTimer(_countDownTimer - 1);
      }
    };
  });

  useEffect(() => {
    let id;
    const tick = () => {
      savedCallback.current();
    };
    if (!id) {
      id = setInterval(tick, 1000);
    }
    return () => {
      clearInterval(id);
    };
  }, []);

  useEffect(() => {
    if (userData && _selectedItem && _selectedItem.levelValue <= 3) {
      GetSubOrganizationList(oddsDiffUpOrgsData => {
        const temp = [{
          ..._selectedItem
        }].concat(oddsDiffUpOrgsData.map(item => ({
          ...item, subOrgId: item.organizationId, name: `${item.loginId}[${item.name}]`
        })));
        setDropdownList(temp);
      });
    }
  }, [userData]);

  const onSelectChange = val => {
    const temp = _dropdownList.find(x => x.organizationId === val);
    setSelectedItem({
      ...temp
    });
  };

  const onClickToggle = () => {
    const obj = {
      lotteryType: activeLottery?.code,
      temaType: _midCode ? _midCode.midCode.indexOf('TM') > -1 ? 1 : 0 : 0
    };
    const callback = () => {
      getInTimeSheetInfo(activeLottery.code, _midCode ? _midCode.midCode.indexOf('TM') > -1 ? 1 : 2 : 0);
    };

    ToggleQishu(obj, callback);
  };


  return (
    <div className=" currentDrawPanel ">
      <div className="currentInstallment">
        <span>{_currentInstallment ? _currentInstallment?.currentInstallmentNo : <Skeleton isAnimated />}</span>
        {t('INSTALLMENT')}
      </div>
      {_currentInstallment ? (
        _currentInstallment && _currentInstallment.closeTimeStamp > 0
          ? (
            <div className="timeToClose">
              {`${t('TIME_TO_CLOSE')} : `}
              <span>
                {moment.duration((_currentInstallment && _currentInstallment.closeTimeStamp >= 0 && _currentInstallment.closeTimeStamp) || 0, 'second').format('hh:mm:ss', {
                  trim: false
                })}
              </span>
            </div>
          )
          : (
            <div className="timeToClose">
              {`${t('TIME_TO_OPEN_RESULT')} : `}
              <span>
                {moment.duration((_currentInstallment && _currentInstallment.openTimeStamp >= 0 && _currentInstallment.openTimeStamp) || 0, 'second').format('hh:mm:ss', {
                  trim: false
                })}
              </span>
            </div>
          )
      ) : (
        <div className="timeToClose">
          {`${t('TIME_TO_CLOSE')} : `}
          <span>
            <Skeleton isAnimated />
          </span>
        </div>
      )}
      <div className="todayProfit">
        {`${t('TODAY_PROFIT')} : `}
        <span>{inTimeSheetInfo?.todayResult ?? '--'}</span>
      </div>
      <div className="preInstallment">
        <span>{_currentInstallment ? _currentInstallment.preInstallmentNo : <Skeleton isAnimated />}</span>
        {`${t('INSTALLMENT_DRAW')} : `}
      </div>
      <div className="drawResultWrapper">
        {drawResult}
      </div>
      {
      (_currentInstallment && _currentInstallment?.isRndNum)
        ? (<div className="text-white me-1">{`${t('RND_NUM')}: ${_currentInstallment?.rndNum || '--'}`}</div>)
        : ''
      }
      <div className="operationSection">
        { (userData && userData.levelValue <= 3)
      && (
      <Select
        className="dropDownList me-1"
        showSearch
        placeholder="Select a person"
        optionFilterProp="children"
        onSearch={() => {}}
        value={_selectedItem.organizationId}
        onChange={code => onSelectChange(code)}
        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
      >
        {_dropdownList.map(item => <Option value={item.organizationId}>{item.name}</Option>)}
      </Select>
      )}
        <Select
          className="amountModeSelect"
          value={amountMode}
          placeholder="请选择"
          onChange={val => setamountMode(val)}
        >
          {
          typeoptions.map(el => <Option key={el.value} value={el.value}>{el.label}</Option>)
        }
        </Select>
        { userInfo?.supportZmt && activeLottery?.templateCode === 'HK6' && (_midCode?.midCode ?? '').includes('ZMT')
          ? (
            <Select
              className="zmtModeSelect ms-1"
              value={midSubCategory}
              placeholder="请选择"
              onChange={val => setMidSubCategory(val)}
            >
              {
                zmtoptions.map(el => <Option key={el.value} value={el.value}>{el.label}</Option>)
              }
            </Select>
          )
          : ''}
        <Select
          className="ms-1 oddstypeSelect"
          value={oddstype}
          placeholder="请选择"
          onChange={val => setoddstype(val)}
        >
          {
          [{
            value: '', label: '全部'
          }].concat(userInfo && userInfo?.gameType ? userInfo.gameType.split('').map(item => ({
            value: item, label: `${item}盘`
          })) : []).map(el => <Option key={el.value} value={el.value}>{el.label}</Option>)
        }
        </Select>
        <Select
          className="ms-1 sortModeSelect"
          value={sortMode}
          placeholder="请选择"
          onChange={val => setSortMode(val)}
        >
          {
          sortoptions.map(el => <Option key={el.value} value={el.value}>{el.label}</Option>)
        }
        </Select>
        <Select
          className="ms-1"
          value={_timevalue}
          placeholder="请选择"
          onChange={val => setTimeValue(val)}
        >
          {
          timeoptions.map(el => <Option key={el.value} value={el.value}>{el.label}</Option>)
        }
        </Select>
        <div className="d-flex align-items-center ms-1">
          <img src={IconRefresh} alt="" className="refreshIcon" onClick={() => {}} />
          <div className="countDownTimer">
            {`${_countDownTimer < 10 ? `0${_countDownTimer}` : _countDownTimer} S`}
          </div>
        </div>
        {
      _currentInstallment && _currentInstallment?.status <= 2 && activeLottery?.templateCode === 'HK6' && userData?.levelValue <= 3
      && <div className="refreshButton mx-1" onClick={() => onClickToggle()}>{_currentInstallment.status === 1 ? '关盘' : '开盘'}</div>
      }
        <div className="refreshButton mx-1" onClick={() => onRefreshClick()}>{t('REFRESH')}</div>
      </div>
      <Notifications />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CurrentDrawPanel);