import { useEffect, useState } from 'react';

import { AnimatePresence, motion } from 'framer-motion';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import AuthContent from '../AuthContent';
import AuthFooter from '../AuthFooter';
import AuthHeader from '../AuthHeader';
import { authWebRoutes } from '../../../routes';

import { appActions, commonActions, thirdPartyGamesActions } from '../../../redux/actions';
import cnImg from '../../../assets/image/lang_cn.png';
import enImg from '../../../assets/image/lang_en.png';

import './styles.scss';

const mapStateToProps = state => ({
  themeColor: state.appReducers.themeColor,
  userData: state.memberReducers.userData,
  activeLottery: state.commonReducers.activeLottery,
  clickReloadFlag: state.appReducers.clickReloadFlag,
});

const mapDispatchToProps = dispatch => ({
  GetLanguage: (orgId, callback) => dispatch(commonActions.GetLanguage(orgId, callback)),
  SetLanguage: (obj, callback) => dispatch(commonActions.SetLanguage(obj, callback)),
  GetInfo: () => dispatch(thirdPartyGamesActions.GetInfo()),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
});

const AuthLayout = props => {
  const { i18n } = useTranslation();
  const [passData, setpassData] = useState();
  const { updateModalAlert, themeColor, userData, activeLottery, clickReloadFlag = 1, GetLanguage, SetLanguage, GetInfo } = props || {};

  const _menuList = [
    {
      label: 'INTIME_SHEET',
      url: '/agent/in-time-sheet',
      id: '1',
      menuName: '即时注单',
      settingsList: [{
        label: 'BET_TASK_BACKUP',
        id: '302',
        menuName: '注单任务备份',
        tabId: 'IS_BET_TASK_BACKUP'
      }, {
        label: 'CHECK',
        id: '44',
        menuName: '查询',
        tabId: 'IS_CHECK'
      }, {
        label: 'AUTO_RESTOCK_SETTINGS',
        id: '15',
        menuName: '自动补货设置',
        tabId: 'IS_AUTO_RESTOCK_SETTINGS'
      }, {
        label: 'RESTOCK_RECORDS',
        id: '16',
        menuName: '补货变更记录',
        tabId: 'IS_RESTOCK_RECORDS'
      }, {
        label: 'REVOKE',
        id: '45',
        menuName: '撤销',
        tabId: 'IS_REVOKE'
      }]
    }, {
      label: 'USER_MANAGEMENT',
      url: '/agent/user-management',
      id: '2',
      menuName: '用户管理',
      settingsList: [{
        label: 'ZSDL',
        id: 'directAgentCount',
        menuName: '直属代理',
        tabId: 'UM_ZSDL'
      }, {
        label: 'ZSHY',
        id: 'directMemberCount',
        menuName: '直属会员',
        tabId: 'UM_ZSHY'
      }, {
        label: 'XJDL',
        id: 'agentCount',
        menuName: '下级代理',
        tabId: 'UM_XJDL'
      }, {
        label: 'XJHY',
        id: 'memberCount',
        menuName: '下级会员',
        tabId: 'UM_XJHY'
      }, {
        label: 'SYDL',
        id: 'totalAgent',
        menuName: '所有代理',
        tabId: 'UM_SYDL'
      }, {
        label: 'QBHY',
        id: 'totalMember',
        menuName: '全部会员',
        tabId: 'UM_QBHY'
      }, {
        label: 'ZSZZH',
        id: 'directSubAccountCount',
        menuName: '直属子帐号',
        tabId: 'UM_ZSZZH'
      }, {
        label: 'XJZZH',
        id: 'subAccountCount',
        menuName: '下级子帐号',
        tabId: 'UM_XJZZH'
      }, {
        label: 'ZZH',
        id: 'directSubAccountCount',
        menuName: '子帐号',
        tabId: 'UM_ZZH'
      }]
    }, {
      label: 'BET_WARNING',
      url: '/agent/bet-warning',
      id: '11',
      menuName: '注单预警',
      settingsList: [{
        label: 'BOGUS_BET_WARNING_MONITOR',
        id: '110',
        menuName: '注单预警监控',
        tabId: 'BW_BOGUS_BET_WARNING_MONITOR'
      }, {
        label: 'SET_SUGGESTION_AMOUNT',
        id: '111',
        menuName: '设置提示金额',
        tabId: 'BW_SET_SUGGESTION_AMOUNT'
      }, {
        label: 'BOGUS_BET_WARNING',
        id: '113',
        menuName: '连号预警设置',
        tabId: 'BW_BOGUS_BET_WARNING'
      }, {
        label: 'BIG_BET_LIMIT',
        id: '114',
        menuName: '大额限注设置',
        tabId: 'BW_BIG_BET_LIMIT'
      }, {
        label: 'QUICK_BET_MONITOR',
        id: '117',
        menuName: '快捷注单监控',
        tabId: 'BW_QUICK_BET_MONITOR'
      }, {
        label: 'ABNORMAL_BET_QUERY',
        id: '118',
        menuName: '异常注单查询',
        tabId: 'BW_ABNORMAL_BET_QUERY'
      }, {
        label: 'BIG_BET_BUHUO_SETTING',
        id: '119',
        menuName: '大额补货设置',
        tabId: 'BW_BIG_BET_BUHUO_SETTING'
      }, {
        label: 'FOLLOW_BET_SETTING',
        id: '905',
        menuName: '跟单设置',
        tabId: 'BW_FOLLOW_BET_SETTING'
      }
      ]
    }, {
      label: 'WALLET_MANAGEMENT',
      id: '90',
      menuName: '个人钱包',
      url: '/agent/wallet-management',
      settingsList: [{
        label: 'DEPOSIT',
        id: '76',
        menuName: '存款',
        tabId: 'WM_DEPOSIT'
      }, {
        label: 'WITHDRAWAL',
        id: '77',
        menuName: '提款',
        tabId: 'WM_WITHDRAWAL'
      }, {
        label: 'SAFETY_SETTING',
        id: '88',
        menuName: '安全设置',
        tabId: 'WM_SAFETY_SETTING'
      }, {
        label: 'TRANSFER',
        id: '78',
        menuName: '转账',
        tabId: 'WM_TRANSFER'
      }, {
        label: 'CARD_SETTING',
        id: '89',
        menuName: '银行卡设置',
        tabId: 'WM_CARD_SETTING'
      }, {
        label: 'TRANSCTION_HISTORY',
        id: '79',
        menuName: '交易历史',
        tabId: 'WM_TRANSCTION_HISTORY'
      }]
    }, {
      label: 'INDIVIDUAL_MANAGEMENT',
      url: '/agent/individual-management',
      id: '3',
      menuName: '个人管理',
      settingsList: [{
        label: 'USER_PROFILE',
        id: '18',
        menuName: '用户资料',
        tabId: 'IM_USER_PROFILE'
      }, {
        label: 'OPERATE_RECORD',
        id: '43',
        menuName: '操作日记',
        tabId: 'IM_RECORD'
      }, {
        label: 'LOGIN_LOG',
        id: '19',
        menuName: '登录日志',
        tabId: 'IM_LOGIN_LOG'
      }, {
        label: 'CHANGE_PASSWORD',
        id: '20',
        menuName: '变更密码',
        tabId: 'IM_CHANGE_PASSWORD'
      }, {
        label: 'HISTORY_TASK',
        id: '69',
        menuName: '历史任务',
        tabId: 'IM_HISTORY_TASK'
      }, {
        label: 'MFA_SETTING',
        id: '3',
        menuName: 'MFA设置',
        tabId: 'IM_MFA_SETTING'
      }]
    }, {
      label: 'TRANSACTION_MANAGEMENT',
      url: '/agent/transaction-management',
      id: '60',
      menuName: '交易管理',
      settingsList: [{
        label: 'TRANSCTION_QUERY',
        id: '61',
        menuName: '交易查询',
        tabId: 'TM_TRANSCTION_QUERY'
      }, {
        label: 'HANDLE_AGENT_RETURN',
        id: '93',
        menuName: '处理代理赚水',
        tabId: 'TM_HANDLE_AGENT_RETURN'
      }, {
        label: 'DEPOSIT_AUDIT',
        id: '63',
        menuName: '存款审核',
        tabId: 'TM_DEPOSIT_AUDIT'
      }, {
        label: 'WITHDRAWAL_AUDIT',
        id: '64',
        menuName: '提款审核',
        tabId: 'TM_WITHDRAWAL_AUDIT'
      }, {
        label: 'AMOUNT_CHANGE',
        id: '65',
        menuName: '人工加/减额',
        tabId: 'TM_AMOUNT_CHANGE'
      }, {
        label: 'MESSAGE_CENTER',
        id: '74',
        menuName: '消息中心',
        tabId: 'TM_MESSAGE_CENTER'
      }, {
        label: 'ACCOUNT_DETAIL',
        id: '95',
        menuName: '帐户流水',
        tabId: 'TM_ACCOUNT_DETAIL'
      }, {
        label: 'VOUCHER',
        id: '6010',
        menuName: '优惠码',
        tabId: 'TM_VOUCHER'
      }, {
        label: 'GIFT_SETTING',
        id: '6011',
        menuName: '奖品设置',
        tabId: 'TM_GIFT_SETTING'
      }, {
        label: 'GAME_TRANSFER_AUDIT',
        id: '6013',
        menuName: '游戏转账审核',
        tabId: 'TM_GAME_TRANSFER_AUDIT'
      }, {
        label: 'OFFLINE_AUDIT',
        id: '6014',
        menuName: '线下审核',
        tabId: 'TM_OFFLINE_AUDIT'
      }, {
        label: 'ONLINE_RECORD',
        id: '6015',
        menuName: '线上记录',
        tabId: 'TM_ONLINE_RECORD'
      }, {
        label: 'USDT_WITHDRAWAL_AUDIT',
        id: '6017',
        menuName: 'USDT提款审核',
        tabId: 'TM_USDT_WITHDRAWAL_AUDIT'
      }]
    },
    {
      label: 'ODDS_MANAGEMENT',
      url: '/agent/odds-management',
      id: '4',
      menuName: '赔率设置',
      settingsList: [{
        label: 'FUTURE_ODDS',
        id: '400',
        menuName: '未来赔率',
        tabId: 'OM_FUTURE_ODDS'
      }, {
        label: 'CURRENT_ODDS',
        id: '21',
        menuName: '当前赔率',
        tabId: 'OM_CURRENT_ODDS'
      }, {
        label: 'DEFAULT_ODDS',
        id: '22',
        menuName: '默认赔率',
        tabId: 'OM_DEFAULT_ODDS'
      }, {
        label: 'DECREASE_ODDS_SETTING',
        id: '23',
        menuName: '降赔设置',
        tabId: 'OM_DECREASE_ODDS_SETTING'
      }, {
        label: 'COMPANY_RETURN',
        id: '27',
        menuName: '公司退水、赔率差',
        tabId: 'OM_COMPANY_RETURN'
      }, {
        label: 'DEFAULT_WIN_LOST',
        id: '112',
        menuName: '默认赚赔',
        tabId: 'OM_DEFAULT_WIN_LOST'
      }, {
        label: 'EXTERNAL_DECREASE_SETTING',
        id: '115',
        menuName: '外网降赔设置',
        tabId: 'OM_EXTERNAL_DECREASE_SETTING'
      }]
    },
    {
      label: 'REPORT_MANAGEMENT',
      url: '/agent/report-management',
      id: '6',
      menuName: '报表查询',
      settingsList: [{
        label: 'WIN_LOSE_REPORT',
        id: '134',
        menuName: '输赢报表',
        tabId: 'RM_WIN_LOSE_REPORT'
      }, {
        label: 'CATEGORY_REPORT',
        id: '86',
        menuName: '分类报表',
        tabId: 'RM_CATEGORY_REPORT'
      }, {
        label: 'COMPANY_GAME_REPORT',
        id: '129',
        menuName: '公司游戏报表',
        tabId: 'RM_COMPANY_GAME_REPORT'
      }, {
        label: 'MEMBER_GAME_REPORT',
        id: '130',
        menuName: '会员游戏报表',
        tabId: 'RM_MEMBER_GAME_REPORT'
      }]
    },
    {
      label: 'SYSTEM_SETTINGS',
      url: '/agent/system-settings',
      id: '5',
      menuName: '系统设置',
      settingsList: [
        {
          label: 'BET_TASK_BACKUP',
          id: '303',
          menuName: '注单任务备份(全部)',
          tabId: 'SS_BET_TASK_BACKUP',
        },
        {
          label: 'LOTTERY_MANAGEMENT',
          id: '51',
          menuName: '彩种管理',
          tabId: 'SS_LOTTERY_MANAGEMENT',
        }, {
          label: 'SYSTEM_SETTING',
          id: '25',
          menuName: '系统设置',
          tabId: 'SS_SYSTEM_SETTING',
        }, {
          label: 'COMPANY_INFO',
          id: '26',
          menuName: '公司信息',
          tabId: 'SS_COMPANY_INFO',
        }, {
          label: 'SYSTEM_NOTICE',
          id: '28',
          menuName: '系统公告',
          tabId: 'SS_SYSTEM_NOTICE',
        }, {
          label: 'SYSTEM_LOG',
          id: '42',
          menuName: '系统日志',
          tabId: 'SS_SYSTEM_LOG',
        }, {
          label: 'OUTSIDE_WEB_SETTING',
          id: '47',
          menuName: '外补网站管理',
          tabId: 'SS_OUTSIDE_WEB_SETTING',
        }, {
          label: 'MEMBER_DAY_BILL',
          id: '83',
          menuName: '会员日结账单',
          tabId: 'SS_MEMBER_DAY_BILL',
        }, {
          label: 'MEMBER_CREDIT_CHANGE',
          id: '84',
          menuName: '会员额度变更',
          tabId: 'SS_MEMBER_CREDIT_CHANGE',
        }, {
          label: 'OPEN_RESULT_ADDRESS_MANAGEMENT',
          id: '55',
          menuName: '开奖地址管理',
          tabId: 'SS_OPEN_RESULT_ADDRESS_MANAGEMENT',
        }, {
          label: 'WHITE_LIST_MANAGEMENT',
          id: '56',
          menuName: '白名单管理',
          tabId: 'SS_WHITE_LIST_MANAGEMENT',
        }, {
          label: 'AGENT_BACKUP_WHITE_LIST',
          id: '57',
          menuName: '备份权限设置',
          tabId: 'SS_AGENT_BACKUP_WHITE_LIST',
        }, {
          label: 'SYSTEM_SERVICE_MANAGEMENT',
          id: '58',
          menuName: '系统服务管理',
          tabId: 'SS_SYSTEM_SERVICE_MANAGEMENT',
        }, {
          label: 'ABNORMAL_BET_EXPORT',
          id: '116',
          menuName: '异常注单导出',
          tabId: 'SS_ABNORMAL_BET_EXPORT',
        }, {
          label: 'CREDIT_MANAGEMENT',
          id: '59',
          menuName: '额度管理',
          tabId: 'SS_CREDIT_MANAGEMENT',
        }, {
          label: 'OPEN_RESULT_SETTING',
          id: '304',
          menuName: '官网开奖网设置',
          tabId: 'SS_OPEN_RESULT_SETTING',
        }, {
          label: 'LOTTERY_STYLE_MANAGEMENT',
          id: '305',
          menuName: '彩种样式管理',
          tabId: 'SS_LOTTERY_STYLE_MANAGEMENT',
        }, {
          label: 'DOMAIN_MANAGEMENT',
          id: '306',
          menuName: '域名管理',
          tabId: 'SS_DOMAIN_MANAGEMENT',
        },]
    },
    {
      label: 'INSTALLMENTS_MANAGEMENT',
      url: '/agent/installment-management',
      id: '7',
      menuName: '盘口管理',
      settingsList: [{
        label: 'INSTALLMENTS_MANAGEMENT',
        id: '52',
        menuName: '盘口管理',
        tabId: 'IM_INSTALLMENTS_MANAGEMENT'
      }, {
        label: 'HISTORY_RESULT_MANAGEMENT',
        id: '53',
        menuName: '历史开奖管理',
        tabId: 'IM_HISTORY_RESULT_MANAGEMENT',
      }]
    }, {
      label: 'GAME_RESULT_MANAGEMENT',
      url: '/agent/game-result-management',
      id: '8',
      menuName: '开奖结果',
      settingsList: [{
        label: 'GAME_RESULT_MANAGEMENT',
        id: '127',
        menuName: '开奖结果',
        url: ''
      }]
    }, {
      label: 'ONLINE_STATISTICS',
      url: '/agent/online-statistics',
      id: '9',
      menuName: '在线统计',
      settingsList: [{
        label: 'ONLINE_STATISTICS',
        id: '128',
        menuName: '在线统计',
        url: ''
      }]
    }, {
      label: 'COMPANY_MANAGEMENT',
      url: '/agent/company-management',
      id: '10',
      menuName: '公司管理',
      settingsList: [{
        label: 'COMPANY_SETTING',
        id: '31',
        menuName: '公司设置',
        tabId: 'CM_COMPANY_SETTING',
      }, {
        label: 'DEFAULT_ODDS',
        id: '32',
        menuName: '默认赔率',
        tabId: 'CM_DEFAULT_ODDS',
      }, {
        label: 'LOTTERY_SETTING',
        id: '50',
        menuName: '彩种设置',
        tabId: 'CM_LOTTERY_SETTING',
      }, {
        label: 'DEFAULT_COMPANY_RETURN',
        id: '33',
        menuName: '默认退水、赔率差',
        tabId: 'CM_COMPANY_RETURN',
      }, {
        label: 'DEFAULT_AUTO_RESTOCK_SETTINGS',
        id: '34',
        menuName: '默认自动补货设置',
        tabId: 'CM_AUTO_RESTOCK_SETTINGS',
      }, {
        label: 'DEFAULT_DECREASE_ODDS_SETTING',
        id: '35',
        menuName: '默认降赔设置',
        tabId: 'CM_DECREASE_ODDS_SETTING',
      }, {
        label: 'CHANGE_PASSWORD',
        id: '36',
        menuName: '变更密码',
        tabId: 'CM_CHANGE_PASSWORD',
      }, {
        label: 'SYSTEM_PERMISSION_SETTING',
        id: '37',
        menuName: '系统权限设置',
        tabId: 'CM_SYSTEM_PERMISSION_SETTING',
      }, {
        label: 'COMPANY_HOSTNAME_SETTING',
        id: '54',
        menuName: '公司域名配置',
        tabId: 'CM_COMPANY_HOSTNAME_SETTING',
      }, {
        label: 'SYSTEM_SETTINGS',
        id: '39',
        menuName: '系统设置',
        tabId: 'CM_SYSTEM_SETTINGS',
      }, {
        label: 'DOMAIN_MANAGEMENT',
        id: '60',
        menuName: '域名管理',
        tabId: 'CM_DOMAIN_MANAGEMENT',
      }, {
        label: 'GAME_MANAGEMENT',
        id: '97',
        menuName: '游戏管理',
        tabId: 'CM_GAME_MANAGEMENT',
      }]
    }, {
      label: 'SUPER_USER_LIST',
      url: '/agent/super-user-list',
      id: '',
      menuName: '超级用户列表',
      settingsList: [{
        label: 'SUPER_USER_LIST',
        id: '',
        menuName: '超级用户列表',
        url: ''
      }]
    }, {
      label: 'THIRD_PARTY_GAME_MANAGEMENT',
      url: '/agent/third-party-game-management',
      id: '12',
      menuName: '第三方游戏',
      settingsList: [{
        label: 'GAME_SETTING',
        id: '120',
        menuName: '游戏设置',
        tabId: 'TG_GAME_SETTING',
      }, {
        label: 'BETTING_QUERY',
        id: '121',
        menuName: '注单查询',
        tabId: 'TG_BETTING_QUERY',
      }, {
        label: 'CHECK_RETURN',
        id: '125',
        menuName: '处理赚水',
        tabId: 'TG_CHECK_RETURN',
      }, {
        label: 'TRANSACTION_MANAGEMENT',
        id: '126',
        menuName: '交易管理',
        tabId: 'TG_TRANSACTION_MANAGEMENT',
      }]
    }, {
      label: 'TOTAL_ONLINE_STATISTICS',
      url: '/agent/total-online-statistics',
      id: '901',
      menuName: '总在线统计',
      settingsList: [{
        label: 'TOTAL_ONLINE_STATISTICS',
        id: '132',
        menuName: '总在线统计',
        url: ''
      }]
    }, {
      label: 'LOG_OUT',
      url: '/agent/logout',
      id: 'logout',
      menuName: '退出系统',
      settingsList: []
    }];

  const [menuList, setMenuList] = useState(_menuList);

  useEffect(() => {
    if (userData) {
      GetLanguage('', data => i18n.changeLanguage(data));
      if (userData?.isThirdPartyEnabled === 1 && userData?.levelValue !== 0) {
        GetInfo();
      }
      if (userData?.status === 2) {
        updateModalAlert({
          visible: true,
          type: 'confirmation',
          data: {
            message: '该帐号已被暂停（只限账务功能可用），请与上级联系!',
            showCancelButton: false
          }
        });
      }
    }
  }, [userData]);

  const onChangeLanguage = data => {
    SetLanguage({
      orgId: '', language: data
    }, () => i18n.changeLanguage(data));
  };

  useEffect(() => {
    if (userData) {
      const _ls = [];
      let tempList = userData.userMenu;
      if (userData?.status === 2) {
        tempList = tempList.filter(x => (x.id === '3' || x.id === '6' || x.id === '8'));
      }
      [].concat(tempList).filter(item => (item?.childMenu && item?.childMenu.length > 0)).forEach(item => {
        const _sub = _menuList.find(x => x.id === item.id);
        if (_sub) {
          const subMenu = [];
          if (_sub.id === '2') {
            [].concat(item.childMenu).forEach(ele => {
              const _item = _sub.settingsList.find(x => ele.menuName.includes(x.menuName));
              if (_item) {
                subMenu.push({
                  ..._item
                });
              }
            });
          } else {
            [].concat(item.childMenu).forEach(ele => {
              const _item = _sub.settingsList.find(x => x.id === ele.id);
              if (_item) {
                subMenu.push({
                  ..._item
                });
              }
            });
          }
          if (_sub.id === '1') {
            _ls.push({
              ..._sub,
              settingsList: subMenu.concat({
                label: 'BLOCK_NUMBER_SETTING',
                id: '',
                menuName: '封号设置',
                tabId: 'IS_BLOCK_NUMBER_SETTING'
              })
            });
          } else {
            _ls.push({
              ..._sub,
              settingsList: (_sub.id === '3' || _sub.id === '10') ? subMenu.concat({
                label: 'MFA_SETTING',
                id: '3',
                menuName: 'MFA设置',
                tabId: 'IM_MFA_SETTING'
              }) : subMenu
            });
          }
        }
      });
      setMenuList([].concat(_ls, userData.levelValue === 0 ? [].concat([{
        label: 'SUPER_USER_LIST',
        url: '/agent/super-user-list',
        id: '',
        menuName: '超级用户列表',
        settingsList: [{
          label: 'SUPER_USER_LIST',
          id: '',
          menuName: '超级用户列表',
          url: ''
        }]
      }]) : [], [{
        label: 'LOG_OUT',
        url: '/agent/logout',
        id: 'logout',
        menuName: '退出系统',
        settingsList: []
      }]));
    }
  }, [userData]);

  useEffect(() => {
    setpassData();
  }, [activeLottery]);

  const change = (info = [], query, sub, midcode) => {
    setpassData([info, query, sub, midcode]);
  };

  return (
    <div className={`authLayout theme-${themeColor || 'blue'}`}>
      <AuthHeader {...props} menuList={menuList} change={change} />
      <AuthContent {...props} menuList={menuList}>
        <Route
          render={({ location }) => (
            <AnimatePresence exitBeforeEnter initial={false}>
              <Switch location={location} key={location.pathname}>
                {
                  authWebRoutes.map(({ path, component: Component }) => (
                    <Route path={`/agent${path}`} exact key={path}>
                      <motion.div
                        className="authContentArea"
                        initial={{
                          opacity: 0
                        }}
                        animate={{
                          opacity: 1
                        }}
                        exit={{
                          opacity: 0
                        }}
                        transition={{
                          duration: 0.2
                        }}
                      >
                        {Component ? <Component {...props} passData={passData} key={clickReloadFlag} /> : ''}
                      </motion.div>
                    </Route>
                  ))
                }
              </Switch>
            </AnimatePresence>
          )}
        />
      </AuthContent>
      <AuthFooter {...props} />
      <div className="sideLanguage">
        <div className="menu">
          <div className="hoverMenu">
            <div className="mb-1" onClick={() => { onChangeLanguage('ZH'); }}>
              <img src={cnImg} alt="cn" srcSet="" />
              <span>中文</span>
            </div>
            <div onClick={() => { onChangeLanguage('EN'); }}>
              <img src={enImg} alt="en" srcSet="" />
              <span>ENG</span>
            </div>
          </div>
        </div>
        <div className="title">{i18n.language === 'ZH' ? '中文' : 'ENG'}</div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthLayout);