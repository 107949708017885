import { SET_LOTTERY_BY_TEMPLATE_CODE, SET_QUICK_BET_QUERY_DATA, SET_WARN_SETTING } from '../../actions/actionTypes';

const initialState = {
  warnSetting: undefined,
  quickBetQueryData: {}, // 异常注单查询 表格数据 ,{data, sum}
  lotteryByTemplateCode: []
};

const bogusBetReducers = (state = initialState, action) => {
  switch (action.type) { // 合并之前，换到 action types 去
    case SET_QUICK_BET_QUERY_DATA:
      return {
        ...state, quickBetQueryData: action.value
      };
    case SET_LOTTERY_BY_TEMPLATE_CODE:
      return {
        ...state, lotteryByTemplateCode: action.value
      };
    case SET_WARN_SETTING:
      return {
        ...state, warnSetting: action.value
      };

    default:
      return state;
  }
};

export default bogusBetReducers;