import { useEffect, useState } from 'react';

import { Label } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { AgentDataTable, CommonHeader } from '../..';
import { backupActions } from '../../../redux/actions';

import './styles.scss';

const mapStateToProps = state => ({
  activeLottery: state.commonReducers.activeLottery,
  backUpAllInfo: state.backupReducers.backUpAllInfo,
  lotteryList: state.commonReducers.lotteryList,
});

const mapDispatchToProps = dispatch => ({
  StartTask: (params, callback) => dispatch(backupActions.StartTask(params, callback)),
  PauseTask: (params, callback) => dispatch(backupActions.PauseTask(params, callback)),
  GetAllListInfo: (params, callback) => dispatch(backupActions.GetAllListInfo(params, callback)),
  DeleteTask: (id, callback) => dispatch(backupActions.DeleteTask(id, callback)),
  GetDownloadUrl: (id, callback) => dispatch(backupActions.GetDownloadUrl(id, callback)),
  addBackUpTask: (data, callback) => dispatch(backupActions.addBackUpTask(data, callback)),
});

const BetTaskBackupAll = ({ StartTask, PauseTask, GetAllListInfo, backUpAllInfo, DeleteTask, GetDownloadUrl }) => {
  const { t } = useTranslation();
  const [_isQuerying, setIsQuerying] = useState(false);

  const onClickDeleteTask = id => {
    DeleteTask(id, () => onQuery());
  };

  const onClickDownload = id => {
    const callback = url => {
      const element = document.createElement('a');
      element.target = '_blank';
      element.href = url;
      element.download = `backup-${Date.now()}.zip`;
      document.body.appendChild(element);
      element.click();
    };
    GetDownloadUrl(id, url => callback(url));
  };

  const onQuery = () => {
    setIsQuerying(true);
    GetAllListInfo({}, () => setIsQuerying(false));
  };

  useEffect(() => {
    onQuery();
  }, []);

  return (
    <div className="BetTaskBackupAllWrapper">
      <CommonHeader
        headerName={(
          <div className="d-flex align-items-center">
            {`${t('BET_TASK_BACKUP')} (${t('ALL')})`}
          </div>
        )}
      />

      <div className="addNewGameBox my-3 buttons">
        <div
          className="operationBtn add d-flex align-items-center"
          onClick={() => PauseTask('', () => onQuery())}
        >
          <span className="ms-2">{t('PAUSE_ALL')}</span>
        </div>
        <div
          className="operationBtn add d-flex align-items-center"
          onClick={() => StartTask('', () => onQuery())}
        >
          <span className="ms-2">{t('START_ALL')}</span>
        </div>
      </div>

      <div className="mx-0 bg-white mb-3 px-0 border-1">
        <AgentDataTable
          isQuerying={_isQuerying}
          tableHeaderTitle={
            [{
              label: t('TASK_NAME'),
            }, {
              label: t('CREATE_USER'),
            }, {
              label: t('LOTTERY_NAME'),
            }, {
              label: t('TASK_INSTALLMENT'),
            }, {
              label: t('JIESUAN_TYPE'),
            }, {
              label: t('CREATE_TIME'),
            }, {
              label: t('TASK_STATUS'),
            }, {
              label: t('RENEW_TIME'),
            }, {
              label: t('OPERATION'),
            }]
          }
          tableHeaderStyle={
            []
          }
          tableData={backUpAllInfo || []}
          tableBodyRowProperty={
            [
              data => (<Label className="d-flex justify-content-center">{data.taskName ?? ''}</Label>),
              data => (<Label className="d-flex justify-content-center">{`${data.createUserLoginId} 【${data.companyName}】`}</Label>),
              data => (<Label className="d-flex justify-content-center">{`${data.lotteryName} 【${data.lotteryType}】`}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.bettingPeroidNum}</Label>),
              data => (<Label className={`d-flex justify-content-center ${data.bettingStatus === 1 && 'text-danger'}`}>{data.bettingStatus === 0 ? t('UN_SETTLED') : t('SETTLED')}</Label>),
              data => (<Label className="d-flex justify-content-center">{moment.unix(data.createTime).format('YYYY-MM-DD HH:mm:ss')}</Label>),
              data => (
                <Label
                  className={`d-flex justify-content-center 
                      ${data.updateStatus === 1 && 'text-green'}
                      ${data.updateStatus === 90 && 'text-red'}
                      `}
                >
                  {
                    data.updateStatus === 0 && t('PROCESSING')
                  }
                  {
                    data.updateStatus === 1 && t('COMPLETED')
                  }
                  {
                    data.updateStatus === 90 && t('PAUSING')
                  }
                </Label>
              ),
              data => (<Label className={`d-flex justify-content-center ${data.status === 1 && 'text-danger'}`}>{moment.unix(data.lastUpdateTime).format('YYYY-MM-DD HH:mm:ss')}</Label>),
              data => {
                if (data.updateStatus === 1) {
                  return (
                    <Label className="d-flex justify-content-center">
                      <div className="operationBtn" onClick={() => onClickDownload(data.taskID)}>{t('DOWNLOAD')}</div>
                      <div className="ms-1 operationBtn cancel" onClick={() => onClickDeleteTask(data.taskID)}>{t('DELETE')}</div>
                    </Label>
                  );
                }
                return (
                  <Label className="d-flex justify-content-center">
                    {data.updateStatus === 0
                      && (
                        <div
                          className="operationBtn"
                          onClick={
                            () => PauseTask(data.taskID, () => onQuery())
                          }
                        >
                          {t('PAUSE')}
                        </div>
                      )}
                    {data.updateStatus === 90
                      && (
                        <div
                          className="operationBtn"
                          onClick={
                            () => StartTask(data.taskID, () => onQuery())
                          }
                        >
                          {t('START')}
                        </div>
                      )}
                    <div className="ms-1 operationBtn cancel" onClick={() => onClickDeleteTask(data.taskID)}>{t('DELETE')}</div>
                  </Label>
                );
              }
            ]
          }
        />
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BetTaskBackupAll);