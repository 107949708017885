import { useEffect, useState } from 'react';

import { Label } from 'reactstrap';
import { connect } from 'react-redux';

import { Checkbox, Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';

import { AgentDataTable, LotteryMenu } from '../../../index';
import { appActions, decreaseOddsActions } from '../../../../redux/actions';

import './styles.scss';

const mapStateToProps = state => ({
  activeLottery: state.commonReducers.activeLottery,
  decreaseOddsData: state.decreaseOddsReducers.decreaseOddsData,
});

const mapDispatchToProps = dispatch => ({
  GetMaxGoods: (obj, callback) => dispatch(decreaseOddsActions.GetMaxGoods(obj, callback)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  SaveMaxGoods: (obj, callback) => dispatch(decreaseOddsActions.SaveMaxGoods(obj, callback)),
});

const { Option } = Select;

const MaxGoods = ({ updateModalAlert, data, GetMaxGoods, SaveMaxGoods }) => {
  const { t } = useTranslation();
  const [_selectedLottery, setSelectedLottery] = useState();
  const [_info, setInfo] = useState();
  const [_selectedQS, setSelectedQS] = useState('maxGoods');
  const [_qsText, setQsText] = useState('');
  const [_isQueryingDetail, setIsQueryingDetail] = useState(false);
  const [selectAllChecked, setselectAllChecked] = useState(false);

  const [_showMenu, setShowMenu] = useState(false);

  const [maxGoods, setMaxGoods] = useState();

  const [lotList, setlotList] = useState();

  const [firstRenderFlag, setfirstRenderFlag] = useState(true);

  useEffect(() => {
    if (data) {
      const obj = {
        lotteryType: _selectedLottery?.code ?? '',
        orgId: data.orgId
      };
      setIsQueryingDetail(true);
      const callback = data => {
        setIsQueryingDetail(false);
        setMaxGoods(data?.oddsList);
        setlotList(data?.lotList);
        setSelectedLottery(data?.lotList[0]);
      };
      GetMaxGoods(obj, callback);
    }
  }, []);

  useEffect(() => {
    if (_selectedLottery && !firstRenderFlag) {
      const obj = {
        lotteryType: _selectedLottery.code,
        orgId: data.orgId
      };
      setIsQueryingDetail(true);
      const callback = data => {
        setIsQueryingDetail(false);
        setMaxGoods(data?.oddsList);
      };
      GetMaxGoods(obj, callback);
    }
  }, [_selectedLottery]);

  useEffect(() => {
    if (maxGoods) {
      setInfo(maxGoods);
    }
  }, [maxGoods]);

  const onValueChange = (id, property, value) => {
    const newItems = _info.map(x => (x.id === id ? ({
      ...x, [property]: value, isChange: true
    }) : x));
    setInfo(newItems);
  };

  const onSaveClick = () => {
    const res = _info.filter(item => item?.isChange === true);

    const obj = {
      OrgId: data.orgId,
      Settings: res
    };
    if (res.length > 0) {
      updateModalAlert({
        type: 'loading',
        visible: true,
      });

      SaveMaxGoods(obj, () => {
        setIsQueryingDetail(true);
        const obj = {
          lotteryType: _selectedLottery?.code ?? '',
          orgId: data.orgId
        };
        const callback = data => {
          setIsQueryingDetail(false);
          setMaxGoods(data?.oddsList);
        };
        GetMaxGoods(obj, callback);
      });
    } else {
      updateModalAlert({
        type: 'confirmation',
        visible: true,
        data: {
          message: '没有任何改动',
          showCancelButton: false
        }
      });
    }
  };

  const onResetClick = () => {
    setselectAllChecked(false);
    setInfo(maxGoods);
  };

  const updateInput = () => {
    const newItems = _info.map(x => ({
      ...x, [_selectedQS]: _qsText, isChange: true
    }));
    setInfo(newItems);
  };

  const QuickSetField = [{
    label: t('HIGHEST_QUANTITY'),
    value: 'maxGoods',
  }
  ];

  return (
    <div className="MaxGoodsWarpper">
      <div className="menuRow mb-2">
        <div className="currentSelected" onFocus={() => {}} onMouseOver={() => setShowMenu(true)} onMouseLeave={() => setShowMenu(false)}>
          {_selectedLottery?.lotteryName ?? ''}
          <span className="arrow_down" />
          {_showMenu && (
            <div className="bogusBetWarningMenuWrapper" onFocus={() => {}} onMouseOver={() => setShowMenu(true)}>
              <LotteryMenu _list={lotList} onClickItem={item => { setfirstRenderFlag(false); setSelectedLottery(item); setShowMenu(false); }} selectedItem={_selectedLottery?.code ?? ''} />
            </div>
          )}
        </div>
      </div>
      <div className="mb-2 d-flex">
        <div className="col-6">
          <AgentDataTable
            isQuerying={_isQueryingDetail}
            tableHeaderTitle={
                [{
                  label: t('TYPE'),
                }, {
                  label: t('ACCUMULATED_VALUE'),
                }]
              }
            tableData={_info ? [].concat(_info.slice(0, _info.length / 2)) : []}
            tableBodyRowProperty={
                [
                  data => (<Label className="d-flex justify-content-center">{data.projectName}</Label>),
                  data => (
                    <div className="d-flex align-items-center justify-content-center">
                      <Input
                        value={data.maxGoods}
                        onChange={({ target: { value: text } }) => onValueChange(data.id, 'maxGoods', Number.isNaN(Number(text.trim())) ? data.maxGoods : text.trim())}
                      />
                    </div>
                  ),
                ]
              }
            tableBodyRowStyle={
                ['backgroundColored']
              }
            tableHeaderStyle={
                ['col-2', 'col-2', 'col-2', 'col-2', 'col-2', 'col-2']
              }
          />
        </div>
        <div className="col-6 ms-3">
          <AgentDataTable
            isQuerying={_isQueryingDetail}
            tableHeaderTitle={
                [{
                  label: t('TYPE'),
                }, {
                  label: t('ACCUMULATED_VALUE'),
                }]
              }
            tableData={_info ? [].concat(_info.slice(_info.length / 2)) : []}
            tableBodyRowProperty={
                [
                  data => (<Label className="d-flex justify-content-center">{data.projectName}</Label>),
                  data => (
                    <div className="d-flex align-items-center justify-content-center">
                      <Input
                        value={data.maxGoods}
                        onChange={({ target: { value: text } }) => onValueChange(data.id, 'maxGoods', Number.isNaN(Number(text.trim())) ? data.maxGoods : text.trim())}
                      />
                    </div>
                  ),
                ]
              }
            tableBodyRowStyle={
                ['backgroundColored']
              }
            tableHeaderStyle={
                ['col-2', 'col-2', 'col-2', 'col-2', 'col-2', 'col-2']
              }
          />
        </div>
      </div>
      <div className="d-flex justify-content-center py-3 mb-5 border-top">
        <div className="operationBtn editAndSave mx-2" onClick={onSaveClick}>{t('SAVE')}</div>
        <div className="operationBtn remove mx-2" onClick={onResetClick}>{t('CANCEL')}</div>
      </div>
      <div className="flex align-items-center justify-content-center footer p-0 m-0">
        <div className="d-flex align-items-center justify-content-start p-2">
          <Label className="flex align-items-center justify-content-center mx-1">快速设置：</Label>
          <Select
            value={_selectedQS}
            onChange={val => setSelectedQS(val)}
          >
            {
                QuickSetField.map(d => <Option value={d.value}>{d.label}</Option>)
            }
          </Select>
          <Input value={_qsText} className="kuaijieInputStyle mx-1" onChange={({ target: { value: text } }) => setQsText(Number.isNaN(Number(text.trim())) ? _qsText : text.trim())} />
          <div className="operationBtn remove mx-2" onClick={() => updateInput(_selectedQS)}>{t('CONFIRM')}</div>
          <div className="operationBtn editAndSave mx-1" onClick={onSaveClick}>{t('SAVE')}</div>
          <Checkbox
            className="mx-2 p-1 align-items-center"
            checked={selectAllChecked}
            onChange={e => {
              setselectAllChecked(e.target.checked);
              const newItems = _info.map(obj => ({
                ...obj, enabled: e.target.checked ? 1 : 0, isChange: true
              }));
              setInfo(newItems);
            }}
          >
            {t('SELECT_ALL')}
          </Checkbox>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MaxGoods);