import { useState } from 'react';

import { InputNumber } from 'antd';
import { IoCloseOutline } from 'react-icons/io5';
import { connect } from 'react-redux';

import { appActions, inTimeSheetActions } from '../../../redux/actions';
// import crossIcon from '../../../assets/image/ic_off_.png';
import './styles.scss';

const mapStateToProps = state => ({
  presetAmountList: state.inTimeSheetReducers.presetAmountList
});

const mapDispatchToProps = dispatch => ({
  updatePreSetAmountList: presetAmountList => dispatch(inTimeSheetActions.updatePreSetAmountList(presetAmountList)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
});

const PresetAmountModal = ({ presetAmountList, updateModalAlert, updatePreSetAmountList }) => {
  const [_presetAmountList, setPresetAmountList] = useState(presetAmountList);

  const onSaveClick = () => {
    updatePreSetAmountList(_presetAmountList);
    updateModalAlert({
      visible: false
    });
  };

  const onPresetAmountChange = (text, index) => {
    const newPresetAmountList = [..._presetAmountList];
    newPresetAmountList[index] = Number.isNaN(text) ? 0 : Number(text);
    setPresetAmountList(newPresetAmountList);
  };

  return (
    <div className="presetAmountWrapper">
      <div className="modalHeader px-2">
        <div
          className="justify-content-center d-flex align-items-center"
        >
          设置增量
        </div>
        <div
          className="d-flex p-0 align-items-center justify-content-center"
          onClick={() => updateModalAlert({
            visible: false
          })}
        >
          <IoCloseOutline className="h4 mb-0 closeIcon" />
        </div>
      </div>
      <div className="modalContent px-2">
        {
          _presetAmountList.map((item, index) => (
            <div className="px-2 position-relative" key={`presetAmount_item_${index.toString()}`}>
              <InputNumber
                value={item}
                onChange={({ target: { value: text } }) => {
                  onPresetAmountChange(text, index);
                }}
              />
              {/* <div
                aria-hidden
                className="crossIconWrapper"
                onMouseDown={e => {
                  e.preventDefault();
                  onPresetAmountChange('0', index);
                }}
              >
                <img src={crossIcon} alt="Reset Amount" className="crossIcon" />
              </div> */}
            </div>
          ))
        }
      </div>
      <div className="modalFooter px-2">
        <div
          className="d-flex justify-content-center align-items-center"
        >
          <div onClick={onSaveClick} className="saveBtn d-flex justify-content-center align-items-center">保存</div>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PresetAmountModal);