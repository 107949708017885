import { useEffect, useState } from 'react';

import { Label } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { AgentDataTable, Pagination } from '../../..';
import { memberActions } from '../../../../redux/actions';
import './styles.scss';


const mapStateToProps = state => ({
  loadLoginLogs: state.memberReducers.loadLoginLogs,
});

const mapDispatchToProps = dispatch => ({
  getLoadLoginLogs: (obj, callback) => dispatch(memberActions.getLoadLoginLogs(obj, callback)),
});

const LoginLog = ({ getLoadLoginLogs, loadLoginLogs, data }) => {
  const { t } = useTranslation();
  const [_isQuerying, setIsQuerying] = useState(false);
  const [_page, setPage] = useState(1);
  const [_rows, setRows] = useState(15);

  const [_loadLoginLogs, setloadLoginLogs] = useState();

  const onPaginationClick = (pageNum, rows) => {
    setIsQuerying(true);
    getLoadLoginLogs({
      id: data?.orgId || data?.memberId, page: pageNum, rows
    }, setIsQuerying);
    setPage(pageNum);
    setRows(rows);
  };

  useEffect(() => {
    setloadLoginLogs(loadLoginLogs);
  }, [loadLoginLogs]);

  useEffect(() => {
    if (data) {
      const obj = {
        id: data.orgId || data?.memberId, page: _page, rows: _rows
      };
      setIsQuerying(true);
      getLoadLoginLogs(obj, setIsQuerying);
    }
  }, []);

  return (
    <div className="loginLogWrapper">
      <div className="mx-0 bg-white my-3  border-1">
        <AgentDataTable
          isQuerying={_isQuerying}
          tableHeaderTitle={
            [{
              label: t('ORDER_NUM'),
            }, {
              label: '登陆时间',
            }, {
              label: 'IP',
            }, {
              label: 'IP归属地'
            }]
          }
          tableHeaderStyle={
            ['smallCol']
          }
          tableData={_loadLoginLogs ? _loadLoginLogs.logs : []}
          tableBodyRowProperty={
            [
              data => (<Label className="d-flex justify-content-center">{data.rowNo}</Label>),
              data => (<Label className="d-flex justify-content-center">{moment.unix(data.goOnDate).format('YYYY-MM-DD HH:mm:ss')}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.ip}</Label>),
              data => (<Label className="d-flex justify-content-center">{`${data.ipPlace}(${data.loginId})`}</Label>),
            ]
          }
        />
      </div>
      <div className="justify-center w-full relative">
        <Pagination totalRecord={_loadLoginLogs ? _loadLoginLogs?.sum?.totalRecords : 0} onClick={(pageNum, rows) => onPaginationClick(pageNum, rows)} isQuery={false} />
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginLog);