

import { Col, Label } from 'reactstrap';
import { connect } from 'react-redux';

import './styles.scss';

const OddsSettingMenu = ({ menu, _selectedMainMenu = 0, _selectedSubMenu, setSelectedSubMenu = () => {}, setInfo = () => {}, setmidCode = () => {}, setSelectedMainMenu = () => {}, setIsQueryingDetail = () => {} }) => (
  <>
    <Col className="menuWrapper mb-1 d-flex align-items-center justify-content-center">
      {
                menu && menu?.map((d, myin) => (
                  <Label
                    key={(d?.displayName ?? '') + myin.toString()}
                    className={_selectedMainMenu === myin ? 'd-flex mainItem mainActive' : 'd-flex mainItem'}
                    onClick={() => {
                      setSelectedSubMenu(0);
                      setInfo([]);
                      setIsQueryingDetail(true);
                      setSelectedMainMenu(myin);
                      if (d?.midcode || d?.settingCode) {
                        setmidCode({
                          midCode: d?.midcode,
                          settingCode: d?.settingCode,
                          isLianma: d?.isLianma || 0
                        });
                      } else if (d?.children[0]?.midcode || d?.children[0]?.settingCode) {
                        setmidCode({
                          midCode: d?.children[0]?.midcode,
                          settingCode: d?.children[0]?.settingCode,
                          isLianma: d?.isLianma || 0,
                          midtype: d?.children[0]?.midtype,
                        });
                      }
                    }}
                  >
                    {d?.displayName}
                  </Label>
                ))
              }
    </Col>
    {
              menu && menu[_selectedMainMenu]?.children && (
                <Col className="menuWrapper p-1 d-flex align-items-center justify-content-center">
                  <div className="boxContainer">
                    {
                      menu[_selectedMainMenu]?.children?.map((d, myin) => (
                        <div
                          key={(d?.tabId ?? '') + myin.toString()}
                          className={_selectedSubMenu === myin ? 'boxItem active' : 'boxItem'}
                          onClick={() => {
                            setIsQueryingDetail(true);
                            setSelectedSubMenu(myin); setmidCode({
                              midCode: d?.midcode,
                              settingCode: d?.settingCode,
                              isLianma: d?.isLianma || 0,
                              midtype: d?.midtype,
                            });
                          }}
                        >
                          {d?.label}
                        </div>
                      ))
                    }
                  </div>
                </Col>
              )
            }
  </>
);

export default connect()(OddsSettingMenu);