import { useEffect, useState } from 'react';

import { Input, Select } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';

import { appActions, cashActions, thirdPartyGamesActions, transactionActions } from '../../../redux/actions';

import './styles.scss';

const mapStateToProps = state => ({
  _transactionDetail: state.thirdPartyGamesReducers.transactionDetail,
});

const mapDispatchToProps = dispatch => ({
  GetTransactionDetail: (id, orderId) => dispatch(thirdPartyGamesActions.GetTransactionDetail(id, orderId)),
  UpdateTransaction: obj => dispatch(transactionActions.UpdateTransaction(obj)),
  GetRefundDetail: (obj, callback) => dispatch(transactionActions.GetRefundDetail(obj, callback)),
  CancelOrder: (orderId, callback) => dispatch(cashActions.CancelOrder(orderId, callback)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
});

const { Option } = Select;
const { TextArea } = Input;

const OrderInformation = ({ _transactionDetail, onlyOrder, CancelOrder, GetTransactionDetail, data, UpdateTransaction, GetRefundDetail, updateModalAlert }) => {
  const [actionStatus, setSTatus] = useState(300);
  const [checkRemark, setcheckRemark] = useState('');
  const [actionFee, setactionFee] = useState(0);
  const [thirdPartyFees, setthirdPartyFees] = useState(0);
  const [transactionDetail, settransactionDetail] = useState();

  useEffect(() => {
    if (data) {
      GetTransactionDetail(data.id, data.orderID);
    }
  }, []);

  useEffect(() => {
    settransactionDetail(_transactionDetail);
    if (transactionDetail && (transactionDetail.actionStatus === 100 || transactionDetail.actionStatus === 200)) {
      setactionFee(transactionDetail.actionFee);
      setthirdPartyFees(transactionDetail.actionFeeThird);
    }
  }, [_transactionDetail]);

  const selectPaymenyType = obj => {
    GetRefundDetail({
      transferId: data.id, thirdPayId: obj.id
    });
  };

  const onClickUpdateTransaction = data => {
    const changData = () => {
      updateModalAlert({
        visible: true,
        type: 'loading',
      });
      UpdateTransaction(data);
    };

    updateModalAlert({
      type: 'confirmation',
      visible: true,
      data: {
        message: '请确认是否要修改/设置',
        onConfirmationClick: () => changData()
      }
    });
  };

  const onClickCancelOrder = () => {
    const cancel = () => {
      updateModalAlert({
        visible: true,
        type: 'loading',
      });
      CancelOrder(data.id, () => { GetTransactionDetail(data.id, data.orderID); });
    };

    updateModalAlert({
      type: 'confirmation',
      visible: true,
      data: {
        message: '请确认是否要修改/设置',
        onConfirmationClick: () => cancel()
      }
    });
  };

  return (
    <div className="orderInformationWrapper">
      <div className="orderInformationTable">
        <div className="orderInformationRowBackGroundColor d-flex justify-content-center align-items-center">交易详情</div>
        <div className="evenRowStyle">
          <div>用户名</div>
          <div>{transactionDetail && transactionDetail.mName}</div>
          <div>订单号</div>
          <div>{transactionDetail && transactionDetail.orderID}</div>
        </div>
        <div className="evenRowStyle">
          <div>交易类型</div>
          <div>{transactionDetail && transactionDetail.actionTypeName}</div>
          <div>交易状态</div>
          <div>{transactionDetail && transactionDetail.actionStatusName}</div>
        </div>
        <div className="evenRowStyle">
          <div>交易金额</div>
          <div>{transactionDetail && (`${transactionDetail.actionAmount} (手续费:${transactionDetail.commissionFee})`)}</div>
          <div>实际金额</div>
          <div>{transactionDetail && (transactionDetail.actionAmount - transactionDetail.commissionFee)}</div>
        </div>
        <div className="evenRowStyle">
          <div>交易前金额</div>
          <div>{transactionDetail && transactionDetail.beforeAmount}</div>
          <div>交易后金额</div>
          <div>{transactionDetail && transactionDetail.afterAmount}</div>
        </div>
        <div className="evenRowStyle">
          <div>交易描述</div>
          <div>{transactionDetail && transactionDetail.createRemark}</div>
        </div>
        <div className="evenRowStyle">
          <div>交易时间</div>
          <div>{transactionDetail && moment.unix(transactionDetail.createTime).format('YYYY-MM-DD HH:mm:ss')}</div>
        </div>
        {transactionDetail && (transactionDetail.actionType === 100 || transactionDetail.actionType === 200) && (
        <>
          <div className="orderInformationRowBackGroundColor d-flex justify-content-center align-items-center">{`${transactionDetail.actionType === 100 ? '充值' : '取款'}银行说明`}</div>
          <div className="evenRowStyle">
            <div>银行名称</div>
            <div>{transactionDetail && (transactionDetail?.toBankName)}</div>
            <div>银行卡号</div>
            <div>{transactionDetail && (transactionDetail?.toBankNumber)}</div>
          </div>
          <div className="evenRowStyle">
            <div>开户名</div>
            <div>{transactionDetail && (transactionDetail?.toBankUName)}</div>
            <div>开户地址</div>
            <div>{transactionDetail && (transactionDetail?.toBankAddress)}</div>
          </div>
        </>
        )}
        <div className="orderInformationRowBackGroundColor d-flex justify-content-center align-items-center">审核信息</div>

        <div className="evenRowStyle">
          <div>详细操作日志</div>
          <div>{transactionDetail && transactionDetail.sysRemark}</div>
        </div>
        <div className="evenRowStyle">
          <div>审核管理员</div>
          <div>{transactionDetail && transactionDetail?.checkUName}</div>
          <div>审核时间</div>
          <div>{transactionDetail && transactionDetail?.checkTime}</div>
        </div>
        <div className="evenRowStyle">
          <div>审核备注</div>
          <div>
            {transactionDetail && (onlyOrder ? transactionDetail?.checkRemark : (transactionDetail.actionStatus === 100 || transactionDetail.actionStatus === 200) ? (
              <TextArea
                className="my-2 w-50"
                rows={4}
                value={checkRemark}
                onChange={({ target: { value: text } }) => setcheckRemark(text)}
              />
            ) : transactionDetail?.checkRemark)}

          </div>
        </div>
        <div className="evenRowStyle">
          <div>手续费</div>
          <div>
            {transactionDetail && (onlyOrder ? (transactionDetail?.actionFee || 0) : (transactionDetail.actionStatus === 100 || transactionDetail.actionStatus === 200) ? (
              <Input value={actionFee} onChange={({ target: { value: text } }) => setactionFee(text === '' ? '' : text > -1 ? Number(text.trim()) : actionFee)} />
            ) : transactionDetail?.actionFee || 0)}
          </div>
          <div>第三方手续费</div>
          <div>
            {transactionDetail && (onlyOrder ? (transactionDetail?.actionFeeThird || 0) : (transactionDetail.actionStatus === 100 || transactionDetail.actionStatus === 200) ? (
              <Input value={thirdPartyFees} onChange={({ target: { value: text } }) => setthirdPartyFees(text === '' ? '' : text > -1 ? Number(text.trim()) : thirdPartyFees)} />
            ) : transactionDetail?.actionFeeThird || 0)}

          </div>
        </div>
        <div className="evenRowStyle">
          <div>审核状态</div>
          <div>
            {transactionDetail && (onlyOrder ? transactionDetail?.actionStatusName : (transactionDetail.actionStatus === 100 || transactionDetail.actionStatus === 200) ? (
              <Select
                value={actionStatus}
                onChange={value => setSTatus(value)}
              >
                {
                    [{
                      label: '成功', value: 300
                    }, {
                      label: '失败', value: 400
                    }].map(item => (
                      <Option key={`UserBetStatus_${item.value}`} value={item.value}>{item.label}</Option>
                    ))
                }
              </Select>
            ) : transactionDetail?.actionStatusName)}

          </div>
          <div>代理手续费</div>
          <div>{transactionDetail && transactionDetail.actionFeeToParent}</div>
        </div>
        { transactionDetail && transactionDetail.actionType === 200 && !onlyOrder
                   && (
                   <div className="evenRowStyle">
                     <div>一键出款</div>
                     <div className="oneKeyOutMoney">
                       <div className="d-flex align-items-center">
                         {transactionDetail.thirdPays.map(item => <div className="mx-1 operationBtn" onClick={() => selectPaymenyType(item)}>{item.thirdPayName}</div>)}
                       </div>
                     </div>
                   </div>
                   )}
      </div>
      {transactionDetail && !onlyOrder && (
      <div className="d-flex justify-content-center align-items-center py-3">
        {
          (transactionDetail.actionStatus === 100 || transactionDetail.actionStatus === 200) ? (
            <div
              className="operationBtn"
              onClick={() => onClickUpdateTransaction({
                id: data.id, actionStatus, checkRemark, thirdPartyFees, actionFee, orderID: data.orderID
              })}
            >
              提交审核
            </div>
          )
            : <div className="border px-3 py-1 text-white bg-secondary">该订单已被处理</div>
        }
      </div>
      )}
      {transactionDetail && onlyOrder && (
      <div className="d-flex justify-content-center align-items-center py-3">
        {
          (transactionDetail.actionStatus === 100 || transactionDetail.actionStatus === 200)
            && (
              <div
                className="operationBtn"
                onClick={() => onClickCancelOrder()}
              >
                取消订单
              </div>
            )
        }
      </div>
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderInformation);