import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { CommonHeader } from '../../components';

import BetHistoryQuery from '../../components/ThirdPartyGame/BetHistoryQuery';
import GameSetting from '../../components/ThirdPartyGame/GameSetting';
import HandleRebate from '../../components/ThirdPartyGame/HandleRebate';
import TransactionQuery from '../../components/TransactionManagement/TransactionQuery';
import './styles.scss';

const mapStateToProps = state => ({
  selectedSubMenu: state.commonReducers.selectedSubMenu,
  userInfo: state.memberReducers.userInfo,
});

const ThirdPartyGame = ({ selectedSubMenu }) => {
  const { t } = useTranslation();
  return (
    <div className="thirdPartyGameWrapper p-3">
      {selectedSubMenu === 'TG_GAME_SETTING' && (
      <GameSetting />
      )}
      {selectedSubMenu === 'TG_BETTING_QUERY' && (
      <>
        <CommonHeader headerName={t('BETTING_QUERY')} />
        <BetHistoryQuery />
      </>
      )}
      {selectedSubMenu === 'TG_CHECK_RETURN' && (
      <HandleRebate />)}
      {selectedSubMenu === 'TG_TRANSACTION_MANAGEMENT' && (
      <TransactionQuery />
      )}
    </div>
  );
};

export default connect(mapStateToProps, null)(ThirdPartyGame);