/* eslint-disable indent */
import { useEffect, useState } from 'react';

import { Button, Input, Popover, Select } from 'antd';
import { FaCircle, FaEdit, FaPlusCircle } from 'react-icons/fa';
import { MdArrowBack } from 'react-icons/md';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { AgentDataTable, CommonHeader } from '../..';
import AgentAdd from '../components/AgentAdd';
import AgentEdit from '../components/AgentEdit';
import BetQueryDetail from '../../../views/BetQueryDetail';
import BigBetLimit from '../../BetWarning/BigBetLimit';
import BigBuhuoLimit from '../../BetWarning/BigBuhuoLimit';
import ChangeHisLog from '../components/ChangeHisLog';
import CompanyDomainSetting from '../../CompanyManagement/CompanyDomainSetting';
import CompanyInfo from '../components/CompanyInfo'; // level >=3 系统设置那边过来的公司信息
import CompanySetting from '../components/CompanySetting'; // level == 2 系统设置那边过来的公司信息
import DefaultWinLost from '../../OddsManage/DefaultWinLost';
import JiaoDui from '../components/JiaoDui';
import LoginLog from '../components/LoginLog';
import LotteryManage from '../../SystemSetting/LotteryManage';
import LotteryReturn from '../components/LotteryReturn';
import LotterySetting from '../../SystemSetting/LotterySetting';
import MaxBuHuo from '../components/MaxBuHuo';
import MaxGoods from '../components/MaxGoods';
import MemberAdd from '../components/MemberAdd';
import Pagination from '../../Content/Pagination';
import ReturnAndOddsDiff from '../../OddsManage/ReturnAndOddsDiff';
import SubAccountAdd from '../components/SubAccountAdd';
import ThirdPartyRebate from '../components/ThirdPartyRebate';

import { agentActions, appActions, commonActions } from '../../../redux/actions';
import './styles.scss';

import Notifications from '../../Content/Notifications';


const limitTypeOptions = [{
  label: '全部', value: '',
}, {
  label: '信用', value: '1',
}, {
  label: '现金', value: '2',
}];

const statusOptions = [{
  label: '全部', value: '',
}, {
  label: '启用', value: '1',
}, {
  label: '暂停', value: '2',
}, {
  label: '停用', value: '0',
}, {
  label: '锁定', value: '3',
}];

const orderOptions = [{
  label: '降序排序', value: 'DESC'
}, {
  label: '升序排序', value: 'ASC'
}];

const orderByOptions = [{
  label: '请选择', value: ''
}, {
  label: '账号', value: 'LoginId'
}, {
  label: '额度', value: 'MoneyLimit'
}, {
  label: '创建日期', value: 'CreateDate'
}];

const searchByOptions = [{
  label: '账号', value: 'LoginId'
}, {
  label: 'IP', value: 'LoginIp'
}];


const mapStateToProps = state => ({
  activeLottery: state.commonReducers.activeLottery,
  userData: state.memberReducers.userData,
  downlineAgentList: state.commonReducers.downlineAgentList,
  accountType: state.commonReducers.accountType,
});

const mapDispatchToProps = dispatch => ({
  GetDownlineAgentList: (obj, callback, catchback) => dispatch(commonActions.GetDownlineAgentList(obj, callback, catchback)),
  deleteUser: (obj, callback, catchback) => dispatch(agentActions.deleteUser(obj, callback, catchback)),
  getAgentList2: (obj, callback, catchback) => dispatch(agentActions.getAgentList2(obj, callback, catchback)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  setAccountStatus: (obj, searchObj) => dispatch(agentActions.setAccountStatus(obj, searchObj)),
  unlockMember: (obj, callback) => dispatch(agentActions.unlockMember(obj, callback)),
  setAccountStatus2: (obj, callback) => dispatch(agentActions.setAccountStatus2(obj, callback)),
  setDefaultAgent: (obj, searchObj) => dispatch(agentActions.setDefaultAgent(obj, searchObj)),
  setDefaultVisitorAgent: (obj, searchObj) => dispatch(agentActions.setDefaultVisitorAgent(obj, searchObj)),
  getLoginAs: (obj, callback) => dispatch(agentActions.getLoginAs(obj, callback)),
  GetAccountType: () => dispatch(commonActions.GetAccountType()),
});

const DirectAgent = ({ GetAccountType, accountType, unlockMember, onMemberListOpen = () => { }, downlineAgentList, GetDownlineAgentList, initialLevel, deleteUser, getLoginAs, userData, getAgentList2, updateModalAlert, setAccountStatus2 }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({
    limitType: '',
    status: '',
    orderBy: 'CreateDate',
    order: 'DESC',
    searchBy: 'LoginId', // 后端参数定义错误，seach？？？
    searchValue: '' // 输入框搜索值
  });
  const [data, setData] = useState({}); // 表格数据 + 分页数据
  const copyStateData = data;

  const [agentInfo, setAgentInfo] = useState();

  const [pagination, setPagination] = useState({
    total: 0,
    pageSize: 15,
    current: 1
  });
  const [selectedItem, setSelectedItem] = useState({});

  const [showDetail, setShowDetail] = useState(false);
  const [tabIndex, setTabIndex] = useState('');

  const [breadcrumbList, setBreadcrumbList] = useState([]);

  const downlineAgent = downlineAgentList.find(i => Number(i.level) === userData.levelValue + 1) || {};

  useEffect(() => {
    GetAccountType();
    GetDownlineAgentList();
  }, []);

  let nextAgentName = '';
  if (downlineAgent?.fullName) {
    if (downlineAgent?.fullName.indexOf('代理') !== -1) {
      nextAgentName = '代理';
    } else {
      nextAgentName = downlineAgent?.fullName;
    }
  }

  useEffect(() => {
    if (userData) {
      setBreadcrumbList([{
        label: `${userData.loginId}`,
        value: userData.loginId,
        orgId: userData.id,
        isChild: userData.isChild,
        userLevel: userData.levelValue,
        active: true,
        clickUp: initialLevel === 'all' // 怎么来的,
        // 默认直属，所有代理，只改变初始画面加载的数据
      }]);
    }
  }, [userData]);

  useEffect(() => {
    setPagination({
      total: 0,
      pageSize: 15,
      current: 1
    });
    getInfo({
      total: 0,
      pageSize: 15,
      current: 1
    });
  }, [breadcrumbList]);

  const activeUser = breadcrumbList.find(i => i.active) || {};
  const activeIndex = breadcrumbList.findIndex(i => i.active);

  const currentActiveAgent = downlineAgentList.find(i => Number(i.level) === activeUser.userLevel + 1) || {};
  let currentActiveName = '';
  if (currentActiveAgent?.fullName) {
    if (currentActiveAgent?.fullName.indexOf('代理') !== -1) {
      currentActiveName = '代理';
    } else {
      currentActiveName = downlineAgent?.fullName;
    }
  }
  const redirectToOtherLevel = (data, clickUp) => {
    setFilter({
      ...filter, searchValue: ''
    }); // 点击账号，清空filter的loginId，
    // bug： 当filter loginId，在点击账号，应该显示所有的代理账号。
    const newList = breadcrumbList.map(item => ({
      ...item, active: false
    }));
    // 如果点击上级账号 应该显示 上级账号的level 和 levelName
    // 确保点击哪个账号，当前显示active的账号就是哪一个。
    const label = clickUp ? `${currentActiveName}-${data?.upLoginId || ''}` : `${data.levelName}-${data.loginId}`;
    const value = clickUp ? data.upLoginId : data.loginId;
    const orgId = clickUp ? data.upOrgId : data.orgId;
    const level = clickUp ? data?.level - 1 : data?.level;
    newList.push(
      {
        label,
        value,
        orgId,
        isChild: data.isChild,
        userLevel: level,
        active: true,
        clickUp
      }
    );
    setBreadcrumbList(newList);
  };

  const tableHeaderTitleOrigin = [{
    label: t('ONLINE'),
  }, {
    label: t('UPPER_LEVEL_ACCOUNT'),
  }, {
    label: t('OPERATION'),
  }, {
    label: t('USER_TYPE'),
  }, {
    label: t('ACCOUNT'),
  }].concat(
    (
      accountType.filter(item => {
      if (userData?.accountOption === 0) {
        return (item?.accountType === 2 || item?.accountType === 3);
      }
        return true;
    }).filter(item => {
      if (agentInfo?.outSiteEnable === 0) {
        return item?.accountType !== 3;
      }
      return true;
    }).map(item => ({
      label: (userData?.accountOption === 0 && item?.accountType === 2 ? '额度' : item?.accName)
    }))
    ),
    [{
      label: t('ZC'),
    }, {
      label: t('AGENT'),
    }, {
      label: t('MEMBER'),
    }, {
      label: t('ADD'),
    }, {
      label: t('CREATE_DATE'),
    }, {
      label: t('STATUS'),
    }]
  );

  const tableContentOrigin = [
    data => (
      <span className={`${data.online === 1 ? 'positiveValue' : 'offline'} justify-content-center smallicon`}>
        <FaCircle className="ml-2" />
      </span>
    ),
    data => (
      <div
        className="positiveValue urlNoUnderline"
        onClick={() => {
          if (activeUser.orgId === data.upOrgId) {
            // 也要清空 filter 的 searchValue，
            setFilter({
              ...filter, searchValue: ''
            });
            const newList = [
              ...breadcrumbList.slice(0, activeIndex),
              {
                ...activeUser, clickUp: true,
              },
              ...breadcrumbList.slice(activeIndex + 1)
            ];
            setBreadcrumbList(newList);
          } else {
            redirectToOtherLevel({
              ...data,
              orgId: data.upOrgId,
            }, true); // 跳上级 level是 0 FIX，在getInfo的时候 由clickUp控制，不然会影响其他
          }
        }}
      >
        {
          data.upLoginId
        }
      </div>
    ),
    data => (
      <span className="positiveValue urlNoUnderline" onClick={() => setSelectedItem(selectedItem?.orgId ? {} : data)}>
        <FaEdit className="ml-2" />
      </span>
    ),
    data => (<span className="d-flex justify-content-center">{`${data.userType} ${data.levelName}`}</span>),
    data => (
      <>
        <span
          className="positiveValue urlNoUnderline mx-1"
          onClick={() => redirectToOtherLevel(data)}
        >
          {data.userId}
        </span>
        <span>
          [
          {data.loginName}
          ]
        </span>
      </>
    )].concat(
      (
        accountType.filter(item => {
        if (userData?.accountOption === 0) {
          return (item?.accountType === 2 || item?.accountType === 3);
        }
          return true;
      }).filter(item => {
        if (agentInfo?.outSiteEnable === 0) {
          return item?.accountType !== 3;
        }
        return true;
      }).map(item => data => {
        const { accountInfo = [] } = data;
        const balanceItem = accountInfo?.find(i => i.accType === item?.accountType);
        return (
          <span
            className="positiveValue urlNoUnderline d-flex justify-content-center"
            onClick={() => updateModalAlert({
          type: 'balanceEdit',
          visible: true,
          data: {
            ...data,
            balanceItem,
            onConfirmationClick: () => getInfo()
          }
})}
          >
            {balanceItem?.balance}
          </span>
              );
        })
      ),
    [data => (
      <span
        className="positiveValue urlNoUnderline"
        onClick={() => {
          updateModalAlert({
            type: 'sharesDetail',
            visible: true,
            data: {
              userType: 2,
              userId: data?.orgId
            }
          });
        }}
      >
        {t('DETAILS')}
      </span>
    ),
    data => (
      <span
        className="d-flex justify-content-center positiveValue urlNoUnderline"
        onClick={() => redirectToOtherLevel(data)}
      >
        {
          data.agentCount
        }
      </span>
    ),
    data => (
      <>
        <span
          className="positiveValue urlNoUnderline"
          onClick={() => {
            onMemberListOpen?.({
              selectedItem: data,
              showMemberAll: true,
            });
          }}
        >
          {data.memCount}
        </span>
        &nbsp;
        <span
          className="positiveValue urlNoUnderline"
          onClick={() => {
            onMemberListOpen?.({
              selectedItem: data,
            });
          }}
        >
          {` (${data.directMemCount})`}
        </span>
      </>
    ),
    data => (
      data?.level === agentInfo?.maxLevel
        ? (
          <span
            className="positiveValue urlNoUnderline"
            onClick={() => {
        setSelectedItem(data);
        handleClickControl(0);
      }}
          >
            {`${t('MEMBER')}`}
          </span>
      )
      : data?.level >= agentInfo?.zdLevel
        ? (
          <>
            <span
              className="positiveValue urlNoUnderline"
              onClick={() => {
                  setSelectedItem(data);
                  handleClickControl(2);
                }}
            >
              {data?.levelDownName ?? t('AGENT')}
            </span>

            <span
              className="positiveValue urlNoUnderline"
              onClick={() => {
                  setSelectedItem(data);
                  handleClickControl(0);
                }}
            >
              {` / ${t('MEMBER')}`}
            </span>
          </>
      )
      : (
        <span
          className="positiveValue urlNoUnderline"
          onClick={() => {
        setSelectedItem(data);
        handleClickControl(2);
      }}
        >
          {data?.levelDownName ?? t('AGENT')}
        </span>
      )
    ),
    data => (<span className="px-1">{data?.createDate}</span>),
    (data, index) => {
      let content = <Button className="operationBtn editAndSave">{t('PAUSE')}</Button>;
      if (data.useStateValue === 1) {
        content = <Button className="operationBtn add">{t('ACTIVE')}</Button>;
      } else if (data.useStateValue === 0) {
        content = <Button className="operationBtn remove">{t('INACTIVE')}</Button>;
      }
      return (
        <Popover
          trigger={['click']}
          content={(
            <div className="p-0 d-flex align-items-center">
              <Button
                className="mx-1 operationBtn add"
                onClick={() => {
                  data.useStateValue === 1 ? false : setAccountStatus2(
                    {
                      userId: data?.orgId,
                      status: 1,
                      userType: 2,
                      orgId: data?.orgId,
                    },
                    () => {
                      setData(
                        [
                          ...copyStateData.slice(0, index),
                          {
                            ...data, useStateValue: 1
                          },
                          ...copyStateData.slice(index + 1),
                        ]
                      );
                    }
                  );
                }}
              >
                {t('ACTIVE')}
              </Button>
              <Button
                className="mx-1 operationBtn remove"
                onClick={() => {
                  data.useStateValue === 0 ? false : setAccountStatus2(
                    {
                      userId: data?.orgId,
                      status: 0,
                      userType: 2,
                      orgId: data?.orgId,
                    },
                    () => {
                      setData(
                        [
                          ...copyStateData.slice(0, index),
                          {
                            ...data, useStateValue: 0
                          },
                          ...copyStateData.slice(index + 1),
                        ]
                      );
                    }
                  );
                }}
              >
                {t('INACTIVE')}
              </Button>
              <Button
                className="mx-1 operationBtn editAndSave"
                onClick={() => {
                  data.useStateValue === 2 ? false : setAccountStatus2(
                    {
                      userId: data?.orgId,
                      status: 2,
                      userType: 2,
                      orgId: data?.orgId,
                    },
                    () => {
                      setData(
                        [
                          ...copyStateData.slice(0, index),
                          {
                            ...data, useStateValue: 2
                          },
                          ...copyStateData.slice(index + 1),
                        ]
                      );
                    }
                  );
                }}
              >
                {t('PAUSE')}
              </Button>
            </div>
          )}
        >
          {content}
        </Popover>
      );
    }
  ]
);

  const tableHeaderTitle = tableHeaderTitleOrigin;

  const tableContent = tableContentOrigin;


  // 获取信息，全部依赖于 breadcrumbList 的变化
  const getInfo = async (paginationParams = {}, params = {}) => {
    setLoading(true);
    const newPagiantion = {
      ...pagination,
      ...paginationParams,
    };
    const activeUserLevel = (Number(activeUser?.userLevel) || 0) + 1;
    const activeClickUp = activeUser?.clickUp;

    getAgentList2(
      {
        agentId: activeUser?.orgId || '',
        level: activeClickUp ? 0 : activeUserLevel, // 点击上级账号，level 变成 0
        orderBy: filter.orderBy,
        order: filter.order,
        loginId: filter.searchValue, // 搜索值
        page: newPagiantion.current,
        rows: newPagiantion.pageSize,
        isChild: 0,
        status: filter.status,
        seachBy: filter.searchBy,
        limitType: filter.limitType,
        ...params
      },
      res => {
        setData(res?.list || []);
        setAgentInfo(res?.info);
        setPagination({
          ...newPagiantion,
          total: res?.total?.records || 0
        });
        setLoading(false);
      },
      () => setLoading(false)
    );
    setSelectedItem({});
  };

  const handleClickControl = (tabIndex = '') => {
    if (tabIndex === 0) {
      setFilter({
        ...filter,
        limitType: '',
        status: '',
        orderBy: 'CreateDate',
        order: 'DESC',
        searchBy: 'LoginId', // 后端参数定义错误，seach？？？
        searchValue: '' // 输入框搜索值
      });
      setPagination({
        ...pagination,
        current: 1
      });
    }
    setShowDetail(true);
    setShowLotterySetting(false);
    setTabIndex(tabIndex);
  };


  const [showLotterySetting, setShowLotterySetting] = useState(false);
  const [lotteryData, setLotteryData] = useState({});

  const actions = [
    // 新增直属会员
    {
      label: '',
      render: data => (
        <MemberAdd
          data={data}
          callback={v => {
            if (!v) return;
            setShowDetail(false);
            getInfo();
          }}
          onCancel={() => setShowDetail(false)}
        />
      )
    },
    // 新增子账号 不要有label
    {
      label: '',
      render: data => (
        <SubAccountAdd
          callback={v => {
            if (!v) return;
            setShowDetail(false);
            getInfo();
          }}
          data={data}
          onCancel={() => setShowDetail(false)}
        />
      )
    },
    // 新增代理 不要有label
    {
      label: '',
      render: data => (
        <AgentAdd
          data={data}
          callback={v => {
            if (!v) return;
            setShowDetail(false);
            getInfo();
          }}
          onCancel={() => setShowDetail(false)}
        />
      )
    },
    (agentInfo?.zdLevel === selectedItem?.level && {
      label: '彩种管理',
      render: () => (
        <>
          {
            !showLotterySetting && (
              <LotteryManage
                data={selectedItem}
                showHeader={false}
                callback={(_, data) => {
                  setShowLotterySetting(true);
                  setLotteryData(data);
                }}
              />
            )
          }
          {
            showLotterySetting && (
              <LotterySetting data={lotteryData} callback={() => setShowLotterySetting(false)} />
            )
          }
        </>
      )
    }) || null,
    {
      label: '修改',
      render: data => (
        <AgentEdit
          data={data}
          callback={v => {
            if (!v) return;
            setShowDetail(false);
            getInfo();
          }}
          onCancel={() => setShowDetail(false)}
        />
      )
    },
    {
      label: '彩票退水', render: data => (<LotteryReturn data={data} />)
    },
    (selectedItem?.outSiteEnable === 1 && {
      label: '视讯，棋牌退水', render: data => (<ThirdPartyRebate data={data} />)
    }) || null,
    (selectedItem?.oddsSettingStatus === 1 && {
      label: '赚赔', render: data => (<DefaultWinLost data={data} />)
    }) || null,
    (selectedItem?.level <= 3 && {
      label: '赔率', render: data => (<ReturnAndOddsDiff data={data} />)
    }) || null,
    {
      label: '最大补货量', render: data => <MaxBuHuo data={data} />
    },
    (agentInfo?.zdLevel === selectedItem?.level && {
      label: '最大货量', render: data => <MaxGoods data={data} />
    }) || null,
    ((
      (selectedItem?.level === agentInfo?.zdLevel && agentInfo?.showZdLogo >= 1) || (selectedItem?.level === agentInfo?.zdLevel - 1 && userData?.levelValue === 1 && agentInfo?.companyLevel === 2)
        ) && {
      label: '设置',
      render: data => {
        if (selectedItem?.level === agentInfo?.zdLevel - 1) {
          return (
            <CompanySetting
              helloFromUserManage
              passData={data}
              onCancel={() => setShowDetail(false)}
            />
          );
        }
        return (
          <CompanyInfo data={data} onCancel={() => setShowDetail(false)} />
        );
      }
    }) || null,


    ((
      (selectedItem?.level === agentInfo?.zdLevel && agentInfo?.showZdLogo === 2) || (selectedItem?.level === agentInfo?.zdLevel - 1 && userData?.levelValue === 1 && agentInfo?.companyLevel === 2)
        ) && {
      label: '域名', render: data => (<CompanyDomainSetting data={data} />)
    }) || null,
    (agentInfo?.zdLevel >= selectedItem?.level && {
      label: '大额限注设置', render: () => (<BigBetLimit showHeader={false} />)
    }) || null,
    (agentInfo?.zdLevel >= selectedItem?.level && {
      label: '大额补货设置', render: () => (<BigBuhuoLimit showHeader={false} />)
    }) || null,
    (userData?.levelValue <= agentInfo?.todayWinLostLevel
      && {
      label: '输赢提示',
      onClick: () => {
        updateModalAlert({
          type: 'winLostNotification',
          visible: true,
          data: {
            orgId: selectedItem?.orgId
          }
        });
      }
    }) || null,
    (selectedItem?.isLocked === 1 && {
      label: '解锁',
      onClick: () => {
        unlockMember({
          memberId: selectedItem.orgId,
          memberType: 2,
        });
      }
    }) || null,
    (selectedItem.userType === '现金' && {
      label: '校对', render: data => (<JiaoDui data={data} userType={2} />)
    }) || null,
    {
      label: '登陆日志', render: data => (<LoginLog data={data} />)
    }, {
      label: '变更日志', render: data => (<ChangeHisLog data={data} />)
    },
    (userData?.levelValue === 0 && {
      label: '浏览日志',
    }) || null,
    {
      label: '注单',
      render: data => (
        <BetQueryDetail presetObj={{
          loginName: data.loginId,
          level: data?.level
        }}
        />
      )
    },
    (userData?.levelValue <= agentInfo?.companyLevel && userData?.isChild === 0 && {
      label: '登入',
      onClick: () => {
        const openNewWindow = data => {
          const callback = () => { window.open(`${window.location.origin}/?loginId=${data.loginId}&uid=${data.orgId}&data=${data.token}`); };
          getLoginAs({
            loginId: data.loginId, uid: data.orgId, data: data.token
          }, callback);
        };
        openNewWindow(selectedItem);
      },
    }) || null,
    (userData?.levelValue === 1 && {
      label: '删除',
      onClick: () => {
        updateModalAlert({
          visible: true,
          type: 'confirmation',
          showCancelButton: true,
          data: {
            message: '股东下的所有会员及其注单数据都会被删除，确定删除吗？',
            onConfirmationClick: () => {
              deleteUser({
                userType: selectedItem?.memberId ? 1 : 2,
                userId: selectedItem?.memberId ? selectedItem?.memberId : selectedItem?.orgId
              });
            }
          }
        });
      }
    }) || null,
  ].filter(Boolean).map((item, index) => ({
    ...item,
    value: index,
    onClick: item.onClick || (() => handleClickControl(index))
  }));

  const tableControl = [
    data => (
      <>
        {
          actions.filter(i => i.label).map(item => (
            <Button
              className="controlButton mx-1"
              value={data?.orgId}
              onClick={() => item.onClick()}
            >
              {
                item.label
              }
            </Button>
          ))
        }
      </>
    )
  ];

  return (
    <div className="DirectAgentWrapper1 p-3">
      {
        !showDetail && (
          <>
            <div className="header p-2 mb-2 filterWrapper d-flex align-items-center w-100 rounded-top">
              <div className="filterArea">
                <div>
                  <span className="mx-1">模式</span>
                  <Select
                    value={filter.limitType}
                    onChange={value => {
                      setFilter({
                        ...filter, limitType: value
                      });
                      getInfo({}, {
                        limitType: value
                      });
                    }}
                    options={limitTypeOptions}
                  />
                </div>
                <div>
                  <span className="mx-1">{t('STATUS')}</span>
                  <Select
                    value={filter.status}
                    onChange={value => {
                      setFilter({
                        ...filter, status: value
                      });
                      getInfo({}, {
                        status: value
                      });
                    }}
                    options={statusOptions}
                  />
                </div>
                <div>
                  <span className="mx-1">排序</span>
                  <Select
                    value={filter.orderBy}
                    onChange={value => {
                      setFilter({
                        ...filter, orderBy: value
                      });
                      getInfo({}, {
                        orderBy: value
                      });
                    }}
                    options={orderByOptions}
                  />
                  <Select
                    className="mx-1"
                    value={filter.order}
                    onChange={value => {
                      setFilter({
                        ...filter, order: value
                      });
                      getInfo({}, {
                        order: value
                      });
                    }}
                    options={orderOptions}
                  />
                </div>
                <div className="d-flex align-items-center ">
                  <span className="mx-1">搜索</span>
                  <Select
                    value={filter.searchBy}
                    onChange={value => (setFilter({
                      ...filter, searchBy: value
                    }))}
                    options={searchByOptions}
                  />
                  <Input
                    className="mx-1 w-input-100"
                    value={filter.searchValue}
                    onChange={e => (setFilter({
                      ...filter, searchValue: e.target.value
                    }))}
                  />
                </div>
                <div>
                  <Button
                    className="searchButton marginLeft5 me-2"
                    onClick={() => getInfo({
                      current: 1
                    })}
                  >
                    {t('FIND')}
                  </Button>
                </div>
              </div>
              <Notifications />
            </div>
            <div className="subMenu mb-2 d-flex align-items-center">
              {
                userData?.levelValue >= 11 && (
                  <Button
                    className="addButton mx-2"
                  >
                    <FaPlusCircle className="mx-1" />
                    新增会员
                  </Button>
                )
              }
              {
                userData?.levelValue <= 2 && (
                  <Button
                    className="addButton mx-2"
                    onClick={() => {
                      setSelectedItem({});
                      handleClickControl(2);
                    }}
                  >
                    <FaPlusCircle className="mx-1" />
                    新增
                    {nextAgentName}
                  </Button>
                )
              }
              {
                userData?.levelValue > 2 && userData?.levelValue < 11 && (
                  <>
                    <Button
                      className="addButton mx-2"
                      onClick={() => {
                        setSelectedItem({});
                        handleClickControl(2);
                      }}
                    >
                      <FaPlusCircle className="mx-1" />
                      新增
                      {nextAgentName}
                    </Button>
                    <Button
                      className="addButton mx-2"
                      onClick={() => handleClickControl(0)}
                    >
                      <FaPlusCircle className="mx-1" />
                      新增直属会员
                    </Button>
                  </>
                )
              }
              {
                userData?.isChild === 0 && (
                  <Button
                    className="addButton mx-2"
                    onClick={() => {
                      setSelectedItem({});
                      handleClickControl(1);
                    }}
                  >
                    <FaPlusCircle className="mx-1" />
                    新增子账号
                  </Button>
                )
              }
              <div className="breadcrumbList p-0 px-3 d-flex justify-content-start align-items-center">
                {
                  breadcrumbList.map((item, i) => (
                    <div className="d-flex">
                      <span
                        className={`mainitem ${item.orgId === activeUser.orgId && 'active'}`}
                        onClick={() => {
                          const temp = [
                            ...breadcrumbList.slice(0, i),
                            {
                              ...breadcrumbList[i],
                              active: true,
                              clickUp: false,
                            }
                          ];
                          setBreadcrumbList(temp);
                        }}
                      >
                        {item.label}
                      </span>
                      {(breadcrumbList.length - 1) !== i && (<span className="mx-2 delimiter">/</span>)}
                    </div>
                  ))
                }
              </div>
            </div>

            <div>
              <AgentDataTable
                tableHeaderTitle={tableHeaderTitle}
                tableControl={tableControl}
                tableBodyRowProperty={tableContent}
                activeControl={(selectedItem?.orgId || undefined)}
                isQuerying={loading}
                tableData={data}
              />
            </div>

            <div>
              <Pagination
                customizePage={pagination?.current}
                totalRecord={pagination?.total || 0}
                isQuery={loading}
                onClick={(pageNum, rows) => {
                  getInfo({
                    pageSize: rows, current: pageNum
                  });
                }}
              />
            </div>
          </>
        )
      }

      {
        showDetail && (
          <>
            <CommonHeader
              headerName={(
                <div className="d-flex align-items-center">
                  <div
                    className="d-flex align-items-center me-2 backIcon"
                    onClick={() => {
                    setShowDetail(false);
                    getInfo();
                    }}
                  >
                    <MdArrowBack className="h5 mb-0 text-white" />
                    返回
                  </div>
                  <div>
                    {
                      initialLevel !== 'all' && (<span className="mx-1">直属代理</span>)
                    }
                    {
                      initialLevel === 'all' && (<span className="mx-1">全部代理</span>)
                    }
                    {
                      selectedItem?.loginId && '/'
                    }
                    <span className="mx-1">{selectedItem?.loginId}</span>
                  </div>
                </div>
              )}
            />
            {
              // 新增不需要显示 tab
              ![0, 1, 2].includes(tabIndex) && (
                <div className="whiteListMenu">
                  {/* 内容和 control 一样。slice(1) 没有render 的也不能出现，注意 tableIndex 是基于所有的actions */}
                  {
                    actions.map((item, index) => {
                      const { label, onClick, render } = item;
                      if (!render) return false;
                      return (
                        <div onClick={() => onClick()} className={`menu_item ${tabIndex === index && 'selected'}`}>
                          {label}
                        </div>
                      );
                    }).slice(1).filter(Boolean)
                  }
                </div>
              )
            }
            <div>
              {actions.find(i => i.value === tabIndex)?.render?.(selectedItem)}
            </div>
          </>
        )
      }
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DirectAgent);