import { connect } from 'react-redux';

import './styles.scss';

const mapStateToProps = state => ({
  lotteryList: state.commonReducers.lotteryList,
});

const LotteryMenu = ({ lotteryList, onClickItem = () => {}, selectedItem = 'JSSCPK10', _list }) => {
  const renderMenu = () => {
    const content = [];

    const temp = new Map();
    (_list || lotteryList).forEach(item => {
      const categoryName = item?.templateCode === 'HK6' ? '六合彩' : item.categoryName;
      if (temp.has(categoryName)) {
        const _tempArr = [...temp.get(categoryName), item];
        temp.set(categoryName, _tempArr);
      } else {
        temp.set(categoryName, [item]);
      }
    });

    temp.forEach((item, key) => {
      if (item.length !== 0) {
        const title = key;
        const tempList = <>{item.map(item => <div key={`menu_item_${item.code}`} className={` lotteryName ${item.code === selectedItem && 'selected'}`} onClick={() => onClickItem(item)}>{item.lotteryName}</div>)}</>;
        content.push(
          <div className="menuSection" key={`menu_section_${title}`}>
            <div className="title">{title}</div>
            <div className="gapLine" />
            <div className="content">{tempList}</div>
          </div>
        );
      }
    });
    return content;
  };
  return (
    <div>
      {(lotteryList && lotteryList.length > 0)
        && (
        <div className="lotteryMenuWrapper">
          {
            renderMenu()
          }
        </div>
        )}
    </div>
  );
};

export default connect(mapStateToProps)(LotteryMenu);