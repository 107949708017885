import { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { CommonHeader } from '../../components';

import AgentBackUpList from '../../components/SystemSetting/AgentBackUpList';
import BankSetting from '../../components/SystemSetting/BankSetting';
import BetTaskBackupAll from '../../components/SystemSetting/BetTaskBackupAll';
import CompanyInfo from '../../components/SystemSetting/CompanyInfo';
import DominManage from '../../components/SystemSetting/DominManage';
import EmailSMSSetting from '../../components/SystemSetting/EmailSMSSetting';
import ExchangeRateSetting from '../../components/SystemSetting/ExchangeRateSetting';
import ExternalWebManage from '../../components/SystemSetting/ExternalWebManage';
import LotteryManage from '../../components/SystemSetting/LotteryManage';
import LotteryStyle from '../../components/SystemSetting/LotteryStyle';
import MemberAmountHistory from '../../components/SystemSetting/MemberAmountHistory';
import MemberAutoClassification from '../../components/SystemSetting/MemberAutoClassification';
import MemberDayReport from '../../components/SystemSetting/MemberDayReport';
import OTPList from '../../components/SystemSetting/OTPList';
import OpenResultAddress from '../../components/SystemSetting/OpenResultAddress';
import OpenResultSetting from '../../components/SystemSetting/OpenResultSetting';
import PaymentAccSetting from '../../components/SystemSetting/PaymentAccSetting';
import PaymentOfflineSetting from '../../components/SystemSetting/PaymentOfflineSetting';
import SystemLogs from '../../components/SystemSetting/SystemLogs';
import SystemNotices from '../../components/SystemSetting/SystemNotices';
import SystemServiceManagement from '../../components/SystemSetting/SystemServiceManagement';
import SystemSetting from '../../components/SystemSetting/SystemSetting';
import SystemSettingSubPage from '../../components/SystemSetting/SystemSettingSubPage';
import ThirdPartyPay from '../../components/SystemSetting/ThirdPartyPay';
import UnusualBetExport from '../../components/SystemSetting/UnusualBetExport';
import WhitelistManagement from '../../components/SystemSetting/WhitelistManagement';
import WithdrawSetting from '../../components/SystemSetting/WithdrawSetting';
import './styles.scss';

const mapStateToProps = state => ({
  selectedSubMenu: state.commonReducers.selectedSubMenu,
});


const ReportManagement = ({ selectedSubMenu }) => {
  const { t } = useTranslation();
  const [_insidePage, setInsidePage] = useState(false);
  const [_tabName, setTabName] = useState(false);
  const [_isEdit, setIsEdit] = useState(false);
  const [data, setData] = useState();

  useEffect(() => {
    setInsidePage(false);
  }, [selectedSubMenu]);

  const renderItems = () => {
    switch (selectedSubMenu) {
      case 'SS_SYSTEM_SETTING': return (
        <>
          <CommonHeader
            headerName={selectedSubMenu ? t(selectedSubMenu.slice(3)) : ''}
          />
          <SystemSetting key="SS_SYSTEM_SETTING" />
        </>
      );
      case 'SS_COMPANY_INFO': return (
        <>
          <CommonHeader
            headerName={selectedSubMenu ? t(selectedSubMenu.slice(3)) : ''}
          />
          <CompanyInfo key="SS_COMPANY_INFO" callback={(val, data) => { setTabName(val); setData(data); setInsidePage(true); }} />
        </>
      );
      case 'SS_SYSTEM_NOTICE': return (
        <>
          <CommonHeader
            headerName={selectedSubMenu ? t(selectedSubMenu.slice(3)) : ''}
          />
          <SystemNotices key="SS_SYSTEM_NOTICE" callback={(val, data, isEdit) => { setTabName(val); setData(data); setInsidePage(true); setIsEdit(isEdit); }} />
        </>
      );
      case 'SS_SYSTEM_LOG': return (
        <>
          <CommonHeader
            headerName={selectedSubMenu ? t(selectedSubMenu.slice(3)) : ''}
          />
          <SystemLogs key="SS_SYSTEM_LOG" callback={(val, data, isEdit) => { setTabName(val); setData(data); setInsidePage(true); setIsEdit(isEdit); }} />
        </>
      );
      case 'SS_RECEIVED_ACCOUNT_SETTING': return (
        <>
          <CommonHeader
            headerName={selectedSubMenu ? t(selectedSubMenu.slice(3)) : ''}
          />
          <PaymentAccSetting key="SS_RECEIVED_ACCOUNT_SETTING" callback={(val, data, isEdit) => { setTabName(val); setData(data); setInsidePage(true); setIsEdit(isEdit); }} />
        </>
      );
      case 'SS_OUTSIDE_WEB_SETTING': return (
        <ExternalWebManage key="SS_OUTSIDE_WEB_SETTING" callback={(val, data, isEdit) => { setTabName(val); setData(data); setInsidePage(true); setIsEdit(isEdit); }} />
      );
      case 'SS_THIRD_PARTY_RECEIVER_SETTING': return (
        <>
          <CommonHeader
            headerName={selectedSubMenu ? t(selectedSubMenu.slice(3)) : ''}
          />
          <ThirdPartyPay key="SS_THIRD_PARTY_RECEIVER_SETTING" callback={(val, data, isEdit) => { setTabName(val); setData(data); setInsidePage(true); setIsEdit(isEdit); }} />
        </>
      );
      case 'SS_USER_CATEGORY': return (
        <>
          <CommonHeader
            headerName={selectedSubMenu ? t(selectedSubMenu.slice(3)) : ''}
          />
          <MemberAutoClassification key="SS_USER_CATEGORY" callback={(val, data, isEdit) => { setTabName(val); setData(data); setInsidePage(true); setIsEdit(isEdit); }} />
        </>
      );
      case 'SS_RECEIVER_OFFLINE_SETTING': return (
        <>
          <CommonHeader
            headerName={selectedSubMenu ? t(selectedSubMenu.slice(3)) : ''}
          />
          <PaymentOfflineSetting key="SS_RECEIVER_OFFLINE_SETTING" callback={(val, data, isEdit) => { setTabName(val); setData(data); setInsidePage(true); setIsEdit(isEdit); }} />
        </>
      );
      case 'SS_EMAIL_SMS_SETTING': return (
        <>
          <CommonHeader
            headerName={selectedSubMenu ? t(selectedSubMenu.slice(3)) : ''}
          />
          <EmailSMSSetting key="SS_EMAIL_SMS_SETTING" callback={(val, data, isEdit) => { setTabName(val); setData(data); setInsidePage(true); setIsEdit(isEdit); }} />
        </>
      );
      case 'SS_GLOBAL_WITHDRAWAL_SETTING': return (
        <>
          <CommonHeader
            headerName={selectedSubMenu ? t(selectedSubMenu.slice(3)) : ''}
          />
          <WithdrawSetting key="SS_GLOBAL_WITHDRAWAL_SETTING" callback={(val, data, isEdit) => { setTabName(val); setData(data); setInsidePage(true); setIsEdit(isEdit); }} />
        </>
      );
      case 'SS_CAPTCHA_SETTING': return (
        <>
          <CommonHeader
            headerName={selectedSubMenu ? t(selectedSubMenu.slice(3)) : ''}
          />
          <OTPList key="SS_CAPTCHA_SETTING" callback={(val, data, isEdit) => { setTabName(val); setData(data); setInsidePage(true); setIsEdit(isEdit); }} />
        </>
      );
      case 'SS_FEES_SETTING': return (
        <>
          <CommonHeader
            headerName={selectedSubMenu ? t(selectedSubMenu.slice(3)) : ''}
          />
          <ExchangeRateSetting key="SS_FEES_SETTING" callback={(val, data, isEdit) => { setTabName(val); setData(data); setInsidePage(true); setIsEdit(isEdit); }} />
        </>
      );
      case 'SS_WITHDRAWAL_BANK': return (
        <>
          <CommonHeader
            headerName={selectedSubMenu ? t(selectedSubMenu.slice(3)) : ''}
          />
          <BankSetting key="SS_WITHDRAWAL_BANK" callback={(val, data, isEdit) => { setTabName(val); setData(data); setInsidePage(true); setIsEdit(isEdit); }} />
        </>
      );
      case 'SS_OPEN_RESULT_SETTING': return (
        <>
          <CommonHeader
            headerName={selectedSubMenu ? t(selectedSubMenu.slice(3)) : ''}
          />
          <OpenResultSetting />
        </>
      );
      case 'SS_OPEN_RESULT_ADDRESS_MANAGEMENT': return (
        <OpenResultAddress />
      );
      case 'SS_WHITE_LIST_MANAGEMENT': return (
        <WhitelistManagement />
      );
      case 'SS_AGENT_BACKUP_WHITE_LIST': return (
        <AgentBackUpList />
      );
      case 'SS_SYSTEM_SERVICE_MANAGEMENT': return (
        <SystemServiceManagement />
      );
      case 'SS_BET_TASK_BACKUP': return (
        <BetTaskBackupAll /> // 注单任务备份 全部
      );
      case 'SS_MEMBER_DAY_BILL': return (
        <MemberDayReport /> // 会员日常结单
      );
      case 'SS_MEMBER_CREDIT_CHANGE': return (
        <MemberAmountHistory /> // 会员日常结单
      );
      case 'SS_LOTTERY_STYLE_MANAGEMENT': return (
        <LotteryStyle /> // 彩种样式设置
      );
      case 'SS_ABNORMAL_BET_EXPORT': return (
        <UnusualBetExport /> // 异常注单导出
      );
      case 'SS_DOMAIN_MANAGEMENT': return (
        <DominManage /> // 域名管理
      );
      case 'SS_LOTTERY_MANAGEMENT': return (
        <LotteryManage
          key="SS_LOTTERY_MANAGEMENT"
          callback={(val, data) => {
            setTabName(val); setData(data); setInsidePage(true);
          }}
        /> // 彩种管理
      );
      default:
        break;
    }
  };

  return (
    <div className="systemSettingWrapper p-3">
      {!_insidePage ? renderItems() : <SystemSettingSubPage callback={() => setInsidePage(false)} data={data} tabName={_tabName} isEdit={_isEdit} />}
    </div>
  );
};

export default connect(mapStateToProps, null)(ReportManagement);