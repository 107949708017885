import { useEffect, useState } from 'react';

import { Button, Col, Label, Row } from 'reactstrap';
import { FaPlusCircle } from 'react-icons/fa';
import { connect } from 'react-redux';

import { useTranslation } from 'react-i18next';

import { appActions, systemActions } from '../../../redux/actions';
import AgentDataTable from '../../AgentDataTable';
import Pagination from '../../Content/Pagination';
import './styles.scss';


const mapStateToProps = state => ({
  paymentOfflineSettingData: state.systemReducers.paymentOfflineSettingData,
  userData: state.memberReducers.userData,
});

const mapDispatchToProps = dispatch => ({
  getOfflineSetting: (obj, callback) => dispatch(systemActions.getOfflineSetting(obj, callback)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  updateExternalWebStatus: (obj, callback) => dispatch(systemActions.updateExternalWebStatus(obj, callback)),
  setOfflineStatus: (obj, callback) => dispatch(systemActions.setOfflineStatus(obj, callback)),
});

const PaymentOfflineSetting = ({ callback = () => {}, paymentOfflineSettingData, getOfflineSetting, data, setOfflineStatus }) => {
  const { t } = useTranslation();
  const [_info, setInfo] = useState([]);
  const [_page, setPage] = useState(1);
  const [_rows, setRows] = useState(15);
  const [_totalRecord, setTotalRecord] = useState(0);
  const [_isQueryingDetail, setIsQueryingDetail] = useState(true);
  const [isQueryStatus, setQueryStatus] = useState(true);

  useEffect(() => {
    if (paymentOfflineSettingData && JSON.stringify(paymentOfflineSettingData?.offlineList) !== JSON.stringify(_info)) {
      setInfo(paymentOfflineSettingData?.offlineList);
      setTotalRecord(paymentOfflineSettingData?.totalRecords);
      setQueryStatus(false);
    }
  }, [paymentOfflineSettingData]);

  useEffect(() => {
    setIsQueryingDetail(true);
    getOfflineSetting({
      page: _page,
      rows: _rows,
    }, setIsQueryingDetail);
  }, []);

  const onPaginationClick = (pageNum, rows) => {
    setIsQueryingDetail(false);
    // Page number and {row number will follow the pagination component instead
    getOfflineSetting({
      page: pageNum,
      rows: _rows,
    }, setIsQueryingDetail);
    setPage(pageNum);
    setRows(rows);
  };

  const afterSuccessCallBack = () => {
    getOfflineSetting({
      page: _page,
      rows: _rows,
    }, setIsQueryingDetail);
  };

  const deleteClick = id => {
    setOfflineStatus(id, afterSuccessCallBack);
  };


  const tableHeader = [{
    label: t('MIN_SINGLE_DEPOSIT_VALUE'),
  }, {
    label: t('MAX_SINGLE_DEPOSIT_VALUE'),
  }, {
    label: t('CLIENT_PLATFORM_BELONGS'),
  }, {
    label: t('DEPOSIT_STATUS'),
  }, {
    label: t('STATUS_REMARK'),
  }, {
    label: t('FUNCTION'),
  }];

  const tableContent = [
    data => (<Label className="d-flex justify-content-center">{data?.min}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.max}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.name}</Label>),
    data => (data?.status === 1 ? <Label className="negativeValue">{t('BAN')}</Label> : <Label className="installment">{t('ALLOW')}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.statusInfo}</Label>),
    data => (
      <>
        <Button className="button handle mx-1" onClick={() => callback('paymentOfflineSetting', data, true)}>{t('COMPILE')}</Button>
        <Button className="button yellow mx-1" onClick={() => deleteClick(data?.id)}>{data?.status === 1 ? t('ALLOW') : t('BAN')}</Button>
      </>
    ),
  ];

  return (
    <div className="PaymentOfflineSettingWrapper">
      <div className="mx-0 bg-white my-2  border-1">
        <Row className="p-0 m-0 mb-2">
          <Col className="p-1 mb-2 filterWrapper d-flex align-items-center w-100">
            <Col className="d-flex align-items-center">
              <Button
                className="button add justify-content-center"
                onClick={() => callback('paymentOfflineSetting', data, false)}
              >
                <FaPlusCircle className="mb-1 mr-1" />
                <Label className="p-0 px-1">{t('ADD_SETTING')}</Label>
              </Button>
            </Col>
          </Col>
        </Row>
        <Row className="bigWrapper m-0 my-1 p-3">
          <AgentDataTable
            isQuerying={isQueryStatus}
            tableHeaderTitle={tableHeader}
            tableData={_info || (isQueryStatus ? [1, 2, 3] : [])}
            tableBodyRowProperty={tableContent}
            tableBodyRowStyle={[]}
          />
        </Row>
        <Row className="justify-center w-full relative">
          <Pagination totalRecord={_totalRecord || 0} onClick={onPaginationClick} isQuery={_isQueryingDetail} />
        </Row>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentOfflineSetting);