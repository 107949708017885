import { useEffect, useState } from 'react';

import { Button, Input, Radio, Select } from 'antd';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';

import { useTranslation } from 'react-i18next';

import { appActions, systemActions } from '../../../redux/actions';
import './styles.scss';


const mapStateToProps = state => ({
  editNoticeData: state.noticeReducers.editNoticeData,
  userData: state.memberReducers.userData,
  editPayAccData: state.systemReducers.editPayAccData,
});

const mapDispatchToProps = dispatch => ({
  getEditPayAccount: (id, callback) => dispatch(systemActions.getEditPayAccount(id, callback)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  saveExternalWeb: (obj, callback) => dispatch(systemActions.saveExternalWeb(obj, callback)),
  EditExternalWeb: (obj, callback) => dispatch(systemActions.EditExternalWeb(obj, callback)),
  GetBuhuoBetList: (obj, callback) => dispatch(systemActions.GetBuhuoBetList(obj, callback)),
});

const ExternalWebAddEdit = ({ callback = {}, EditExternalWeb, isEdit, data, updateModalAlert, saveExternalWeb }) => {
  const { t } = useTranslation();
  const [_info, setInfo] = useState({});

  useEffect(() => {
    if (data?.id) {
      EditExternalWeb(
        {
          id: data?.id
        },
        res => {
          setInfo(res);
        }
      );
    } else {
      EditExternalWeb({}, res => setInfo(res));
    }
  }, [data]);


  const saveButton = () => {
    const invalid = _info?.address && !jugeUrl(_info?.address);
    if (invalid) {
      return updateModalAlert({
        type: 'confirmation',
        visible: true,
        showCancelButton: true,
        data: {
          message: '请输入正确的网址格式',
        },
      });
    }
    if (_info?.isChange) {
      const updateObj = _info;
      updateModalAlert({
        type: 'loading',
        visible: true,
      });
      if (!isEdit) {
        updateObj.isUpdate = 0;
      } else {
        updateObj.isUpdate = 1;
      }
      saveExternalWeb(updateObj, callback);
    } else {
      updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: '无变动'
        }
      });
    }
  };

  const onInfoValueChange = (val, field) => {
    const newItems = _info;
    newItems[field] = val;
    newItems.isChange = true;
    setInfo(newItems => ({
      ...newItems, field: val
    }));
  };

  const resetDefault = () => {
    callback();
  };

  const status = [
    {
      label: '启用',
      value: '1',
    },
    {
      label: '禁用',
      value: '0',
    }
  ];


  function jugeUrl(URL) {
    const valid = URL?.startsWith('http') || URL?.startsWith('https');
    if (valid) {
      return true;
    }
    return false;
  }

  return (
    <div className="ExternalWebAddEditWrapper">
      <div className="mx-0 bg-white my-2  border-1">
        <Row className="m-0 my-1 p-0">
          <table className="p-3 m-0" cellSpacing="0" cellPadding="0" border="0">
            <tbody>
              <tr>
                <td className="titleHeader" colSpan={2}>
                  {isEdit === true ? '编辑外补网站' : '新增外补网站'}
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">网站补货代码</td>
                <td>
                  <Select
                    className="col-2"
                    options={
                      (_info?.webList || []).map(item => ({
                        label: item.webName, value: item.webCode
                      }))
                    }
                    value={_info?.webCode || ''}
                    onChange={text => onInfoValueChange(text, 'webCode')}
                  />
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">网站名称</td>
                <td>
                  <div>
                    <Input
                      value={_info?.name || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text, 'name')}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">网站地址</td>
                <td>
                  <div>
                    <Input
                      value={_info?.address || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text, 'address')}
                    />
                    <span className="mx-2 text-red">
                      {
                        _info?.address && !jugeUrl(_info?.address) && '请输入正确的网址格式'
                      }
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">登陆用户</td>
                <td>
                  <div>
                    <Input
                      value={_info?.userName || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text, 'userName')}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">密码</td>
                <td>
                  <div>
                    <Input
                      value={_info?.password || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text, 'password')}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">搜索码</td>
                <td>
                  <div>
                    <Input
                      value={_info?.searchCode || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text, 'searchCode')}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">补货占成</td>
                <td>
                  <div>
                    <Input
                      type="number"
                      value={_info?.rate || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text, 'rate')}
                    />
                    <span className="mx-1">
                      %
                    </span>

                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">状态</td>
                <td>
                  <div className="d-flex my-1 rdSearchOptionsGroup">
                    {
                      status.map(d => (
                        <div key={`rdTypeGroup_${d.value}`}>
                          <Radio
                            value={_info?.status}
                            checked={_info?.status === Number(d.value)}
                            onChange={() => onInfoValueChange(Number(d.value), 'status')}
                          >
                            {d?.label}
                          </Radio>
                        </div>
                      ))
                    }
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-2">备注</td>
                <td>
                  <span className="textAreaInput">
                    <Input.TextArea
                      value={_info?.remark}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text, 'remark')}
                    />
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </Row>
      </div>
      <Row className="mb-5 my-2 p-2">
        <Col className="d-flex justify-content-center py-2 border-top">
          <Button className="button handle mx-1" onClick={() => saveButton()}>{t('SAVE')}</Button>
          <Button className="button find mx-1" onClick={() => resetDefault()}>{t('CANCEL')}</Button>
        </Col>
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ExternalWebAddEdit);