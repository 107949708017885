import { useEffect, useState } from 'react';

import { Input } from 'antd';
import { Label } from 'reactstrap';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { AgentDataTable, Pagination } from '../..';
import { appActions, systemActions } from '../../../redux/actions';

import './styles.scss';

const mapStateToProps = state => ({
  roleInfo: state.systemReducers.roleInfo,
  parentMenuList: state.commonReducers.parentMenuList,
  activeMenu: state.commonReducers.activeMenu,
  menuInfo: state.systemReducers.menuInfo,
  editRoleInfo: state.systemReducers.editRoleInfo,
  company: state.whiteListReducers.company,
  subAccountList: state.whiteListReducers.subAccountList,
});
const mapDispatchToProps = dispatch => ({
  GetControllers: (obj, callback) => dispatch(systemActions.GetControllers(obj, callback)),
  DeleteController: (id, callback) => dispatch(systemActions.DeleteController(id, callback)),
  SetControllerStatus: (obj, callback) => dispatch(systemActions.SetControllerStatus(obj, callback)),
  UpdateController: (obj, callback) => dispatch(systemActions.UpdateController(obj, callback)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
});

const PageSetting = ({ GetControllers, DeleteController, SetControllerStatus, UpdateController, updateModalAlert }) => {
  const { t } = useTranslation();
  const [_isQuerying, setIsQuerying] = useState(false);
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(15);
  const [settingData, setData] = useState();
  const [controller, setcontroller] = useState('');
  const [dataList, setDataList] = useState();

  const getData = data => {
    setIsQuerying(true);
    GetControllers(data, param => {
      setIsQuerying(false);
      setDataList(param?.list ? param?.list.map((x, index) => ({
        ...x, index
      })) : []);
      setData(param);
    });
  };

  useEffect(() => {
    const _obj = {
      page,
      rows,
      controller
    };
    getData(_obj);
  }, []);

  const onSearchClick = () => {
    const _obj = {
      page: 1,
      rows: 15,
      controller
    };
    getData(_obj);
    setPage(1);
    setRows(15);
  };

  const onPaginationClick = (pageNum, rows) => {
    setPage(pageNum);
    setRows(rows);
    const _obj = {
      page: pageNum,
      rows,
      controller
    };
    getData(_obj, () => setIsQuerying(false));
  };

  const onValueChange = (index, type, value) => {
    let tempData = [];
    tempData = dataList;
    tempData[index][type] = value;
    setDataList([].concat(tempData));
  };

  const deleteRecord = id => {
    DeleteController(id, () => {
      const _obj = {
        page,
        rows,
        controller
      };
      getData(_obj);
    });
  };

  const setStatus = setDto => {
    SetControllerStatus(setDto, () => {
      const _obj = {
        page,
        rows,
        controller
      };
      getData(_obj);
    });
  };

  const onClickSave = () => {
    UpdateController({
      controllers: dataList
    }, () => {
      const _obj = {
        page,
        rows,
        controller
      };
      getData(_obj);
    });
  };

  const onClickCreateNewPage = () => {
    updateModalAlert({
      visible: true,
      type: 'createSettingPage',
      data: {
        onConfirmationClick: () => {
          const _obj = {
            page,
            rows,
            controller
          };
          getData(_obj);
        }
      }

    });
  };

  return (
    <div className="PageSettingWrapper">
      <div className="menuManagementHeader mb-3">
        <div>Controller</div>
        <Input className="mx-2 menuNameInput" value={controller} onChange={({ target: { value: text } }) => setcontroller(text.trim())} />
        <div className="operationBtn mx-2 add" onClick={() => onSearchClick()}>查询</div>
        <div
          className="operationBtn mx-2 add"
          onClick={() => {
            onClickCreateNewPage();
          }}
        >
          新增
        </div>
        <div
          className="operationBtn mx-2 add"
          onClick={() => {
            onClickSave();
          }}
        >
          保存
        </div>
      </div>
      <div className="mx-0 bg-white px-0 border-1">
        <AgentDataTable
          isQuerying={_isQuerying}
          tableHeaderTitle={
                [{
                  label: 'Controller',
                }, {
                  label: 'ActionName',
                }, {
                  label: 'Url路径',
                }, {
                  label: 'Actiontype',
                }, {
                  label: '状态',
                }, {
                  label: t('OPERATION'),
                }]
              }
          tableHeaderStyle={
                []
              }
          tableData={dataList || []}
          tableBodyRowProperty={
                [
                  data => (
                    <Label className="d-flex justify-content-center">
                      <Input className="mx-2 menuNameInput" value={data?.controller} onChange={({ target: { value: text } }) => onValueChange(data.index, 'controller', text.trim())} />
                    </Label>
                  ),
                  data => (
                    <Label className="d-flex justify-content-center">
                      <Input className="mx-2 menuNameInput" value={data?.actionName} onChange={({ target: { value: text } }) => onValueChange(data.index, 'actionName', text.trim())} />
                    </Label>
                  ),
                  data => (
                    <Label className="d-flex justify-content-center">
                      <Input className="mx-2 menuNameInput" value={data?.url} onChange={({ target: { value: text } }) => onValueChange(data.index, 'url', text.trim())} />
                    </Label>
                  ),
                  data => (
                    <Label className="d-flex justify-content-center">
                      <Input className="mx-2 menuNameInput" value={data?.actionType} onChange={({ target: { value: text } }) => onValueChange(data.index, 'actionType', text.trim())} />
                    </Label>
                  ),
                  data => (<Label className={`d-flex justify-content-center ${data.status === 1 ? 'text-success' : 'text-danger'}`}>{data.status === 1 ? '启用' : '停用'}</Label>),
                  data => (
                    <Label className="d-flex justify-content-center align-items-center">
                      <div
                        className="operationBtn mx-2"
                        onClick={() => {
                          deleteRecord(data.id);
                        }}
                      >
                        删除
                      </div>
                      <div
                        className="operationBtn mx-2"
                        onClick={() => {
                          setStatus({
                            id: data.id, status: data.status === 1 ? 0 : 1
                          });
                        }}
                      >
                        切换状态
                      </div>
                    </Label>
                  )
                ]
              }
        />
      </div>
      <div className="d-flex align-items-center justify-content-center w-full relative">
        <Pagination totalRecord={settingData?.totalRecords || 0} onClick={onPaginationClick} isQuery={false} />
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PageSetting);