import { useEffect, useState } from 'react';

import { Col, Label, Row } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { AgentDataTable, Pagination } from '../..';
import { betListActions } from '../../../redux/actions';

import './styles.scss';

const mapStateToProps = state => ({
  betQueryResult: state.betListReducers.betQueryResult,
});

const mapDispatchToProps = dispatch => ({
  getBetQueryResult: (obj, callback) => dispatch(betListActions.getBetQueryResult(obj, callback)),
});

const LotteryBet = ({ betQueryResult, getBetQueryResult, data }) => {
  const { t } = useTranslation();
  const [_isQueryingDetail, setIsQueryingDetail] = useState(false);
  const _searchItem = data?.mName;
  const _date = [moment().subtract(45, 'days').startOf('day').toDate(), moment().endOf('day').toDate()];
  const _selectedLottery = '';
  const _selectedBetStatus = '2';
  const _selectedInstallments = '';
  const _selectedGameType = '0';
  const _selectedSearchBy = 'username';
  const [_page, setPage] = useState(1);
  const [_rows, setRows] = useState(15);

  useEffect(() => {
    if (data) {
      getBetQueryResult({
        lotteryType: _selectedLottery,
        startDate: moment(_date[0]).unix(),
        endDate: moment(_date[1]).unix(),
        searchBy: _selectedSearchBy,
        page: _page,
        rows: _rows,
        status: _selectedBetStatus,
        value: _searchItem,
        isTest: _selectedGameType,
        installments: _selectedInstallments,
        memberId: data?.mid || '',
        userType: data?.mType || ''
      }, setIsQueryingDetail);
    }
  }, []);


  const onPaginationClick = (pageNum, rows) => {
    setIsQueryingDetail(true);
    setPage(pageNum);
    setRows(rows);
    getBetQueryResult({
      lotteryType: _selectedLottery,
      startDate: moment(_date[0]).unix(),
      endDate: moment(_date[1]).unix(),
      searchBy: _selectedSearchBy,
      page: pageNum,
      rows,
      status: _selectedBetStatus,
      value: _searchItem,
      isTest: _selectedGameType,
      installments: _selectedInstallments === '-1' ? '' : _selectedInstallments,
      memberId: (data ? data.level === 1 ? data.memberId : data.orgId : '') || data?.memberId || '',
      userType: (data ? data.level : '') || data?.memberId ? 1 : 2
    }, setIsQueryingDetail);
  };

  return (
    <Col className="LotteryBetWrapper">
      <Row className="mx-0 bg-white px-0 border-1">
        <AgentDataTable
          isQuerying={_isQueryingDetail}
          tableHeaderTitle={
            [{
              label: t('ORDER_NUM'),
            }, {
              label: '注单号',
            }, {
              label: '下注时间',
            }, {
              label: '彩种',
            }, {
              label: '下注金额',
            }, {
              label: '下注内容',
            }, {
              label: '结果',
            }]
          }
          tableHeaderStyle={['headerStyle', 'headerStyle', 'headerStyle', 'headerStyle', 'headerStyle', 'headerStyle', 'headerStyle', 'headerStyle', 'headerStyle', 'headerStyle', 'headerStyle', 'headerStyle', 'headerStyle', 'headerStyle', 'headerStyle', 'headerStyle']}
          tableData={betQueryResult ? betQueryResult.data : []}
          tableBodyRowProperty={
            [
              data => (<Label className="d-flex justify-content-center">{data.ord}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.oddNo}</Label>),
              data => (<Label className="d-flex justify-content-center">{moment.unix(data.created).format('YYYY-MM-DD HH:mm:ss')}</Label>),
              data => (
                <Label className="d-flex justify-content-center align-items-center">
                  <div>
                    <div>{data.lotteryName}</div>
                    <div>{data.installments}</div>
                  </div>
                </Label>
              ),
              data => (<Label className="d-flex justify-content-center">{data.betAmount}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.placeBetContent}</Label>),
              data => (<Label className="d-flex justify-content-center">{Number(data?.memAmount).toFixed(2)}</Label>),
            ]
          }
          tableFooter={[[{
            value: `${t('CURRENT_PAGE_TOTAL')}: ${(betQueryResult && betQueryResult?.sum?.thisBetCount) || 0}${t('TIMES')}`,
            colspan: 4,
            className: 'textRight pr-1 total tableSum'
          },
          {
            value: (betQueryResult && betQueryResult.sum.thisBetAmt) || 0,
            colspan: 1,
            className: 'tableSum'
          },
          {
            colspan: 1,
            className: 'tableSum'
          },
          {
            value: (betQueryResult && betQueryResult.sum.thisMemAmt) || 0,
            colspan: 1,
            className: 'tableSum'
          }], [{
            value: `${t('TOTAL_COUNT')}: ${(betQueryResult && betQueryResult?.sum?.totalRecord) || 0}${t('TIMES')}`,
            colspan: 4,
            className: 'textRight pr-1 total tableSum'
          },
          {
            value: (betQueryResult && betQueryResult.sum.totalBetAmt) || 0,
            colspan: 1,
            className: 'tableSum'
          },
          {
            colspan: 1,
            className: 'tableSum'
          },
          {
            value: (betQueryResult && betQueryResult.sum.totalMemAmt) || 0,
            colspan: 1,
            className: 'tableSum'
          }]]}
        />
      </Row>
      <Row className="justify-center w-full relative">
        <Pagination totalRecord={betQueryResult ? (betQueryResult.sum.totalRecord || 0) : 0} onClick={onPaginationClick} isQuery={false} />
      </Row>
    </Col>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LotteryBet);