
import { dxds } from '../Items/dsdx';

import { fan1to4, jiao1to4, men1to4, nian1to4, zheng1to4 } from '../Items/others';
import { qsFANT } from '../Items/quickset';

const FANT = [{
  displayName: '总项盘口',
  hideMenu: 1,
  isStaticRow: 1,
  combineStyle: 1,
  repeatPerRow: 3,
  repeatPerCol: 3,
  midcode: 'DS,DX,FA,JI,NI,SM,TO,ZE',
  quickSet: {
    style: 2,
    variables: [[...qsFANT]]
  },
  display: [[...dxds],
    [...zheng1to4],
    [...nian1to4],
    [...fan1to4],
    [...men1to4], [],
    [...jiao1to4]
  ],
  firstTableHeader: ['两面', '正', '念', '番', '三门', '', '角'],
}
];

export default FANT;