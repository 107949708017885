/* eslint-disable indent */
import { useEffect, useState } from 'react';

import { Button, Input, Popover, Radio, Select } from 'antd';
import { FaCircle, FaEdit, FaPlusCircle } from 'react-icons/fa';
import { MdArrowBack } from 'react-icons/md';
import { connect } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { AgentDataTable, CommonHeader } from '../..';
import AgentAdd from '../components/AgentAdd';
import BetQueryDetail from '../../../views/BetQueryDetail';
import ChangeHisLog from '../components/ChangeHisLog';
import DefaultWinLost from '../../OddsManage/DefaultWinLost';
import JiaoDui from '../components/JiaoDui';
import LoginLog from '../components/LoginLog';
import LotteryReturn from '../components/LotteryReturn';
import MemberAdd from '../components/MemberAdd';
import MemberEdit from '../components/MemberEdit';
import Notifications from '../../Content/Notifications';
import Pagination from '../../Content/Pagination';
import ThirdPartyRebate from '../components/ThirdPartyRebate';

import { agentActions, appActions, commonActions, memberActions } from '../../../redux/actions';
import './styles.scss';

const limitTypeOptions = [{
  label: '全部', value: '',
}, {
  label: '信用', value: '1',
}, {
  label: '现金', value: '2',
}];

const statusOptions = [{
  label: '全部', value: '',
}, {
  label: '启用', value: '1',
}, {
  label: '暂停', value: '2',
}, {
  label: '停用', value: '0',
}, {
  label: '锁定', value: '3',
}];

const orderOptions = [{
  label: '降序排序', value: 'DESC'
}, {
  label: '升序排序', value: 'ASC'
}];

const orderByOptions = [{
  label: '请选择', value: ''
}, {
  label: '账号', value: 'LoginId'
}, {
  label: '额度', value: 'MoneyLimit'
}, {
  label: '创建日期', value: 'CreateDate'
}];

const searchByOptions = [{
  label: '账号', value: 'LoginId'
}, {
  label: 'IP', value: 'LoginIp'
}];


const mapStateToProps = state => ({
  userData: state.memberReducers.userData,
  userInfo: state.memberReducers.userInfo,
  downlineAgentList: state.commonReducers.downlineAgentList,
  accountType: state.commonReducers.accountType,
});

const mapDispatchToProps = dispatch => ({
  GetDownlineAgentList: (obj, callback, catchback) => dispatch(commonActions.GetDownlineAgentList(obj, callback, catchback)),
  SetBetOnlineNotification2: (obj, callback) => dispatch(memberActions.SetBetOnlineNotification2(obj, callback)),
  getMemberList2: (obj, callback, catchback) => dispatch(memberActions.getMemberList2(obj, callback, catchback)),
  getAgentList2: (obj, callback, catchback) => dispatch(agentActions.getAgentList2(obj, callback, catchback)),
  getAgentList: (agentId, levelValue, orderBy, order, page, rows, startDate, endDate, isChild, loginId, status, searchBy, minAmount, maxAmount) => dispatch(agentActions.getAgentList(agentId, levelValue, orderBy, order, page, rows, startDate, endDate, isChild, loginId, status, searchBy, minAmount, maxAmount)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  setAccountStatus: (obj, searchObj) => dispatch(agentActions.setAccountStatus(obj, searchObj)),
  setAccountStatus2: (obj, callback) => dispatch(agentActions.setAccountStatus2(obj, callback)),
  unlockMember: (obj, callback) => dispatch(agentActions.unlockMember(obj, callback)),
  GetAccountType: () => dispatch(commonActions.GetAccountType()),
});

const DirectMember = ({ GetAccountType, unlockMember, accountType, propData = {}, propOrgId = '', onMemberListClose = () => { }, showMember = false, SetBetOnlineNotification2, userInfo, downlineAgentList, GetDownlineAgentList, initialLevel, getMemberList2, userData, updateModalAlert, setAccountStatus2 }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({
    limitType: '',
    status: '',
    orderBy: 'CreateDate',
    order: 'DESC',
    searchBy: 'LoginId', // 后端参数定义错误，seach？？？
    searchValue: '' // 输入框搜索值
  });
  const [data, setData] = useState({}); // 表格数据 + 分页数据
  const copyStateData = data;

  const [pagination, setPagination] = useState({
    total: 0,
    pageSize: 15,
    current: 1
  });
  const [selectedItem, setSelectedItem] = useState({});
  const [res, setRes] = useState({});

  const [showDetail, setShowDetail] = useState(false);
  const [tabIndex, setTabIndex] = useState('');

  const [breadcrumbList, setBreadcrumbList] = useState([]);

  const downlineAgent = downlineAgentList.find(i => Number(i.level) === userData.levelValue + 1) || {};
  useEffect(() => {
    GetAccountType();
    GetDownlineAgentList();
  }, []);

  let nextAgentName = '';
  if (downlineAgent?.fullName) {
    if (downlineAgent?.fullName.indexOf('代理') !== -1) {
      nextAgentName = '代理';
    } else {
      nextAgentName = downlineAgent?.fullName;
    }
  }

  useEffect(() => {
    if (userData) {
      setBreadcrumbList([{
        label: `${userData.loginId}`,
        value: userData.loginId,
        orgId: userData.id,
        isChild: userData.isChild,
        userLevel: userData.levelValue,
        active: true,
        clickUp: false // 怎么来的。
      }]);
    }
    // getInfo();
  }, [userData]);

  useEffect(() => {
    getInfo();
  }, [breadcrumbList]);

  const activeUser = breadcrumbList.find(i => i.active) || {};
  const activeIndex = breadcrumbList.findIndex(i => i.active);

  useEffect(() => {
    if (propData && propData.loginId) {
      redirectToOtherLevel(propData, true);
    }
  }, [propData]);
  const redirectToOtherLevel = (data, clickUp) => {
    const newList = breadcrumbList.map(item => ({
      ...item, active: false
    }));
    newList.push(
      {
        label: `${data.levelName}-${data.loginId}`,
        value: data.loginId,
        orgId: data.orgId,
        isChild: data.isChild,
        userLevel: data?.level,
        active: true,
        clickUp
      }
    );
    setBreadcrumbList(newList);
  };

  const tableHeaderTitleOrigin = [{
    label: t('ONLINE'),
  }, {
    label: t('UPPER_LEVEL_ACCOUNT'),
  }, {
    label: t('OPERATION'),
  }, {
    label: t('USER_TYPE'),
  }, {
    label: t('ACCOUNT'),
  }].concat(
    (
      accountType.filter(item => {
      if (userData?.accountOption === 0) {
        return (item?.accountType === 2 || item?.accountType === 3);
      }
        return true;
    }).filter(item => {
      if (res?.info?.outsiteEnable === 0) {
        return item?.accountType !== 3;
      }
      return true;
    }).map(item => ({
      label: (userData?.accountOption === 0 && item?.accountType === 2 ? '额度' : item?.accName)
    }))
    ),
  [
  {
    label: t('ZC'),
  },
  {
    label: '盘口',
  },
  {
    label: t('CREATE_DATE'),
  }, {
    label: t('STATUS'),
  }].concat(
    userInfo.levelValue <= 2 && res?.isNeedMemberLoginAlert ? {
      label: '上线/下注提醒',
    } : []
  )
);

  const tableContentOrigin = [
    data => (
      <span className={`${data.online === 1 ? 'positiveValue' : 'offline'} justify-content-center smallicon`}>
        <FaCircle className="ml-2" />
      </span>
    ),
    data => (
      <div
        className="positiveValue urlNoUnderline"
        onClick={() => {
          if (activeUser.orgId === data.upOrgId) {
            const newList = [
              ...breadcrumbList.slice(0, activeIndex),
              {
                ...activeUser, clickUp: true,
              },
              ...breadcrumbList.slice(activeIndex + 1)
            ];
            setBreadcrumbList(newList);
          } else {
            redirectToOtherLevel({
              ...data,
              orgId: data.upOrgId,
              level: 0
            }, true); // 跳上级 level是 0
          }
        }}
      >
        {
          data.upLoginId
        }
      </div>
    ),
    data => (
      <span className="positiveValue urlNoUnderline" onClick={() => setSelectedItem(selectedItem?.orgId ? {} : data)}>
        <FaEdit className="ml-2" />
      </span>
    ),
    data => (
      <span
        className="d-flex justify-content-center"
      >
        {
          `${data.userType} ${data.levelName}`
        }
      </span>
    ),
    data => (
      <>
        <span className=" mx-1">
          {data.userId}
        </span>
        <span>
          [
          {data.loginName}
          ]
        </span>
      </>
    )].concat(
      (
        accountType.filter(item => {
        if (userData?.accountOption === 0) {
          return (item?.accountType === 2 || item?.accountType === 3);
        }
          return true;
      }).filter(item => {
        if (res?.info?.outsiteEnable === 0) {
          return item?.accountType !== 3;
        }
        return true;
      }).map(item => data => {
        const { accList = [] } = data;
        const balanceItem = accList?.find(i => i.accType === item?.accountType);
        return (
          <span
            className="positiveValue urlNoUnderline d-flex justify-content-center"
            onClick={() => updateModalAlert({
          type: 'balanceEdit',
          visible: true,
          data: {
            ...data,
            balanceItem,
            type: 1, // 会员是1 代理是2
            onConfirmationClick: () => getInfo()
          }
})}
          >
            {balanceItem?.balance}
          </span>
              );
        })
      ),
      [
          data => (
            <span
              className="positiveValue urlNoUnderline"
              onClick={() => {
                updateModalAlert({
                  type: 'sharesDetail',
                  visible: true,
                  data: {
                    userType: 1,
                    userId: data?.memberId // 换成会员
                  }
                });
              }}
            >
              {t('DETAILS')}
            </span>
          ),
          data => (<span className="px-1">{data.game}</span>),
          data => (<span className="px-1">{moment.unix(data.createDate).format('YYYY-MM-DD HH:mm:ss')}</span>),
          (data, index) => {
            let content = <Button className="operationBtn editAndSave">{t('PAUSE')}</Button>;
            if (data.useStateValue === 1) {
              content = <Button className="operationBtn add">{t('ACTIVE')}</Button>;
            } else if (data.useStateValue === 0) {
              content = <Button className="operationBtn remove">{t('INACTIVE')}</Button>;
            }
            return (
              <Popover
                trigger={['click']}
                content={(
                  <div className="p-0 d-flex align-items-center">
                    <Button
                      className="mx-1 operationBtn add"
                      onClick={() => {
                        data.useStateValue === 1 ? false : setAccountStatus2(
                          {
                            userId: data?.memberId,
                            status: 1,
                            userType: 1, // userType userId orgId  和agent 很不一样
                            orgId: data?.memberId,
                          },
                          () => {
                            setData(
                              [
                                ...copyStateData.slice(0, index),
                                {
                                  ...data, useStateValue: 1
                                },
                                ...copyStateData.slice(index + 1),
                              ]
                            );
                          }
                        );
                      }}
                    >
                      {t('ACTIVE')}
                    </Button>
                    <Button
                      className="mx-1 operationBtn remove"
                      onClick={() => {
                        data.useStateValue === 0 ? false : setAccountStatus2(
                          {
                            userId: data?.memberId,
                            status: 0,
                            userType: 1,
                            orgId: data?.memberId,
                          },
                          () => {
                            setData(
                              [
                                ...copyStateData.slice(0, index),
                                {
                                  ...data, useStateValue: 0
                                },
                                ...copyStateData.slice(index + 1),
                              ]
                            );
                          }
                        );
                      }}
                    >
                      {t('INACTIVE')}
                    </Button>
                    <Button
                      className="mx-1 operationBtn editAndSave"
                      onClick={() => {
                        data.useStateValue === 2 ? false : setAccountStatus2(
                          {
                            userId: data?.memberId,
                            status: 2,
                            userType: 1,
                            orgId: data?.memberId,
                          },
                          () => {
                            setData(
                              [
                                ...copyStateData.slice(0, index),
                                {
                                  ...data, useStateValue: 2
                                },
                                ...copyStateData.slice(index + 1),
                              ]
                            );
                          }
                        );
                      }}
                    >
                      {t('PAUSE')}
                    </Button>
                  </div>
                )}
              >
                {content}
              </Popover>
            );
          }
        ].concat(
          userInfo.levelValue <= 2 && res?.isNeedMemberLoginAlert ? (data, index) => {
            const content = data.onlineWaring;
            const handleChange11 = value => {
              SetBetOnlineNotification2(
                {
                  username: data.memberId,
                  notifyType: value,
                  userType: 'Member'
                },
                () => {
                  setData(
                    [
                      ...copyStateData.slice(0, index),
                      {
                        ...data,
                        isLoginWaring: value,
                        onlineWaring: ['不提醒', '登陆提醒', '下注提醒', '都提醒'][value]
                      },
                      ...copyStateData.slice(index + 1),
                    ]
                  );
                }
              );
            };
            return (
              <Popover
                trigger={['click']}
                content={(
                  <>
                    <Radio className="w-max" checked={data.isLoginWaring === 1} onChange={() => handleChange11(1)}>上线提醒</Radio>
                    <Radio className="w-max" checked={data.isLoginWaring === 2} onChange={() => handleChange11(2)}>下注提醒</Radio>
                    <Radio className="w-max" checked={data.isLoginWaring === 3} onChange={() => handleChange11(3)}>上线/下注提醒</Radio>
                    <Radio className="w-max" checked={data.isLoginWaring === 0} onChange={() => handleChange11(0)}>不提醒</Radio>
                  </>
                )}
              >
                <Button className={`operationBtn ${data.isLoginWaring === 2 && 'edit'} ${data.isLoginWaring === 3 && 'add'} ${data.isLoginWaring === 0 && 'remove'} ${data.isLoginWaring === 1 && 'editAndSave'}`}>{content}</Button>
              </Popover>
            );
          } : []
      )
    );

  const tableHeaderTitle = tableHeaderTitleOrigin;

  const tableContent = tableContentOrigin;


  const getInfo = async (paginationParams = {}, params = {}) => {
    setLoading(true);
    const newPagiantion = {
      ...pagination,
      ...paginationParams,
    };

    getMemberList2(
      {
        agentId: (showMember ? propOrgId : activeUser?.orgId) || '',
        level: 12, // activeClickUp? 0: activeUserLevel, // 点击上级账号，level 变成 0
        orderBy: filter.orderBy,
        order: filter.order,
        loginId: filter.searchValue, // 搜索值
        page: newPagiantion.current,
        rows: newPagiantion.pageSize,
        isChild: initialLevel === 'all' ? 0 : 1, // 会员是 1 agent 是0
        status: filter.status,
        seachBy: filter.searchBy,
        limitType: filter.limitType,
        ...params
      },
      res => {
        setRes(res);
        setData(res?.memList || []);
        setPagination({
          ...newPagiantion,
          total: res?.sum?.totalRecords || 0
        });
        setLoading(false);
      },
      () => setLoading(false)
    );
    setSelectedItem({});
  };

  const handleClickControl = (tabIndex = '') => {
    if (tabIndex === 0) {
      setFilter({
        ...filter,
        limitType: '',
        status: '',
        orderBy: 'CreateDate',
        order: 'DESC',
        searchBy: 'LoginId', // 后端参数定义错误，seach？？？
        searchValue: '' // 输入框搜索值
      });
      setPagination({
        ...pagination,
        current: 1
      });
    }
    setShowDetail(true);
    setTabIndex(tabIndex);
  };

  const actions = [
    {
      // 新增直属会员
      label: '',
      render: data => (
        <MemberAdd
          data={data}
          callback={v => {
            if (!v) return;
            setShowDetail(false);
            getInfo();
          }}
          onCancel={() => setShowDetail(false)}
        />
      )
    },
    {
      // 新增代理
      label: '',
      render: () => (
        <AgentAdd
          callback={v => {
            if (!v) return;
            setShowDetail(false);
            getInfo();
          }}
          onCancel={() => setShowDetail(false)}
        />
      )
    },
    {
      label: '修改',
      render: data => (
        <MemberEdit
          callback={v => {
            if (!v) return;
            setShowDetail(false);
            getInfo();
          }}
          data={data}
          onCancel={() => setShowDetail(false)}
        />
      )
    },
    (res?.isEarnOdds === 1 && {
      label: '赚赔', render: data => (<DefaultWinLost data={data} />)
    }) || null,
    (selectedItem?.isLocked === 1 && {
      label: '解锁',
      onClick: () => {
        unlockMember({
          memberId: selectedItem?.memberId,
          memberType: 1,
        });
      }
    }) || null,
    {
      label: '彩票退水', render: data => (<LotteryReturn data={data} />)
    },
    (selectedItem?.thirdPartyEnable === 1
    && {
      label: '视讯，棋牌退水', render: data => (<ThirdPartyRebate data={data} />)
    }) || null,
    (selectedItem.userType === '现金' && {
      label: '校对', render: data => (<JiaoDui data={data} />)
    }) || null,
    {
      label: '登陆日志', render: data => (<LoginLog data={data} />)
    }, {
      label: '变更日志', render: data => (<ChangeHisLog data={data} />)
    },
    (userData?.levelValue === 0 && {
      label: '浏览日志',
    }) || null,
    {
      label: '注单',
      render: data => (
        <BetQueryDetail presetObj={{
          loginName: data.loginId,
          level: data?.level
        }}
        />
      )
    },
    (selectedItem.userType === '现金' && selectedItem.thirdPartyEnable === 1 && {
      label: '游戏余额',
      onClick: () => {
        updateModalAlert({
          type: 'thirdPartyGames',
          visible: true,
          data: {
            orgId: selectedItem?.orgId,
            memberId: selectedItem.memberId
          }
        });
      }
    }) || null,
  ].filter(Boolean).map((item, index) => ({
    ...item,
    value: index,
    onClick: item.onClick || (() => handleClickControl(index))
  }));

  const tableControl = [
    data => (
      <>
        {
          // 除去 新增直属会员
          actions.filter(i => i.label).map(item => (
            <Button
              className="controlButton mx-1"
              value={data?.orgId}
              onClick={() => item.onClick()}
            >
              {
                item.label
              }
            </Button>
          ))
        }
      </>
    )
  ];

  return (
    <div className="DirectMemberWrapper1 p-3">
      {
        !showDetail && (
          <>
            <div className="header p-2 mb-2 filterWrapper d-flex align-items-center w-100 rounded-top">
              <div className="filterArea">
                <div>
                  <span className="mx-1">模式</span>
                  <Select
                    value={filter.limitType}
                    onChange={value => {
                      setFilter({
                        ...filter, limitType: value
                      });
                      getInfo({}, {
                        limitType: value
                      });
                    }}
                    options={limitTypeOptions}
                  />
                </div>
                <div>
                  <span className="mx-1">{t('STATUS')}</span>
                  <Select
                    value={filter.status}
                    onChange={value => {
                      setFilter({
                        ...filter, status: value
                      });
                      getInfo({}, {
                        status: value
                      });
                    }}
                    options={statusOptions}
                  />
                </div>
                <div>
                  <span className="mx-1">排序</span>
                  <Select
                    value={filter.orderBy}
                    onChange={value => {
                      setFilter({
                        ...filter, orderBy: value
                      });
                      getInfo({}, {
                        orderBy: value
                      });
                    }}
                    options={orderByOptions}
                  />
                  <Select
                    className="mx-1"
                    value={filter.order}
                    onChange={value => {
                      setFilter({
                        ...filter, order: value
                      });
                      getInfo({}, {
                        order: value
                      });
                    }}
                    options={orderOptions}
                  />
                </div>
                <div className="d-flex align-items-center ">
                  <span className="mx-1">搜索</span>
                  <Select
                    value={filter.searchBy}
                    onChange={value => (setFilter({
                      ...filter, searchBy: value
                    }))}
                    options={searchByOptions}
                  />
                  <Input
                    className="mx-1 w-input-100"
                    value={filter.searchValue}
                    onChange={e => (setFilter({
                      ...filter, searchValue: e.target.value
                    }))}
                  />
                </div>
                <div>
                  <Button className="searchButton marginLeft5 me-2" onClick={() => getInfo()}>{t('FIND')}</Button>
                </div>
              </div>

              <Notifications />
            </div>

            <div className="subMenu mb-2 d-flex align-items-center">
              {
                showMember && (
                  <div
                    className="d-flex align-items-center me-2 backIcon"
                    onClick={() => {
                      onMemberListClose({
                        showMember: false,
                      });
                    }}
                  >
                    <MdArrowBack className="h5 mb-0" />
                    返回 代理列表
                  </div>
                )
              }
              {
                userData?.levelValue >= 11 && (
                  <Button
                    className="addButton mx-2"
                  >
                    <FaPlusCircle className="mx-1" />
                    新增会员
                  </Button>
                )
              }
              {
                userData?.levelValue <= 2 && (
                  <Button
                    className="addButton mx-2"
                    onClick={() => {
                      setSelectedItem({});
                      handleClickControl(1);
                    }}
                  >
                    <FaPlusCircle className="mx-1" />
                    新增
                    {nextAgentName}
                  </Button>
                )
              }
              {
                userData?.levelValue > 2 && userData?.levelValue < 11 && (
                  <>
                    <Button
                      className="addButton mx-2"
                      onClick={() => {
                        setSelectedItem({});
                        handleClickControl(1);
                      }}
                    >
                      <FaPlusCircle className="mx-1" />
                      新增
                      {nextAgentName}
                    </Button>
                    <Button
                      className="addButton mx-2"
                      onClick={() => handleClickControl(0)}
                    >
                      <FaPlusCircle className="mx-1" />
                      新增直属会员
                    </Button>
                  </>
                )
              }
              <div className="breadcrumbList p-0 px-3 d-flex justify-content-start align-items-center">
                {
                  breadcrumbList.map((item, i) => (
                    <div className="d-flex">
                      <span
                        className={`mainitem ${item.orgId === activeUser.orgId && 'active'}`}
                        onClick={() => {
                          const temp = [
                            ...breadcrumbList.slice(0, i),
                            {
                              ...breadcrumbList[i],
                              active: true,
                              clickUp: i !== 0, // 第一级的时候，恢复
                            }
                          ];
                          setBreadcrumbList(temp);
                        }}
                      >
                        {item.label}
                      </span>
                      {(breadcrumbList.length - 1) !== i && (<span className="mx-2 delimiter">/</span>)}
                    </div>
                  ))
                }
              </div>
            </div>

            <div>
              <AgentDataTable
                tableHeaderTitle={tableHeaderTitle}
                tableControl={tableControl}
                tableBodyRowProperty={tableContent}
                activeControl={(selectedItem?.memberId || undefined)}
                isQuerying={loading}
                tableData={data}

              />
            </div>

            <div>
              <Pagination
                customizePage={pagination?.current}
                totalRecord={pagination?.total || 0}
                isQuery={loading}
                onClick={(pageNum, rows) => {
                  getInfo({
                    pageSize: rows, current: pageNum
                  });
                }}
              />
            </div>
          </>
        )
      }

      {
        showDetail && (
          <>
            <CommonHeader
              headerName={(
                <div className="d-flex align-items-center">
                  <div
                    className="d-flex align-items-center me-2 backIcon"
                    onClick={() => {
                    setShowDetail(false);
                    getInfo();
                    }}
                  >
                    <MdArrowBack className="h5 mb-0 text-white" />
                    返回
                  </div>
                  <div>
                    {
                      initialLevel !== 'all' && (<span className="mx-1">直属会员</span>)
                    }
                    {
                      initialLevel === 'all' && (<span className="mx-1">全部会员</span>)
                    }
                    /
                    {
                      tabIndex === 0 && (
                        <span className="mx-1">新增直属会员</span>
                      )
                    }
                    {
                      tabIndex !== 0 && (
                        <span className="mx-1">{selectedItem?.loginId}</span>
                      )
                    }
                  </div>
                </div>
              )}
            />
            {
              ![0, 1].includes(tabIndex) && (
                <div className="whiteListMenu">
                  {/* 内容和 control 一样。slice(1) 没有render 的也不能出现，注意 tableIndex 是基于所有的actions */}
                  {
                    actions.map((item, index) => {
                      const { label, onClick, render } = item;
                      if (!render) return false;
                      return (
                        <div onClick={() => onClick()} className={`menu_item ${tabIndex === index && 'selected'}`}>
                          {label}
                        </div>
                      );
                    }).slice(1).filter(Boolean)
                  }
                </div>
              )
            }

            <div>
              {actions.find(i => i.value === tabIndex)?.render?.(selectedItem)}
            </div>
          </>
        )
      }
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DirectMember);