import { SET_COMPANY_EDIT_INFO, SET_COMPANY_INFO, SET_COMPANY_MANAGE_INFO, SET_DOMAIN_EDIT_INFO, SET_DOMAIN_INFO, SET_DOMAIN_NAME_SETTING, SET_GAMES_INFO, SET_LV2_ORGS, SET_UPORG_FOR_COPY } from '../../actions/actionTypes';

const initialState = {
  oddsDiffUpOrgsData: undefined,
  upOrgsCopyData: undefined,
  companyInfoData: undefined,
  companyEditInfo: undefined,
  domainInfo: undefined,
  domainEditInfo: undefined,
  companyManageInfo: undefined,
  gamesInfo: undefined,
  domainNameSetting: []
};

const companyReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_LV2_ORGS:
      return {
        ...state, oddsDiffUpOrgsData: action.value
      };
    case SET_UPORG_FOR_COPY:
      return {
        ...state, upOrgsCopyData: action.value
      };
    case SET_COMPANY_INFO:
      return {
        ...state, companyInfoData: action.value
      };
    case SET_COMPANY_EDIT_INFO:
      return {
        ...state, companyEditInfo: action.value
      };
    case SET_DOMAIN_NAME_SETTING:
      return {
        ...state, domainNameSetting: action.value
      };
    case SET_DOMAIN_INFO:
      return {
        ...state, domainInfo: action.value
      };
    case SET_DOMAIN_EDIT_INFO:
      return {
        ...state, domainEditInfo: action.value
      };
    case SET_COMPANY_MANAGE_INFO:
      return {
        ...state, companyManageInfo: action.value
      };
    case SET_GAMES_INFO:
      return {
        ...state, gamesInfo: action.value
      };
    default:
      return state;
  }
};

export default companyReducers;