import { SET_ADD_MEMBER_INFO, SET_CAPTCHA_DATA, SET_EDIT_MEMBER_DETAIL, SET_LOAD_LOGIN_LOGS, SET_MEMBER_LIST, SET_MFA_SETTING, SET_USER_DATA, SET_USER_INFO } from '../../actions/actionTypes';

const initialState = {
  userData: undefined,
  captchaData: undefined,
  userInfo: undefined,
  loadLoginLogs: undefined,
  mfaSetting: undefined,
  memberListData: undefined,
  addMemberInfoData: undefined,
  editMemDetailData: {},
};

const memberReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_DATA:
      return {
        ...state, userData: action.value
      };

    case SET_USER_INFO:
      return {
        ...state, userInfo: action.value
      };

    case SET_CAPTCHA_DATA:
      return {
        ...state, captchaData: action.value
      };

    case SET_LOAD_LOGIN_LOGS:
      return {
        ...state, loadLoginLogs: action.value
      };

    case SET_MFA_SETTING:
      return {
        ...state, mfaSetting: action.value
      };

    case SET_MEMBER_LIST:
      return {
        ...state, memberListData: action.value
      };

    case SET_ADD_MEMBER_INFO:
      return {
        ...state, addMemberInfoData: action.value
      };

    case SET_EDIT_MEMBER_DETAIL:
      return {
        ...state, editMemDetailData: action.value
      };

    default:
      return state;
  }
};

export default memberReducers;