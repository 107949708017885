import CJ_11X5 from './11X5';
import CJ_F31X7 from './F31X7';
import CJ_F36X7 from './F36X7';
import CJ_FANT from './FANT';
import CJ_GXK10 from './GXK10';
import CJ_HK6 from './HK6';
import CJ_K3 from './K3';
import CJ_KL10 from './KL10';
import CJ_PCDD from './PCDD';
import CJ_PK10 from './PK10';
import CJ_QXC from './QXC';
import CJ_SSC from './SSC';

export default {
  CJ_11X5,
  CJ_HK6,
  CJ_PK10,
  CJ_F31X7,
  CJ_F36X7,
  CJ_FANT,
  CJ_GXK10,
  CJ_K3,
  CJ_KL10,
  CJ_SSC,
  CJ_PCDD,
  CJ_QXC
};