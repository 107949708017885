import { useEffect, useState } from 'react';

import { Button, DatePicker, Tabs } from 'antd';
import { Col, Label, Row } from 'reactstrap';
import { connect } from 'react-redux';

import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { appActions, memberActions, operationLogAtions } from '../../../redux/actions';
import AgentDataTable from '../../AgentDataTable';
import Pagination from '../../Content/Pagination';

import './styles.scss';


const mapStateToProps = state => ({
  operationLogResult: state.operationLogReducers.operationLogResult,
  userData: state.memberReducers.userData,
  loadLoginLogs: state.memberReducers.loadLoginLogs,
});

const { RangePicker } = DatePicker;

const mapDispatchToProps = dispatch => ({
  getOperationLogByType: (type, rows, page, from, to) => dispatch(operationLogAtions.getOperationLogByType(type, rows, page, from, to)),
  getHandlerLog: (obj, callback) => dispatch(operationLogAtions.GetHandlerLog(obj, callback)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  getLoadLoginLogs: (obj, callback) => dispatch(memberActions.getLoadLoginLogs(obj, callback)),
});

const SystemLogs = ({ getOperationLogByType, operationLogResult, getLoadLoginLogs, userData, loadLoginLogs }) => {
  const { t } = useTranslation();
  const [_info, setInfo] = useState([]);
  const [_date, setDate] = useState([]);
  const [_page, setPage] = useState(1);
  const [_rows, setRows] = useState(15);
  const [_totalRecord, setTotalRecord] = useState(0);
  const [isQueryStatus, setQueryStatus] = useState(true);
  const [_selectedTab, setSelectedTab] = useState('0');

  useEffect(() => {
    setQueryStatus(true);
    getLoadLoginLogs({
      id: userData?.id,
      rows: _rows,
      page: _page,
    });
  }, []);

  useEffect(() => {
    setPage(1);
    if (_selectedTab === '0') {
      getLoadLoginLogs({
        id: userData?.id,
        rows: _rows,
        page: 1,
        startDate: (_date?.length === 0 ? '' : moment(_date[0])?.unix()),
        endDate: (_date?.length === 0 ? '' : moment(_date[1])?.unix()),
      });
    } else {
      getOperationLogByType(
        _selectedTab,
        _rows,
        1,
        (_date?.length === 0 ? '' : moment(_date[0])?.unix()),
        (_date?.length === 0 ? '' : moment(_date[1])?.unix()),
      );
    }
  }, [_selectedTab]);

  useEffect(() => {
    if (_selectedTab !== '0' && _selectedTab !== '-1' && operationLogResult && JSON.stringify(operationLogResult?.data) !== JSON.stringify(_info)) {
      setInfo(operationLogResult?.data);
      setTotalRecord(operationLogResult?.sum?.totalRecords);
    }
    setQueryStatus(false);
  }, [operationLogResult]);

  useEffect(() => {
    if (_selectedTab === '0' && loadLoginLogs && JSON.stringify(loadLoginLogs?.logs) !== JSON.stringify(_info)) {
      setInfo(loadLoginLogs?.logs);
      setTotalRecord(loadLoginLogs?.sum?.totalRecords);
    }
    setQueryStatus(false);
  }, [loadLoginLogs]);

  const onPaginationClick = (pageNum, rows) => {
    setQueryStatus(false);
    // Page number and row number will follow the pagination component instead
    if (_selectedTab === '0') {
      getLoadLoginLogs({
        id: userData?.id,
        rows,
        page: pageNum,
        startDate: (_date?.length === 0 ? '' : moment(_date[0])?.unix()),
        endDate: (_date?.length === 0 ? '' : moment(_date[1])?.unix()),
      });
    } else {
      getOperationLogByType(
        _selectedTab,
        rows,
        pageNum,
        (_date?.length === 0 ? '' : moment(_date[0])?.unix()),
        (_date?.length === 0 ? '' : moment(_date[1])?.unix()),
      );
    }
    setPage(pageNum);
    setRows(rows);
  };

  const tableHeader = [{
    label: t('ORDER_NUM'),
  }, {
    label: t('ACCOUNT_TYPE'),
  }, {
    label: t('OPERATE_TYPE'),
  }, {
    label: t('OPERATE_CONTENT'),
  }, {
    label: t('LOG_OLD_VALUE'),
  }, {
    label: t('LOG_NEW_VALUE'),
  }, {
    label: t('OPERATION_TIME'),
  }, {
    label: 'IP',
  }, {
    label: `IP ${t('DEPENDENCY')}`,
  }, {
    label: t('UPDATED_BY'),
  }, {
    label: t('TARGET_ACCOUNT'),
  }];

  const logList = [{
    label: '登陆日志',
    value: '0',
  },
  {
    label: '自动补货变更日志',
    value: '1',
  },
  {
    label: '赔率变更日志',
    value: '4',
  }, {
    label: '退水变更日志',
    value: '2',
  },
  {
    label: '自动降赔变更日志',
    value: '7',
  },
  {
    label: '盘口信息变更日志',
    value: '9',
  }, {
    label: '会员信息变更日志',
    value: '6',
  },
  {
    label: '操作日志',
    value: '-1',
  },
  {
    label: '设置信息变更日志',
    value: '10',
  }, {
    label: '开奖与结算',
    value: '8',
  }];

  const tableContent = [
    data => (<Label className="d-flex justify-content-center">{data.rowNo}</Label>),
    data => (<Label className="d-flex justify-content-center">{_selectedTab === '0' ? '代理' : (data?.memberType === 2 ? '代理' : '会员')}</Label>),
    () => (<Label className="d-flex justify-content-center">{logList.find(x => x.value === _selectedTab).label}</Label>),
    data => (<Label className="d-flex justify-content-center">{_selectedTab === '0' ? '系统登陆' : data.operation}</Label>),
    data => (<Label className="d-flex justify-content-center">{data.oldValue}</Label>),
    data => (<Label className="d-flex justify-content-center">{_selectedTab === '0' ? '系统登陆' : data.newValue}</Label>),
    data => (
      <Label className="px-1">{moment.unix(data?.operationDate || data?.goOnDate).format('YYYY-MM-DD HH:mm:ss')}</Label>
    ),
    data => (<Label className="d-flex justify-content-center">{data.ipAddress || data?.ip}</Label>),
    data => (<Label className="d-flex justify-content-center">{data.ipPlace}</Label>),
    data => (<Label className="d-flex justify-content-center">{data.loginId}</Label>),
    data => (<Label className="d-flex justify-content-center">{data.remark}</Label>),
  ];

  const findClick = () => {
    setQueryStatus(true);
    setPage(1);
    if (_selectedTab === '0') {
      getLoadLoginLogs({
        id: userData?.id,
        rows: _rows,
        page: 1,
        startDate: (_date?.length === 0 ? '' : moment(_date[0])?.unix()),
        endDate: (_date?.length === 0 ? '' : moment(_date[1])?.unix()),
      });
    } else {
      getOperationLogByType(
        _selectedTab,
        _rows,
        1,
        (_date?.length === 0 ? '' : moment(_date[0])?.unix()),
        (_date?.length === 0 ? '' : moment(_date[1])?.unix()),
      );
    }
  };


  return (
    <div className="SystemLogsWrapper">
      <div className="mx-0 bg-white my-2  border-1">
        <Row className="p-0 m-0 mb-2">
          <Col className="p-1 mb-2 filterWrapper d-flex align-items-center w-100">
            <Col className="d-flex align-items-center">
              <Label value={t('EXTERNAL_WEB_NAME')} className="px-3 marginLeft5">日期范围</Label>
              <div className="d-flex">
                <div>
                  <RangePicker
                    value={[_date[0] && moment(_date[0]), _date[1] && moment(_date[1])]}
                    separator={<span>{` ${t('TO')} `}</span>}
                    onChange={date => {
                      setDate([moment(date[0]).startOf('day').toDate(), moment(date[1]).endOf('day').toDate()]);
                    }}
                  />
                </div>
              </div>
              <Button className="button find mx-3" onClick={() => findClick()}>{t('FIND')}</Button>
              <Button className="button reset" onClick={() => setDate([])}>{t('RESET')}</Button>
            </Col>
          </Col>
        </Row>
        <Row className="bigWrapper m-0 my-1 p-2">
          <Row className="m-0 p-1">
            <Tabs
              className="m-0 p-0"
              activeKey={_selectedTab}
              onChange={key => {
                setSelectedTab(key);
                setQueryStatus(true);
              }}
            >
              {
                logList.filter(x => x.value !== ((userData?.levelValue === 1 && userData?.isChild === 0) ? '999' : '8')).map(item => (
                  <Tabs.Pane className="m-0 p-0" tab={item.label} key={item.value}>
                    <Row className="m-0 p-0">
                      <AgentDataTable
                        isQuerying={isQueryStatus}
                        tableHeaderTitle={tableHeader}
                        tableData={_info}
                        tableBodyRowProperty={tableContent}
                        tableBodyRowStyle={['', '', '', '', 'col-3', 'col-3', '', '', '', '', '', '']}
                      />
                    </Row>
                  </Tabs.Pane>
                ))
              }
              <Tabs.Pane className="none" label="" name="" />
            </Tabs>
          </Row>
        </Row>
      </div>
      <Row className="justify-center w-full relative smallInput">
        <Pagination totalRecord={_totalRecord || 0} onClick={onPaginationClick} isQuery={isQueryStatus} />
      </Row>
      <Row className="text-danger justify-content-center w-full relative py-3">
        注意：修改记录最少保留15天，超过15天部分最多保留最后50笔。
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SystemLogs);