import { useEffect, useRef, useState } from 'react';

import { Button, Col, Row } from 'reactstrap';
import { HiOutlineViewGrid } from 'react-icons/hi';
import { IoClose } from 'react-icons/io5';
import { Spin } from 'antd';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { appActions, companyActions, systemActions } from '../../../redux/actions';
import './styles.scss';


const mapStateToProps = state => ({
  platform: state.appReducers.platform,
  oddsDiffUpOrgsData: state.companyReducers.oddsDiffUpOrgsData,
});

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  getOddsDiffUpOrgs: (id, lotType) => dispatch(companyActions.getOddsDiffUpOrgs(id, lotType)),
  updateLotteryOrder: obj => dispatch(systemActions.updateLotteryOrder(obj)),
});

const LotteryReorderModal = ({ updateModalAlert, data, updateLotteryOrder }) => {
  const { t } = useTranslation();
  const [_loading, setIsLoading] = useState(false);
  const dragItem = useRef();
  const dragOverItem = useRef();
  const [_list, setList] = useState([]);

  useEffect(() => {
    if (data) {
      setList(data);
    }
  }, [data]);


  const saveButton = () => {
    const newCodeOrder = [...new Set(_list.map(item => item.code))];
    setIsLoading(true);
    updateModalAlert({
      type: 'loading',
      visible: true
    });
    const obj = newCodeOrder;
    updateLotteryOrder(obj);
  };

  const dragStart = (e, position) => {
    e.target.className = 'myClass';
    e.target.style.opacity = 1;
    dragItem.current = position;
    return false;
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };

  const drop = e => {
    e.target.className = 'boxDropped my-1';
    const copyListItems = [..._list];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setList(copyListItems);
  };

  return (
    <div className="lotteryReorderModalWrapper">
      {
        _loading && (
          <div className="black loadingwrapper justify-content-center">
            <Spin />
          </div>
        )
      }
      <Row className="modalHeader">
        <Col
          className={`${'d-flex justify-content-center align-items-center'}`}
          xs={{
            size: 4, offset: 4
          }}
        >
          彩种修改排序
        </Col>
        <Col xs="4" className="d-flex justify-content-end align-items-center">
          <IoClose
            onClick={() => {
              updateModalAlert({
                visible: false
              });
              if (data && data.overBet && data.onConfirmationClick) data.onConfirmationClick();
            }}
            className="h4 mb-0 float-right closeIcon"
          />
        </Col>
      </Row>
      <Row className="modalContent d-flex justify-content-center">
        <Row className="tableWrapper p-2 justify-content-center align-items-center">
          <Col className="justify-content-center align-items-center">
            {_list && _list.map((item, index) => (
              <div
                className="box my-1 justify-content-center align-items-center"
                onDragStart={e => dragStart(e, index)}
                onDragEnter={e => dragEnter(e, index)}
                onDragEnd={e => drop(e)}
                key={`key_${item?.code}`}
                draggable
              >
                <HiOutlineViewGrid className="justify-content-center align-items-center mx-1 blue" />
                {item?.name}
              </div>
            ))}
          </Col>
        </Row>
      </Row>
      <Row className="modalFooter d-flex align-items-center">
        <Button
          className={`confirmButton my-2 ${'me-3'}`}
          onClick={() => saveButton()}
        >
          {(data && data.confirmText) || t('CONFIRM')}
        </Button>
        <Button
          className={`confirmButton my-2 ${'me-3'}`}
          onClick={() => {
            updateModalAlert({
              visible: false
            });
          }}
        >
          {(data && data.confirmText) || t('CANCEL')}
        </Button>
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LotteryReorderModal);