import { StatusCodes } from 'http-status-codes';

import { SET_PANKOU_LOTTERY_RESULT, SET_RESULT } from '../actionTypes';
import { apiService } from '../../../library';
import appActions from '../appActions';

const setPanKouLotteryResult = data => ({
  type: SET_PANKOU_LOTTERY_RESULT,
  value: data,
});

const setResult = data => ({
  type: SET_RESULT,
  value: data,
});


const GetLotteryresult = (obj, callback = () => {}) => dispatch => {
  apiService.get('/LotteryResult/Lotteryresult', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        callback();
        return dispatch(setPanKouLotteryResult(data.result));
      }
    }
  }).catch(error => {
    dispatch(setPanKouLotteryResult());
    dispatch(appActions.updateModalAlert({
      visible: true,
      type: 'confirmation',
      data: {
        message: error.response.data.msg
      }
    }));
    Promise.reject(error);
  });
};

const getResult = (obj, callback = () => {}) => dispatch => {
  apiService.get('/LotteryResult/GetInfo', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        callback();
        return dispatch(setResult(data.result));
      }
    }
  }).catch(error => {
    callback();
    dispatch(setResult());
    dispatch(appActions.updateModalAlert({
      visible: true,
      type: 'confirmation',
      data: {
        message: error.response.data.msg
      }
    }));
    Promise.reject(error);
  });
};

export default {
  GetLotteryresult,
  getResult,
};