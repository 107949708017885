import { useEffect, useState } from 'react';

import { Button, Col, Label, Row } from 'reactstrap';
import { connect } from 'react-redux';

import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

import { appActions, systemActions } from '../../../redux/actions';
import AgentDataTable from '../../AgentDataTable';
import Pagination from '../../Content/Pagination';
import './styles.scss';


const mapStateToProps = state => ({
  thirdPaySettingData: state.systemReducers.thirdPaySettingData,
  userData: state.memberReducers.userData,
});

const mapDispatchToProps = dispatch => ({
  getThirdPartyPaySetting: (obj, callback) => dispatch(systemActions.getThirdPartyPaySetting(obj, callback)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  setThirdPartyPayStatus: (obj, callback) => dispatch(systemActions.setThirdPartyPayStatus(obj, callback)),
  copyThirdPartyPaySetting: (obj, callback) => dispatch(systemActions.copyThirdPartyPaySetting(obj, callback)),
  updateThirdPartyPaySorting: (obj, callback) => dispatch(systemActions.updateThirdPartyPaySorting(obj, callback)),
});

const ThirdPartyPay = ({ callback = () => {}, thirdPaySettingData, getThirdPartyPaySetting, updateThirdPartyPaySorting, updateModalAlert, copyThirdPartyPaySetting, setThirdPartyPayStatus }) => {
  const { t } = useTranslation();
  const [_info, setInfo] = useState([]);
  const [_page, setPage] = useState(1);
  const [_rows, setRows] = useState(15);
  const [_totalRecord, setTotalRecord] = useState(0);
  const [_isQueryingDetail, setIsQueryingDetail] = useState(true);
  const [isQueryStatus, setQueryStatus] = useState(true);
  const [_payGroup, setPayGroup] = useState('');
  const [_sorting, setSorting] = useState('');
  const [_payType, setPayType] = useState('');
  const [_itemStatus, setItemStatus] = useState('');
  const [_customPage, setCustomPage] = useState(0);
  const [_highlightItem, setHighLightItem] = useState({});
  const [_isDelStatus, setIsDelStatus] = useState('');
  const [_isLoaded, setIsReload] = useState(false);

  useEffect(() => {
    if (thirdPaySettingData?.thirdList && JSON.stringify(thirdPaySettingData?.thirdList) !== JSON.stringify(_info)) {
      const list = [...thirdPaySettingData?.thirdList ?? []];
      const index = Number(list?.findIndex(item => item.id === _highlightItem?.id));
      if (index !== -1) {
        list[index].actionStatus = '300';
      } else if (_highlightItem !== '' && index === -1 && _isLoaded) {
        setIsReload(false);
        if (_highlightItem?.type === 0) {
          onPaginationClick(_page - 1, _rows);
          setCustomPage(_page - 1);
        } else {
          onPaginationClick(_page + 1, _rows);
          setCustomPage(_page + 1);
        }
      }
      setInfo(list);
      setTotalRecord(thirdPaySettingData?.totalRecords);
      setQueryStatus(false);
    }
  }, [thirdPaySettingData]);

  useEffect(() => {
    setIsQueryingDetail(true);
    getThirdPartyPaySetting({
      page: _page,
      rows: _rows,
    }, setIsQueryingDetail);
  }, []);

  const findClick = () => {
    // Page number and {row number will follow the pagination component instead
    getThirdPartyPaySetting({
      payType: _payType,
      groupType: _payGroup,
      status: _itemStatus,
      isDel: _isDelStatus,
      order: _sorting,
      page: 1,
      rows: _rows,
    }, setIsQueryingDetail);
  };

  const afterSetClick = () => {
    // Page number and {row number will follow the pagination component instead
    getThirdPartyPaySetting({
      payType: _payType,
      groupType: _payGroup,
      status: _itemStatus,
      isDel: _isDelStatus,
      order: _sorting,
      page: _page,
      rows: _rows,
    }, setIsQueryingDetail);
  };


  const onPaginationClick = (pageNum, rows) => {
    setIsQueryingDetail(false);
    // Page number and {row number will follow the pagination component instead
    getThirdPartyPaySetting({
      payType: _payType,
      groupType: _payGroup,
      status: _itemStatus,
      isDel: _isDelStatus,
      order: _sorting,
      page: pageNum,
      rows: _rows,
    }, setIsQueryingDetail);
    setPage(pageNum);
    setRows(rows);
  };
  const CopyClick = id => {
    updateModalAlert({
      type: 'confirmation',
      visible: true,
      showCancelButton: true,
      data: {
        message: '确认复制',
        onConfirmationClick: () => copyThirdPartyPaySetting(id)
      },
    });
  };

  const setStatus = (id, type, needNoti) => {
    const updateobj = {
      id,
      type
    };
    if (needNoti) {
      updateModalAlert({
        type: 'confirmation',
        visible: true,
        showCancelButton: true,
        data: {
          message: '确认删除',
          onConfirmationClick: () => setThirdPartyPayStatus(updateobj, afterSetClick)
        },
      });
    } else {
      setThirdPartyPayStatus(updateobj, afterSetClick);
    }
  };

  const updateSorting = (id, type) => {
    setIsReload(true);
    setHighLightItem({
      id,
      type
    });
    const updateobj = {
      id,
      type
    };
    updateThirdPartyPaySorting(updateobj, afterSetClick);
  };

  const Status = [
    {
      label: '全部',
      value: '-1',
    },
    {
      label: '启用',
      value: '0',
    }, {
      label: '禁用',
      value: '1',
    }
  ];

  const IsDelStatus = [
    {
      label: '全部',
      value: '-1',
    },
    {
      label: '正常',
      value: '0',
    }, {
      label: '已删除',
      value: '1',
    }
  ];


  const Sorting = [
    {
      label: '降序',
      value: '0',
    }, {
      label: '升序',
      value: '1',
    }
  ];

  const PayType = [
    {
      label: '全部',
      value: '-1',
    },
    {
      label: 'Web',
      value: '0',
    }, {
      label: 'Qrcode',
      value: '1',
    }
  ];

  const PayGroup = [
    {
      label: '网银支付',
      value: '100',
    }, {
      label: '银联快捷',
      value: '110',
    }, {
      label: '云闪付',
      value: '120',
    }, {
      label: '银行卡汇款',
      value: '130',
    }, {
      label: '支付宝',
      value: '200',
    }, {
      label: '微信',
      value: '210',
    }, {
      label: 'QQ支付',
      value: '220'
    }, {
      label: '虚拟币',
      value: '300',
    }, {
      label: '其他支付',
      value: '0'
    }
  ];

  const tableHeader = [{
    label: t('RECOGNISE_CODE'),
  }, {
    label: t('PAYMENT_NAME'),
  }, {
    label: t('NAME_REMARK'),
  }, {
    label: t('PAYMENT_TYPE'),
  }, {
    label: t('PAYMENT_CATEGORY'),
  }, {
    label: t('MAX_SINGLE_PAYMENT'),
  }, {
    label: t('MIN_SINGLE_PAYMENT'),
  }, {
    label: t('DAILY_QUOTA'),
  }, {
    label: t('QUOTA_SPEND_TODAY'),
  }, {
    label: t('PLATFORM_BELONGS'),
  }, {
    label: t('APPLY_STATUS'),
  }, {
    label: t('RELOAD'),
  }, {
    label: t('PAYOUT'),
  }, {
    label: t('TEST'),
  }, {
    label: t('MANUAL_CONFIRM'),
  }, {
    label: t('MANUAL_AUDIT'),
  }, {
    label: t('ORDER_BY'),
  }, {
    label: t('FUNCTION'),
  }];

  const tableContent = [
    data => (<Label className="d-flex justify-content-center">{data?.code}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.payName}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.payRemark}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.payType}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.payGroup}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.maxAmount}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.minAmount}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.dailyMaxAmount}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.todayUsage}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.platformName}</Label>),
    data => (data?.isUse === 1 ? <Label onClick={() => setStatus(data?.id, 0)} className="negativeValue urlNoUnderline">{t('DISABLED')}</Label> : <Label onClick={() => setStatus(data?.id, 0)} className="urlNoUnderline installment">{t('ACTIVE')}</Label>),
    data => (data?.isDeposit === 1 ? <Label onClick={() => setStatus(data?.id, 1)} className="negativeValue urlNoUnderline">{t('DISABLED')}</Label> : <Label onClick={() => setStatus(data?.id, 1)} className="urlNoUnderline installment">{t('ACTIVE')}</Label>),
    data => (data?.isWithdraw === 1 ? <Label onClick={() => setStatus(data?.id, 2)} className="negativeValue urlNoUnderline">{t('DISABLED')}</Label> : <Label onClick={() => setStatus(data?.id, 2)} className="urlNoUnderline installment">{t('ACTIVE')}</Label>),
    data => (data?.isTest === 1 ? <Label onClick={() => setStatus(data?.id, 3)} className="negativeValue urlNoUnderline">{t('DISABLED')}</Label> : <Label onClick={() => setStatus(data?.id, 3)} className="urlNoUnderline installment">{t('ACTIVE')}</Label>),
    data => (data?.isManualConfirm === 1 ? <Label onClick={() => setStatus(data?.id, 4)} className="negativeValue urlNoUnderline">{t('DISABLED')}</Label> : <Label onClick={() => setStatus(data?.id, 4)} className="urlNoUnderline installment">{t('ACTIVE')}</Label>),
    data => (data?.isManualCheck === 1 ? <Label onClick={() => setStatus(data?.id, 5)} className="negativeValue urlNoUnderline">{t('DISABLED')}</Label> : <Label onClick={() => setStatus(data?.id, 5)} className="urlNoUnderline installment">{t('ACTIVE')}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.sort}</Label>),
    data => (
      <>
        <Button className="button green mx-1" onClick={() => callback('thirdPartyPayEdit', data, true)}>{t('COMPILE')}</Button>
        <Button className="button handle mx-1" onClick={() => CopyClick(data?.id)}>{t('COPY')}</Button>
        <Button className="button handle mx-1" onClick={() => updateSorting(data?.id, 0)}>{t('MOVE_UP')}</Button>
        <Button className="button handle mx-1" onClick={() => updateSorting(data?.id, 1)}>{t('MOVE_DOWN')}</Button>
        <Button className="button find mx-1" onClick={() => setStatus(data?.id, 7, 1)}>{t('DELETE')}</Button>
      </>
    ),
  ];

  return (
    <div className="ThirdPartyPayWrapper">
      <div className="mx-0 bg-white my-2  border-1">
        <Row className="p-0 m-0 mb-2">
          <Col className="p-1 mb-2 filterWrapper d-flex align-items-center w-100">
            <Col className="d-flex align-items-center">
              <Label value={t('PAYMENT_TYPE')} className="px-2 marginLeft5">{t('PAYMENT_TYPE')}</Label>
              <Select
                value={_payType}
                placeholder="请选择"
                onChange={val => setPayType(val)}
              >
                {
                              PayType.map(d => (
                                <Select.Option key={`setReportUnit_${d.value}`} label={d.label} value={d.value} />
                              ))
                        }
              </Select>
              <Label value={t('PAYMENT_CATEGORY')} className="px-2 marginLeft5">{t('PAYMENT_CATEGORY')}</Label>
              <Select
                value={_payGroup}
                placeholder="请选择"
                onChange={val => setPayGroup(val)}
              >
                {
                              PayGroup.map(d => (
                                <Select.Option key={`setReportUnit_${d.value}`} label={d.label} value={d.value} />
                              ))
                        }
              </Select>
              <Label value={t('APPLY_STATUS')} className="px-2 marginLeft5">{t('APPLY_STATUS')}</Label>
              <Select
                value={_itemStatus}
                placeholder="请选择"
                onChange={val => setItemStatus(val)}
              >
                {
                              Status.map(d => (
                                <Select.Option key={`setReportUnit_${d.value}`} label={d.label} value={d.value} />
                              ))
                        }
              </Select>
              <Label value={t('DELETE')} className="px-2 marginLeft5">{t('DELETE')}</Label>
              <Select
                value={_isDelStatus}
                placeholder="请选择"
                onChange={val => setIsDelStatus(val)}
              >
                {
                              IsDelStatus.map(d => (
                                <Select.Option key={`setReportUnit_${d.value}`} label={d.label} value={d.value} />
                              ))
                        }
              </Select>
              <Label value={t('ORDER_BY')} className="px-2 marginLeft5">{t('ORDER_BY')}</Label>
              <Select
                value={_sorting}
                placeholder="请选择"
                onChange={val => setSorting(val)}
              >
                {
                              Sorting.map(d => (
                                <Select.Option key={`setReportUnit_${d.value}`} label={d.label} value={d.value} />
                              ))
                        }
              </Select>
              <Button className="button find mx-3" onClick={() => { setIsQueryingDetail(true); findClick(); }}>{t('FIND')}</Button>
            </Col>
          </Col>
        </Row>
        <Row className="bigWrapper m-0 my-1 p-3">
          <AgentDataTable
            isQuerying={_isQueryingDetail}
            tableHeaderTitle={tableHeader}
            tableData={_info || (isQueryStatus ? [1, 2, 3] : [])}
            tableBodyRowProperty={tableContent}
          />
        </Row>
        <Row className="justify-center w-full relative">
          <Pagination totalRecord={_totalRecord || 0} onClick={onPaginationClick} isQuery={_isQueryingDetail} customizePage={_customPage} />
        </Row>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ThirdPartyPay);