import { useState } from 'react';

import { Button, Col, Row } from 'reactstrap';
import { Input, Radio } from 'antd';
import { IoClose } from 'react-icons/io5';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { appActions, systemActions } from '../../../redux/actions';
import './styles.scss';

const mapStateToProps = state => ({
  platform: state.appReducers.platform,
});

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  CreateController: (obj, callback) => dispatch(systemActions.CreateController(obj, callback)),
});

const CreatePageSettingModal = ({ updateModalAlert, data, CreateController }) => {
  const { t } = useTranslation();

  const [newPageData, setNewPageData] = useState({
    controller: '',
    actionName: '',
    actionType: '',
    url: '',
    status: 1
  });

  const onValueChange = (type, value) => {
    const tempData = {
      ...newPageData
    };
    tempData[type] = value;
    setNewPageData({
      ...tempData
    });
  };

  const onClickToCreate = () => {
    CreateController(newPageData, () => {
      data.onConfirmationClick();
    });
  };

  return (
    <div className="createPageSettingModalWrapperWeb">
      <Row className="modalHeader">
        <Col
          className={`${'d-flex align-items-center'}`}
        >
          添加页面
        </Col>
        <Col className="d-flex justify-content-end align-items-center">
          <IoClose
            onClick={() => {
              updateModalAlert({
                visible: false
              });
              if (data && data.overBet && data.onConfirmationClick) data.onConfirmationClick();
            }}
            className="h4 mb-0 float-right closeIcon"
          />
        </Col>
      </Row>
      <Row className="modalContent">
        <div>
          <div>Controller</div>
          <div>
            <Input value={newPageData?.controller} onChange={({ target: { value: text } }) => onValueChange('controller', text.trim())} />
          </div>
        </div>
        <div>
          <div>Actionname</div>
          <div>
            <Input value={newPageData?.actionName} onChange={({ target: { value: text } }) => onValueChange('actionName', text.trim())} />
          </div>
        </div>
        <div>
          <div>Url路径</div>
          <div>
            <Input value={newPageData?.url} onChange={({ target: { value: text } }) => onValueChange('url', text.trim())} />
          </div>
        </div>
        <div>
          <div>Actiontype</div>
          <div>
            <Input value={newPageData?.actionType} onChange={({ target: { value: text } }) => onValueChange('actionType', text.trim())} />
          </div>
        </div>
        <div>
          <div>状态</div>
          <div>
            <Radio.Group onChange={e => onValueChange('status', e.target.value)} value={newPageData?.status}>
              <Radio value={1}>启用</Radio>
              <Radio value={0}>禁用</Radio>
            </Radio.Group>
          </div>
        </div>

      </Row>
      <Row className="modalFooter d-flex align-items-center">
        <Button
          className={`confirmButton my-3 ${'me-3'}`}
          onClick={() => {
            updateModalAlert({
              visible: false
            });
            onClickToCreate();
          }}
        >
          {(data && data.confirmText) || t('CONFIRM')}
        </Button>
        {
          data && data.showCancelButton !== false && (
            <Button
              className={`cancelButton my-3 ${'me-3'}`}
              onClick={() => {
                updateModalAlert({
                  visible: false
                });
                if (data && data.onCancelClick) data.onCancelClick();
              }}
            >
              {(data && data.cancelText) || t('CANCEL')}
            </Button>
          )
        }
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CreatePageSettingModal);