import { useEffect, useState } from 'react';

import { DatePicker } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { CommonHeader, LotteryMenu, Pagination } from '../../components';
import GameVariable from '../../constants/GameVariable';
import ResultTable from '../../components/Result/ResultTable';
import { lotteryResultActions } from '../../redux/actions';
import './styles.scss';

const mapStateToProps = state => ({
  resultList: state.lotteryResultReducers.resultList,
  activeLottery: state.commonReducers.activeLottery
});

const mapDispatchToProps = dispatch => ({
  getResultList: (obj, callback) => dispatch(lotteryResultActions.getResult(obj, callback)),
});

const Result = ({ lotteryType, getResultList, resultList, activeLottery }) => {
  const { t } = useTranslation();
  const [lotteryTime, setlotteryTime] = useState(moment().toDate());
  const [_isQuerying, setIsQuerying] = useState(false);
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(15);
  const [_showMenu, setShowMenu] = useState(false);
  const [_selectedLottery, setLottery] = useState(activeLottery);
  const [currentTab, setcurrentTab] = useState();

  useEffect(() => {
    if (_selectedLottery) {
      setIsQuerying(true);
      const obj = {
        lotteryType: _selectedLottery.code, lotteryTime: moment(lotteryTime).format('YYYY-MM-DD'), page, rows
      };
      getResultList(obj, () => setIsQuerying(false));
    }
  }, [_selectedLottery, lotteryTime]);

  useEffect(() => {
    if (GameVariable[`CJ_${_selectedLottery.templateCode}`]) {
      const { headerCell, bodyCell } = GameVariable[`CJ_${_selectedLottery.templateCode}`].resultTab;
      let tempHeaderCell = headerCell.concat([]);
      let tempBodyCell = bodyCell.concat([]);
      if (resultList) {
        if (resultList?.isRndNum) {
          tempHeaderCell = headerCell.slice(0, 3).concat({
            attr: '挂码'
          }, headerCell.slice(4));

          tempBodyCell = bodyCell.slice(0, 3).concat({
            attr: 'rndNum',
            wrapperStyle: 'd-flex justify-content-center align-items-center'
          }, bodyCell.slice(4));
        }
      }
      setcurrentTab({
        headerCell: tempHeaderCell, bodyCell: tempBodyCell
      });
    }
  }, [resultList, _selectedLottery]);


  const onPaginationClick = (pageNum, rows) => {
    setIsQuerying(true);
    getResultList({
      lotteryType: _selectedLottery.code, lotteryTime: moment(lotteryTime).format('YYYY-MM-DD'), page: pageNum, rows
    }, () => setIsQuerying(false));
    setPage(pageNum);
    setRows(rows);
  };

  return (
    <div className="resultWrapper">
      <CommonHeader headerName="开奖结果" />
      <div className="menuRow mb-2">
        <div className="currentSelected" onFocus={() => {}} onMouseOver={() => setShowMenu(true)} onMouseLeave={() => setShowMenu(false)}>
          {_selectedLottery && (_selectedLottery.lotteryName)}
          <span className="arrow_down" />
          {_showMenu && (
          <div className="bogusBetWarningMenuWrapper" onFocus={() => {}} onMouseOver={() => setShowMenu(true)}>
            <LotteryMenu onClickItem={item => { setLottery(item); setShowMenu(false); }} selectedItem={_selectedLottery.code} />
          </div>
          )}
        </div>
        <div className="mx-3">{t('DATE_RANGE')}</div>
        <DatePicker
          value={moment(lotteryTime)}
          placeholder={t('PLEASE_SELECT_DATE')}
          onChange={date => {
            setlotteryTime(date.toDate());
          }}
          allowClear={false}
        />
      </div>
      <ResultTable key={lotteryType} _isQuerying={_isQuerying} rowData={resultList ? resultList.data : []} {...currentTab} />
      <div className="justify-center w-full relative">
        <Pagination totalRecord={(resultList && resultList?.totalRecords)} onClick={(pageNum, rows) => onPaginationClick(pageNum, rows)} isQuery={false} />
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Result);