import { dragonTiger, fortune } from '../Items/others';
import { dxds, hdxds, totalSumDS, totalSumDX, totaltailDX, wdx } from '../Items/dsdx';
import { GXKL10Number1to21 } from '../Items/numbers';
import { pureThreeColors } from '../Items/colors';

const CJ_GXK10 = [{
  displayName: '两面',
  isStaticRow: 1,
  repeatPerRow: 6,
  combineStyle: 2,
  repeatPerCol: 1,
  midcode: 'ZH,LH,B1LM,B2LM,B3LM,B4LM,B5LM',
  display: [
    [...totalSumDX, ...totalSumDS, ...totaltailDX, ...dragonTiger],
    [...dxds, ...wdx, ...hdxds.slice(0, 2)],
    [...dxds, ...wdx, ...hdxds.slice(0, 2)],
    [...dxds, ...wdx, ...hdxds.slice(0, 2)],
    [...dxds, ...wdx, ...hdxds.slice(0, 2)],
    [...dxds, ...wdx, ...hdxds.slice(0, 2)]
  ],
  prefix: ['', 'B1', 'B2', 'B3', 'B4', 'B5'],
  firstTableHeader: ['总和', '第一球', '第二球', '第三球', '第四球', '第五球'],
  displayRows: [2, 8, 8, 8, 8, 8],
  tableStyle: ['flex-fill w-100'],
  tabId: 'count_LM'
}, {
  displayName: '单号1-5',
  isStaticRow: 1,
  settingCode: 'QH15,FLSX15,SB15',
  prefix: ['B1', 'B2', 'B3', 'B4', 'B5', ''],
  firstTableHeader: ['第一球', '第二球', '第三球', '第四球', '第五球', '总和'],
  display: [[...GXKL10Number1to21.slice(0, 21), ...pureThreeColors, ...fortune],
    [...GXKL10Number1to21.slice(0, 21), ...pureThreeColors, ...fortune],
    [...GXKL10Number1to21.slice(0, 21), ...pureThreeColors, ...fortune],
    [...GXKL10Number1to21.slice(0, 21), ...pureThreeColors, ...fortune],
    [...GXKL10Number1to21.slice(0, 21), ...pureThreeColors, ...fortune]],
  tabId: 'count_HM'
}
];

export default CJ_GXK10;