import { useEffect, useState } from 'react';

import { Button, Col, Input, Label, Row } from 'reactstrap';
import { Select } from 'antd';
import { connect } from 'react-redux';

import { useTranslation } from 'react-i18next';

import oddsTypeMenu from '../../../constants/OddsManageSetting';

import { appActions, defaultOddsActions } from '../../../redux/actions';

import QuickSetTable from '../QuickSetTable';

import './styles.scss';

import OddsSettingMenuRender from '../../Content/OddsSettingMenuRender';
import OddsSettingTableRender from '../../Content/OddsSettingTableRender';

import CommonHeader from '../../Content/CommonHeader';


const mapStateToProps = state => ({
  activeLottery: state.commonReducers.activeLottery,
  defaultOddsData: state.defaultOddsReducers.defaultOddsData,
  userInfo: state.memberReducers.userInfo,
});

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  SaveOdds: (obj, callback) => dispatch(defaultOddsActions.SaveOdds(obj, callback)),
  getInfo: (lotteryCode, callback) => dispatch(defaultOddsActions.getInfo(lotteryCode, callback)),
  QuickSaveOdds: (obj, callback) => dispatch(defaultOddsActions.QuickSaveOdds(obj, callback)),
});

const zmtoptions = [{
  value: '-1',
  label: '全部'
}, {
  value: '正码特',
  label: '正码特A'
}, {
  value: '正码特B',
  label: '正码特B'
}];

const DefaultOddsSetting = ({
  defaultOddsData,
  activeLottery,
  updateModalAlert,
  SaveOdds,
  getInfo,
  QuickSaveOdds,
  userInfo
}) => {
  const { t } = useTranslation();
  const [_info, setInfo] = useState([]);
  const [_menu, setMenu] = useState([]);
  const [_selectedLottery, setSelectedLottery] = useState('');
  const [_selectedMainMenu, setSelectedMainMenu] = useState(0);
  const [_selectedSubMenu, setSelectedSubMenu] = useState(0);
  const [_midCode, setmidCode] = useState('');
  const [_isQueryingDetail, setIsQueryingDetail] = useState([]);
  const [_text, setText] = useState('');
  const [midSubCategory, setMidSubCategory] = useState('-1');

  useEffect(() => {
    if (defaultOddsData && JSON.stringify(defaultOddsData) !== JSON.stringify(_info)) {
      setInfo([...defaultOddsData]);
      setIsQueryingDetail(false);
      const data = defaultOddsData;
      if (_menu[_selectedMainMenu]?.isStaticRow === 1) {
        if (_menu[_selectedMainMenu]?.display) {
          let content = [];
          for (let i = 0; i < _menu[_selectedMainMenu]?.display?.length; i++) {
            const combined = _menu[_selectedMainMenu]?.display[i]
              .filter(y => (y?.tabId ? y?.tabId === _menu[_selectedMainMenu]?.children[_selectedSubMenu]?.tabId : true))
              .map(item => {
                const index = data.findIndex(x => (x?.displayName ? x?.displayName === item?.displayName : true)
                && (_menu[_selectedMainMenu]?.prefix ? x?.keyCode?.includes(_menu[_selectedMainMenu]?.prefix[i]) : true)
                && (item?.keyCode ? x?.keyCode?.includes(item?.keyCode) : true));
                if (index !== -1) {
                  if (!data[index]?.displayName) {
                    data[index].displayName = item.displayName;
                  }
                  data[index].className = item.className;
                  data[index].rowSpan = item?.rowSpan;
                  data[index].img = item?.img;
                  data[index].minOdds = '';
                  data[index].refEnabled = data[index]?.enabled;
                  return data[index];
                }
                return item;
              });
            content = [...content, ...combined.map(obj => ({
              ...obj, mark: i.toString()
            }))];
          }
          setInfo(content);
        }
      } else if (_menu[_selectedMainMenu]?.display) {
        const combined = _menu[_selectedMainMenu]?.display.map(item => {
          const i = data.findIndex(x => (x?.displayName ? x?.displayName === item?.displayName : true)
            && (item?.keyCode ? x?.keyCode?.includes(item?.keyCode) : true));
          if (i !== -1) {
            data[i].className = item.className;
            if (!data[i]?.displayName) {
              data[i].displayName = item.displayName;
              data[i].minOdds = '';
              data[i].refEnabled = data[i]?.enabled;
            }
            return data[i];
          }
          return item;
        });
        setInfo(combined);
      } else {
        setInfo(data);
      }
      setIsQueryingDetail(false);
    } else if (!defaultOddsData) {
      setInfo(_menu[_selectedMainMenu]?.display);
    } else if (defaultOddsData) {
      setInfo(_menu[_selectedMainMenu]?.display);
      setIsQueryingDetail(false);
    }
  }, [defaultOddsData]);

  useEffect(() => {
    if (_midCode) {
      onRefreshClick();
    }
  }, [_midCode, activeLottery, midSubCategory]);

  useEffect(() => {
    setIsQueryingDetail(true);
    if (activeLottery && activeLottery?.templateCode !== _selectedLottery) {
      setSelectedMainMenu(0);
      setSelectedLottery(activeLottery?.templateCode);
      switch (activeLottery?.templateCode) {
        case 'HK6': {
          setMenu(oddsTypeMenu?.HK6);
          refresh({
            midCode: oddsTypeMenu?.HK6[0]?.midcode || oddsTypeMenu?.HK6[0]?.children[0]?.midcode,
            settingCode: oddsTypeMenu?.HK6[0]?.settingCode || oddsTypeMenu?.HK6[0]?.children[0]?.settingCode,
            isLianma: oddsTypeMenu?.HK6[0]?.isLianMa,
            midtype: oddsTypeMenu?.HK6[0]?.children[0]?.midtype
          });
          break;
        }
        case 'PK10': {
          setMenu(oddsTypeMenu?.PK10);
          setmidCode({
            midCode: oddsTypeMenu?.PK10[0]?.midcode || oddsTypeMenu?.PK10[0]?.children[0]?.midcode,
          });
          break;
        }
        case '11X5': {
          setMenu(oddsTypeMenu?.type11X5);
          setmidCode({
            settingCode: oddsTypeMenu?.type11X5[0]?.settingCode || '',
            midCode: oddsTypeMenu?.type11X5[0]?.midcode || '',
          });
          break;
        }
        case 'F31X7': {
          setMenu(oddsTypeMenu?.F31X7);
          setmidCode({
            settingCode: oddsTypeMenu?.F31X7[0]?.settingCode || '',
            midCode: oddsTypeMenu?.F31X7[0]?.midcode || '',
          });
          break;
        }
        case 'F36X7': {
          setMenu(oddsTypeMenu?.F36X7);
          setmidCode({
            settingCode: oddsTypeMenu?.F36X7[0]?.settingCode || '',
            midCode: oddsTypeMenu?.F36X7[0]?.midcode || '',
          });
          break;
        }
        case 'BJK8': {
          setMenu(oddsTypeMenu?.BJK8);
          setmidCode({
            settingCode: oddsTypeMenu?.BJK8[0]?.settingCode || '',
            midCode: oddsTypeMenu?.BJK8[0]?.midcode || '',
          });
          break;
        }
        case 'K3': {
          setMenu(oddsTypeMenu?.K3);
          setmidCode({
            settingCode: oddsTypeMenu?.K3[0]?.settingCode || '',
            midCode: oddsTypeMenu?.K3[0]?.midcode || '',
          });
          break;
        }
        case 'KL10': {
          setMenu(oddsTypeMenu?.KL10);
          setmidCode({
            settingCode: oddsTypeMenu?.KL10[0]?.settingCode || '',
            midCode: oddsTypeMenu?.KL10[0]?.midcode || '',
          });
          break;
        }
        case 'GXK10': {
          setMenu(oddsTypeMenu?.GXK10);
          setmidCode({
            settingCode: oddsTypeMenu?.GXK10[0]?.settingCode || '',
            midCode: oddsTypeMenu?.GXK10[0]?.midcode || '',
          });
          break;
        }
        case 'SSC': {
          setMenu(oddsTypeMenu?.SSC);
          setmidCode({
            settingCode: oddsTypeMenu?.SSC[0]?.settingCode || '',
            midCode: oddsTypeMenu?.SSC[0]?.midcode || '',
          });
          break;
        }
        case 'PCDD': {
          setMenu(oddsTypeMenu?.PCDD);
          setmidCode({
            settingCode: oddsTypeMenu?.PCDD[0]?.settingCode || '',
            midCode: oddsTypeMenu?.PCDD[0]?.midcode || '',
          });
          break;
        }
        case 'FANT': {
          setMenu(oddsTypeMenu?.FANT);
          setmidCode({
            settingCode: oddsTypeMenu?.FANT[0]?.settingCode || '',
            midCode: oddsTypeMenu?.FANT[0]?.midcode || '',
          });
          break;
        }
        default: break;
      }
    }
  }, [activeLottery]);


  const onValueChange = (id, field, value) => {
    const index = Number(_info.findIndex(item => item.keyCode === id));
    const newItems = [..._info];
    if (index !== -1) {
      newItems[index][field] = value;
      newItems[index].isChange = true;
      newItems[index].actionStatus = '300';
      if ((field === 'minOdds' && value === '' && newItems[index]?.refEnabled === newItems[index]?.enabled)
      || (field === 'enabled' && value === newItems[index]?.refEnabled && newItems[index]?.minOdds === '')) {
        newItems[index].isChange = false;
        newItems[index].actionStatus = '';
      }
      setInfo(newItems);
    }
  };
  const refresh = midCode => {
    setmidCode(midCode);
    if (midCode) {
      if (midCode?.midCode) {
        getInfo({
          lotteryType: activeLottery?.code,
          midCode: midCode?.midCode || 'TMA,TMDSDX,TMSB',
          isLianma: midCode?.isLianma || 0,
          midtype: midCode?.midtype,
        });
      } else {
        getInfo({
          lotteryType: activeLottery?.code,
          settingCode: midCode?.settingCode || '',
          isLianma: midCode?.isLianma || 0
        });
      }
    }
  };

  const onRefreshClick = () => {
    reset();
    if (_midCode?.midCode) {
      let tempmidSubCategory = _midCode?.midtype ?? '';
      if (activeLottery?.templateCode === 'HK6' && (_midCode?.midCode ?? '').includes('ZMT') && userInfo?.supportZmt) {
        tempmidSubCategory = midSubCategory;
      }
      getInfo({
        lotteryType: activeLottery?.code,
        midCode: _midCode?.midCode || 'TMA,TMDSDX,TMSB',
        isLianma: _midCode?.isLianma || 0,
        midtype: tempmidSubCategory,
      });
    } else {
      getInfo({
        lotteryType: activeLottery?.code,
        settingCode: _midCode?.settingCode || '',
        isLianma: _midCode?.isLianma || 0
      });
    }
  };

  const changeOdds = (type, oddsObj) => {
    if (type) {
      updateModalAlert({
        type: 'loading',
        visible: true
      });
      if ((type === 'minus' || type === 'add') && !_text) {
        updateModalAlert({
          type: 'confirmation',
          visible: true,
          data: {
            message: '请选择/输入增值',
            showCancelButton: false
          }
        });
      } else if (type === 'add') {
        const temp = {
          lotteryType: activeLottery.code,
          oddsList: [{
            ...oddsObj, odds: Number.parseFloat((oddsObj.odds + Number(_text || 0)).toFixed(10))
          }]
        };
        SaveOdds(temp, () => tempUpdate(oddsObj, Number.parseFloat((oddsObj.odds + Number(_text || 0)).toFixed(10))));
      } else if (type === 'minus') {
        const temp = {
          lotteryType: activeLottery.code,
          oddsList: [{
            ...oddsObj, odds: Number.parseFloat((oddsObj.odds - Number(_text || 0)).toFixed(10))
          }]
        };
        SaveOdds(temp, () => tempUpdate(oddsObj, Number.parseFloat((oddsObj.odds - Number(_text || 0)).toFixed(10))));
      } else if (type === 'saveButton') {
        const res = _info.filter(item => item?.isChange === true).map(obj => ({
          ...obj, odds: obj?.minOdds || obj?.odds
        }));
        if (res?.length > 0) {
          const temp = {
            lotteryType: activeLottery.code,
            oddsList: [...res]
          };
          SaveOdds(temp, saveButtonAfter);
        } else {
          updateModalAlert({
            type: 'confirmation',
            visible: true,
            data: {
              message: '无变动',
              showCancelButton: false
            }
          });
        }
      } else {
        updateModalAlert({
          type: 'loading',
          visible: false
        });
      }
    }
  };

  const saveButtonAfter = () => {
    onRefreshClick();
    updateModalAlert({
      type: 'success',
      visible: true,
      data: {
        message: '更新成功',
        showCancelButton: false
      }
    });
  };

  const checkAll = val => {
    const res = _info.map(obj => ({
      ...obj, enabled: val, isChange: (val !== obj?.refEnabled), actionStatus: val !== obj?.refEnabled ? '300' : ''
    }));
    setInfo(res);
  };

  const reset = () => {
    const res = (_info ?? []).map(obj => ({
      ...obj, minOdds: '', isChange: false, actionStatus: ''
    }));
    setInfo(res);
  };
  const qsOdds = (type, var3, isMulti) => {
    if (type) {
      updateModalAlert({
        type: 'loading',
        visible: true
      });
      let keyCodes = '';
      if (isMulti) {
        for (let i = 0; i < var3?.length; i++) {
          keyCodes += _info.filter(item => (var3[i]?.values ? var3[i]?.values.find(x => x === item?.displayName) : true)
        && (var3[i]?.keyCode ? item?.keyCode?.includes(var3[i]?.keyCode) : true)
        && (var3[i]?.className ? item?.className?.includes(var3[i]?.className) : true)).map(u => u.keyCode).join(',');
        }
      } else {
        keyCodes = _info.map(u => u.keyCode).join(',');
      }

      if (_text) {
        if (type === 'add') {
          const temp = {
            lotteryType: activeLottery.code,
            downValue: Number(_text),
            keyCodes
          };
          QuickSaveOdds(temp, onRefreshClick);
        } else {
          const temp = {
            lotteryType: activeLottery.code,
            downValue: -Number(_text),
            keyCodes
          };
          QuickSaveOdds(temp, onRefreshClick);
        }
      } else {
        updateModalAlert({
          type: 'confirmation',
          visible: true,
          data: {
            message: '请选择增值',
            showCancelButton: false
          }
        });
      }
    }
  };

  const tempUpdate = (obj, val) => {
    const index = _info.findIndex(x => x?.keyCode === obj?.keyCode);
    if (index !== -1) {
      const res = [..._info];
      res[index].odds = Math.min(val);
      res[index].isChange = false;
      setInfo(res);
    }
  };

  const clickCall = (inputVar, type, var3, isMulti) => {
    const value = Math.abs(Number(inputVar));
    if (var3 === 'shuangMian') {
      const shuangMianCode = ['LM', 'TMDSDX'];
      const combined = _info.map(item => {
        if (shuangMianCode.includes(item?.settingCode) || shuangMianCode.includes(item?.midCode)) {
          item.minOdds = calculateOdds(type, item.odds, value);
          item.isChange = true;
          item.actionStatus = '300';
        }
        return item;
      });
      setInfo(combined);
    }
    if (inputVar && value && var3 !== 'shuangMian') {
      if (!var3 || var3?.length <= 0) {
        return updateModalAlert({
          type: 'confirmation',
          visible: true,
          data: {
            message: '请选择或设置要修改的项目！',
            showCancelButton: false
          }
        });
      }
      const qiuHaoArray = Array(49).fill('').map((value, index) => (index < 9 ? `0${index + 1}` : `${index + 1}`));
      const combined = _info.map(item => {
        if (isMulti) {
          if (var3?.length > 0) {
            for (let i = 0; i < var3?.length; i++) {
              if ((var3[i]?.values ? var3[i]?.values.find(x => x === item?.displayName) : true)
                && (var3[i]?.keyCode ? item?.keyCode?.includes(var3[i]?.keyCode) : true)
                && (var3[i]?.className ? item?.className?.includes(var3[i]?.className) : true)) {
                item.minOdds = calculateOdds(type, item.odds, value);
                item.isChange = true;
                item.actionStatus = '300';
              }
            }
          }
        } else if (var3?.find(x => x === item?.displayName)) {
          item.minOdds = calculateOdds(type, item.odds, value);
          item.isChange = true;
          item.actionStatus = '300';
        } else if (var3?.find(x => x === 'ALL') && qiuHaoArray.includes(item?.displayName)) {
          item.minOdds = calculateOdds(type, item.odds, value);
          item.isChange = true;
          item.actionStatus = '300';
        }
        return item;
      });
      setInfo(combined);
    }
  };

  const calculateOdds = (type, odds, value) => {
    switch (type) {
      case '0': return value;
      case '1': return odds - value < 0 ? 0 : Number((odds - value).toFixed(4));
      case '2': return Number((odds + value).toFixed(4));
      default: break;
    }
  };

  const renderCheckboxes = () => (
    <>
      { userInfo?.supportZmt && activeLottery?.templateCode === 'HK6' && (_midCode?.midCode ?? '').includes('ZMT')
        ? (
          <Select
            className="zmtModeSelect ms-1"
            value={midSubCategory}
            placeholder="请选择"
            onChange={val => setMidSubCategory(val)}
          >
            {
              zmtoptions.map(el => <Select.Option key={el.value} value={el.value}>{el.label}</Select.Option>)
            }
          </Select>
        )
        : ''}
      <Input
        id="main_selectAll"
        type="checkbox"
        className="mx-2 p-1 align-items-center hide"
        onClick={() => checkAll(0)}
      />
      <Label for="main_selectAll" className="refreshButton select mx-1">
        {t('ALL_MORATORIUM')}
      </Label>
      <Input
        id="main_deselectAll"
        type="checkbox"
        className="mx-2 p-1 align-items-center hide"
        onClick={() => checkAll(1)}
      />
      <Label for="main_deselectAll" className="refreshButton deselect mx-1">
        {t('ALL_OPEN')}
      </Label>
    </>
  );

  return (
    <Col className="DefaultOddsSettingWarpper">
      <CommonHeader
        headerName={t('DEFAULT_ODDS')}
        rightLocationItem={renderCheckboxes}
      />
      {
        // hide menu if needed
        _menu[_selectedMainMenu]?.hideMenu !== 1 && (
        <OddsSettingMenuRender
          menu={_menu}
          _selectedMainMenu={_selectedMainMenu}
          _selectedSubMenu={_selectedSubMenu}
          setSelectedSubMenu={setSelectedSubMenu}
          setInfo={setInfo}
          setmidCode={setmidCode}
          setSelectedMainMenu={setSelectedMainMenu}
          setIsQueryingDetail={setIsQueryingDetail}
        />
        )
      }

      <Col className="content mt-1 p-0">
        {
          // Dynamic rows display
          _menu && (
          <OddsSettingTableRender
            menu={_menu}
            _selectedMainMenu={_selectedMainMenu}
            _isQueryingDetail={_isQueryingDetail}
            _info={_info}
            _selectedSubMenu={_selectedSubMenu}
            _isAvailable
            changeOdds={changeOdds}
            onValueChange={onValueChange}
          />
          )
        }
      </Col>
      <Col className="content m-0 p-0 border-top">
        <Row>
          <Col className="d-flex justify-content-center py-2">
            <Button className="button save mx-2" onClick={() => changeOdds('saveButton')}>{t('SAVE')}</Button>
            <Button className="button cancel mx-2" onClick={() => onRefreshClick()}>{t('CANCEL')}</Button>
          </Col>
        </Row>
      </Col>
      <Row className="m-0 p-0 mb-5">
        <QuickSetTable
          data={_menu[_selectedMainMenu]?.quickSet || []}
          updateText={text => setText(text)}
          onClick={(myvar, changeType, itemVar, isMulti) => clickCall(myvar, changeType, itemVar, isMulti)}
          qsOdds={qsOdds}
          _midCode={_midCode}
          isAvailable
        />
      </Row>
    </Col>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DefaultOddsSetting);