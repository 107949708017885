import { useEffect, useState } from 'react';

import { Button, Col, Input, Label, Row } from 'reactstrap';
import { DatePicker, Popover, Select } from 'antd';
import { FaCircle, FaEdit, FaPlusCircle } from 'react-icons/fa';
import { connect } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { AccountStatus, OrderBySelection, OrderSelection, UserSearchBy } from '../../../constants/SelectionItems';
import AgentDataTable from '../../AgentDataTable';
import Notifications from '../../Content/Notifications';
import Pagination from '../../Content/Pagination';

import { agentActions, appActions, memberActions } from '../../../redux/actions';

import './styles.scss';

const mapStateToProps = state => ({
  activeLottery: state.commonReducers.activeLottery,
  memberListData: state.memberReducers.memberListData,
  userData: state.memberReducers.userData,
  userInfo: state.memberReducers.userInfo,
});

const mapDispatchToProps = dispatch => ({
  GetMemberList: (agentId, levelValue, orderBy, order, page, rows, startDate, endDate, isChild, loginId, status, searchBy, minAmount, maxAmount, isTest) => dispatch(memberActions.GetMemberList(agentId, levelValue, orderBy, order, page, rows, startDate, endDate, isChild, loginId, status, searchBy, minAmount, maxAmount, isTest)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  setAccountStatus: (obj, searchObj) => dispatch(agentActions.setAccountStatus(obj, searchObj)),
});

const { RangePicker } = DatePicker;

const MemberList = ({ userInfo, data, memberListData, GetMemberList, userData, updateModalAlert, agentType, userLevel, setAccountStatus, callback = () => {}, breadcrumb }) => {
  const { t } = useTranslation();
  const [_searchText, setSearchText] = useState('');
  const [_date, setDate] = useState([]);
  const [_detailItem, setDetailItem] = useState();
  const [_detailItemSum, setDetailItemSum] = useState();
  const [_selectedStatus, setSelectedStatus] = useState('');
  const [_activeId, setActiveId] = useState();
  const [_isUpdateStatus, setIsUpdateStatus] = useState(false);
  const [_balanceLessThan, setBalanceLessThan] = useState('');
  const [_isTest, setIsTest] = useState('0');
  const [_balanceMoreThan, setBalanceMoreThan] = useState('');
  const [_selectedOrderBy, setSelectedOrderBy] = useState('CreateDate');
  const [_selectedOrderType, setSelectedOrderType] = useState('DESC');
  const [_selectedSearchBy, setSelectedSearchBy] = useState('loginId');
  const [_isQueryingDetail, setIsQueryingDetail] = useState(true);
  const [_header, setHeader] = useState([]);
  const [_content, setContent] = useState([]);
  const lowLevelAgentItems = [0, 1, 2, 3, 4, 5, 6, 8];
  const [_page, setPage] = useState(1);
  const [_rows, setRows] = useState(15);

  useEffect(() => {
    setIsQueryingDetail(true);
    // Default search when first load
    GetMemberList(
      data?.orgId || userData?.id || '',
      userLevel,
      'CreateDate',
      'DESC',
      _page,
      _rows,
      '',
      '',
      agentType,
      ''
    );
  }, []);

  useEffect(() => {
    if (memberListData?.sum) {
      if (_detailItemSum) {
        if (_isUpdateStatus) {
          const temp = _detailItem;
          memberListData?.memList.forEach(curr => {
            const index = _detailItem.findIndex(item => item.memberId === curr.memberId);
            if (index !== -1) {
              temp[index].useStateValue = curr.useStateValue;
            }
            setIsUpdateStatus(false);
          });
          setDetailItem(temp);
        } else {
          setDetailItem(memberListData?.memList);
          setDetailItemSum(memberListData.sum);
        }
        setIsQueryingDetail(false);
      } else if (JSON.stringify(_detailItemSum) !== JSON.stringify(memberListData?.sum)) {
        setDetailItem(memberListData?.memList);
        setDetailItemSum(memberListData.sum);
      }
    }
    // Hide most of the detail for low level agent
    if (Number(userInfo?.levelValue) !== 1) {
      const tempHead = [];
      const tempContent = [];
      lowLevelAgentItems.forEach(d => {
        tempHead.push(tableHeader[d]);
        tempContent.push(tableContent[d]);
      });
      setHeader(tempHead);
      setContent(tempContent);
    } else if (Number(userInfo?.isChild) === 1) {
      setHeader(tableHeader.slice(0, tableHeader.length - 2));
      setContent(tableContent.slice(0, tableContent.length - 2));
    } else {
      setHeader(tableHeader);
      setContent(tableContent);
    }
    if (!memberListData) {
      setIsQueryingDetail(false);
    }
  }, [memberListData]);

  const onSearchClick = () => {
    setIsQueryingDetail(true);
    GetMemberList(
      data?.orgId || userData?.id || '',
      userLevel,
      _selectedOrderBy,
      _selectedOrderType,
      1,
      _rows,
      (_date?.length === 0 ? '' : moment(_date[0])?.unix()),
      (_date?.length === 0 ? '' : moment(_date[1])?.unix()),
      agentType,
      _searchText,
      _selectedStatus,
      _selectedSearchBy || 'loginId',
      _balanceMoreThan,
      _balanceLessThan,
      _isTest
    );
  };

  const onButtonClick = (selectedMemberId, selectedAgentType, selectedUserLevel) => {
    setIsQueryingDetail(true);
    GetMemberList(
      selectedMemberId,
      selectedUserLevel,
      _selectedOrderBy,
      _selectedOrderType,
      1,
      _rows,
      (_date?.length === 0 ? '' : moment(_date[0])?.unix()),
      (_date?.length === 0 ? '' : moment(_date[1])?.unix()),
      selectedAgentType,
      _searchText,
      _selectedStatus,
      _selectedSearchBy || 'loginId',
      _balanceMoreThan,
      _balanceLessThan,
      _isTest
    );
  };

  const onPaginationClick = (pageNum, rows) => {
    setIsQueryingDetail(false);
    // Page number and row number will follow the pagination component instead
    GetMemberList(
      data?.orgId || userData?.id || '',
      userLevel,
      'CreateDate',
      'DESC',
      pageNum,
      rows,
      (_date?.length === 0 ? '' : moment(_date[0]).unix()),
      (_date?.length === 0 ? '' : moment(_date[1]).unix()),
      agentType,
      _searchText,
      _selectedStatus,
      _selectedSearchBy,
      _balanceMoreThan,
      _balanceLessThan,
      _isTest,
    );
    setPage(pageNum);
    setRows(rows);
  };

  const updateStatus = (type, userId, status, userType, loginId) => {
    setActiveId('nil');
    setIsUpdateStatus(true);
    const obj = {
      userId,
      status,
      userType,
      orgId: userId,
    };
    const searchObj = {
      userId: data?.orgId || userData?.id || '',
      userLevel,
      orderBy: _selectedOrderBy,
      orderType: _selectedOrderType,
      page: 1,
      rows: _rows,
      startDate: (_date?.length === 0 ? '' : moment(_date[0]).unix()),
      endDate: (_date?.length === 0 ? '' : moment(_date[1]).unix()),
      agentType,
      loginId
    };
    if (type === 'status') {
      updateModalAlert({
        type: 'loading',
        visible: true
      });
      setAccountStatus(obj, searchObj);
    }
  };

  const tableHeader = [{
    label: t('ONLINE'),
  }, {
    label: t('UPPER_LEVEL_ACCOUNT'),
  }, {
    label: t('OPERATION'),
  }, {
    label: t('USER_TYPE'),
  }, {
    label: t('ACCOUNT'),
  }, {
    label: t('ACCOUNT_BALANCE'),
  }, {
    label: t('PLATE_TYPE'),
  }, {
    label: t('ZC'),
  }, {
    label: t('CREATE_DATE'),
  }, {
    label: t('STATUS'),
  }];

  const tableContent = [
    data => (
      <Label className={`${data.online === 1 ? 'positiveValue' : 'offline'} justify-content-center smallicon`}>
        <FaCircle className="ml-2" />
      </Label>
    ),
    data => (
      <Label
        className="positiveValue urlNoUnderline"
        onClick={() => onButtonClick(data?.upOrgId, 0, 0)}
      >
        {data.upLoginId}
      </Label>
    ),
    data => (
      <Label className="positiveValue urlNoUnderline" onClick={() => setActiveId(data?.memberId)}>
        <FaEdit className="ml-2" />
      </Label>
    ),
    data => (<Label className="d-flex justify-content-center">{`${data.isTest === 0 ? '正式会员' : '试玩会员'} ${data.mLevel}`}</Label>),
    data => (
      <Label
        className="d-flex justify-content-center"
      >
        {`${data.loginName} [${data.userId}]`}
      </Label>
    ),
    data => (<Label className="d-flex justify-content-center">{data.leftCredit}</Label>),
    data => (<Label className="d-flex justify-content-center">{data.game}</Label>),
    data => (
      <Label
        className="positiveValue urlNoUnderline"
        onClick={() => {
          updateModalAlert({
            type: 'sharesDetail',
            visible: true,
            data: {
              userType: 1,
              userId: data?.memberId
            }
          });
        }}
      >
        {t('DETAILS')}
      </Label>
    ),
    data => (
      <Label className="px-1">{moment.unix(data.createDate).format('YYYY-MM-DD hh:mm:ss')}</Label>
    ),
    data => (
      <Label>
        <Popover
          placement="top"
          width="160"
          trigger="click"
          visible={false}
          content={(
            <div className="popButton p-0">
              <Button
                className={`mx-1 ${data.useStateValue === 1 ? 'active' : 'idle'}`}
                onClick={() => { data.useStateValue === 1 ? false : updateStatus('status', data?.memberId, 1, 1, data?.loginId); }}
              >
                {t('ACTIVE')}
              </Button>
              <Button
                className={`mx-1 ${data.useStateValue === 0 ? 'active' : 'idle'}`}
                onClick={() => { data.useStateValue === 0 ? false : updateStatus('status', data?.memberId, 0, 1, data?.loginId); }}
              >
                {t('INACTIVE')}
              </Button>
              <Button
                className={`mx-1 ${data.useStateValue === 2 ? 'active' : 'idle'}`}
                onClick={() => { data.useStateValue === 2 ? false : updateStatus('status', data?.memberId, 2, 1, data?.loginId); }}
              >
                {t('PAUSE')}
              </Button>
            </div>
          )}
        >
          <Button className={`buttonURL ${data.useStateValue === 1 ? 'installment' : 'negativeValue'}`}>{data.useStateValue === 1 ? t('ACTIVE') : (data.useStateValue === 0 ? t('INACTIVE') : t('PAUSE'))}</Button>
        </Popover>
      </Label>
    ),
  ];

  const control = [
    data => (
      <>
        <Button className="controlButton mx-1" value={data?.memberId} onClick={() => callback(0, data, breadcrumb)}>{t('EDIT')}</Button>
        <Button className="controlButton mx-1" value={data?.memberId} onClick={() => callback(1, data, breadcrumb)}>{t('LOTTERY_REBATE')}</Button>
        <Button className="controlButton mx-1" value={data?.memberId} onClick={() => callback(2, data, breadcrumb)}>{t('LOGIN_LOG')}</Button>
        <Button className="controlButton mx-1" value={data?.memberId} onClick={() => callback(3, data, breadcrumb)}>{t('EDIT_LOG')}</Button>
        <Button className="controlButton mx-1" value={data?.memberId} onClick={() => callback(4, data, breadcrumb)}>{t('BET_LIST')}</Button>
        <Button className="controlButton mx-1" value={data?.memberId} onClick={() => callback(5, data, breadcrumb)}>{t('CHECK_TRANSFER_FLOW')}</Button>
        <Button className="controlButton mx-1" value={data?.memberId} onClick={() => callback(6, data, breadcrumb)}>{t('BANK_CARD')}</Button>
        <Button
          className="controlButton mx-1"
          value={data?.memberId}
          onClick={() => {
            updateModalAlert({
              type: 'winLostNotification',
              visible: true,
              data: {
                memberId: data?.memberId
              }
            });
          }}
        >
          {t('RESULT_NOTIFICATION')}
        </Button>
        <Button className="controlButton mx-1" value={data?.memberId} onClick={() => callback(7, data, breadcrumb)}>{t('DIRECT_WX')}</Button>
        <Button
          className="controlButton mx-1"
          value={data?.memberId}
          onClick={() => {
            updateModalAlert({
              type: 'thirdPartyGames',
              visible: true,
              data: {
                memberId: data?.memberId
              }
            });
          }}
        >
          {t('GAME_BALANCE')}
        </Button>
      </>
    ),
  ];

  return (
    <Col className="memberListWrapper p-3">
      <Col className="p-2 mb-2 filterWrapper d-flex align-items-center w-100 rounded-top">
        <Label value={t('CATEGORY')} className="px-2">{t('CATEGORY')}</Label>
        <Select
          popper-class="select-popper"
          value={_isTest}
          onChange={value => (setIsTest(value))}
        >
          <Select.Option label="全部" value="-1" />
          <Select.Option label="正式" value="0" />
          <Select.Option label="试玩" value="1" />
        </Select>
        <Label value={t('STATUS')} className="px-2">{t('STATUS')}</Label>
        <Select
          popper-class="select-popper"
          value={_selectedStatus}
          onChange={value => (setSelectedStatus(value))}
        >
          {
            AccountStatus.map(item => (
              <Select.Option key={`UserStatus_${item.value}`} label={item.label} value={item.value} />
            ))
          }
        </Select>
        <Label className="px-2">{t('ORDER_BY')}</Label>
        <Select
          popper-class="select-popper"
          value={_selectedOrderBy}
          onChange={value => (setSelectedOrderBy(value))}
        >
          {
            OrderBySelection.map(item => (
              <Select.Option key={`OrderBy_${item.value}`} label={item.label} value={item.value} />
            ))
          }
        </Select>
        <Select
          className="mx-1"
          popper-class="select-popper"
          value={_selectedOrderType}
          onChange={value => (setSelectedOrderType(value))}
        >
          {
            OrderSelection.map(item => (
              <Select.Option key={`OrderType_${item.value}`} label={item.label} value={item.value} />
            ))
          }
        </Select>
        <Label value={t('SEARCH')} className="px-2">{t('SEARCH')}</Label>
        <Select
          popper-class="select-popper"
          value={_selectedSearchBy}
          onChange={value => {
            setSelectedSearchBy(value);
            if (value === '-1') {
              setSearchText('');
            }
          }}
        >
          {
            UserSearchBy.map(item => (
              <Select.Option key={`CommonSearchBy${item.value}`} label={item.label} value={item.value} />
            ))
          }
        </Select>
        <Input className="mx-1" value={_searchText} onChange={({ target: { value: text } }) => setSearchText(text)} />
        <Label type="daterange" value={t('DATE')} className="px-2">{t('DATE')}</Label>
        <RangePicker
          allowClear={false}
          value={[moment(_date[0]), moment(_date[1])]}
          separator={<span>{` ${t('TO')} `}</span>}
          ranges={{
            Today: [moment(), moment()],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
          }}
          onChange={date => {
            setDate([date[0].toDate(), date[1].toDate()]);
          }}
        />

        <Label value={t('BALANCE_MORE_THAN')} className="px-2 marginLeft5">{t('BALANCE_MORE_THAN')}</Label>
        <Input type="number" className="mx-1" value={_balanceMoreThan} onChange={({ target: { value: text } }) => setBalanceMoreThan(text)} />
        <Label value={t('BALANCE_LESS_THAN')} className="px-2">{t('BALANCE_LESS_THAN')}</Label>
        <Input type="number" className="mx-1" value={_balanceLessThan} onChange={({ target: { value: text } }) => setBalanceLessThan(text)} />
        <Button className="searchButton marginLeft5 me-2" onClick={onSearchClick}>{t('FIND')}</Button>
        <Notifications />
      </Col>
      <Col className="subMenu mb-2 flex">
        {
          (data?.levelValue || userData?.levelValue === 1) && (
            <Button className="addButton mx-2" onClick={() => callback('create_agent', userInfo, breadcrumb)}>
              <FaPlusCircle className="mx-1" />
              {t('ADD_AGENT')}
            </Button>
          )
        }
        {
          (data?.levelValue || userData?.levelValue) >= 10 && (
            <Button
              className="addButton mx-2"
              onClick={() => callback('create_member', {
                orgId: data?.id || userData.id, isChild: data?.isChild || userData?.isChild, loginId: userData?.loginId
              }, breadcrumb)}
            >
              <FaPlusCircle className="mx-1" />
              {t('ADD_MEMBER')}
            </Button>
          )
        }
        {
          (data?.levelValue || userData?.levelValue) !== 1 && (data?.levelValue || userData?.levelValue) < 10 && (
            <>
              <Button
                className="addButton mx-2"
                onClick={() => callback('create_member', {
                  orgId: data?.id || userData.id, isChild: data?.isChild || userData?.isChild, loginId: userData?.loginId
                }, breadcrumb)}
              >
                <FaPlusCircle className="mx-1" />
                {t('ADD_DIRECT_MEMBER')}
              </Button>
              <Button className="addButton mx-2" onClick={() => callback('create_agent', userInfo, breadcrumb)}>
                <FaPlusCircle className="mx-1" />
                {t('ADD_AGENT')}
              </Button>
            </>
          )
}
      </Col>
      <Row className="mx-0 bg-white px-0 border-1">
        <AgentDataTable
          isQuerying={_isQueryingDetail}
          tableHeaderTitle={_header}
          tableData={_detailItem || []}
          tableBodyRowProperty={_content}
          tableControl={control}
          activeControl={_activeId}
          tableFooter={[[{
            value: `${t('THIS_PAGE')}: `,
            colspan: 5,
            className: 'textRight pr-1 total tableSum'
          },
          {
            value: (_detailItemSum && _detailItemSum.sumPage) || 0,
            colspan: 1,
            className: 'tableSum'
          },
          {
            colspan: _header.length - 5,
            className: 'tableSum'
          }], [{
            value: `${t('TOTAL_COUNT')}: `,
            colspan: 5,
            className: 'textRight pr-1 total tableSum'
          },
          {
            value: (_detailItemSum && _detailItemSum.sumAll) || 0,
            colspan: 1,
            className: 'tableSum'
          },
          {
            colspan: _header.length - 5,
            className: 'tableSum'
          }]]}
        />
      </Row>
      <Row className="justify-center w-full relative">
        <Pagination totalRecord={(_detailItemSum && _detailItemSum?.totalRecords) || 0} onClick={onPaginationClick} isQuery={_isQueryingDetail} />
      </Row>
    </Col>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MemberList);