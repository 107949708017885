import { useEffect, useState } from 'react';

import { Button, Col, Input, Label, Row } from 'reactstrap';
import { connect } from 'react-redux';

import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

import { appActions, systemActions } from '../../../redux/actions';
import './styles.scss';


const mapStateToProps = state => ({
  editNoticeData: state.noticeReducers.editNoticeData,
  userData: state.memberReducers.userData,
  editThirdPaySettingData: state.systemReducers.editThirdPaySettingData,
});

const mapDispatchToProps = dispatch => ({
  getEditThirdPaySetting: (id, callback) => dispatch(systemActions.getEditThirdPaySetting(id, callback)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  saveThirdPartyPaySetting: (obj, callback) => dispatch(systemActions.saveThirdPartyPaySetting(obj, callback)),
});

const ThirdPartyPayEdit = ({ callback = {}, isEdit, getEditThirdPaySetting, data, editThirdPaySettingData, updateModalAlert, saveThirdPartyPaySetting }) => {
  const { t } = useTranslation();
  const [_info, setInfo] = useState([]);
  const [_memberList, setMemberList] = useState([]);
  const [_agentList, setAgentList] = useState([]);

  useEffect(() => {
    getEditThirdPaySetting({
      id: data?.id || ''
    });
  }, [data]);

  useEffect(() => {
    if (!isEdit) {
      setInfo([]);
      setMemberList([]);
      setAgentList([]);
    } else if (editThirdPaySettingData && JSON.stringify(editThirdPaySettingData) !== JSON.stringify(_info)) {
      setInfo(editThirdPaySettingData);
      setMemberList(editThirdPaySettingData?.memberLevels && editThirdPaySettingData?.memberLevels?.split(','));
      setAgentList(editThirdPaySettingData?.agentLevels && editThirdPaySettingData?.agentLevels?.split(','));
    }
  }, [editThirdPaySettingData]);


  const saveButton = () => {
    const valuesMember = Array
      .from(document.querySelectorAll('[name^="chkMemberList"]:checked'))
      .filter(checkbox => checkbox.checked)
      .map(checkbox => checkbox.value);
    const valuesAgent = Array
      .from(document.querySelectorAll('[name^="chkAgentList"]:checked'))
      .filter(checkbox => checkbox.checked)
      .map(checkbox => checkbox.value);
    if (_info?.isChange || valuesMember?.length > 0 || valuesAgent?.length > 0) {
      const updateObj = _info;
      updateModalAlert({
        type: 'loading',
        visible: true,
      });
      updateObj.memberLevels = valuesMember;
      updateObj.agentLevels = valuesAgent;
      saveThirdPartyPaySetting(updateObj, callback);
    } else {
      updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: '无变动'
        }
      });
    }
  };

  const onInfoValueChange = (val, field) => {
    const newItems = _info;
    newItems[field] = val;
    newItems.isChange = true;
    setInfo(newItems => ({
      ...newItems, field: val
    }));
  };

  const resetDefault = () => {
    if (!isEdit) {
      callback();
    } else {
      getEditThirdPaySetting({
        id: data?.id
      });
    }
  };

  const status = [
    {
      label: '启用',
      value: '0',
    },
    {
      label: '禁用',
      value: '1',
    }
  ];

  const PayType = [
    {
      label: '网页支付',
      value: 'WEB',
    },
    {
      label: '二维码支付',
      value: 'QRCODE',
    }
  ];
  const UpdateExchangeRate = [
    {
      label: '手动',
      value: 0,
    },
    {
      label: '自动(第三方同步)',
      value: 1,
    }
  ];
  const PayModel = [
    {
      label: '订单模式',
      value: 0,
    },
    {
      label: '非订单模式',
      value: 1,
    }
  ];
  const platforms = [
    {
      label: '所有平台',
      value: '-1',
    },
    {
      label: '后台',
      value: '0',
    }, {
      label: '会员PC',
      value: '1',
    },
    {
      label: '会员手机版',
      value: '2',
    }
  ];

  const PayGroup = [
    {
      label: '网银支付',
      value: '100',
    }, {
      label: '银联快捷',
      value: '110',
    }, {
      label: '云闪付',
      value: '120',
    }, {
      label: '银行卡汇款',
      value: '130',
    }, {
      label: '支付宝',
      value: '200',
    }, {
      label: '微信',
      value: '210',
    }, {
      label: 'QQ支付',
      value: '220'
    }, {
      label: '虚拟币',
      value: '300',
    }, {
      label: '其他支付',
      value: '0'
    }
  ];

  return (
    <div className="ThirdPartyPayEditWrapper">
      <div className="mx-0 bg-white my-2  border-1">
        <Row className="m-0 my-1 p-0">
          <table className="p-3 m-0" cellSpacing="0" cellPadding="0" border="0">
            <tbody>
              <tr>
                <td className="titleHeader" colSpan={4}>
                  {isEdit === true ? '修改收款第三方设置' : '新增收款第三方设置'}
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-2">第三方识别Code</td>
                <td>
                  <div>
                    <Input
                      className="d-inline col-1"
                      type="text"
                      value={_info?.code || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text, 'code')}
                    />
                  </div>
                </td>
                <td className="tableHeader col-2">充值API</td>
                <td>
                  <div className="longInput">
                    <Input
                      className="d-inline col-1"
                      type="text"
                      value={_info?.payUrl || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text, 'payUrl')}
                    />
                    <Label className="mx-1">(留空则使用默认)</Label>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-2">支付名称</td>
                <td>
                  <div>
                    <Input
                      className="d-inline col-1"
                      type="text"
                      value={_info?.payName || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text, 'payName')}
                    />
                  </div>
                </td>
                <td className="tableHeader col-2">充值扫码API</td>
                <td>
                  <div className="longInput">
                    <Input
                      className="d-inline col-1"
                      type="text"
                      value={_info?.payQRUrl || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text, 'payQRUrl')}
                    />
                    <Label className="mx-1">(留空则使用默认)</Label>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-2">名称备注</td>
                <td>
                  <div>
                    <Input
                      className="d-inline col-1"
                      type="text"
                      value={_info?.payRemark || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text, 'payRemark')}
                    />
                  </div>
                </td>
                <td className="tableHeader col-2">充值返回确认地址</td>
                <td>
                  <div className="longInput">
                    <Input
                      className="d-inline col-1"
                      type="text"
                      value={_info?.payReturnHost || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text, 'payReturnHost')}
                    />
                    <Label className="mx-1">(留空则使用默认)</Label>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-2">支付方式</td>
                <td>
                  <div className="d-flex my-1 rdSearchOptionsGroup">
                    {
                      PayType.map(d => (
                        <div key={`rdPayType_${d.value}`}>
                          <Input
                            id={`rdPayType_${d.value}`}
                            type="radio"
                            name="rdPayType"
                            value={_info?.payType}
                            checked={_info?.payType === d.value}
                            onChange={() => onInfoValueChange(d.value, 'payType')}
                          />
                          <Label
                            for={`rdPayType_${d.value}`}
                            className={_info?.payType === d.value ? 'active mx-2 smallLabel' : 'mx-2 smallLabel'}
                          >
                            {d?.label}
                          </Label>
                        </div>
                      ))
                    }
                  </div>
                </td>
                <td className="tableHeader col-2">充值成功返回页面</td>
                <td>
                  <div className="longInput">
                    <Input
                      className="d-inline col-1"
                      type="text"
                      value={_info?.payReturnPage || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text, 'payReturnPage')}
                    />
                    <Label className="mx-1">(留空则使用默认)</Label>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-2">支付分组</td>
                <td>
                  <Select
                    value={_info?.payGroup}
                    placeholder="请选择"
                    onChange={val => { onInfoValueChange(Number(val), 'payGroup'); }}
                  >
                    {
                            PayGroup.map(d => (
                              <Select.Option key={`setPayGroup_${d.value}`} label={d.label} value={Number(d.value)} />
                            ))
                      }
                  </Select>
                </td>
                <td className="tableHeader col-2">充值</td>
                <td>
                  <div className="d-flex my-1 rdSearchOptionsGroup">
                    {
                      status.map(d => (
                        <div key={`rdIsDeposit_${d.value}`}>
                          <Input
                            id={`rdIsDeposit_${d.value}`}
                            type="radio"
                            name="rdIsDeposit"
                            value={_info?.isDeposit}
                            checked={_info?.isDeposit === Number(d.value)}
                            onChange={() => onInfoValueChange(Number(d.value), 'isDeposit')}
                          />
                          <Label
                            for={`rdIsDeposit_${d.value}`}
                            className={_info?.isDeposit === Number(d.value) ? 'active mx-2 smallLabel' : 'mx-2 smallLabel'}
                          >
                            {d?.label}
                          </Label>
                        </div>
                      ))
                    }
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-2">支付模式</td>
                <td>
                  <div className="d-flex my-1 rdSearchOptionsGroup">
                    {
                      PayModel.map(d => (
                        <div key={`rdOrderTypeGroup_${d.value}`}>
                          <Input
                            id={`rdOrderType_${d.value}`}
                            type="radio"
                            name="rdOrderType"
                            value={_info?.orderType}
                            checked={_info?.orderType === d.value}
                            onChange={() => onInfoValueChange(d.value, 'orderType')}
                          />
                          <Label
                            for={`rdOrderType_${d.value}`}
                            className={_info?.orderType === d.value ? 'active mx-2 smallLabel' : 'mx-2 smallLabel'}
                          >
                            {d?.label}
                          </Label>
                        </div>
                      ))
                    }
                  </div>
                </td>
                <td className="tableHeader col-2">充值状态说明</td>
                <td>
                  <div className="longInput">
                    <Input
                      className="d-inline col-1"
                      type="text"
                      value={_info?.depositRemark || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text, 'depositRemark')}
                    />
                    <Label className="mx-1">(留空则使用默认)</Label>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-2">币种</td>
                <td>
                  <div>
                    <Input
                      className="d-inline col-1"
                      type="text"
                      value={_info?.currency || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text, 'currency')}
                      placeholder="默认:RMB"
                    />
                  </div>
                </td>
                <td className="tableHeader col-2">出款API</td>
                <td>
                  <div className="longInput">
                    <Input
                      className="d-inline col-1"
                      type="text"
                      value={_info?.withdrawUrl || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text, 'withdrawUrl')}
                    />
                    <Label className="mx-1">(留空则使用默认)</Label>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-2">兑率</td>
                <td>
                  <div>
                    <Input
                      className="d-inline col-1"
                      type="number"
                      value={_info?.exchangeRate?.toString() || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text === '' ? '' : Number(text), 'exchangeRate')}
                      placeholder="默认:1"
                    />
                    <Label className="mx-1">(1币种=多少RMB)</Label>
                  </div>
                </td>
                <td className="tableHeader col-2">出款返回确认地址</td>
                <td>
                  <div className="longInput">
                    <Input
                      className="d-inline col-1"
                      type="text"
                      value={_info?.withdrawReturnHost || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text, 'withdrawReturnHost')}
                    />
                    <Label className="mx-1">(留空则使用默认)</Label>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-2">兑率更新模式</td>
                <td>
                  <div className="d-flex my-1 rdSearchOptionsGroup">
                    {
                      UpdateExchangeRate.map(d => (
                        <div key={`rdUpdateExchangeRate_${d.value}`}>
                          <Input
                            id={`rdUpdateExchangeRate_${d.value}`}
                            type="radio"
                            name="rdUpdateExchangeRate"
                            value={_info?.updateExchangeRate}
                            checked={_info?.updateExchangeRate === Number(d.value)}
                            onChange={() => onInfoValueChange(Number(d.value), 'updateExchangeRate')}
                          />
                          <Label
                            for={`rdUpdateExchangeRate_${d.value}`}
                            className={_info?.updateExchangeRate === Number(d.value) ? 'active mx-2 smallLabel' : 'mx-2 smallLabel'}
                          >
                            {d?.label}
                          </Label>
                        </div>
                      ))
                    }
                  </div>
                </td>
                <td className="tableHeader col-2">出款成功返回页面</td>
                <td>
                  <div className="longInput">
                    <Input
                      className="d-inline col-1"
                      type="text"
                      value={_info?.withdrawReturnPage || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text, 'withdrawReturnPage')}
                    />
                    <Label className="mx-1">(留空则使用默认)</Label>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-2">最大支付金额</td>
                <td>
                  <div>
                    <Input
                      className="d-inline col-1"
                      type="number"
                      value={_info?.maxAmount?.toString() || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text === '' ? '' : Number(text), 'maxAmount')}
                    />
                  </div>
                </td>
                <td className="tableHeader col-2">出款</td>
                <td>
                  <div className="d-flex my-1 rdSearchOptionsGroup">
                    {
                      status.map(d => (
                        <div key={`rdisWithdraw_${d.value}`}>
                          <Input
                            id={`rdisWithdraw_${d.value}`}
                            type="radio"
                            name="rdisWithdraw"
                            value={_info?.isWithdraw}
                            checked={_info?.isWithdraw === Number(d.value)}
                            onChange={() => onInfoValueChange(Number(d.value), 'isWithdraw')}
                          />
                          <Label
                            for={`rdisWithdraw_${d.value}`}
                            className={_info?.isWithdraw === Number(d.value) ? 'active mx-2 smallLabel' : 'mx-2 smallLabel'}
                          >
                            {d?.label}
                          </Label>
                        </div>
                      ))
                    }
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-2">最小支付金额</td>
                <td>
                  <div>
                    <Input
                      className="d-inline col-1"
                      type="number"
                      value={_info?.minAmount?.toString() || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text === '' ? '' : Number(text), 'minAmount')}
                    />
                  </div>
                </td>
                <td className="tableHeader col-2">充值银行配置</td>
                <td>
                  <div className="longInput">
                    <Input
                      className="d-inline col-1"
                      type="text"
                      value={_info?.rechargeBank || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text, 'rechargeBank')}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-2">手动确认</td>
                <td>
                  <div className="d-flex my-1 rdSearchOptionsGroup">
                    {
                      status.map(d => (
                        <div key={`rdisManualConfirm_${d.value}`}>
                          <Input
                            id={`rdisManualConfirm_${d.value}`}
                            type="radio"
                            name="rdisManualConfirm"
                            value={_info?.isManualConfirm}
                            checked={_info?.isManualConfirm === Number(d.value)}
                            onChange={() => onInfoValueChange(Number(d.value), 'isManualConfirm')}
                          />
                          <Label
                            for={`rdisManualConfirm_${d.value}`}
                            className={_info?.isManualConfirm === Number(d.value) ? 'active mx-2 smallLabel' : 'mx-2 smallLabel'}
                          >
                            {d?.label}
                          </Label>
                        </div>
                      ))
                    }
                  </div>
                </td>
                <td className="tableHeader col-2">第三方支付配置</td>
                <td>
                  <div className="longInput">
                    <Input
                      className="d-inline col-1"
                      type="text"
                      value={_info?.thirdPartyPayConfig || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text, 'thirdPartyPayConfig')}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-2">手动审核</td>
                <td>
                  <div className="d-flex my-1 rdSearchOptionsGroup">
                    {
                      status.map(d => (
                        <div key={`rdisManualCheck_${d.value}`}>
                          <Input
                            id={`rdisManualCheck_${d.value}`}
                            type="radio"
                            name="rdisManualCheck"
                            value={_info?.isManualCheck}
                            checked={_info?.isManualCheck === Number(d.value)}
                            onChange={() => onInfoValueChange(Number(d.value), 'isManualCheck')}
                          />
                          <Label
                            for={`rdisManualCheck_${d.value}`}
                            className={_info?.isManualCheck === Number(d.value) ? 'active mx-2 smallLabel' : 'mx-2 smallLabel'}
                          >
                            {d?.label}
                          </Label>
                        </div>
                      ))
                    }
                  </div>
                </td>
                <td className="tableHeader col-2">出款银行配置</td>
                <td>
                  <div className="longInput">
                    <Input
                      className="d-inline col-1"
                      type="text"
                      value={_info?.withdrawBank || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text, 'withdrawBank')}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-2">会员分类</td>
                <td>
                  <div className="d-flex p-0">
                    {
                       Array.from('1234567').map(d => (
                         <p key={`chkMemberList_${d}`} className="d-inline border-0 m-1">
                           <Input
                             id={`chkMemberList_${d}`}
                             type="checkbox"
                             key={`${_info?.id}_member_${d}`}
                             name="chkMemberList"
                             value={d}
                             defaultChecked={_memberList?.length > 0 && _memberList?.filter(x => x === d).length > 0}
                             className="justify-content-center align-items-center"
                           />
                           <Label className="px-1">{d}</Label>
                           <Label>类</Label>
                         </p>
                       ))
                    }
                  </div>
                </td>
                <td className="tableHeader col-2">排序</td>
                <td>
                  <div>
                    <Input
                      className="d-inline col-1"
                      type="number"
                      value={_info?.sortID?.toString() || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text === '' ? '' : Number(text), 'sortID')}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-2">代理分类</td>
                <td>
                  <div className="d-flex p-0">
                    {
                       Array.from('1234567').map(d => (
                         <p key={`chkAgentList_${d}`} className="d-inline border-0 m-1">
                           <Input
                             id={`chkAgentList_${d}`}
                             type="checkbox"
                             key={`${_info?.id}_agent_${d}`}
                             name="chkAgentList"
                             value={d}
                             defaultChecked={_agentList?.length > 0 && _agentList?.filter(x => x === d).length > 0}
                             className="justify-content-center align-items-center"
                           />
                           <Label className="px-1">{d}</Label>
                           <Label>类</Label>
                         </p>
                       ))
                    }
                  </div>
                </td>
                <td className="tableHeader col-2">充值回调IP白名单</td>
                <td>
                  <div className="longInput">
                    <Input
                      className="d-inline col-1"
                      type="text"
                      value={_info?.payWhitelist || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text, 'payWhitelist')}
                    />
                    <Label className="mx-1">(不设置则不验证)</Label>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-2">会员实扣手续费</td>
                <td>
                  <div>
                    <Input
                      className="d-inline col-1"
                      placeholder="默认值:0.00"
                      type="number"
                      value={_info?.fee?.toString() || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text === '' ? '' : Number(text), 'fee')}
                    />
                    <Label className="mx-1">% (充值手续费,不收手续费则:0)</Label>
                  </div>
                </td>
                <td className="tableHeader col-2">代付请求IP白名单</td>
                <td>
                  <div className="longInput">
                    <Input
                      className="d-inline col-1"
                      type="text"
                      value={_info?.payRequestWhitelist || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text, 'payRequestWhitelist')}
                    />
                    <Label className="mx-1">(不设置则不验证)</Label>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-2">代理实扣手续费</td>
                <td>
                  <div>
                    <Input
                      className="d-inline col-1"
                      placeholder="默认值：0"
                      type="number"
                      value={_info?.parentFee?.toString() || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text === '' ? '' : Number(text), 'parentFee')}
                    />
                    <Label className="mx-1">% (充值手续费,留空则为默认值:0.00)</Label>
                  </div>
                </td>
                <td className="tableHeader col-2">代付回调IP白名单</td>
                <td>
                  <div className="longInput">
                    <Input
                      className="d-inline col-1"
                      type="text"
                      value={_info?.withdrawWhitelist || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text, 'withdrawWhitelist')}
                    />
                    <Label className="mx-1">(不设置则不验证)</Label>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-2">每日限额</td>
                <td>
                  <div>
                    <Input
                      className="d-inline col-1"
                      type="number"
                      value={_info?.dayMaxAmount?.toString() || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text === '' ? '' : Number(text), 'dayMaxAmount')}
                      placeholder="默认:0.00"
                    />
                    <Label className="mx-1"> (每日充值金额总数,不做限制则为:0)</Label>
                  </div>
                </td>
                <td className="tableHeader col-2">附加说明</td>
                <td>
                  <div>
                    <Input
                      className="d-inline col-1"
                      type="text"
                      value={_info?.remark || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text, 'remark')}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-2">显示平台</td>
                <td>
                  <div>
                    <Select
                      value={_info?.platform || ''}
                      placeholder="请选择"
                      onChange={val => onInfoValueChange(Number(val), 'platform')}
                    >
                      {
                              platforms.map(d => (
                                <Select.Option key={`setPlatformsSelect_${d.value}`} label={d.label} value={Number(d.value)} />
                              ))
                        }
                    </Select>
                  </div>
                </td>
                <td className="tableHeader col-2">测试模式</td>
                <td>
                  <div className="d-flex my-1 rdSearchOptionsGroup">
                    {
                      status.map(d => (
                        <div key={`rdIsTest_${d.value}`}>
                          <Input
                            id={`rdIsTest_${d.value}`}
                            type="radio"
                            name="rdIsTest"
                            value={_info?.isTest}
                            checked={_info?.isTest === Number(d.value)}
                            onChange={() => onInfoValueChange(Number(d.value), 'isTest')}
                          />
                          <Label
                            for={`rdIsTest_${d.value}`}
                            className={_info?.isTest === Number(d.value) ? 'active mx-2 smallLabel' : 'mx-2 smallLabel'}
                          >
                            {d?.label}
                          </Label>
                        </div>
                      ))
                    }
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-2">中转地址</td>
                <td>
                  <div className="longInput">
                    <Input
                      className="d-inline col-1"
                      type="text"
                      value={_info?.url || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text, 'url')}
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </Row>
      </div>
      <Row className="mb-5 my-2 p-2">
        <Col className="d-flex justify-content-center py-2 border-top">
          <Button className="button handle mx-1" onClick={() => saveButton()}>{t('SAVE')}</Button>
          <Button className="button find mx-1" onClick={() => resetDefault()}>{t('CANCEL')}</Button>
        </Col>
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ThirdPartyPayEdit);