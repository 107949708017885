import { useEffect, useState } from 'react';

import { Button, Col, Label, Row } from 'reactstrap';
import { Checkbox, DatePicker, Input, Select } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { AgentDataTable, CommonHeader, Pagination } from '../..';
import { appActions, thirdPartyGamesActions } from '../../../redux/actions';

import './styles.scss';

const mapStateToProps = state => ({
  rebate: state.thirdPartyGamesReducers.rebate,
});

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  GetRebate: (obj, callback) => dispatch(thirdPartyGamesActions.GetRebate(obj, callback)),
  CancelRebate: (obj, list, callback) => dispatch(thirdPartyGamesActions.CancelRebate(obj, list, callback)),
  SaveRebate: (list, callback) => dispatch(thirdPartyGamesActions.SaveRebate(list, callback)),
  GenerateRebate: obj => dispatch(thirdPartyGamesActions.GenerateRebate(obj)),
});

const { RangePicker } = DatePicker;

const { Option } = Select;

const HandleRebate = ({ updateModalAlert, GenerateRebate, GetRebate, rebate, SaveRebate, CancelRebate }) => {
  const { t } = useTranslation();
  const [_date, setDate] = useState([moment().subtract(1, 'weeks').toDate(), moment().toDate()]);
  const [_status, setstatus] = useState(0);
  const [_isQueryingDetail, setIsQueryingDetail] = useState(false);
  const [_page, setPage] = useState(1);
  const [_rows, setRows] = useState(15);
  const [userType, setuserType] = useState(-1);
  const [loginName, setloginName] = useState('');
  const [rebateAmount, setrebateAmount] = useState('');
  const [_list, setList] = useState();
  const [_checkAll, setCheckAll] = useState(false);

  useEffect(() => {
    const obj = {
      beginTime: moment().subtract(1, 'weeks').format('YYYY-MM-DD  HH:mm:ss'),
      endTime: moment().format('YYYY-MM-DD  HH:mm:ss'),
      userType,
      status: _status,
      loginName,
      rebateAmount,
      page: _page,
      row: _rows,
    };
    GetRebate(obj, setIsQueryingDetail);
  }, []);

  useEffect(() => {
    if (_checkAll && rebate) {
      const _ls = rebate.rebateList.map(item => item.reportId);
      setList([..._ls]);
    } else {
      setList([]);
    }
  }, [_checkAll, rebate]);

  const onSearchClick = () => {
    setIsQueryingDetail(true);
    const obj = {
      beginTime: moment(_date[0]).format('YYYY-MM-DD  HH:mm:ss'),
      endTime: moment(_date[1]).format('YYYY-MM-DD  HH:mm:ss'),
      userType,
      status: _status,
      loginName,
      rebateAmount,
      page: _page,
      row: _rows,
    };
    GetRebate(obj, setIsQueryingDetail);
  };

  const onPaginationClick = (pageNum, rows) => {
    setIsQueryingDetail(false);
    const obj = {
      beginTime: moment(_date[0]).format('YYYY-MM-DD  HH:mm:ss'),
      endTime: moment(_date[1]).format('YYYY-MM-DD  HH:mm:ss')
    };
    GetRebate(obj, setIsQueryingDetail);
    setPage(pageNum);
    setRows(rows);
  };

  const handleAgentRebate = (batchFlag, type, item = {}) => {
    const obj = {
      beginTime: moment(_date[0]).format('YYYY-MM-DD  HH:mm:ss'),
      endTime: moment(_date[1]).format('YYYY-MM-DD  HH:mm:ss'),
      userType,
      status: _status,
      loginName,
      rebateAmount,
      page: _page,
      row: _rows,
    };
    let arr = [];
    if (batchFlag === false) {
      arr = [{
        id: item.reportId, memberId: item.memId, amount: item.rebateAmount, memberName: item.loginId, memberType: item.userType, remarks: type === 'save' ? '赚水' : '撤消赚水'
      }];
    } else {
      _list.forEach(x => {
        const item = rebate.rebateList.find(ele => ele.reportId === x);
        const temp = {
          id: item.reportId, memberId: item.memId, amount: item.rebateAmount, memberName: item.loginId, memberType: item.userType, remarks: type === 'save' ? '赚水' : '撤消赚水'
        };
        arr.push(temp);
      });
    }
    updateModalAlert({
      type: 'rebate',
      visible: true,
      data: {
        onConfirmationClick: list => {
          if (type === 'save') {
            SaveRebate(list, () => { GetRebate(obj, setIsQueryingDetail); });
          } else {
            CancelRebate(list, () => { GetRebate(obj, setIsQueryingDetail); });
          }
        },
        data: arr
      }
    });
  };

  return (
    <Col className="betHandleRebateWrapper">
      <CommonHeader headerName={t('CHECK_RETURN')} />
      <Col className="p-2 mb-2 filterWrapper d-flex align-items-center w-100 rounded-top">
        <span className="mx-2">用户类型</span>
        <Select
          popper-class="select-popper"
          value={userType}
          onChange={value => (setuserType(value))}
        >
          {
            [{
              code: -1, userType: '全部'
            }, {
              code: 1, userType: '会员'
            }, {
              code: 2, userType: '代理'
            }].map(item => (
              <Option key={item.code} value={item.code}>{item.userType}</Option>
            ))
          }
        </Select>
        <span className="mx-2">状态</span>
        <Select
          value={_status}
          onChange={value => (setstatus(value))}
        >
          {
            [{
              code: 0, status: '未处理'
            }, {
              code: 1, status: '已处理'
            }].map(item => (
              <Option key={item.code} value={item.code}>{item.status}</Option>
            ))
          }
        </Select>
        <span className="mx-2">账号</span>
        <Input className="mx-1 rebetaInputStyle" value={loginName} onChange={({ target: { value: text } }) => setloginName(text.trim())} />
        <Label type="daterange" value={t('DATE')} className="px-1">{t('DATE')}</Label>
        <RangePicker
          allowClear={false}
          value={[moment(_date[0]), moment(_date[1])]}
          onChange={date => {
            setDate([date[0].toDate(), date[1].toDate()]);
          }}
        />
        <span className="mx-2">赚水大于</span>
        <Input className="mx-1 rebetaInputStyle" value={rebateAmount} onChange={({ target: { value: text } }) => setrebateAmount(text > -1 ? Number(text.trim()) : rebateAmount)} />
        <Button className="searchButton find mx-1" onClick={onSearchClick}>{t('FIND')}</Button>
        <Button
          className="searchButton generate mx-1"
          onClick={() => GenerateRebate({
            beginTime: moment(_date[0]).format('YYYY-MM-DD  HH:mm:ss'), endTime: moment(_date[1]).format('YYYY-MM-DD  HH:mm:ss')
          })}
        >
          生成
        </Button>
        <Button className="searchButton handle mx-1" onClick={() => handleAgentRebate(true, (_status === 0 ? 'save' : 'cancel'))}>操作所选</Button>
      </Col>
      <Row className="mx-0 bg-white px-0 border-1">
        <AgentDataTable
          isQuerying={_isQueryingDetail}
          tableHeaderTitle={
            [{
              label: (
                (<Checkbox onChange={({ target: { checked: val } }) => setCheckAll(val)} checked={_checkAll}>全选</Checkbox>)
              ),
            }, {
              label: '序号',
            }, {
              label: '日期',
            }, {
              label: '账号',
            }, {
              label: '用户类型',
            }, {
              label: '有效金额',
            }, {
              label: '赚水',
            }, {
              label: '状态',
            }, {
              label: '反水',
            }]
          }
          tableData={rebate ? rebate.rebateList.map((item, index) => ({
            ...item, index: index + 1
          })) : []}
          tableBodyRowProperty={
            [
              data => (
                <Label className="d-flex justify-content-center">
                  <Checkbox
                    onChange={() => {
                      if (_list.includes(data.reportId)) {
                        setList(_list.filter(item => item !== data.reportId));
                      } else {
                        setList([..._list, data.reportId]);
                      }
                    }}
                    checked={_list.includes(data.reportId)}
                  />
                </Label>
              ),
              data => (<Label className="d-flex justify-content-center">{data.index}</Label>),
              data => (<Label className="px-1">{moment.unix(data.date).format('YYYY-MM-DD')}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.loginId}</Label>),
              data => (<Label>{data.userType === 1 ? '会员' : '代理'}</Label>),
              data => (<Label className={data.betAmount >= 0 ? 'positiveValue' : 'negativeValue'}>{data.betAmount}</Label>),
              data => (<Label className={data.rebateAmount >= 0 ? 'positiveValue' : 'negativeValue'}>{data.rebateAmount}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.isHandled === 0 ? '未处理' : '已处理'}</Label>),
              data => (
                <Label
                  className="lotteryName url"
                  onClick={() => {
                    if (data.isHandled === 0) {
                      handleAgentRebate(false, 'save', data);
                    } else {
                      handleAgentRebate(false, 'cancel', data);
                    }
                  }}
                >
                  {data.isHandled === 0 ? '处理' : '撤销'}
                </Label>
              ),
            ]
          }
          tableFooter={[[{
            value: `${t('CURRENT_PAGE_TOTAL')}`,
            colspan: 5,
            className: 'textRight pr-1 total tableSum'
          },
          {
            value: (rebate && rebate.total.betAmount) || 0,
            colspan: 1,
            className: 'tableSum text-center'
          },
          {
            value: (rebate && rebate.total.rebateAmount) || 0,
            colspan: 1,
            className: 'text-center tableSum'
          },
          {
            colspan: 1,
            className: 'tableSum'
          },
          {
            colspan: 1,
            className: 'tableSum'
          }]]}
          tableHeaderStyle={
            []
          }
        />
      </Row>
      <Row className="justify-center w-full relative">
        <Pagination totalRecord={(rebate && rebate?.rebateList.length) || 0} onClick={onPaginationClick} isQuery={_isQueryingDetail} />
      </Row>
    </Col>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(HandleRebate);