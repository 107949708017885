import { useEffect, useState } from 'react';

import { Checkbox, Input, Radio, Select } from 'antd';
import { Label } from 'reactstrap';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import AgentDataTable from '../../../AgentDataTable';
import { agentActions } from '../../../../redux/actions';
import mainGames from '../../../../constants/QuickSetVariable/Games';
import './styles.scss';

const mapStateToProps = state => ({
  activeLottery: state.commonReducers.activeLottery,
  rebateSetting: state.agentReducers.rebateSetting,
  userData: state.memberReducers.userData,
});

const mapDispatchToProps = dispatch => ({
  getRebateSetting: (obj, callback) => dispatch(agentActions.getRebateSetting(obj, callback)),
  saveRebateSetting: (obj, callback) => dispatch(agentActions.saveRebateSetting(obj, callback)),
  resetData: () => dispatch(agentActions.resetData()),
});

const { Option } = Select;

const LotteryReturn = ({ getRebateSetting, data, rebateSetting, saveRebateSetting }) => {
  const { t } = useTranslation();
  const [lotType, setlotType] = useState();
  const [_isQuerying, setIsQuerying] = useState(false);
  const [memberGame, setMemberGame] = useState(['A', 'B', 'C', 'D']);
  const [lotteryList, setLotteryList] = useState();
  const [oddsList, setOddsList] = useState();
  const [allOddsList, setAllOddsList] = useState();
  const [menuList, setMenuList] = useState();
  const [activeKey, setActiveKey] = useState();
  const [_selectedLottery, setSelectedLottery] = useState();
  const [changAll, setChangeAll] = useState(false);
  const [commonValue, setCommonValue] = useState('');
  const [_returnOption, setReturnOption] = useState('All');
  const [_mainGames, setMainGames] = useState('');

  const [quickDataSet, setQuickData] = useState();

  const [billList, setBillList] = useState();

  const [simultaneousSave, setsimultaneousSave] = useState(1);

  const getRebateSettingData = () => {
    setIsQuerying(true);
    setOddsList([]);
    getRebateSetting({
      orgId: data.memberId || data?.orgId, userType: data?.orgId ? 2 : 1,
    }, () => setIsQuerying(false));
  };

  useEffect(() => {
    getRebateSettingData();
  }, []);

  useEffect(() => {
    if (rebateSetting) {
      if (JSON.stringify(lotteryList) !== JSON.stringify(rebateSetting.lotteryList)) {
        setLotteryList(rebateSetting.lotteryList);
      }
      if (JSON.stringify(memberGame) !== JSON.stringify(rebateSetting.memberGame.split(''))) {
        setMemberGame(rebateSetting.memberGame.split(''));
      }
      setBillList(rebateSetting.haveBillList);
      setAllOddsList(rebateSetting.listSetting.map(x => ({
        ...x
      })));
    }
  }, [rebateSetting]);

  useEffect(() => {
    if (lotteryList) {
      const tempGames = mainGames.Games.filter(x => lotteryList.some(item => x.code.includes(item.templateCode)));
      setlotType(tempGames[0]?.value);
      setMainGames(tempGames);
    }
  }, [lotteryList]);

  useEffect(() => {
    if (_mainGames && _mainGames.length > 0) {
      const _tab = _mainGames.find(x => x.value === lotType);
      const content = [];
      const temp = new Map();
      if (_tab) {
        lotteryList.filter(x => _tab.code.includes(x.templateCode)).forEach(item => {
          if (temp.has(item.templateCode)) {
            const _tempArr = [...temp.get(item.templateCode), {
              ...item, lotteryName: billList.includes(item.code) ? `${item.lotteryName}[预设]` : item.lotteryName
            }];
            temp.set(item.templateCode, _tempArr);
          } else {
            temp.set(item.templateCode, [{
              ...item, lotteryName: billList.includes(item.code) ? `${item.lotteryName}[预设]` : item.lotteryName
            }]);
          }
        });
        temp.forEach((item, key) => {
          if (item.length !== 0) {
            content.push({
              label: _tab.name.find(x => x.value === key)?.label,
              key,
              children: item
            });
          }
        });
        setActiveKey(content[0]?.key);
        setMenuList(content);
      }
    }
  }, [_mainGames, lotType]);

  useEffect(() => {
    if (activeKey && _mainGames && _mainGames.length > 0) {
      const _tab = _mainGames.find(x => x.value === lotType);
      const temp = new Map();
      lotteryList.filter(x => _tab.code.includes(x.templateCode)).forEach(item => {
        if (temp.has(item.templateCode)) {
          const _tempArr = [...temp.get(item.templateCode), item];
          temp.set(item.templateCode, _tempArr);
        } else {
          temp.set(item.templateCode, [item]);
        }
      });
      setSelectedLottery(temp.get(activeKey)[0].code);
      setChangeAll(false);
    }
  }, [activeKey]);

  useEffect(() => {
    if (_selectedLottery && allOddsList) {
      const temp = allOddsList.filter(x => x.lotteryType === _selectedLottery);
      setOddsList([...temp]);
    }
  }, [_selectedLottery, allOddsList]);

  useEffect(() => {
    if (_mainGames && _mainGames.length > 0 && lotType !== undefined) {
      const _oddList = rebateSetting.listSetting.reduce((group, item) => {
        const { code } = item;
        group[code] = group[code] ?? [];
        group[code].push(item);
        return group;
      }, {});
      const tempPresetData = {};
      const quickData = {};
      const { data } = _mainGames.find(x => x.value === lotType);
      data.forEach(x => {
        x.subCode.forEach(y => {
          if (_oddList[y]) {
            tempPresetData[x.value] = {
              ...x, list: tempPresetData[x.value] ? tempPresetData[x.value].list.concat(_oddList[y]) : [].concat(_oddList[y])
            };
          }
        });
      });

      Object.entries(tempPresetData).forEach(([key, value]) => {
        const obj = {
          itemLimit: 0, projectLimit: 0
        };
        memberGame.forEach(x => {
          obj[`${x.toLowerCase()}Return`] = 0;
        });
        value.list.forEach(x => {
          Object.keys(obj).forEach(property => {
            obj[property] = obj[property] >= x[property] ? obj[property] : x[property];
          });
        });
        quickData[key] = {
          label: value.label, subCode: value.subCode, value: value.value, ...obj
        };
      });
      setQuickData(quickData);
    }
  }, [_mainGames, lotType]);

  const onValueChange = (text, code, type) => {
    if (changAll) {
      const _list = menuList.find(x => x.key === activeKey).children;
      const temp = allOddsList.map(x => {
        if (_list.find(item => x.lotteryType === item.code)) {
          if (type.includes('Return') || type.includes('itemLimit') || type.includes('projectLimit')) {
            if (x.code === code) {
              return ({
                ...x, [type]: text > -1 ? text || 0 : x[type], [`${type}singleChange`]: false, [`${type}multiChange`]: true
              });
            }
            return ({
              ...x
            });
          }
          return ({
            ...x
          });
        }
        return ({
          ...x
        });
      });
      setAllOddsList(temp);
    } else {
      const temp = allOddsList.map(x => {
        if (x.lotteryType === _selectedLottery) {
          if (type.includes('Return') || type.includes('itemLimit') || type.includes('projectLimit')) {
            if (x.code === code) {
              return ({
                ...x, [type]: text > -1 ? text || 0 : x[type], [`${type}singleChange`]: true, [`${type}multiChange`]: false
              });
            }
            return ({
              ...x
            });
          }
          return ({
            ...x
          });
        }
        return ({
          ...x
        });
      });
      setAllOddsList(temp);
    }
  };

  const onClickConfirm = () => {
    const _list = menuList.find(x => x.key === activeKey).children;
    if (_returnOption === 'All') {
      const temp = allOddsList.map(x => {
        const checkAllFlag = !!_list.find(item => x.lotteryType === item.code);
        const singFlag = x.lotteryType === _selectedLottery;
        if (((changAll && checkAllFlag) || (!changAll && singFlag))) {
          const obj = {};
          memberGame.forEach(y => {
            const property = `${y.toLowerCase()}Return`;
            obj[property] = commonValue !== '' ? (Number(x.parentSetting[property]) - Number(commonValue)) < 0 ? 0 : Number((Number(x.parentSetting[property]) - Number(commonValue)).toFixed(4)) : Number(x[property]);
            obj[`${property}singleChange`] = true;
            obj[`${property}multiChange`] = false;
          });
          return ({
            ...x, ...obj
          });
        }
        return ({
          ...x
        });
      });
      setAllOddsList(temp);
    } else {
      const property = `${_returnOption.toLowerCase()}Return`;
      if (changAll) {
        const temp = allOddsList.map(x => {
          if (_list.find(item => x.lotteryType === item.code)) {
            return ({
              ...x, [property]: commonValue !== '' ? (Number(x.parentSetting[property]) - Number(commonValue)) < 0 ? 0 : Number((Number(x.parentSetting[property]) - Number(commonValue)).toFixed(4)) : Number(x[property]), [`${property}singleChange`]: false, [`${property}multiChange`]: true
            });
          }
          return ({
            ...x
          });
        });
        setAllOddsList(temp);
      } else {
        const temp = allOddsList.map(x => {
          if (x.lotteryType === _selectedLottery) {
            return ({
              ...x, [property]: commonValue !== '' ? (Number(x.parentSetting[property]) - Number(commonValue)) < 0 ? 0 : Number((Number(x.parentSetting[property]) - Number(commonValue)).toFixed(4)) : Number(x[property]), [`${property}singleChange`]: true, [`${property}multiChange`]: false
            });
          }
          return ({
            ...x
          });
        });
        setAllOddsList(temp);
      }
    }
  };

  const onClickReset = () => {
    setAllOddsList(rebateSetting.listSetting.map(x => ({
      ...x, oddsADefault: x.oddsA, oddsBDefault: x.oddsB, oddsCDefault: x.oddsC, oddsDDefault: x.oddsD
    })));
    setChangeAll(false);
    setCommonValue('');
    setReturnOption('');
  };

  const onClickSave = () => {
    const temp = allOddsList.filter(x => Object.keys(x).find(a => a.includes('singleChange')) || Object.keys(x).find(a => a.includes('multiChange')));
    const obj = {
      lotteryType: _selectedLottery,
      settingList: temp,
      orgId: data.memberId || data?.orgId,
      simultaneousSave,
      memberType: data?.orgId ? 2 : 1,
    };
    saveRebateSetting(obj, getRebateSettingData);
  };

  const quickSetValueChange = (key, property, value) => {
    const tempData = {
      ...quickDataSet[key]
    };
    tempData[property] = value > -1 ? value : tempData[property];
    setQuickData({
      ...quickDataSet, [key]: tempData
    });
  };

  const onClickChangeAll = (key, type) => {
    const { subCode } = quickDataSet[key];
    let changeStatus = {};

    if (type === 'limit') {
      changeStatus = {
        projectLimitmultiChange: true,
        itemLimitmultiChange: true
      };
    }
    if (type === 'return') {
      memberGame.forEach(x => {
        changeStatus[`${x.toLowerCase()}ReturnmultiChange`] = true;
      });
    }
    const _list = menuList.find(x => x.key === activeKey).children;
    const temp = allOddsList.map(x => {
      const checkAllFlag = !!_list.find(item => x.lotteryType === item.code);
      const singFlag = x.lotteryType === _selectedLottery;
      if (subCode.includes(x.code) && ((changAll && checkAllFlag) || (!changAll && singFlag))) {
        const tempData = {};
        if (type === 'return') {
          memberGame.forEach(item => {
            const property = `${item.toLowerCase()}Return`;
            tempData[property] = quickDataSet[key][property] > x.parentSetting[property] ? x.parentSetting[property] : quickDataSet[key][property];
          });

          return ({
            ...x, ...tempData, ...changeStatus
          });
        }
        return ({
          ...x,
          itemLimit: quickDataSet[key].itemLimit > x.parentSetting.itemLimit ? x.parentSetting.itemLimit : quickDataSet[key].itemLimit,
          projectLimit: quickDataSet[key].projectLimit > x.parentSetting.projectLimit ? x.parentSetting.projectLimit : quickDataSet[key].projectLimit,
          ...changeStatus
        });
      }
      return ({
        ...x
      });
    });
    setAllOddsList(temp);
  };

  return (
    <div className="LotteryReturnWrapper">
      <div className="operationSection mb-2">
        <div className="categorySelect">
          <Radio.Group onChange={({ target: { value: text } }) => setlotType(text)} value={lotType} className="squareCheckStyle">
            {
              _mainGames && _mainGames.map(item => <Radio value={item.value}>{item.displayName}</Radio>)
            }
          </Radio.Group>
        </div>
        <div className="dropdownArea">
          <div className="ms-1">赚取退水：</div>
          <Select
            className="dropDownList"
            value={_returnOption}
            placeholder="请选择"
            onChange={val => setReturnOption(val)}
          >
            {[<Option value="All">所有盘口</Option>].concat(memberGame.map(item => <Option value={item}>{`${item}盘`}</Option>))}
          </Select>
          <div className="mx-1">统一值：</div>
          <Input className="inputStyle" value={commonValue} onChange={({ target: { value: text } }) => setCommonValue(text > -1 ? text.trim() : commonValue)} />
          <div className="operationButton generate mx-1" onClick={() => onClickConfirm()}>{t('CONFIRM')}</div>
          <div className="operationButton handle mx-1" onClick={() => onClickSave()}>{t('SAVE')}</div>
          <div className="operationButton find mx-1" onClick={() => onClickReset()}>{t('RESET')}</div>
          <div className="ms-4">
            <div>保存时下线是否等量增加：</div>
            <Radio.Group onChange={({ target: { value: text } }) => setsimultaneousSave(text)} value={simultaneousSave}>
              <Radio value={1}>不增加</Radio>
              <Radio value={2}>只代理</Radio>
              <Radio value={3}>代理和会员</Radio>
            </Radio.Group>
          </div>
        </div>
      </div>
      <div className="quickSettingSection my-3">
        <AgentDataTable
          isQuerying={_isQuerying}
          tableHeaderTitle={
            [{
              label: '种类',
            },
            ...memberGame.map(x => ({
              label: (
                <div>
                  {x}
                  盘（%）
                </div>
              )
            })),
            {
              label: '退水操作',
            },
            {
              label: '注单限额',
            }, {
              label: '单期限额',
            }, {
              label: '限额操作',
            }]
          }
          tableData={quickDataSet && memberGame ? Object.values(quickDataSet) : []}
          tableHeaderStyle={
            ['firstCellStyle']
          }
          tableBodyRowProperty={
            [
              data => (
                <Label className="categoryWrapper">
                  <div>{data.label}</div>
                </Label>
              ),
              ...memberGame.map(x => data => {
                const { [`${x.toLowerCase()}Return`]: oddsReturn } = data;
                const { value } = data;
                return (
                  <Label className="pankouContentWarpper">
                    <div>
                      <Input value={oddsReturn || 0} onChange={({ target: { value: text } }) => quickSetValueChange(value, `${x.toLowerCase()}Return`, text > -1 ? text.trim() : oddsReturn || 0)} />
                    </div>
                  </Label>
                );
              }),
              data => (
                <Label
                  className="positiveValue urlNoUnderline"
                  onClick={() => {
                    onClickChangeAll(data.value, 'return');
                  }}
                >
                  修改
                </Label>
              ),
              data => <Label className="d-flex justify-content-center"><Input value={data?.itemLimit || 0} onChange={({ target: { value: text } }) => quickSetValueChange(data.value, 'itemLimit', Number(text > -1 ? text || 0 : data?.itemLimit || 0))} /></Label>,
              data => <Label className="d-flex justify-content-center"><Input value={data?.projectLimit || 0} onChange={({ target: { value: text } }) => quickSetValueChange(data.value, 'projectLimit', Number(text > -1 ? text || 0 : data?.projectLimit || 0))} /></Label>,
              data => (
                <Label
                  className="positiveValue urlNoUnderline"
                  onClick={() => {
                    onClickChangeAll(data.value, 'limit');
                  }}
                >
                  修改
                </Label>
              )]
          }
        />
      </div>
      <div className="selectSection my-2">
        <div className="d-flex">
          {
            menuList && menuList.map(item => <div className={`templateStyle ${item.key === activeKey && 'selected'}`} onClick={() => setActiveKey(item.key)}>{item.label}</div>)
          }
        </div>
        <div>
          <Radio.Group value={_selectedLottery} onChange={({ target: { value: text } }) => setSelectedLottery(text)} className="squareCheckStyle">
            {menuList && menuList.find(x => x.key === activeKey) && menuList.find(x => x.key === activeKey)?.children.map(item => <Radio value={item.code}>{item.lotteryName}</Radio>)}
          </Radio.Group>
          <Checkbox className="ms-5" checked={changAll} onChange={({ target: { checked: val } }) => setChangeAll(val)}>统一设置</Checkbox>
        </div>
      </div>
      <div className="contentSection">
        <AgentDataTable
          isQuerying={_isQuerying}
          tableHeaderTitle={
            [{
              label: '种类',
            },
            ...memberGame.map(x => ({
              label: (
                <div>
                  {x}
                  盘（%）
                </div>
              )
            })), {
              label: '注单限额',
            }, {
              label: '单期限额',
            }]
          }
          tableHeaderStyle={
            ['firstCellStyle']
          }
          tableData={oddsList && oddsList.length > 0 && memberGame ? oddsList : []}
          tableBodyRowProperty={
            [
              data => (
                <Label className="categoryWrapper">
                  <div>{data.projectName}</div>
                </Label>
              ),
              ...memberGame.map(x => data => {
                const { [`${x.toLowerCase()}Return`]: oddsReturn } = data || {};
                const { code } = data || {};
                const { parentSetting: { [`${x.toLowerCase()}Return`]: parentReturn } = {} } = data || {};
                const { [`${x.toLowerCase()}ReturnsingleChange`]: singleChange } = data || {};
                const { [`${x.toLowerCase()}ReturnmultiChange`]: multiChange } = data || {};
                return (
                  <Label className="pankouContentWarpper">
                    <div>
                      <Input
                        className={`${singleChange && 'singleChange'} ${multiChange && 'multiChange'}`}
                        value={oddsReturn || 0}
                        onChange={({ target: { value: text } }) => onValueChange(text > -1 ? text || 0 : oddsReturn, code, `${x.toLowerCase()}Return`)}
                      />
                      <div>
                        (
                        {parentReturn || ''}
                        )
                      </div>
                    </div>
                  </Label>
                );
              }),
              data => (
                <Label className="pankouContentWarpper">
                  <div>
                    <Input className={`${data?.itemLimitsingleChange && 'singleChange'} ${data?.itemLimitmultiChange && 'multiChange'}`} value={data.itemLimit} onChange={({ target: { value: text } }) => onValueChange(Number(text > -1 ? text || 0 : data.itemLimit), data.code, 'itemLimit')} />
                    <div>
                      (
                      {data.parentSetting?.itemLimit}
                      )
                    </div>
                  </div>
                </Label>
              ),
              data => (
                <Label className="pankouContentWarpper">
                  <div>
                    <Input className={`${data?.projectLimitsingleChange && 'singleChange'} ${data?.projectLimitmultiChange && 'multiChange'}`} value={data.projectLimit} onChange={({ target: { value: text } }) => onValueChange(Number(text > -1 ? text || 0 : data.projectLimit), data.code, 'projectLimit')} />
                    <div>
                      (
                      {data.parentSetting?.projectLimit}
                      )
                    </div>
                  </div>
                </Label>
              )
            ]
          }
        />
        <div className="d-flex justify-content-center align-items-center py-3 w-100">
          <div className="operationButton handle mx-2" onClick={() => onClickSave()}>{t('SAVE')}</div>
          <div className="operationButton find mx-2" onClick={() => onClickReset()}>{t('RESET')}</div>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LotteryReturn);