import { useEffect, useState } from 'react';

import { Checkbox, DatePicker } from 'antd';
import { MdArrowBack } from 'react-icons/md';
import { connect } from 'react-redux';

import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { backupActions, unusualBetActions } from '../../../redux/actions';
import AbnormalBetQuery from '../../BetWarning/AbnormalBetQuery';
import { CommonHeader } from '../..';
import { exportCSV } from '../../../constants/utils';

import './styles.scss';

const { RangePicker } = DatePicker;

const mapStateToProps = state => ({
  activeLottery: state.commonReducers.activeLottery,
  backUpInfo: state.backupReducers.backUpInfo,
  lotteryList: state.commonReducers.lotteryList,
});

const mapDispatchToProps = dispatch => ({
  addBackUpTask: (data, callback) => dispatch(backupActions.addBackUpTask(data, callback)),
  GetUnusualBetDetail: calllback => dispatch(unusualBetActions.GetUnusualBetDetail(calllback)),
  GetExportBetsList: (str, calllback) => dispatch(unusualBetActions.GetExportBetsList(str, calllback)),
});

const UnusualBetExport = ({ GetUnusualBetDetail, GetExportBetsList, lotteryList }) => {
  const { t } = useTranslation();
  const setIsQuerying = useState(false)[1];

  const [data, setData] = useState({});
  const [checkedKeys, setCheckedKeys] = useState(new Set());
  const [checkAll, setCheckAll] = useState(false);
  const [tableList, setTableList] = useState([]);
  const [_pageFlag, setPageFlag] = useState(0);
  const [_date, setDate] = useState([moment().startOf('day'), moment().startOf('day')]); // filter date
  const [dates, setDates] = useState(null);
  const [hackValue, setHackValue] = useState(null);
  const [agentList, setAgentList] = useState([]);

  const disabledDate = current => {
    if (!dates) {
      return false;
    }
    const diff = moment(current).add(3, 'month').diff(current, 'days'); // 计算从当前起三个月 是多少天
    const tooLate = dates[0] && current.diff(dates[0], 'days') > diff;
    const tooEarly = dates[1] && dates[1].diff(current, 'days') > diff;
    const shouldBeforeToday = current && current > moment().endOf('day');
    return !!tooEarly || !!tooLate || !!shouldBeforeToday;
  };

  const onOpenChange = open => {
    if (open) {
      setHackValue([null, null]);
      setDates([null, null]);
    } else {
      setHackValue(null);
    }
  };

  const getData = () => {
    setIsQuerying(true);
    GetUnusualBetDetail(data => {
      setData(data);
      setIsQuerying(false);
      const tmp = ((data && data.agentList) || []).map(i => ({
        ...i,
        checked: true
      }));
      setAgentList(tmp);
      setCheckedKeys(new Set(tmp.map(i => i.agentId)));
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const tableLotteryList = (data && data.lotteryList) || [];


  const exportFile = () => {
    const startDate = moment(_date[0]).startOf('day').unix();
    const endDate = moment(_date[1]).endOf('day').unix();
    const agList = [...new Set(checkedKeys)];
    const agListStr = agList.map(item => `agList=${item}`).join('&');
    const lotterys = tableList.reduce((acc, item) => {
      const { list = [] } = item;
      const checked = list.filter(i => i.checked).map(i => i.code);
      acc = [...acc, ...checked];
      return acc;
    }, []);
    const lotterysStr = lotterys.map(item => `lotterys=${item}`).join('&');
    const url = `startDate=${startDate}&endDate=${endDate}&${agListStr}&${lotterysStr}`;
    GetExportBetsList(url, res => {
      const names = res.map(i => i.name);
      const columns = ['特码/会员'].concat(names);
      const data = res.reduce((acc, item, index) => {
        if (index === 0) {
          item.list.forEach((_, i) => {
            acc[i] = {
              '特码/会员': `\t${(i + 1).toString().padStart(2, '0')}`
            };
          });
          acc[item.list.length] = {
            '特码/会员': '合计'
          };
        }
        item.list.forEach((_, ii) => {
          acc[ii][item.name] = item.list[ii];
        });
        acc[item.list.length][item.name] = item.totalSum;

        return acc;
      }, []);
      const fileName = `unNormalBets${moment().format('YYMMDD')}`;
      exportCSV({
        data,
        fileName,
        columns,
      }).save();
    });
  };

  useEffect(() => {
    const temp = new Map();
    lotteryList.forEach(item => {
      if (temp.has(item.categoryName)) {
        const _tempArr = [...temp.get(item.categoryName), item];
        temp.set(item.categoryName, _tempArr);
      } else {
        temp.set(item.categoryName, [item]);
      }
    });
    const doms = [];
    temp.forEach((array, key) => {
      doms.push(
        {
          categoryName: key,
          list: array
            .filter(i => tableLotteryList.find(it => it.id === i.id))
            .map(i => ({
              ...i, checked: true
            }))
        }
      );
    });
    setTableList(doms.filter(i => i.list && i.list.length));
  }, [lotteryList, data.lotteryList]);

  return (
    <div className="UnusualBetExportWrapper">
      <CommonHeader
        headerName={(
          <div className="d-flex align-items-center">
            {_pageFlag !== 0 && (
              <div className="d-flex align-items-center me-2 backIcon" onClick={() => setPageFlag(0)}>
                <MdArrowBack className="h5 mb-0 text-white" />
                {t('BACK_PAGE')}
              </div>
            )}
            {`${t('ABNORMAL_BET_EXPORT')}`}
          </div>
        )}
        rightLocationItem={() => _pageFlag === 0 && (
          <div className="d-flex align-items-center justify-content-center">
            <RangePicker
              allowClear={false}
              separator={<span>{` ${t('TO')} `}</span>}
              value={hackValue || _date}
              onChange={date => setDate(date)}
              disabledDate={disabledDate}
              onCalendarChange={val => setDates(val)}
              onOpenChange={onOpenChange}
            />
            <div
              className="marginLeft5 operationBtn cancel"
              onClick={() => setPageFlag(1)}
            >
              {t('BET_TO_UNUSUAL')}
            </div>
          </div>
        )}
      />

      {
        _pageFlag === 0 && (
          <>
            <div className="my-3 thirdPartyGamesTable">
              <div className="thirdPartyGamesTitle">{t('LOTTERY_SELECT')}</div>
              {
                tableList.map((lottery, index) => {
                  const { categoryName, list = [], } = lottery;
                  return (
                    <div className="thirdPartyGamesRow">
                      <div>
                        {categoryName}
                        <Checkbox
                          className="marginLeft5"
                          onChange={e => {
                            const { checked } = e.target;
                            setTableList([
                              ...tableList.slice(0, index),
                              {
                                categoryName,
                                list: list.map(i => ({
                                  ...i, checked
                                })),
                              },
                              ...tableList.slice(index + 1),
                            ]);
                          }}
                        >
                          {t('SUOYOU')}
                        </Checkbox>
                      </div>
                      <div>
                        {
                          list.map((item, listIndex) => {
                            const { lotteryName } = item;
                            return (
                              <Checkbox
                                checked={item.checked}
                                onChange={e => {
                                  const { checked } = e.target;
                                  setTableList([
                                    ...tableList.slice(0, index),
                                    {
                                      categoryName,
                                      list: list.map((i, li) => ({
                                        ...i, checked: li === listIndex ? checked : i.checked
                                      })),
                                    },
                                    ...tableList.slice(index + 1),
                                  ]);
                                }}
                              >
                                {
                                  lotteryName
                                }
                              </Checkbox>
                            );
                          })
                        }
                      </div>
                    </div>
                  );
                })
              }
            </div>

            <div className="my-3 thirdPartyGamesTable">
              <div className="thirdPartyGamesTitle">
                {t('AGENT_SELECT')}
                <Checkbox
                  className="marginLeft5"
                  checked={checkAll}
                  onChange={e => {
                    const { checked } = e.target;
                    if (checked) {
                      setCheckedKeys(new Set(agentList.map(i => i.agentId)));
                    } else {
                      setCheckedKeys(new Set());
                    }
                    setCheckAll(checked);
                  }}
                >
                  {t('SUOYOU')}
                </Checkbox>
              </div>
              {
                agentList.map(item => {
                  const { loginId, customerId } = item;
                  return (
                    <div className="thirdPartyGamesRow">
                      <div>
                        {loginId}
                        【
                        {customerId}
                        】
                      </div>
                      <div>
                        <Checkbox
                          onChange={e => {
                            const { checked: ck } = e.target;
                            const copySet = new Set();
                            checkedKeys.forEach(value => {
                              copySet.add(value);
                            });
                            if (!ck) {
                              copySet.delete(item.agentId);
                            } else {
                              copySet.add(item.agentId);
                            }
                            setCheckedKeys(copySet);
                            if (copySet.size < checkedKeys.size) {
                              setCheckAll(false);
                            }
                          }}
                          checked={checkedKeys.has(item.agentId)}
                        />
                      </div>
                    </div>
                  );
                })
              }
            </div>

            <div className="thirdPartyGamesFooter">
              <div className="operationBtn editAndSave me-3" onClick={() => exportFile()}>{t('EXPORT')}</div>
              <div className="operationBtn cancel">{t('CANCEL')}</div>
            </div>
          </>
        )
      }

      {
        _pageFlag === 1 && (
          <AbnormalBetQuery showNotify={false} />
        )
      }

    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UnusualBetExport);