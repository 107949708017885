import { useEffect, useRef, useState } from 'react';

import Marquee from 'react-fast-marquee';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { appActions, noticeActions, onlineStatisticsActions } from '../../../redux/actions';
import Label from '../../../components/Label';
import './styles.scss';


const mapStateToProps = state => ({
  latestAnnouncement: state.noticeReducers.latestAnnouncement,
  userData: state.memberReducers.userData,
});

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  GetLatestAnnouncement: () => dispatch(noticeActions.GetLatestAnnouncement()),
  GetOnlineUserCount: () => dispatch(onlineStatisticsActions.GetOnlineUserCount()),
  GetNotification: () => dispatch(noticeActions.GetNotification()),
});

const AuthFooter = ({ latestAnnouncement, GetLatestAnnouncement, GetNotification, GetOnlineUserCount, userData, updateModalAlert }) => {
  const [_runningNews, setRunningNews] = useState([]);

  const [_popupNews, setPopupNews] = useState([]);

  const { t } = useTranslation();

  const savedCallback = useRef();

  useEffect(() => {
    if (latestAnnouncement && latestAnnouncement?.length > 0) {
      setRunningNews(latestAnnouncement.filter(x => x.popup !== 1));

      const _tempPopup = latestAnnouncement.filter(x => x.popup !== 0);

      if (JSON.stringify(_tempPopup) !== JSON.stringify(_popupNews) && _tempPopup?.length > 0) {
        updateModalAlert({
          visible: true,
          type: 'latestAnnouncement',
          data: _tempPopup
        });
      }
      setPopupNews(_tempPopup);
    }
  }, [latestAnnouncement]);

  useEffect(() => {
    savedCallback.current = () => {
      GetLatestAnnouncement();
      GetNotification();
      if (userData && userData?.levelValue === 1) {
        GetOnlineUserCount();
      }
    };
  });

  useEffect(() => {
    savedCallback.current();
    const tick = () => {
      savedCallback.current();
    };
    const id = setInterval(tick, 10000);
    return () => {
      clearInterval(id);
    };
  }, []);

  return (
    <div className="authFooter">
      <Marquee pauseOnHover speed={40} className="paoMaDeng">
        {
          _runningNews.map(msg => <div key={msg?.id}>{msg?.content}</div>)
        }
      </Marquee>
      <div className="footerMore">
        <Label
          className="moreButton"
          value={t('MORE')}
          onClick={() => updateModalAlert({
            visible: true,
            type: 'latestAnnouncement',
          })}
        />
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthFooter);