import { useEffect, useState } from 'react';

import { Checkbox, DatePicker, Input, Radio } from 'antd';
import { Col, Label, Row } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { appActions, oddsManageActions } from '../../../redux/actions';
import { AgentDataTable } from '../..';
import Pagination from '../../Content/Pagination';

import './styles.scss';

const mapStateToProps = state => ({
  pankouData: state.oddsManageReducers.pankouData,
  activeLottery: state.commonReducers.activeLottery,
});

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  GetInfo: (obj, callback) => dispatch(oddsManageActions.GetInfo(obj, callback)),
  GetNextLotteryInstallNo: (obj, callback) => dispatch(oddsManageActions.GetNextLotteryInstallNo(obj, callback)),
  SaveOdds: (obj, callback) => dispatch(oddsManageActions.SaveOdds(obj, callback)),
  SaveOddsAutoOpen: (obj, callback) => dispatch(oddsManageActions.SaveOddsAutoOpen(obj, callback)),
  SaveOddsAutoResult: (obj, callback) => dispatch(oddsManageActions.SaveOddsAutoResult(obj, callback)),
  SaveOddsCurrPk: (obj, callback) => dispatch(oddsManageActions.SaveOddsCurrPk(obj, callback)),
  CancelInstallments: (obj, callback) => dispatch(oddsManageActions.CancelInstallments(obj, callback)),
  SaveAllOddsAutoOpen: (obj, callback) => dispatch(oddsManageActions.SaveAllOddsAutoOpen(obj, callback)),
  SaveAllOddsAutoResult: (obj, callback) => dispatch(oddsManageActions.SaveAllOddsAutoResult(obj, callback)),
  BatchCancelInstallments: (obj, callback) => dispatch(oddsManageActions.BatchCancelInstallments(obj, callback)),
  RestartService: callback => dispatch(oddsManageActions.RestartService(callback)),
  GetOddsCurrAndLotteryHis: (id, type, callback) => dispatch(oddsManageActions.GetOddsCurrAndLotteryHis(id, type, callback)),
  SaveLottery: (obj, callback, lastOneNumber) => dispatch(oddsManageActions.SaveLottery(obj, callback, lastOneNumber)),
});

const { RangePicker } = DatePicker;

const InstallmentManagement = ({
  GetOddsCurrAndLotteryHis,
  SaveLottery,
  RestartService,
  BatchCancelInstallments,
  SaveAllOddsAutoOpen,
  SaveAllOddsAutoResult,
  CancelInstallments,
  SaveOddsCurrPk,
  SaveOddsAutoOpen,
  SaveOddsAutoResult,
  pankouData,
  GetInfo,
  activeLottery,
  GetNextLotteryInstallNo,
  SaveOdds,
}) => {
  const { t } = useTranslation();
  const [_date, setDate] = useState([
    moment().subtract(1, 'weeks').startOf('day').toDate(),
    moment().endOf('day').toDate(),
  ]);

  const [_isQueryingDetail, setIsQueryingDetail] = useState(false);
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(15);
  const [_list, setList] = useState();
  const [_checkAll, setCheckAll] = useState(false);

  const [installments, setInstallNo] = useState();

  const [lotteryTime, setlotteryTime] = useState(
    moment().endOf('day').subtract(10, 'minute').toDate()
  );
  const [openTime, setopenTime] = useState(
    moment().endOf('day').subtract(23399, 'seconds').toDate()
  );
  const [closeTime, setcloseTime] = useState(
    moment().endOf('day').subtract(8999, 'seconds').toDate()
  );
  const [temaCloseTime, settemaCloseTime] = useState(
    moment().endOf('day').subtract(8999, 'seconds').toDate()
  );
  const [noneTemaCloseTime, setnoneTemaCloseTime] = useState(
    moment().endOf('day').subtract(8999, 'seconds').toDate()
  );
  const [isClose, setisClose] = useState(1);
  const [autoOpen, setautoOpen] = useState(1);
  const [noneTemaCloseTimeState, setnoneTemaCloseTimeState] = useState(1);
  const [temaCloseTimeState, settemaCloseTimeState] = useState(1);

  const [res, setRes] = useState();

  useEffect(() => {
    if (activeLottery) {
      setIsQueryingDetail(true);
      const obj = {
        startTime: moment(_date[0]).unix(),
        endTime: moment(_date[1]).unix(),
        lotteryType: activeLottery.code,
        page,
        rows,
      };
      GetInfo(obj, () => setIsQueryingDetail(false));
    }
  }, [activeLottery.code]);

  useEffect(() => {
    if (
      activeLottery.templateCode === 'HK6'
      && activeLottery.code !== 'JSHK6'
      && pankouData
    ) {
      const len = pankouData.lottery?.openResult
        ? pankouData.lottery.openResult.split(',').filter(x => x !== '')
          .length
        : 0;
      const ls = [
        'ping1',
        'ping2',
        'ping3',
        'ping4',
        'ping5',
        'ping6',
        'tema',
      ].slice(0, len + 1);
      const _res = {};
      ls.forEach(item => {
        _res[item] = pankouData.lottery[item] || '';
      });
      setRes(_res);
    } else {
      setRes();
    }
  }, [activeLottery, pankouData]);

  useEffect(() => {
    if (
      (activeLottery.templateCode === 'HK6'
        && activeLottery.code !== 'JSHK6')
      || activeLottery.templateCode === 'F36X7'
      || activeLottery.templateCode === 'F31X7'
      || activeLottery.templateCode === 'QXC'
      || activeLottery.templateCode === 'PL3'
      || activeLottery.templateCode === 'PL5'
      || activeLottery.templateCode === 'VNDLT'
    ) {
      if (pankouData && pankouData.data && pankouData.data.length > 0) {
        setInstallNo(pankouData.data[0].installments);
        setlotteryTime(moment.unix(pankouData.data[0].lotteryTime).toDate());
        setopenTime(moment.unix(pankouData.data[0].openTime).toDate());
        setcloseTime(moment.unix(pankouData.data[0].closeTime).toDate());
        settemaCloseTime(
          moment.unix(pankouData.data[0].temaCloseTime).toDate()
        );
        setnoneTemaCloseTime(
          moment.unix(pankouData.data[0].noneTemaCloseTime).toDate()
        );
        setisClose(pankouData.data[0]?.isClose ?? 1);
        setautoOpen(pankouData.data[0]?.autoOpen ?? 1);
        setnoneTemaCloseTimeState(
          pankouData.data[0]?.noneTemaCloseTimeState ?? 1
        );
        settemaCloseTimeState(pankouData.data[0]?.temaCloseTimeState ?? 1);
      } else {
        setInstallNo();
        setlotteryTime(
          moment().endOf('day').subtract(8999, 'seconds').toDate()
        );
        setopenTime(moment().endOf('day').subtract(23399, 'seconds').toDate());
        setcloseTime(moment().endOf('day').subtract(8999, 'seconds').toDate());
        settemaCloseTime(
          moment().endOf('day').subtract(8999, 'seconds').toDate()
        );
        setnoneTemaCloseTime(
          moment().endOf('day').subtract(8999, 'seconds').toDate()
        );
        setisClose(1);
        setautoOpen(1);
        setnoneTemaCloseTimeState(1);
        settemaCloseTimeState(1);
      }
    }
  }, [pankouData?.data, activeLottery.code]);

  useEffect(() => {
    if (_checkAll && pankouData) {
      const _ls = pankouData.data.map(item => item.id);
      setList([..._ls]);
    } else {
      setList([]);
    }
  }, [_checkAll, pankouData]);

  const onSearchClick = () => {
    setIsQueryingDetail(true);
    const obj = {
      startTime: moment(_date[0]).unix(),
      endTime: moment(_date[1]).unix(),
      lotteryType: activeLottery.code,
      page,
      rows,
    };
    GetInfo(obj, () => setIsQueryingDetail(false));
  };

  const onPaginationClick = (pageNum, rows) => {
    setIsQueryingDetail(true);
    setPage(pageNum);
    setRows(rows);
    const _obj = {
      startTime: moment(_date[0]).unix(),
      endTime: moment(_date[1]).unix(),
      lotteryType: activeLottery.code,
      page,
      rows,
    };
    GetInfo(_obj, () => setIsQueryingDetail(false));
  };

  const pankouSave = () => {
    if (pankouData.data) {
      const _obj = {
        id: pankouData.data[0] ? pankouData.data[0]?.id : '',
        installments,
        lotteryType: activeLottery.code,
        openTime: moment(openTime).unix(),
        closeTime: moment(closeTime).unix(),
        lotteryTime: moment(lotteryTime).unix(),
        temaCloseTime: moment(temaCloseTime).unix(),
        noneTemaCloseTime: moment(noneTemaCloseTime).unix(),

        kjStatus: pankouData.data[0] ? pankouData.data[0]?.kjStatus : '',
        noneTemaCloseTimeState,
        temaCloseTimeState,
        isClose,
        autoOpen,
      };
      SaveOdds(_obj, () => {
        GetInfo({
          startTime: moment(_date[0]).unix(),
          endTime: moment(_date[1]).unix(),
          lotteryType: activeLottery.code,
          page,
          rows,
        });
      });
    }
  };

  const saveAutoOpen = data => {
    SaveOddsAutoOpen(data.id, () => {
      const obj = {
        startTime: moment(_date[0]).unix(),
        endTime: moment(_date[1]).unix(),
        lotteryType: activeLottery.code,
        page,
        rows,
      };
      GetInfo(obj, () => setIsQueryingDetail(false));
    });
  };

  const saveAutoResult = data => {
    SaveOddsAutoResult(data.id, () => {
      const obj = {
        startTime: moment(_date[0]).unix(),
        endTime: moment(_date[1]).unix(),
        lotteryType: activeLottery.code,
        page,
        rows,
      };
      GetInfo(obj, () => setIsQueryingDetail(false));
    });
  };

  const saveCurrPK = data => {
    SaveOddsCurrPk(data.id, () => {
      const obj = {
        startTime: moment(_date[0]).unix(),
        endTime: moment(_date[1]).unix(),
        lotteryType: activeLottery.code,
        page,
        rows,
      };
      GetInfo(obj, () => setIsQueryingDetail(false));
    });
  };

  const cancelInstallemnt = data => {
    CancelInstallments(data.id, () => {
      const obj = {
        startTime: moment(_date[0]).unix(),
        endTime: moment(_date[1]).unix(),
        lotteryType: activeLottery.code,
        page,
        rows,
      };
      GetInfo(obj, () => setIsQueryingDetail(false));
    });
  };

  const batchOpenAndClose = (type, status) => {
    const obj = {
      startTime: moment(_date[0]).unix(),
      endTime: moment(_date[1]).unix(),
      lotteryType: activeLottery.code,
      page,
      rows,
    };
    if (type === 'pan') {
      SaveAllOddsAutoOpen(
        {
          lotteryType: activeLottery.code,
          status,
        },
        () => {
          GetInfo(obj, () => setIsQueryingDetail(false));
        }
      );
    }
    if (type === 'result') {
      SaveAllOddsAutoResult(
        {
          lotteryType: activeLottery.code,
          status,
        },
        () => {
          GetInfo(obj, () => setIsQueryingDetail(false));
        }
      );
    }
  };

  const batchCancel = () => {
    const obj = {
      startTime: moment(_date[0]).unix(),
      endTime: moment(_date[1]).unix(),
      lotteryType: activeLottery.code,
      page,
      rows,
    };
    BatchCancelInstallments(_list, () => {
      GetInfo(obj, () => setIsQueryingDetail(false));
    });
  };

  const restartSvc = () => {
    const obj = {
      startTime: moment(_date[0]).unix(),
      endTime: moment(_date[1]).unix(),
      lotteryType: activeLottery.code,
      page,
      rows,
    };
    RestartService(() => {
      GetInfo(obj, () => setIsQueryingDetail(false));
    });
  };

  const pankouHandle = (id, type, original) => {
    GetOddsCurrAndLotteryHis(id, type, data => {
      if (type === 'time') {
        SaveOdds(data, () => GetInfo({
          startTime: moment(_date[0]).unix(),
          endTime: moment(_date[1]).unix(),
          lotteryType: activeLottery.code,
          page,
          rows,
        }));
      } else {
        data.lottery.qishuDate = original.qishuDate;
        SaveLottery(data, () => GetInfo({
          startTime: moment(_date[0]).unix(),
          endTime: moment(_date[1]).unix(),
          lotteryType: activeLottery.code,
          page,
          rows,
        }));
      }
    });
  };

  const updateResNumber = (text, index) => {
    const temp = {
      ...res,
      [index]: text.trim() === '' ? '' : text > -1 ? Number(text) : temp[index],
    };
    setRes(temp);
  };

  const drawLottery = () => {
    let _obj;
    if (
      activeLottery.templateCode === 'F36X7'
      || activeLottery.templateCode === 'F31X7'
      || activeLottery.templateCode === 'QXC'
      || activeLottery.templateCode === 'PL3'
      || activeLottery.templateCode === 'PL5'
      || activeLottery.templateCode === 'VNDLT'
    ) {
      _obj = {
        OddsMngId: pankouData.data[0]?.id,
        lottery: {
          ...pankouData.data[0],
          openResult: res,
        },
      };
    } else {
      _obj = {
        OddsMngId: `${activeLottery.code.toLowerCase()}_${installments}`,
        lottery: {
          ...pankouData.data[0],
          ...res,
        },
      };
    }

    const lastOneNumber = !!(res?.tema && res?.tema !== '');

    SaveLottery(
      _obj,
      () => {
        if (
          activeLottery.templateCode === 'F36X7'
          || activeLottery.templateCode === 'F31X7'
          || activeLottery.templateCode === 'QXC'
          || activeLottery.templateCode === 'PL3'
          || activeLottery.templateCode === 'PL5'
                || activeLottery.templateCode === 'VNDLT'
        ) {
          setRes();
        }
        GetInfo({
          startTime: moment(_date[0]).unix(),
          endTime: moment(_date[1]).unix(),
          lotteryType: activeLottery.code,
          page,
          rows,
        });
      },
      lastOneNumber
    );
  };

  return (
    <div className="InstallmentManagementWrapper">
      {(activeLottery.templateCode === 'HK6'
        && activeLottery.code !== 'JSHK6')
      || activeLottery.templateCode === 'F36X7'
      || activeLottery.templateCode === 'F31X7'
      || activeLottery.templateCode === 'QXC'
      || activeLottery.templateCode === 'PL3'
      || activeLottery.templateCode === 'PL5'
            || activeLottery.templateCode === 'VNDLT'
        ? (
          <div>
            <div className="orderInformationTable">
              <div className="orderInformationRowBackGroundColor d-flex justify-content-center align-items-center">
                开奖号码
              </div>
              <div className="evenRowStyle">
                <div>开奖球号</div>
                <div>
                  {activeLottery.templateCode === 'HK6'
                  && activeLottery.code !== 'JSHK6'
                  && res
                  && Object.entries(res).map(([key, item]) => (
                    <div className="me-3 my-1">
                      <div className="text-center">
                        {t(`${key.toUpperCase()}`)}
                      </div>
                      <div className="d-flex align-items-center justify-content-center">
                        <Input
                          className="resultNumCell"
                          value={item}
                          onChange={({ target: { value: text } }) => updateResNumber(text, key)}
                        />
                      </div>
                    </div>
                  ))}
                  {(activeLottery.templateCode === 'F36X7'
                  || activeLottery.templateCode === 'F31X7') && (
                  <>
                    <Input
                      className="w-50"
                      value={res}
                      placeholder="8个开奖号码且,分隔！"
                      onChange={({ target: { value: text } }) => setRes(text)}
                    />
                    <span className="ms-3">
                      如果还未到开奖，这里数据请保持为空！
                    </span>
                  </>
                  )}
                  {activeLottery.templateCode === 'QXC' && (
                  <>
                    <Input
                      className="w-50"
                      value={res}
                      placeholder="7个开奖号码且,分隔！"
                      onChange={({ target: { value: text } }) => setRes(text)}
                    />
                    <span className="ms-3">
                      如果还未到开奖，这里数据请保持为空！
                    </span>
                  </>
                  )}
                  {activeLottery.templateCode === 'PL3' && (
                  <>
                    <Input
                      className="w-50"
                      value={res}
                      placeholder="3个开奖号码且,分隔！"
                      onChange={({ target: { value: text } }) => setRes(text)}
                    />
                    <span className="ms-3">
                      如果还未到开奖，这里数据请保持为空！
                    </span>
                  </>
                  )}
                  {activeLottery.templateCode === 'PL5' && (
                  <>
                    <Input
                      className="w-50"
                      value={res}
                      placeholder="5个开奖号码且,分隔！"
                      onChange={({ target: { value: text } }) => setRes(text)}
                    />
                    <span className="ms-3">
                      如果还未到开奖，这里数据请保持为空！
                    </span>
                  </>
                  )}
                  {activeLottery.templateCode === 'VNDLT' && (
                  <>
                    <Input
                      className="w-50"
                      value={res}
                      placeholder="5个开奖号码且,分隔！"
                      onChange={({ target: { value: text } }) => setRes(text)}
                    />
                    <span className="ms-3">
                      如果还未到开奖，这里数据请保持为空！
                    </span>
                  </>
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center my-3">
              <div className="operationBtn" onClick={() => drawLottery()}>
                开奖
              </div>
            </div>
            <div className="orderInformationTable">
              <div className="orderInformationRowBackGroundColor d-flex justify-content-center align-items-center">
                开封盘设置
              </div>
              <div className="evenRowStyle">
                <div>期数</div>
                <div>
                  <Input
                    className="qishuInputStyle"
                    value={installments}
                    onChange={({ target: { value: text } }) => {
                      setInstallNo(text > -1 ? Number(text.trim()) : 0);
                    }}
                  />
                  {!(
                    pankouData
                  && pankouData.data
                  && pankouData.data.length > 0
                  ) && (
                  <div
                    className="operationBtn ms-2"
                    onClick={() => GetNextLotteryInstallNo(
                      {
                        lotteryType: activeLottery.code,
                      },
                      no => setInstallNo(no)
                    )}
                  >
                    取下一期
                  </div>
                  )}
                  <span className="text-danger ms-2">
                    *（正在开盘时不能修改！只能填写数字）
                  </span>
                </div>
              </div>
              <div className="evenRowStyle">
                <div>开奖时间</div>
                <div>
                  <DatePicker
                    value={moment(lotteryTime)}
                    showTime
                    onChange={_date => {
                      setlotteryTime(_date.toDate());
                    }}
                  />
                </div>
              </div>
              <div className="evenRowStyle">
                <div>总封盘</div>
                <div>
                  <div>
                    <Radio.Group
                      onChange={({ target: { value: code } }) => setisClose(code)}
                      value={isClose}
                    >
                      <Radio value={0}>开盘</Radio>
                      <Radio value={1}>封盘</Radio>
                    </Radio.Group>
                  </div>
                  {isClose === 1 && (
                  <span className="text-danger ms-3">封盘中...... </span>
                  )}
                </div>
              </div>
              <div className="evenRowStyle">
                <div>总自动开盘时间</div>
                <div>
                  <DatePicker
                    value={moment(openTime)}
                    showTime
                    onChange={_date => {
                      setopenTime(_date.toDate());
                    }}
                  />
                </div>
              </div>
              <div className="evenRowStyle">
                <div>总自动封盘时间</div>
                <div>
                  <DatePicker
                    className="betQueryDatepicker"
                    value={moment(closeTime)}
                    showTime
                    onChange={_date => {
                      setcloseTime(_date.toDate());
                    }}
                  />
                </div>
              </div>
              <div className="evenRowStyle">
                <div>是否允许自动开/封盘</div>
                <div>
                  <div>
                    <Radio.Group
                      onChange={({ target: { value: code } }) => setautoOpen(code)}
                      value={autoOpen}
                    >
                      <Radio value={1}>是</Radio>
                      <Radio value={0}>否</Radio>
                    </Radio.Group>
                  </div>
                </div>
              </div>
              {!(
                activeLottery.templateCode === 'QXC'
              || activeLottery.templateCode === 'PL3'
              || activeLottery.templateCode === 'PL5'
                    || activeLottery.templateCode === 'VNDLT'
              ) && (
              <>
                <div className="evenRowStyle">
                  <div>特码类封盘时间</div>
                  <div>
                    <DatePicker
                      value={moment(temaCloseTime)}
                      showTime
                      onChange={_date => {
                        settemaCloseTime(_date.toDate());
                      }}
                    />
                    <div className="ms-3">
                      <Radio.Group
                        onChange={({ target: { value: code } }) => settemaCloseTimeState(code)}
                        value={temaCloseTimeState}
                      >
                        <Radio value={1}>开</Radio>
                        <Radio value={0}>关</Radio>
                      </Radio.Group>
                    </div>
                  </div>
                </div>
                <div className="evenRowStyle">
                  <div>非特码类封盘时间</div>
                  <div>
                    <DatePicker
                      value={moment(noneTemaCloseTime)}
                      showTime
                      onChange={_date => {
                        setnoneTemaCloseTime(_date.toDate());
                      }}
                    />
                    <div className="ms-3">
                      <Radio.Group
                        onChange={({ target: { value: code } }) => setnoneTemaCloseTimeState(code)}
                        value={noneTemaCloseTimeState}
                      >
                        <Radio value={1}>开</Radio>
                        <Radio value={0}>关</Radio>
                      </Radio.Group>
                    </div>
                  </div>
                </div>
              </>
              )}
            </div>
            <div className="d-flex justify-content-center align-items-center my-3">
              <div className="operationBtn" onClick={() => pankouSave()}>
                保存盘口
              </div>
            </div>
          </div>
        ) : (
          <>
            <Col className="p-2 mb-2 filterWrapper d-flex align-items-center w-100 rounded-top">
              <Label type="daterange" value={t('DATE')} className="px-1">
                显示时间
              </Label>
              <RangePicker
                allowClear={false}
                value={[moment(_date[0]), moment(_date[1])]}
                onChange={date => {
                  setDate([date[0].toDate(), date[1].toDate()]);
                }}
              />
              <div className="operationBtn find mx-1" onClick={onSearchClick}>
                {t('FIND')}
              </div>
              <div className="d-flex justify-content-end align-items-center flex-grow-1">
                <div
                  className="operationBtn mx-1"
                  onClick={() => batchOpenAndClose('pan', 0)}
                >
                  全部手动开盘
                </div>
                <div
                  className="operationBtn mx-1"
                  onClick={() => batchOpenAndClose('pan', 1)}
                >
                  全部自动开盘
                </div>
                <div
                  className="operationBtn mx-1"
                  onClick={() => batchOpenAndClose('result', 0)}
                >
                  全部手动开奖
                </div>
                <div
                  className="operationBtn mx-1"
                  onClick={() => batchOpenAndClose('result', 1)}
                >
                  全部自动开奖
                </div>
                <div className="operationBtn mx-1" onClick={() => batchCancel()}>
                  批量取消盘口
                </div>
                <div className="operationBtn mx-1" onClick={() => restartSvc()}>
                  重启接奖服务
                </div>
              </div>
            </Col>
            <Row className="mx-0 bg-white px-0 border-1">
              <AgentDataTable
                isQuerying={_isQueryingDetail}
                tableHeaderTitle={[
                  {
                    label: (
                      <Checkbox
                        onChange={({ target: { checked: text } }) => setCheckAll(text)}
                        checked={_checkAll}
                      >
                        全选
                      </Checkbox>
                    ),
                  },
                  {
                    label: '日序号',
                  },
                  {
                    label: '期数',
                  },
                  {
                    label: '开奖时间 ',
                  },
                  {
                    label: '开盘时间',
                  },
                  {
                    label: '自动开封盘',
                  },
                  {
                    label: '封盘时间',
                  },
                  {
                    label: '盘口状态',
                  },
                  {
                    label: '自动开奖',
                  },
                  {
                    label: '开奖结果',
                  },
                  {
                    label: '盘口时间设置',
                  },
                  {
                    label: '操作',
                  },
                ]}
                tableData={pankouData ? pankouData.data : []}
                tableBodyRowProperty={[
                  data => (
                    <Label className="d-flex justify-content-center">
                      <Checkbox
                        onChange={() => {
                          if (_list.includes(data.id)) {
                            setList(_list.filter(item => item !== data.id));
                          } else {
                            setList([..._list, data.id]);
                          }
                        }}
                        checked={_list.includes(data.id)}
                      />
                    </Label>
                  ),
                  data => (
                    <Label className="d-flex justify-content-center">
                      {data.serialNumber}
                    </Label>
                  ),
                  data => (
                    <Label className="d-flex justify-content-center">
                      {data.installments}
                    </Label>
                  ),
                  data => (
                    <Label className="d-flex justify-content-center">
                      {moment
                        .unix(data.lotteryTime)
                        .format('YYYY-MM-DD HH:mm:ss')}
                    </Label>
                  ),
                  data => (
                    <Label className="d-flex justify-content-center">
                      {moment.unix(data.openTime).format('YYYY-MM-DD HH:mm:ss')}
                    </Label>
                  ),
                  data => (
                    <Label className="d-flex justify-content-center mousePointIn">
                      <div
                        className={`statusBtn ${
                          data.autoOpen === 0 ? 'text-danger' : 'text-success'
                        }`}
                      >
                        {data.autoOpen === 0 ? '手动开盘' : '自动开盘'}
                      </div>
                      <div
                        className="shiftBtn"
                        onClick={() => saveAutoOpen(data)}
                      >
                        {data.autoOpen === 0 ? '自动开盘' : '手动开盘'}
                      </div>
                    </Label>
                  ),
                  data => (
                    <Label className="d-flex justify-content-center">
                      {moment.unix(data.closeTime).format('YYYY-MM-DD HH:mm:ss')}
                    </Label>
                  ),
                  data => (
                    <Label className="d-flex justify-content-center mousePointIn">
                      {data.openTime <= moment().unix()
                    && data.closeTime >= moment().unix()
                    && data.isClose !== 1 ? (
                      <>
                        <div className="statusBtn text-success">当前盘口</div>
                        <div
                          className="shiftBtn"
                          onClick={() => saveCurrPK(data)}
                        >
                          封盘
                        </div>
                      </>
                        ) : (data.lotteryTime >= moment().unix()
                        && data.closeTime <= moment().unix())
                      || data.isClose === 1 ? (
                        <>
                          <div className="statusBtn text-danger">已封盘</div>
                          <div
                            className="shiftBtn"
                            onClick={() => saveCurrPK(data)}
                          >
                            开盘
                          </div>
                        </>
                          ) : data.lotteryTime <= moment().unix() ? (
                            <>
                              <div className="statusBtn text-danger">待开奖</div>
                              <div
                                className="shiftBtn"
                                onClick={() => cancelInstallemnt(data)}
                              >
                                取消
                              </div>
                            </>
                          ) : (
                            '未开盘'
                          )}
                    </Label>
                  ),
                  data => (
                    <Label className="d-flex justify-content-center mousePointIn">
                      <div
                        className={`statusBtn ${
                          data.autoResult === 0 ? 'text-danger' : 'text-success'
                        }`}
                      >
                        {data.autoResult === 0 ? '手动开奖' : '自动开奖'}
                      </div>
                      <div
                        className="shiftBtn"
                        onClick={() => saveAutoResult(data)}
                      >
                        {data.autoResult === 0 ? '自动开奖' : '手动开奖'}
                      </div>
                    </Label>
                  ),
                  data => (
                    <Label className="d-flex justify-content-center">
                      <div
                        className="operationBtn"
                        onClick={() => pankouHandle(data.id, 'draw', data)}
                      >
                        开奖
                      </div>
                    </Label>
                  ),
                  data => (
                    <Label className="d-flex justify-content-center">
                      <div
                        className="operationBtn"
                        onClick={() => pankouHandle(data.id, 'time')}
                      >
                        设置
                      </div>
                    </Label>
                  ),
                  data => (
                    <Label className="d-flex justify-content-center">
                      <div
                        className={`operationBtn ${
                          data.kjStatus === 2 && 'find'
                        }`}
                        onClick={() => cancelInstallemnt(data)}
                      >
                        {data.kjStatus === 2 ? '恢复' : '取消'}
                      </div>
                    </Label>
                  ),
                ]}
              />
            </Row>
            <Row className="justify-center w-full relative">
              <Pagination
                totalRecord={pankouData ? pankouData.totalRecords || 0 : 0}
                onClick={onPaginationClick}
                isQuery={false}
              />
            </Row>
          </>
        )}
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InstallmentManagement);