import { useEffect, useRef, useState } from 'react';

import { Button, Col, Label, Row } from 'reactstrap';
import { FaSlidersH } from 'react-icons/fa';
import { connect } from 'react-redux';

import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { appActions, systemActions } from '../../../redux/actions';

import { CommonHeader } from '../..';

import AgentDataTable from '../../AgentDataTable';
import './styles.scss';

const mapStateToProps = state => ({
  lotteryItemsData: state.systemReducers.lotteryItemsData,
  userData: state.memberReducers.userData,
});

const mapDispatchToProps = dispatch => ({
  getLotteryItems: (orgId, callback) => dispatch(systemActions.getLotteryItems(orgId, callback)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  updateLotteryGameStatus: (obj, callback) => dispatch(systemActions.updateLotteryGameStatus(obj, callback)),
});

const LotteryManage = ({
  showHeader = true,
  callback = () => { },
  lotteryItemsData,
  getLotteryItems,
  data,
  userData,
  updateLotteryGameStatus,
  updateModalAlert,
}) => {
  const { t } = useTranslation();
  const [_info, setInfo] = useState([]);
  const [isQueryStatus, setQueryStatus] = useState(true);
  const countDownRef = useRef();
  const [_countDownTimer, setCountDownTimer] = useState(5);
  const [loading, setLoading] = useState(false);

  const intervalValue = 5; //

  useEffect(() => {
    getLotteryItems(data ? data.orgId : userData?.id);
  }, []);

  useEffect(() => {
    countDownRef.current = intervalValue;
    setCountDownTimer(intervalValue);

    const timer = setInterval(() => {
      countDownRef.current -= 1;
      setCountDownTimer(countDownRef.current);
      if (countDownRef.current === 0) {
        setLoading(true);
        getLotteryItems(data ? data.orgId : userData?.id, () => {
          setLoading(false);
          countDownRef.current = intervalValue;
          setCountDownTimer(countDownRef.current);
        });
      }
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (lotteryItemsData && lotteryItemsData?.lotteries) {
      const list = lotteryItemsData?.lotList ?? [];
      const temp = lotteryItemsData.lotteries.map(item => {
        const tempItem = list.find(x => x.code === item.code);
        if (tempItem) {
          return ({
            ...item, ...tempItem
          });
        }
        return ({
          ...item, isEditable: 1
        });
      });
      setInfo([].concat(temp));
      setQueryStatus(false);
    }
  }, [lotteryItemsData]);

  const saveButton = (item = {}, status) => {
    if (Object.keys(item).length <= 4) {
      return updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: '请在开盘时间段设置！',
          showCancelButton: false
        }
      });
    }
    updateModalAlert({
      type: 'loading',
      visible: true
    });
    const obj = {
      code: item.code,
      status,
      orgId: data ? data.orgId : userData?.id
    };
    updateLotteryGameStatus(obj, () => {
      getLotteryItems(data ? data.orgId : userData?.id);
    });
  };

  const tableHeader = [{
    label: `${t('LOTTERY')} code`,
  }, {
    label: t('NAME'),
  }, {
    label: t('CURRENT_INSTALLMENTS'),
  }, {
    label: t('BET_OPEN_TIME'),
  }, {
    label: t('SYSTEM_BET_OPEN_TIME'),
  }, {
    label: t('BET_CLOSE_TIME'),
  }, {
    label: t('SYSTEM_BET_CLOSE_TIME'),
  }, {
    label: t('TEMA_BET_CLOSE_TIME'),
  }, {
    label: t('NON_TEMA_BET_CLOSE_TIME'),
  }, {
    label: t('BET_RESULT_TIME'),
  }, {
    label: t('STATUS'),
  }, {
    label: t('PLATE_TYPE'),
  }, {
    label: t('FUNCTION'),
  }];

  const tableContent = [
    data => (<Label className="d-flex justify-content-center">{data?.code}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.name}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.installment || '无期数'}</Label>),
    data => (
      <div className="d-flex justify-content-center">
        <Label className="d-flex justify-content-center">
          {data?.openTime && moment.unix(data?.openTime).format('YYYY-MM-DD HH:mm:ss')}
        </Label>
        {data?.openSeconds > 0 && (
          <Label className="d-flex negativeValue mx-2">
            {` [${0 - (data?.openSeconds ?? 0)}s]`}
          </Label>
        )}
        {
          !data?.openTime && !data?.openSeconds && '-'
        }
      </div>
    ),
    data => (
      <Label className="d-flex justify-content-center">
        {
          (data?.openTimeSys && moment.unix(data?.openTimeSys).format('YYYY-MM-DD HH:mm:ss')) || '-'
        }
      </Label>
    ),
    data => (
      <div className="d-flex justify-content-center">
        <Label className="d-flex justify-content-center">
          {data?.closeTime && moment.unix(data?.closeTime).format('YYYY-MM-DD HH:mm:ss')}
        </Label>
        {data?.closeSeconds > 0 && (
          <Label className="d-flex negativeValue mx-2">
            {` [${0 - (data?.closeSeconds ?? 0)}s]`}
          </Label>
        )}
        {
          !data?.closeTime && !data?.closeSeconds && '-'
        }
      </div>
    ),
    data => (
      <Label className="d-flex justify-content-center">
        {
          (data?.closeTimeSys && moment.unix(data?.closeTimeSys).format('YYYY-MM-DD HH:mm:ss')) || '-'
        }
      </Label>
    ),
    data => (
      <div className="d-flex justify-content-center">
        <Label className="d-flex justify-content-center">
          {data?.temaCloseTime && moment.unix(data?.temaCloseTime).format('YYYY-MM-DD HH:mm:ss')}
        </Label>
        {data?.temaBeforeCloseTime > 0 && (
          <Label className="d-flex negativeValue mx-2">
            {` [${0 - (data?.temaBeforeCloseTime ?? 0)}s]`}
          </Label>
        )}
        {
          !data?.temaCloseTime && !data?.temaBeforeCloseTime && '-'
        }
      </div>
    ),
    data => (
      <div className="d-flex justify-content-center">
        <Label className="d-flex justify-content-center">
          {data?.notTemaCloseTime && moment.unix(data?.notTemaCloseTime).format('YYYY-MM-DD HH:mm:ss')}
        </Label>
        {data?.nonTemaBeforeCloseTime > 0 && (
          <Label className="d-flex negativeValue mx-2">
            {` [${0 - (data?.nonTemaBeforeCloseTime ?? 0)}s]`}
          </Label>
        )}
        {
          !data?.notTemaCloseTime && !data?.nonTemaBeforeCloseTime && '-'
        }
      </div>
    ),
    data => (
      <div className="d-flex justify-content-center">
        <Label className="d-flex justify-content-center">
          {data?.lotteryTime && moment.unix(data?.lotteryTime).format('YYYY-MM-DD HH:mm:ss')}
        </Label>
        {
          !data?.lotteryTime && !data?.lotteryTime && '-'
        }
      </div>
    ),
    data => (<Label className="d-flex justify-content-center">{data?.status || '-'}</Label>),
    data => (
      <Label
        className={`d-flex justify-content-center ${['关盘', '上级关盘'].includes(data?.pkStatus) && 'text-red'}`}
      >
        {
          data?.pkStatus || '-'
        }
      </Label>
    ),
    item => (
      item?.isEditable === 1
        ? (
          <div className="d-flex justify-content-center align-item-center">
            <div
              className="button handle mx-1"
              onClick={() => callback('lotterySetting', {
                orgId: data ? data.orgId : userData?.id, code: item.code, name: item.name
              })}
            >
              {t('SETTING')}
            </div>
            {(item?.closeStatus === 0 || !item?.closeStatus)
              ? <div className="button green mx-1" onClick={() => saveButton(item, 1)}>{t('RECOVER')}</div>
              : <div className="button find mx-1" onClick={() => saveButton(item, 0)}>{t('CLOSE_PLATE')}</div>}
          </div>
        )
        : ''
    ),
  ];

  return (
    <div className="LotteryManageWrapper">
      {
        showHeader && (
          <CommonHeader
            headerName={t('LOTTERY_MANAGEMENT')}
            rightLocationItem={() => (
              <div>
                {(loading && `${t('LOADING')}...`) || `${_countDownTimer}${t('SECOND')}`}
              </div>
            )}
          />
        )
      }

      <div className="mx-0 bg-white my-2  border-1">
        {
          showHeader && (userData?.levelValue === 0 || userData?.levelValue <= lotteryItemsData?.info?.companyLevel) && (
            <Row className="p-2 m-0 mb-3 filterWrapper">
              <Col className="m-0 p-0">
                <Button
                  className="button add"
                  onClick={() => {
                    updateModalAlert({
                      type: 'lotteryReorder',
                      visible: true,
                      data: _info
                    });
                  }}
                >
                  <FaSlidersH className="m-1" />
                  {t('SORTING')}
                </Button>
              </Col>
            </Row>
          )
        }

        {
          !showHeader && (
            <Button
              className="button add"
              onClick={() => {
                updateModalAlert({
                  type: 'lotteryReorder',
                  visible: true,
                  data: _info
                });
              }}
            >
              <FaSlidersH className="m-1" />
              {t('SORTING')}
            </Button>
          )
        }

        <Row className="bigWrapper m-0 my-1 p-3">
          <AgentDataTable
            isQuerying={isQueryStatus}
            tableHeaderTitle={tableHeader}
            tableData={_info || (isQueryStatus ? [1, 2, 3] : [])}
            tableBodyRowProperty={tableContent}
          />
        </Row>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LotteryManage);