import { useEffect, useState } from 'react';

import { InputNumber, Select } from 'antd';
import { connect } from 'react-redux';

import { useTranslation } from 'react-i18next';

import { appActions, systemActions } from '../../../redux/actions';
import './styles.scss';


const mapStateToProps = state => ({
  editLotteryItemData: state.systemReducers.editLotteryItemData,
});

const mapDispatchToProps = dispatch => ({
  getEditLotteryItem: (userId, callback) => dispatch(systemActions.getEditLotteryItem(userId, callback)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  saveLotteryItem: (obj, callback) => dispatch(systemActions.saveLotteryItem(obj, callback)),
});

const LotterySetting = ({
  callback = {},
  getEditLotteryItem,
  data,
  editLotteryItemData,
  updateModalAlert,
  saveLotteryItem
}) => {
  const { t } = useTranslation();
  const [_info, setInfo] = useState([]);

  useEffect(() => {
    getEditLotteryItem({
      code: data.code,
      orgId: data.orgId
    });
  }, []);

  useEffect(() => {
    if (editLotteryItemData) {
      setInfo(editLotteryItemData);
    }
  }, [editLotteryItemData]);

  const saveButton = () => {
    updateModalAlert({
      type: 'loading',
      visible: true
    });
    const obj = {
      ..._info, orgId: data.orgId
    };
    saveLotteryItem(obj, callback);
  };

  const onInfoValueChange = (val, field) => {
    const newItems = _info;
    newItems[field] = Number.isNaN(val) ? 0 : Number(val);
    setInfo(newItems => ({
      ...newItems
    }));
  };

  const resetDefault = () => {
    callback();
  };

  const PlateStatus = [
    {
      label: t('NORMAL'),
      value: 1,
    },
    {
      label: t('CLOSE_PLATE'),
      value: 0,
    }
  ];

  return (
    <div className="LotterySettingWrapper">
      <div className="mx-0 bg-white my-2  border-1">
        <table className="p-3 m-0" cellSpacing="0" cellPadding="0" border="0">
          <tbody>
            <tr>
              <td className="titleHeader" colSpan={2}>
                个人彩种资料
              </td>
            </tr>
            <tr>
              <td className="tableHeader col-4">{t('LOTTERY_NAME')}</td>
              <td>
                <div>{data.name}</div>
              </td>
            </tr>
            <tr>
              <td className="tableHeader col-4">全局盘口</td>
              <td>
                <div className="d-flex p-0">
                  <Select
                    className="dropdownAndInputStyle"
                    value={_info?.closeStatus}
                    placeholder={t('PLEASE_SELECT')}
                    onChange={val => onInfoValueChange(Number(val), 'closeStatus')}
                    options={PlateStatus}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td className="tableHeader col-4">提前开盘时间</td>
              <td>
                <div className="d-flex">
                  <InputNumber
                    className="dropdownAndInputStyle"
                    value={_info?.beforeOpenTime}
                    onChange={text => onInfoValueChange(text, 'beforeOpenTime')}
                  />
                  <div className="mx-2 justify-content-center p-1 align-item-center">
                    (
                    {
                        t('SECOND')
                      }
                    )
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td className="tableHeader col-4">提前封盘时间</td>
              <td>
                <div className="d-flex">
                  <InputNumber
                    className="dropdownAndInputStyle"
                    value={_info?.beforeCloseTime}
                    onChange={text => onInfoValueChange(text, 'beforeCloseTime')}
                  />
                  <div className="mx-2 justify-content-center p-1 align-item-center">
                    (
                    {
                        t('SECOND')
                      }
                    )
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td className="tableHeader col-4">特码提前封盘时间</td>
              <td>
                <div className="d-flex">
                  <InputNumber
                    className="dropdownAndInputStyle"
                    value={_info?.temaBeforeCloseTime}
                    onChange={text => onInfoValueChange(text, 'temaBeforeCloseTime')}
                  />
                  <div className="mx-2 justify-content-center p-1 align-item-center">(秒)</div>
                </div>
              </td>
            </tr>
            <tr>
              <td className="tableHeader col-4">非特码提前封盘时间</td>
              <td>
                <div className="d-flex">
                  <InputNumber
                    className="dropdownAndInputStyle"
                    value={_info?.nonTemaBeforeCloseTime}
                    onChange={text => onInfoValueChange(text, 'nonTemaBeforeCloseTime')}
                  />
                  <div className="mx-2 justify-content-center p-1 align-item-center">(秒)</div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="mb-5 my-2 p-2 d-flex">
        <div className="d-flex justify-content-center py-2 border-top w-100">
          <div className="button handle mx-1" onClick={() => saveButton()}>{t('SAVE')}</div>
          <div className="button find mx-1" onClick={() => resetDefault()}>{t('CANCEL')}</div>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LotterySetting);