

import { Col, Label } from 'reactstrap';
import { connect } from 'react-redux';

import Notifications from '../Notifications';

import './styles.scss';

const CommonHeader = ({ headerName, rightLocationItem }) => (
  <Col className="CommonHeaderWrapper p-2 mb-2 d-flex align-items-center w-100 rounded-top">
    <Col>
      <Label className="px-2">
        {headerName}
      </Label>
    </Col>
    {rightLocationItem && rightLocationItem()}
    <Notifications />
  </Col>
);

export default connect(null)(CommonHeader);