import { useEffect } from 'react';

import { Label } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { AgentDataTable, CommonHeader } from '../../components';
import { whiteListActions } from '../../redux/actions';

import './styles.scss';

const mapStateToProps = state => ({
  superUser: state.whiteListReducers.superUser,
});

const mapDispatchToProps = dispatch => ({
  getSuperUser: () => dispatch(whiteListActions.getSuperUser()),
});

const SuperUserList = ({ superUser, getSuperUser }) => {
  const { t } = useTranslation();

  useEffect(() => {
    getSuperUser();
  }, []);


  return (
    <div className="SuperUserListWrapper p-3">
      <CommonHeader headerName={t('SUPER_USER_LIST')} />
      <div className="mx-0 mb-3 bg-white px-0 border-1">
        <AgentDataTable
          isQuerying={false}
          tableHeaderTitle={
            [{
              label: t('ACCOUNT'),
            }, {
              label: t('CREATE_DATE'),
            }]
          }
          tableHeaderStyle={
            ['w-50', 'w-50']
          }
          tableData={superUser ? superUser.userList : []}
          tableBodyRowProperty={
            [
              data => <Label className="d-flex justify-content-center">{data.loginId}</Label>,
              data => (<Label className="d-flex justify-content-center">{moment.unix(data.date).format('YYYY-MM-DD hh:mm:ss')}</Label>),
            ]
          }
        />
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SuperUserList);