import { StatusCodes } from 'http-status-codes';

import { SET_BATCH_ODDS_LIST, SET_DEFAULT_WIN_LOST, SET_ODDS_SETTING } from '../actionTypes';
import { apiService } from '../../../library';

import appActions from '../appActions';

const setBatchOddsList = list => ({
  type: SET_BATCH_ODDS_LIST,
  value: list,
});

const setOddsSetting = list => ({
  type: SET_ODDS_SETTING,
  value: list,
});

const setDefaultWinLost = list => ({
  type: SET_DEFAULT_WIN_LOST,
  value: list,
});

const updateBatchOddsList = list => dispatch => {
  dispatch(setBatchOddsList(list));
};


const getInfo = (obj, calllback = () => {}) => dispatch => {
  apiService.get('/oddssetting/GetInfo', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      calllback(false);
      dispatch(setOddsSetting(data.result));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    calllback(false);
    Promise.reject(error);
  });
};

const SaveOdds = (obj, calllback = () => {}) => dispatch => {
  apiService.post('/oddssetting/saveodds', obj).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        dispatch(appActions.updateModalAlert({
          visible: false
        }));
        return calllback();
      }
    }
  }).catch(error => {
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};

const QuickSaveOdds = (obj, calllback = () => {}) => dispatch => {
  apiService.post('/oddssetting/quicksaveodds', obj).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        dispatch(appActions.updateModalAlert({
          visible: false
        }));
        return calllback();
      }
    }
  }).catch(error => {
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  }).finally(() => {
    dispatch(updateBatchOddsList());
  });
};

const getAgentOddsCmSetting = (obj, callback = () => {}) => dispatch => {
  apiService.get('oddssetting/AgentOddsCmSetting', new URLSearchParams(obj))
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          callback(true);
          return dispatch(setDefaultWinLost(data.result));
        }
      }
    }).catch(error => {
      dispatch(setDefaultWinLost());
      if (error?.response?.data?.state) {
        dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'confirmation',
          data: {
            message: error.response.data.msg,
            showCancelButton: false
          }
        }));
      }
      Promise.reject(error);
    });
};

const SaveAgentOddsCmSetting = (obj = {}, callback = () => {}) => dispatch => {
  apiService.post('oddssetting/SaveAgentOddsCmSetting', obj)
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          dispatch(appActions.updateModalAlert({
            visible: false
          }));
          dispatch(appActions.updateModalAlert({
            visible: true,
            type: 'success',
            data: {
              message: data.msg,
            }
          }));
          return callback();
        }
      }
    }).catch(error => {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      if (error.response.data.state) {
        dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'confirmation',
          data: {
            message: error.response.data.msg
          }
        }));
      }
      Promise.reject(error);
    });
};

const SaveDefaultOddsCmSetting = (obj = {}, callback = () => {}) => dispatch => {
  apiService.post('oddssetting/SaveDefaultOddsCmSetting', obj)
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          dispatch(appActions.updateModalAlert({
            visible: false
          }));
          dispatch(appActions.updateModalAlert({
            visible: true,
            type: 'success',
            data: {
              message: data.msg,
            }
          }));
          return callback();
        }
      }
    }).catch(error => {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      if (error.response.data.state) {
        dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'confirmation',
          data: {
            message: error.response.data.msg
          }
        }));
      }
      Promise.reject(error);
    });
};

export default {
  SaveOdds,
  QuickSaveOdds,
  updateBatchOddsList,
  getInfo,
  getAgentOddsCmSetting,
  SaveAgentOddsCmSetting,
  SaveDefaultOddsCmSetting,
};