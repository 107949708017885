import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import EN from './en';
import ZH from './zh';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      EN,
      ZH
    },
    lng: 'ZH',
    fallbackLng: 'ZH',
    debug: true,

    interpolation: {
      escapeValue: false,
    }
  });

export default i18n;