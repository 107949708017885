

import { Button, Col, Row } from 'reactstrap';
import { IoClose } from 'react-icons/io5';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Label from '../../Label';
import { ReactComponent as Tick } from '../../../assets/image/icon_tick.svg';
import { appActions } from '../../../redux/actions';
import './styles.scss';


const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
});

const SuccessModal = ({ updateModalAlert, data }) => {
  const { t } = useTranslation();
  return (
    <div className="successModalWrapperWeb">
      <Row className="modalHeader">
        <Col
          className="d-flex align-items-center"
        >
          {t('REMINDER')}
        </Col>
        <Col className="d-flex justify-content-end align-items-center">
          <IoClose
            onClick={() => {
              updateModalAlert({
                visible: false
              });
              if (data && data.overBet && data.onConfirmationClick) data.onConfirmationClick();
            }}
            className="h4 mb-0 float-right closeIcon"
          />
        </Col>
      </Row>
      <Row className="modalContent d-flex justify-content-center">
        <Row className="d-flex align-items-center justify-content-center mb-4 mt-5 mb-4 mt-5 mx-0">
          <Tick className="successTick" />
        </Row>
        <Row className="d-flex align-items-center text-center justify-content-center mb-4 mx-0">
          <Label className="successText" value={data && data.message} />
        </Row>
      </Row>
      <Row className="modalFooter d-flex align-items-center ">
        <Button
          className="confirmButton my-3"
          onClick={() => {
            updateModalAlert({
              visible: false
            });
            if (data && data.onConfirmationClick) data.onConfirmationClick();
          }}
        >
          {(data && data.confirmText) || t('CONFIRM')}
        </Button>
      </Row>
    </div>
  );
};

export default connect(null, mapDispatchToProps)(SuccessModal);