import { SET_ONLINE_LEVEL_SUM, SET_ONLINE_USER_COUNT, SET_ONLINE_USER_LIST } from '../../actions/actionTypes';

const initialState = {
  onlineUserList: undefined,
  onlineLevelSum: undefined,
  onlineUserCount: undefined,
};

const onlineStatisticsReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_ONLINE_USER_LIST:
      return {
        ...state, onlineUserList: action.value
      };
    case SET_ONLINE_LEVEL_SUM:
      return {
        ...state, onlineLevelSum: action.value
      };
    case SET_ONLINE_USER_COUNT:
      return {
        ...state, onlineUserCount: action.value
      };
    default:
      return state;
  }
};

export default onlineStatisticsReducers;