import { useEffect, useState } from 'react';

import { Input, Select } from 'antd';
import { Label } from 'reactstrap';
import { MdArrowBack } from 'react-icons/md';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { PlusCircleOutlined } from '@ant-design/icons';

import { AgentDataTable, CommonHeader } from '../..';
import { thirdPartyGamesActions } from '../../../redux/actions';

import './styles.scss';


const mapStateToProps = state => ({
  activeLottery: state.commonReducers.activeLottery,
  thirdPartyGamesList: state.thirdPartyGamesReducers.thirdPartyGamesList,
  thirdPartyGamesEdit: state.thirdPartyGamesReducers.thirdPartyGamesEdit,
  selectedSubMenu: state.commonReducers.selectedSubMenu,
});

const mapDispatchToProps = dispatch => ({
  GetInfo: callback => dispatch(thirdPartyGamesActions.GetInfo(callback)),
  EditThirdPartyGame: (id, callback) => dispatch(thirdPartyGamesActions.EditThirdPartyGame(id, callback)),
  SaveThirdPartyGame: (obj, callback) => dispatch(thirdPartyGamesActions.SaveThirdPartyGame(obj, callback)),
});

const { Option } = Select;

const GameSetting = ({ thirdPartyGamesList, GetInfo, EditThirdPartyGame, thirdPartyGamesEdit, SaveThirdPartyGame }) => {
  const { t } = useTranslation();
  const [_isQuerying, setIsQuerying] = useState(false);
  const [_pageFlag, setPageFlag] = useState(0);
  const [_gameCode, setGameCode] = useState('');
  const [_gameName, setGameName] = useState('');
  const [_gameClient, setGameClient] = useState('');
  const [_gameType, setGameType] = useState();
  const [_minInAmount, setMinInAmount] = useState(0);
  const [_minOutAmount, setMinOutAmount] = useState(0);
  const [_timeZoneDiff, setTimeZoneDiff] = useState(0);
  const [_timeZoneRemark, setTimeZoneRemark] = useState('');
  const [_transferStatus, setTransferStatus] = useState(0);
  const [_status, setStatus] = useState(0);
  const [_statusRemark, setStatusRemark] = useState('');
  const [GameId, setGameId] = useState('');

  const saveThirdPartyGame = () => {
    if (_gameCode && _gameName) {
      const obj = {
        GameId,
        gameCode: _gameCode,
        gameName: _gameName,
        gameStaus: _status,
        gameStatusRemark: _statusRemark,
        transferEnable: _transferStatus,
        clientCode: _gameClient,
        transferInMin: _minInAmount,
        transferOutMin: _minOutAmount,
        gameTimeDiff: _timeZoneDiff,
        gameTimeDiffRemark: _timeZoneRemark,
        categoryCode: _gameType
      };
      const callback = () => {
        GetInfo();
        setPageFlag(0);
      };
      SaveThirdPartyGame(obj, callback);
    }
  };

  useEffect(() => {
    setIsQuerying(true);
    GetInfo(setIsQuerying);
  }, []);

  useEffect(() => {
    if (_pageFlag === 2 && thirdPartyGamesEdit) {
      setGameId(thirdPartyGamesEdit?.id);
      setGameCode(thirdPartyGamesEdit?.gameCode);
      setGameName(thirdPartyGamesEdit?.gameName);
      setGameClient(thirdPartyGamesEdit?.clientCode);
      setGameType(thirdPartyGamesEdit?.categoryCode);
      setMinInAmount(thirdPartyGamesEdit?.transferIn);
      setMinOutAmount(thirdPartyGamesEdit?.transferOut);
      setTimeZoneDiff(thirdPartyGamesEdit?.timezoneDiff);
      setTransferStatus(thirdPartyGamesEdit?.transferEnable);
      setStatus(thirdPartyGamesEdit?.status);
      setStatusRemark(thirdPartyGamesEdit?.statusRemark);
      setTimeZoneRemark(thirdPartyGamesEdit?.timezoneRemark);
    } else {
      setGameId('');
      setGameCode('');
      setGameName('');
      setGameClient('');
      setGameType();
      setMinInAmount(0);
      setMinOutAmount(0);
      setTimeZoneDiff(0);
      setTransferStatus(0);
      setStatus(0);
      setStatusRemark('');
      setTimeZoneRemark('');
    }
  }, [_pageFlag, thirdPartyGamesEdit]);

  return (
    <div className="gameSettingWrapper">
      <CommonHeader
        headerName={(
          <div className="d-flex align-items-center">
            {_pageFlag !== 0 && (
            <div className="d-flex align-items-center me-2 backIcon" onClick={() => setPageFlag(0)}>
              <MdArrowBack className="h5 mb-0 text-white" />
              返回
            </div>
            )}
            {`${t('GAME_SETTING')} ${_pageFlag === 0 ? '' : _pageFlag === 1 ? '/ 新增游戏' : '/ 编辑'}`}
          </div>
)}
      />
      {_pageFlag === 0 && (
      <>
        <div className="addNewGameBox my-3">
          <div className="operationBtn add  d-flex align-items-center" onClick={() => setPageFlag(1)}>
            <PlusCircleOutlined />
            <span className="ms-2">新增游戏</span>
          </div>
        </div>

        <div className="mx-0 bg-white mb-3 px-0 border-1">
          <AgentDataTable
            isQuerying={_isQuerying}
            tableHeaderTitle={
            [{
              label: t('ORDER_NUM'),
            }, {
              label: t('GAME_CODE'),
            }, {
              label: t('GAME_NAME'),
            }, {
              label: t('GAME_TYPE'),
            }, {
              label: t('TRANSFER_LIMIT'),
            }, {
              label: t('STATUS'),
            }, {
              label: t('STATUS_STATEMENT'),
            }, {
              label: t('OPERATION'),
            }]
          }
            tableHeaderStyle={
            ['smallCol']
          }
            tableData={thirdPartyGamesList ? thirdPartyGamesList.map((item, index) => ({
              ...item, index: index + 1
            })) : []}
            tableBodyRowProperty={
            [
              data => (<Label className="d-flex justify-content-center">{data.index}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.gameCode}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.gameName}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.categoryName}</Label>),
              data => (
                <div className="d-flex flex-column justify-content-center">
                  <Label className="d-flex justify-content-center">
                    {`转入: ${data.transferIn}`}
                  </Label>
                  <Label className="d-flex justify-content-center">
                    {`转出: ${data.transferOut}`}
                  </Label>
                </div>
              ),
              data => (<Label className={`d-flex justify-content-center ${data.status === 1 && 'text-danger'}`}>{data.status === 0 ? '启用' : '停用'}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.gameStatusRemark}</Label>),
              data => <Label className="d-flex justify-content-center operationBtn w-50 m-auto" onClick={() => { EditThirdPartyGame(data.id, () => setPageFlag(2)); }}>编辑</Label>
            ]
          }
          />
        </div>
      </>
      )}
      {_pageFlag !== 0 && (
        <>
          <div className="my-3 thirdPartyGamesTable">
            <div className="thirdPartyGamesTitle">{_pageFlag === 1 ? '新增游戏' : '修改游戏'}</div>
            <div className="thirdPartyGamesRow">
              <div>游戏Code: </div>
              <div><Input disabled={_pageFlag === 2} value={_gameCode} onChange={({ target: { value: text } }) => setGameCode(text.trim())} placeholder="请输入内容" /></div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>游戏名称: </div>
              <div><Input value={_gameName} onChange={({ target: { value: text } }) => setGameName(text.trim())} placeholder="请输入内容" /></div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>游戏Client: </div>
              <div><Input value={_gameClient} onChange={({ target: { value: text } }) => setGameClient(text.trim())} placeholder="请输入内容" /></div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>游戏种类: </div>
              <div>
                <Select value={_gameType} placeholder="请选择" onChange={code => setGameType(code)}>
                  {
                [{
                  value: 1, label: '棋牌游戏'
                }, {
                  value: 2, label: '真人游戏'
                }, {
                  value: 3, label: '体育游戏'
                }].map(el => <Option key={el.value} value={el.value}>{el.label}</Option>)
              }
                </Select>
              </div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>最低转入金额: </div>
              <div>
                <Input
                  value={_minInAmount}
                  onChange={({ target: { value: text } }) => {
                    setMinInAmount(text > -1 ? Number(text.trim()) : 0);
                  }}
                  placeholder="请输入内容"
                />

              </div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>最低转出金额: </div>
              <div>
                <Input
                  value={_minOutAmount}
                  onChange={({ target: { value: text } }) => {
                    setMinOutAmount(text > -1 ? Number(text.trim()) : 0);
                  }}
                  placeholder="请输入内容"
                />

              </div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>时区差: </div>
              <div>
                <Input
                  value={_timeZoneDiff}
                  onChange={({ target: { value: text } }) => {
                    setTimeZoneDiff(text > -1 ? Number(text.trim()) : 0);
                  }}
                  placeholder="请输入内容"
                />
              </div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>时区: </div>
              <div>
                <Input value={_timeZoneRemark} onChange={({ target: { value: text } }) => setTimeZoneRemark(text.trim())} placeholder="请输入内容" />
              </div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>转账状态: </div>
              <div>
                <Select
                  value={_transferStatus}
                  placeholder="请选择"
                  onChange={code => {
                    setTransferStatus(code);
                  }}
                >
                  {
                        [{
                          value: 0, label: '启用'
                        }, {
                          value: 1, label: '停用'
                        }].map(el => <Option key={el.value} value={el.value}>{el.label}</Option>)
                  }
                </Select>
              </div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>状态: </div>
              <div>
                <Select
                  value={_status}
                  placeholder="请选择"
                  onChange={code => {
                    setStatus(code);
                  }}
                >
                  {
                [{
                  value: 0, label: '启用'
                }, {
                  value: 1, label: '停用'
                }].map(el => <Option key={el.value} value={el.value}>{el.label}</Option>)
              }
                </Select>
              </div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>停用说明: </div>
              <div><Input value={_statusRemark} onChange={({ target: { value: text } }) => setStatusRemark(text.trim())} placeholder="请输入内容" /></div>
            </div>
          </div>
          <div className="thirdPartyGamesFooter">
            <div className="operationBtn editAndSave me-3" onClick={() => saveThirdPartyGame()}>确定</div>
            <div className="operationBtn cancel" onClick={() => setPageFlag(0)}>取消</div>
          </div>
        </>
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(GameSetting);