import { useEffect, useState } from 'react';

import { Checkbox, DatePicker, Input, Select } from 'antd';
import { FaCaretDown } from 'react-icons/fa';
import { Label } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { AgentDataTable, LotteryMenu, Pagination } from '../../components';
import { CommonSearchBy, UserBetStatus, _betType } from '../../constants/SelectionItems';
import { appActions, betListActions, commonActions, systemActions } from '../../redux/actions';
import Notifications from '../../components/Content/Notifications';

import './styles.scss';

const PagesList = [{
  label: '900条/页',
  value: 900,
}, {
  label: '700条/页',
  value: 700,
}, {
  label: '500条/页',
  value: 500,
}, {
  label: '300条/页',
  value: 300,
}];

const mapStateToProps = state => ({
  betQueryResult: state.betListReducers.betQueryResult,
  lotteryList: state.commonReducers.lotteryList,
  lotteryInstallments: state.commonReducers.lotteryInstallments,
  settingCodeList: state.commonReducers.settingCode,
  userData: state.memberReducers.userData,
});

const mapDispatchToProps = dispatch => ({
  getBetQueryResult: (obj, callback) => dispatch(betListActions.getBetQueryResult(obj, callback)),
  getLotteryInstallments: (lotteryCode, date) => dispatch(commonActions.getLotteryInstallments(lotteryCode, date)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  cancelBettingByInstallNo: (lotteryType, installments, status, onClick) => dispatch(systemActions.cancelBettingByInstallNo(lotteryType, installments, status, onClick)),
  cancelBetting: (lotteryType, installments, status, ids, onClick) => dispatch(systemActions.cancelBetting(lotteryType, installments, status, ids, onClick)),
  QuerySettingCode: obj => dispatch(commonActions.QuerySettingCode(obj)),
});

const { RangePicker } = DatePicker;
const { Option } = Select;

const BetRevoke = ({
  QuerySettingCode,
  settingCodeList,
  userData,
  betQueryResult,
  getBetQueryResult,
  lotteryList,
  getLotteryInstallments,
  lotteryInstallments,
  updateModalAlert,
  cancelBetting
}) => {
  const { t } = useTranslation();
  const [_searchItem, setSearchItem] = useState('');
  const [_date, setDate] = useState([moment().subtract(1, 'weeks').toDate(), moment().toDate()]);
  const [_detailItem, setDetailItem] = useState();
  const [_installmentsResults, setInstallmentResults] = useState([]);
  const [_detailItemSum, setDetailItemSum] = useState();
  const [_selectedLottery, setSelectedLottery] = useState('');
  const [_selecteLotteryTxt, setSelectedLotteryTxt] = useState('全部');
  const [_displayMenu, setDisplayMenu] = useState(false);
  const [_selectedBetStatus, setSelectedBetStatus] = useState('3');
  const [_selectedInstallments, setSelectedInstallments] = useState('-1');
  const [_selectedBetType, setSelectedBetType] = useState(-1);
  const [_selectedSearchBy, setSelectedSearchBy] = useState('username');
  const [_isQueryingDetail, setIsQueryingDetail] = useState(false);
  const [_page, setPage] = useState(1);
  const [_rows, setRows] = useState(900);
  const [SettingCode, setSettingCode] = useState('');

  const [selectedItemList, setSelectedItemList] = useState([]);

  useEffect(() => {
    if (lotteryList) {
      getBetQueryResult({
        lotteryType: _selectedLottery,
        startDate: moment().add(-7, 'days').unix(),
        endDate: moment().unix(),
        searchBy: _selectedSearchBy,
        page: _page,
        rows: _rows,
        status: _selectedBetStatus,
        value: _searchItem,
        installments: _selectedInstallments === '-1' ? '' : _selectedInstallments,
        memberId: '',
        userType: '',
        SettingCode
      }, setIsQueryingDetail);
      setSelectedLotteryTxt(lotteryList.find(item => item.code === _selectedLottery)?.lotteryName);
    }
  }, [lotteryList]);

  useEffect(() => {
    if (_selectedLottery) {
      setSettingCode('');
      QuerySettingCode({
        lotteryType: _selectedLottery
      });
    }
  }, [_selectedLottery]);

  useEffect(() => {
    setInstallmentResults(lotteryInstallments);
  }, [lotteryInstallments]);

  useEffect(() => {
    if (betQueryResult && JSON.stringify(_detailItem) !== JSON.stringify(betQueryResult)) {
      setDetailItem(betQueryResult.data);
      setDetailItemSum(betQueryResult.sum);
      setIsQueryingDetail(false);
    }
  }, [betQueryResult]);

  const onLotterySelect = retvalue => {
    getLotteryInstallments(retvalue?.code, moment(_date[0]).unix() || moment().add(-7, 'days').unix());
    setSelectedLottery(retvalue?.code);
    setSelectedLotteryTxt(retvalue?.lotteryName);
    setSelectedInstallments('-1');
    setDisplayMenu(false);
  };

  const onSearchClick = () => {
    setSelectedItemList([]);
    setIsQueryingDetail(true);
    getBetQueryResult({
      lotteryType: _selectedLottery || '',
      startDate: (moment(_date[0]).unix() || moment().add(-7, 'days').unix()),
      endDate: (moment(_date[1]).unix() || moment().unix()),
      searchBy: _selectedSearchBy,
      page: 1,
      rows: _rows,
      status: _selectedBetStatus,
      value: _searchItem,
      installments: _selectedInstallments === '-1' ? '' : _selectedInstallments,
      memberId: '',
      userType: '',
      SettingCode
    }, setIsQueryingDetail);
  };

  const onPaginationClick = (pageNum, rows) => {
    setIsQueryingDetail(false);
    getBetQueryResult({
      lotteryType: _selectedLottery,
      startDate: (moment(_date[0]).unix() || moment().add(-7, 'days').unix()),
      endDate: (moment(_date[1]).unix() || moment().unix()),
      searchBy: _selectedSearchBy,
      page: pageNum,
      rows,
      status: _selectedBetStatus,
      value: _searchItem,
      installments: _selectedInstallments === '-1' ? '' : _selectedInstallments,
      memberId: '',
      userType: '',
      SettingCode
    }, setIsQueryingDetail);
    setPage(pageNum);
    setRows(rows);
  };

  const onClickByIds = status => {
    if (_selectedInstallments === '-1' || !_selectedLottery) {
      const mymsg = _selectedInstallments === '-1' ? '请选择期数' : '请选择彩种';
      updateModalAlert({
        type: 'confirmation',
        visible: true,
        data: {
          message: mymsg,
          showCancelButton: false
        }
      });
    } else if (selectedItemList?.length > 0) {
      updateModalAlert({
        type: 'loading',
        visible: true,
      });
      cancelBetting(_selectedLottery, _selectedInstallments, status, selectedItemList, onSearchClick);
    } else {
      updateModalAlert({
        type: 'confirmation',
        visible: true,
        data: {
          message: '请选择注单',
          showCancelButton: false
        }
      });
    }
  };

  const onClickById = (status, id, lotteryType, installments) => {
    updateModalAlert({
      type: 'loading',
      visible: true,
    });
    cancelBetting(lotteryType, installments, status, [id], onSearchClick);
  };

  return (
    <div className="betRevokeWrapper">
      <div className="py-2 px-1 mb-2 filterWrapper d-flex align-items-center w-100 rounded-top">
        <Label value={t('LOTTERY')}>{t('LOTTERY')}</Label>
        <div className="flex">
          <div
            onMouseEnter={() => setDisplayMenu(!_displayMenu)}
            onClick={() => setDisplayMenu(!_displayMenu)}
            className={`menuWrapper d-flex ${_displayMenu ? 'menuactive' : ''}`}
          >
            <Label className="menuSelectedText">{_selecteLotteryTxt || '全部'}</Label>
            <div className="float-right">
              <FaCaretDown className="float-right" />
            </div>
          </div>

          <div onMouseLeave={() => setDisplayMenu(false)} className={`subselection ${_displayMenu ? '' : 'hide'}`}>
            <LotteryMenu
              lotteryList={lotteryList}
              selectedItem={_selectedLottery}
              onClickItem={onLotterySelect}
            />
          </div>
        </div>
        <Label value={t('INSTALLMENTS')}>{t('INSTALLMENTS')}</Label>
        <Select
          disabled={_selectedLottery === ''}
          className="selectStyle installment"
          value={_selectedInstallments}
          onChange={value => (setSelectedInstallments(value))}
        >
          {
            _installmentsResults && [{
              desc: t('ALL'), installment: '-1'
            }].concat(_installmentsResults).map(item => (
              <Option value={item.installment}>{item.desc}</Option>
            ))
          }
        </Select>
        <Label value={t('BET_TYPE')}>{t('BET_TYPE')}</Label>
        <Select
          className="selectStyle betType"
          value={_selectedBetType}
          onChange={value => (setSelectedBetType(value))}
        >
          {
          userData && _betType.filter(x => (x.value <= 0) || (x.value >= userData.levelValue && x.value > 0)).map(item => <Option value={item.value}>{item.label}</Option>)
        }
        </Select>
        <Label value={t('BET_TYPE')}>分类</Label>
        <Select
          className="selectStyle category"
          value={SettingCode}
          disabled={_selectedLottery === ''}
          onChange={value => (setSettingCode(value))}
        >
          {
            [{
              code: '', projectName: '全部'
            }].concat(settingCodeList || []).map(item => <Option value={item.code}>{item.projectName}</Option>)
          }
        </Select>
        <Label value={t('STATUS')}>{t('STATUS')}</Label>
        <Select
          className="betStatus selectStyle"
          value={_selectedBetStatus}
          onChange={value => (setSelectedBetStatus(value))}
        >
          {
            UserBetStatus.map(item => (
              <Option key={`UserBetStatus_${item.value}`} value={item.value}>{item.label}</Option>
            ))
          }
        </Select>
        <Label value={t('SEARCH')}>{t('SEARCH')}</Label>
        <Select
          className="searchType selectStyle"
          value={_selectedSearchBy}
          onChange={value => {
            setSelectedSearchBy(value);
            setSearchItem('');
          }}
        >
          {
            userData && CommonSearchBy.filter(x => !(x.value >= 0) || (x.value >= userData.levelValue && x.value > 0)).map(item => (
              <Option key={`CommonSearchBy${item.value}`} value={item.value}>{item.label}</Option>
            ))
          }
        </Select>
        <Input className="inputSearchStyle" value={_searchItem} onChange={({ target: { value: text } }) => setSearchItem(text)} />
        <Label value={t('DATE')}>{t('DATE')}</Label>
        <RangePicker
          className="datePickRangeStyle"
          allowClear={false}
          value={[moment(_date[0]), moment(_date[1])]}
          onChange={date => {
            setDate([date[0].toDate(), date[1].toDate()]);
          }}
        />

        <div className="searchButton ms-1" onClick={onSearchClick}>{t('FIND')}</div>
        <div className="searchButton ms-1" onClick={() => onClickByIds(1)}>{t('CANCEL')}</div>
        <div className="searchButton ms-1" onClick={() => onClickByIds(0)}>{t('REVERT')}</div>
        <Notifications />
      </div>
      <div className="mx-0 bg-white px-0 border-1">
        <AgentDataTable
          isQuerying={_isQueryingDetail}
          tableHeaderTitle={
            [{
              label: (
                <Checkbox
                  checked={(selectedItemList?.length === _detailItem?.length) && selectedItemList?.length > 0 && _detailItem?.length > 0}
                  className="text-nowrap"
                  onChange={() => {
                    if (selectedItemList?.length === _detailItem?.length) {
                      setSelectedItemList([]);
                    } else {
                      setSelectedItemList(_detailItem.map(x => x.id));
                    }
                  }}
                >
                  选择所有
                </Checkbox>),
            }, {
              label: t('BET_NUMBER'),
            }, {
              label: t('BET_TIME'),
            }, {
              label: t('LOTTERY'),
            }, {
              label: t('ACCOUNT_NAME'),
            }, {
              label: t('BET_CONTENT'),
            }, {
              label: t('BET') + t('AMOUNT'),
            }, {
              label: `${t('REBATE')}%/${t('AMOUNT')}`,
            }, {
              label: t('BET_RESULT'),
            }, {
              label: t('THIS_LVL_PERCENTAGE'),
            }, {
              label: t('THIS_LVL_RESULT'),
            }, {
              label: t('REMARK'),
            }, {
              label: t('STATUS'),
            }, {
              label: t('OPERATION'),
            }]
          }
          tableData={_detailItem || []}
          tableBodyRowProperty={
            [
              data => (
                <Checkbox
                  checked={selectedItemList.includes(data.id)}
                  onChange={() => {
                    if (selectedItemList.includes(data.id)) {
                      setSelectedItemList(selectedItemList.filter(x => x !== data.id));
                    } else {
                      setSelectedItemList([data.id].concat(selectedItemList));
                    }
                  }}
                />
              ),
              data => (
                <Label
                  className="lotteryName url"
                  onClick={() => {
                    updateModalAlert({
                      type: 'betDetails',
                      visible: true,
                      data: {
                        id: data?.id,
                      }
                    });
                  }}
                >
                  {data.oddNo}
                </Label>
              ),
              data => (
                <>
                  <Label className="px-1">{moment.unix(data.created).format('YYYY-MM-DD hh:mm:ss')}</Label>
                  <Label>{t(`DAY_OF_WEEK_${moment.unix(data.created || 0).weekday()}`)}</Label>
                </>
              ),
              data => (
                <>
                  <Label className="lotteryName">{data.lotteryName}</Label>
                  <Label className="installment">{t('DI')}</Label>
                  <Label className="installment">{data.installments}</Label>
                  <Label className="installment">{t('INSTALLMENTS')}</Label>
                </>
              ),
              data => (
                <>
                  <Label className="lotteryName">{data.orgName}</Label>
                  <Label className="px-1">
                    {data.oddsType}
                  </Label>
                  {t('PLATE')}
                </>
              ),
              data => (
                <>
                  <Label className="content">{`${data.midType}`}</Label>
                  <Label className="content">{`【${data.displayname}】`}</Label>
                  <Label className="mx-1">@</Label>
                  <Label className="odds">{data.odds}</Label>
                </>
              ),
              data => (<Label className="d-flex justify-content-center">{data.deleteFlag === 0 ? data.betAmount : 0}</Label>),
              data => (
                <Label>{`${data.returnValue || 0}%/${data?.memReturn || 0}`}</Label>
              ),
              data => (<Label className={data.deleteFlag === 1 ? 'positiveValue' : (data.memAmount >= 0 ? 'positiveValue' : 'negativeValue')}>{data.deleteFlag === 1 ? '未结算' : data.memAmount}</Label>),
              data => (<Label>{`${data.zc}%`}</Label>),
              data => (<Label className={data.totalResult >= 0 ? 'positiveValue' : 'negativeValue'}>{data.totalResult}</Label>),
              data => (<Label>{data.remark || ''}</Label>),
              data => (<Label className={data.deleteFlag === 0 ? 'installment' : 'negativeValue'}>{data.deleteFlag === 0 ? t('NORMAL') : t('CANCEL')}</Label>),
              data => (
                <Label
                  className="lotteryName url"
                  onClick={() => onClickById((data.deleteFlag === 0 ? 1 : 0), data.id, data.lotteryType, data.installments)}
                >
                  {data.deleteFlag === 0 ? t('CANCEL') : t('REVERT')}
                </Label>
              ),
            ]
          }
          tableFooter={[[{
            value: `${t('CURRENT_PAGE_TOTAL')}: ${(betQueryResult && betQueryResult?.sum?.thisBetCount) || 0}${t('TIMES')}`,
            colspan: 6,
            className: 'textRight pr-1 total tableSum'
          },
          {
            value: (_detailItemSum && _detailItemSum.thisBetAmt) || 0,
            colspan: 1,
            className: 'textRight tableSum'
          },
          {
            colspan: 1,
            className: 'tableSum'
          },
          {
            value: (_detailItemSum && _detailItemSum.thisMemAmt) || 0,
            colspan: 1,
            className: 'tableSum'
          }, {
            colspan: 1,
            className: 'tableSum'
          },
          {
            value: (_detailItemSum && _detailItemSum.thisResult) || 0,
            colspan: 1,
            className: 'tableSum'
          },
          {
            colspan: 3,
            className: 'tableSum'
          }], [{
            value: `${t('TOTAL_COUNT')}: ${(betQueryResult && betQueryResult?.sum?.totalRecord) || 0}${t('TIMES')}`,
            colspan: 6,
            className: 'textRight pr-1 total tableSum'
          },
          {
            value: (_detailItemSum && _detailItemSum.totalBetAmt) || 0,
            colspan: 1,
            className: 'textRight tableSum'
          },
          {
            colspan: 1,
            className: 'tableSum'
          },
          {
            value: (_detailItemSum && _detailItemSum.totalMemAmt) || 0,
            colspan: 1,
            className: 'tableSum'
          },
          {
            colspan: 1,
            className: 'tableSum'
          },
          {
            value: (_detailItemSum && _detailItemSum.totalResult) || 0,
            colspan: 1,
            className: 'tableSum'
          },
          {
            colspan: 3,
            className: 'tableSum'
          }]]}
          tableHeaderStyle={
            ['smallCol']
          }
        />
      </div>
      <div className="d-flex w-full relative align-items-center justify-content-center">
        <Pagination
          dropdownList={PagesList}
          totalRecord={(_detailItemSum && _detailItemSum?.totalRecord) || 0}
          onClick={onPaginationClick}
          isQuery={_isQueryingDetail}
        />
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BetRevoke);