import { useEffect, useState } from 'react';

import { MdArrowBack } from 'react-icons/md';
import { connect } from 'react-redux';
import { motion } from 'framer-motion';
import { thirdPartyGamesActions } from '../../../redux/actions';

import CommonHeader from '../../Content/CommonHeader';
import LotteryReport from './LotteryReport';
import ThirdReport from './ThirdReport';
import './styles.scss';

const mapStateToProps = state => ({
  userData: state.memberReducers.userData,
});

const mapDispatchToProps = dispatch => ({
  GetInfo: () => dispatch(thirdPartyGamesActions.GetInfo()),
});

const WinLostReport = ({ GetInfo, userData }) => {
  const [_tabFlag, setTabFlag] = useState(0);
  const [_specialLoginName, setSpecialLoginName] = useState();

  const [showQueryMenu, setShowQueryMenu] = useState(true);

  useEffect(() => {
    if (userData) {
      if (userData?.isThirdPartyEnabled === 1) {
        GetInfo();
      }
    }
  }, [userData]);

  const variants = {
    hidden: {
      height: 0,
      overflow: 'hidden',
    },
    show: {
      height: 'auto',
      overflow: 'hidden',
    }
  };

  return (
    <div className="winLostReportWrapper">
      <CommonHeader
        headerName={(
          <div className="d-flex align-items-center">
            {_specialLoginName && (
            <div className="d-flex align-items-center me-2 backIcon" onClick={() => setSpecialLoginName()}>
              <MdArrowBack className="h5 mb-0 text-white" />
              返回
            </div>
            )}
            {`报表查询${_specialLoginName ? `/ ${_specialLoginName}` : ''}`}
          </div>
        )}
      />
      <motion.div
        variants={variants}
        initial={{
          overflow: 'hidden',
          height: 'auto'
        }}
        animate={showQueryMenu ? 'show' : 'hidden'}
      >
        <div className="tabWrapper">
          <div className={`${_tabFlag === 0 && 'selected'}`} onClick={() => { setTabFlag(0); setSpecialLoginName(); }}>游戏彩种</div>
          {userData?.isThirdPartyEnabled === 1 && <div className={`${_tabFlag === 1 && 'selected'}`} onClick={() => { setTabFlag(1); setSpecialLoginName(); }}>第三方游戏</div>}
        </div>
      </motion.div>
      {
        _tabFlag === 0
          ? <LotteryReport showQueryMenu={showQueryMenu} setShowQueryMenu={setShowQueryMenu} _specialLoginName={_specialLoginName} setSpecialLoginName={setSpecialLoginName} />
          : <ThirdReport showQueryMenu={showQueryMenu} setShowQueryMenu={setShowQueryMenu} _specialLoginName={_specialLoginName} setSpecialLoginName={setSpecialLoginName} />
      }
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(WinLostReport);