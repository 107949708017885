import { StatusCodes } from 'http-status-codes';

import { SET_MESSAGE } from '../actionTypes';
import { apiService } from '../../../library';
import appActions from '../appActions';

const setMessage = data => ({
  type: SET_MESSAGE,
  value: data
});

const GetMessage = (obj, callback = () => {}) => dispatch => {
  apiService.get('/Message/GetMessage', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      callback(false);
      return dispatch(setMessage(data.result));
    }
  }).catch(error => {
    callback(false);
    Promise.reject(error);
  });
};

const DelMessage = (id, obj) => dispatch => {
  apiService.post('/Message/DelMessage', id).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'success',
          data: {
            message: data.msg
          }
        }));

        return dispatch(GetMessage(obj));
      }
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: true,
      type: 'confirmation',
      data: {
        message: error.response.data.msg
      }
    }));
    Promise.reject(error);
  });
};

const NewMessage = (obj, callback = () => {}) => dispatch => {
  apiService.post('/Message/NewMessage', obj).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        return dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'success',
          data: {
            message: data.msg,
            onConfirmationClick: callback
          }
        }));
      }
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: true,
      type: 'confirmation',
      data: {
        message: error.response.data.msg
      }
    }));
    Promise.reject(error);
  });
};

const GetMessageDetail = (id, callback = () => {}) => dispatch => {
  apiService.get(`/Message/MessageDetail/${id}`).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      return dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'messageDetail',
        data: data.result
      }));
    }
  }).catch(error => {
    callback(false);
    dispatch(appActions.updateModalAlert({
      visible: true,
      type: 'confirmation',
      data: {
        message: error.response.data.msg
      }
    }));
    Promise.reject(error);
  });
};


export default {
  GetMessage,
  DelMessage,
  NewMessage,
  GetMessageDetail,
};