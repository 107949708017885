import { useEffect, useState } from 'react';

import { Button, Checkbox, Input, Radio } from 'antd';
import { Col, Label, Row } from 'reactstrap';
import { connect } from 'react-redux';

import { useTranslation } from 'react-i18next';

import { appActions, noticeActions } from '../../../redux/actions';
import './styles.scss';


const mapStateToProps = state => ({
  editNoticeData: state.noticeReducers.editNoticeData,
  userData: state.memberReducers.userData,
  isValidNameMsg: state.agentReducers.isValidNameMsg,
  isValidLoginIdMsg: state.agentReducers.isValidLoginIdMsg,
});

const mapDispatchToProps = dispatch => ({
  getEditNoticesInfo: (id, callback) => dispatch(noticeActions.GetEditNoticesInfo(id, callback)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  saveNotice: (obj, callback) => dispatch(noticeActions.saveNotice(obj, callback)),
});

const PublishAnnoucement = ({ callback = {}, isEdit, getEditNoticesInfo, data, editNoticeData, updateModalAlert, saveNotice }) => {
  const { t } = useTranslation();
  const [_info, setInfo] = useState([]);
  const [_lotteryList, setLotteryList] = useState([]);
  const [checkedKeys, setCheckedKeys] = useState(new Set());


  useEffect(() => {
    getEditNoticesInfo(data?.id);
  }, [data]);

  useEffect(() => {
    if (!isEdit) {
      setInfo([]);
      setCheckedKeys(new Set());
      setLotteryList(editNoticeData?.gameLottery);
    } else if (editNoticeData && JSON.stringify(editNoticeData) !== JSON.stringify(_info)) {
      setInfo(editNoticeData);
      setLotteryList(editNoticeData?.gameLottery);
      setCheckedKeys(new Set(editNoticeData?.gameCategory || []));
    }
  }, [editNoticeData]);


  const saveButton = () => {
    const values = [...checkedKeys];
    if (_info?.isChange || values?.length > 0) {
      const updateObj = _info;
      updateModalAlert({
        type: 'loading',
        visible: true,
      });
      updateObj.gameCategory = values;
      saveNotice(updateObj, callback);
    } else {
      updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: '无变动'
        }
      });
    }
  };

  const onInfoValueChange = (val, field) => {
    const newItems = _info;
    newItems[field] = val;
    newItems.isChange = true;
    setInfo(newItems => ({
      ...newItems, field: val
    }));
  };

  const resetDefault = () => {
    callback();
    // if (!isEdit) {
    // } else {
    //   getEditNoticesInfo(data?.id);
    // }
  };

  const ReceiveMethod = [
    {
      label: '指定用户',
      value: 0,
    },
    {
      label: '所有用户',
      value: 1,
    },
    {
      label: '所有代理',
      value: 2,
    }, {
      label: '所有会员',
      value: 3,
    }
  ];
  const AnnouceMethod = [
    {
      label: '普通跑马灯',
      value: 0,
    },
    {
      label: '弹出公告',
      value: 1,
    },
    {
      label: '弹出公告+跑马灯',
      value: 2,
    }
  ];
  const GameGroupReceiver = [
    {
      label: '所有用户',
      value: '0',
    },
    {
      label: '按彩种类别',
      value: '1',
    }
  ];

  return (
    <div className="PublishAnnoucementWrapper">
      <div className="mx-0 bg-white my-2  border-1">
        <Row className="m-0 my-1 p-0">
          <table className="p-3 m-0" cellSpacing="0" cellPadding="0" border="0">
            <tbody>
              <tr>
                <td className="titleHeader" colSpan={2}>
                  公告信息
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">公告内容</td>
                <td>
                  <span className="textAreaInput">
                    <Input.TextArea
                      value={_info?.content || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text, 'content')}
                    />
                  </span>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">公告类型</td>
                <td>
                  <div className="d-flex my-1">
                    {
                      AnnouceMethod.map(d => (
                        <div key={`rdTypeGroup_${d.value}`}>
                          <Radio
                            checked={_info?.type === Number(d.value)}
                            onChange={() => onInfoValueChange(Number(d.value), 'type')}
                          >
                            {d?.label}
                          </Radio>
                        </div>
                      ))
                    }
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">接收方式</td>
                <td>
                  <div className="d-flex my-1">
                    {
                      ReceiveMethod.map(d => (
                        <div key={`rdReceiverTypeGroup_${d.value}`}>
                          <Radio
                            checked={_info?.receiverType === Number(d.value)}
                            onChange={() => {
                              if (Number(d.value) !== 0) {
                                setInfo({
                                  ..._info, isChange: true, receiver: '', receiverType: Number(d.value)
                                });
                              } else {
                                onInfoValueChange(Number(d.value), 'receiverType');
                              }
                            }}
                          >
                            {d?.label}
                          </Radio>
                        </div>
                      ))
                    }
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">彩种组别</td>
                <td>
                  <div className="d-flex p-0 lotterys">
                    <div className="d-flex justify-content-center align-items-center ">
                      {
                        GameGroupReceiver.map(d => (
                          <div key={`rdGameGroup_${d.value}`}>
                            <Radio
                              disabled={_info?.receiverType === 0}
                              checked={_info?.gameType === Number(d.value)}
                              onChange={() => onInfoValueChange(Number(d.value), 'gameType')}
                            >
                              <span className="text-max">{d?.label}</span>
                            </Radio>
                          </div>
                        ))
                      }
                    </div>
                    {
                      _lotteryList && _lotteryList.map(d => (
                        <Checkbox
                          className=" m-1 p-1 justify-content-center align-items-center"
                          disabled={_info?.gameType === 0 || _info?.receiverType === 0}
                          checked={checkedKeys.has(d?.key)}
                          onChange={e => {
                            const { checked: ck } = e.target;
                            const copySet = new Set();
                            checkedKeys.forEach(value => {
                              copySet.add(value);
                            });
                            if (!ck) {
                              copySet.delete(d.key);
                            } else {
                              copySet.add(d.key);
                            }
                            setCheckedKeys(copySet);
                          }}
                        >
                          <span className="text-max">{d?.value}</span>
                        </Checkbox>
                      ))
                    }
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">接收人</td>
                <td>
                  <div className="longInput">
                    <Input
                      className="d-inline col-1"
                      type="text"
                      value={_info?.receiver || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text, 'receiver')}
                      disabled={_info?.receiverType !== 0}
                    />
                    <Label className="mx-2 justify-content-center p-1 align-item-center">(请用分号分隔多个接收人)</Label>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </Row>
      </div>
      <Row className="mb-5 my-2 p-2">
        <Col className="d-flex justify-content-center py-2 border-top">
          <Button className="button handle mx-1" onClick={() => saveButton()}>{t('SAVE')}</Button>
          <Button className="button find mx-1" onClick={() => resetDefault()}>{t('CANCEL')}</Button>
        </Col>
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PublishAnnoucement);