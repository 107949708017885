export default {
  translation: {
    COMPANY_NAME: '长江彩世界',
    INTIME_SHEET: '即时注单',
    USER_MANAGEMENT: '用户管理',
    BET_WARNING: '注单预警',
    WALLET_MANAGEMENT: '个人钱包',
    INDIVIDUAL_MANAGEMENT: '个人管理',
    TRANSACTION_MANAGEMENT: '交易管理',
    ODDS_MANAGEMENT: '赔率设置',
    REPORT_MANAGEMENT: '报表查询',
    SYSTEM_SETTINGS: '系统设置',
    INSTALLMENTS_MANAGEMENT: '盘口管理',
    GAME_RESULT_MANAGEMENT: '开奖结果',
    ONLINE_STATISTICS: '在线统计',
    THIRD_PARTY_GAME_MANAGEMENT: '第三方游戏',
    LOG_OUT: '退出系统',
    MORE: '更多',
    CURRENT_SELECTED: '当前选中',
    LATEST_ANNOUNCEMENT: '最新公告',
    CHECK: '查询',
    AUTO_RESTOCK_SETTINGS: '自动补货设置',
    RESTOCK_RECORDS: '补货变更记录',
    BET_ORDER_BACK_UP: '注单备份',
    REVOKE: '撤销',
    ENTRIES: '条',
    TOTAL: '共',
    PAGE: '页',
    GO_TO: '前往',
    LOTTERY: '彩种',
    INSTALLMENTS: '期数',
    BET_TYPE: '下注种类',
    CATEGORY: '类型',
    STATUS: '状态',
    SEARCH: '搜索',
    DATE: '日期',
    DATE_RANGE: '日期范围',
    START_END_DATE: '开始日期  至  结束日期',
    THIS_WEEK: '本周',
    PREVIOUS_WEEK: '上一周',
    PREVIOUS_2_WEEK: '上两周',
    THIS_MONTH: '本月',
    PREVIOUS_MONTH: '上月',
    TO: '至',
    ORDER_NUM: '序号',
    BET_NUMBER: '下注编号',
    BET_TIME: '下注时间',
    ACCOUNT_NAME: '帐号名',
    BET_CONTENT: '投注内容',
    BET: '下注',
    BET_RESULT: '下注结果',
    THIS_LVL_PERCENTAGE: '本级占成',
    THIS_LVL_RESULT: '本级结果',
    REMAINED_AMOUNT: '余额',
    BEFORE: '前',
    AFTER: '后',
    REMARK: '备注',
    REBATE: '退水',
    AMOUNT: '金额',
    DI: '第',
    NORMAL: '正常',
    CANCEL: '取消',
    PLATE: '盘',
    CURRENT_PAGE_TOTAL: '此页面统计',
    TOTAL_COUNT: '总计',
    TIMES: '笔',
    FIND: '查找',
    ALL: '全部',
    TODAY: '今日',
    DAY_OF_WEEK_1: '星期一',
    DAY_OF_WEEK_2: '星期二',
    DAY_OF_WEEK_3: '星期三',
    DAY_OF_WEEK_4: '星期四',
    DAY_OF_WEEK_5: '星期五',
    DAY_OF_WEEK_6: '星期六',
    DAY_OF_WEEK_0: '星期日',
    ZSDL: '直属代理',
    ZSHY: '直属会员',
    XJDL: '下级代理',
    XJHY: '下级会员',
    SYDL: '所有代理',
    QBHY: '全部会员',
    ZSZZH: '直属子账号',
    XJZZH: '下级子帐号',
    ZZH: '子帐号',
    DEPOSIT: '存款',
    WITHDRAWAL: '提款',
    SAFETY_SETTING: '安全设置',
    TRANSFER: '转账',
    CARD_SETTING: '银行卡设置',
    TRANSCTION_HISTORY: '交易历史',
    GAME_SETTING: '游戏设置',
    BETTING_QUERY: '注单查询',
    CHECK_RETURN: '处理赚水',
    HISTORY_RESULT_MANAGEMENT: '历史开奖管理',
    USER_PROFILE: '信用资料',
    OPERATE_RECORD: '操作日记',
    LOGIN_LOG: '登录日志',
    CHANGE_PASSWORD: '变更密码',
    HISTORY_TASK: '历史任务',
    PROMOTE_LINK: '推广链接',
    MFA_SETTING: 'MFA 设置',
    TRANSCTION_QUERY: '交易查询',
    HANDLE_AGENT_RETURN: '处理代理赚水',
    DEPOSIT_AUDIT: '存款审核',
    WITHDRAWAL_AUDIT: '提款审核',
    AMOUNT_CHANGE: '人工加/减额',
    MESSAGE_CENTER: '消息中心',
    ACCOUNT_DETAIL: '账户流水',
    VOUCHER: '优惠码',
    GIFT_SETTING: '奖品设置',
    GAME_TRANSFER_AUDIT: '游戏转账审核',
    OFFLINE_AUDIT: '线下审核',
    ONLINE_RECORD: '线上记录',
    DEPOSIT_RECORD: '存款记录',
    USDT_WITHDRAWAL_AUDIT: 'USDT提款审核',
    FUTURE_ODDS: '未来赔率',
    CURRENT_ODDS: '当前赔率',
    DEFAULT_ODDS: '默认赔率',
    DECREASE_ODDS_SETTING: '降赔设置',
    DEFAULT_DECREASE_ODDS_SETTING: '默认降赔设置',
    COMPANY_RETURN: '公司退水、赔率差',
    WIN_LOSE_REPORT: '输赢报表',
    CATEGORY_REPORT: '分类报表',
    BILL_QUERY: '账变报表',
    FINANCE_REPORT: '财务总报表',
    DEPOSIT_WITHDRAWAL_REPORT: '存提款报表',
    UNDERLING_DETAIL: '下线明细',
    IN_OUT_STATISTICS: '出入款统计',
    BETTING_TOP_LIST: '下注榜单',
    MEMBER_STATISTICS_REPORT: '会员统计报表',
    IN_OUT_STATISTICS_REPORT: '出入金、统计报表',
    DEPOSIT_WITHDRAWAL_STATISTICS_REPORT: '存提款统计报表',
    MEMBER_GAME_REPORT: '会员游戏报表',
    COMPANY_GAME_REPORT: '公司游戏报表',
    SYSTEM_SETTING: '系统设置',
    LOTTERY_MANAGEMENT: '彩种管理',
    COMPANY_INFO: '公司信息',
    SYSTEM_NOTICE: '系统公告',
    SYSTEM_LOG: '系统日志',
    RECEIVED_ACCOUNT_SETTING: '收款账户设置',
    OUTSIDE_WEB_SETTING: '外补网站管理',
    THIRD_PARTY_RECEIVER_SETTING: '收款第三方设置',
    USER_CATEGORY: '用户自动分类',
    RECEIVER_OFFLINE_SETTING: '收款线下设置',
    EMAIL_SMS_SETTING: '邮箱/短信设置',
    GLOBAL_WITHDRAWAL_SETTING: '全局提款设置',
    CAPTCHA_SETTING: '验证码管理',
    FEES_SETTING: '费率设置',
    WITHDRAWAL_BANK: '提款绑定银行',
    DETAILS: '明细',
    CONFIRM: '确定',
    REMINDER: '提示',
    ZC: '占成',
    NAME: '名称',
    LEVEL: '级别',
    ODDS: '赔率',
    TO_REBATE: '应退',
    COMMISSION: '赚佣',
    WIN_LOST: '赚赔',
    RESULT: '结果',
    HAND_IN: '上交',
    VOLUME: '货量',
    UPPER_LEVEL: '上级',
    SETTLEMENT: '交收',
    AUTO_RESTOCK_SETTING: '自动补货设置',
    RESTOCK_CHANGE_HISTORY: '补货变更记录',
    TYPE: '种类',
    BET_AMOUNT: '注额',
    AUTO_RESTOCK: '自动补货',
    MANUAL_RESTOCK: '手动补货',
    SAVE: '保存',
    TIME_TO_CLOSE: '距封盘',
    TODAY_PROFIT: '今日输赢',
    INSTALLMENT: '期',
    INSTALLMENT_DRAW: '期开奖',
    REFRESH: '刷新',
    OPERATION_TIME: '变更时间',
    OPERATION_TYPE: '变更类别',
    VALUE_BEFORE: '原始值',
    VALUE_AFTER: '变更值',
    UPDATED_BY: '操作人',
    DEPENDENCY: '归属',
    TARGET_ACCOUNT: '被改帐号',
    REVERT: '还原',
    OPERATION: '操作',
    USERNAME: '用户名',
    PASSWORD: '密码',
    CAPTCHA: '验证码',
    LOGIN: '登录',
    LOGIN_NAME: '登陆名',
    CLIENT_PLATFORM: '客户端',
    ACCOUNT_BALANCE: '账户余额',
    PAN_TYPE: '盘类',
    ADDRESS: '地址',
    LINE: '线路',
    LAST_ACTIVITY_TIME: '最后活动时间',
    TOTAL_BALANCE: '总余额',
    TOP_COMPANY: '总公司',
    COMPANY: '公司',
    TOP_AGENT: '总代',
    LEVEL1_AGENT: '一级代理',
    LEVEL2_AGENT: '二级代理',
    LEVEL3_AGENT: '三级代理',
    LEVEL4_AGENT: '四级代理',
    LEVEL5_AGENT: '五级代理',
    LEVEL6_AGENT: '六级代理',
    LEVEL7_AGENT: '七级代理',
    LEVEL8_AGENT: '八级代理',
    MEMBER: '会员',
    AGENT: '代理',
    ACTIVE: '启用',
    INACTIVE: '停用',
    SETTING: '设置',
    DEFAULT: '默认',
    DEFAULT_VISITOR: '访客默认',
    PRIVATE: '私有',
    THIS_PAGE: '本页',
    ONLINE: '在线',
    UPPER_LEVEL_ACCOUNT: '上级账号',
    USER_TYPE: '用户类型',
    ACCOUNT: '账号',
    ADD: '新增',
    CREATE_DATE: '新增日期',
    ORDER_BY: '排序',
    EDIT: '修改',
    LOTTERY_REBATE: '彩票退水',
    GAMES_REBATE: '视讯，棋牌退水',
    ODDS_DIFF: '赔率差',
    RESULT_NOTIFICATION: '输赢提示',
    EDIT_LOG: '修改日志',
    BET_LIST: '注单',
    CHECK_TRANSFER_FLOW: '查看账变',
    BANK_CARD: '银行卡',
    BALANCE_MORE_THAN: '余额大于',
    BALANCE_LESS_THAN: '余额小于',
    ADD_AGENT: '新增代理',
    ADD_SUB_AGENT: '新增子账号',
    GAME_CODE: '游戏代码',
    GAME_NAME: '游戏名称',
    GAME_TYPE: '游戏种类',
    GAME_ACCOUNT: '游戏账户',
    API_ADDRESS: 'Api 地址',
    TRANSFER_LIMIT: '转账限额',
    STATUS_STATEMENT: '状态说明',
    UP_AGENT: '上级代理',
    XING_MING: '姓名',
    BEFORE_BET_BALANCE: '下注前余额',
    AFTER_BET_BALANCE: '下注后余额',
    BETS: '注单数',
    WIN_LOSE: '输赢',
    EFFECTIVE: '有效',
    PAUSE: '暂停',
    PUBLIC: '公有',
    PREVIEW: '预览',
    COPY: '复制',
    NUMBER: '号码',
    DIRECT_WX: '直属微信',
    PLATE_TYPE: '盘口',
    REGISTER_TIME: '注册时间',
    TRANSFER_STATUS: '转账状态',
    RECYLE: '回收',
    GAME_BALANCE: '游戏余额',
    WIN: '赢',
    LOSE: '输',
    PARENT_ACCOUNT: '主帐号',
    SUB_ACCOUNT: '子帐号',
    DELETE: '删除',
    AMENDED_LOG: '变更日志',
    AUDIT_SETTING: '审核设置',
    HANDLED_BY_LOG: '操作日志(操作者)',
    RESET: '重置',
    DISABLED: '禁用',
    ORDER_INFORMATION: '订单信息',
    RECENT_FLOW: '近期流水',
    RECENT_TRANSACTION: '近期交易',
    LOTTERY_BET: '彩票注单',
    GAME_BET: '游戏注单',
    SORTING: '修改排序',
    CURRENT_INSTALLMENTS: '当前期数',
    BET_OPEN_TIME: '开盘时间',
    SYSTEM_BET_OPEN_TIME: '系统开盘时间',
    BET_CLOSE_TIME: '封盘时间',
    SYSTEM_BET_CLOSE_TIME: '系统封盘时间',
    BET_RESULT_TIME: '开奖时间',
    FUNCTION: '功能',
    CLOSE_PLATE: '关盘',
    RECOVER: '恢复',
    PUBLISH_ANNOUCEMENT: '发布公告',
    TIME: '时间',
    NOTICE_TITLE: '公告标题',
    NOTICE_CONTENT: '公告详情',
    RECEIVER: '接受人',
    SENDER: '发送人',
    RECEIVE_METHOD: '接受方式',
    ADD_MEMBER: '新增会员',
    ADD_DIRECT_MEMBER: '新增直属会员',
    ACCOUNT_TYPE: '账号类型',
    OPERATE_TYPE: '操作类型',
    OPERATE_CONTENT: '操作内容',
    LOG_OLD_VALUE: '操作前旧值',
    LOG_NEW_VALUE: '操作后新值',
    ADD_PAYMENT_ACCOUNT: '新增收款账户',
    PLATFORM: '平台',
    ACCOUNT_NUMBER: '帐号号码',
    DAILY_LIMIT: '每日限额',
    ACCOUNT_OPEN_ADDRESS: '帐号开户地址',
    ACCOUNT_OPEN_NAME: '帐号开户名',
    BELONGS_BANK: '所属银行',
    MAX_SINGLE_TRANSACTION: '单笔最大',
    MIN_SINGLE_TRANSACTION: '单笔最大',
    ACCOUNT_CATEGORISE: '账户分组',
    BANK_CARD_NUMBER: '银行卡号',
    BANK_CARD_NAME: '开户名',
    EXTERNAL_WEB_NAME: '外补网站名称',
    WEB_NAME: '网站名称',
    TOGGLE_STATUS: '切换状态',
    WEB_ADDRESS: '网站地址',
    ADD_EXTERNAL_WEB: '新增外补网站',
    SELECT_ALL: '全选',
    MANUAL_AUDIT: '手动审核',
    MANUAL_CONFIRM: '手动确认',
    TEST: '测试',
    PAYOUT: '出款',
    RELOAD: '充值',
    APPLY_STATUS: '使用状态',
    PLATFORM_BELONGS: '所属平台',
    QUOTA_SPEND_TODAY: '今日已用额度',
    DAILY_QUOTA: '每日限额',
    MIN_SINGLE_PAYMENT: '单笔最少支付金额',
    MAX_SINGLE_PAYMENT: '单笔最大支付金额',
    PAYMENT_CATEGORY: '支付分组',
    PAYMENT_TYPE: '支付方式',
    NAME_REMARK: '名称备注',
    PAYMENT_NAME: '支付名称',
    RECOGNISE_CODE: '识别Code',
    MOVE_UP: '上升',
    MOVE_DOWN: '下降',
    USER_CLASS: '用户分类',
    MIN_ACCUMULATE_VALUE: '累计最少金额',
    MAX_ACCUMULATE_VALUE: '累计最多金额',
    MAX_SINGLE_RELOAD_VALUE: '单笔最大充值金额',
    COMPILE: '编辑',
    MIN_SINGLE_DEPOSIT_VALUE: '单笔最低存款金额',
    MAX_SINGLE_DEPOSIT_VALUE: '单笔最高存款金额',
    CLIENT_PLATFORM_BELONGS: '所属客户端',
    DEPOSIT_STATUS: '存款状态',
    STATUS_REMARK: '状态说明',
    ADD_SETTING: '添加设置',
    ALLOW: '允许',
    BAN: '禁止',
    ADD_SMS_PORT: '添加短信接口',
    ADD_EMAIL_PORT: '添加邮箱接口',
    SMS_PLATFORM: '短信平台',
    SEND_INTERVAL_IN_SEC: '发送间隔(秒)',
    MAILBOX_ALT_NAME: '邮箱别名',
    PORT: '端口',
    MIN_SINGLE_WITHDRAW_VALUE: '单笔最低提款金额',
    MAX_SINGLE_WITHDRAW_VALUE: '单笔最高提款金额',
    DAILY_WITHDRAW_LIMIT: '单日最高提款金额',
    WITHDRAW_FEES_RATE: '提款手续费率',
    FOC_WITHDRAW_TIME: '免费提款次数',
    CHARGE_RATE_FOR_EXCEED_WITHDRAW: '超过免费提款次数后提款手续费率',
    DAILY_WITHDRAW_TIME: '单日可提款次数',
    WITHDRAW_TURNOVER_RATE: '提款流水倍数',
    WITHDRAW_STATUS: '提款状态',
    PING1: '平一',
    PING2: '平二',
    PING3: '平三',
    PING4: '平四',
    PING5: '平五',
    PING6: '平六',
    PING7: '平七',
    TEMA: '特码',
    OPERATE_TIME: '操作时间',
    OTP_NUMBER: '验证号码',
    OTP_TYPE: '验证类型',
    RECEIVER_ADDRESS: '接收地址',
    OPERATE_USER: '操作用户',
    STATEMENT: '说明',
    EMAIL: '邮件',
    PHONE: '手机',
    OPERATE_SEND_USER: '发送用户',
    OPERATE_SEND_ADDRESS: '发送地址',
    RENEW_TIME: '更新时间',
    EXCHANGE_RATE_REMARK: '汇率备注',
    WITHDRAW_FLOAT_RATE: '提款浮动汇率',
    DEPOSIT_FLOAT_RATE: '充值浮动汇率',
    AUTO_RENEW_INFO: '自动汇率说明',
    AUTO_RENEW_EX_RATE: '自动更新汇率',
    DEPOSIT_RATE: '充值汇率',
    WITHDRAW_RATE: '提款汇率',
    CURRENCY_NAME: '币种名称',
    CURRENCY_CODE: '币种代码',
    YES: '是',
    NO: '否',
    TYPE_NAME: '类型名称',
    BANK_CARD_PREFIX: '银行卡前缀',
    BANK_CODE: '银行代码',
    ACTIVE_COMPANY: '启用公司',
    DESELECT_ALL: '全部不选',
    OPEN_RESULT_SETTING: '官网开奖网设置',
    OFFICIAL_NAME: '官方名称',
    OFFICIAL_ADDRESS: '官方网址',
    REFERENCE_NAME: '参考名称',
    REFERENCE_ADDRESS: '参考网址',
    LOTTERY_RESULT_CANCEL: '官方停售，奖期取消',
    PLEASE_SELECT_DATE: '请选择日期',
    ACCUMULATED_VALUE: '累计金额',
    DECREASE_ODDS_VALUE: '降赔值',
    LOWEST_ODDS: '最低赔率',
    HIGHEST_QUANTITY: '最大货量',
    AUTO_ON_OFF: '自动开关',
    MORATORIUM: '停押',
    TOTAL_VALUE: '总额',
    SEND: '传送',
    COMPANY_MANAGEMENT: '公司管理',
    COMPANY_SETTING: '公司设置',
    LOTTERY_SETTING: '彩种设置',
    SYSTEM_PERMISSION_SETTING: '系统权限设置',
    COMPANY_HOSTNAME_SETTING: '公司域名配置',
    THIRD_TOPUP_SETTING: '第三充值管理',
    GAME_MANAGEMENT: '游戏管理',
    SUPER_USER_LIST: '超级用户列表',
    OPEN_RESULT_ADDRESS_MANAGEMENT: '开奖地址管理',
    WHITE_LIST_MANAGEMENT: '白名单管理',
    AGENT_BACKUP_WHITE_LIST: '备份权限设置',
    SYSTEM_SERVICE_MANAGEMENT: '系统服务管理',
    ALL_MORATORIUM: '全停押',
    ALL_OPEN: '全开启',
    TIME_TO_OPEN_RESULT: '距开奖',
    REGISTER: '注册',
    TOPUP_TYPE: '充值类型',
    TOPUP_METHOD: '充值方式',
    SELECT_WALLET: '选择钱包',
    REAL_NAME: '真实姓名',
    DEPOASIT_AMOUNT: '存款金额',
    CONFIRM_TOPUP: '确定充值',
    PROFIT: '盈亏',
    RESTOCK: '补货',
    AGENT_LOGIN: '信用代理登入',
    NO_ACCOUNT: '没有账号',
    PLEASE_REGISTER: '请注册',
    AGENT_REGISTER: '信用代理注册',
    HAVE_ACCOUNT: '已有账号',
    PLEASE_LOGIN: '请登入',
    BACK_PAGE: '返回页面',
    AFF_CODE: '邀请码',
    CONFIRM_PASSWORD: '确认密码',
    INCREASEMENT: '增量',
    DEFAULT_COMPANY_RETURN: '默认退水、赔率差',
    DEFAULT_AUTO_RESTOCK_SETTINGS: '默认自动补货设置',
    ORDER_ID_GENERATED: '订单生成',
    ORDER_ID: '订单号',
    WALLET_NAME: '钱包名称',
    WALLET_ADDRESS: '钱包地址',
    TRANSACTION_QUANTITY: '交易数量',
    TOP_UP_REMINDER: '成功付款后,将会自动到帐,并弹出到帐提示。如有疑问,请联系客服!',
    COMPLETE_TOP_UP: '完成充值',
    BANK_CARD_BANK: '开户行',
    BANK_CARD_ADDRESS: '开户行地址',
    TOP_UP_BANK_NUM: '开户账号',
    SCAN_ENDPOINT: '扫码终端',
    ACCOUNT_KEYCODE: '识别码',
    ATTENTION: '注意',
    TOP_UP_ATTENTION_TEXT: '转账备注必须正确填写识别码，否则影响上分。如因识别码无效造成上不了分，本公司则不负责。',
    TOP_UP_ISSUEING_BANK: '开户行',
    TOP_UP_NAME: '开户名',
    CANCEL_TOP_UP_ORDER: '取消订单',
    ARE_YOU_SURE_YOUR_TOP_UP_HAS_SUCCEED: '您确认是否已经充值成功？',
    BOGUS_BET_WARNING_MONITOR: '注单预警监控',
    SET_SUGGESTION_AMOUNT: '设置提示金额',
    BIG_BET_LIMIT: '大额限注设置',
    QUICK_BET_MONITOR: '快捷注单监控',
    BIG_BET_BUHUO_SETTING: '大额补货设置',
    BOGUS_BET_WARNING: '连号预警设置',
    ABNORMAL_BET_QUERY: '异常注单查询',
    TOTAL_ONLINE_STATISTICS: '总在线统计',
    MANUAL: '手动',
    SECOND: '秒',
    MINUTE: '分钟',
    HOUR: '小时',
    PLEASE_SELECT: '请选择',
    SHOW_RECENT: '显示最近',
    PROJECT: '项目',
    THE_FIRST_AMOUNT_OF_WARNING_VIRTUAL_GOODS: '第一次金额预警(虚货)',
    THE_FIRST_AMOUNT_OF_WARNING_ACCOUNTED_FOR: '第一次金额预警(实占)',
    THE_SECOND_AMOUNT_OF_WARNING_VIRTUAL_GOODS: '第二次金额预警(虚货)',
    THE_SECOND_AMOUNT_OF_WARNING_ACCOUNTED_FOR: '第二次金额预警(实占)',
    SELECT_CATEGORY_AMOUNT_ALERT: '选择分类金额预警',
    BET_TASK_BACKUP: '注单任务备份',
    TASK_NAME: '任务名称',
    LOTTERY_NAME: '彩种名称',
    TASK_INSTALLMENT: '任务期数',
    JIESUAN_TYPE: '结算类型',
    CREATE_TIME: '创建时间',
    TASK_STATUS: '任务状态',
    BLOCK_NUMBER_SETTING: '封号设置',
    BLOCK_NUMBER: '封号',
    SELECT: '选择',
    UNUSUAL_BET: '异常注单',
    UNUSUAL: '异常',
    PLEASE_SELECT_INSTALLMENTS: '请选择期数',
    CLASS: '分类',
    TO_UNUSUAL: '转到异常',
    DELETE_UNUSUAL: '删除异常',
    TOP_COMPANY_RESTOCK: '总公司补货',
    COMPANY_RESTOCK: '公司补货',
    TOP_AGENT_RESTOCK: '总代补货',
    LEVEL1_RESTOCK: '一级补货',
    LEVEL2_RESTOCK: '二级补货',
    LEVEL3_RESTOCK: '三级补货',
    LEVEL4_RESTOCK: '四级补货',
    LEVEL5_RESTOCK: '五级补货',
    LEVEL6_RESTOCK: '六级补货',
    LEVEL7_RESTOCK: '七级补货',
    LEVEL8_RESTOCK: '八级补货',
    LEVEL9_RESTOCK: '九级补货',
    LEVEL10_RESTOCK: '十级补货',
    LOGGED_OUT: '注销',
    SETTLED: '结算',
    UN_SETTLED: '未结算',
    MEMBER_IP: '会员IP',
    BET_NO: '注单号',
    MEMBER_ACCOUNT: '会员账号',
    MEMBER_BET: '会员下注',
    PAGE_TOTAL: '页面合计',
    CONTENT: '内容',
    NOTE_RECORDS_ARE_RETAINED_FOR_A_MAXIMUM_OF_1_DAY: '注意：记录最多被保留1天。',
    VIRTUAL_GOODS: '虚货',
    ACCOUNTED_FOR: '实占',
    THE_FIRST_AMOUNT_OF_WARNING: '第一次金额警示',
    INCREASED_AMOUNT_OF_WARNING: '增加此金额循环警示',
    FAST_SETTING: '快速设置',
    EXTERNAL_DECREASE_SETTING: '外网降赔设置',
    BET_LIMIT_TIME: '限注时间',
    BIG_BET_LIMIT_TIME: '大额限注时间',
    SINGLE_BET_LIMIT: '单注限额',
    SINGLE_BET_ACCOUNTED_FOR_LIMIT: '单注实占限额',
    NOT_SET: '未设置',
    RESTOCK_LIMIT: '补货限额',
    BIG_RESTOCK_TIME: '大额补货时间',
    COMPLETED: '已完成',
    PROCESSING: '进行中',
    PAUSING: '暂停中',
    DOWNLOAD: '下载',
    PAUSE_ALL: '暂停所有',
    START_ALL: '启用所有',
    CREATE_USER: '创建人',
    START: '开始',
    EXPORT: '导出',
    FIX: '修复',
    GENERATE: '生成',
    MODE: '模式',
    QUOTA_TYPE: '额度类型',
    REPAIRABLE: '可修复',
    USER_ACCOUNT: '用户账号',
    INITIAL_AMOUNT: '初始金额',
    END_AMOUNT: '结束金额',
    ADDITION_OR_SUBTRACTION: '加减额',
    INSTALLMENT_DATE: '期数日期',
    OUTSTANDING_AMOUNT: '未结金额',
    DIFF_TO_BE_RETURNED: '待返金额',
    OPERATE_AMOUNT: '操作金额',
    OPERATE_REMARK: '操作说明',
    CREDIT: '信用',
    CASH: '现金',
    MEMBER_DAY_BILL: '会员日结帐单',
    MEMBER_CREDIT_CHANGE: '会员额度变更',
    ABNORMAL_BET_EXPORT: '异常注单导出',
    CREDIT_MANAGEMENT: '额度管理',
    LOTTERY_STYLE_MANAGEMENT: '彩种样式管理',
    DOMAIN_MANAGEMENT: '域名管理',
    NEW_BALANCE: '新余额',
    CHANGE_AMOUNT: '变更金额',
    THEME: '主题',
    BLUE: '蓝色',
    GOLDEN: '金黃色',
    DARK_YELLOW: '深黃色',
    SUOYOU: '所有',
    LOTTERY_SELECT: '彩种选择',
    AGENT_SELECT: '代理选择',
    BET_TO_UNUSUAL: '注单转异常',
    TEMA_BET_CLOSE_TIME: '特码封盘时间',
    NON_TEMA_BET_CLOSE_TIME: '非特码封盘时间',
    LOADING: '加载中',
    DESCRIPTION: '描述',
    DEFAULT_WIN_LOST: '默认赚赔',
    CHANGE_PASSWORD_OLD_PASSWORD: '原始密码',
    CHANGE_PASSWORD_NEW_PASSWORD: '新设密码',
    CHANGE_PASSWORD_CONFIRM_PASSWORD: '确认密码',
    CHANGE_PASSWORD_PASSWORD_PLACEHOLDER: '请输入原始密码',
    CHANGE_PASSWORD_NEW_PASSWORD_PLACEHOLDER: '6-20个字母、数字或组合组成，区分大小写',
    CHANGE_PASSWORD_CONFIRM_PASSWORD_PLACEHOLDER: '请再次输入密码以确保输入无误',
    CHANGE_PASSWORD_PASSWORD_EMPTY_ERROR: '请输入原始密码',
    CHANGE_PASSWORD_NEW_PASSWORD_EMPTY_ERROR: '请输入新设密码',
    CHANGE_PASSWORD_NEW_PASSWORD_UNFIT_ERROR: '6-20个字母、数字或组合组成，区分大小写',
    CHANGE_PASSWORD_CONFIRM_PASSWORD_EMPTY_ERROR: '请输入确认密码',
    CHANGE_PASSWORD_NEW_CONFIRM_PASSWORD_MISMATCHED_ERROR: '两次输入密码不一致',
    SAFETY_CENTER_SAFEPASS: '原密码',
    SAFETY_CENTER_SAFEPASS_PLACEHOLDER: '请输入原密码',
    SAFETY_CENTER_OLD_SAFEPASS_EMPTY_ERROR: '请填写原密码',
    SAFETY_CENTER_NEW_SAFEPASS_EMPTY_ERROR: '请填写新密码',
    SAFETY_CENTER_CONFIRM_SAFEPASS_EMPTY_ERROR: '请填写确认密码',
    SAFETY_CENTER_SAFEPASS_MISMATCH_ERROR: '密码不一样',
    SAFETY_CENTER_SAFEPASS_COMBINATION_ERROR: '密码必须是 6 到 20 个字母数字',
    SAFETY_CENTER_FORGOT_PASSWORD: '忘记密码',
    SAFETY_CENTER_NEW_SAFEPASS: '新密码',
    SAFETY_CENTER_NEW_SAFEPASS_PLACEHOLDER: '请输入6-12字母+数字组合',
    SAFETY_CENTER_CONFIRM_NEW_SAFEPASS: '确认密码',
    SAFETY_CENTER_CONFIRM_NEW_SAFEPASS_PLACEHOLDER: '请再次输入密码',
    RND_NUM: '挂码',
    EMAIL_LOGIN: '邮箱登录',
    FOLLOW_BET_SETTING: '跟单设置',
    FOLLOW_BET_LOGS: '跟单日志',
  }
};