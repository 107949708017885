import { useEffect, useState } from 'react';

import { Label } from 'reactstrap';
import { connect } from 'react-redux';
import { memberActions } from '../../../redux/actions';

import { AgentDataTable, LotteryMenu } from '../..';
import './styles.scss';

const mapStateToProps = state => ({
  userInfo: state.memberReducers.userInfo,
  activeLottery: state.commonReducers.activeLottery,
});
const mapDispatchToProps = dispatch => ({
  getMyInfo: () => dispatch(memberActions.getMyInfo()),
});

const UserProfile = ({ activeLottery, userInfo, getMyInfo }) => {
  const [_selectedLottery, setLottery] = useState(activeLottery);
  const [_showMenu, setShowMenu] = useState(false);
  const [_map, setMap] = useState(new Map());
  const [_isQuerying, setIsQuerying] = useState(false);
  const [items, setItems] = useState();

  useEffect(() => {
    const timerId = setInterval(getMyInfo, 5000);
    return () => clearInterval(timerId);
  }, []);

  useEffect(() => {
    if (userInfo) {
      setIsQuerying(false);
      const mapObj = new Map();
      userInfo.zcList.forEach(item => {
        if (mapObj.has(item.lotteryType)) {
          const arr = [...mapObj.get(item.lotteryType), item];
          mapObj.set(item.lotteryType, arr);
        } else {
          mapObj.set(item.lotteryType, [item]);
        }
      });
      setMap(mapObj);
    }
  }, [userInfo]);

  useEffect(() => {
    if (_map.size > 0) {
      const _temp = _map.get(_selectedLottery.code);
      setItems(_temp);
    }
  }, [_map, _selectedLottery]);

  return (
    <div className="userProfileWrapper">
      <div className="userProfileTableWrapper d-flex p-3">
        <div className="userTable w-100">
          <div>基本信息</div>
          <div className="d-flex">
            <div>账号</div>
            <div>{userInfo && userInfo.loginId}</div>
          </div>
          <div className="d-flex">
            <div>额度类型</div>
            <div>{userInfo && userInfo.limitTypeName}</div>
          </div>
          {userInfo && userInfo.accList.map(item => (
            <div className="d-flex">
              <div>{item.accName}</div>
              <div>{`${item.moneyLimit}(余额：${item.balance})`}</div>
            </div>
          ))}
        </div>
      </div>

      <div className="menuRow">
        <div className="currentSelected" onFocus={() => {}} onMouseOver={() => setShowMenu(true)} onMouseLeave={() => setShowMenu(false)}>
          {_selectedLottery.lotteryName}
          <span className="arrow_down" />
          {_showMenu && (
          <div className="userProfileMenuWrapper" onFocus={() => {}} onMouseOver={() => setShowMenu(true)}>
            <LotteryMenu
              _list={userInfo.myLotteryAndRate}
              onClickItem={item => { setLottery(item); setShowMenu(false); }}
              selectedItem={_selectedLottery.code}
            />
          </div>
          )}
        </div>
      </div>
      <div className="userProfileTableWrapper p-3">
        <div className="mx-0 bg-white">
          <AgentDataTable
            isQuerying={_isQuerying}
            tableHeaderTitle={
            [{
              label: '玩法',
            }, {
              label: 'A盘退水',
            }, {
              label: 'B盘退水',
            }, {
              label: 'C盘退水'
            }, {
              label: 'D盘退水',
            }, {
              label: '单注金额',
            }, {
              label: '单期金额',
            }]
          }
            tableHeaderStyle={
            ['smallCol']
          }
            tableData={items || []}
            tableBodyRowProperty={
            [
              data => (<Label className="d-flex justify-content-center noWrapStyle playStyle">{data.projectName}</Label>),
              data => (<Label className="d-flex justify-content-center">{`${data.aReturn}%`}</Label>),
              data => (<Label className="d-flex justify-content-center">{`${data.bReturn}%`}</Label>),
              data => (<Label className="d-flex justify-content-center">{`${data.cReturn}%`}</Label>),
              data => (<Label className="d-flex justify-content-center">{`${data.dReturn}%`}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.itemLimit}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.projectLimit}</Label>),
            ]
          }
          />
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);