import React, { useEffect, useImperativeHandle, useState } from 'react';

import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';

import oddsTypeMenu from '../../constants/OddsManageSetting';

import { appActions, commonActions, inTimeSheetActions, oddsSettingActions } from '../../redux/actions';

import AgentDataTable from '../AgentDataTable';
import CurrentDrawPanel from '../CurrentDrawPanel';
import InTimeSheetMenuRender from '../Content/InTimeSheetMenuRender';
import InTimeSheetTableRender from '../Content/InTimeSheetTableRender';
import Pagination from '../Content/Pagination';

import './styles.scss';

const mapStateToProps = state => ({
  activeLottery: state.commonReducers.activeLottery,
  inTimeSheetOdds: state.inTimeSheetReducers.inTimeSheetOdds,
  inTimeSheetInfo: state.inTimeSheetReducers.inTimeSheetInfo,
  _selectedMainMenu: state.commonReducers.selectedSubMenu,
  userData: state.memberReducers.userData,
  userInfo: state.memberReducers.userInfo,
});

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  SaveOdds: (obj, listObj) => dispatch(oddsSettingActions.SaveOdds(obj, listObj)),
  getListOdds: oddsListObj => dispatch(inTimeSheetActions.getListOdds(oddsListObj)),
  updateSelectedSubMenu: val => dispatch(commonActions.updateSelectedSubMenu(val)),
  updateBatchOddsList: obj => dispatch(oddsSettingActions.updateBatchOddsList(obj)),
  TakeBuhuoTicket: (obj, callback) => dispatch(inTimeSheetActions.TakeBuhuoTicket(obj, callback)),
  getListLianma: (oddsListObj, callback) => dispatch(inTimeSheetActions.getListLianma(oddsListObj, callback)),
});

const InTimeSheetManageTable = ({ _selectedAmount, _isAvailable, userInfo, setAvailability = () => {}, userData, setBuhuoData, getListLianma, amountMode, setamountMode, TakeBuhuoTicket, childRef, updateBatchOddsList, passData, _selectedMainMenu = 0, updateSelectedSubMenu, inTimeSheetOdds, activeLottery, updateModalAlert, SaveOdds, getListOdds, inTimeSheetInfo }) => {
  const [_info, setInfo] = useState([]);
  const [_menu, setMenu] = useState([]);
  const [_selectedSubMenu, setSelectedSubMenu] = useState(0);
  const [_midCode, setmidCode] = useState(0);
  const [_isQueryingDetail, setIsQueryingDetail] = useState([]);
  const [_MenuOddsListObj, setMenuOddsListObj] = useState();

  const [_lianMa, setLianMa] = useState();
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(15);

  const [sortMode, setSortMode] = useState('0');
  const [oddstype, setoddstype] = useState('');
  const [midSubCategory, setMidSubCategory] = useState('-1');

  const [_selectedItem, setSelectedItem] = useState({
    organizationId: userData.id, name: userData.loginId, levelValue: userData.levelValue, subOrgId: '-1'
  });

  useEffect(() => {
    if (_lianMa && _lianMa.lianma.length === 0) {
      setPage(1);
      setRows(15);
    }
  }, [_lianMa]);

  useEffect(() => {
    if (_isAvailable === false) {
      updateBatchOddsList();
    }
  }, [_isAvailable]);

  useImperativeHandle(childRef, () => ({
    change: () => onRefreshClick(),
    _midCode
  }), [_midCode]);

  useEffect(() => {
    if (passData) {
      setInfo(passData[0]);
      setIsQueryingDetail(passData[1]);
      setSelectedSubMenu(passData[2]);
      setmidCode(passData[3]);
    }
  }, [passData]);

  useEffect(() => {
    if (inTimeSheetOdds) {
      const obj = {};
      inTimeSheetOdds.subMenuSum.forEach(element => {
        obj[element.menuId] = element;
      });
      setMenuOddsListObj(obj);
    } else {
      setMenuOddsListObj();
    }
  }, [inTimeSheetOdds]);

  let CalculateTeMa = () => '';
  const [highLightNum, setHighLightNum] = useState('');

  useEffect(() => {
    if (activeLottery && inTimeSheetInfo?.currentInstallment) {
      const { currentInstallment = {} } = inTimeSheetInfo;
      CalculateTeMa = _menu[_selectedMainMenu]?.TeMaHighlight || (() => '');
      if (currentInstallment?.currentInstallmentNo === currentInstallment?.preInstallmentNo) {
        const temp = CalculateTeMa(currentInstallment?.preLotteryResult);
        setHighLightNum(temp);
      }
    }
  }, [_selectedMainMenu, inTimeSheetInfo, activeLottery]);

  useEffect(() => {
    const oddsList = inTimeSheetOdds?.listOdds;
    if (inTimeSheetOdds && JSON.stringify(oddsList) !== JSON.stringify(_info)) {
      const data = oddsList.map(x => ({
        ...x
      }));
      if (_menu[_selectedMainMenu]?.isStaticRow === 1) {
        if (_menu[_selectedMainMenu]?.display) {
          let content = [];
          for (let i = 0; i < _menu[_selectedMainMenu]?.display?.length; i++) {
            const combined = _menu[_selectedMainMenu]?.display[i]
              .filter(y => (y?.tabId ? y?.tabId === _menu[_selectedMainMenu]?.children[_selectedSubMenu]?.tabId : true))
              .map(item => {
                const index = data.findIndex(x => (x?.displayName ? x?.displayName === item?.displayName : true)
                && (_menu[_selectedMainMenu]?.prefix ? x?.keyCode?.includes(_menu[_selectedMainMenu]?.prefix[i]) : true)
                && (item?.keyCode ? x?.keyCode?.includes(item?.keyCode) : true));
                if (index !== -1) {
                  if (!data[index]?.displayName) {
                    data[index].displayName = item.displayName;
                  }
                  data[index].className = item.className;
                  data[index].rowSpan = item?.rowSpan;
                  data[index].img = item?.img;
                  data[index].minOdds = '';
                  data[index].refEnabled = data[index]?.enabled;
                  return data[index];
                }
                return item;
              });
            content = [...content, ...combined.map(obj => ({
              ...obj, mark: i.toString()
            }))];
          }
          setInfo(content);
        }
      } else if (_menu[_selectedMainMenu]?.display) {
        const combined = _menu[_selectedMainMenu]?.display.map(item => {
          const i = data.findIndex(x => (x?.displayName ? x?.displayName === item?.displayName : true)
            && (item?.keyCode ? x?.keyCode?.includes(item?.keyCode) : true));
          if (i !== -1) {
            const tempItem = {
              ...data[i]
            };
            if (!tempItem?.displayName) {
              tempItem.displayName = item.displayName;
              tempItem.minOdds = '';
              tempItem.refEnabled = data[i]?.enabled;
            }
            tempItem.className = `${item.className} ${item?.displayName === highLightNum ? 'teMaMaker' : ''}`;
            return tempItem;
          }
          return ({
            ...item
          });
        });
        setInfo(combined);
      } else {
        setInfo([...data]);
      }
      setIsQueryingDetail(false);
    }
  }, [inTimeSheetOdds]);

  useEffect(() => {
    if (activeLottery) {
      setIsQueryingDetail(true);
      updateSelectedSubMenu(0);
      switch (activeLottery?.templateCode) {
        case 'HK6': {
          setSortMode('1');
          setMenu(oddsTypeMenu?.CJ_HK6);
          setmidCode({
            midCode: oddsTypeMenu?.CJ_HK6[0]?.midcode || (oddsTypeMenu?.CJ_HK6[0]?.children ? oddsTypeMenu?.CJ_HK6[0]?.children[0]?.midcode : ''),
            settingCode: oddsTypeMenu?.CJ_HK6[0]?.settingCode || (oddsTypeMenu?.CJ_HK6[0]?.children ? oddsTypeMenu?.CJ_HK6[0]?.children[0]?.settingCode : ''),
            isLianma: oddsTypeMenu?.CJ_HK6[0]?.isLianMa,
            midSubCategory: oddsTypeMenu?.CJ_HK6[0]?.children ? oddsTypeMenu?.CJ_HK6[0]?.children[0]?.midSubCategory : ''
          });
          break;
        }
        case 'PK10': {
          setSortMode('0');
          setMenu(oddsTypeMenu?.CJ_PK10);
          setmidCode({
            midCode: oddsTypeMenu?.CJ_PK10[0]?.midcode || '',
            settingCode: oddsTypeMenu?.CJ_PK10[0]?.settingCode || '',
          });
          break;
        }
        case 'QXC': {
          setSortMode('0');
          setMenu(oddsTypeMenu?.CJ_QXC);
          setmidCode({
            midCode: oddsTypeMenu?.CJ_QXC[0]?.midcode || '',
            settingCode: oddsTypeMenu?.CJ_QXC[0]?.settingCode || '',
          });
          break;
        }
        case '11X5': {
          setSortMode('0');
          setMenu(oddsTypeMenu?.CJ_11X5);
          setmidCode({
            settingCode: oddsTypeMenu?.CJ_11X5[0]?.settingCode || '',
            midCode: oddsTypeMenu?.CJ_11X5[0]?.midcode || '',
          });
          break;
        }
        case 'F31X7': {
          setSortMode('0');
          setMenu(oddsTypeMenu?.CJ_F31X7);
          setmidCode({
            settingCode: oddsTypeMenu?.CJ_F31X7[0]?.settingCode || '',
            midCode: oddsTypeMenu?.CJ_F31X7[0]?.midcode || '',
            isLianma: oddsTypeMenu?.CJ_F31X7[0]?.isLianMa
          });
          break;
        }
        case 'F36X7': {
          setSortMode('0');
          setMenu(oddsTypeMenu?.CJ_F36X7);
          setmidCode({
            settingCode: oddsTypeMenu?.CJ_F36X7[0]?.settingCode || '',
            midCode: oddsTypeMenu?.CJ_F36X7[0]?.midcode || '',
            isLianma: oddsTypeMenu?.CJ_F36X7[0]?.isLianMa
          });
          break;
        }
        case 'BJK8': {
          setSortMode('0');
          setMenu(oddsTypeMenu?.CJ_BJK8);
          setmidCode({
            settingCode: oddsTypeMenu?.CJ_BJK8[0]?.settingCode || '',
            midCode: oddsTypeMenu?.CJ_BJK8[0]?.midcode || '',
          });
          break;
        }
        case 'K3': {
          setSortMode('0');
          setMenu(oddsTypeMenu?.CJ_K3);
          setmidCode({
            settingCode: oddsTypeMenu?.CJ_K3[0]?.settingCode || '',
            midCode: oddsTypeMenu?.CJ_K3[0]?.midcode || '',
          });
          break;
        }
        case 'KL10': {
          setSortMode('0');
          if (activeLottery.code === 'CQXYNC') {
            const _obj = oddsTypeMenu?.CJ_KL10.map(item => (item?.displayForCQXYNC ? ({
              ...item, display: item.displayForCQXYNC
            }) : item));
            setMenu([].concat(_obj));
          } else {
            setMenu(oddsTypeMenu?.CJ_KL10);
          }
          setmidCode({
            settingCode: oddsTypeMenu?.CJ_KL10[0]?.settingCode || '',
            midCode: oddsTypeMenu?.CJ_KL10[0]?.midcode || '',
          });
          break;
        }
        case 'GXK10': {
          setSortMode('0');
          setMenu(oddsTypeMenu?.CJ_GXK10);
          setmidCode({
            settingCode: oddsTypeMenu?.CJ_GXK10[0]?.settingCode || '',
            midCode: oddsTypeMenu?.CJ_GXK10[0]?.midcode || '',
          });
          break;
        }
        case 'SSC': {
          setSortMode('0');
          if (activeLottery.code === 'HLSXSSC') {
            const _obj = oddsTypeMenu?.CJ_SSC.map(item => (item?.displayForHLSXSSC ? ({
              ...item, display: item.displayForHLSXSSC
            }) : item));
            setMenu([].concat(_obj));
          } else {
            setMenu(oddsTypeMenu?.CJ_SSC);
          }
          setmidCode({
            settingCode: oddsTypeMenu?.CJ_SSC[0]?.settingCode || '',
            midCode: oddsTypeMenu?.CJ_SSC[0]?.midcode || '',
          });
          break;
        }
        case 'PCDD': {
          setSortMode('0');
          setMenu(oddsTypeMenu?.CJ_PCDD);
          setmidCode({
            settingCode: oddsTypeMenu?.CJ_PCDD[0]?.settingCode || '',
            midCode: oddsTypeMenu?.CJ_PCDD[0]?.midcode || '',
          });
          break;
        }
        case 'FANT': {
          setSortMode('0');
          setMenu(oddsTypeMenu?.CJ_FANT);
          setmidCode({
            settingCode: oddsTypeMenu?.CJ_FANT[0]?.settingCode || '',
            midCode: oddsTypeMenu?.CJ_FANT[0]?.midcode || '',
          });
          break;
        }
        default: break;
      }
      // }
    }
  }, [activeLottery]);

  useEffect(() => {
    if (_midCode && inTimeSheetInfo) {
      onRefreshClick();
      setBuhuoData(_midCode);
    }
  }, [_midCode, inTimeSheetInfo, _selectedItem, amountMode, sortMode, oddstype, midSubCategory]);

  const onPaginationClick = (pageNum = 1, rows = 15, flag = false) => {
    if (inTimeSheetInfo) {
      const { currentInstallmentNo } = inTimeSheetInfo.currentInstallment || {};
      if (flag) {
        setPage(pageNum);
        setRows(rows);
      }
      const _obj = {
        lotteryType: activeLottery?.code,
        midCode: _midCode?.midCode || '',
        settingCode: _midCode?.settingCode || '',
        isLianma: _midCode?.isLianma || 0,
        installments: currentInstallmentNo,
        midSubCategory: _midCode?.midSubCategory,
        amountMode,
        sortMode,
        page: pageNum,
        rows,
        oddstype
      };
      getListLianma(_obj, data => {
        setLianMa(data);
      });
    }
  };


  const onRefreshClick = () => {
    reset();
    if (inTimeSheetInfo) {
      let tempmidSubCategory = _midCode?.midSubCategory ?? '';
      if (activeLottery?.templateCode === 'HK6' && (_midCode?.midCode ?? '').includes('ZMT') && userInfo?.supportZmt) {
        tempmidSubCategory = midSubCategory;
      }

      const { currentInstallmentNo } = inTimeSheetInfo.currentInstallment || {};
      if (_midCode) {
        const _obj = {
          lotteryType: activeLottery?.code,
          midCode: _midCode?.midCode || '',
          settingCode: _midCode?.settingCode || '',
          isLianma: _midCode?.isLianma || 0,
          installments: currentInstallmentNo,
          midSubCategory: tempmidSubCategory,
          amountMode,
          sortMode,
          type: _midCode?.type || '',
          subOrgId: _selectedItem.subOrgId,
          oddstype
        };
        getListOdds(_obj);
        if (_midCode?.isLianma === 1) {
          onPaginationClick(page, rows, false);
        }
      }
    }
  };

  const changeOdds = (type, oddsObj) => {
    if (inTimeSheetInfo) {
      const oddsChange = () => {
        const downValue = _selectedAmount || (inTimeSheetInfo.oddsDownSettingInfo.find(x => x.code.includes(oddsObj.settingCode))?.downValue ?? 0);
        if (type === 'add') {
          const temp = {
            lotteryType: activeLottery.code,
            oddsList: [{
              ...oddsObj, odds: Number.parseFloat((oddsObj.odds + downValue).toFixed(10))
            }]
          };
          SaveOdds(temp, onRefreshClick);
        } else {
          const temp = {
            lotteryType: activeLottery.code,
            oddsList: [{
              ...oddsObj, odds: Number.parseFloat((oddsObj.odds - downValue).toFixed(10))
            }]
          };
          SaveOdds(temp, onRefreshClick);
        }
      };
      return oddsChange();
    }
  };

  const reset = () => {
    if (_info) {
      const res = _info.map(obj => ({
        ...obj, minOdds: '', isChange: false, actionStatus: ''
      }));
      setInfo(res);
    }
  };

  const onBuhuoClick = (data, type = 'buhuoAmount') => {
    if (inTimeSheetInfo?.allowBuhuo === 0 || inTimeSheetInfo?.currentInstallment?.status !== 1 || _selectedItem.subOrgId !== '-1') {
      return;
    }
    const amount = data[type] ?? 0;
    if (amount > 0) {
      const obj = {
        betData: [{
          keyCode: data.keyCode,
          displayName: data.displayName,
          amount: parseInt(amount, 10),
        }
        ],
        lotteryType: activeLottery.code
      };

      return TakeBuhuoTicket(obj, () => onRefreshClick);
    }
    return updateModalAlert({
      type: 'confirmation',
      visible: true,
      data: {
        message: '补货金额需大于0',
        showCancelButton: false
      }
    });
  };

  const onAmountClick = (data, isLianMa = false) => {
    updateModalAlert({
      type: 'betList',
      visible: true,
      data: {
        data,
        isLianMa
      }
    });
  };

  return (
    <Col className="InTimeSheetManageTableWarpper">
      <CurrentDrawPanel
        setMidSubCategory={setMidSubCategory}
        midSubCategory={midSubCategory}
        oddstype={oddstype}
        setoddstype={setoddstype}
        sortMode={sortMode}
        setSortMode={setSortMode}
        _midCode={_midCode}
        onRefreshClick={onRefreshClick}
        _selectedItem={_selectedItem}
        setSelectedItem={setSelectedItem}
        setAvailability={setAvailability}
        amountMode={amountMode}
        setamountMode={setamountMode}
      />
      {
        // hide menu if needed
        _menu[_selectedMainMenu]?.hideMenu !== 1 && (
        <InTimeSheetMenuRender
          menu={_menu}
          _selectedMainMenu={_selectedMainMenu}
          _selectedSubMenu={_selectedSubMenu}
          setSelectedSubMenu={setSelectedSubMenu}
          setInfo={setInfo}
          setmidCode={setmidCode}
          setSelectedMainMenu={updateSelectedSubMenu}
          setIsQueryingDetail={setIsQueryingDetail}
        />
        )
      }

      <Col className="content mt-1 p-0">
        {
          // Dynamic rows display
          _menu && (
          <InTimeSheetTableRender
            menu={_menu}
            _selectedMainMenu={_selectedMainMenu}
            _isQueryingDetail={_isQueryingDetail}
            _info={_info ? (_info.map(x => ({
              ...x
            }))) : []}
            _selectedSubMenu={_selectedSubMenu}
            _isAvailable={_isAvailable}
            changeOdds={changeOdds}
            inTimeSheetOdds={inTimeSheetOdds}
            _MenuOddsListObj={_MenuOddsListObj}
            onBuhuoClick={(data, type) => onBuhuoClick(data, type)}
            onAmountClick={data => onAmountClick(data)}
            sortMode={sortMode}
          />
          )
        }
      </Col>
      {_menu[_selectedMainMenu]?.lianMaMode
      && (
      <>
        <Row className="m-0 p-0 mb-1">
          <AgentDataTable
            isQuerying={false}
            tableHeaderTitle={[
              {
                label: '投注内容'
              },
              {
                label: '笔数'
              },
              {
                label: '总额'
              },
              {
                label: '补货'
              },
            ]}
            tableData={_lianMa ? _lianMa.lianma : []}
            tableBodyRowProperty={
              [
                data => (
                  <div className="d-flex  align-items-center justify-content-center h-100" onClick={e => { onBuhuoClick(data, 'bettingAmount'); e.stopPropagation(); }}>
                    {
                    [].concat(_menu[_selectedMainMenu]?.lianMaMode.rowProperty[0](data.displayName)).map(item => <span className={item.className}>{item.displayName}</span>)
                    }
                  </div>
                ),
                data => (
                  <div className="d-flex align-items-center justify-content-center h-100 ">
                    <span className="inTimeAmount" onClick={e => { onAmountClick(data, true); e.stopPropagation(); }}>
                      {data?.count}
                    </span>
                  </div>
                ),
                data => (
                  <div className="d-flex  align-items-center justify-content-center h-100">
                    {data?.bettingAmount}
                  </div>
                ),
                data => (
                  <div className="d-flex  installment justify-content-center h-100 ">
                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <span title="补货" className="buhuoDone" onClick={e => { onBuhuoClick(data); e.stopPropagation(); }}>{data?.buhuoAmount ?? 0}</span>
                      <span title="已补货">{data?.buhuoedAmount ?? 0 }</span>
                    </div>
                  </div>
                ),
              ]
            }
            tableBodyRowStyle={['backgroundColored nopadding', 'nopadding', 'nopadding']}
            tableHeaderStyle={[]}
          />
        </Row>
        <Row className="justify-center w-full relative">
          <Pagination totalRecord={_lianMa ? _lianMa.sum.totalRecords : 0} onClick={(page, rows) => onPaginationClick(page, rows, true)} isQuery={false} />
        </Row>
      </>
      )}
    </Col>
  );
};

const Component = connect(mapStateToProps, mapDispatchToProps)(InTimeSheetManageTable);
export default React.forwardRef((props, ref) => <Component {...props} refInstance={ref} />);