import { dragonTiger, fortune } from '../Items/others';
import { dxds, hdxds, totalSumDS, totalSumDX, totaltailDX, wdx } from '../Items/dsdx';
import { number1to20 } from '../Items/numbers';
import { pureThreeColors } from '../Items/colors';

import { qsBall1to5, qsFortune, qsSumBigSmall, qsSumSingleDouble, qsSumTailBigSmall, qsThreeColor, qsTwoFace } from '../Items/quickset';


const GXK10 = [{
  displayName: '两面',
  isStaticRow: 1,
  repeatPerRow: 3,
  midcode: 'ZH,LH,B1LM,B2LM,B3LM,B4LM,B5LM',
  quickSet: {
    style: 2,
    variables: [[...qsTwoFace, ...qsSumBigSmall, ...qsSumSingleDouble, ...qsSumTailBigSmall]]
  },
  display: [[...dxds, ...wdx, ...hdxds.slice(0, 2)],
    [...dxds, ...wdx, ...hdxds.slice(0, 2)],
    [...dxds, ...wdx, ...hdxds.slice(0, 2)],
    [...dxds, ...wdx, ...hdxds.slice(0, 2)],
    [...dxds, ...wdx, ...hdxds.slice(0, 2)],
    [...totalSumDX, ...totalSumDS, ...totaltailDX, ...dragonTiger]
  ],
  prefix: ['B1', 'B2', 'B3', 'B4', 'B5', ''],
  firstTableHeader: ['第一球', '第二球', '第三球', '第四球', '第五球', '总和'],
}, {
  displayName: '单号1-5',
  isStaticRow: 1,
  settingCode: 'QH15,FLSX15,SB15',
  prefix: ['B1', 'B2', 'B3', 'B4', 'B5', ''],
  quickSet: {
    style: 2,
    variables: [[...qsBall1to5, ...qsThreeColor, ...qsFortune]]
  },
  firstTableHeader: ['第一球', '第二球', '第三球', '第四球', '第五球', '总和'],
  display: [[...number1to20.slice(0, 21), ...pureThreeColors, ...fortune],
    [...number1to20.slice(0, 21), ...pureThreeColors, ...fortune],
    [...number1to20.slice(0, 21), ...pureThreeColors, ...fortune],
    [...number1to20.slice(0, 21), ...pureThreeColors, ...fortune],
    [...number1to20.slice(0, 21), ...pureThreeColors, ...fortune]],
}
];

export default GXK10;