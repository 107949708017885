import { SET_AGENT_EDIT_INFO, SET_AGENT_LIST, SET_CROSS_LOGIN, SET_IS_VALID_LOGINID_MSG, SET_IS_VALID_NAME_MSG, SET_ODDS_DIFF, SET_PREV_ODDS_DIFF, SET_REBATE_SETTING, SET_SHARES_DETAIL, SET_SUB_ACCOUNT_INFO, SET_WIN_LOST_NOTIFICATION } from '../../actions/actionTypes';

const initialState = {
  agentListData: undefined,
  sharesDetailData: undefined,
  rebateSetting: undefined,
  oddsDiffData: undefined,
  prevOddDiffData: undefined,
  winLostNotificationData: undefined,
  subAccountData: undefined,
  isValidLoginIdMsg: undefined,
  isValidNameMsg: undefined,
  agentEditInfo: undefined,
  crossLogin: undefined,
};

const agentReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_AGENT_LIST:
      return {
        ...state, agentListData: action.value
      };
    case SET_AGENT_EDIT_INFO:
      return {
        ...state, agentEditInfo: action.value
      };

    case SET_SHARES_DETAIL:
      return {
        ...state, sharesDetailData: action.value
      };

    case SET_REBATE_SETTING:
      return {
        ...state, rebateSetting: action.value
      };

    case SET_ODDS_DIFF:
      return {
        ...state, oddsDiffData: action.value
      };

    case SET_PREV_ODDS_DIFF:
      return {
        ...state, prevOddDiffData: action.value
      };

    case SET_WIN_LOST_NOTIFICATION:
      return {
        ...state, winLostNotificationData: action.value
      };

    case SET_SUB_ACCOUNT_INFO:
      return {
        ...state, subAccountData: action.value
      };

    case SET_IS_VALID_LOGINID_MSG:
      return {
        ...state, isValidLoginIdMsg: action.value
      };

    case SET_IS_VALID_NAME_MSG:
      return {
        ...state, isValidNameMsg: action.value
      };
    case SET_CROSS_LOGIN:
      return {
        ...state, crossLogin: action.value
      };

    default:
      return state;
  }
};

export default agentReducers;