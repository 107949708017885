import { SET_CHANGE_HISTORY_LOG, SET_HANDLER_LOG, SET_OPERATION_LOG } from '../../actions/actionTypes';

const initialState = {
  operationLogResult: undefined,
  handlerLog: undefined,
  changeHistoryLog: undefined,
};

const operationLogReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_OPERATION_LOG:
      return {
        ...state, operationLogResult: action.value
      };
    case SET_HANDLER_LOG:
      return {
        ...state, handlerLog: action.value
      };

    case SET_CHANGE_HISTORY_LOG:
      return {
        ...state, changeHistoryLog: action.value
      };
    default:
      return state;
  }
};

export default operationLogReducers;