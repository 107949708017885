import { StatusCodes } from 'http-status-codes';

import { SET_AGENT_EDIT_INFO, SET_AGENT_LIST, SET_CROSS_LOGIN, SET_IS_VALID_LOGINID_MSG, SET_IS_VALID_NAME_MSG, SET_ODDS_DIFF, SET_PREV_ODDS_DIFF, SET_REBATE_SETTING, SET_SHARES_DETAIL, SET_SUB_ACCOUNT_INFO, SET_WIN_LOST_NOTIFICATION } from '../actionTypes';
import { apiService } from '../../../library';
import appActions from '../appActions';
import commonActions from '../commonActions';
import memberActions from '../memberActions';

const setAgentList = agentListData => ({
  type: SET_AGENT_LIST,
  value: agentListData,
});

const setShareDetailData = sharesDetailData => ({
  type: SET_SHARES_DETAIL,
  value: sharesDetailData,
});

const setOddsDiff = oddDiffData => ({
  type: SET_ODDS_DIFF,
  value: oddDiffData,
});

const setPrevOddsDiff = prevOddDiffData => ({
  type: SET_PREV_ODDS_DIFF,
  value: prevOddDiffData,
});


const setRebateSetting = rebateSetting => ({
  type: SET_REBATE_SETTING,
  value: rebateSetting,
});

const setWinLostNotification = winLostData => ({
  type: SET_WIN_LOST_NOTIFICATION,
  value: winLostData,
});

const setSubAccountInfo = subAccData => ({
  type: SET_SUB_ACCOUNT_INFO,
  value: subAccData,
});

const setIsValidNameMsg = data => ({
  type: SET_IS_VALID_NAME_MSG,
  value: data,
});

const setIsValidLoginIdMsg = data => ({
  type: SET_IS_VALID_LOGINID_MSG,
  value: data,
});

const setAgentEditInfo = data => ({
  type: SET_AGENT_EDIT_INFO,
  value: data,
});

const setCrossLogin = data => ({
  type: SET_CROSS_LOGIN,
  value: data,
});

const resetAgentList = () => dispatch => dispatch(setAgentList(undefined));
const resetShareDetails = () => dispatch => dispatch(setShareDetailData(undefined));
const resetData = () => dispatch => dispatch(setRebateSetting(undefined));
const resetOddsDiffData = () => dispatch => dispatch(setOddsDiff(undefined));
const resetReturnMsgData = () => dispatch => {
  dispatch(setIsValidLoginIdMsg(undefined));
  dispatch(setIsValidNameMsg(undefined));
};
const resetSubAccountInfo = () => dispatch => dispatch(setSubAccountInfo(undefined));

const getOddsDifferent = (orgId, lotType, callback = () => {}) => dispatch => {
  apiService.get('agent/OddsDifference', new URLSearchParams({
    orgId, lotType
  }))
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          callback(true);
          return dispatch(setOddsDiff(data.result || {}));
        }
      }
    }).catch(error => {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      if (error?.response?.data?.state) {
        dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'confirmation',
          data: {
            message: error.response.data.msg,
            showCancelButton: false
          }
        }));
      }
      Promise.reject(error);
    });
};

const saveOddsDifference = (obj = {}, callback = () => {}) => dispatch => {
  apiService.post('agent/SaveOddsDifference', {
    ...obj
  })
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          dispatch(appActions.updateModalAlert({
            visible: false
          }));
          dispatch(appActions.updateModalAlert({
            visible: true,
            type: 'success',
            data: {
              message: data.msg,
            }
          }));
          return callback();
        }
      }
    }).catch(error => {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      if (error.response.data.state) {
        dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'confirmation',
          data: {
            message: error.response.data.msg
          }
        }));
      }
      Promise.reject(error);
    });
};


const getPreviewOddsDifference = obj => dispatch => {
  apiService.get('company/PreviewOddsDifference', new URLSearchParams(obj))
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          return dispatch(setPrevOddsDiff(data.result || {}));
        }
      }
    }).catch(error => {
      Promise.reject(error);
    });
};

const ExtractAccountQuota = (obj, callback = () => {}) => dispatch => {
  apiService.post('agent/ExtractAccountQuota', obj)
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          dispatch(appActions.updateModalAlert({
            visible: false
          }));
          dispatch(appActions.updateModalAlert({
            visible: true,
            type: 'success',
            data: {
              message: data.msg,
              onConfirmationClick: () => callback()
            }
          }));
        }
      }
    }).catch(error => {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      if (error.response.data.state) {
        dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'confirmation',
          data: {
            message: error.response.data.msg
          }
        }));
      }
      Promise.reject(error);
    });
};

const SetAccountQuota = (obj, callback = () => {}) => dispatch => {
  apiService.post('agent/SetAccountQuota', obj)
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          dispatch(appActions.updateModalAlert({
            visible: true,
            type: 'success',
            data: {
              message: data.msg,
              onConfirmationClick: () => callback()
            }
          }));
        }
      }
    }).catch(error => {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      if (error.response.data.state) {
        dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'confirmation',
          data: {
            message: error.response.data.msg
          }
        }));
      }
      Promise.reject(error);
    });
};

const unlockMember = (obj, callback = () => {}) => dispatch => {
  apiService.post('agent/unlockMember', obj)
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          dispatch(appActions.updateModalAlert({
            visible: false
          }));
          dispatch(appActions.updateModalAlert({
            visible: true,
            type: 'success',
            data: {
              message: data.msg,
              onConfirmationClick: () => callback()
            }
          }));
        }
      }
    }).catch(error => {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      if (error.response.data.state) {
        dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'confirmation',
          data: {
            message: error.response.data.msg
          }
        }));
      }
      Promise.reject(error);
    });
};

const GetAccountQuota = (obj, callback = () => {}, catchback = () => { }) => () => {
  apiService.get('agent/GetAccountQuota', new URLSearchParams(obj))
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          return callback(data.result);
        }
      }
    }).catch(error => {
      catchback();
      Promise.reject(error);
    });
};

const getAgentList2 = (obj, callback = () => {}, catchback = () => { }) => () => {
  apiService.get('agent/agentlist', new URLSearchParams(obj))
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          return callback(data.result);
        }
      }
    }).catch(error => {
      catchback();
      Promise.reject(error);
    });
};

const getAgentList = (agentId, levelValue, orderby, order, page, rows, startDate, endDate, isChild = '', loginId = '', status = '', searchBy = '', minAmount = '', maxAmount = '') => dispatch => {
  apiService.get(
    'agent/agentlist',
    `agentId=${agentId}&level=${levelValue}&orderby=${orderby}&order=${order}&startDate=${startDate}&endDate=${endDate}&loginId=${loginId}&page=${page}&row=${rows}&isChild=${isChild}&status=${status}&searchBy=${searchBy}&minAmount=${minAmount}&maxAmount=${maxAmount}`
  )
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          return dispatch(setAgentList(data.result || {}));
        }
      }
    }).catch(error => {
      Promise.reject(error);
    });
};

const getSharesDetail = (userType, userId) => dispatch => {
  apiService.get(
    'agent/sharesDetail',
    `userId=${userId}&userType=${userType}`
  )
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          return dispatch(setShareDetailData(data.result || {}));
        }
      }
    }).catch(error => {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      if (error.response.data.state) {
        dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'confirmation',
          data: {
            message: error.response.data.msg
          }
        }));
      }
      Promise.reject(error);
    });
};

const setAccountStatus = (obj = {}, searchObj = {}) => dispatch => {
  apiService.post('agent/SetAccountStatus', {
    ...obj
  })
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          dispatch(appActions.updateModalAlert({
            visible: false
          }));
          if (obj?.userType === 1) {
            return dispatch(memberActions.GetMemberList(searchObj?.userId, searchObj?.userLevel, searchObj?.orderBy, searchObj?.orderType, searchObj?.page, searchObj?.rows, searchObj?.startDate, searchObj?.endDate, searchObj?.agentType, searchObj?.loginId));
          }
          return dispatch(getAgentList(searchObj?.userId, searchObj?.userLevel, searchObj?.orderBy, searchObj?.orderType, searchObj?.page, searchObj?.rows, searchObj?.startDate, searchObj?.endDate, searchObj?.agentType, searchObj?.loginId));
        }
      }
    }).catch(error => {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      if (error.response.data.state) {
        dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'confirmation',
          data: {
            message: error.response.data.msg
          }
        }));
      }
      Promise.reject(error);
    });
};

const setAccountStatus2 = (obj = {}, callback = () => {}) => dispatch => {
  apiService.post('agent/SetAccountStatus', obj)
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          dispatch(appActions.updateModalAlert({
            visible: false
          }));
          callback();

          // if (obj?.userType === 1) {
          //   return dispatch(memberActions.GetMemberList(searchObj?.userId, searchObj?.userLevel, searchObj?.orderBy, searchObj?.orderType, searchObj?.page, searchObj?.rows, searchObj?.startDate, searchObj?.endDate, searchObj?.agentType, searchObj?.loginId));
          // }
          // return dispatch(getAgentList(searchObj?.userId, searchObj?.userLevel, searchObj?.orderBy, searchObj?.orderType, searchObj?.page, searchObj?.rows, searchObj?.startDate, searchObj?.endDate, searchObj?.agentType, searchObj?.loginId));
        }
      }
    }).catch(error => {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      if (error.response.data.state) {
        dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'confirmation',
          data: {
            message: error.response.data.msg
          }
        }));
      }
      Promise.reject(error);
    });
};
const setDefaultAgent = (orgId, searchObj = {}) => dispatch => {
  apiService.post('agent/SetDefaultAgent', orgId)
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          dispatch(appActions.updateModalAlert({
            visible: false
          }));
          return dispatch(getAgentList(searchObj?.userId, searchObj?.userLevel, searchObj?.orderBy, searchObj?.orderType, searchObj?.page, searchObj?.rows, searchObj?.startDate, searchObj?.endDate, searchObj?.agentType, searchObj?.loginId));
        }
      }
    }).catch(error => {
      Promise.reject(error);
    });
};

const setDefaultVisitorAgent = (obj = {}, searchObj = {}) => dispatch => {
  apiService.post('agent/SetDefaultVisitorAgent', {
    ...obj
  })
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          dispatch(appActions.updateModalAlert({
            visible: false
          }));
          return dispatch(getAgentList(searchObj?.userId, searchObj?.userLevel, searchObj?.orderBy, searchObj?.orderType, searchObj?.page, searchObj?.rows, searchObj?.startDate, searchObj?.endDate, searchObj?.agentType, searchObj?.loginId));
        }
      }
    }).catch(error => {
      Promise.reject(error);
    });
};


const getRebateSetting = (obj = {}, callback = () => {}) => dispatch => {
  apiService.get('agent/rebateSetting', new URLSearchParams(obj))
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          dispatch(setRebateSetting(data.result || {}));
          return callback();
        }
      }
    }).catch(error => {
      Promise.reject(error);
    });
};

const saveRebateSetting = (obj = {}, callback = () => {}) => dispatch => {
  dispatch(appActions.updateModalAlert({
    visible: false
  }));
  apiService.post('agent/saveRebateSetting', {
    ...obj
  })
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          dispatch(appActions.updateModalAlert({
            visible: true,
            type: 'success',
            data: {
              message: data.msg,
            }
          }));
          return callback();
        }
      }
    }).catch(error => {
      if (error.response.data.state) {
        dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'confirmation',
          data: {
            message: error.response.data.msg,
            showCancelButton: false
          }
        }));
      }
      Promise.reject(error);
    });
};


const copyOddsDiff = (obj = {}, callback = () => {}) => dispatch => {
  apiService.post('agent/copyOddsDiff', {
    ...obj
  })
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          dispatch(appActions.updateModalAlert({
            visible: false
          }));
          dispatch(appActions.updateModalAlert({
            visible: true,
            type: 'success',
            data: {
              message: data.msg,
            }
          }));
          return callback();
        }
      }
    }).catch(error => {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      callback(false);
      if (error.response.data.state) {
        dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'confirmation',
          data: {
            message: error.response.data.msg
          }
        }));
      }
      Promise.reject(error);
    });
};

const getWinLostNotification = userId => dispatch => {
  apiService.get(`agent/WinLostNotification/${userId}`)
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          return dispatch(setWinLostNotification(data.result || {}));
        }
      }
    }).catch(error => {
      Promise.reject(error);
    });
};

const saveWinLostNotification = (obj = {}, callback = () => {}) => dispatch => {
  apiService.post('agent/SaveWinLostNotification', {
    ...obj
  })
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          dispatch(appActions.updateModalAlert({
            visible: false
          }));
          dispatch(appActions.updateModalAlert({
            visible: true,
            type: 'success',
            data: {
              message: data.msg,
            }
          }));
        }
      }
    }).catch(error => {
      callback(false);
      if (error.response.data.state) {
        dispatch(appActions.updateModalAlert({
          visible: false
        }));
        dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'confirmation',
          data: {
            message: error.response.data.msg
          }
        }));
      }
      Promise.reject(error);
    });
};


const saveSubAgent = (obj = {}, callback = () => {}) => dispatch => {
  apiService.post('agent/SaveSubAgent', {
    ...obj
  })
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          dispatch(appActions.updateModalAlert({
            visible: false
          }));
          dispatch(commonActions.GetOrganizationUserCount());
          dispatch(getSubAccountInfo(obj?.subAgent?.organizationId));
          dispatch(appActions.updateModalAlert({
            visible: true,
            type: 'success',
            data: {
              message: data.msg,
              onConfirmationClick: () => callback(true)
            }
          }));
        }
      }
    }).catch(error => {
      callback(false);
      if (error.response.data.state) {
        dispatch(appActions.updateModalAlert({
          visible: false
        }));
        dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'confirmation',
          data: {
            message: error.response.data.msg
          }
        }));
      }
      Promise.reject(error);
    });
};


const getSubAccountInfo = userId => dispatch => {
  apiService.get(`agent/SubAccountInfo/${userId}`)
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          return dispatch(setSubAccountInfo(data.result || {}));
        }
      }
    }).catch(error => {
      Promise.reject(error);
    });
};

const GetAddSubAccountInfo = (parentId, isChild = 1, callback = () => {}) => dispatch => {
  apiService.get('agent/GetAddSubAccountInfo', new URLSearchParams({
    parentId, isChild
  }))
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          callback();
          return dispatch(setSubAccountInfo(data.result || {}));
        }
      }
    }).catch(error => {
      Promise.reject(error);
    });
};


const isExistLoginId = (loginId, callback = () => {}) => dispatch => {
  apiService.get(`agent/isExistLoginId/${loginId}`)
    .then(res => {
      const { data } = res || {};
      if (data) {
        callback(data);
        return dispatch(setIsValidLoginIdMsg(data || {}));
      }
    }).catch(error => {
      callback(error.response?.data);
      dispatch(setIsValidLoginIdMsg(error.response?.data || {}));
      Promise.reject(error);
    });
};

const isValidName = (name, callback = () => {}) => dispatch => {
  apiService.get(`agent/isValidName/${name}`)
    .then(res => {
      const { data } = res || {};
      if (data) {
        callback(data);
        return dispatch(setIsValidNameMsg(data || {}));
      }
    }).catch(error => {
      callback(error.response?.data);
      dispatch(setIsValidNameMsg(error.response?.data || {}));
      Promise.reject(error);
    });
};

const deleteUser = (obj = {}, callback = () => {}) => dispatch => {
  apiService.post('agent/deleteUser', {
    ...obj
  })
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          dispatch(commonActions.GetOrganizationUserCount());
          dispatch(appActions.updateModalAlert({
            visible: false
          }));
          dispatch(appActions.updateModalAlert({
            visible: true,
            type: 'success',
            data: {
              message: data.msg,
            }
          }));
        }
      }
    }).catch(error => {
      callback(false);
      if (error.response.data.state) {
        dispatch(appActions.updateModalAlert({
          visible: false
        }));
        dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'confirmation',
          data: {
            message: error.response.data.msg
          }
        }));
      }
      Promise.reject(error);
    });
};

const GetEditAgentInfo = (orgId, callback = () => {}) => dispatch => {
  apiService.get('/agent/EditAgentInfo', new URLSearchParams({
    orgId
  })).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      callback();
      return dispatch(setAgentEditInfo(data.result));
    }
  }).catch(error => {
    dispatch(setAgentEditInfo());
    dispatch(appActions.updateModalAlert({
      visible: true,
      type: 'confirmation',
      data: {
        message: error.response.data.msg
      }
    }));
    Promise.reject(error);
  });
};

const SaveAgentInfo = (obj, callback = () => {}) => dispatch => {
  apiService.post('/agent/SaveAgentInfo', obj).then(res => {
    const { data, status } = res || {};
    dispatch(appActions.updateModalAlert({
      visible: false,
    }));
    if (status === StatusCodes.OK) {
      dispatch(commonActions.GetOrganizationUserCount());
      return dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
          onConfirmationClick: () => callback(true)
        }
      }));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false,
    }));
    dispatch(appActions.updateModalAlert({
      visible: true,
      type: 'confirmation',
      data: {
        message: error.response.data.msg,
        showCancelButton: false
      }
    }));
    Promise.reject(error);
  });
};

const getLoginAs = (obj, callback = () => {}) => dispatch => {
  apiService.post('/agent/LoginAs/', obj).then(res => {
    const { status } = res || {};

    if (status === StatusCodes.OK) {
      return callback();
    }
  }).catch(error => {
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg,
          showCancelButton: false
        }
      }));
    }
    Promise.reject(error);
  });
};

const GetAddAgentInfo = (obj, calllback = () => {}) => () => {
  apiService.get('/agent/GetAddAgentInfo', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        return calllback(data.result);
      }
    }
  }).catch(error => {
    Promise.reject(error);
  });
};


export default {
  GetAddAgentInfo,
  getAgentList,
  getSharesDetail,
  setAccountStatus,
  setAccountStatus2,
  setDefaultAgent,
  setDefaultVisitorAgent,
  getRebateSetting,
  saveRebateSetting,
  resetData,
  getOddsDifferent,
  resetOddsDiffData,
  saveOddsDifference,
  getPreviewOddsDifference,
  copyOddsDiff,
  getWinLostNotification,
  saveWinLostNotification,
  getSubAccountInfo,
  isExistLoginId,
  isValidName,
  resetReturnMsgData,
  saveSubAgent,
  deleteUser,
  resetSubAccountInfo,
  resetShareDetails,
  resetAgentList,
  GetEditAgentInfo,
  SaveAgentInfo,
  setCrossLogin,
  getLoginAs,
  getAgentList2,
  GetAccountQuota,
  SetAccountQuota,
  ExtractAccountQuota,
  unlockMember,
  GetAddSubAccountInfo
};