import { useEffect, useState } from 'react';

import { Modal } from 'reactstrap';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import AdditionSubtractionDetailsModal from './AdditionSubtractionDetailsModal';
import BalanceEditModal from './BalanceEditModal';
import BankCardModal from './BankCardModal';
import BetDetailsModal from './BetDetailsModal';
import BetListModal from './BetListModal';
import BuhuoModal from './BuhuoModal';
import ChangePasswordModal from './ChangePasswordModal';
import ConfirmationModal from './ConfirmationModal';
import CopyOddsDiffModal from './CopyOddsDiffModal';
import CreatePageSettingModal from './CreatePageSettingModal';
import EmailModal from './EmailModal';
import HandleAgentRebateModal from './HandleAgentRebateModal';
import LatestAnnouncementModal from './LatestAnnouncementModal';
import LoadingModal from './LoadingModal';
import LotteryReorderModal from './LotteryReorderModal';
import MessageDetailModal from './MessageDetailModal';
import OneTimeWithdrawalModal from './OneTimeWithdrawalModal';
import PanKouSettingModal from './PanKouSettingModal';
import PresetAmountModal from './PresetAmountModal';
import PreviewOddDiffModal from './PreviewOddDiffModal';
import RebateModal from './RebateModal';
import SafePassModal from './SafePassModal';
import SafePhoneModal from './SafePhoneModal';
import SharesDetailModal from './SharesDetailsModal';
import SuccessModal from './SuccessModal';
import ThirdGamesAccountModal from './ThirdGamesAccountModal';
import ThirdPartyTopupModal from './ThirdPartyTopupModal';
import TopUpPanelModal from './TopUpPanelModal';
import TransactionHistoryModal from './TransactionHistoryModal';
import WinLostDetailModal from './WinLostDetailModal';
import WinLostNotificationModal from './WinLostNotificationModal';
import { appActions } from '../../redux/actions';
import './styles.scss';

const mapStateToProps = state => ({
  modalAlert: state.appReducers.modalAlert,
  platform: state.appReducers.platform,
  themeColor: state.appReducers.themeColor,
});

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
});

const ModalAlert = ({ modalAlert, platform, themeColor, updateModalAlert }) => {
  const [_modalAlert, setModalAlert] = useState(modalAlert || {
    visible: false
  });

  const history = useHistory();

  useEffect(() => {
    let timeoutId = null;
    if (modalAlert.visible === false) {
      timeoutId = setTimeout(() => setModalAlert({
        visible: false
      }), 400);
    } else {
      setModalAlert(modalAlert);
    }
    return () => clearTimeout(timeoutId);
  }, [modalAlert]);

  const resetModal = () => {
    updateModalAlert({
      visible: false
    });
  };

  useEffect(() => {
    window.onbeforeunload = resetModal;
    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  useEffect(() => {
    history.listen(() => {
      resetModal();
    });
  }, [history]);

  const renderModalAlert = () => {
    switch (_modalAlert.type) {
      case 'changePassword':
        return (<ChangePasswordModal data={_modalAlert.data} />);
      case 'additionSubtractionDetails':
        return (<AdditionSubtractionDetailsModal data={_modalAlert.data} />);
      case 'confirmation':
        return (<ConfirmationModal data={_modalAlert.data} />);
      case 'betDetails':
        return (<BetDetailsModal data={_modalAlert.data} />);
      case 'success':
        return (<SuccessModal data={_modalAlert.data} />);
      case 'presetAmount':
        return (<PresetAmountModal data={_modalAlert.data} />);
      case 'previewOddDiff':
        return (<PreviewOddDiffModal data={_modalAlert.data} />);
      case 'sharesDetail':
        return (<SharesDetailModal data={_modalAlert.data} />);
      case 'loading':
        return (<LoadingModal data={_modalAlert.data} />);
      case 'rebate':
        return (<RebateModal data={_modalAlert.data} />);
      case 'copyOddsDiff':
        return (<CopyOddsDiffModal data={_modalAlert.data} />);
      case 'winLostDetail':
        return (<WinLostDetailModal data={_modalAlert.data} />);
      case 'thirdPartyGames':
        return (<ThirdGamesAccountModal data={_modalAlert.data} />);
      case 'winLostNotification':
        return (<WinLostNotificationModal data={_modalAlert.data} />);
      case 'latestAnnouncement':
        return (<LatestAnnouncementModal data={_modalAlert.data} />);
      case 'oneTimeWithdrawal':
        return (<OneTimeWithdrawalModal data={_modalAlert.data} />);
      case 'messageDetail':
        return (<MessageDetailModal data={_modalAlert.data} />);
      case 'handleAgentRebate':
        return (<HandleAgentRebateModal data={_modalAlert.data} />);
      case 'lotteryReorder':
        return (<LotteryReorderModal data={_modalAlert.data} />);
      case 'bankCard':
        return (<BankCardModal data={_modalAlert.data} />);
      case 'transactionHistory':
        return (<TransactionHistoryModal data={_modalAlert.data} />);

      case 'safePhone':
        return (<SafePhoneModal data={_modalAlert.data} />);
      case 'email':
        return (<EmailModal data={_modalAlert.data} />);
      case 'safePassword':
        return (<SafePassModal data={_modalAlert.data} />);
      case 'pankouSetting':
        return (<PanKouSettingModal data={_modalAlert.data} />);
      case 'thirdPartyTopup':
        return (<ThirdPartyTopupModal data={_modalAlert.data} />);
      case 'buhuo':
        return (<BuhuoModal data={_modalAlert.data} />);
      case 'betList':
        return (<BetListModal data={_modalAlert.data} />);
      case 'topup':
        return (<TopUpPanelModal depositResult={_modalAlert.data} />);
      case 'balanceEdit':
        return (<BalanceEditModal data={_modalAlert.data} />);
      case 'createSettingPage':
        return (<CreatePageSettingModal data={_modalAlert.data} />);
      default:
        return null;
    }
  };

  return (
    <Modal
      size="sm"
      isOpen={_modalAlert.visible}
      centered
      className={`theme-${themeColor || 'blue'} ${platform && platform.toLowerCase()} ${modalAlert.type} ${modalAlert.visible ? 'popUpOpen' : 'popUpClose'}`}
    >
      { renderModalAlert() }
    </Modal>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalAlert);