import { useEffect, useState } from 'react';

import { connect } from 'react-redux';

import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { MdArrowBack } from 'react-icons/md';
import { followBetActions } from '../../../redux/actions';

import AgentDataTable from '../../AgentDataTable';
import CommonHeader from '../../Content/CommonHeader';
import Pagination from '../../Content/Pagination';
import './styles.scss';

const mapDispatchToProps = dispatch => ({
  GetLogs: (obj, callback) => dispatch(followBetActions.GetLogs(obj, callback)),
});

const FollowBetLogs = ({
  data,
  GetLogs,
  setPageName
}) => {
  const { t } = useTranslation();
  const [_info, setInfo] = useState();
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(15);
  const [_totalRecord, setTotalRecord] = useState(0);
  const [_isQueryingDetail, setIsQueryingDetail] = useState(true);

  useEffect(() => {
    setIsQueryingDetail(true);
    const callback = data => {
      setInfo(data.list ?? []);
      setIsQueryingDetail(false);
      setTotalRecord(data.total.records ?? 0);
    };
    const obj = {
      configId: data.id,
      page,
      rows
    };
    GetLogs(obj, callback);
  }, []);

  const onPaginationClick = (pageNum, rows) => {
    setIsQueryingDetail(false);
    const callback = data => {
      setInfo(data.list ?? []);
      setIsQueryingDetail(false);
      setTotalRecord(data.total.records ?? 0);
    };
    const obj = {
      configId: data.id,
      page: pageNum,
      rows
    };
    GetLogs(obj, callback);
    setPage(pageNum);
    setRows(rows);
  };

  const tableHeader = [{
    label: '序号',
  }, {
    label: '跟单帐号',
  }, {
    label: '被跟单帐号',
  }, {
    label: '日志',
  }, {
    label: '跟单时间',
  }];

  const tableContent = [
    data => (
      <div className="d-flex justify-content-center">
        <div className="d-flex justify-content-center">
          {data?.rowNo}
        </div>
      </div>
    ),
    data => (<div className="d-flex justify-content-center">{data?.loginId}</div>),
    data => (
      <div className="d-flex justify-content-center">
        {
          data?.followedLoginId
        }
      </div>
    ),
    data => (
      <div className="d-flex justify-content-center">
        {data?.logMsg}
      </div>
    ),
    data => (
      <div className="d-flex justify-content-center">
        {data?.createDate && moment.unix(data?.createDate).format('YYYY-MM-DD HH:mm:ss')}
      </div>
    ),
  ];

  return (
    <div className="FollowBetLogsWrapper">
      <CommonHeader headerName={(
        <div className="d-flex align-items-center">
          <div className="d-flex align-items-center me-2 backIcon" onClick={() => setPageName('FollowBetList')}>
            <MdArrowBack className="h5 mb-0 text-white" />
            返回
          </div>
          <div>
            {
              t('FOLLOW_BET_LOGS')
            }
          </div>
        </div>
      )}
      />
      <div className="mx-0 bg-white my-2  border-1">
        <div className="bigWrapper m-0 my-1 p-3 d-flex">
          <AgentDataTable
            isQuerying={_isQueryingDetail}
            tableHeaderTitle={tableHeader}
            tableData={_info || (_isQueryingDetail ? [1, 2, 3] : [])}
            tableBodyRowProperty={tableContent}
            tableBodyRowStyle={['col-1', 'col-1', 'col-1', 'col-8', 'col-1']}
          />
        </div>
        <div className="justify-content-center w-full relative d-flex">
          <Pagination totalRecord={_totalRecord} onClick={onPaginationClick} isQuery={_isQueryingDetail} />
        </div>
      </div>
    </div>
  );
};

export default connect(null, mapDispatchToProps)(FollowBetLogs);