import { useEffect, useImperativeHandle, useState } from 'react';

import { Checkbox, DatePicker, Input, Radio, Select } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { commonActions, reportActions, thirdPartyGamesActions } from '../../redux/actions';

import './styles.scss';

const mapStateToProps = state => ({
  lotteryInstallments: state.commonReducers.lotteryInstallments,
  lotteryList: state.commonReducers.lotteryList,
  userInfo: state.memberReducers.userInfo,
  thirdPartyGamesList: state.thirdPartyGamesReducers.thirdPartyGamesList,
});

const mapDispatchToProps = dispatch => ({
  getWinLostReport: (obj, callback) => dispatch(reportActions.getWinLostReport(obj, callback)),
  GetWinLostAgentReport: (obj, callback) => dispatch(thirdPartyGamesActions.GetWinLostAgentReport(obj, callback)),
  GetWinLostMemberReport: (obj, callback) => dispatch(thirdPartyGamesActions.GetWinLostMemberReport(obj, callback)),
  getLotteryInstallments: (lotteryCode, date) => dispatch(commonActions.getLotteryInstallments(lotteryCode, date)),
  getClassificationReport: (obj, callback) => dispatch(reportActions.getClassificationReport(obj, callback)),
});

const { RangePicker } = DatePicker;
const { Option } = Select;

const ReportCommonSelection = ({
  setShowQueryMenu = () => {},
  setSpecialLoginName = () => {},
  setgamePage = () => {},
  _type, setType = () => {},
  onRef,
  _isShow = true,
  _setDate = () => {},
  thirdPartyGamesList = [],
  GetWinLostMemberReport,
  GetWinLostAgentReport,
  getWinLostReport,
  getClassificationReport,
  getLotteryInstallments,
  lotteryInstallments,
  lotteryList,
  userInfo,
  callback,
  isSettled,
  setisSettled
}) => {
  const { t } = useTranslation();
  const qishuData = userInfo.qishuCutOff ?? 6;
  const [_date, setDate] = useState([
    (moment().hour() < qishuData
      ? moment().subtract(1, 'days').set('hour', qishuData).toDate()
      : moment().set('hour', qishuData).toDate()),
    (moment().hour() < qishuData
      ? moment().subtract(1, 'days').set('hour', qishuData).toDate()
      : moment().set('hour', qishuData).toDate())]);

  const [_dateFlag, setDateFlag] = useState('1');
  const [_hide, setHide] = useState(false);

  const [memberLoginId, setmemberLoginId] = useState('');
  const [memberMoney, setmemberMoney] = useState('');
  const [winLostAmount, setwinLostAmount] = useState('');
  const [installment, setSelectedInstallments] = useState('');
  const [_installmentsResults, setInstallmentResults] = useState([]);
  const [lotteryType, setlotteryType] = useState(lotteryList.map(item => item.code));
  const [gameCode, setgameCode] = useState(thirdPartyGamesList.map(item => item.gameCode));

  const [parentLoginName, setparentLoginName] = useState(userInfo.loginId || '');

  useEffect(() => {
    if (lotteryType.length === 1) {
      getLotteryInstallments(lotteryType[0], moment(_date[0]).unix() || moment().unix());
    } else {
      setInstallmentResults([]);
      setSelectedInstallments('');
    }
  }, [lotteryType]);

  useEffect(() => {
    if (lotteryInstallments) {
      setInstallmentResults(lotteryInstallments);
    }
  }, [lotteryInstallments]);

  const onSearchClick = (level = userInfo.levelValue, userId = userInfo.orgId, isDir = 0, pLoginName, userType, _SelectedlotteryType = '', settingCode = '', isMember = 0) => {
    const obj = {
      level,
      userId,
      isDir,
      installment,
      winLostAmount,
      memberMoney,
      isSettled,
      memberLoginId,
      lotteryType: lotteryType.toString(),
      beginTime: moment(_date[0]).format('YYYY-MM-DD'),
      endTime: moment(_date[1]).format('YYYY-MM-DD')
    };
    callback(true);
    if (_type === 'classification') {
      getClassificationReport({
        ...obj, isMember, settingCode, lotteryType: _SelectedlotteryType
      }, data => callback(data));
    } else if (_type === 'thirdParty') {
      const obj = {
        gameCode: gameCode.toString(),
        beginTime: moment(_date[0]).format('YYYY-MM-DD HH:mm:ss'),
        endTime: moment(_date[1]).format('YYYY-MM-DD HH:mm:ss'),
        parentLoginName: pLoginName || parentLoginName,
        memberName: memberLoginId,
        memberMoney,
        winLostMoney: winLostAmount
      };
      if (pLoginName) {
        setparentLoginName(pLoginName);
      }
      if (userType === 1) {
        GetWinLostMemberReport(obj, data => callback(data));
      } else {
        GetWinLostAgentReport(obj, data => callback(data));
      }
    } else {
      getWinLostReport(obj, data => callback(data));
    }
  };

  useImperativeHandle(onRef, () => ({
    onSearchClick
  }));

  useEffect(() => {
    const obj = {
      level: userInfo.levelValue, userId: userInfo.orgId, isDir: 0, beginTime: moment().format('YYYY-MM-DD'), endTime: moment().format('YYYY-MM-DD'), isSettled
    };
    if (_type === 'classification') {
      getClassificationReport({
        ...obj, isMember: 2, lotteryType: lotteryType.toString()
      }, data => callback(data));
    } else if (_type === 'thirdParty') {
      const obj = {
        gameCode: gameCode.toString(),
        beginTime: moment(_date[0]).format('YYYY-MM-DD HH:mm:ss'),
        endTime: moment(_date[1]).format('YYYY-MM-DD HH:mm:ss'),
        parentLoginName: userInfo.loginId,
        memberName: memberLoginId,
        memberMoney,
        winLostMoney: winLostAmount
      };
      GetWinLostAgentReport(obj, data => callback(data));
    } else {
      getWinLostReport({
        ...obj, lotteryType: lotteryType.toString()
      }, data => callback(data));
    }
  }, []);

  const dateSelector = id => {
    const currentHour = moment().hour();
    const isbeforeQishuDate = currentHour < qishuData;
    setDateFlag(id);
    switch (id) {
      case '1': {
        const tempDate = [
          (isbeforeQishuDate
            ? moment().subtract(1, 'days').set('hour', qishuData).toDate()
            : moment().set('hour', qishuData).toDate()),
          (isbeforeQishuDate
            ? moment().subtract(1, 'days').set('hour', qishuData).toDate()
            : moment().set('hour', qishuData).toDate())
        ];
        setDate(tempDate);
        _setDate(tempDate);
        break;
      }
      case '2': {
        let tempDate;
        if (isbeforeQishuDate) {
          tempDate = [moment().subtract(2, 'days').set('hour', qishuData).toDate(), moment().subtract(2, 'days').set('hour', qishuData).toDate()];
        } else {
          tempDate = [moment().subtract(1, 'days').set('hour', qishuData).toDate(), moment().subtract(1, 'days').set('hour', qishuData).toDate()];
        }
        setDate(tempDate);
        _setDate(tempDate);
        break;
      }
      case '3':
        setDate([moment().startOf('isoWeek').toDate(), moment().endOf('isoWeek').toDate()]);
        _setDate([moment().startOf('isoWeek').toDate(), moment().endOf('isoWeek').toDate()]);
        break;
      case '4':
        setDate([moment().subtract(1, 'weeks').startOf('isoWeek').toDate(), moment().subtract(1, 'weeks').endOf('isoWeek').toDate()]);
        _setDate([moment().subtract(1, 'weeks').startOf('isoWeek').toDate(), moment().subtract(1, 'weeks').endOf('isoWeek').toDate()]);
        break;
      case '5':
        setDate([moment().startOf('month').toDate(), moment().endOf('month').toDate()]);
        _setDate([moment().startOf('month').toDate(), moment().endOf('month').toDate()]);
        break;
      case '6':
        setDate([moment().subtract(1, 'months').startOf('month').toDate(), moment().subtract(1, 'months').endOf('month').toDate()]);
        _setDate([moment().subtract(1, 'months').startOf('month').toDate(), moment().subtract(1, 'months').endOf('month').toDate()]);
        break;
      default:
        break;
    }
  };

  const chooseLottery = code => {
    setlotteryType([...code]);
  };

  const chooseThirdParty = code => {
    setgameCode([...code]);
  };

  const cancel = () => {
    setDate([
      (moment().hour() < qishuData
        ? moment().subtract(1, 'days').set('hour', qishuData).toDate()
        : moment().set('hour', qishuData).toDate()),
      (moment().hour() < qishuData
        ? moment().subtract(1, 'days').set('hour', qishuData).toDate()
        : moment().set('hour', qishuData).toDate())]);
    setDateFlag('1');
    setmemberLoginId('');
    setisSettled(1);
    setmemberMoney('');
    setwinLostAmount('');
    setSelectedInstallments('');
    setInstallmentResults([]);
    setlotteryType(lotteryList.map(item => item.code));
    setgameCode([]);
    const obj = {
      level: userInfo.levelValue, userId: userInfo.orgId, isDir: 0, beginTime: moment().format('YYYY-MM-DD'), endTime: moment().format('YYYY-MM-DD')
    };
    if (_type === 'classification') {
      getClassificationReport({
        ...obj, isMember: 2, lotteryType: lotteryList.map(item => item.code).toString(), isSettled: 1
      }, data => callback(data));
    } else if (_type === 'thirdParty') {
      const obj = {
        gameCode: gameCode.toString(),
        beginTime: moment(_date[0]).format('YYYY-MM-DD HH:mm:ss'),
        endTime: moment(_date[1]).format('YYYY-MM-DD HH:mm:ss'),
        parentLoginName: userInfo.loginId,
        memberName: memberLoginId,
        memberMoney,
        winLostMoney: winLostAmount
      };
      GetWinLostAgentReport(obj, data => callback(data));
    } else {
      getWinLostReport({
        ...obj, lotteryType: lotteryList.map(item => item.code).toString(), isSettled: 1
      }, data => callback(data));
    }
  };

  return (
    <div className={`reportCommonSelectionWrapper ${!_isShow && 'd-none'}`}>
      <div className="reportCommonSelectionTableWrapper d-flex p-3">
        <div className="userTable w-100">
          <div className="d-flex">
            <div>日期范围</div>
            <div>
              <RangePicker
                allowClear={false}
                value={[moment(_date[0]), moment(_date[1])]}
                separator={<span>{` ${t('TO')} `}</span>}
                onChange={date => {
                  setDate([date[0].toDate(), date[1].toDate()]);
                  _setDate([date[0].toDate(), date[1].toDate()]);
                }}
              />
              <div onClick={e => dateSelector(e.target.id)} className="d-flex mx-2">
                {
              [{
                label: '今天', value: 1
              }, {
                label: '昨天', value: 2
              }, {
                label: '本星期', value: 3
              }, {
                label: '上星期', value: 4
              }, {
                label: '本月', value: 5
              }, {
                label: '上月', value: 6
              }].map(item => <span id={item.value} className={`mx-1 operationBtn dateSelectBtn ${+_dateFlag === item.value && 'selected'}`}>{item.label}</span>)
                 }
              </div>
              <div className="flex-grow-1 d-flex justify-content-end pe-4">
                <div className="operationBtn findAndCollapse" onClick={() => setHide(!_hide)}>{!_hide ? '收起' : '展开'}</div>
              </div>
            </div>
          </div>
          {!_hide && (
          <>
            <div className="d-flex">
              <div>彩票种类</div>
              <div className="p-3">
                {_type === 'thirdParty' ? (
                  <>
                    <div className="selectedAndUnselected">
                      <div className="d-flex align-items-center" onClick={() => setgameCode(thirdPartyGamesList.map(item => item.gameCode))}>
                        <CheckCircleOutlined />
                        <span className="ms-1">全部选择</span>
                      </div>
                      <div className="ms-3" onClick={() => setgameCode([])}>全部不选</div>
                    </div>
                    <Checkbox.Group
                      options={thirdPartyGamesList ? thirdPartyGamesList.map(x => ({
                        label: x.gameName, value: x.gameCode
                      })) : []}
                      value={gameCode}
                      onChange={code => chooseThirdParty(code)}
                    />
                  </>
                )
                  : (
                    <>
                      <div className="selectedAndUnselected">
                        <div className="d-flex align-items-center" onClick={() => setlotteryType(lotteryList.map(item => item.code))}>
                          <CheckCircleOutlined />
                          <span>全部选择</span>
                        </div>
                        <div className="ms-3" onClick={() => setlotteryType([])}>全部不选</div>
                      </div>
                      <Checkbox.Group
                        options={lotteryList ? lotteryList.map(x => ({
                          label: x.lotteryName, value: x.code
                        })) : []}
                        value={lotteryType}
                        onChange={code => chooseLottery(code)}
                      />
                    </>
                  )}
              </div>
            </div>
            { _type !== 'thirdParty' && (
            <>
              <div className="d-flex">
                <div>期数</div>
                <div>
                  <Select
                    disabled={lotteryType.length !== 1}
                    className="installmentSelectStyle"
                    value={installment || '请选择单一彩种'}
                    onChange={value => setSelectedInstallments(value)}
                  >
                    {
                    _installmentsResults.map(item => (
                      <Option key={`UserBetStatus_${item.installment}`} value={item.installment}>{item.desc}</Option>
                    ))
                }
                  </Select>

                </div>
              </div>
              <div className="d-flex">
                <div>结算状态</div>
                <div className="align-items-center">
                  <Radio value="1" checked={isSettled === 1} onChange={() => setisSettled(1)}>已结算</Radio>
                  <Radio value="0" checked={isSettled === 0} onChange={() => setisSettled(0)}>未结算</Radio>
                </div>
              </div>
              <div className="d-flex">
                <div>代理报表种类</div>
                <div className="align-items-center">
                  <Radio value="0" checked={_type === 'jiaoShou'} onChange={() => setType('jiaoShou')}>交收报表</Radio>
                  <Radio value="1" checked={_type === 'classification'} onChange={() => setType('classification')}>分类报表</Radio>
                </div>
              </div>
            </>
            )}
            <div className="d-flex">
              <div>用户名</div>
              <div>
                <Input className="mx-1 reportInputStyle" value={memberLoginId} onChange={({ target: { value: text } }) => setmemberLoginId(text.trim())} />
              </div>
            </div>
            <div className="d-flex">
              <div>会员金额</div>
              <div>
                <Input className="mx-1 reportInputStyle" value={memberMoney} onChange={({ target: { value: text } }) => setmemberMoney(text === '' ? '' : text > -1 ? Number(text.trim()) : memberMoney)} />
              </div>
            </div>
            <div className="d-flex">
              <div>盈亏金额</div>
              <div>
                <Input className="mx-1 reportInputStyle" value={winLostAmount} onChange={({ target: { value: text } }) => setwinLostAmount(text === '' ? '' : text > -1 ? Number(text.trim()) : winLostAmount)} />
              </div>
            </div>
          </>
          )}
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center">
        <div
          className="operationBtn findAndCollapse mx-1"
          onClick={() => {
            setShowQueryMenu(false);
            setSpecialLoginName();
            setgamePage(true);
            onSearchClick();
          }}
        >
          查询
        </div>
        <div className="operationBtn cancel mx-1" onClick={() => cancel()}>取消</div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportCommonSelection);