
import { dxds, totalSumDS, totalSumDX, totaltailDX } from '../Items/dsdx';

import { dragonTiger, lianma11x5 } from '../Items/others';

import { number1to20 } from '../Items/numbers';

const CJ_11X5 = [{
  displayName: '两面',
  isStaticRow: 1,
  repeatPerRow: 5,
  settingCode: 'LM,ZHDS,ZHWDX',
  display: [[...dxds], [...dxds], [...dxds], [...dxds], [...dxds], [...totalSumDX, ...totalSumDS, ...totaltailDX, ...dragonTiger]],
  firstTableHeader: ['第一球', '第二球', '第三球', '第四球', '第五球', '总和'],
  prefix: ['B1', 'B2', 'B3', 'B4', 'B5', 'ZH'],
  tabId: 'count_LM'
}, {
  displayName: '单号1-5',
  settingCode: 'QH15,YZY',
  isStaticRow: 1,
  repeatPerRow: 3,
  display: [[...number1to20.slice(0, 11)], [...number1to20.slice(0, 11)], [...number1to20.slice(0, 11)], [...number1to20.slice(0, 11)], [...number1to20.slice(0, 11)], [...number1to20.slice(0, 11)]],
  firstTableHeader: ['第一球', '第二球', '第三球', '第四球', '第五球', '一中一'],
  prefix: ['B1', 'B2', 'B3', 'B4', 'B5', 'YZY'],
  tabId: 'count_QH15'
}, {
  displayName: '连码、直选',
  settingCode: 'LIANMA,ZX',
  display: [...lianma11x5],
  horizontalDataAlign: 1,
  horizontalSet: 3,
  tabId: 'count_RX2Z2'
}
];

export default CJ_11X5;