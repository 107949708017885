import { useEffect, useState } from 'react';

import { Button, Col, Label, Row } from 'reactstrap';
import { Input, Switch } from 'antd';
import { FaEdit } from 'react-icons/fa';
import { connect } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { AgentDataTable, Pagination } from '../..';
import { appActions, lotteryResultActions, oddsManageActions } from '../../../redux/actions';
import { number1to49 } from '../../../constants/OddsManageSetting/Items/numbers';
import { renderResultDateCell } from '../../../constants/utils';

import './styles.scss';

const mapStateToProps = state => ({
  panKouLotteryResult: state.lotteryResultReducers.panKouLotteryResult,
  activeLottery: state.commonReducers.activeLottery,
});

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  GetLotteryresult: (obj, callback) => dispatch(lotteryResultActions.GetLotteryresult(obj, callback)),
  CancelLotteryHis: (id, callback) => dispatch(oddsManageActions.CancelLotteryHis(id, callback)),
  DeleteLotteryHis: (id, callback) => dispatch(oddsManageActions.DeleteLotteryHis(id, callback)),
  Settlement: (obj, callback) => dispatch(oddsManageActions.Settlement(obj, callback)),
  RestoreBetting: (obj, callback) => dispatch(oddsManageActions.RestoreBetting(obj, callback)),
  OpenForMember: (id, callback) => dispatch(oddsManageActions.OpenForMember(id, callback)),
  CancelLotteryInstallment: (id, callback) => dispatch(oddsManageActions.CancelLotteryInstallment(id, callback)),
  GetLotteryHis: (id, callback) => dispatch(oddsManageActions.GetLotteryHis(id, callback)),
  UpdateLotteryHis: (obj, callback) => dispatch(oddsManageActions.UpdateLotteryHis(obj, callback)),
});

const HistoryDrawManagement = ({ panKouLotteryResult, UpdateLotteryHis, GetLotteryHis, OpenForMember, activeLottery, CancelLotteryInstallment, GetLotteryresult, CancelLotteryHis, DeleteLotteryHis, Settlement, RestoreBetting }) => {
  const { t, i18n } = useTranslation();
  const [_isQueryingDetail, setIsQueryingDetail] = useState(false);
  const [_activeId, setActiveId] = useState();
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(15);
  const [qishu, setqishu] = useState('');

  useEffect(() => {
    setIsQueryingDetail(true);
    const obj = {
      page,
      rows,
      lotteryType: activeLottery.code,
      date: moment().unix()
    };
    GetLotteryresult(obj, () => setIsQueryingDetail(false));
  }, [activeLottery?.code]);

  const onSearchClick = () => {
    setIsQueryingDetail(true);
    const obj = {
      page,
      rows,
      lotteryType: activeLottery.code,
      date: moment().unix(),
      qishu
    };
    GetLotteryresult(obj, () => setIsQueryingDetail(false));
  };

  const onPaginationClick = (pageNum, rows) => {
    setIsQueryingDetail(true);
    setPage(pageNum);
    setRows(rows);
    const obj = {
      page: pageNum,
      rows,
      lotteryType: activeLottery.code,
      date: moment().unix()
    };
    GetLotteryresult(obj, () => setIsQueryingDetail(false));
  };

  const cancelAndResume = id => {
    const obj = {
      page,
      rows,
      lotteryType: activeLottery.code,
      date: moment().unix()
    };
    if (
      (activeLottery.templateCode === 'HK6' && activeLottery.code !== 'JSHK6') || activeLottery.templateCode === 'FJ31X7' || activeLottery.templateCode === 'FJ36X7' || activeLottery.templateCode === 'QXC'
    ) {
      CancelLotteryInstallment(id, () => { GetLotteryresult(obj, () => setIsQueryingDetail(false)); });
    } else {
      CancelLotteryHis(id, () => { GetLotteryresult(obj, () => setIsQueryingDetail(false)); });
    }
  };

  const deleteHis = id => {
    const obj = {
      page,
      rows,
      lotteryType: activeLottery.code,
      date: moment().unix()
    };
    DeleteLotteryHis(id, () => { GetLotteryresult(obj, () => setIsQueryingDetail(false)); });
  };

  const JieSuan = data => {
    const obj = {
      page,
      rows,
      lotteryType: activeLottery.code,
      date: moment().unix()
    };
    Settlement({
      lotteryType: activeLottery.code,
      installments: data.installments,
      host: panKouLotteryResult.openLotteryMode === 2 ? panKouLotteryResult.hostArr[0].RemoteServiceUrl : ''
    }, () => { GetLotteryresult(obj, () => setIsQueryingDetail(false)); });
  };

  const ResetResult = id => {
    const obj = {
      page,
      rows,
      lotteryType: activeLottery.code,
      date: moment().unix()
    };
    RestoreBetting([id], () => { GetLotteryresult(obj, () => setIsQueryingDetail(false)); });
  };

  const openToMember = id => {
    const obj = {
      page,
      rows,
      lotteryType: activeLottery.code,
      date: moment().unix()
    };
    OpenForMember(id, () => { GetLotteryresult(obj, () => setIsQueryingDetail(false)); });
  };

  const modifyResult = id => {
    const obj = {
      page,
      rows,
      lotteryType: activeLottery.code,
      date: moment().unix()
    };
    GetLotteryHis(id, data => UpdateLotteryHis(data, () => { GetLotteryresult(obj); setIsQueryingDetail(false); }));
  };

  const control = [
    data => (
      <>
        <Button className="controlButton mx-1" onClick={() => JieSuan(data)}>结算</Button>
        <Button className="controlButton mx-1" onClick={() => modifyResult(data.id)}>修改</Button>
        {
        (activeLottery.templateCode === 'HK6' && activeLottery.code !== 'JSHK6') || activeLottery.templateCode === 'FJ31X7' || activeLottery.templateCode === 'FJ36X7' || activeLottery.templateCode === 'QXC'
          ? <Button className="controlButton mx-1" onClick={() => cancelAndResume(data.id)}>{data.status === 1 ? '取消期数' : '恢复期数'}</Button>
          : <Button className="controlButton mx-1" onClick={() => cancelAndResume(data.id)}>{data.status === 1 ? '取消' : '恢复'}</Button>
        }
        {data.isHis === 0 && <Button className="controlButton mx-1" onClick={() => ResetResult(data.id)}>重置</Button>}
        <Button className="controlButton mx-1" onClick={() => deleteHis(data.id)}>删除</Button>
      </>
    ),
  ];

  const generateStyle = item => {
    if (item) {
      const _item = number1to49.find(x => x.displayName === item);
      return <span className={`${_item.className}`}>{item}</span>;
    }
    return '';
  };

  const generateQXCNum = (str, type) => {
    if (str) {
      const arr = str.split(',');
      if (type < 7) {
        return arr[type];
      }
    }
    return '';
  };

  const generateQXCDS = (str, type) => {
    if (str) {
      const arr = str.split(',');
      if (type < 7 && arr[type]) {
        return arr[type] % 2 === 0 ? '双' : '单';
      }
      return '';
    }
    return '';
  };

  const generateQXCHDS = (str, firstType, secondType) => {
    if (str) {
      const arr = str.split(',');
      if (arr[firstType] && arr[secondType]) {
        return (Number(arr[firstType]) + Number(arr[secondType])) % 2 === 0 ? '双' : '单';
      }
      return '';
    }
    return '';
  };

  return (
    <div className="HistoryDrawManagementWrapper">
      <Col className="p-2 mb-2 filterWrapper d-flex align-items-center w-100 rounded-top">
        <span className="mx-2">期数</span>
        <Input
          className="qishuInputStyle"
          value={qishu}
          onChange={({ target: { value: text } }) => {
            setqishu(text > -1 ? text.trim() : qishu);
          }}
        />
        <Button className="searchButton redBtn mx-1" onClick={onSearchClick}>{t('FIND')}</Button>
      </Col>
      <Row className="mx-0 bg-white px-0 border-1">
        {
        (activeLottery.templateCode === 'HK6' && activeLottery.code !== 'JSHK6') || activeLottery.templateCode === 'FJ31X7' || activeLottery.templateCode === 'FJ36X7' || activeLottery.templateCode === 'QXC'
          ? ((activeLottery.templateCode === 'FJ31X7' || activeLottery.templateCode === 'FJ36X7')
            ? (
              <AgentDataTable
                isQuerying={_isQueryingDetail}
                tableControl={control}
                activeControl={_activeId}
                tableHeaderStyle={['p-0', 'p-0', 'p-0', 'p-0', 'p-0', 'p-0', 'p-0', 'p-0', 'p-0', 'p-0', 'p-0', 'p-0', 'p-0', 'p-0', 'p-0', 'p-0']}
                tableBodyRowStyle={['p-0', 'p-0', 'p-0', 'p-0', 'p-0', 'p-0', 'p-0', 'p-0', 'p-0', 'p-0', 'p-0', 'p-0', 'p-0', 'p-0', 'p-0', 'p-0']}
                tableHeaderTitle={
                  [{
                    label: '期数',
                  }, {
                    label: '日期',
                  }, {
                    label: '操作',
                  }, {
                    label: (
                      <div className="composedCell">
                        <div>彩球号码</div>
                        <div className="divideCellWithBorder subSingleInner">
                          <div>一</div>
                          <div>二</div>
                          <div>三</div>
                          <div>四</div>
                          <div>五</div>
                          <div>六</div>
                          <div>七</div>
                          <div>特</div>
                        </div>
                      </div>),
                  },
                  {
                    label: (
                      <div className="composedCell">
                        <div>特</div>
                        <div>单双</div>
                      </div>),
                  },
                  {
                    label: (
                      <div className="composedCell">
                        <div>特</div>
                        <div>大小</div>
                      </div>),
                  },
                  {
                    label: (
                      <div className="composedCell">
                        <div>特</div>
                        <div>大小尾</div>
                      </div>),
                  },
                  {
                    label: (
                      <div className="composedCell">
                        <div>特合</div>
                        <div>单双</div>
                      </div>),
                  },
                  {
                    label: (
                      <div className="composedCell">
                        <div>特合</div>
                        <div>大小</div>
                      </div>),
                  },
                  {
                    label: '总分'
                  },
                  {
                    label: (
                      <div className="composedCell">
                        <div>总</div>
                        <div>单双</div>
                      </div>),
                  },
                  {
                    label: (
                      <div className="composedCell">
                        <div>总</div>
                        <div>大小</div>
                      </div>),
                  },
                  {
                    label: '波段'
                  },
                  {
                    label: '结算'
                  },
                  {
                    label: '状态'
                  }]
                }
                tableData={panKouLotteryResult ? panKouLotteryResult.data.records : []}
                tableBodyRowProperty={
                  [
                    data => (<Label className="d-flex justify-content-center  text-primary">{data.installments}</Label>),
                    data => (<Label className="d-flex flex-column justify-content-center">{renderResultDateCell(data, i18n.language, true).map(item => <div className={`${item.className}`}>{item.value}</div>)}</Label>),
                    data => (
                      <Label className="d-flex justify-content-center" onClick={() => setActiveId(data.id)}>
                        <FaEdit className="ml-2 detailLink text-primary" />
                      </Label>
                    ),
                    data => (
                      <Label className="d-flex justify-content-center h-100">
                        <div className="divideCellWithBorder subSingleInner">
                          <div>
                            <div>{generateStyle(data.ping1)}</div>
                            <div>{data?.p1sx}</div>
                          </div>
                          <div>
                            <div>{generateStyle(data.ping2)}</div>
                            <div>{data?.p2sx}</div>
                          </div>
                          <div>
                            <div>{generateStyle(data.ping3)}</div>
                            <div>{data?.p3sx}</div>
                          </div>
                          <div>
                            <div>{generateStyle(data.ping4)}</div>
                            <div>{data?.p4sx}</div>
                          </div>
                          <div>
                            <div>{generateStyle(data.ping5)}</div>
                            <div>{data?.p5sx}</div>
                          </div>
                          <div>
                            <div>{generateStyle(data.ping6)}</div>
                            <div>{data?.p6sx}</div>
                          </div>
                          <div>
                            <div>{generateStyle(data.ping7)}</div>
                            <div />
                          </div>
                          <div>
                            <div>{generateStyle(data.tema)}</div>
                            <div>{data?.sx}</div>
                          </div>

                        </div>
                      </Label>
                    ),
                    data => (<Label className="d-flex justify-content-center">{data.tds}</Label>),
                    data => (<Label className="d-flex justify-content-center">{data.tdx}</Label>),
                    data => (<Label className="d-flex justify-content-center">{data.htwds}</Label>),
                    data => (<Label className="d-flex justify-content-center">{data.hds}</Label>),
                    data => (<Label className="d-flex justify-content-center">{data.hdx}</Label>),
                    data => (<Label className="d-flex justify-content-center">{data.sumMa}</Label>),
                    data => (<Label className="d-flex justify-content-center">{data.zds}</Label>),
                    data => (<Label className="d-flex justify-content-center">{data.zdx}</Label>),
                    data => (<Label className={`d-flex justify-content-center boDuanColor ${data.tmcolor === '红' && 'red'} ${data.tmcolor === '蓝' && 'blue'} ${data.tmcolor === '绿' && 'green'}`}>{data.tmcolor}</Label>),
                    data => (<Label className={`d-flex justify-content-center ${data.settled === 1 ? 'text-success' : 'text-danger'}`}>{data.settled === 1 ? '已结' : '未结'}</Label>),
                    data => (<Label className="d-flex justify-content-center"><Switch checked={data?.open4Member === 1} onText="开" offText="关" onChange={() => openToMember(data.id)} value={data?.open4Member === 1} /></Label>),
                  ]
                }
              />
            )
            : ((activeLottery.templateCode === 'QXC')
              ? (
                <AgentDataTable
                  isQuerying={_isQueryingDetail}
                  tableControl={control}
                  activeControl={_activeId}
                  tableHeaderStyle={['p-0', 'p-0', 'p-0', 'p-0', 'p-0', 'p-0', 'p-0', 'p-0']}
                  tableBodyRowStyle={['p-0', 'p-0', 'p-0', 'p-0', 'p-0', 'p-0', 'p-0', 'p-0']}
                  tableHeaderTitle={
                  [{
                    label: '期数',
                  }, {
                    label: '日期',
                  }, {
                    label: '操作',
                  }, {
                    label: (
                      <div className="composedCell">
                        <div>彩球号码</div>
                        <div className="divideCellWithBorder subSingleInner">
                          <div>仟</div>
                          <div>佰</div>
                          <div>拾</div>
                          <div>个</div>
                          <div>五</div>
                          <div>六</div>
                          <div>七</div>
                        </div>
                      </div>),
                  }, {
                    label: (
                      <div className="composedCell">
                        <div>单双</div>
                        <div className="divideCellWithBorder subSingleInner">
                          <div>仟</div>
                          <div>佰</div>
                          <div>拾</div>
                          <div>个</div>
                        </div>
                      </div>),
                  }, {
                    label: (
                      <div className="composedCell">
                        <div>合单双</div>
                        <div className="divideCellWithBorder subSingleInner">
                          <div>仟佰</div>
                          <div>仟拾</div>
                          <div>仟个</div>
                          <div>佰拾</div>
                          <div>佰个</div>
                          <div>拾个</div>
                        </div>
                      </div>),
                  },

                  {
                    label: '结算'
                  },
                  {
                    label: '状态'
                  }]
                }
                  tableData={panKouLotteryResult ? panKouLotteryResult.data.records : []}
                  tableBodyRowProperty={
                  [
                    data => (<Label className="d-flex justify-content-center  text-primary">{data.installments}</Label>),
                    data => (<Label className="d-flex flex-column justify-content-center">{renderResultDateCell(data, i18n.language, true).map(item => <div className={`${item.className}`}>{item.value}</div>)}</Label>),
                    data => (
                      <Label className="d-flex justify-content-center" onClick={() => setActiveId(data.id)}>
                        <FaEdit className="ml-2 detailLink text-primary" />
                      </Label>
                    ),
                    data => (
                      <Label className="d-flex justify-content-center h-100">
                        <div className="divideCellWithBorderForQXC">
                          <div>
                            {generateQXCNum(data.openResult, 0)}
                          </div>
                          <div>
                            {generateQXCNum(data.openResult, 1)}
                          </div>
                          <div>
                            {generateQXCNum(data.openResult, 2)}
                          </div>
                          <div>
                            {generateQXCNum(data.openResult, 3)}
                          </div>
                          <div>
                            {generateQXCNum(data.openResult, 4)}
                          </div>
                          <div>
                            {generateQXCNum(data.openResult, 5)}
                          </div>
                          <div>
                            {generateQXCNum(data.openResult, 6)}
                          </div>
                        </div>
                      </Label>
                    ),
                    data => (
                      <Label className="d-flex justify-content-center h-100">
                        <div className="divideCellWithBorderForQXC">
                          <div>
                            {generateQXCDS(data.openResult, 0)}
                          </div>
                          <div>
                            {generateQXCDS(data.openResult, 1)}
                          </div>
                          <div>
                            {generateQXCDS(data.openResult, 2)}
                          </div>
                          <div>
                            {generateQXCDS(data.openResult, 3)}
                          </div>

                        </div>
                      </Label>
                    ),
                    data => (
                      <Label className="d-flex justify-content-center h-100">
                        <div className="divideCellWithBorderForQXC">
                          <div>
                            {generateQXCHDS(data.openResult, 0, 1)}
                          </div>
                          <div>
                            {generateQXCHDS(data.openResult, 0, 2)}
                          </div>
                          <div>
                            {generateQXCHDS(data.openResult, 0, 3)}
                          </div>
                          <div>
                            {generateQXCHDS(data.openResult, 1, 2)}
                          </div>
                          <div>
                            {generateQXCHDS(data.openResult, 1, 3)}
                          </div>
                          <div>
                            {generateQXCHDS(data.openResult, 2, 3)}
                          </div>

                        </div>
                      </Label>
                    ),
                    data => (<Label className={`d-flex justify-content-center ${data.settled === 1 ? 'text-success' : 'text-danger'}`}>{data.settled === 1 ? '已结' : '未结'}</Label>),
                    data => (<Label className="d-flex justify-content-center"><Switch checked={data?.open4Member === 1} onText="开" offText="关" onChange={() => openToMember(data.id)} value={data?.open4Member === 1} /></Label>),
                  ]
                }
                />
              )
              : (
                <AgentDataTable
                  isQuerying={_isQueryingDetail}
                  tableControl={control}
                  activeControl={_activeId}
                  tableHeaderStyle={['p-0', 'p-0', 'p-0', 'p-0', 'p-0', 'p-0', 'p-0', 'p-0', 'p-0', 'p-0', 'p-0', 'p-0', 'p-0', 'p-0', 'p-0', 'p-0']}
                  tableBodyRowStyle={['p-0', 'p-0', 'p-0', 'p-0', 'p-0', 'p-0', 'p-0', 'p-0', 'p-0', 'p-0', 'p-0', 'p-0', 'p-0', 'p-0', 'p-0', 'p-0']}
                  tableHeaderTitle={
                  [{
                    label: '期数',
                  }, {
                    label: '日期',
                  }, {
                    label: '操作',
                  }, {
                    label: (
                      <div className="composedCell">
                        <div>彩球号码</div>
                        <div className="divideCellWithBorder subSingleInner">
                          <div>一</div>
                          <div>二</div>
                          <div>三</div>
                          <div>四</div>
                          <div>五</div>
                          <div>六</div>
                          <div>特</div>
                        </div>
                      </div>),
                  },
                  {
                    label: (
                      <div className="composedCell">
                        <div>特</div>
                        <div>单双</div>
                      </div>),
                  },
                  {
                    label: (
                      <div className="composedCell">
                        <div>特</div>
                        <div>大小</div>
                      </div>),
                  },
                  {
                    label: (
                      <div className="composedCell">
                        <div>特</div>
                        <div>大中小</div>
                      </div>),
                  },
                  {
                    label: (
                      <div className="composedCell">
                        <div>特尾</div>
                        <div>大小</div>
                      </div>),
                  },
                  {
                    label: (
                      <div className="composedCell">
                        <div>特合</div>
                        <div>单双</div>
                      </div>),
                  },
                  {
                    label: (
                      <div className="composedCell">
                        <div>特合</div>
                        <div>大小</div>
                      </div>),
                  },
                  {
                    label: (
                      <div className="composedCell">
                        <div>特合尾</div>
                        <div>大小</div>
                      </div>),
                  },
                  {
                    label: '总分'
                  },
                  {
                    label: (
                      <div className="composedCell">
                        <div>总</div>
                        <div>单双</div>
                      </div>),
                  },
                  {
                    label: (
                      <div className="composedCell">
                        <div>总</div>
                        <div>大小</div>
                      </div>),
                  },
                  {
                    label: '波段'
                  },
                  {
                    label: '结算'
                  },
                  {
                    label: '会员报表'
                  }]
              }
                  tableData={panKouLotteryResult ? panKouLotteryResult.data.records : []}
                  tableBodyRowProperty={
                [
                  data => (<Label className="d-flex justify-content-center text-primary">{data.installments}</Label>),
                  data => (<Label className="d-flex flex-column justify-content-center">{renderResultDateCell(data, i18n.language, true).map(item => <div className={`${item.className}`}>{item.value}</div>)}</Label>),
                  data => (
                    <Label className="d-flex justify-content-center" onClick={() => setActiveId(data.id)}>
                      <FaEdit className="ml-2 detailLink text-primary" />
                    </Label>
                  ),
                  data => (
                    <Label className="d-flex justify-content-center h-100">
                      <div className="divideCellWithBorder subInner">
                        <div>
                          <div>{generateStyle(data.ping1)}</div>
                          <div>{data?.p1sx}</div>
                        </div>
                        <div>
                          <div>{generateStyle(data.ping2)}</div>
                          <div>{data?.p2sx}</div>
                        </div>
                        <div>
                          <div>{generateStyle(data.ping3)}</div>
                          <div>{data?.p3sx}</div>
                        </div>
                        <div>
                          <div>{generateStyle(data.ping4)}</div>
                          <div>{data?.p4sx}</div>
                        </div>
                        <div>
                          <div>{generateStyle(data.ping5)}</div>
                          <div>{data?.p5sx}</div>
                        </div>
                        <div>
                          <div>{generateStyle(data.ping6)}</div>
                          <div>{data?.p6sx}</div>
                        </div>
                        <div>
                          <div>{generateStyle(data.tema)}</div>
                          <div>{data?.sx}</div>
                        </div>

                      </div>
                    </Label>
                  ),
                  data => (<Label className="d-flex justify-content-center">{data.tds}</Label>),
                  data => (<Label className="d-flex justify-content-center">{data.tdx}</Label>),
                  data => (<Label className="d-flex justify-content-center">{data.tdzx}</Label>),
                  data => (<Label className="d-flex justify-content-center">{data.htwds}</Label>),
                  data => (<Label className="d-flex justify-content-center">{data.hds}</Label>),
                  data => (<Label className="d-flex justify-content-center">{data.hdx}</Label>),
                  data => (<Label className="d-flex justify-content-center">{data.htwds}</Label>),
                  data => (<Label className="d-flex justify-content-center">{data.sumMa}</Label>),
                  data => (<Label className="d-flex justify-content-center">{data.zds}</Label>),
                  data => (<Label className="d-flex justify-content-center">{data.zdx}</Label>),
                  data => (<Label className={`d-flex justify-content-center boDuanColor ${data.tmcolor === '红' && 'red'} ${data.tmcolor === '蓝' && 'blue'} ${data.tmcolor === '绿' && 'green'}`}>{data.tmcolor}</Label>),
                  data => (<Label className={`d-flex justify-content-center ${data.settled === 1 ? 'text-success' : 'text-danger'}`}>{data.settled === 1 ? '已结' : '未结'}</Label>),
                  data => (<Label className="d-flex justify-content-center"><Switch checked={data?.open4Member === 1} onText="开" offText="关" onChange={() => openToMember(data.id)} value={data?.open4Member === 1} /></Label>),
                ]
              }
                />
              )
            )
          )
        // for other lotterys
          : (
            <AgentDataTable
              isQuerying={_isQueryingDetail}
              tableControl={control}
              activeControl={_activeId}
              tableHeaderTitle={
          [{
            label: '期数',
          }, {
            label: '操作',
          }, {
            label: '开奖时间',
          }, {
            label: '开奖号码',
          }, {
            label: '结算',
          }]
        }
              tableData={panKouLotteryResult ? panKouLotteryResult.data.records : []}
              tableBodyRowProperty={
          [
            data => (<Label className="d-flex justify-content-center  text-primary">{data.installments}</Label>),
            data => (
              <Label className="d-flex justify-content-center" onClick={() => setActiveId(data.id)}>
                <FaEdit className="ml-2 detailLink text-primary" />
              </Label>
            ),
            data => (<Label className="d-flex flex-column justify-content-center">{renderResultDateCell(data, i18n.language, true).map(item => <div className={`${item.className}`}>{item.value}</div>)}</Label>),
            data => (<Label className="d-flex justify-content-center">{data.openResult}</Label>),
            data => (<Label className={`d-flex justify-content-center ${data.settled === 1 ? 'text-success' : 'text-danger'}`}>{data.settled === 1 ? '已结' : '未结'}</Label>),
          ]
        }
            />
          )
        }
      </Row>
      <Row className="justify-center w-full relative">
        <Pagination totalRecord={(panKouLotteryResult && panKouLotteryResult.data.totalAmount) || 0} onClick={onPaginationClick} isQuery={false} />
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(HistoryDrawManagement);