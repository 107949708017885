

import { connect } from 'react-redux';

import './styles.scss';

const mapStateToProps = state => ({
  inTimeSheetOdds: state.inTimeSheetReducers.inTimeSheetOdds,
});

const DisplayLabel = ({ obj, betItemStyle }) => (
  <div className={`${betItemStyle} displayLabelWrapper`}>
    {obj?.img && <img src={obj.img} alt="" />}
    <div className={`${obj.className}`}>{obj.displayName}</div>
  </div>
);

export default connect(mapStateToProps)(DisplayLabel);