import { useEffect, useRef, useState } from 'react';

import { Label } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';

import { AgentDataTable } from '../../..';
import BetHistoryQuery from '../../../ThirdPartyGame/BetHistoryQuery';
import ReportCommonSelection from '../../../ReportCommonSelection';
import { thirdPartyGamesActions } from '../../../../redux/actions';
import './styles.scss';

const mapStateToProps = state => ({
  thirdPartyGamesList: state.thirdPartyGamesReducers.thirdPartyGamesList,
  thirdPartyWinLostAgentReport: state.thirdPartyGamesReducers.thirdPartyWinLostAgentReport,
});

const mapDispatchToProps = dispatch => ({
  GetWinLostAgentReport: (obj, callback) => dispatch(thirdPartyGamesActions.GetWinLostAgentReport(obj, callback)),
});

const ThirdReport = ({ thirdPartyGamesList, thirdPartyWinLostAgentReport, _specialLoginName, setSpecialLoginName }) => {
  const [_isQueryingDetail, setIsQueryingDetail] = useState(true);
  const [list, setList] = useState();
  const [total, setTotal] = useState();

  const ChildRef = useRef();

  const [_date, setDate] = useState([moment().startOf('day').toDate(), moment().toDate()]);

  useEffect(() => {
    if (thirdPartyWinLostAgentReport) {
      setList(thirdPartyWinLostAgentReport.betList);
      setTotal(thirdPartyWinLostAgentReport.total);
    }
  }, [thirdPartyWinLostAgentReport]);


  return (
    <div className="ThirdReportWrapper">
      <ReportCommonSelection setSpecialLoginName={setSpecialLoginName} _isShow={!_specialLoginName} _setDate={setDate} callback={data => setIsQueryingDetail(data)} _type="thirdParty" onRef={ChildRef} thirdPartyGamesList={thirdPartyGamesList} />
      <div className={`winLostReportTableWrapper p-3 ${_specialLoginName && 'd-none'}`}>
        <div className="mx-0 bg-white">
          <div className="headerTitle d-flex justify-content-center py-2 w-100">合计</div>
          <AgentDataTable
            isQuerying={_isQueryingDetail}
            tableHeaderTitle={
            [{
              label: '账号',
            }, {
              label: '名称',
            }, {
              label: '余额',
            }, {
              label: '笔数',
            }, {
              label: '下注金额',
            }, {
              label: '有效金额',
            }, {
              label: '手续费',
            }, {
              label: (
                <div className="combineTitleCell">
                  <div className="h-50 py-1">会员输赢</div>
                  <div className="d-flex h-50">
                    <div className="py-1">输赢</div>
                    <div className="py-1">退水</div>
                    <div className="py-1">盈亏结果</div>
                  </div>
                </div>
              ),
            }, {
              label: (
                <div className="combineTitleCell">
                  <div className="h-50 py-1">总公司 输赢</div>
                  <div className="d-flex h-50">
                    <div className="py-1">应收下线</div>
                    <div className="py-1">占成</div>
                    <div className="py-1">实占金额</div>
                    <div className="py-1">实占结果</div>
                    <div className="py-1">实占退水</div>
                    <div className="py-1">赚水</div>
                    <div className="py-1">盈亏结果</div>
                  </div>
                </div>
              ),
            }, {
              label: '上交货量',
            }, {
              label: '上级交收',
            }]
          }
            tableHeaderStyle={['', '', '', '', '', '', '', 'p-0', 'p-0', '', '']}
            tableData={list || []}
            tableBodyRowStyle={['', '', '', '', '', '', '', 'p-0', 'p-0', '', '']}
            tableBodyRowProperty={
            [
              data => (
                <Label
                  className="d-flex justify-content-center numberBlue detailLink"
                  onClick={() => {
                    if (data.userType === 0) {
                      setSpecialLoginName(data.loginName);
                    } else {
                      ChildRef.current.onSearchClick('', '', '', data.loginName, data.userType);
                    }
                  }}
                >
                  {data.loginName || '直属会员'}
                </Label>
              ),
              data => (<Label className="d-flex justify-content-center">{data.description}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.balance}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.count}</Label>),
              data => (<Label className="d-flex justify-content-center numberBlue">{data.amount}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.validAmount}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.handleFee}</Label>),
              data => (
                <Label className="d-flex justify-content-center combineBodyRowCell">
                  <div>{data.winLost}</div>
                  <div>{data.rebateAmount}</div>
                  <div>{data.winLostResult}</div>
                </Label>
              ),
              data => (
                <Label className="d-flex justify-content-center combineBodyRowCell">
                  <div className={`${parseFloat(data.subResult) < 0 ? 'numberRed' : 'numberBlue'}`}>{data.subResult}</div>
                  <div>{`${data.maxZc}%`}</div>
                  <div>{data.zcAmount}</div>
                  <div>{data.zcResult}</div>
                  <div>{data.zcRebate}</div>
                  <div>{data.zcRebateAmount}</div>
                  <div className={`${parseFloat(data.zcTotal) < 0 ? 'numberRed' : 'numberBlue'}`}>{data.zcTotal}</div>
                </Label>
              ),
              data => (<Label className="d-flex justify-content-center">{data.uplineAmount}</Label>),
              data => (<Label className="d-flex justify-content-center numberBlue">{data.uplineResult}</Label>),
            ]
          }
            tableFooter={[
              [{
                value: `合计：${list ? list.length : 0}行`,
                colspan: 3,
                className: 'pe-1 total tableSum'
              },
              {
                value: (total && total.count) || 0,
                colspan: 1,
                className: 'tableSum'
              },
              {
                value: (total && total.amount) || 0,
                colspan: 1,
                className: 'tableSum'
              },
              {
                value: (total && total.validAmount) || 0,
                colspan: 1,
                className: 'tableSum'
              },
              {
                value: (total && total.fee) || 0,
                colspan: 1,
                className: 'tableSum'
              },
              {
                render: () => {
                  if (total) {
                    return (
                      <Label className="d-flex justify-content-center combineBodyRowCell">
                        <div>{total.winLost}</div>
                        <div>{total.rebateAmount}</div>
                        <div>{total.winLostResult}</div>
                      </Label>
                    );
                  }

                  return 0;
                },
                colspan: 1,
                className: 'tableSum noPadding'
              },
              {
                render: () => {
                  if (total) {
                    return (
                      <Label className="d-flex justify-content-center combineBodyRowCell">
                        <div className={`${parseFloat(total.subResult) < 0 ? 'numberRed' : 'numberBlue'}`}>{total.subResult}</div>
                        <div>{total.maxZc}</div>
                        <div>{total.zcAmount}</div>
                        <div>{total.zcResult}</div>
                        <div>{total.zcRebate}</div>
                        <div>{total.zcRebateAmount}</div>
                        <div className={`${parseFloat(total.zcTotal) < 0 ? 'numberRed' : 'numberBlue'}`}>{total.zcTotal}</div>
                      </Label>
                    );
                  }

                  return 0;
                },
                colspan: 1,
                className: 'tableSum noPadding'
              },
              {
                value: (total && total.uplineAmount) || 0,
                colspan: 1,
                className: 'tableSum'
              },
              {
                value: (total && total.uplineResult) || 0,
                colspan: 1,
                className: 'tableSum numberBlue'
              }]
            ]}
          />
        </div>
      </div>
      {
        _specialLoginName
        && (
          <div className="mt-2">
            <BetHistoryQuery presetObj={{
              loginName: _specialLoginName, beginDate: _date[0], endDate: _date[1]
            }}
            />
          </div>
        )
      }
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ThirdReport);