import { useEffect, useState } from 'react';

import { Button, Checkbox, Input } from 'antd';
import { Col, Label, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { agentActions, appActions } from '../../../../redux/actions';
import AgentDataTable from '../../../AgentDataTable';
import './styles.scss';

const mapStateToProps = state => ({
  subAccountData: state.agentReducers.subAccountData,
  userData: state.memberReducers.userData,
  isValidNameMsg: state.agentReducers.isValidNameMsg,
  isValidLoginIdMsg: state.agentReducers.isValidLoginIdMsg,
});

const mapDispatchToProps = dispatch => ({
  GetAddSubAccountInfo: (parentId, isChild = 1, callback = () => {}) => dispatch(agentActions.GetAddSubAccountInfo(parentId, isChild, callback)),
  saveSubAgent: (obj, callback) => dispatch(agentActions.saveSubAgent(obj, callback)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  isValidName: (userId, callback) => dispatch(agentActions.isValidName(userId, callback)),
  isExistLoginId: (userId, callback) => dispatch(agentActions.isExistLoginId(userId, callback)),
  resetReturnMsgData: () => dispatch(agentActions.resetReturnMsgData()),
  resetSubAccountInfo: () => dispatch(agentActions.resetSubAccountInfo()),
});

const SubAccountAddEdit = ({ callback = () => { }, onCancel = () => { }, resetReturnMsgData, resetSubAccountInfo, GetAddSubAccountInfo, subAccountData, data, saveSubAgent, updateModalAlert, userData, isValidName, isExistLoginId, isValidNameMsg, isValidLoginIdMsg }) => {
  const { t } = useTranslation();
  const [_password, setPassword] = useState();
  const [_loginId, setLoginId] = useState();
  const [_name, setName] = useState();
  const [_nameReturnMsg, setNameReturnMsg] = useState({});
  const [_loginIdReturnMsg, setLoginIdReturnMsg] = useState({});
  const [_currentOrg, setCurrentOrg] = useState({});
  const [_passwordMsg, setPasswordMsg] = useState({});
  const [isQuery, setIsQuery] = useState(true);

  const [checkAll1, setCheckAll1] = useState(false);
  // const [checkAll2, setCheckAll2] = useState(false);

  // 权限管理
  // 左边大菜单， menus 是选项集合，包含左边的选项
  const [authMenu, setAuthMenu] = useState([]);
  const leftMenu = authMenu.filter(x => x.parentId === '-1')?.sort((a, b) => ((a.orderBy > b.orderBy) ? 1 : -1));

  // 彩种管理
  const [lotteryMenu, setLotteryMenu] = useState([]);
  const leftLotteryMenu = lotteryMenu.reduce((acc, item) => {
    const exist = acc.find(i => i.categoryCode === item.categoryCode);
    if (!exist) {
      acc.push({
        categoryName: item.categoryName,
        categoryCode: item.categoryCode,
      });
    }
    return acc;
  }, []).sort((a, b) => ((a.orderBy > b.orderBy) ? -1 : 1));

  useEffect(() => {
    resetSubAccountInfo();
    resetReturnMsgData();
    if (subAccountData) {
      const _menus = subAccountData?.parentMenu.map(item => ({
        ...item, checked: false,
      }));

      const totalLotteryMenus = subAccountData?.parentLotList || [];
      const _lotteryMenu = totalLotteryMenus.map(item => ({
        ...item, checked: false,
      }));

      setAuthMenu(_menus);
      setLotteryMenu(_lotteryMenu);
      setCurrentOrg(subAccountData?.orgParent);
      setIsQuery(false);
    }
  }, [subAccountData]);

  useEffect(() => {
    if (data) {
      GetAddSubAccountInfo(userData?.id);
    }
  }, [data]);

  useEffect(() => {
    setNameReturnMsg(isValidNameMsg);
  }, [isValidNameMsg]);

  useEffect(() => {
    setLoginIdReturnMsg(isValidLoginIdMsg);
  }, [isValidLoginIdMsg]);

  const handleNameOnBlur = text => {
    setName(text);
    if (text?.length > 1) {
      isValidName(text);
    } else {
      setNameReturnMsg({
        state: 99,
        msg: '名称只能2-12位由数字、英文字母,中文组成'
      });
    }
  };

  const handleLoginIdOnBlur = text => {
    setLoginId(text);
    if (text?.length > 3) {
      isExistLoginId(text);
    } else {
      setLoginIdReturnMsg({
        state: 99,
        msg: '帐号必须由字母数字组成, 4-12位'
      });
    }
  };

  const handlePasswordChange = text => {
    setPassword(text);
    if (text?.length > 5 && text?.length < 21 && text.match(/^[0-9a-zA-Z]+$/)) {
      if (/[0-9]/.test(text) && /[a-z]/.test(text) && /[A-Z]/.test(text)) {
        setPasswordMsg({
          state: 1,
          msg: '密码可用'
        });
      } else {
        setPasswordMsg({
          state: 99,
          msg: '密码必须由字母数字组成（区分大小写）！'
        });
      }
    } else {
      setPasswordMsg({
        state: 99,
        msg: '密码必须由字母数字组成，6-20位（区分大小写）！'
      });
    }
  };


  const saveButton = isResetMFA => {
    updateModalAlert({
      type: 'confirmation',
      visible: true,
      data: {
        message: '请确认是否要设置',
        onConfirmationClick: () => changData()
      }
    });
    const changData = () => {
      const newProj = _currentOrg;
      newProj.loginId = _loginId;
      newProj.name = _name;
      if (isResetMFA) {
        newProj.validType = 0;
      }
      if (_password) {
        newProj.password = _password;
      }
      const values = authMenu.filter(item => item.checked).map(checkbox => checkbox.id);

      const lotteryValues = lotteryMenu.filter(checkbox => checkbox.checked).map(checkbox => checkbox.id);
      if (_loginIdReturnMsg?.state === 1 && _nameReturnMsg?.state === 1 && _passwordMsg?.state === 1) {
        updateModalAlert({
          type: 'loading',
          visible: true
        });
        newProj.parentId = userData?.id;
        const obj = {
          subAgent: newProj,
          isUpdate: false,
          menuId: values || [],
          lotteryType: lotteryValues || []
        };
        saveSubAgent(obj, callback);
      } else {
        updateModalAlert({
          visible: true,
          type: 'confirmation',
          data: {
            message: _loginIdReturnMsg?.state !== 1 ? '帐号必须由字母数字组成，4-12位' : _passwordMsg?.state !== 1 ? '密码必须由字母数字组成（大写+小写+数字），6-20位（区分大小写）' : _nameReturnMsg?.state !== 1 ? '名称只能2-12位由数字、英文字母,中文组成' : '',
            showCancelButton: false
          }
        });
      }
    };
  };

  const tableHeader = [{
    label: '主菜单',
  }, {
    label: (
      <>
        子菜单项
        <Checkbox
          className="mx-1"
          checked={checkAll1}
          onChange={e => {
            setCheckAll1(e.target.checked);
            setAuthMenu(authMenu.map(item => ({
              ...item,
              checked: e.target.checked
            })));
          }}
        >
          选择所有
        </Checkbox>
      </>),
  }];

  const tableContent = [
    data => {
      const _menus = authMenu.filter(x => x.parentId === data.id);
      const checked = _menus.every(item => item.checked);
      return (
        <div className="d-flex justify-content-end no-wrap m-1 p-1">
          <Checkbox
            className="w-all-checkbox"
            checked={_menus?.length ? checked : data.checked}
            onChange={e => {
              const index = authMenu.findIndex(i => i.id === data.id);
              const item = authMenu[index];
              setAuthMenu([
                ...authMenu.slice(0, index),
                {
                  ...item, checked: e.target.checked
                },
                ...authMenu.slice(index + 1),
              ].map(item => {
                if (item.parentId === data.id) {
                  return {
                    ...item, checked: e.target.checked
                  };
                }
                return item;
              }));
            }}
          >
            {data?.menuName}
          </Checkbox>
        </div>
      );
    },
    data => {
      const _menus = authMenu.filter(x => x.parentId === data.id);
      return (
        <>
          {
            _menus.map(d => (
              <p key={`returnSelection_${d?.id}`} className="d-inline border-0 m-1">
                <Checkbox
                  className="w-single-checkbox"
                  checked={d.checked}
                  onChange={e => {
                    const index = authMenu.findIndex(i => i.id === d.id);
                    const item = authMenu[index];
                    setAuthMenu([
                      ...authMenu.slice(0, index),
                      {
                        ...item,
                        checked: e.target.checked
                      },
                      ...authMenu.slice(index + 1),
                    ]);
                  }}
                >
                  {d.menuName}
                </Checkbox>
              </p>
            ))
          }
        </>
      );
    }
  ];

  const tableContent2 = [
    data => {
      const _menus = lotteryMenu.filter(x => x.categoryCode === data.categoryCode);
      const checked = _menus.every(item => item.checked);
      return (
        <div className="d-flex justify-content-end no-wrap m-1 p-1">
          <span className="mx-2">{data?.categoryName}</span>
          <Checkbox
            checked={checked}
            className="w-all-checkbox"
            onChange={e => {
              const { checked } = e.target;
              setLotteryMenu(
                lotteryMenu.map(item => {
                  if (item.categoryCode === data.categoryCode) {
                    return {
                      ...item, checked,
                    };
                  }
                  return item;
                })
              );
            }}
          >
            所有
          </Checkbox>
        </div>
      );
    },
    data => {
      const _menus = lotteryMenu.filter(x => x.categoryCode === data.categoryCode);
      return (
        <>
          {
            _menus.map(d => (
              <p key={d.code} className="d-inline border-0 m-1">
                <Checkbox
                  className="w-single-checkbox"
                  checked={d.checked}
                  onChange={e => {
                    const index = lotteryMenu.findIndex(i => i.id === d.id);
                    const item = lotteryMenu[index];
                    setLotteryMenu([
                      ...lotteryMenu.slice(0, index),
                      {
                        ...item,
                        checked: e.target.checked
                      },
                      ...lotteryMenu.slice(index + 1),
                    ]);
                  }}
                >
                  {d.lotteryName}
                </Checkbox>
              </p>
            ))
          }
        </>
      );
    }
  ];

  // console.log(leftLotteryMenu);

  return (
    <div className="SubAccAddEditWrapper">
      <div className="mx-0 bg-white my-3  border-1">
        <Row className="titleWrapper m-0 my-2 p-2">
          账户资料
        </Row>
        <Row className="bigWrapper m-0 my-3 p-3">
          <table className="agentDataTable p-3 m-0" cellSpacing="0" cellPadding="0" border="0">
            <tbody>
              <tr>
                <td className="tableHeader col-5">子帐号</td>
                <td>
                  <span>
                    <Input
                      className="d-inline"
                      value={_loginId}
                      onChange={({ target: { value: text } }) => setLoginId(text.trim())}
                      onBlur={({ target: { value: text } }) => handleLoginIdOnBlur(text.trim())}
                    />
                    <Label className={`d-inline mx-2 ${_loginIdReturnMsg?.state === 1 ? 'text-success' : 'text-danger'}`}>{_loginIdReturnMsg?.msg}</Label>
                  </span>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-5">登入密码</td>
                <td>
                  <span>
                    <Input
                      className="d-inline"
                      value={_password}
                      onChange={({ target: { value: text } }) => handlePasswordChange(text.trim())}
                    />
                  </span>
                  <Label className={`d-inline mx-2 ${_passwordMsg?.state === 1 ? 'text-success' : 'text-danger'}`}>{_passwordMsg?.msg}</Label>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-5">子帐号名称</td>
                <td>
                  <span>
                    <Input
                      className="d-inline"
                      value={_name}
                      onChange={({ target: { value: text } }) => setName(text.trim())}
                      onBlur={({ target: { value: text } }) => handleNameOnBlur(text.trim())}
                    />
                    <Label className={`d-inline mx-2 ${_nameReturnMsg?.state === 1 ? 'text-success' : 'text-danger'}`}>{_nameReturnMsg?.msg}</Label>
                  </span>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-5">MFA验证</td>
                <td>
                  {
                    _currentOrg?.validType === 1 ? (<Button className="button handle" onClick={() => saveButton(true)}>{t('RESET')}</Button>)
                      : (<span>未设置</span>)
                  }
                </td>
              </tr>
            </tbody>
          </table>
        </Row>
        <Row className="titleWrapper m-0 my-2 p-2">
          权限设定
        </Row>
        <Row className="bigWrapper m-0 my-3 p-3">
          {
            isQuery ? (
              <Row>
                <AgentDataTable
                  isQuerying={isQuery}
                  tableHeaderTitle={tableHeader}
                  tableData={[1, 2, 3]}
                  tableBodyRowProperty={tableContent}
                  tableHeaderStyle={['textRight px-1 col-5', 'textLeft px-1']}
                  tableBodyRowStyle={['backgroundColored p-0 mediumSmallCol', 'textLeft flex-wrap']}
                />
              </Row>
            ) : (
              <AgentDataTable
                tableHeaderTitle={tableHeader}
                tableData={leftMenu}
                tableBodyRowProperty={tableContent}
                tableHeaderStyle={['textRight px-1 col-5', 'textLeft px-1']}
                tableBodyRowStyle={['backgroundColored p-0 mediumSmallCol', 'textLeft flex-wrap']}
              />
            )
          }
        </Row>

        <Row className="titleWrapper m-0 my-2 p-2">
          彩种选择
        </Row>
        <Row className="bigWrapper m-0 my-3 p-3">
          {
            isQuery ? (
              <Row>
                <AgentDataTable
                  isQuerying={isQuery}
                  tableHeaderTitle={[]}
                  tableData={[1, 2, 3]}
                  tableBodyRowProperty={tableContent2}
                  tableHeaderStyle={['textRight px-1 col-5', 'textLeft px-1']}
                  tableBodyRowStyle={['backgroundColored p-0 mediumSmallCol', 'textLeft flex-wrap']}
                />
              </Row>
            ) : (
              <AgentDataTable
                tableHeaderTitle={[tableHeader[0]]}
                tableData={leftLotteryMenu}
                tableBodyRowProperty={tableContent2}
                tableHeaderStyle={['textRight px-1 col-5', 'textLeft px-1']}
                tableBodyRowStyle={['backgroundColored p-0 mediumSmallCol', 'textLeft flex-wrap']}
              />
            )
          }
        </Row>
      </div>

      <Row className="mb-5 my-2 p-2">
        <Col className="d-flex justify-content-center py-2 border-top">
          <Button className="button handle mx-1" onClick={() => saveButton()}>{t('SAVE')}</Button>
          <Button className="button find mx-1" onClick={() => onCancel?.()}>{t('CANCEL')}</Button>
        </Col>
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SubAccountAddEdit);