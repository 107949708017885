import { connect } from 'react-redux';

import noMatch from '../../assets/image/404.png';
import './styles.scss';


const NoMatch = () => (
  <div className="noMatchWrapper">
    <img src={noMatch} alt="404" />
  </div>
);

export default connect(null, null)(NoMatch);