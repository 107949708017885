import { useEffect, useState } from 'react';

import { Col, Label, Row } from 'reactstrap';
import { connect } from 'react-redux';

import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { AgentDataTable, Pagination } from '../..';
import { thirdPartyGamesActions } from '../../../redux/actions';


import './styles.scss';

const mapStateToProps = state => ({
  transactionManage: state.thirdPartyGamesReducers.transactionManage,
});

const mapDispatchToProps = dispatch => ({
  GetTransactionList: (obj, callback) => dispatch(thirdPartyGamesActions.GetTransactionList(obj, callback)),
});

const RecentTransaction = ({ transactionManage, GetTransactionList, data, callback = () => {} }) => {
  const { t } = useTranslation();
  const [_isQueryingDetail, setIsQueryingDetail] = useState(false);
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(15);
  const _date = [moment().subtract(5, 'years').startOf('day').toDate(), moment().endOf('day').toDate()];

  const userName = data.mName;
  const dateType = 2;

  const { mType } = data;
  const orderID = '';
  const minAcAmount = '';
  const maxAcAmount = '';
  const orderType = 2;
  const { actionType } = data;
  const actionStatus = '';
  const confirmStatus = '';

  useEffect(() => {
    setIsQueryingDetail(true);
    const _obj = {
      startTime: moment(_date[0]).unix(),
      endTime: moment(_date[1]).unix(),
      page,
      rows,
      dateType,
      orderType,
      currencyCode: '',
      actionType,
      userName,
      mType,
    };
    GetTransactionList(_obj, setIsQueryingDetail);
  }, []);


  const onPaginationClick = (pageNum, rows) => {
    setIsQueryingDetail(true);
    setPage(pageNum);
    setRows(rows);
    const _obj = {
      userName,
      orderID,
      dateType,
      mType,
      minAcAmount,
      maxAcAmount,
      orderType,
      actionStatus,
      confirmStatus,
      currencyCode: '',
      actionType,

      page: pageNum,
      rows,
      startTime: moment(_date[0]).unix(),
      endTime: moment(_date[1]).unix()
    };
    GetTransactionList(_obj, setIsQueryingDetail);
  };

  return (
    <Col className="RecentTransactionWrapper">
      <Row className="mx-0 bg-white px-0 border-1">
        <AgentDataTable
          isQuerying={_isQueryingDetail}
          tableHeaderTitle={
            [{
              label: t('ORDER_NUM'),
            }, {
              label: '交易时间',
            }, {
              label: '交易类型',
            }, {
              label: '交易金额',
            }, {
              label: '交易状态',
            }, {
              label: '交易描述',
            }, {
              label: '订单详情',
            }]
          }
          tableHeaderStyle={['headerStyle', 'headerStyle', 'headerStyle', 'headerStyle', 'headerStyle', 'headerStyle', 'headerStyle', 'headerStyle', 'headerStyle', 'headerStyle', 'headerStyle', 'headerStyle', 'headerStyle', 'headerStyle', 'headerStyle', 'headerStyle']}
          tableData={transactionManage ? transactionManage.list : []}
          tableBodyRowProperty={
            [
              data => (<Label className="d-flex justify-content-center">{data.no}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.createTime}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.actionTypeName}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.actualAmount}</Label>),
              data => (<Label className={data.actionStatus === '300' ? 'successValue' : 'negativeValue'}>{data.actionStatusName}</Label>),
              data => (<Label className="d-flex justify-content-center">{`${data.createRemark}`}</Label>),
              data => <Label className="d-flex justify-content-center align-items-center"><div className=" operationBtn editAndSave" onClick={() => callback(data)}>详情</div></Label>
            ]
          }
        />
      </Row>
      <Row className="justify-center w-full relative">
        <Pagination totalRecord={transactionManage ? (transactionManage.sum.totalRecord || 0) : 0} onClick={onPaginationClick} isQuery={false} />
      </Row>
    </Col>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(RecentTransaction);