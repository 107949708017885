import { useEffect, useState } from 'react';

import { Button, Col, Input, Label, Row } from 'reactstrap';
import { FaPlusCircle } from 'react-icons/fa';
import QRCode from 'react-google-qrcode';
import { connect } from 'react-redux';

import { useTranslation } from 'react-i18next';

import { appActions, systemActions } from '../../../redux/actions';
import AgentDataTable from '../../AgentDataTable';
import Pagination from '../../Content/Pagination';
import './styles.scss';


const mapStateToProps = state => ({
  paymentAccSettingData: state.systemReducers.paymentAccSettingData,
  userData: state.memberReducers.userData,
});

const mapDispatchToProps = dispatch => ({
  getPayAccountSetting: (userId, callback) => dispatch(systemActions.getPayAccountSetting(userId, callback)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  setPayAccountStatus: (obj, callback) => dispatch(systemActions.setPayAccountStatus(obj, callback)),
  deletePayAccount: (obj, callback) => dispatch(systemActions.deletePayAccount(obj, callback)),
});

const PaymentAccSetting = ({ callback = () => {}, paymentAccSettingData, getPayAccountSetting, data, updateModalAlert, deletePayAccount, setPayAccountStatus }) => {
  const { t } = useTranslation();
  const [_info, setInfo] = useState([]);
  const [_page, setPage] = useState(1);
  const [_rows, setRows] = useState(15);
  const [_totalRecord, setTotalRecord] = useState(0);
  const [_isQueryingDetail, setIsQueryingDetail] = useState(true);
  const [isQueryStatus, setQueryStatus] = useState(true);
  const [_bankNo, setBankNo] = useState('');
  const [_bankName, setBankName] = useState('');

  useEffect(() => {
    if (paymentAccSettingData && JSON.stringify(paymentAccSettingData?.bankList) !== JSON.stringify(_info)) {
      setInfo(paymentAccSettingData?.bankList);
      setTotalRecord(paymentAccSettingData?.totalRecords);
      setQueryStatus(false);
    }
  }, [paymentAccSettingData]);

  useEffect(() => {
    setIsQueryingDetail(true);
    getPayAccountSetting({
      bankNo: _bankNo,
      bankName: _bankName,
      page: _page,
      rows: _rows,
    });
  }, []);

  const findClick = () => {
    setIsQueryingDetail(false);
    // Page number and {row number will follow the pagination component instead
    getPayAccountSetting({
      bankNo: _bankNo,
      bankName: _bankName,
      page: 1,
      rows: _rows,
    });
  };

  const onPaginationClick = (pageNum, rows) => {
    setIsQueryingDetail(false);
    // Page number and {row number will follow the pagination component instead
    getPayAccountSetting({
      bankNo: _bankNo,
      bankName: _bankName,
      page: pageNum,
      rows: _rows,
    });
    setPage(pageNum);
    setRows(rows);
  };
  const deleteClick = id => {
    updateModalAlert({
      type: 'confirmation',
      visible: true,
      showCancelButton: true,
      data: {
        message: '确认删除',
        onConfirmationClick: () => deletePayAccount(id)
      },
    });
  };

  const setStatus = id => {
    updateModalAlert({
      type: 'confirmation',
      visible: true,
      showCancelButton: true,
      data: {
        message: '确认变换状态',
        onConfirmationClick: () => setPayAccountStatus(id)
      },
    });
  };

  const tableHeader = [{
    label: t('ACCOUNT_CATEGORISE'),
  }, {
    label: t('BELONGS_BANK'),
  }, {
    label: t('ACCOUNT_NUMBER'),
  }, {
    label: t('ACCOUNT_OPEN_NAME'),
  }, {
    label: t('ACCOUNT_OPEN_ADDRESS'),
  }, {
    label: t('DAILY_LIMIT'),
  }, {
    label: t('MAX_SINGLE_TRANSACTION'),
  }, {
    label: t('MIN_SINGLE_TRANSACTION'),
  }, {
    label: t('PLATFORM'),
  }, {
    label: t('STATUS'),
  }, {
    label: t('FUNCTION'),
  }];

  const tableContent = [
    data => (<Label className="d-flex justify-content-center">{data?.type}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.name}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.number}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.accountName}</Label>),
    data => (data?.code === 'BOQrCode' ? <div className="d-flex justify-content-center"><QRCode data={`https://chart.googleapis.com/chart?cht=qr&chs=300x300&chl=${data?.address}`} /></div> : data?.address),
    data => (<Label className="d-flex justify-content-center">{data?.dayMaxAmount}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.maxAmount}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.minAmount}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.platform}</Label>),
    data => (data?.status === 1 ? <Label className="negativeValue d-flex justify-content-center">禁用</Label> : <Label className="d-flex justify-content-center">正常</Label>),
    data => (
      <>
        <Button className="button yellow mx-1" onClick={() => setStatus(data?.id)}>{data?.status === 1 ? '启用' : '禁用'}</Button>
        <Button className="button handle mx-1" onClick={() => callback('paymentAccEditAdd', data, true)}>{t('EDIT')}</Button>
        <Button className="button find mx-1" onClick={() => deleteClick(data?.id)}>{t('DELETE')}</Button>
      </>
    ),
  ];

  return (
    <div className="PaymentAccSettingWrapper">
      <div className="mx-0 bg-white my-2  border-1">
        <Row className="p-0 m-0 mb-2">
          <Col className="p-1 mb-2 filterWrapper d-flex align-items-center">
            <Button
              className="button add justify-content-center"
              onClick={() => callback('paymentAccEditAdd', data, false)}
            >
              <FaPlusCircle className="mb-1 mr-1" />
              <Label className="p-0 px-1">{t('ADD_PAYMENT_ACCOUNT')}</Label>
            </Button>
            <Label value={t('BANK_CARD_NUMBER')} className="px-3 marginLeft5">{t('BANK_CARD_NUMBER')}</Label>
            <Input type="text" className="mx-2 justify-content-center" value={_bankNo} onChange={({ target: { value: text } }) => setBankNo(text)} />
            <Label value={t('BANK_CARD_NAME')} className="mx-3">{t('BANK_CARD_NAME')}</Label>
            <Input type="text" className="mx-2 justify-content-center" value={_bankName} onChange={({ target: { value: text } }) => setBankName(text)} />
            <Button className="button find mx-3" onClick={() => findClick()}>{t('FIND')}</Button>
          </Col>
        </Row>
        <Row className="bigWrapper m-0 my-1 p-3">
          <AgentDataTable
            isQuerying={isQueryStatus}
            tableHeaderTitle={tableHeader}
            tableData={_info || (isQueryStatus ? [1, 2, 3] : [])}
            tableBodyRowProperty={tableContent}
            tableBodyRowStyle={[]}
          />
        </Row>
        <Row className="justify-center w-full relative">
          <Pagination totalRecord={_totalRecord || 0} onClick={onPaginationClick} isQuery={_isQueryingDetail} />
        </Row>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentAccSetting);