const dxds = [
  {
    displayName: '单',
  }, {
    displayName: '双',
  }, {
    displayName: '大',
  }, {
    displayName: '小',
  }
];

const hdxds = [
  {
    displayName: '合单',
  }, {
    displayName: '合双',
  }, {
    displayName: '合大',
  }, {
    displayName: '合小',
  }
];

const wdx = [
  {
    displayName: '尾大',
  }, {
    displayName: '尾小',
  }
];

const twdx = [
  {
    displayName: '特尾大',
  }, {
    displayName: '特尾小',
  }
];

const totalSumDS = [
  {
    displayName: '总和单',
  }, {
    displayName: '总和双',
  }
];

const totalSumDX = [
  {
    displayName: '总和大',
  }, {
    displayName: '总和小',
  }
];

const totalSumtailDX = [
  {
    displayName: '总和尾大',
  }, {
    displayName: '总和尾小',
  }
];

const totaltailDX = [
  {
    displayName: '总尾大',
  }, {
    displayName: '总尾小',
  }
];

const totalds = [
  {
    displayName: '总单',
  }, {
    displayName: '总双',
  }
];

const totaldx = [
  {
    displayName: '总大',
  }, {
    displayName: '总小',
  }
];
const specialBig = [
  {
    displayName: '特大单',
  }, {
    displayName: '特大双',
  }
];
const specialdSmall = [
  {
    displayName: '特小单',
  }, {
    displayName: '特小双',
  }
];

const totalScoreDx = [
  {
    displayName: '总分大',
  }, {
    displayName: '总分小',
  }
];
const totalScoreDs = [
  {
    displayName: '总分单',
  }, {
    displayName: '总分双',
  }
];

const tdxds = [
  {
    displayName: '特单',
  }, {
    displayName: '特双',
  }, {
    displayName: '特大',
  }, {
    displayName: '特小',
  }
];

const thdxds = [
  {
    displayName: '特合单',
  }, {
    displayName: '特合双',
  }, {
    displayName: '特合大',
  }, {
    displayName: '特合小',
  }
];

const totalZodiacDS = [
  {
    displayName: '总肖单',
  }, {
    displayName: '总肖双',
  }
];

const championDx = [
  {
    displayName: '冠亚大',
  }, {
    displayName: '冠亚小',
  }
];

const championDs = [
  {
    displayName: '冠亚单',
  }, {
    displayName: '冠亚双',
  }
];

const bigsmalltail = [
  {
    displayName: '大尾',
  }, {
    displayName: '小尾',
  }
];

const totalHdxds = [
  {
    displayName: '总和大单',
  }, {
    displayName: '总和大双',
  }, {
    displayName: '总和小单',
  }, {
    displayName: '总和小双',
  }
];
const extraDs = [
  {
    displayName: '单(多)',
  }, {
    displayName: '双(多)',
  }, {
    displayName: '单双(和)',
  }
];

const extremeDx = [
  {
    displayName: '极大',
  }, {
    displayName: '极小',
  }
];
const bigDs = [
  {
    displayName: '大单',
  }, {
    displayName: '大双',
  }
];

const smallDs = [
  {
    displayName: '小单',
  }, {
    displayName: '小双',
  }
];

const cowDsdx = [
  {
    displayName: '牛单',
  }, {
    displayName: '牛双',
  }, {
    displayName: '牛大',
  }, {
    displayName: '牛小',
  }
];

export {
  dxds,
  hdxds,
  wdx,
  tdxds,
  thdxds,
  twdx,
  totalds,
  totaldx,
  specialBig,
  specialdSmall,
  totalSumDX,
  totalSumDS,
  totalZodiacDS,
  championDx,
  championDs,
  totaltailDX,
  bigsmalltail,
  totalScoreDx,
  totalScoreDs,
  totalHdxds,
  extraDs,
  totalSumtailDX,
  extremeDx,
  bigDs,
  smallDs,
  cowDsdx,
};