import { SET_PANKOU_DATA } from '../../actions/actionTypes';

const initialState = {
  pankouData: undefined,
};

const oddsManageReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_PANKOU_DATA:
      return {
        ...state, pankouData: action.value
      };
    default:
      return state;
  }
};

export default oddsManageReducers;