import { useEffect, useState } from 'react';

import { Checkbox, Input, InputNumber, Radio, Select } from 'antd';
import { Label } from 'reactstrap';
import { connect } from 'react-redux';

import { agentActions, appActions, commonActions, companyActions } from '../../../../redux/actions';
import { SectionToChinese } from '../../../../constants/utils';
import './styles.scss';

const mapStateToProps = state => ({
  oddsDiffUpOrgsData: state.companyReducers.oddsDiffUpOrgsData,
  isValidLoginIdMsg: state.agentReducers.isValidLoginIdMsg,
  isValidNameMsg: state.agentReducers.isValidNameMsg,
  userData: state.memberReducers.userData,
  userInfo: state.memberReducers.userInfo,
  downlineAgentList: state.commonReducers.downlineAgentList, // 上一个父组件有了，所以当前组件不需要再请求了，直接用
  accountType: state.commonReducers.accountType,
});

const mapDispatchToProps = dispatch => ({
  GetAddAgentInfo: (id, callback) => dispatch(agentActions.GetAddAgentInfo(id, callback)),
  SaveAgentInfo: (obj, callback) => dispatch(agentActions.SaveAgentInfo(obj, callback)),
  getOddsDiffUpOrgs: (orgId, orgLevel) => dispatch(companyActions.getOddsDiffUpOrgs(orgId, orgLevel)),
  isValidName: (userId, callback) => dispatch(agentActions.isValidName(userId, callback)),
  isExistLoginId: (userId, callback) => dispatch(agentActions.isExistLoginId(userId, callback)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  resetReturnMsgData: () => dispatch(agentActions.resetReturnMsgData()),
  resetSubAccountInfo: () => dispatch(agentActions.resetSubAccountInfo()),
  GetAccountType: () => dispatch(commonActions.GetAccountType()),
});

const AgentAdd = ({ GetAccountType, accountType, oddsDiffUpOrgsData, getOddsDiffUpOrgs, data, callback = () => { }, SaveAgentInfo, resetReturnMsgData, resetSubAccountInfo, isExistLoginId, isValidName, isValidNameMsg, updateModalAlert, GetAddAgentInfo, isValidLoginIdMsg, downlineAgentList, userData, userInfo, onCancel, }) => {
  const [_loginIdReturnMsg, setLoginIdReturnMsg] = useState({});
  const [thirdPartyZC, setThirdPartyZC] = useState([]); // 第三方占城
  const [orgZC, setOrgZC] = useState([]); // 当前占城
  const [lotteryMenu, setLotteryMenu] = useState([]); // 彩种管理
  const [_passwordMsg, setPasswordMsg] = useState({});
  const [_nameReturnMsg, setNameReturnMsg] = useState({});

  const [info, setInfo] = useState({
    accountStatus: 1,
    loginId: '',
    password: '',
    name: '',
    limitType: 1,
    lowValue: 0,
    highValue: 0,
    thirdPartyValue: 0,
    zcType: 0,
    rateSurplus: 1,
    quickReturn: 0,
    multiOdd: 1,
    orgCtrlSetting: 0,
    orgOddsSetting: 0,
    outSideEnable: 1,
    replenishmentType: 3, // 补货方式
    replenishmentSurplus: 1, // 补货剩余
    lotteryAssigned: 1,
    copyOddsDiffOrgId: '-1',
    accountInfo: []
  });

  useEffect(() => {
    GetAccountType();
    if ((JSON.stringify(data) === '{}' && userInfo?.levelValue < 3) || (data?.level < 3)) {
      const orgId = data?.loginId ? data.orgId : userInfo.orgId;
      getOddsDiffUpOrgs(orgId, 1);
    }
  }, []);
  const currentInfo = {
    ...data,
    isChild: data?.loginId ? data.isChild : userData.isChild,
    orgId: data?.loginId ? data.orgId : userInfo.orgId,
    level: data?.loginId ? data.level : userInfo.levelValue,
    levelName: data?.loginId ? data.levelName : userInfo.levelName,
    loginId: data?.loginId ? data.loginId : userData.loginId,
  };

  const [res, setRes] = useState({});

  const handleNameOnBlur = text => {
    setInfo({
      ...info,
      name: text
    });
    if (text?.length > 1) {
      isValidName(text);
    } else {
      setNameReturnMsg({
        state: 99,
        msg: '名称只能2-12位由数字、英文字母,中文组成'
      });
    }
  };

  const handleLoginIdOnBlur = text => {
    setInfo({
      ...info,
      loginId: text
    });
    if (text?.length > 3) {
      isExistLoginId(text);
    } else {
      setLoginIdReturnMsg({
        state: 99,
        msg: '帐号必须由字母数字组成, 4-12位'
      });
    }
  };

  const handlePasswordChange = text => {
    setInfo({
      ...info,
      password: text
    });
    if (text?.length > 5 && text?.length < 21 && text.match(/^[0-9a-zA-Z]+$/)) {
      if (/[0-9]/.test(text) && /[a-z]/.test(text) && /[A-Z]/.test(text)) {
        setPasswordMsg({
          state: 1,
          msg: '密码可用'
        });
      } else {
        setPasswordMsg({
          state: 99,
          msg: '密码必须由字母数字组成（区分大小写）！'
        });
      }
    } else {
      setPasswordMsg({
        state: 99,
        msg: '密码必须由字母数字组成，6-20位（区分大小写）！'
      });
    }
  };

  useEffect(() => {
    setNameReturnMsg(isValidNameMsg);
  }, [isValidNameMsg]);

  useEffect(() => {
    setLoginIdReturnMsg(isValidLoginIdMsg);
  }, [isValidLoginIdMsg]);

  const leftLotteryMenu = lotteryMenu.reduce((acc, item) => {
    const exist = acc.find(i => i.categoryCode === item.categoryCode);
    if (!exist) {
      acc.push({
        categoryName: item.categoryName,
        categoryCode: item.categoryCode,
      });
    }
    return acc;
  }, []).sort((a, b) => ((a.orderBy > b.orderBy) ? -1 : 1));

  useEffect(() => {
    resetSubAccountInfo();
    resetReturnMsgData();
    GetAddAgentInfo(
      {
        isChild: currentInfo.isChild,
        parentId: currentInfo.orgId,
      },
      res => {
        const _lotteryMenu = (res?.lotteryList || []).map(item => ({
          ...item, checked: false,
        }));
        const accountInfo = [];
        accountType.forEach(item => {
          const acc = res?.upOrg?.accountInfo.find(x => x.catagoryId === item.accountType);
          let { accName } = item;
          if (res?.info?.accountOption === 0 && item.accountType === 2) {
            accName = '额度';
          }
          if (acc) {
            accountInfo.push({
              ...acc, accName, moneyLimit: 0
            });
          }
        });

        setInfo({
          ...info, limitType: res?.upOrg?.limitType, accountInfo, zcType: res?.upOrg?.zcType ?? 0

        });
        setRes(res);
        const initialPklist = (res?.orgParent?.levelValue <= res?.info?.companyLevel ? 'ABCD' : res?.orgParent?.gameType ?? '').split('') || [];
        setPkList(initialPklist);
        setLotteryMenu(_lotteryMenu);
        setThirdPartyZC(res.thirdPartyGamesZc || []);
        setOrgZC((res?.orgZc ? res.orgZc.map(item => {
          const temp = {
            ...item, avairableRateForDown: item.availableRateForDown
          };
          delete temp.availableRateForDown;
          return temp;
        }) : []).map(item => ({
          ...item, replenishment: item?.replenishment ?? 1
        })));
      }
    );
  }, []);

  const downlineAgent = downlineAgentList.find(i => Number(i.level) === currentInfo.level + 1) || {};

  let nextAgentName = '';
  if (downlineAgent?.fullName) {
    if (downlineAgent?.fullName.indexOf('代理') !== -1) {
      nextAgentName = '代理';
    } else {
      nextAgentName = downlineAgent?.fullName;
    }
  }

  const [pkList, setPkList] = useState(['A']);

  const handleSave = () => {
    updateModalAlert({
      type: 'confirmation',
      visible: true,
      data: {
        message: '请确认是否要设置',
        onConfirmationClick: () => changData()
      }
    });
    const changData = () => {
      const lotteryValues = lotteryMenu.filter(checkbox => checkbox.checked).map(checkbox => checkbox.id);
      if (_loginIdReturnMsg?.state === 1 && _nameReturnMsg?.state === 1 && _passwordMsg?.state === 1 && (res?.orgParent?.lotteryAssigned === 1 ? lotteryValues?.length > 0 : true)) {
        updateModalAlert({
          type: 'loading',
          visible: true
        });
        const obj = {
          isUpdate: false,
          // userInfo,
          org: {
            ...info,
            copyOrgOddsDiff: info.copyOddsDiffOrgId,
            levelValue: currentInfo.level + 1,
            isChild: currentInfo.isChild,
            organizationId: currentInfo.orgId,
            parentId: currentInfo.orgId,
            gameType: pkList.join(''),
            useState: info.accountStatus, // 用户状态
          },
          sharesList: orgZC,
          // 第三方占成
          thirdPartySharesList: thirdPartyZC.map(item => ({
            gameCode: item.gameCode,
            zc: item.zc
          })),
          // 彩种
          lotteryType: lotteryValues || [],
          accountInfo: info?.accountInfo?.map(x => ({
            ...x, moneyLimit: x?.moneyLimit ?? 0
          })),
        };
        SaveAgentInfo(obj, callback);
      } else {
        updateModalAlert({
          visible: true,
          type: 'confirmation',
          data: {
            message: _loginIdReturnMsg?.state !== 1 ? '帐号必须由字母数字组成，4-12位' : _passwordMsg?.state !== 1 ? '密码必须由字母数字组成（大写+小写+数字），6-20位（区分大小写）' : _nameReturnMsg?.state !== 1 ? '名称只能2-12位由数字、英文字母,中文组成' : '请至少选择一个彩！',
            showCancelButton: false
          }
        });
      }
    };
  };

  const pkOptions = (
    res?.orgParent?.levelValue <= res?.info?.companyLevel
      ? ('ABCD').split('').map(item => ({
        label: item, value: item
      }))
      : (res?.orgParent?.gameType || '').split('').map(item => ({
        label: item, value: item
      }))
  );

  return (
    <div className="AgentAddWrapper">
      <div className="sectionWrapper">
        <div className="tableTitleStyle">账户资料</div>
        <div className="d-flex">
          <div className="tableSection labelCellBackground">
            <div className="rowStyle">
              <div>
                所属
                {currentInfo.levelName}
              </div>
              <div>{currentInfo?.isChild === 1 ? userInfo?.loginId : currentInfo?.loginId}</div>
            </div>
            <div className="rowStyle">
              <div>
                {nextAgentName}
                账号
              </div>
              <div>
                <Input
                  // disabled={!data}
                  value={info?.loginId}
                  onChange={e => setInfo({
                    ...info,
                    loginId: e.target.value
                  })}
                  onBlur={({ target: { value: text } }) => handleLoginIdOnBlur(text.trim())}
                />
                <Label className={`d-inline mx-2 ${_loginIdReturnMsg?.state === 1 ? 'text-success' : 'text-danger'}`}>{_loginIdReturnMsg?.msg}</Label>
              </div>
            </div>
            <div className="rowStyle">
              <div>帐号状态</div>
              <div>
                <Radio.Group
                  value={info?.accountStatus}
                  onChange={e => setInfo({
                    ...info,
                    accountStatus: e.target.value
                  })}
                >
                  <Radio value={1}>启用</Radio>
                  <Radio value={2}>暂停</Radio>
                  <Radio value={0}>停用</Radio>
                </Radio.Group>
              </div>
            </div>
            {/* 有权限控制 */}
            <div className="rowStyle">
              <div>登入密码</div>
              <div>
                <Input
                  value={info?.password}
                  onChange={e => handlePasswordChange(e.target.value.trim())}
                />
                <Label className={`d-inline mx-2 ${_passwordMsg?.state === 1 ? 'text-success' : 'text-danger'}`}>{_passwordMsg?.msg}</Label>
              </div>
            </div>
            <div className="rowStyle">
              <div>
                {nextAgentName}
                名称
              </div>
              <div>
                <Input
                  value={info?.name}
                  onChange={e => setInfo({
                    ...info,
                    name: e.target.value
                  })}
                  onBlur={({ target: { value: text } }) => handleNameOnBlur(text.trim())}
                />
                <Label className={`d-inline mx-2 ${_nameReturnMsg?.state === 1 ? 'text-success' : 'text-danger'}`}>{_nameReturnMsg?.msg}</Label>
              </div>
            </div>
            <div className="rowStyle">
              <div>额度模式</div>
              {
                res?.orgParent?.levelValue <= res?.info?.companyLevel
                  ? (
                    <div>
                      <Radio
                        checked={info.limitType === 1}
                        onChange={() => setInfo({
                          ...info,
                          limitType: 1
                        })}
                      >
                        信用模式
                      </Radio>
                      <Radio
                        checked={info.limitType === 2}
                        onChange={() => setInfo({
                          ...info,
                          limitType: 2
                        })}
                      >
                        现金模式
                      </Radio>
                    </div>
                  )
                  : (
                    <div>
                      <Radio
                        checked
                      >
                        {res?.upOrg?.limitType === 1 ? '信用模式' : '现金模式'}
                      </Radio>
                    </div>
                  )
              }
            </div>

            {
              info && info?.accountInfo && info?.accountInfo?.map(item => (
                <div className="rowStyle">
                  <div>{item?.accName}</div>
                  <div>
                    <InputNumber
                      className="w-select-value"
                      type="number"
                      value={item?.moneyLimit ?? 0}
                      onChange={value => setInfo({
                        ...info,
                        accountInfo: [].concat(info.accountInfo.map(x => ({
                          ...x, moneyLimit: x.catagoryId === item?.catagoryId ? Number(String(value).replace(/\D/g, '')) : x.moneyLimit
                        })))
                      })}
                    />
                    <span className="mx-1">
                      <span className="text-danger mx-1">{SectionToChinese(item?.moneyLimit ?? 0)}</span>
                      (上级余额：
                      {(item || {})?.balance?.toFixed(2) ?? ''}
                      )
                    </span>
                  </div>
                </div>
              ))
            }

            <div className="rowStyle">
              <div>开放盘口</div>
              <div>
                <Checkbox.Group
                  options={pkOptions}
                  value={pkList}
                  onChange={values => setPkList(values)}
                />
              </div>
            </div>
            {
              currentInfo.level === 2 && (
                <>
                  <div className="rowStyle">
                    <div>占成模式</div>
                    <div>
                      <Radio
                        checked={info.zcType === 0}
                        onChange={() => setInfo({
                          ...info,
                          zcType: 0
                        })}
                      >
                        浮动占成
                      </Radio>
                      <Radio
                        checked={info.zcType === 2}
                        onChange={() => setInfo({
                          ...info,
                          zcType: 2
                        })}
                      >
                        浮动占成II
                      </Radio>
                      <Radio
                        checked={info.zcType === 1}
                        onChange={() => setInfo({
                          ...info,
                          zcType: 1
                        })}
                      >
                        固定占成
                      </Radio>
                    </div>
                  </div>

                  <div className="rowStyle">
                    <div>退水赔率差复制</div>
                    <div>
                      <Select
                        className="w-select-value"
                        value={info.copyOddsDiffOrgId}
                        onChange={v => setInfo({
                          ...info,
                          copyOddsDiffOrgId: v
                        })}
                        options={
                          [{
                            label: '系统默认',
                            value: '-1'
                          }].concat(
                            (oddsDiffUpOrgsData || []).map(item => ({
                              label: `从[${item.loginId || ''}]复制`,
                              value: item.organizationId
                            }))
                          )
                        }
                      />
                    </div>
                  </div>

                  <div className="rowStyle">
                    <div>占成剩余</div>
                    <div>
                      <Radio
                        disabled={info.zcType !== 1}
                        checked={info.rateSurplus === 1}
                        onChange={() => setInfo({
                          ...info,
                          rateSurplus: 1
                        })}
                      >
                        归
                        {currentInfo.levelName}
                      </Radio>
                      <Radio
                        disabled={info.zcType !== 1}
                        checked={info.rateSurplus === 2}
                        onChange={() => setInfo({
                          ...info,
                          rateSurplus: 2
                        })}
                      >
                        归
                        {nextAgentName}
                      </Radio>
                    </div>
                  </div>
                  <div className="rowStyle">
                    <div>补货方式</div>
                    <div>
                      <Radio
                        disabled={info.zcType !== 1}
                        checked={info.replenishmentType === 1}
                        onChange={() => setInfo({
                          ...info,
                          replenishmentType: 1
                        })}
                      >
                        全归
                        {currentInfo.levelName}
                      </Radio>
                      <Radio
                        disabled={info.zcType !== 1}
                        checked={info.replenishmentType === 2}
                        onChange={() => setInfo({
                          ...info,
                          replenishmentType: 2
                        })}
                      >
                        全归
                        {nextAgentName}
                      </Radio>
                      <Radio
                        disabled={info.zcType !== 1}
                        checked={info.replenishmentType === 3}
                        onChange={() => setInfo({
                          ...info,
                          replenishmentType: 3
                        })}
                      >
                        按各级占成
                      </Radio>
                    </div>
                  </div>
                  <div className="rowStyle">
                    <div>补货剩余</div>
                    <div>
                      <Radio
                        disabled={info.zcType !== 1}
                        checked={info.replenishmentSurplus === 1}
                        onChange={() => setInfo({
                          ...info,
                          replenishmentSurplus: 1
                        })}
                      >
                        归
                        {currentInfo.levelName}
                      </Radio>
                      <Radio
                        disabled={info.zcType !== 1}
                        checked={info.replenishmentSurplus === 2}
                        onChange={() => setInfo({
                          ...info,
                          replenishmentSurplus: 2
                        })}
                      >
                        归
                        {nextAgentName}
                      </Radio>
                    </div>
                  </div>
                </>
              )
            }

            {
              currentInfo.level <= 2 && (
                <div className="rowStyle">
                  <div>允许多赔率</div>
                  <div>
                    <Radio
                      checked={info.multiOdd === 1}
                      onChange={() => setInfo({
                        ...info,
                        multiOdd: 1
                      })}
                    >
                      允许
                    </Radio>
                    <Radio
                      checked={info.multiOdd === 0}
                      onChange={() => setInfo({
                        ...info,
                        multiOdd: 0
                      })}
                    >
                      不允许
                    </Radio>
                  </div>
                </div>
              )
            }

            <div className="rowStyle">
              <div>赚取退水</div>
              <div>
                <Select
                  className="w-select"
                  onChange={val => setInfo({
                    ...info,
                    quickReturn: val
                  })}
                  value={info.quickReturn}
                  options={[
                    {
                      label: '水全退到底', value: 0
                    },
                    {
                      label: '赚取0.1%', value: 0.1
                    },
                    {
                      label: '赚取0.3%', value: 0.3
                    },
                    {
                      label: '赚取0.5%', value: 0.5
                    },
                    {
                      label: '赚取1.0%', value: 1.0
                    },
                    {
                      label: '赚取1.5%', value: 1.5
                    },
                    {
                      label: '赚取2.0%', value: 2.0
                    },
                    {
                      label: '赚取2.5%', value: 2.5
                    },
                    {
                      label: '赚取所有退水', value: 100
                    },
                  ]}
                />
              </div>
            </div>
            <div className="rowStyle">
              <div>MFA验证</div>
              <div>
                {
                  info?.editOrg?.validType === 1 ? (
                    <div className="operationBtn reset">
                      重置
                    </div>
                  ) : '未设置'
                }
              </div>
            </div>

            {
              (res?.upOrg?.levelValue + 1) === res?.info?.zdLevel && res?.info?.earnOddsSett === 1 && (
                <div className="rowStyle">
                  <div>操盘设置</div>
                  <div>
                    <Radio
                      checked={info?.orgCtrlSetting === 0}
                      onChange={() => setInfo({
                        ...info,
                        orgCtrlSetting: 0
                      })}
                    >
                      禁用
                    </Radio>
                    <Radio
                      checked={info?.orgCtrlSetting === 1}
                      onChange={() => setInfo({
                        ...info,
                        orgCtrlSetting: 1
                      })}
                    >
                      启用
                    </Radio>
                  </div>
                </div>
              )
            }

            {
              res?.upOrg?.orgOddsSetting === 1 && res?.info?.earnOddsSett === 1 && (res?.upOrg?.levelValue + 1) >= res?.info?.zdLevel && (
                <div className="rowStyle">
                  <div>赚赔设置</div>
                  <div>
                    <Radio
                      checked={info?.orgOddsSetting === 0}
                      onChange={() => setInfo({
                        ...info,
                        orgOddsSetting: 0
                      })}
                    >
                      禁用
                    </Radio>
                    <Radio
                      checked={info?.orgOddsSetting === 1}
                      onChange={() => setInfo({
                        ...info,
                        orgOddsSetting: 1
                      })}
                    >
                      启用
                    </Radio>
                  </div>
                </div>
              )
            }

            {
              res && res?.info?.outsiteEnable === 1 && (res?.orgParent?.levelValue + 1 === res?.info?.zdLevel) && (
                <div className="rowStyle">
                  <div>第三方游戏</div>
                  <div>
                    <Radio
                      checked={info.outSideEnable === 0}
                      onChange={() => setInfo({
                        ...info,
                        outSideEnable: 0
                      })}
                    >
                      禁用
                    </Radio>
                    <Radio
                      checked={info.outSideEnable === 1}
                      onChange={() => setInfo({
                        ...info,
                        outSideEnable: 1
                      })}
                    >
                      启用
                    </Radio>
                  </div>
                </div>
              )
            }

          </div>
        </div>
      </div>
      {
      res?.orgParent?.lotteryAssigned === 1
            && (
            <div className="sectionWrapper">
              <div className="tableTitleStyle">彩种选择</div>
              <div className="tableSection labelCellBackground">
                <div className="rowStyle">
                  <div>开放彩种管理给下线</div>
                  <div>
                    <Radio
                      checked={info.lotteryAssigned === 1}
                      onChange={() => {
                        setInfo({
                          ...info,
                          lotteryAssigned: 1
                        });
                      }}
                    >
                      允许
                    </Radio>
                    <Radio
                      checked={info.lotteryAssigned === 0}
                      onChange={() => {
                        setInfo({
                          ...info,
                          lotteryAssigned: 0
                        });
                      }}
                    >
                      不允许
                    </Radio>
                  </div>
                </div>
                {
                  leftLotteryMenu?.map(item => {
                    const _menus = lotteryMenu.filter(x => x.categoryCode === item.categoryCode);
                    const checked = _menus.every(item => item.checked);
                    const { categoryName } = item || {};
                    return (
                      <div className="rowStyle">
                        <div>
                          <span className="mx-2">{categoryName}</span>
                          <Checkbox
                            checked={checked}
                            className="w-all-checkbox"
                            onChange={e => {
                              const { checked } = e.target;
                              setLotteryMenu(
                                lotteryMenu.map(data => {
                                  if (item.categoryCode === data.categoryCode) {
                                    return {
                                      ...data, checked,
                                    };
                                  }
                                  return data;
                                })
                              );
                            }}
                          >
                            所有
                          </Checkbox>
                        </div>
                        <div>
                          {
                            _menus.map(d => (
                              <p key={d.code} className="d-inline border-0 m-1">
                                <Checkbox
                                  className="w-single-checkbox"
                                  checked={d.checked}
                                  onChange={e => {
                                    const index = lotteryMenu.findIndex(i => i.id === d.id);
                                    const item = lotteryMenu[index];
                                    setLotteryMenu([
                                      ...lotteryMenu.slice(0, index),
                                      {
                                        ...item,
                                        checked: e.target.checked
                                      },
                                      ...lotteryMenu.slice(index + 1),
                                    ]);
                                  }}
                                >
                                  {d.lotteryName}
                                </Checkbox>
                              </p>
                            ))
                          }
                        </div>
                      </div>
                    );
                  })
                }
              </div>
            </div>
            )
      }

      <div className="sectionWrapper">
        <div className="tableTitleStyle">
          占成设置 -
          {info.zcType === 0 && (
            <span className="mx-2">
              默认模式
            </span>
          )}
          {info.zcType === 2 && (
            <span className="mx-2">
              浮动占成II
            </span>
          )}
          {info.zcType === 1 && (
            <span className="mx-2">
              固定模式(上级公司占成与本级总代可占成数之和不能超过上级可用占成)
            </span>
          )}
        </div>
        <div className="tableSection">
          <div className="rowStyle bg-grey zhanChengHeader ">
            <div className="bg-grey w10 d-flex justify-content-center align-items-center">类型</div>
            <div className="w30">
              <div className={`w-100 d-flex justify-content-center align-items-center ${info.zcType === 2 ? 'h-50 borderBottom' : 'h-100'} `}>
                {`本级${currentInfo.levelName}${info.zcType === 0 ? '最高' : ''}占成`}
              </div>
              {info.zcType === 2
              && (
              <>
                <div className="w50 d-flex justify-content-center align-items-center h-50">最低占成 (%)</div>
                <div className="w50 borderLeft d-flex justify-content-center align-items-center h-50">最高成数(%)</div>
              </>
              )}
            </div>
            <div className="w30">
              <div className="w-100 d-flex justify-content-center align-items-center h-50 borderBottom">
                下级
                {nextAgentName}
                占成
              </div>
              <div className="w50 d-flex justify-content-center align-items-center h-50">最低占成 (%)</div>
              <div className="w50 borderLeft d-flex justify-content-center align-items-center h-50">最高成数(%)</div>
            </div>
            <div className="flex-1 d-flex justify-content-center align-items-center">
              <div className="rowHeader d-flex justify-content-center align-items-center">允许补货</div>
            </div>
          </div>
          {
            orgZC.filter(item => item.hide === false).map(item => {
              const { categoryName, replenishment = 1, rateForUp, minZc, rate4UpMin, hasBet, upReplenishment, buhuoIndependence } = item || {};
              return (
                <div className="rowStyle">
                  <div className="w10 d-flex justify-content-center align-items-center">
                    {
                      hasBet && (<span className="text-red">【预设】</span>)
                    }
                    {
                      categoryName
                    }
                  </div>
                  <div className="w30 borderRight d-flex justify-content-center align-items-center">
                    {info.zcType === 2
                    && (
                    <div className="rowHeader w50 d-flex justify-content-center align-items-center">
                      <InputNumber
                        min={0}
                        value={rate4UpMin}
                        onChange={v => {
                          const temp = orgZC.map(data => (data.categoryCode === item.categoryCode ? {
                            ...data, rate4UpMin: v,
                          } : {
                            ...data
                          }));
                          setOrgZC(temp);
                        }}
                      />
                      <span className="mx-1">
                        {`${item.upMinZc || 0}%至${item.upAvailableRateForDown || 0}%`}
                      </span>
                    </div>
                    )}

                    <div className={`rowHeader borderLeft ${info.zcType === 2 ? 'w50' : 'w-100'} d-flex justify-content-center align-items-center`}>
                      <InputNumber
                        min={0}
                        value={rateForUp}
                        onChange={v => {
                          const temp = orgZC.map(data => (data.categoryCode === item.categoryCode ? {
                            ...data, rateForUp: v,
                          } : {
                            ...data
                          }));
                          setOrgZC(temp);
                        }}
                      />
                      <span className="mx-1">
                        {`${item.upMinZc || 0}%至${item.upAvailableRateForDown || 0}%`}
                      </span>
                    </div>

                  </div>
                  <div className="w30 borderRight d-flex justify-content-center align-items-center">
                    <div className="rowHeader borderRight w50 d-flex justify-content-center align-items-center">
                      <InputNumber
                        min={0}
                        value={minZc || 0}
                        onChange={v => {
                          const temp = orgZC.map(data => (data.categoryCode === item.categoryCode ? {
                            ...data, minZc: v,
                          } : {
                            ...data
                          }));
                          setOrgZC(temp);
                        }}
                      />
                    </div>
                    <div className="rowHeader borderRight w50 d-flex justify-content-center align-items-center">
                      <InputNumber
                        min={0}
                        value={item.avairableRateForDown || 0}
                        onChange={v => {
                          const temp = orgZC.map(data => (data.categoryCode === item.categoryCode ? {
                            ...data, avairableRateForDown: v,
                          } : {
                            ...data
                          }));
                          setOrgZC(temp);
                        }}
                      />
                      <span className="mx-1">
                        {`(最大 ${item.upAvailableRateForDown || 0}%)`}
                      </span>
                    </div>

                  </div>
                  <div className="flex-1 d-flex justify-content-center align-items-center">
                    <Radio
                      disabled={buhuoIndependence === 0 && upReplenishment === 0}
                      checked={replenishment === 1}
                      onChange={() => {
                        const temp = orgZC.map(data => (data.categoryCode === item.categoryCode ? {
                          ...data, replenishment: 1,
                        } : {
                          ...data
                        }));
                        setOrgZC(temp);
                      }}
                    >
                      允许
                    </Radio>
                    <Radio
                      disabled={buhuoIndependence === 0 && upReplenishment === 0}
                      checked={replenishment === 0}
                      onChange={() => {
                        const temp = orgZC.map(data => (data.categoryCode === item.categoryCode ? {
                          ...data, replenishment: 0,
                        } : {
                          ...data
                        }));
                        setOrgZC(temp);
                      }}
                    >
                      禁止
                    </Radio>
                  </div>
                </div>
              );
            })
          }
        </div>
      </div>
      {res && res?.info?.outsiteEnable === 1 && thirdPartyZC && thirdPartyZC?.length > 0
      && (
      <div className="sectionWrapper">
        <div className="tableTitleStyle">占成设置</div>
        <div className="tableSection">
          <div className="rowStyle bg-grey">
            <div className="d-flex justify-content-center align-items-center">类型</div>
            <div className="d-flex justify-content-center align-items-center">占成</div>
          </div>
          {
             thirdPartyZC?.filter(x => x?.hide === false).map(item => {
               const { gameName, zc, upZc } = item || {};
               return (
                 <div className="rowStyle">
                   <div className="d-flex justify-content-center align-items-center">{gameName}</div>
                   <div className="d-flex justify-content-center align-items-center">
                     <InputNumber
                       min={0}
                       value={zc}
                       onChange={v => {
                         const temp = thirdPartyZC.map(data => (data.gameCode === item.gameCode ? {
                           ...data, zc: v,
                         } : {
                           ...data
                         }));
                         setThirdPartyZC(temp);
                       }}
                     />
                     <span className="mx-1">
                       {`（最大${upZc}%）`}
                     </span>
                   </div>
                 </div>
               );
             })
          }
        </div>
      </div>
      )}

      <div className="d-flex justify-content-center py-3">
        <div className="operationBtn" onClick={() => handleSave()}>保存</div>
        <div className="operationBtn ms-3 cancel" onClick={() => onCancel?.()}>取消</div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AgentAdd);