import FANT_ZONGXIANG from './FANT_ZONGXIANG';
import { renderResultDateCell } from '../../utils';


const renderQiu = data => {
  if (data && !data.openResult) return;
  const result = data.openResult.split(',');
  return result.map(item => (
    {
      value: item, className: 'circle circleBlue'
    }
  ));
};

const renderSum = data => {
  if (data && !data.openResult) return;
  const result = data.openResult.split(',');
  return result.slice(0, 3).reduce((a, b) => parseInt(a, 10) + parseInt(b, 10), 0);
};

const renderRemainder = data => {
  if (data && !data.openResult) return;
  const result = data.openResult.split(',');
  const remainder = result.slice(0, 3).reduce((a, b) => parseInt(a, 10) + parseInt(b, 10), 0) % 4;
  return remainder === 0 ? 0 : remainder;
};

const renderFan = data => {
  if (data && !data.openResult) return;
  const result = data.openResult.split(',');
  const sum = result.slice(0, 3).reduce((a, b) => parseInt(a, 10) + parseInt(b, 10), 0);
  return sum % 4 === 0 ? 4 : sum % 4;
};

const renderZhong = data => {
  if (data && !data.openResult) return;
  const result = data.openResult.split(',');
  const remainder = result.slice(0, 3).reduce((a, b) => parseInt(a, 10) + parseInt(b, 10), 0) % 4;
  const zhongList = [];
  if ('123'.includes(remainder === 0 ? 4 : remainder)) {
    zhongList.push('123中');
  }
  if ('124'.includes(remainder === 0 ? 4 : remainder)) {
    zhongList.push('124中');
  }
  if ('134'.includes(remainder === 0 ? 4 : remainder)) {
    zhongList.push('134中');
  }
  if ('234'.includes(remainder === 0 ? 4 : remainder)) {
    zhongList.push('234中');
  }
  return zhongList.join(',');
};

const renderDS = data => {
  if (data && !data.openResult) return;
  const result = data.openResult.split(',');
  const tempResult = [];
  const sum = result.slice(0, 3).reduce((a, b) => parseInt(a, 10) + parseInt(b, 10), 0);
  tempResult.push({
    value: (sum % 4) % 2 ? '单' : '双',
    className: `${((sum % 4) % 2) && 'redText'}`
  });
  return tempResult;
};

const renderDX = data => {
  if (data && !data.openResult) return;
  const result = data.openResult.split(',');
  const tempResult = [];
  const sum = result.slice(0, 3).reduce((a, b) => parseInt(a, 10) + parseInt(b, 10), 0);
  tempResult.push({
    value: (sum % 4) % 2 ? '大' : '小',
    className: `${((sum % 4) % 2) && 'redText'}`
  });
  return tempResult;
};


const FANT = {
  children: [
    {
      tabId: 'count_B9CH',
      label: '总项',
      children: FANT_ZONGXIANG.FANT_ZONGXIANG
    },
  ],
  resultTab: {
    headerCell: [{
      attr: '期数',
      wrapperStyle: 'fantanHeader'
    }, {
      attr: '日期',
      wrapperStyle: 'fantanHeader'
    }, {
      attr: '彩球号码',
      wrapperStyle: 'fantanHeader'
    }, {
      attr: '结果',
      wrapperStyle: 'fantanHeader'
    }, {
      attr: '除4余数',
      wrapperStyle: 'fantanHeader'
    }, {
      attr: '番',
      wrapperStyle: 'fantanHeader'
    }, {
      attr: '中',
      wrapperStyle: 'fantanHeader'
    }, {
      attr: '单双',
      wrapperStyle: 'fantanHeader'
    }, {
      attr: '大小',
      wrapperStyle: 'fantanHeader'
    }],
    bodyCell: [{
      attr: 'installments',
      wrapperStyle: 'd-flex justify-content-center align-items-center'
    }, {
      attr: renderResultDateCell,
      wrapperStyle: 'dateCol'
    }, {
      attr: renderQiu,
      wrapperStyle: 'd-flex fantanBall justify-content-center align-items-center'
    }, {
      attr: renderSum,
      wrapperStyle: 'd-flex justify-content-center align-items-center'
    }, {
      attr: renderRemainder,
      wrapperStyle: 'd-flex justify-content-center align-items-center'
    }, {
      attr: renderFan,
      wrapperStyle: 'd-flex justify-content-center align-items-center'
    }, {
      attr: renderZhong,
      wrapperStyle: 'd-flex justify-content-center align-items-center'
    }, {
      attr: renderDS,
      wrapperStyle: 'd-flex justify-content-center align-items-center'
    }, {
      attr: renderDX,
      wrapperStyle: 'd-flex justify-content-center align-items-center'
    }],
  }
};

export default FANT;