import { useEffect, useState } from 'react';

import { Label, Row } from 'reactstrap';
import { connect } from 'react-redux';

import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { AgentDataTable, Pagination } from '../..';
import { appActions, reportActions } from '../../../redux/actions';

import './styles.scss';

const mapStateToProps = state => ({
  activeLottery: state.commonReducers.activeLottery,
  betQueryResult: state.betListReducers.betQueryResult,
  lotteryList: state.commonReducers.lotteryList,
  userData: state.memberReducers.userData,
});

const mapDispatchToProps = dispatch => ({
  getBetQueryResult: (obj, callback) => dispatch(reportActions.WinLostReportDetail(obj, callback)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
});

const WinLostReportDetail = ({ userData, presetObj, getBetQueryResult, updateModalAlert }) => {
  const { t } = useTranslation();
  const [_detailItem, setDetailItem] = useState();
  const [_detailItemSum, setDetailItemSum] = useState();
  const [_isQueryingDetail, setIsQueryingDetail] = useState(false);
  const [_page, setPage] = useState(1);
  const [_rows, setRows] = useState(15);

  const [betQueryResult, setbetQueryResult] = useState();

  const getData = (pageNum, rows) => {
    setIsQueryingDetail(true);
    getBetQueryResult({
      isBuhuo: presetObj?.isBuhuo ?? '',
      userId: presetObj?.userId ?? '',
      memberLoginId: presetObj?.memberLoginId,
      orgId: presetObj?.orgId ?? '',
      level: presetObj?.level ?? 12,
      lotteryType: presetObj?.lotteryType,
      startDate: moment(presetObj.beginDate).unix(),
      endDate: moment(presetObj.endDate).unix(),
      settingCode: presetObj?.settingCode ?? '',
      page: pageNum || _page,
      rows: rows || _rows,
      settlementStatus: presetObj?.settlementStatus,
      installments: '',

    }, data => { setbetQueryResult(data); setIsQueryingDetail(false); });
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (betQueryResult && JSON.stringify(_detailItem) !== JSON.stringify(betQueryResult)) {
      setDetailItem(betQueryResult.data);
      setDetailItemSum(betQueryResult.sum);
    }
  }, [betQueryResult]);

  const onPaginationClick = (pageNum, rows) => {
    setPage(pageNum);
    setRows(rows);
    getData(pageNum, rows);
  };


  return (
    <div className="WinLostReportDetailWrapper">
      <Row className="mx-0 bg-white px-0 border-1">
        <AgentDataTable
          isQuerying={_isQueryingDetail}
          tableHeaderTitle={
            [{
              label: t('ORDER_NUM'),
            }, {
              label: t('BET_NUMBER'),
            }, {
              label: t('BET_TIME'),
            }, {
              label: t('LOTTERY'),
            }, {
              label: t('ACCOUNT_NAME'),
            }, {
              label: t('BET_CONTENT'),
            }, {
              label: t('BET') + t('AMOUNT'),
            }, {
              label: `${t('REBATE')}%/${t('AMOUNT')}`,
            }, {
              label: t('BET_RESULT'),
            }, {
              label: t('THIS_LVL_PERCENTAGE'),
            }, {
              label: t('THIS_LVL_RESULT'),
            }, {
              label: t('REMARK'),
            }, {
              label: t('STATUS'),
            }]
          }
          tableData={_detailItem || []}
          tableBodyRowProperty={
            [
              data => (<Label className="d-flex justify-content-center">{data.ord}</Label>),
              data => (
                <>
                  <Label
                    className="lotteryName url"
                    onClick={() => {
                      updateModalAlert({
                        type: 'betDetails',
                        visible: true,
                        data: {
                          id: data?.id,
                        }
                      });
                    }}
                  >
                    {data.oddNo}
                  </Label>
                  {userData?.levelValue <= 1 && data?.ip ? <Label>{`IP: ${data?.ip}`}</Label> : ''}
                </>
              ),
              data => (
                <>
                  <Label className="px-1">{moment.unix(data.created).format('YYYY-MM-DD HH:mm:ss')}</Label>
                  <Label>{t(`DAY_OF_WEEK_${moment.unix(data.created || 0).weekday()}`)}</Label>
                </>
              ),
              data => (
                <>
                  <Label className="lotteryName">{data.lotteryName}</Label>
                  <Label className="installment">{t('DI')}</Label>
                  <Label className="installment">{data.installments}</Label>
                  <Label className="installment">{t('INSTALLMENTS')}</Label>
                </>
              ),
              data => (
                <>
                  <Label className="lotteryName">{data.orgName}</Label>
                  <Label className="px-1">
                    {data.oddsType}
                  </Label>
                  {t('PLATE')}
                </>
              ),
              data => (
                <>
                  <Label className="content">{`${data.midType}`}</Label>
                  <Label className="content">{`【${data.displayname}】`}</Label>
                  <Label className="mx-1">@</Label>
                  <Label className="odds">{data.odds}</Label>
                </>
              ),
              data => (<Label className="d-flex justify-content-end mx-1">{data.deleteFlag === 0 ? data.betAmount : 0}</Label>),
              data => (
                <Label>{`${data.returnValue || 0}%/${data?.memReturn || 0}`}</Label>
              ),
              data => (<Label className={data.deleteFlag === 1 ? 'positiveValue' : (data.memAmount >= 0 ? 'positiveValue' : 'negativeValue')}>{data.deleteFlag === 1 ? '未结算' : data.memAmount}</Label>),
              data => (<Label>{`${data.zc}%`}</Label>),
              data => (<Label className={data.totalResult >= 0 ? 'positiveValue' : 'negativeValue'}>{data.totalResult}</Label>),
              data => (<Label>{data.remark || ''}</Label>),
              data => (<Label className={data.deleteFlag === 0 ? 'installment' : 'negativeValue'}>{data.deleteFlag === 0 ? t('NORMAL') : t('CANCEL')}</Label>),
            ]
          }
          tableFooter={[[{
            value: `${t('CURRENT_PAGE_TOTAL')}: ${(betQueryResult && betQueryResult?.sum?.thisBetCount) || 0}${t('TIMES')}`,
            colspan: 6,
            className: 'textRight pr-1 total tableSum'
          },
          {
            value: (_detailItemSum && _detailItemSum.thisBetAmt) || 0,
            colspan: 1,
            className: 'textRight tableSum'
          },
          {
            colspan: 1,
            className: 'tableSum'
          },
          {
            value: (_detailItemSum && _detailItemSum.thisMemAmt) || 0,
            colspan: 1,
            className: 'tableSum'
          }, {
            colspan: 1,
            className: 'tableSum'
          },
          {
            value: (_detailItemSum && _detailItemSum.thisResult) || 0,
            colspan: 1,
            className: 'tableSum'
          },
          {
            colspan: 2,
            className: 'tableSum'
          }], [{
            value: `${t('TOTAL_COUNT')}: ${(betQueryResult && betQueryResult?.sum?.totalRecord) || 0}${t('TIMES')}`,
            colspan: 6,
            className: 'textRight pr-1 total tableSum'
          },
          {
            value: (_detailItemSum && _detailItemSum.totalBetAmt) || 0,
            colspan: 1,
            className: 'textRight tableSum'
          },
          {
            colspan: 1,
            className: 'tableSum'
          },
          {
            value: (_detailItemSum && _detailItemSum.totalMemAmt) || 0,
            colspan: 1,
            className: 'tableSum'
          },
          {
            colspan: 1,
            className: 'tableSum'
          },
          {
            value: (_detailItemSum && _detailItemSum.totalResult) || 0,
            colspan: 1,
            className: 'tableSum'
          },
          {
            colspan: 2,
            className: 'tableSum'
          }]]}
        />
      </Row>
      <Row className="justify-center w-full relative">
        <Pagination totalRecord={(_detailItemSum && _detailItemSum?.totalRecord) || 0} onClick={onPaginationClick} isQuery={false} />
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(WinLostReportDetail);