const threeNumberCom = [{
  displayName: '000',
},
{
  displayName: '001',
},
{
  displayName: '002',
},
{
  displayName: '003',
},
{
  displayName: '004',
},
{
  displayName: '005',
},
{
  displayName: '006',
},
{
  displayName: '007',
},
{
  displayName: '008',
},
{
  displayName: '009',
},
{
  displayName: '011',
},
{
  displayName: '012',
},
{
  displayName: '013',
},
{
  displayName: '014',
},
{
  displayName: '015',
},
{
  displayName: '016',
},
{
  displayName: '017',
},
{
  displayName: '018',
},
{
  displayName: '019',
},
{
  displayName: '022',
},
{
  displayName: '023',
},
{
  displayName: '024',
},
{
  displayName: '025',
},
{
  displayName: '026',
},
{
  displayName: '027',
},
{
  displayName: '028',
},
{
  displayName: '029',
},
{
  displayName: '033',
},
{
  displayName: '034',
},
{
  displayName: '035',
},
{
  displayName: '036',
},
{
  displayName: '037',
},
{
  displayName: '038',
},
{
  displayName: '039',
},
{
  displayName: '044',
},
{
  displayName: '045',
},
{
  displayName: '046',
},
{
  displayName: '047',
},
{
  displayName: '048',
},
{
  displayName: '049',
},
{
  displayName: '055',
},
{
  displayName: '056',
},
{
  displayName: '057',
},
{
  displayName: '058',
},
{
  displayName: '059',
},
{
  displayName: '066',
},
{
  displayName: '067',
},
{
  displayName: '068',
},
{
  displayName: '069',
},
{
  displayName: '077',
},
{
  displayName: '078',
},
{
  displayName: '079',
},
{
  displayName: '088',
},
{
  displayName: '089',
},
{
  displayName: '099',
},
{
  displayName: '111',
},
{
  displayName: '112',
},
{
  displayName: '113',
},
{
  displayName: '114',
},
{
  displayName: '115',
},
{
  displayName: '116',
},
{
  displayName: '117',
},
{
  displayName: '118',
},
{
  displayName: '119',
},
{
  displayName: '122',
},
{
  displayName: '123',
},
{
  displayName: '124',
},
{
  displayName: '125',
},
{
  displayName: '126',
},
{
  displayName: '127',
},
{
  displayName: '128',
},
{
  displayName: '129',
},
{
  displayName: '133',
},
{
  displayName: '134',
},
{
  displayName: '135',
},
{
  displayName: '136',
},
{
  displayName: '137',
},
{
  displayName: '138',
},
{
  displayName: '139',
},
{
  displayName: '144',
},
{
  displayName: '145',
},
{
  displayName: '146',
},
{
  displayName: '147',
},
{
  displayName: '148',
},
{
  displayName: '149',
},
{
  displayName: '155',
},
{
  displayName: '156',
},
{
  displayName: '157',
},
{
  displayName: '158',
},
{
  displayName: '159',
},
{
  displayName: '166',
},
{
  displayName: '167',
},
{
  displayName: '168',
},
{
  displayName: '169',
},
{
  displayName: '177',
},
{
  displayName: '178',
},
{
  displayName: '179',
},
{
  displayName: '188',
},
{
  displayName: '189',
},
{
  displayName: '199',
},
{
  displayName: '222',
},
{
  displayName: '223',
},
{
  displayName: '224',
},
{
  displayName: '225',
},
{
  displayName: '226',
},
{
  displayName: '227',
},
{
  displayName: '228',
},
{
  displayName: '229',
},
{
  displayName: '233',
},
{
  displayName: '234',
}, {
  displayName: '235',
},
{
  displayName: '236',
},
{
  displayName: '237',
},
{
  displayName: '238',
},
{
  displayName: '239',
},
{
  displayName: '244',
},
{
  displayName: '245',
},
{
  displayName: '246',
},
{
  displayName: '247',
},
{
  displayName: '248',
},
{
  displayName: '249',
},
{
  displayName: '255',
},
{
  displayName: '256',
},
{
  displayName: '257',
},
{
  displayName: '258',
},
{
  displayName: '259',
},
{
  displayName: '266',
},
{
  displayName: '267',
},
{
  displayName: '268',
},
{
  displayName: '269',
},
{
  displayName: '277',
},
{
  displayName: '278',
},
{
  displayName: '279',
},
{
  displayName: '288',
},
{
  displayName: '289',
},
{
  displayName: '299',
},
{
  displayName: '333',
},
{
  displayName: '334',
},
{
  displayName: '335',
},
{
  displayName: '336',
},
{
  displayName: '337',
},
{
  displayName: '338',
},
{
  displayName: '339',
},
{
  displayName: '344',
},
{
  displayName: '345',
},
{
  displayName: '346',
},
{
  displayName: '347',
},
{
  displayName: '348',
},
{
  displayName: '349',
},
{
  displayName: '355',
},
{
  displayName: '356',
},
{
  displayName: '357',
},
{
  displayName: '358',
},
{
  displayName: '359',
},
{
  displayName: '366',
},
{
  displayName: '367',
},
{
  displayName: '368',
},
{
  displayName: '369',
},
{
  displayName: '377',
},
{
  displayName: '378',
},
{
  displayName: '379',
},
{
  displayName: '388',
},
{
  displayName: '389',
},
{
  displayName: '399',
},
{
  displayName: '444',
}, {
  displayName: '445',
},
{
  displayName: '446',
},
{
  displayName: '447',
},
{
  displayName: '448',
},
{
  displayName: '449',
},
{
  displayName: '455',
},
{
  displayName: '456',
},
{
  displayName: '457',
},
{
  displayName: '458',
},
{
  displayName: '459',
},
{
  displayName: '466',
},
{
  displayName: '467',
},
{
  displayName: '468',
},
{
  displayName: '469',
},
{
  displayName: '477',
},
{
  displayName: '478',
},
{
  displayName: '479',
},
{
  displayName: '488',
},
{
  displayName: '489',
},
{
  displayName: '499',
},
{
  displayName: '555',
},
{
  displayName: '556',
},
{
  displayName: '557',
},
{
  displayName: '558',
},
{
  displayName: '559',
},
{
  displayName: '566',
},
{
  displayName: '567',
},
{
  displayName: '568',
},
{
  displayName: '569',
},
{
  displayName: '577',
},
{
  displayName: '578',
},
{
  displayName: '579',
},
{
  displayName: '588',
},
{
  displayName: '589',
},
{
  displayName: '599',
},
{
  displayName: '666',
},
{
  displayName: '667',
},
{
  displayName: '668',
},
{
  displayName: '669',
},
{
  displayName: '677',
},
{
  displayName: '678',
},
{
  displayName: '679',
},
{
  displayName: '688',
},
{
  displayName: '689',
},
{
  displayName: '699',
},
{
  displayName: '777',
},
{
  displayName: '778',
},
{
  displayName: '779',
},
{
  displayName: '788',
},
{
  displayName: '789',
},
{
  displayName: '799',
},
{
  displayName: '888',
},
{
  displayName: '889',
},
{
  displayName: '899',
},
{
  displayName: '999',
},
];

const twoNumberCom = [{
  displayName: '00',
},
{
  displayName: '01',
},
{
  displayName: '02',
},
{
  displayName: '03',
},
{
  displayName: '04',
},
{
  displayName: '05',
},
{
  displayName: '06',
},
{
  displayName: '07',
},
{
  displayName: '08',
},
{
  displayName: '09',
},
{
  displayName: '11',
},
{
  displayName: '12',
},
{
  displayName: '13',
},
{
  displayName: '14',
},
{
  displayName: '15',
},
{
  displayName: '16',
},
{
  displayName: '17',
},
{
  displayName: '18',
},
{
  displayName: '19',
},
{
  displayName: '22',
},
{
  displayName: '23',
},
{
  displayName: '24',
},
{
  displayName: '25',
},
{
  displayName: '26',
},
{
  displayName: '27',
},
{
  displayName: '28',
},
{
  displayName: '29',
},
{
  displayName: '33',
},
{
  displayName: '34',
},
{
  displayName: '35',
},
{
  displayName: '36',
},
{
  displayName: '37',
},
{
  displayName: '38',
},
{
  displayName: '39',
},
{
  displayName: '44',
},
{
  displayName: '45',
},
{
  displayName: '46',
},
{
  displayName: '47',
},
{
  displayName: '48',
},
{
  displayName: '49',
},
{
  displayName: '55',
},
{
  displayName: '56',
},
{
  displayName: '57',
},
{
  displayName: '58',
},
{
  displayName: '59',
},
{
  displayName: '66',
},
{
  displayName: '67',
},
{
  displayName: '68',
},
{
  displayName: '69',
},
{
  displayName: '77',
},
{
  displayName: '78',
},
{
  displayName: '79',
},
{
  displayName: '88',
},
{
  displayName: '89',
},
{
  displayName: '99',
}];

export {
  threeNumberCom, twoNumberCom
};