import { useEffect, useState } from 'react';

import { FaPlusCircle } from 'react-icons/fa';
import { connect } from 'react-redux';

import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { appActions, followBetActions } from '../../../redux/actions';

import AgentDataTable from '../../AgentDataTable';
import CommonHeader from '../../Content/CommonHeader';
import FollowBetAddEdit from '../FollowBetAddEdit';
import FollowBetLogs from '../FollowBetLogs';
import './styles.scss';

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  GetInfo: callback => dispatch(followBetActions.GetInfo(callback)),
  DeleteConfig: (obj, callback) => dispatch(followBetActions.DeleteConfig(obj, callback)),
});

const FollowBet = ({
  updateModalAlert,
  GetInfo,
  DeleteConfig,
}) => {
  const { t } = useTranslation();
  const [_info, setInfo] = useState([]);
  const [_isQueryingDetail, setIsQueryingDetail] = useState(true);
  const [pageName, setPageName] = useState('FollowBetList');
  const [currentSelected, setCurrentSelected] = useState(); // current edit item

  const getListData = () => {
    setIsQueryingDetail(true);
    const callback = data => {
      setInfo(data?.configList || []);
      setIsQueryingDetail(false);
    };
    GetInfo(callback);
  };

  useEffect(() => {
    if (pageName === 'FollowBetList') {
      getListData();
    }
  }, [pageName]);

  const deleteClick = id => {
    updateModalAlert({
      type: 'confirmation',
      visible: true,
      showCancelButton: true,
      data: {
        message: '确认删除',
        onConfirmationClick: () => DeleteConfig([id], () => getListData())
      },
    });
  };

  const tableHeader = [{
    label: '类型',
  }, {
    label: '跟单比例(%)',
  }, {
    label: '被跟单帐号',
  }, {
    label: '跟单帐号',
  }, {
    label: '彩种',
  }, {
    label: '创建日期',
  }, {
    label: t('FUNCTION'),
  }];

  const tableContent = [
    data => (
      <div className="d-flex justify-content-center">
        <div className="d-flex justify-content-center">
          {data?.memberType === 1 ? '代理' : ' 会员'}
          {data?.followType === 1 ? '反跟单' : ' 正跟单'}
        </div>
      </div>
    ),
    data => (<div className="d-flex justify-content-center text-break">{`${data?.multiple ?? '--'}%`}</div>),
    data => (
      <div className="d-flex justify-content-center text-break">
        <span>{data?.followedLoginId}</span>
        <span className="px-1">{data?.followedSericeName}</span>
        <span>{`公司: ${data?.followedCompanyName}`}</span>
      </div>
    ),
    data => (
      <div className="d-flex justify-content-center text-break">
        <span>{data?.followLoginId}</span>
        <span className="px-1">{data?.followServiceName}</span>
        <span>{`公司: ${data?.followCompanyName}`}</span>
      </div>
    ),
    data => (
      <div className="d-flex justify-content-center text-break">
        {
            data?.lotteryList
              ? data.lotteryList.map(x => x.lotteryType).toString()
              : ''
        }
      </div>
    ),
    data => (
      <div className="d-flex justify-content-center text-break">
        {data?.createDate && moment.unix(data?.createDate).format('YYYY-MM-DD HH:mm:ss')}
      </div>
    ),
    data => (
      <div className="d-flex justify-content-center align-items-center text-break">
        <div className="button find " onClick={() => { setCurrentSelected(data); setPageName('FollowBetAddEdit'); }}>{t('EDIT')}</div>
        <div className="button handle mx-1" onClick={() => { setCurrentSelected(data); setPageName('FollowBetLogs'); }}>跟单日志</div>
        <div className="button green " onClick={() => deleteClick(data.id)}>{t('DELETE')}</div>
      </div>
    ),
  ];

  return (pageName === 'FollowBetList'
    ? (
      <div className="FollowBetWrapper">
        <CommonHeader headerName={t('FOLLOW_BET_SETTING')} />
        <div className="mx-0 bg-white my-2  border-1">
          <div className="p-2 m-0 mb-3 filterWrapper d-flex">
            <div
              className="button add justify-content-center d-flex align-items-center"
              onClick={() => { setCurrentSelected(''); setPageName('FollowBetAddEdit'); }}
            >
              <FaPlusCircle className=" mr-1" />
              <div className="p-0 px-1">{t('ADD')}</div>
            </div>
          </div>
          <div className="bigWrapper m-0 my-1 p-3 d-flex">
            <AgentDataTable
              isQuerying={_isQueryingDetail}
              tableHeaderTitle={tableHeader}
              tableData={_info || (_isQueryingDetail ? [1, 2, 3] : [])}
              tableBodyRowProperty={tableContent}
              tableBodyRowStyle={['col-1', 'col-1', 'col-2', 'col-2', 'col-3', 'col-1', 'col-2']}
            />
          </div>
        </div>
      </div>
    )
    : pageName === 'FollowBetLogs'
      ? <FollowBetLogs data={currentSelected} setPageName={setPageName} />
      : <FollowBetAddEdit data={currentSelected} setPageName={setPageName} />
  );
};

export default connect(null, mapDispatchToProps)(FollowBet);