

const threeColors = [
  {
    displayName: '红波', className: 'hk6_sebo redbo',
  }, {
    displayName: '蓝波', className: 'hk6_sebo bluebo',
  }, {
    displayName: '绿波', className: 'hk6_sebo greenbo',
  },
];

const pureThreeColors = [
  {
    displayName: '红', className: 'hk6_sebo redbo',
  }, {
    displayName: '蓝', className: 'hk6_sebo bluebo',
  }, {
    displayName: '绿', className: 'hk6_sebo greenbo',
  },
];

const redSet = [
  {
    displayName: '红单',
  }, {
    displayName: '红双',
  }, {
    displayName: '红大',
  }, {
    displayName: '红小',
  }, {
    displayName: '红大单',
  }, {
    displayName: '红大双',
  }, {
    displayName: '红小单',
  }, {
    displayName: '红小双',
  }, {
    displayName: '红波', className: 'hk6_sebo redbo',
  },
];

const blueSet = [
  {
    displayName: '蓝单',
  }, {
    displayName: '蓝双',
  }, {
    displayName: '蓝大',
  }, {
    displayName: '蓝小',
  }, {
    displayName: '蓝大单',
  }, {
    displayName: '蓝大双',
  }, {
    displayName: '蓝小单',
  }, {
    displayName: '蓝小双',
  }, {
    displayName: '蓝波', className: 'hk6_sebo bluebo',
  }
];

const greenSet = [
  {
    displayName: '绿单',
  }, {
    displayName: '绿双',
  }, {
    displayName: '绿大',
  }, {
    displayName: '绿小',
  }, {
    displayName: '绿大单',
  }, {
    displayName: '绿大双',
  }, {
    displayName: '绿小单',
  }, {
    displayName: '绿小双',
  }, {
    displayName: '绿波', className: 'hk6_sebo greenbo',
  }
];

export {
  threeColors,
  redSet,
  greenSet,
  blueSet,
  pureThreeColors
};