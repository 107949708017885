import { useState } from 'react';

import { Col, Row } from 'reactstrap';
import { Input } from 'antd';
import { connect } from 'react-redux';

import { memberActions } from '../../../redux/actions';

import './styles.scss';


const mapDispatchToProps = dispatch => ({
  ChangePassword: (obj, callback) => dispatch(memberActions.ChangePassword(obj, callback)),
});

const ChangePassword = ({ ChangePassword }) => {
  const [_password, setPassword] = useState();
  const [_passwordError, setPasswordError] = useState();
  const [_newPassword, setNewPassword] = useState();
  const [_newPasswordError, setNewPasswordError] = useState();
  const [_confirmPassword, setConfirmPassword] = useState();
  const [_confirmPasswordError, setConfirmPasswordError] = useState();
  const passwordValidation = /^[a-zA-Z0-9]{6,20}$/;

  const onChangePasswordClick = () => {
    if (!_password || !_password.trim()) return setPasswordError('密码不能为空');
    if (!_newPassword || !_newPassword.trim()) return setNewPasswordError('密码不能为空');
    if (!passwordValidation.test(_newPassword)) return setNewPasswordError('密码格式不正确');
    if (!_confirmPassword || !_confirmPassword.trim()) return setConfirmPasswordError('密码不能为空');
    if (_confirmPassword !== _newPassword) return setConfirmPasswordError('两次密码输入不一致');
    ChangePassword({
      oldPassword: _password, newPassword: _newPassword, cfmPassword: _confirmPassword
    }, () => {
      setPassword('');
      setNewPassword('');
      setConfirmPassword('');
    });
  };

  const onResetClick = () => {
    setPassword('');
    setNewPassword('');
    setConfirmPassword('');
  };

  return (
    <div className="changePasswordWrapper mx-0 bg-white px-0">
      <div className="p-3 changePasswordContentWrapper">
        <Col className="changePasswordContent px-0">
          <Row className="inputRow">
            <Col className="m-0 p-0 inputHeader">
              原始密码
            </Col>
            <Col className="d-flex align-items-center pe-0">
              <Input
                type="password"
                value={_password}
                placeholder=""
                onChange={({ target: { value: text } }) => {
                  if (text !== '') setPasswordError();
                  setPassword(text);
                }}
              />
            </Col>
            <Col className="col-7 inputError">{_passwordError}</Col>
          </Row>
          <Row className="inputRow">
            <Col className="m-0 p-0 inputHeader">
              新设密码
            </Col>
            <Col className="d-flex align-items-center pe-0">
              <Input
                type="password"
                value={_newPassword}
                placeholder=""
                onChange={({ target: { value: text } }) => {
                  if (text !== '') setNewPasswordError();
                  setNewPassword(text);
                }}
              />
            </Col>
            <Col className="col-7 inputError">{_newPasswordError}</Col>
          </Row>
          <Row className="inputRow">
            <Col className="m-0 p-0 inputHeader">
              确认密码
            </Col>
            <Col className="d-flex align-items-center pe-0">
              <Input
                type="password"
                value={_confirmPassword}
                placeholder=""
                onChange={({ target: { value: text } }) => {
                  if (text !== '') setConfirmPasswordError();
                  setConfirmPassword(text);
                }}
              />
            </Col>
            <Col className="col-7 inputError">{_confirmPasswordError}</Col>
          </Row>
        </Col>
      </div>
      <Row className="confirmRow">
        <div className="confirmButton confirm" onClick={onChangePasswordClick}>
          确定
        </div>
        <div className="confirmButton reset" onClick={onResetClick}>
          重置
        </div>
      </Row>
    </div>
  );
};

export default connect(null, mapDispatchToProps)(ChangePassword);