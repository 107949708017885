import { SET_BIG_BUHUO_LIMIT_QUERY_DATA } from '../../actions/actionTypes';

const initialState = {
  bigBuhuoLimitQueryData: undefined,
};

const bigBetLimitReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_BIG_BUHUO_LIMIT_QUERY_DATA:
      return {
        ...state, bigBuhuoLimitQueryData: action.value
      };
    default:
      return state;
  }
};

export default bigBetLimitReducers;