import { useEffect, useState } from 'react';

import { Input, Radio, Select } from 'antd';
import { Label } from 'reactstrap';
import { MdArrowBack } from 'react-icons/md';
import { connect } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { PlusCircleOutlined } from '@ant-design/icons';

import { AgentDataTable, CommonHeader, Pagination } from '../..';
import { appActions, whiteListActions } from '../../../redux/actions';

import './styles.scss';

const mapStateToProps = state => ({
  editBackupWhitelist: state.whiteListReducers.editBackupWhitelist,
  backWhitelist: state.whiteListReducers.backWhitelist,
});

const mapDispatchToProps = dispatch => ({
  GetBackupWhiteList: (obj, callback) => dispatch(whiteListActions.GetBackupWhiteList(obj, callback)),
  DelBackupWhiteList: (id, callback) => dispatch(whiteListActions.DelBackupWhiteList(id, callback)),
  EditBackupWhiteList: (id, callback) => dispatch(whiteListActions.EditBackupWhiteList(id, callback)),
  SaveBackupWhiteList: (obj, callback) => dispatch(whiteListActions.SaveBackupWhiteList(obj, callback)),
  SetStatus: (obj, callback) => dispatch(whiteListActions.SetStatus(obj, callback)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
});

const AgentBackUpList = ({
  updateModalAlert,
  SetStatus,
  SaveBackupWhiteList,
  DelBackupWhiteList,
  GetBackupWhiteList,
  backWhitelist,
  editBackupWhitelist,
  EditBackupWhiteList
}) => {
  const { t } = useTranslation();
  const [_isQuerying, setIsQuerying] = useState(false);
  const [_pageFlag, setPageFlag] = useState(0);

  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(15);

  const [loginId, setloginId] = useState('');
  const [companyId] = useState('');
  const [backup, setbackup] = useState('');
  const [exportitem, setexportitem] = useState('');
  const [auto, setauto] = useState('');

  // detail
  const [item, setItem] = useState({});

  useEffect(() => {
    onSearchClick();
  }, []);

  const saveAndEdit = () => {
    const invalid1 = item?.loginWhiteList && item?.loginWhiteList?.split(',').some(i => i && !ValidateIPaddress(i));
    const invalid2 = item?.backupWhitelist && item?.backupWhitelist.split(',').some(i => i && !ValidateIPaddress(i));
    if (invalid1 || invalid2) {
      return updateModalAlert({
        type: 'confirmation',
        visible: true,
        showCancelButton: true,
        data: {
          message: '请输入正确的IP格式',
        },
      });
    }

    const invalid3 = item?.downloadURL && !jugeUrl(item?.downloadURL);
    const invalid4 = item?.downloadURL2 && !jugeUrl(item?.downloadURL2);

    if (invalid3 || invalid4) {
      return updateModalAlert({
        type: 'confirmation',
        visible: true,
        showCancelButton: true,
        data: {
          message: '请输入正确的网址格式',
        },
      });
    }


    if (_pageFlag === 1 || _pageFlag === 2) {
      SaveBackupWhiteList(item, () => {
        setPageFlag(0);
        onSearchClick();
      });
    }
  };

  const Remove = id => DelBackupWhiteList(id, onSearchClick);

  const changeState = (id, type) => {
    SetStatus({
      id, type
    }, onSearchClick);
  };

  const onSearchClick = (params = {}) => {
    setIsQuerying(true);
    const _obj = {
      loginId,
      bak: backup,
      exp: exportitem,
      auto,
      page,
      rows,
      ...params
    };
    GetBackupWhiteList(_obj, () => setIsQuerying(false));
    setPage(1);
    setRows(15);
  };

  const onPaginationClick = (pageNum, rows) => {
    setIsQuerying(true);
    setPage(pageNum);
    setRows(rows);
    const _obj = {
      loginId,
      companyId,
      bak: backup,
      exp: exportitem,
      auto,
      page: pageNum,
      rows,
    };
    GetBackupWhiteList(_obj, () => setIsQuerying(false));
  };

  useEffect(() => {
    if (_pageFlag === 1 || _pageFlag === 0) {
      setItem({
        isAutoRun: 0,
        isBackupEnable: 0,
        isExportable: 0
      });
    }
    if (_pageFlag === 2 && editBackupWhitelist) {
      setItem(editBackupWhitelist);
    }
  }, [_pageFlag, editBackupWhitelist]);

  function ValidateIPaddress(ipaddress) {
    if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress)) {
      return true;
    }
    return false;
  }
  function jugeUrl(URL) {
    const valid = URL?.startsWith('http') || URL?.startsWith('https');
    if (valid) {
      return true;
    }
    return false;
  }
  return (
    <div className="AgentBackUpListWrapper">
      <CommonHeader
        headerName={(
          <div className="d-flex align-items-center">
            {_pageFlag !== 0 && (
              <div className="d-flex align-items-center me-2 backIcon" onClick={() => setPageFlag(0)}>
                <MdArrowBack className="h5 mb-0 text-white" />
                返回
              </div>
            )}
            {`${t('AGENT_BACKUP_WHITE_LIST')} ${_pageFlag === 0 ? '' : _pageFlag === 1 ? '/ 新增白名单' : '/ 编辑白名单'}`}
          </div>
        )}
      />
      {_pageFlag === 0 && (
        <>
          <div className="addNewGameBox my-3">
            <div className="operationBtn add d-flex align-items-center" onClick={() => setPageFlag(1)}>
              <PlusCircleOutlined />
              <span className="ms-2">新增</span>
            </div>
            <div className="mx-2">
              备份
              <Select
                className="ms-2"
                value={backup}
                placeholder="请选择"
                onChange={code => {
                  setbackup(code);
                  onSearchClick({
                    bak: code
                  });
                }}
                options={[{
                  value: '', label: '全部'
                }, {
                  value: 0, label: '禁用'
                }, {
                  value: 1, label: '允许'
                }]}
              />
            </div>
            <div className="mx-2">
              导出
              <Select
                className="ms-2"
                value={exportitem}
                placeholder="请选择"
                onChange={code => {
                  setexportitem(code);
                  onSearchClick({
                    exp: code
                  });
                }}
                options={[{
                  value: '', label: '全部'
                }, {
                  value: 0, label: '禁用'
                }, {
                  value: 1, label: '允许'
                }]}
              />
            </div>
            <div className="mx-2">
              自动
              <Select
                className="ms-2"
                value={auto}
                placeholder="请选择"
                onChange={code => {
                  setauto(code);
                  onSearchClick({
                    auto: code
                  });
                }}
                options={[{
                  value: '', label: '全部'
                }, {
                  value: 0, label: '禁用'
                }, {
                  value: 1, label: '允许'
                }]}
              />
            </div>
            <div className=" mx-2 d-flex align-items-center justify-content-center">
              <span className="mx-1 text-max">账号</span>
              <Input className="ms-2" value={loginId} onChange={e => setloginId(e.target.value.trim())} />
            </div>
            <div className="operationBtn mx-2 add" onClick={() => onSearchClick()}>查询</div>
          </div>

          <div className="mx-0 bg-white mb-3 px-0 border-1">
            <AgentDataTable
              isQuerying={_isQuerying}
              tableHeaderTitle={
                [{
                  label: '名称',
                }, {
                  label: '账号',
                }, {
                  label: '白名单',
                }, {
                  label: '滚单白名单',
                }, {
                  label: '滚单',
                }, {
                  label: '导出',
                }, {
                  label: '自动',
                },
                {
                  label: '备份彩种',
                }, {
                  label: '下载域名',
                }, {
                  label: '创建时间',
                }, {
                  label: t('OPERATION'),
                }]
              }
              tableData={backWhitelist ? backWhitelist.whiteList : []}
              tableBodyRowProperty={
                [
                  data => (<Label className="d-flex justify-content-center">{data.name}</Label>),
                  data => (<Label className="d-flex justify-content-center">{data.loginId}</Label>),
                  data => (<Label className="d-flex justify-content-center">{data.loginWhiteList}</Label>),
                  data => (<Label className="d-flex justify-content-center">{data.backupWhitelist}</Label>),
                  data => (
                    <Label className="d-flex justify-content-center mousePointIn">
                      <div className={`statusBtn ${data.isBackupEnable === 0 ? 'text-danger' : 'text-success'}`}>
                        {data.isBackupEnable === 0 ? '禁用' : '允许'}
                      </div>
                      <div className="shiftBtn" onClick={() => changeState(data.orgId, 'backup')}>
                        {data.isBackupEnable === 0 ? '允许' : '禁用'}
                      </div>
                    </Label>
                  ),
                  data => (
                    <Label className="d-flex justify-content-center mousePointIn">
                      <div className={`statusBtn ${data.isExportable === 0 ? 'text-danger' : 'text-success'}`}>
                        {data.isExportable === 0 ? '禁用' : '允许'}
                      </div>
                      <div className="shiftBtn" onClick={() => changeState(data.orgId, 'export')}>
                        {data.isExportable === 0 ? '允许' : '禁用'}
                      </div>
                    </Label>
                  ),
                  data => (
                    <Label className="d-flex justify-content-center mousePointIn">
                      <div className={`statusBtn ${data.isAutoRun === 0 ? 'text-danger' : 'text-success'}`}>
                        {data.isAutoRun === 0 ? '禁用' : '允许'}
                      </div>
                      <div className="shiftBtn" onClick={() => changeState(data.orgId, 'autorun')}>
                        {data.isAutoRun === 0 ? '允许' : '禁用'}
                      </div>
                    </Label>
                  ),
                  data => (
                    <Label className="d-flex flex-column justify-content-center align-items-center ">
                      <div>
                        {`SQL:${data?.backupLottList || ''}`}
                      </div>
                      <div>
                        {`CSV:${data?.backupLottList2 || ''}`}
                      </div>
                    </Label>
                  ),
                  data => (
                    <Label className="d-flex flex-column justify-content-center align-items-center ">
                      <div>
                        {`SQL:${data?.downloadURL || ''}`}
                      </div>
                      <div>
                        {`CSV:${data?.downloadURL2 || ''}`}
                      </div>
                    </Label>
                  ),
                  data => (<Label className="d-flex justify-content-center">{moment.unix(data.date).format('YYYY-MM-DD HH:mm:ss')}</Label>),
                  data => (
                    <Label className="d-flex justify-content-center align-items-center">
                      <div className="operationBtn mx-2" onClick={() => { EditBackupWhiteList(data.orgId, () => setPageFlag(2)); }}>编辑</div>
                      <div className="operationBtn cancel mx-2" onClick={() => { Remove(data.orgId); }}>删除</div>
                    </Label>
                  )
                ]
              }
            />
            <div className="d-flex align-items-center justify-content-center w-full relative">
              <Pagination totalRecord={backWhitelist ? (backWhitelist.totalRecords || 0) : 0} onClick={onPaginationClick} isQuery={false} />
            </div>
          </div>
        </>
      )}
      {_pageFlag !== 0 && (
        <>
          <div className="my-3 thirdPartyGamesTable">
            <div className="thirdPartyGamesTitle">{_pageFlag === 1 ? '新增白名单' : '编辑白名单'}</div>
            {(_pageFlag === 2 && editBackupWhitelist) && (
              <div className="thirdPartyGamesRow">
                <div>名称 </div>
                <div><Input disabled value={editBackupWhitelist?.name || ''} placeholder="请输入内容" /></div>
              </div>
            )}
            <div className="thirdPartyGamesRow">
              <div>账号 </div>
              <div>
                <Input
                  value={item.loginId}
                  disabled={_pageFlag === 2}
                  onChange={e => setItem({
                    ...item, loginId: e.target.value.trim()
                  })}
                  placeholder="请输入内容"
                />
              </div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>登录IP白名单 </div>
              <div>
                <Input.TextArea
                  className="formWidth"
                  value={item.loginWhiteList}
                  onChange={e => setItem({
                    ...item, loginWhiteList: e.target.value
                  })}
                  placeholder="多个IP用逗号隔开(留空则不作登录IP白名单设置)，如 127.0.0.1,127.0.0.2"
                />
                {(item?.loginWhiteList && item?.loginWhiteList?.split(',').some(i => i && !ValidateIPaddress(i)) && <span className="mx-2 text-red">请输入正确的IP格式</span>) || ''}
              </div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>滚单IP白名单 </div>
              <div>
                <Input.TextArea
                  className="formWidth"
                  value={item.backupWhitelist}
                  onChange={e => setItem({
                    ...item, backupWhitelist: e.target.value
                  })}
                  placeholder="多个IP用逗号隔开(留空则不作登录IP白名单设置)，如 127.0.0.1,127.0.0.2"
                />
                {(item?.backupWhitelist && item?.backupWhitelist?.split(',').some(i => i && !ValidateIPaddress(i)) && <span className="mx-2 text-red">请输入正确的IP格式</span>) || ''}
              </div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>备份下载域名（SQL） </div>
              <div>
                <Input.TextArea
                  className="formWidth"
                  value={item.downloadURL}
                  onChange={e => setItem({
                    ...item, downloadURL: e.target.value
                  })}
                  placeholder="绑定的公司相关的备份下载域名地址"
                />
                <span className="mx-2 text-red">
                  {
                    item.downloadURL && !jugeUrl(item.downloadURL) && '请输入正确的网址格式'
                  }
                </span>
              </div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>备份彩种（SQL） </div>
              <div>
                <Input.TextArea
                  className="formWidth"
                  value={item.backupLottList}
                  onChange={e => setItem({
                    ...item, backupLottList: e.target.value
                  })}
                  placeholder="备份下载彩种"
                />
                <span className="mx-2 text-red">
                  多彩种请逗号隔开，此设置暂时仅在自动备份中有效
                </span>
              </div>
            </div>

            <div className="thirdPartyGamesRow">
              <div>备份下载域名（CSV） </div>
              <div>
                <Input.TextArea
                  className="formWidth"
                  value={item.downloadURL2}
                  onChange={e => setItem({
                    ...item, downloadURL2: e.target.value
                  })}
                  placeholder="绑定的公司相关的备份下载域名地址"
                />
                <span className="mx-2 text-red">
                  {
                    item.downloadURL2 && !jugeUrl(item.downloadURL2) && '请输入正确的网址格式'
                  }
                </span>
              </div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>备份彩种（CSV） </div>
              <div>
                <Input.TextArea
                  className="formWidth"
                  value={item.backupLottList2}
                  onChange={e => setItem({
                    ...item, backupLottList2: e.target.value
                  })}
                  placeholder="备份下载彩种"
                />
                <span className="mx-2 text-red">
                  多彩种请逗号隔开，此设置暂时仅在自动备份中有效
                </span>
              </div>
            </div>

            <div className="thirdPartyGamesRow">
              <div>滚单权限 </div>
              <div>
                <Radio
                  checked={item.isBackupEnable === 0}
                  onChange={() => setItem({
                    ...item, isBackupEnable: 0
                  })}
                >
                  禁用
                </Radio>
                <Radio
                  checked={item.isBackupEnable === 1}
                  onChange={() => setItem({
                    ...item, isBackupEnable: 1
                  })}
                >
                  允许
                </Radio>

              </div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>导出权限 </div>
              <div>
                <Radio
                  checked={item.isExportable === 0}
                  onChange={() => setItem({
                    ...item, isExportable: 0
                  })}
                >
                  禁用
                </Radio>
                <Radio
                  checked={item.isExportable === 1}
                  onChange={() => setItem({
                    ...item, isExportable: 1
                  })}
                >
                  允许
                </Radio>
              </div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>自动备份 </div>
              <div>
                <Radio
                  checked={item.isAutoRun === 0}
                  onChange={() => setItem({
                    ...item, isAutoRun: 0
                  })}
                >
                  禁用
                </Radio>
                <Radio
                  checked={item.isAutoRun === 1}
                  onChange={() => setItem({
                    ...item, isAutoRun: 1
                  })}
                >
                  允许
                </Radio>
              </div>
            </div>
          </div>
          <div className="thirdPartyGamesFooter">
            <div className="operationBtn editAndSave me-3" onClick={() => saveAndEdit()}>{t('CONFIRM')}</div>
            <div className="operationBtn cancel me-3" onClick={() => setPageFlag(0)}>{t('CANCEL')}</div>
          </div>
        </>
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AgentBackUpList);