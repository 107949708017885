import { useEffect, useState } from 'react';

import { Button, Col, Input, Label, Row } from 'reactstrap';
import { FaPlusCircle } from 'react-icons/fa';
import { connect } from 'react-redux';

import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

import { appActions, systemActions } from '../../../redux/actions';
import AgentDataTable from '../../AgentDataTable';
import './styles.scss';


const mapStateToProps = state => ({
  bankCards: state.systemReducers.bankCards,
  userData: state.memberReducers.userData,
});

const mapDispatchToProps = dispatch => ({
  getBankCardList: (obj, callback) => dispatch(systemActions.GetBankCardList(obj, callback)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  deleteBankSetting: (obj, callback) => dispatch(systemActions.deleteBankSetting(obj, callback)),
  updateBankSettingSorting: (obj, callback) => dispatch(systemActions.updateBankSettingSorting(obj, callback)),
});

const BankSetting = ({ callback = () => {}, bankCards, getBankCardList, updateBankSettingSorting, updateModalAlert, deleteBankSetting }) => {
  const { t } = useTranslation();
  const [_info, setInfo] = useState([]);
  const [_isQueryingDetail, setIsQueryingDetail] = useState(true);
  const [isQueryStatus, setQueryStatus] = useState(true);
  const [_sorting, setSorting] = useState(0);
  const [_listStatus, setListStatus] = useState(-1);
  const [_highlightItem, setHighLightItem] = useState({});
  const [_isLoaded, setIsReload] = useState(false);

  useEffect(() => {
    if (bankCards?.cardList && JSON.stringify(bankCards?.cardList) !== JSON.stringify(_info)) {
      const list = [...bankCards?.cardList ?? []];
      const index = Number(list?.findIndex(item => item.id === _highlightItem?.id));
      if (index !== -1) {
        list[index].actionStatus = '300';
      } else if (_highlightItem !== '' && index === -1 && _isLoaded) {
        setIsReload(false);
      }
      setInfo(list);
      setQueryStatus(false);
    }
  }, [bankCards]);

  useEffect(() => {
    setIsQueryingDetail(true);
    getBankCardList({
      order: _sorting,
      status: _listStatus,
    }, setIsQueryingDetail);
  }, []);

  const findClick = () => {
    // Page number and {row number will follow the pagination component instead
    getBankCardList({
      order: _sorting,
      status: _listStatus,
    }, setIsQueryingDetail);
  };

  const afterSetClick = () => {
    // Page number and {row number will follow the pagination component instead
    getBankCardList({
      order: _sorting,
      status: _listStatus,
    }, setIsQueryingDetail);
  };

  const setStatus = (id, type, needNoti) => {
    if (needNoti) {
      updateModalAlert({
        type: 'confirmation',
        visible: true,
        showCancelButton: true,
        data: {
          message: '确认删除',
          onConfirmationClick: () => deleteBankSetting(id, afterSetClick)
        },
      });
    } else {
      deleteBankSetting(id, afterSetClick);
    }
  };

  const updateSorting = (id, type) => {
    setIsReload(true);
    setHighLightItem({
      id,
      type
    });
    const updateobj = {
      id,
      type
    };
    updateBankSettingSorting(updateobj, afterSetClick);
  };

  const Status = [
    {
      label: '全部',
      value: -1,
    },
    {
      label: '启用',
      value: 0,
    }, {
      label: '禁用',
      value: 1,
    }
  ];

  const Sorting = [
    {
      label: '降序',
      value: 0,
    }, {
      label: '升序',
      value: 1,
    }
  ];

  const tableHeader = [{
    label: t('TYPE'),
  }, {
    label: t('TYPE_NAME'),
  }, {
    label: t('CURRENCY_CODE'),
  }, {
    label: t('BELONGS_BANK'),
  }, {
    label: t('BANK_CARD_PREFIX'),
  }, {
    label: t('DEPOSIT_RATE'),
  }, {
    label: t('WITHDRAW_RATE'),
  }, {
    label: t('AUTO_RENEW_EX_RATE'),
  }, {
    label: t('STATUS'),
  }, {
    label: t('ORDER_BY'),
  }, {
    label: t('FUNCTION'),
  }];

  const tableContent = [
    data => (<Label className="d-flex justify-content-center">{data?.type}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.typeName}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.currency}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.name}</Label>),
    data => (<div className={data?.number && (data?.number.length > 100 && 'havetext')}><Input type="textarea" value={data?.number} disabled /></div>),
    data => (<Label className="d-flex justify-content-center">{data?.deposit}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.withdraw}</Label>),
    data => (data?.autoExchange === 1 ? <Label className="negativeValue">{t('NO')}</Label> : <Label className="installment">{t('YES')}</Label>),
    data => (data?.status === 1 ? <Label className="negativeValue">{t('DISABLED')}</Label> : <Label className="installment">{t('ACTIVE')}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.orderId}</Label>),
    data => (
      <>
        <Button className="button green mx-1" onClick={() => callback('bankSettingAddEdit', data, true)}>{t('COMPILE')}</Button>
        <Button className="button find mx-1" onClick={() => setStatus(data?.id, 7, 1)}>{t('DELETE')}</Button>
        <Button className="button handle mx-1" onClick={() => updateSorting(data?.id, -1)}>{t('MOVE_UP')}</Button>
        <Button className="button handle mx-1" onClick={() => updateSorting(data?.id, 1)}>{t('MOVE_DOWN')}</Button>
      </>
    ),
  ];

  return (
    <div className="BankSettingWrapper mb-0">
      <div className="mx-0 bg-white my-2 border-1">
        <Row className="p-0 m-0 mb-2">
          <Col className="p-1 mb-2 filterWrapper d-flex align-items-center w-100">
            <Col className="d-flex align-items-center">
              <Button
                className="button add justify-content-center marginLeft5"
                onClick={() => callback('bankSettingAddEdit', false)}
              >
                <FaPlusCircle className="mb-1 mr-1" />
                <Label className="p-0 px-1">{t('ADD_SETTING')}</Label>
              </Button>
              <Label value={t('STATUS')} className="px-3">{t('STATUS')}</Label>
              <Select
                value={_listStatus}
                placeholder="请选择"
                onChange={val => setListStatus(val)}
              >
                {
                              Status.map(d => (
                                <Select.Option key={`setReportUnit_${d.value}`} label={d.label} value={d.value} />
                              ))
                        }
              </Select>
              <Label value={t('ORDER_BY')} className="px-2 marginLeft5">{t('ORDER_BY')}</Label>
              <Select
                value={_sorting}
                placeholder="请选择"
                onChange={val => setSorting(val)}
              >
                {
                              Sorting.map(d => (
                                <Select.Option key={`setReportUnit_${d.value}`} label={d.label} value={d.value} />
                              ))
                        }
              </Select>
              <Button className="button find mx-3" onClick={() => { setIsQueryingDetail(true); findClick(); }}>{t('FIND')}</Button>
            </Col>
          </Col>
        </Row>
        <Row className="bigWrapper m-0 my-1 p-3 mb-0">
          <AgentDataTable
            isQuerying={_isQueryingDetail}
            tableHeaderTitle={tableHeader}
            tableData={_info || (isQueryStatus ? [1, 2, 3] : [])}
            tableBodyRowProperty={tableContent}
            tableBodyRowStyle={['', '', '', '', 'col-5', '']}
          />
        </Row>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BankSetting);