
import { renderResultDateCell } from '../../utils';

const renderBall = data => {
  if (!data.openResult) return;
  const result = data.openResult.split(',');
  const tempResult = [[{
    value: result[0],
    className: ''
  }], [{
    value: result[1],
    className: ''
  }], [{
    value: result[2],
    className: ''
  }], [{
    value: result[3],
    className: ''
  }], [{
    value: result[4],
    className: ''
  }], [{
    value: result[5],
    className: ''
  }], [{
    value: result[6],
    className: ''
  }]];
  return tempResult;
};

const generateQXCDS = (arr, type) => {
  if (type < 7 && arr[type]) {
    const value = arr[type] % 2 === 0 ? '双' : '单';
    return ({
      value,
      className: value === '单' ? 'redText' : ''
    });
  }
  return '';
};

const generateQXCHDS = (arr, firstType, secondType) => {
  if (arr[firstType] && arr[secondType]) {
    const value = (Number(arr[firstType]) + Number(arr[secondType])) % 2 === 0 ? '双' : '单';
    return ({
      value,
      className: value === '单' ? 'redText' : ''
    });
  }
  return '';
};

const renderDS = data => {
  if (!data.openResult) return;
  const result = data.openResult.split(',');
  const tempResult = [
    [generateQXCDS(result, 0)],
    [generateQXCDS(result, 1)],
    [generateQXCDS(result, 2)],
    [generateQXCDS(result, 3)]
  ];
  return tempResult;
};

const renderHDS = data => {
  if (!data.openResult) return;
  const result = data.openResult.split(',');
  const tempResult = [
    [generateQXCHDS(result, 0, 1)],
    [generateQXCHDS(result, 0, 2)],
    [generateQXCHDS(result, 0, 3)],
    [generateQXCHDS(result, 1, 2)],
    [generateQXCHDS(result, 1, 3)],
    [generateQXCHDS(result, 2, 3)]
  ];
  return tempResult;
};

const QXC = {
  children: [
  ],
  resultTab: {
    headerCell: [{
      attr: '期数'
    }, {
      attr: '日期'
    }, {
      attr: () => [[{
        value: '彩球号码'
      }], [{
        value: '仟'
      }, {
        value: '佰'
      }, {
        value: '拾'
      }, {
        value: '个'
      }, {
        value: '五'
      }, {
        value: '六'
      }, {
        value: '七'
      }]],
      wrapperStyle: 'hk6Header',
    }, {
      attr: () => [[{
        value: '单双'
      }], [{
        value: '仟'
      }, {
        value: '佰'
      }, {
        value: '拾'
      }, {
        value: '个'
      }]],
      wrapperStyle: 'hk6Header',
    }, {
      attr: () => [[{
        value: '合单双'
      }], [{
        value: '仟佰'
      }, {
        value: '仟拾'
      }, {
        value: '仟个'
      }, {
        value: '佰拾'
      }, {
        value: '佰个'
      }, {
        value: '拾个'
      }]],
      wrapperStyle: 'hk6Header',
    }
    ],
    bodyCell: [{
      attr: 'installments',
      wrapperStyle: 'd-flex justify-content-center align-items-center'
    }, {
      attr: renderResultDateCell,
      wrapperStyle: 'dateCol'
    }, {
      attr: renderBall,
      wrapperStyle: 'd-flex hk6BallCol'
    },
    {
      attr: renderDS,
      wrapperStyle: 'd-flex hk6BallCol'
    }, {
      attr: renderHDS,
      wrapperStyle: 'd-flex hk6BallCol'
    }
    ]
  },
};

export default QXC;