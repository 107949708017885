

import { Button, Col, Row } from 'reactstrap';
import { IoClose } from 'react-icons/io5';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { appActions } from '../../../redux/actions';

import './styles.scss';

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
});

const TransactionHistoryModal = ({ updateModalAlert, data }) => {
  const { t } = useTranslation();

  return (
    <div className="TransactionHistoryModalWrapperWeb">
      <Row className="modalHeader">
        <Col
          className="d-flex align-items-center"
        >
          {t('REMINDER')}
        </Col>
        <Col className="d-flex justify-content-end align-items-center">
          <IoClose
            onClick={() => {
              updateModalAlert({
                visible: false
              });
              if (data && data.overBet && data.onConfirmationClick) data.onConfirmationClick();
            }}
            className="h4 mb-0 float-right closeIcon"
          />
        </Col>
      </Row>
      <div className="modalContent d-flex justify-content-center mx-0 p-2 ">
        <div className="orderInformationTable">
          <div className="evenRowStyle">
            <div>订单号</div>
            <div>{data && data.result.orderID}</div>
            <div>用户名</div>
            <div>{data && data.result.mName}</div>
          </div>
          <div className="evenRowStyle">
            <div>交易类型</div>
            <div>{data && data.result.actionTypeName}</div>
            <div>交易状态</div>
            <div>{data && data.result.actionStatusName}</div>
          </div>
          <div className="evenRowStyle">
            <div>交易金额</div>
            <div>{data && (`${data.result.actionAmount} (手续费:${data.result.actionFee})`)}</div>
            <div>实际金额</div>
            <div>{data && (data.result.actionAmount - data.result.actionFee)}</div>
          </div>
          <div className="evenRowStyle">
            <div>交易描述</div>
            <div>{data && data.result.createRemark}</div>
          </div>
          <div className="evenRowStyle">
            <div>交易时间</div>
            <div>{data && data.result.createTime}</div>
            <div>审核备注</div>
            <div>{data && data.result?.checkRemark}</div>
          </div>
          {/* <div className="orderInformationRowBackGroundColor d-flex justify-content-center align-items-center">银行信息说明</div>

          <div className="evenRowStyle">
            <div>银行名称</div>
            <div>{data && (data.result?.toBankName)}</div>
            <div>银行卡号</div>
            <div>{data && (data.result?.toBankNumber)}</div>
          </div>
          <div className="evenRowStyle">
            <div>开户名</div>
            <div>{data && (data.result?.toBankUName)}</div>
            <div>开户地址</div>
            <div>{data && (data.result?.toBankAddress)}</div>
          </div> */}
        </div>
      </div>
      <Row className="modalFooter d-flex align-items-center ">
        <Button
          className="confirmButton my-3"
          onClick={() => {
            updateModalAlert({
              visible: false
            });
          }}
        >
          {t('CONFIRM')}
        </Button>
      </Row>
    </div>
  );
};

export default connect(null, mapDispatchToProps)(TransactionHistoryModal);