import { StatusCodes } from 'http-status-codes';

import { SET_TRANSACTION_DETAIL, SET_TRANSACTION_FLOW, SET_TRANSACTION_MANAGE } from '../actionTypes';
import { apiService } from '../../../library';
import appActions from '../appActions';

const setTransactionList = data => ({
  type: SET_TRANSACTION_MANAGE,
  value: data,
});

const setTransactionFlows = data => ({
  type: SET_TRANSACTION_FLOW,
  value: data,
});

const setTransactionDetail = data => ({
  type: SET_TRANSACTION_DETAIL,
  value: data,
});

const GetTransactionList = (obj, callback = () => {}) => dispatch => {
  apiService.get('/TransactionManage/GetTransactionList', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      callback(false);
      return dispatch(setTransactionList(data.result));
    }
  }).catch(error => {
    callback(false);
    dispatch(setTransactionList());
    dispatch(appActions.updateModalAlert({
      visible: true,
      type: 'confirmation',
      data: {
        message: error.response.data.msg
      }
    }));
    Promise.reject(error);
  });
};

const GetTransactionFlows = (obj, callback = () => {}) => dispatch => {
  apiService.get('/TransactionManage/GetTransactionFlows', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      callback(false);
      return dispatch(setTransactionFlows(data.result));
    }
  }).catch(error => {
    callback(false);
    dispatch(setTransactionFlows());
    dispatch(appActions.updateModalAlert({
      visible: true,
      type: 'confirmation',
      data: {
        message: error.response.data.msg
      }
    }));
    Promise.reject(error);
  });
};

const GetTransactionDetail = (id, orderId) => dispatch => {
  apiService.get('/TransactionManage/GetTransactionDetail', new URLSearchParams({
    id, orderId
  })).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      return dispatch(setTransactionDetail(data.result));
    }
  }).catch(error => {
    dispatch(setTransactionDetail());
    Promise.reject(error);
  });
};

const UpdateTransaction = (obj, callback = () => {}) => dispatch => {
  apiService.post('/TransactionManage/UpdateTransaction', obj).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        callback();
        dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'success',
          data: {
            message: data.msg,
          }
        }));
        return dispatch(GetTransactionDetail(obj.id, obj.orderId));
      }
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: true,
      type: 'confirmation',
      data: {
        message: error.response.data.msg
      }
    }));
    Promise.reject(error);
  });
};

const ResetBetFlow = (id, obj) => dispatch => {
  apiService.post(`/TransactionManage/ResetBetFlow/${id}`).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        dispatch(GetTransactionList(obj));
        return dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'success',
          data: {
            message: data.msg,
          }
        }));
      }
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: true,
      type: 'confirmation',
      data: {
        message: error.response.data.msg
      }
    }));
    Promise.reject(error);
  });
};

const GetRefundDetail = (obj, callback = () => {}) => dispatch => {
  apiService.get('/TransactionManage/GetRefundDetail', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        callback(false);
        return dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'oneTimeWithdrawal',
          data: {
            result: data.result,
            obj
          }
        }));
      }
    }
  }).catch(error => {
    callback(false);
    dispatch(appActions.updateModalAlert({
      visible: true,
      type: 'confirmation',
      data: {
        message: error.response.data.msg
      }
    }));
    Promise.reject(error);
  });
};

const SaveRefund = obj => dispatch => {
  apiService.post('/TransactionManage/SaveRefund', obj).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        return dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'success',
          data: {
            message: data.msg,
          }
        }));
      }
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: true,
      type: 'confirmation',
      data: {
        message: error.response.data.result || error.response.data.msg
      }
    }));
    Promise.reject(error);
  });
};

const SaveUserTransfer = (obj, callback = () => {}) => dispatch => {
  apiService.post('/TransactionManage/SaveUserTransfer', obj).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        callback();
        return dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'success',
          data: {
            message: data.msg,
          }
        }));
      }
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: true,
      type: 'confirmation',
      data: {
        message: error.response.data.result || error.response.data.msg
      }
    }));
    Promise.reject(error);
  });
};

export default {
  GetTransactionList,
  GetTransactionFlows,
  GetTransactionDetail,
  UpdateTransaction,
  ResetBetFlow,
  GetRefundDetail,
  SaveRefund,
  SaveUserTransfer,
};