/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';

import { Checkbox, Input, InputNumber, Radio, Select } from 'antd';
import { Label } from 'reactstrap';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { agentActions, appActions, companyActions } from '../../../redux/actions';
import AgentDataTable from '../../AgentDataTable';
import Skeleton from '../../Skeleton';
import mainGames from '../../../constants/QuickSetVariable/Games';
import './styles.scss';

const { Option } = Select;
const returnOptionList = [
  {
    label: 'A盘', value: 'aReturn'
  },
  {
    label: 'B盘', value: 'bReturn'
  },
  {
    label: 'C盘', value: 'cReturn'
  },
  {
    label: 'D盘', value: 'dReturn'
  },
  {
    label: '赔率A', value: 'oddsA'
  },
  {
    label: '赔率B', value: 'oddsB'
  },
  {
    label: '赔率C', value: 'oddsC'
  },
  {
    label: '赔率D', value: 'oddsD'
  }
];

const mapStateToProps = state => ({
  activeLottery: state.commonReducers.activeLottery,
  oddsDiffData: state.agentReducers.oddsDiffData,
  userData: state.memberReducers.userData,
  oddsDiffUpOrgsData: state.companyReducers.oddsDiffUpOrgsData,
  userInfo: state.memberReducers.userInfo,
});

const mapDispatchToProps = dispatch => ({
  getOddsDifferent: (orgId, lotType, callback) => dispatch(agentActions.getOddsDifferent(orgId, lotType, callback)),
  getOddsDiffUpOrgs: (orgId, lotType, callback) => dispatch(companyActions.getOddsDiffUpOrgs(orgId, lotType, callback)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  saveOddsDifference: (obj, callback) => dispatch(agentActions.saveOddsDifference(obj, callback)),
});


const OddsDiffItem = ({ item, memberGame, allOddsList = new Map(), setAllOddsList, setOddsList, changAll, updateModalAlert, _selectedLottery }) => {
  const { t } = useTranslation();
  const [ownState, setOwnState] = useState(item);
  useEffect(() => {
    if (JSON.stringify(item) !== JSON.stringify(ownState)) setOwnState(item);
  }, [item]);

  const onValueChange = (text, settingCode, oddsCode, type) => {
    const ls = new Map(allOddsList).get(settingCode);
    let tempItem = [];

    if (changAll) {
      tempItem = ls.map(x => {
        if (type.includes('Return') || type.includes('itemLimit') || type.includes('projectLimit')) {
          if (x.settingCode === settingCode) {
            return ({
              ...x, [type]: Number.isNaN(Number(text)) ? x[type] : text, [`${type}singleChange`]: false, [`${type}multiChange`]: true
            });
          }
          return ({
            ...x
          });
        }
        if (x.settingCode === settingCode && x.oddsCode === oddsCode) {
          return ({
            ...x, [type]: Number.isNaN(Number(text)) ? x[type] : text, [`${type}singleChange`]: false, [`${type}multiChange`]: true
          });
        }
        return ({
          ...x
        });
      });
    } else {
      tempItem = ls.map(x => {
        if (x.lotteryCode === _selectedLottery) {
          if (type.includes('Return') || type.includes('itemLimit') || type.includes('projectLimit')) {
            if (x.settingCode === settingCode) {
              return ({
                ...x, [type]: Number.isNaN(Number(text)) ? x[type] : text, [`${type}singleChange`]: true, [`${type}multiChange`]: false
              });
            }
            return ({
              ...x
            });
          }
          if (x.settingCode === settingCode && x.oddsCode === oddsCode) {
            return ({
              ...x, [type]: Number.isNaN(Number(text)) ? x[type] : text, [`${type}singleChange`]: true, [`${type}multiChange`]: false
            });
          }
          return ({
            ...x
          });
        }
        return ({
          ...x
        });
      });
    }
    if (tempItem.length > 0) {
      allOddsList.set(settingCode, tempItem);
      setAllOddsList(allOddsList);
    }

    const temp = ownState.map(x => {
      if (type.includes('Return') || type.includes('itemLimit') || type.includes('projectLimit')) {
        if (x.settingCode === settingCode) {
          return ({
            ...x, [type]: Number.isNaN(Number(text)) ? x[type] : text, [`${type}singleChange`]: true, [`${type}multiChange`]: false
          });
        }
        return ({
          ...x
        });
      }
      if (x.settingCode === settingCode && x.oddsCode === oddsCode) {
        return ({
          ...x, [type]: Number.isNaN(Number(text)) ? x[type] : text, [`${type}singleChange`]: true, [`${type}multiChange`]: false
        });
      }
      return ({
        ...x
      });
    });
    setOwnState(temp);
  };

  return (
    <div className="itemRowWrapper">
      <div className="categoryWrapper">
        <div>{ownState[0].name}</div>
        <div>
          {
            ownState.map(item => <div className="subNameWrapper">{item.subName}</div>)
          }
        </div>
      </div>
      {
        memberGame.map(panKou => (
          <div className="SingleOddsDiffWrapper">
            <div>
              {
                ownState.map(subItem => (
                  <div className="subNameWrapper">
                    <InputNumber
                      className={`${subItem[`odds${panKou}singleChange`] ? 'singleChange' : ''} ${subItem[`odds${panKou}multiChange`] ? 'multiChange' : ''}`}
                      value={subItem[`odds${panKou}`]}
                      onChange={text => onValueChange(text, subItem.settingCode, subItem.oddsCode, `odds${panKou}`)}
                    />
                    @
                    {Number(subItem[`odds${panKou}Default`] || 0).toFixed(3)}
                  </div>
                ))
              }
            </div>
            <div className="singleReturnWrapper">
              <InputNumber
                min={0}
                onChange={text => onValueChange((text > -1 ? Number(text) : ownState[0][`${panKou.toLowerCase()}Return`]), ownState[0].settingCode, ownState[0].oddsCode, `${panKou.toLowerCase()}Return`)}
                className={`${ownState[0][`${panKou.toLowerCase()}ReturnsingleChange`] ? 'singleChange' : ''} ${ownState[0][`${panKou.toLowerCase()}ReturnmultiChange`] ? 'multiChange' : ''}`}
                value={
                ownState[0][`${panKou.toLowerCase()}Return`]
              }
              />
            </div>
          </div>
        ))
      }
      <div className="moneyLimitColumn">
        <InputNumber
          min={0}
          className={`${ownState[0]?.itemLimitsingleChange ? 'singleChange' : ''} ${ownState[0]?.itemLimitmultiChange ? 'multiChange' : ''}`}
          value={ownState[0].itemLimit}
          onChange={text => onValueChange((text > -1 ? Number(text) : ownState[0]?.itemLimit), ownState[0].settingCode, ownState[0].oddsCode, 'itemLimit')}
        />
      </div>
      <div className="moneyLimitColumn">
        <InputNumber
          min={0}
          className={`${ownState[0]?.projectLimitsingleChange ? 'singleChange' : ''} ${ownState[0]?.projectLimitmultiChange ? 'multiChange' : ''}`}
          value={ownState[0].projectLimit}
          onChange={text => onValueChange((text > -1 ? Number(text) : ownState[0]?.projectLimit), ownState[0].settingCode, ownState[0].oddsCode, 'projectLimit')}
        />
      </div>
      <div className="previewColumn">
        {
          ownState.map(subItem => (
            <div
              className="subNameWrapper"
              title="赔率预览"
              onClick={() => {
                const temp = {
                  ...subItem,
                };
                memberGame.forEach(Pankou => {
                  temp[`diffOdds${Pankou}`] = ((subItem[`odds${Pankou}`] ?? 0) - (subItem[`odds${Pankou}Default`] ?? 0)).toFixed(3);
                });
                updateModalAlert({
                  type: 'previewOddDiff',
                  visible: true,
                  data: {
                    thisData: temp,
                    memberGame: memberGame.join('')
                  }
                });
              }}
            >
              {t('PREVIEW')}
            </div>
          ))
        }
      </div>
    </div>
  );
};

const ReturnAndOddsDiff = ({ getOddsDiffUpOrgs, oddsDiffUpOrgsData, data, userInfo, userData, getOddsDifferent, oddsDiffData, saveOddsDifference, updateModalAlert }) => {
  const { t } = useTranslation();
  const [_selectedItem, setSelectedItem] = useState(
    data
      ? {
        organizationId: data.orgId, name: data.loginId, levelValue: data.level
      }
      : {
        organizationId: userData.isChild === 1 ? userInfo?.orgId : userData.id, name: userData.loginId, levelValue: userData.levelValue
      }
  );
  const [_dropdownList, setDropdownList] = useState([]);
  const [lotType, setlotType] = useState();
  const [_isQuerying, setIsQuerying] = useState(false);
  const [billList, setBillList] = useState();
  const [memberGame, setMemberGame] = useState(['A', 'B', 'C', 'D']);
  const [lotteryList, setLotteryList] = useState();
  const [oddsList, setOddsList] = useState([]);
  const [allOddsList, setAllOddsList] = useState(new Map());
  const [menuList, setMenuList] = useState([]);
  const [activeKey, setActiveKey] = useState();
  const [_selectedLottery, setSelectedLottery] = useState();
  const [changAll, setChangeAll] = useState(false);
  const [commonValue, setCommonValue] = useState('');
  const [_returnOption, setReturnOption] = useState('');
  const [_changeValue, setChangeValue] = useState('');
  const [syncOdds, setsyncOdds] = useState(0);
  const [downValue, setDownValue] = useState(1);
  const [_mainGames, setMainGames] = useState('');

  const resetMode = () => {
    setMemberGame(['A', 'B', 'C', 'D']);
    setOddsList([]);
    setAllOddsList(new Map());
    setChangeAll(false);
    setCommonValue('');
    setReturnOption('');
    setChangeValue('');
    setDownValue(1);
    setsyncOdds(0);
    setLotteryList(0);
    setMenuList([]);
    setActiveKey();
    setSelectedLottery();
    setMainGames('');
  };

  useEffect(() => {
    if (_selectedItem && _selectedItem.levelValue <= 3) {
      getOddsDiffUpOrgs(_selectedItem.organizationId, _selectedItem.levelValue === 3 ? 2 : 1);
    }
  }, [_selectedItem]);

  const getOddsDiffData = () => {
    setIsQuerying(true);
    getOddsDifferent(_selectedItem.organizationId, lotType, () => setIsQuerying(false));
  };

  useEffect(() => {
    getOddsDiffData();
  }, [_selectedItem, lotType]);

  useEffect(() => {
    if (oddsDiffData && lotType !== undefined) {
      const newMap = new Map();
      oddsDiffData.oddsList.forEach(item => {
        if (newMap.has(item.settingCode)) {
          const temp = newMap.get(item.settingCode).concat({
            ...item, oddsADefault: item.oddsA, oddsBDefault: item.oddsB, oddsCDefault: item.oddsC, oddsDDefault: item.oddsD
          });
          newMap.set(item.settingCode, temp);
        } else {
          newMap.set(item.settingCode, [{
            ...item, oddsADefault: item.oddsA, oddsBDefault: item.oddsB, oddsCDefault: item.oddsC, oddsDDefault: item.oddsD
          }]);
        }
      });
      setAllOddsList(newMap);
      setMemberGame(oddsDiffData.memberGame.split(''));
      setBillList(oddsDiffData.billList);
    }
    if (oddsDiffData) {
      setLotteryList(oddsDiffData.lotteryList);
    }
  }, [oddsDiffData]);

  useEffect(() => {
    if (lotteryList) {
      const tempGames = mainGames.Games.filter(x => lotteryList.some(item => x.code.includes(item.templateCode)));
      if (lotType === undefined) {
        setlotType(tempGames[0]?.value);
      }
      if (lotType !== undefined) {
        setMainGames(tempGames);
      }
    }
  }, [lotteryList]);

  useEffect(() => {
    if (_mainGames && _mainGames.length > 0) {
      const _tab = _mainGames.find(x => x.value === lotType);
      const content = [];
      const temp = new Map();
      if (_tab) {
        lotteryList.filter(x => _tab.code.includes(x.templateCode)).forEach(item => {
          if (temp.has(item.templateCode)) {
            const _tempArr = [...temp.get(item.templateCode), {
              ...item, lotteryName: billList.includes(item.code) ? `${item.lotteryName}[预设]` : item.lotteryName
            }];
            temp.set(item.templateCode, _tempArr);
          } else {
            temp.set(item.templateCode, [{
              ...item, lotteryName: billList.includes(item.code) ? `${item.lotteryName}[预设]` : item.lotteryName
            }]);
          }
        });
        temp.forEach((item, key) => {
          if (item.length !== 0) {
            content.push({
              label: _tab.name.find(x => x.value === key)?.label,
              key,
              children: item
            });
          }
        });
        setActiveKey(content[0]?.key);
        setMenuList(content);
      }
    }
  }, [_mainGames]);

  useEffect(() => {
    if (activeKey && _mainGames && _mainGames.length > 0) {
      const _tab = _mainGames.find(x => x.value === lotType);
      const temp = new Map();
      if (_tab) {
        lotteryList.filter(x => _tab.code.includes(x.templateCode)).forEach(item => {
          if (temp.has(item.templateCode)) {
            const _tempArr = [...temp.get(item.templateCode), item];
            temp.set(item.templateCode, _tempArr);
          } else {
            temp.set(item.templateCode, [item]);
          }
        });
        setSelectedLottery(temp.get(activeKey)[0].code);
        onLotteryChanges(temp.get(activeKey)[0].code);
      }
    }
  }, [activeKey]);

  useEffect(() => {
    if (oddsDiffUpOrgsData) {
      const temp = [{
        ..._selectedItem
      }].concat(oddsDiffUpOrgsData.map(item => ({
        ...item, levelValue: _selectedItem.levelValue === 3 ? _selectedItem.levelValue : _selectedItem.levelValue + 1
      })));
      setDropdownList(temp);
    }
  }, [oddsDiffUpOrgsData]);

  const onSelectChange = val => {
    resetMode();
    const temp = _dropdownList.find(x => x.organizationId === val);
    setSelectedItem({
      ...temp
    });
  };

  const onClickConfirm = () => {
    if (_returnOption && (commonValue !== '' || _changeValue !== '')) {
      const temp = new Map();
      if (changAll) {
        allOddsList.forEach((ls = [], key) => {
          const _ls = ls.map(x => {
            if (_returnOption.includes('Return') || _returnOption.includes('itemLimit') || _returnOption.includes('projectLimit')) {
              return ({
                ...x, [_returnOption]: commonValue !== '' ? Number((Number(commonValue) + Number(_changeValue)).toFixed(4)) : Number((Number(_changeValue) + Number(x[_returnOption])).toFixed(4)), [`${_returnOption}singleChange`]: false, [`${_returnOption}multiChange`]: true
              });
            }
            return ({
              ...x, [_returnOption]: commonValue !== '' ? Number((Number(commonValue) + Number(_changeValue)).toFixed(4)) : Number((Number(_changeValue) + Number(x[_returnOption])).toFixed(4)), [`${_returnOption}singleChange`]: false, [`${_returnOption}multiChange`]: true
            });
          });
          temp.set(key, _ls);
        });
      } else {
        allOddsList.forEach((ls = [], key) => {
          const _ls = ls.map(x => {
            if (x.lotteryCode === _selectedLottery) {
              if (_returnOption.includes('Return') || _returnOption.includes('itemLimit') || _returnOption.includes('projectLimit')) {
                return ({
                  ...x, [_returnOption]: commonValue !== '' ? Number((Number(commonValue) + Number(_changeValue)).toFixed(4)) : Number((Number(_changeValue) + Number(x[_returnOption])).toFixed(4)), [`${_returnOption}singleChange`]: true, [`${_returnOption}multiChange`]: false
                });
              }
              return ({
                ...x, [_returnOption]: commonValue !== '' ? Number((Number(commonValue) + Number(_changeValue)).toFixed(4)) : Number((Number(_changeValue) + Number(x[_returnOption])).toFixed(4)), [`${_returnOption}singleChange`]: true, [`${_returnOption}multiChange`]: false
              });
            }
            return ({
              ...x
            });
          });
          temp.set(key, _ls);
        });
      }
      const oddLs = new Array(0);
      temp.forEach(item => {
        if (item.length > 0) {
          const tempList = item.filter(x => x.lotteryCode === _selectedLottery);
          oddLs.push(tempList.map(x => ({
            ...x
          })));
        }
      });
      setOddsList(oddLs);
      setAllOddsList(temp);
    }
  };

  const onClickReset = () => {
    resetMode();
    getOddsDiffData();
  };

  const onClickSave = () => {
    let temp = new Array(0);
    allOddsList.forEach(item => {
      const _ls = item.filter(x => Object.keys(x).find(a => a.includes('singleChange')) || Object.keys(x).find(a => a.includes('multiChange')));
      if (_ls.length > 0) {
        temp = temp.concat(_ls);
      }
    });
    const obj = {
      lotteryType: 'HK6',
      downValue,
      syncOdds,
      setting: temp,
      orgId: _selectedItem.organizationId
    };
    saveOddsDifference(obj);
  };

  const onLotteryChanges = text => {
    const _ls = new Array(0);
    allOddsList.forEach(item => {
      const temp = item.filter(x => x.lotteryCode === (text ?? _selectedLottery));
      if (temp.length > 0) {
        _ls.push(temp);
      }
    });
    setOddsList(_ls);
  };

  return (
    <div className="returnAndOddsWrapper">
      <div className="operationSection mb-2">
        <div className="categorySelect">
          <Radio.Group onChange={({ target: { value: text } }) => { setlotType(text); }} value={lotType} className="squareCheckStyle">
            {
              _mainGames && _mainGames.map(item => <Radio value={item.value}>{item.displayName}</Radio>)
            }
          </Radio.Group>
        </div>
        <div className="dropdownArea">
          <div className="ms-1">赔率/退水：</div>
          <Select
            className="dropDownList"
            value={_returnOption}
            placeholder="请选择"
            onChange={val => setReturnOption(val)}
          >
            {memberGame && returnOptionList.filter(x => memberGame.find(item => x.label.includes(item))).concat(
              [{
                label: '注单限额', value: 'itemLimit'
              },
              {
                label: '单期限额', value: 'projectLimit'
              }]
            ).map(item => <Option value={item.value}>{item.label}</Option>)}
          </Select>
          <Select
            className="dropDownList shortvalue"
            value={_changeValue}
            placeholder="请选择"
            onChange={item => setChangeValue(item)}
          >
            {
  [1, 0.5, 0.1, 0.05, 0.01, 0, -0.01, -0.05, -0.1, -0.5, -1].map(val => <Option label={val} value={val} />)
            }
          </Select>
          <div className="mx-1">统一值：</div>
          <Input className="inputStyle" value={commonValue} onChange={({ target: { value: text } }) => setCommonValue(Number.isNaN(Number(text.trim())) ? commonValue : text.trim())} />
          <div className="operationButton generate mx-1" onClick={() => onClickConfirm()}>{t('CONFIRM')}</div>
          <div className="operationButton handle mx-1" onClick={() => onClickSave()}>{t('SAVE')}</div>
          <div className="operationButton find mx-1" onClick={() => onClickReset()}>{t('RESET')}</div>
          <div
            className="operationButton copy mx-1"
            onClick={() => {
              updateModalAlert({
                type: 'copyOddsDiff',
                visible: true,
                data: {
                  lotteryList,
                  orgId: _selectedItem?.organizationId,
                  toOrgId: _selectedItem?.organizationId,
                  orgLevel: 2,
                  onConfirmBtnClick: () => getOddsDiffData()
                }
              });
            }}
          >
            {t('COPY')}
          </div>
          <div className="ms-4">
            <div>保存时下线是否等量增加：</div>
            <Radio.Group onChange={({ target: { value: text } }) => setDownValue(text)} value={downValue}>
              <Radio value={1}>不增加</Radio>
              <Radio value={2}>只代理</Radio>
              <Radio value={3}>代理和会员</Radio>
            </Radio.Group>
          </div>
          <Checkbox checked={syncOdds} className="longtextCheckBox ms-1" onChange={({ target: { checked: val } }) => setsyncOdds(val ? 1 : 0)}>
            <div>赔率修改时，</div>
            <div>下级赔率保持不变</div>
          </Checkbox>
          <Select
            className="dropDownList longTextDropDown ms-auto me-1"
            showSearch
            placeholder="Select a person"
            optionFilterProp="children"
            onSearch={() => {}}
            value={_selectedItem.organizationId}
            onChange={code => onSelectChange(code)}
            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
          >
            {_dropdownList.map((item, index) => <Option value={item.organizationId}>{`${index > 0 ? '  ' : ''}${item.name}`}</Option>)}
          </Select>
        </div>
      </div>
      <div className="selectSection my-2">
        <div className="d-flex">
          {
            menuList.map(item => <div className={`templateStyle ${item.key === activeKey && 'selected'}`} onClick={() => setActiveKey(item.key)}>{item.label}</div>)
          }
        </div>
        <div>
          <Radio.Group value={_selectedLottery} onChange={({ target: { value: text } }) => { setSelectedLottery(text); onLotteryChanges(text); }} className="squareCheckStyle">
            {menuList.find(x => x.key === activeKey) && menuList.find(x => x.key === activeKey)?.children.map(item => <Radio value={item.code}>{item.lotteryName}</Radio>)}
          </Radio.Group>
          <Checkbox className="ms-5" checked={changAll} onChange={({ target: { checked: val } }) => setChangeAll(val)}>统一设置</Checkbox>
        </div>
      </div>

      <div className="OddsAndReturnWrapper">
        <div className="OddsAndReturnHeader">
          <div>种类</div>
          {
            (memberGame ?? []).map(item => (
              <div className="oddsDiffItemWrapper">
                <div>
                  {item}
                  盘
                </div>
                <div>
                  <div>赔率/赔率</div>
                  <div>退水（%）</div>
                </div>
              </div>
            ))
          }
          <div className="moneyLimitColumn">注单限额</div>
          <div className="moneyLimitColumn">单期限额</div>
          <div className="moneyLimitColumn">赔率预览</div>
        </div>
        {
          (oddsList ?? []).map((item, index) => (
            <OddsDiffItem
              key={`${index.toString()}`}
              index={index}
              updateModalAlert={updateModalAlert}
              memberGame={memberGame}
              item={item}
              setAllOddsList={setAllOddsList}
              allOddsList={allOddsList}
              changAll={changAll}
              _selectedLottery={_selectedLottery}
              setOddsList={setOddsList}
            />
          ))
        }
      </div>

      <div className="d-flex justify-content-center align-items-center py-3 w-100">
        <div className="operationButton handle mx-2" onClick={() => onClickSave()}>{t('SAVE')}</div>
        <div className="operationButton find mx-2" onClick={() => onClickReset()}>{t('RESET')}</div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ReturnAndOddsDiff);