import { useEffect, useState } from 'react';

import { Col, Row } from 'reactstrap';
import QRCode from 'react-google-qrcode';
import { Select } from 'antd';
import { connect } from 'react-redux';

import { memberActions } from '../../../redux/actions';

import './styles.scss';

const mapStateToProps = state => ({
  mfaSetting: state.memberReducers.mfaSetting,
});

const mapDispatchToProps = dispatch => ({
  GetMFASetting: () => dispatch(memberActions.GetMFASetting()),
  UpdateMFASetting: (obj, callback) => dispatch(memberActions.UpdateMFASetting(obj, callback)),
  RefreshMFA: () => dispatch(memberActions.RefreshMFA()),
});

const { Option } = Select;
const MFASetting = ({ GetMFASetting, mfaSetting, RefreshMFA, UpdateMFASetting }) => {
  const [validType, setvalidType] = useState(0);

  useEffect(() => {
    GetMFASetting();
  }, []);

  useEffect(() => {
    if (mfaSetting) {
      setvalidType(mfaSetting.validType);
    }
  }, [mfaSetting]);

  return (
    <div className="mfaSettingWrapper mx-0 bg-white px-0">
      <div className="p-3 changePasswordContentWrapper">
        <Col className="changePasswordContent px-0">
          <Row className="inputRow">
            <Col xs="1" className="m-0 p-0 inputHeader">
              MFA验证
            </Col>
            <Col className="d-flex align-items-center pe-0 py-2">
              <Select value={validType} placeholder="请选择" onChange={code => setvalidType(code)}>
                {
                [{
                  value: 0, label: '不启用'
                }, {
                  value: 1, label: '启用'
                }].map(el => <Option key={el.value} value={el.value}>{el.label}</Option>)
              }
              </Select>
            </Col>
          </Row>
          <Row className="inputRow">
            <Col xs="1" className="m-0 p-0 inputHeader">
              MFA二维码
            </Col>
            <Col className="d-flex align-items-center pe-0 py-2">
              {mfaSetting && (mfaSetting.validType === 0 ? '未启用' : <QRCode data={`https://chart.googleapis.com/chart?cht=qr&chs=300x300&chl=${mfaSetting.googleQr}`} />)}
            </Col>
          </Row>
        </Col>
      </div>
      <Row className="confirmRow">
        <div
          className="confirmButton confirm"
          onClick={() => UpdateMFASetting(
            validType
          )}
        >
          确定
        </div>
        <div className="confirmButton reset" onClick={() => RefreshMFA()}>
          更换MFA
        </div>
      </Row>
      <div className="d-flex justify-content-center text-danger py-3">
        启用或更换MFA需使用MFA应用程序进行扫码绑定。 iPhone请到AppStore应用商店中搜索下载-Google Authenticator、Android请到 主流应用商店中搜索下载-谷歌动态口令/身份验证器
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MFASetting);