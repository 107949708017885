import { StatusCodes } from 'http-status-codes';

import { SET_BACKUPALL_INFO, SET_BACKUP_INFO } from '../actionTypes';
import { apiService } from '../../../library';

import appActions from '../appActions';

const setBackUpInfo = data => ({
  type: SET_BACKUP_INFO,
  value: data
});

const setBackUpAllInfo = data => ({
  type: SET_BACKUPALL_INFO,
  value: data
});

const GetInfo = (params = {}, calllback = () => { }) => dispatch => {
  apiService.get('backup/GetInfo', new URLSearchParams(params))
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          calllback();
          return dispatch(setBackUpInfo(data.result || {}));
        }
      }
    }).catch(error => {
      calllback();
      if (error?.response?.data?.state) {
        dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'confirmation',
          data: {
            message: error.response.data.msg
          }
        }));
      }
      Promise.reject(error);
    });
};

const GetAllListInfo = (params = {}, calllback = () => { }) => dispatch => {
  apiService.get('backup/GetAllListInfo', new URLSearchParams(params))
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          calllback();
          return dispatch(setBackUpAllInfo(data.result || {}));
        }
      }
    }).catch(error => {
      calllback();
      if (error?.response?.data?.state) {
        dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'confirmation',
          data: {
            message: error.response.data.msg
          }
        }));
      }
      Promise.reject(error);
    });
};

const addBackUpTask = (data, callback = () => { }) => dispatch => {
  apiService.post('/backup/AddBackupTask', data).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      return dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
          onConfirmationClick: () => callback(),
          showCancelButton: false
        }
      }));
    }
  }).catch(error => {
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};

const DeleteTask = (taskId, callback = () => {}) => dispatch => {
  apiService.post(`/backup/DeleteTask/${taskId}`).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      return dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
          onConfirmationClick: () => callback()
        }
      }));
    }
  }).catch(error => {
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};
const PauseTask = (taskId, callback = () => { }) => dispatch => {
  apiService.post('/backup/PauseTask', {}, {
    params: {
      taskId
    }
  })
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        return dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'success',
          data: {
            message: data.msg,
            onConfirmationClick: () => callback()
          }
        }));
      }
    }).catch(error => {
      if (error?.response?.data?.state) {
        dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'confirmation',
          data: {
            message: error.response.data.msg
          }
        }));
      }
      Promise.reject(error);
    });
};
const StartTask = (taskId, callback = () => { }) => dispatch => {
  apiService.post('/backup/StartTask', {}, {
    params: {
      taskId
    }
  })
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        return dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'success',
          data: {
            message: data.msg,
            onConfirmationClick: () => callback()
          }
        }));
      }
    }).catch(error => {
      if (error?.response?.data?.state) {
        dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'confirmation',
          data: {
            message: error.response.data.msg
          }
        }));
      }
      Promise.reject(error);
    });
};

const GetDownloadUrl = (taskId, callback = () => { }) => dispatch => {
  apiService.get(`/backup/GetDownloadUrl/${taskId}`).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      return callback(data.result);
    }
  }).catch(error => {
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};


export default {
  GetAllListInfo,
  GetInfo,
  addBackUpTask,
  DeleteTask,
  GetDownloadUrl,
  PauseTask,
  StartTask
};