import { useEffect, useState } from 'react';

import { InputNumber, Select } from 'antd';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import mainGames from '../../../constants/QuickSetVariable/Games';

import { appActions, companyActions } from '../../../redux/actions';

import './styles.scss';

const mapStateToProps = state => ({
  activeLottery: state.commonReducers.activeLottery,
  oddsDiffData: state.agentReducers.oddsDiffData,
  userData: state.memberReducers.userData,
});

const mapDispatchToProps = dispatch => ({
  getOddsDifferent: (lotType, callback) => dispatch(companyActions.getOddsDifferent(lotType, callback)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  saveOddsDifference: (obj, callback) => dispatch(companyActions.saveOddsDifference(obj, callback)),
  resetOddsDiffData: () => dispatch(companyActions.resetOddsDiffData()),
});

const { Option } = Select;

const returnOptionList = [
  {
    label: 'A盘', value: 'aReturn'
  },
  {
    label: 'B盘', value: 'bReturn'
  },
  {
    label: 'C盘', value: 'cReturn'
  },
  {
    label: 'D盘', value: 'dReturn'
  },
  {
    label: '赔率差A', value: 'diffOddsA'
  },
  {
    label: '赔率差B', value: 'diffOddsB'
  },
  {
    label: '赔率差C', value: 'diffOddsC'
  },
  {
    label: '赔率差D', value: 'diffOddsD'
  }
];

const OddsDiffItem = ({ item, memberGame, allOddsList = new Map(), setAllOddsList, updateModalAlert }) => {
  const { t } = useTranslation();
  const [ownState, setOwnState] = useState(item);
  useEffect(() => {
    if (JSON.stringify(item) !== JSON.stringify(ownState)) setOwnState(item);
  }, [item]);

  const onValueChange = (text, settingCode, oddsCode, type) => {
    const ls = new Map(allOddsList).get(settingCode);
    const tempItem = ls.map(x => {
      if (type.includes('Return') || type.includes('itemLimit') || type.includes('projectLimit')) {
        if (x.settingCode === settingCode) {
          return ({
            ...x, [type]: Number.isNaN(Number(text)) ? x[type] : text, [`${type}singleChange`]: true, [`${type}multiChange`]: false
          });
        }
        return ({
          ...x
        });
      }
      if (x.settingCode === settingCode && x.oddsCode === oddsCode) {
        return ({
          ...x, [type]: Number.isNaN(Number(text)) ? x[type] : text, [`${type}singleChange`]: true, [`${type}multiChange`]: false
        });
      }
      return ({
        ...x
      });
    });
    allOddsList.set(settingCode, tempItem);
    setAllOddsList(allOddsList);

    const temp = ownState.map(x => {
      if (type.includes('Return') || type.includes('itemLimit') || type.includes('projectLimit')) {
        if (x.settingCode === settingCode) {
          return ({
            ...x, [type]: Number.isNaN(Number(text)) ? x[type] : text, [`${type}singleChange`]: true, [`${type}multiChange`]: false
          });
        }
        return ({
          ...x
        });
      }
      if (x.settingCode === settingCode && x.oddsCode === oddsCode) {
        return ({
          ...x, [type]: Number.isNaN(Number(text)) ? x[type] : text, [`${type}singleChange`]: true, [`${type}multiChange`]: false
        });
      }
      return ({
        ...x
      });
    });
    setOwnState(temp);
  };

  return (
    <div className="itemRowWrapper">
      <div className="categoryWrapper">
        <div>{ownState[0].name}</div>
        <div>
          {
            ownState.map(item => <div className="subNameWrapper">{item.subName}</div>)
          }
        </div>
      </div>
      {
        memberGame.map(panKou => (
          <div className="SingleOddsDiffWrapper">
            <div>
              {
                ownState.map(subItem => (
                  <div className="subNameWrapper">
                    <InputNumber
                      className={`${subItem[`diffOdds${panKou}singleChange`] ? 'singleChange' : ''} ${subItem[`diffOdds${panKou}multiChange`] ? 'multiChange' : ''}`}
                      value={subItem[`diffOdds${panKou}`]}
                      onChange={text => onValueChange(text, subItem.settingCode, subItem.oddsCode, `diffOdds${panKou}`)}
                    />
                    @
                    {Number(Number(subItem[`odds${panKou}`] || 0) + Number(subItem[`diffOdds${panKou}`] || 0)).toFixed(3)}
                  </div>
                ))
              }
            </div>
            <div className="singleReturnWrapper">
              <InputNumber
                min={0}
                onChange={text => onValueChange((text > -1 ? Number(text) : ownState[0][`${panKou.toLowerCase()}Return`]), ownState[0].settingCode, ownState[0].oddsCode, `${panKou.toLowerCase()}Return`)}
                className={`${ownState[0][`${panKou.toLowerCase()}ReturnsingleChange`] ? 'singleChange' : ''} ${ownState[0][`${panKou.toLowerCase()}ReturnmultiChange`] ? 'multiChange' : ''}`}
                value={
                ownState[0][`${panKou.toLowerCase()}Return`]
              }
              />
            </div>
          </div>
        ))
      }
      <div className="moneyLimitColumn">
        <InputNumber
          min={0}
          className={`${ownState[0]?.itemLimitsingleChange ? 'singleChange' : ''} ${ownState[0]?.itemLimitmultiChange ? 'multiChange' : ''}`}
          value={ownState[0].itemLimit}
          onChange={text => onValueChange((text > -1 ? Number(text) : ownState[0]?.itemLimit), ownState[0].settingCode, ownState[0].oddsCode, 'itemLimit')}
        />
      </div>
      <div className="moneyLimitColumn">
        <InputNumber
          min={0}
          className={`${ownState[0]?.projectLimitsingleChange ? 'singleChange' : ''} ${ownState[0]?.projectLimitmultiChange ? 'multiChange' : ''}`}
          value={ownState[0].projectLimit}
          onChange={text => onValueChange((text > -1 ? Number(text) : ownState[0]?.projectLimit), ownState[0].settingCode, ownState[0].oddsCode, 'projectLimit')}
        />
      </div>
      <div
        className="moneyLimitColumn"
        title="赔率预览"
        onClick={() => {
          updateModalAlert({
            type: 'previewOddDiff',
            visible: true,
            data: {
              thisData: ownState[0],
              memberGame: memberGame.join('')
            }
          });
        }}
      >
        {t('PREVIEW')}
      </div>
    </div>
  );
};

const QuickSetRow = ({ item, memberGame, setAllOddsList, allOddsList = new Map(), quickDataSet, setQuickData, setOddsList }) => {
  const [ownState, setOwnState] = useState(item);

  const quickSetValueChange = (key, property, value) => {
    const tempData = {
      ...ownState
    };
    tempData[property] = value > -1 ? Number(value) : tempData[property];
    quickDataSet[key] = {
      ...quickDataSet[key], ...tempData
    };
    setOwnState(tempData);
    setQuickData(quickDataSet);
  };

  const onClickChangeAll = key => {
    const { subCode } = quickDataSet[key];

    const changeStatus = {
      projectLimitmultiChange: true,
      itemLimitmultiChange: true
    };
    memberGame.forEach(x => {
      changeStatus[`diffOdds${x}multiChange`] = true;
      changeStatus[`${x.toLowerCase()}ReturnmultiChange`] = true;
    });
    subCode.forEach(item => {
      const ls = new Map(allOddsList).get(item) ?? [];
      const temp = ls.map(item => ({
        ...item, ...quickDataSet[key], ...changeStatus
      }));
      allOddsList.set(item, temp);
    });
    const oddLs = new Array(0);
    allOddsList.forEach(item => {
      if (item.length > 0) {
        oddLs.push(item.map(x => ({
          ...x
        })));
      }
    });
    setOddsList(oddLs);
    setAllOddsList(allOddsList);
  };

  return (
    <div className="quickSetRow">
      <div>{ownState.label}</div>
      {
      memberGame.map(pankou => (
        <div className="quickSetReturnAndOddsWrapper">
          <div>
            <InputNumber
              value={ownState[`diffOdds${pankou}`]}
              onChange={text => quickSetValueChange(ownState.value, `diffOdds${pankou}`, text)}
            />
          </div>
          <div>
            <InputNumber
              onChange={text => quickSetValueChange(ownState.value, `${pankou.toLowerCase()}Return`, text)}
              value={ownState[`${pankou.toLowerCase()}Return`]}
            />
          </div>
        </div>
      ))
    }
      <div className="moneyLimitColumn">
        <InputNumber
          onChange={text => quickSetValueChange(ownState.value, 'itemLimit', text)}
          value={ownState.itemLimit}
        />
      </div>
      <div className="moneyLimitColumn">
        <InputNumber
          onChange={text => quickSetValueChange(ownState.value, 'projectLimit', text)}
          value={ownState.projectLimit}
        />
      </div>
      <div
        title="统一修改"
        className="moneyLimitColumn"
        onClick={() => {
          onClickChangeAll(ownState.value);
        }}
      >
        修改
      </div>
    </div>
  );
};

const OddsDiff = ({
  getOddsDifferent,
  oddsDiffData,
  updateModalAlert,
  saveOddsDifference,
  activeLottery,
  userData
}) => {
  const { t } = useTranslation();
  const [memberGame, setMemberGame] = useState(['A', 'B', 'C', 'D']);
  const [oddsList, setOddsList] = useState([]);
  const [allOddsList, setAllOddsList] = useState(new Map());
  const [_selectedLottery, setSelectedLottery] = useState(activeLottery);
  const [commonValue, setCommonValue] = useState('');
  const [_returnOption, setReturnOption] = useState('');
  const [_changeValue, setChangeValue] = useState('');
  const [tempOddList, setTempOddList] = useState();
  const [quickDataSet, setQuickData] = useState();

  const resetMode = () => {
    setMemberGame(['A', 'B', 'C', 'D']);
    setAllOddsList(new Map());
    setOddsList([]);
    setCommonValue('');
    setReturnOption('');
    setChangeValue('');
    setTempOddList();
    setQuickData();
  };

  useEffect(() => {
    resetMode();
    setSelectedLottery(activeLottery);
  }, [activeLottery]);

  useEffect(() => {
    getOddsDifferent(_selectedLottery.code);
  }, [_selectedLottery]);

  useEffect(() => {
    if (oddsDiffData) {
      if (JSON.stringify(memberGame) !== JSON.stringify(oddsDiffData.memberGame.split(''))) {
        setMemberGame(oddsDiffData.memberGame.split(''));
      }
      const newMap = new Map();
      const oddList = [];

      const temp = oddsDiffData.oddsList.reduce((group, item) => {
        const { settingCode } = item;
        group[settingCode] = group[settingCode] ?? [];
        group[settingCode].push(item);
        return group;
      }, {});
      setTempOddList(temp);

      oddsDiffData.oddsList.forEach(item => {
        if (newMap.has(item.settingCode)) {
          const temp = newMap.get(item.settingCode).concat({
            ...item
          });
          newMap.set(item.settingCode, temp);
        } else {
          newMap.set(item.settingCode, [{
            ...item
          }]);
        }
      });
      newMap.forEach(item => {
        oddList.push(item);
      });
      setOddsList(oddList);
      setAllOddsList(newMap);
    }
  }, [oddsDiffData]);

  useEffect(() => {
    if (tempOddList && _selectedLottery) {
      const tempPresetData = {};
      const quickData = {};
      const { data } = mainGames.Games.find(x => x.code.includes(_selectedLottery.templateCode));
      data.forEach(x => {
        x.subCode.forEach(y => {
          if (tempOddList[y]) {
            tempPresetData[x.value] = {
              ...x, list: tempPresetData[x.value] ? tempPresetData[x.value].list.concat(tempOddList[y]) : [].concat(tempOddList[y])
            };
          }
        });
      });

      Object.entries(tempPresetData).forEach(([key, value]) => {
        const obj = {
          itemLimit: 0, projectLimit: 0
        };
        memberGame.forEach(x => {
          obj[`${x.toLowerCase()}Return`] = 0;
          obj[`diffOdds${x}`] = 0;
        });
        value.list.forEach(x => {
          Object.keys(obj).forEach(property => {
            obj[property] = obj[property] >= x[property] ? obj[property] : x[property];
          });
        });
        quickData[key] = {
          label: value.label, subCode: value.subCode, value: value.value, ...obj
        };
      });
      setQuickData(quickData);
    }
  }, [tempOddList, _selectedLottery]);

  const onClickConfirm = () => {
    if (_returnOption && (commonValue !== '' || _changeValue !== '')) {
      const temp = new Map();
      allOddsList.forEach((x = [], key) => {
        const _ls = x.map(x => {
          if (_returnOption.includes('Return') || _returnOption.includes('itemLimit') || _returnOption.includes('projectLimit')) {
            return ({
              ...x, [_returnOption]: commonValue !== '' ? Number(commonValue) + Number(_changeValue) : (Number(_changeValue) + Number(x[_returnOption])), [`${_returnOption}singleChange`]: true, [`${_returnOption}multiChange`]: false
            });
          }
          return ({
            ...x, [_returnOption]: commonValue !== '' ? Number(commonValue) + Number(_changeValue) : (Number(_changeValue) + Number(x[_returnOption])), [`${_returnOption}singleChange`]: true, [`${_returnOption}multiChange`]: false
          });
        });
        temp.set(key, _ls);
      });

      const oddLs = new Array(0);
      temp.forEach(item => {
        if (item.length > 0) {
          oddLs.push(item.map(x => ({
            ...x
          })));
        }
      });
      setOddsList(oddLs);
      setAllOddsList(temp);
    }
  };

  const onClickReset = () => {
    resetMode();
    getOddsDifferent(_selectedLottery.code);
  };

  const onClickSave = () => {
    let temp = new Array(0);
    allOddsList.forEach(item => {
      const _ls = item.filter(x => Object.keys(x).find(a => a.includes('singleChange')) || Object.keys(x).find(a => a.includes('multiChange')));
      if (_ls.length > 0) {
        temp = temp.concat(_ls);
      }
    });

    const obj = {
      lotteryType: _selectedLottery.code,
      setting: temp,
      orgId: userData.id
    };
    saveOddsDifference(obj);
  };

  return (
    <div className="CompanyOddsDiffWrapper">
      <div className="operationSection mb-2">
        <div className="dropdownArea py-2">
          <div className="ms-1">退水：</div>
          <Select
            className="dropDownList"
            value={_returnOption}
            placeholder="请选择"
            onChange={val => setReturnOption(val)}
          >
            {returnOptionList.filter(x => memberGame.find(item => x.label.includes(item))).concat(
              [{
                label: '注单限额', value: 'itemLimit'
              },
              {
                label: '单期限额', value: 'projectLimit'
              }]
            ).map(item => <Option value={item.value}>{item.label}</Option>)}
          </Select>
          <Select
            className="dropDownList shortvalue"
            value={_changeValue}
            placeholder="请选择"
            onChange={item => setChangeValue(item)}
          >
            {
              [1, 0.5, 0.1, 0.05, 0.01, 0, -0.01, -0.05, -0.1, -0.5, -1].map(value => <Option key={value} label={value} value={value} />)
            }
          </Select>
          <div className="mx-1">统一值：</div>
          <InputNumber className="inputStyle" value={commonValue} onChange={text => setCommonValue(text > -1 ? Number(text) : commonValue)} />
          <div className="operationButton generate mx-1" onClick={() => onClickConfirm()}>{t('CONFIRM')}</div>
          <div className="operationButton handle mx-1" onClick={() => onClickSave()}>{t('SAVE')}</div>
          <div className="operationButton find mx-1" onClick={() => onClickReset()}>{t('RESET')}</div>
        </div>
      </div>

      <div className="quickSettingSection my-3">
        <div className="OddsAndReturnHeader">
          <div>种类</div>
          {
            (memberGame ?? []).map(item => (
              <div className="oddsDiffItemWrapper">
                <div>
                  {item}
                  盘
                </div>
                <div>
                  <div>赔率差</div>
                  <div>退水（%）</div>
                </div>
              </div>
            ))
          }
          <div className="moneyLimitColumn">注单限额</div>
          <div className="moneyLimitColumn">单期限额</div>
          <div className="moneyLimitColumn">操作</div>
        </div>
        {
          quickDataSet && Object.values(quickDataSet).map(item => (
            <QuickSetRow
              item={item}
              memberGame={memberGame}
              setAllOddsList={setAllOddsList}
              allOddsList={allOddsList}
              quickDataSet={quickDataSet}
              setQuickData={setQuickData}
              setOddsList={setOddsList}
            />
          ))
        }
      </div>

      <div className="OddsAndReturnWrapper">
        <div className="OddsAndReturnHeader">
          <div>种类</div>
          {
            (memberGame ?? []).map(item => (
              <div className="oddsDiffItemWrapper">
                <div>
                  {item}
                  盘
                </div>
                <div>
                  <div>赔率差/赔率</div>
                  <div>退水（%）</div>
                </div>
              </div>
            ))
          }
          <div className="moneyLimitColumn">注单限额</div>
          <div className="moneyLimitColumn">单期限额</div>
          <div className="moneyLimitColumn">赔率预览</div>
        </div>
        {
          (oddsList ?? []).map((item, index) => (
            <OddsDiffItem key={`${index.toString()}`} index={index} updateModalAlert={updateModalAlert} memberGame={memberGame} item={item} setAllOddsList={setAllOddsList} allOddsList={allOddsList} />
          ))
        }
      </div>

      <div className="d-flex justify-content-center align-items-center py-3 w-100">
        <div className="operationButton handle mx-2" onClick={() => onClickSave()}>{t('SAVE')}</div>
        <div className="operationButton find mx-2" onClick={() => onClickReset()}>{t('RESET')}</div>
      </div>

    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(OddsDiff);