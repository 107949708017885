import { StatusCodes } from 'http-status-codes';

import { SET_BANK_CARDS_LIST, SET_CHANGE_LIST, SET_COMPANY_LIST, SET_EDIT_BANK_SETTING, SET_EDIT_EMAIL_SETTING, SET_EDIT_EXCHANGE_RATE, SET_EDIT_LOTTERY_ITEM, SET_EDIT_LOTTERY_TYPE_INFO, SET_EDIT_OFFLINE_SETTING, SET_EDIT_PAY_ACC, SET_EDIT_ROLE_INFO, SET_EDIT_SMS_SETTING, SET_EDIT_SYS_SERVICE_INFO, SET_EDIT_THIRD_PAY_SETTING, SET_EDIT_WITHDRAW_SETTING, SET_EMAIL_SETTING, SET_EXCHANGE_RATE_LIST, SET_EXTERNAL_WEB_INFO, SET_LOTTERY_ADDRESS_LIST, SET_LOTTERY_EDIT_ADDRESS, SET_LOTTERY_ITEMS, SET_LOTTERY_TYPE_INFO, SET_MEMBER_AMOUNT_HISTORY, SET_MEMBER_AUTO_CLASSIFY, SET_MEMBER_DAY_REPORT, SET_MENU_INFO, SET_MODAL_ALERT, SET_OPEN_RESULT_SETTING_LIST, SET_OTP_LIST, SET_PAYMENT_ACCOUNT_SETTING, SET_PAYMENT_OFFLINE_SETTING, SET_ROLE_INFO, SET_SMS_SETTING, SET_SUB_ACCOUNT_LIST, SET_SYSTEM_SETTING_INFO, SET_SYS_CONFIG_INFO, SET_SYS_SERVICE_INFO, SET_THIRD_PAY_SETTING, SET_WITHDRAW_SETTING } from '../actionTypes';
import { apiService } from '../../../library';
import appActions from '../appActions';

const setCompanyList = data => ({
  type: SET_COMPANY_LIST,
  value: data,
});

const setSubAccountList = data => ({
  type: SET_SUB_ACCOUNT_LIST,
  value: data,
});

const setRoleInfo = data => ({
  type: SET_ROLE_INFO,
  value: data,
});

const setLotteryTypeInfo = data => ({
  type: SET_LOTTERY_TYPE_INFO,
  value: data,
});

const setEditLotteryTypeInfo = data => ({
  type: SET_EDIT_LOTTERY_TYPE_INFO,
  value: data,
});

const setMenuInfo = data => ({
  type: SET_MENU_INFO,
  value: data,
});

const setEditRoleInfo = data => ({
  type: SET_EDIT_ROLE_INFO,
  value: data,
});

const setModalAlert = modal => ({
  type: SET_MODAL_ALERT,
  value: modal
});

const setSystemSettingInfo = data => ({
  type: SET_SYSTEM_SETTING_INFO,
  value: data,
});

const setLotteryItems = data => ({
  type: SET_LOTTERY_ITEMS,
  value: data,
});

const setEditLotteryItem = data => ({
  type: SET_EDIT_LOTTERY_ITEM,
  value: data,
});

const setPaymentAccountSetting = data => ({
  type: SET_PAYMENT_ACCOUNT_SETTING,
  value: data,
});

const setBankCardsList = data => ({
  type: SET_BANK_CARDS_LIST,
  value: data,
});

const setEditPayAcc = data => ({
  type: SET_EDIT_PAY_ACC,
  value: data,
});

const setExternalWebInfo = data => ({
  type: SET_EXTERNAL_WEB_INFO,
  value: data,
});

const setThirdPaySetting = data => ({
  type: SET_THIRD_PAY_SETTING,
  value: data,
});

const setEditThirdPaySetting = data => ({
  type: SET_EDIT_THIRD_PAY_SETTING,
  value: data,
});

const setMemberAutoClassify = data => ({
  type: SET_MEMBER_AUTO_CLASSIFY,
  value: data,
});

const setPaymentOfflineSetting = data => ({
  type: SET_PAYMENT_OFFLINE_SETTING,
  value: data,
});

const setEditOfflineSetting = data => ({
  type: SET_EDIT_OFFLINE_SETTING,
  value: data,
});

const setSMSSetting = data => ({
  type: SET_SMS_SETTING,
  value: data,
});

const setEmailSetting = data => ({
  type: SET_EMAIL_SETTING,
  value: data,
});

const setEditSMSSetting = data => ({
  type: SET_EDIT_SMS_SETTING,
  value: data,
});

const setEditEmailSetting = data => ({
  type: SET_EDIT_EMAIL_SETTING,
  value: data,
});

const setWithdrawSetting = data => ({
  type: SET_WITHDRAW_SETTING,
  value: data,
});

const setEditWithdrawSetting = data => ({
  type: SET_EDIT_WITHDRAW_SETTING,
  value: data,
});

const setOTPList = data => ({
  type: SET_OTP_LIST,
  value: data,
});

const setExchangeRateList = data => ({
  type: SET_EXCHANGE_RATE_LIST,
  value: data,
});

const setEditExchangeRate = data => ({
  type: SET_EDIT_EXCHANGE_RATE,
  value: data,
});

const setEditBankSetting = data => ({
  type: SET_EDIT_BANK_SETTING,
  value: data,
});

const setOpenResultSetting = data => ({
  type: SET_OPEN_RESULT_SETTING_LIST,
  value: data,
});

const setLotteryAddressList = data => ({
  type: SET_LOTTERY_ADDRESS_LIST,
  value: data,
});

const setLotteryEditAddress = data => ({
  type: SET_LOTTERY_EDIT_ADDRESS,
  value: data,
});

const setEditSysServiceInfo = data => ({
  type: SET_EDIT_SYS_SERVICE_INFO,
  value: data,
});

const setSysServiceInfo = data => ({
  type: SET_SYS_SERVICE_INFO,
  value: data,
});

const setSysConfigInfo = data => ({
  type: SET_SYS_CONFIG_INFO,
  value: data,
});

const setMemberDayReport = data => ({
  type: SET_MEMBER_DAY_REPORT,
  value: data,
});

const setChangeList = data => ({
  type: SET_CHANGE_LIST,
  value: data,
});

const setMemberAmountHistory = data => ({
  type: SET_MEMBER_AMOUNT_HISTORY,
  value: data,
});

const GetBuhuoBetList = (obj, calllback = () => { }) => dispatch => {
  apiService.get('/system/GetBuhuoBetList', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      return calllback(data.result);
    }
  }).catch(error => {
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};

const EditExternalWeb = (obj, calllback = () => { }) => dispatch => {
  apiService.get('/system/EditExternalWeb', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      return calllback(data.result);
    }
  }).catch(error => {
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};


const GetChangeList = (obj, calllback = () => { }) => dispatch => {
  apiService.get('/system/GetChangeList', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      calllback();
      dispatch(setChangeList(data.result));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    calllback();
    Promise.reject(error);
  });
};
const BatchRepair = (data, callback = () => { }) => dispatch => {
  apiService.post('system/BatchRepair', data).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      callback();
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
    }
  }).catch(error => {
    // callback();
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error.response.data.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};

const SaveLotteryStyle = (obj, callback = () => { }) => dispatch => {
  apiService.post('system/SaveLotteryStyle', obj).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      return dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
          onConfirmationClick: () => callback()
        }
      }));
    }
  }).catch(error => {
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};


const GetLotteryStyle = (calllback = () => { }) => dispatch => {
  apiService.get('/system/GetLotteryStyle').then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      return calllback(data.result);
    }
  }).catch(error => {
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};

const GenerateCheckBills = (data, callback = () => { }) => dispatch => {
  apiService.post('system/GenerateCheckBills', data).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      callback();
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
    }
  }).catch(error => {
    callback();
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error.response.data.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};

const GetMemberAmountHistory = (obj, calllback = () => { }, catchback = () => { }) => dispatch => {
  apiService.get('/system/GetMemberAmountHistory', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      calllback();
      dispatch(setMemberAmountHistory(data.result));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    catchback();
    Promise.reject(error);
  });
};

const GetMemberDailyReport = (obj, calllback = () => { }) => dispatch => {
  apiService.get('/system/GetMemberDailyReport', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      calllback();
      dispatch(setMemberDayReport(data.result));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    calllback();
    Promise.reject(error);
  });
};

const getExternalWeb = (obj, calllback = () => { }) => dispatch => {
  apiService.get('/system/GetExternalWeb', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      calllback(false);
      dispatch(setExternalWebInfo(data.result));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    calllback(false);
    Promise.reject(error);
  });
};

const getEditPayAccount = (obj, calllback = () => { }) => dispatch => {
  apiService.get('/system/EditPayAccount', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      calllback(false);
      dispatch(setEditPayAcc(data.result));
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const getInfo = () => dispatch => {
  apiService.get('/system/getInfo').then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      dispatch(setSystemSettingInfo(data.result));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};

const getLotteryItems = (orgId = '', calllback = () => { }) => dispatch => {
  apiService.get('/system/LotteryItems', new URLSearchParams({
    orgId
  })).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      calllback(false);
      dispatch(setLotteryItems(data.result));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    calllback(false);
    Promise.reject(error);
  });
};

const getPayAccountSetting = (obj, calllback = () => { }) => dispatch => {
  apiService.get('/system/PayAccountSetting', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      calllback(false);
      dispatch(setPaymentAccountSetting(data.result));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    calllback(false);
    Promise.reject(error);
  });
};

const getEditLotteryItem = (obj, calllback = () => { }) => dispatch => {
  apiService.get('/system/EditLotteryItem', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      calllback(false);
      dispatch(setEditLotteryItem(data.result));
    }
  }).catch(error => {
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    calllback(false);
    Promise.reject(error);
  });
};

const cancelBetting = (lotteryType, installments, status, ids, onClick) => dispatch => {
  apiService.post('/system/cancelBetting', {
    lotteryType,
    installments,
    status,
    ids
  }).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      return dispatch(setModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
          onConfirmationClick: () => {
            onClick();
          },
        }
      }));
    }
    if (status === StatusCodes.BAD_REQUEST) {
      return dispatch(setModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
    }
  }).catch(error => {
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};

const updateExternalWebStatus = (id = '') => dispatch => {
  apiService.post('system/UpdateExternalWebStatus', id).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
      return dispatch(getExternalWeb({
        page: 1,
        rows: 15
      }));
    }
    if (status === StatusCodes.BAD_REQUEST) {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      return dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error.response.data.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};


const setPayAccountStatus = (id = '') => dispatch => {
  apiService.post('system/setPayAccountStatus', id).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
      return dispatch(getPayAccountSetting({
        page: 1,
        rows: 15
      }));
    }
    if (status === StatusCodes.BAD_REQUEST) {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      return dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error.response.data.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};


const deletePayAccount = (id = '') => dispatch => {
  apiService.post('system/DeletePayAccount', id).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
      return dispatch(getPayAccountSetting({
        page: 1,
        rows: 15
      }));
    }
    if (status === StatusCodes.BAD_REQUEST) {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      return dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error.response.data.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};

const cancelBettingByInstallNo = (lotteryType, installments, status, onClick) => dispatch => {
  apiService.post('/system/cancelBettingByInstallNo', {
    lotteryType,
    installments,
    status,
  }).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      dispatch(setModalAlert({
        visible: false,
        type: 'loading',
        data: {
          message: data.msg
        }
      }));
      return dispatch(setModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
          onConfirmationClick: () => {
            onClick();
          },
        }
      }));
    }
    if (status === StatusCodes.BAD_REQUEST) {
      dispatch(setModalAlert({
        visible: false,
        type: 'loading',
        data: {
          message: data.msg,
        }
      }));
      return dispatch(setModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
    }
  }).catch(error => {
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};


const saveInfo = (obj = {}, callback = () => { }) => dispatch => {
  apiService.post('system/SaveInfo', {
    ...obj
  })
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          dispatch(appActions.updateModalAlert({
            visible: false
          }));
          dispatch(appActions.updateModalAlert({
            visible: true,
            type: 'success',
            data: {
              message: data.msg,
            }
          }));
          callback(false);
          return dispatch(getInfo());
        }
      }
    }).catch(error => {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      callback(false);
      if (error.response.data.state) {
        dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'confirmation',
          data: {
            message: error.response.data.msg
          }
        }));
      }
      Promise.reject(error);
    });
};

const GetBankCardList = (obj, calllback = () => { }) => dispatch => {
  apiService.get('/system/GetBankCardList', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      calllback(false);
      dispatch(setBankCardsList(data.result));
    }
  }).catch(error => {
    calllback(false);
    dispatch(setBankCardsList());
    Promise.reject(error);
  });
};

const savePayAccount = (obj = {}, callback = () => { }) => dispatch => {
  apiService.post('system/SavePayAccount', {
    ...obj
  })
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          dispatch(appActions.updateModalAlert({
            visible: true,
            type: 'success',
            data: {
              message: data.msg,
            }
          }));
          callback(false);
          if (obj?.id) {
            return dispatch(getEditPayAccount(obj?.id || ''));
          }
        }
      }
    }).catch(error => {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      callback(false);
      if (error.response.data.state) {
        dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'confirmation',
          data: {
            message: error.response.data.msg
          }
        }));
      }
      Promise.reject(error);
    });
};

const saveLotteryItem = (obj = {}, callback = () => { }) => dispatch => {
  apiService.post('system/SaveLotteryItem', {
    ...obj
  })
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          dispatch(appActions.updateModalAlert({
            visible: true,
            type: 'success',
            data: {
              message: data.msg,
            }
          }));
          return callback(false);
        }
      }
    }).catch(error => {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      callback(false);
      if (error.response.data.state) {
        dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'confirmation',
          data: {
            message: error.response.data.msg
          }
        }));
      }
      Promise.reject(error);
    });
};


const updateLotteryGameStatus = (obj = {}, callback = () => {}) => dispatch => {
  apiService.post('system/UpdateLotteryGameStatus', obj)
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          callback();
          return dispatch(appActions.updateModalAlert({
            visible: true,
            type: 'success',
            data: {
              message: data.msg,
            }
          }));
        }
      }
    }).catch(error => {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      if (error.response.data.state) {
        dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'confirmation',
          data: {
            message: error.response.data.msg
          }
        }));
      }
      Promise.reject(error);
    });
};


const updateLotteryOrder = (obj = {}) => dispatch => {
  apiService.post('system/UpdateLotteryOrder', obj)
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          dispatch(appActions.updateModalAlert({
            visible: false
          }));
          dispatch(appActions.updateModalAlert({
            visible: true,
            type: 'success',
            data: {
              message: data.msg,
            }
          }));
          return dispatch(getLotteryItems());
        }
      }
    }).catch(error => {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      if (error.response.data.state) {
        dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'confirmation',
          data: {
            message: error.response.data.msg
          }
        }));
      }
      Promise.reject(error);
    });
};

const saveExternalWeb = (obj = {}, callback = () => { }) => dispatch => {
  apiService.post('system/SaveExternalWeb', {
    ...obj
  })
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          dispatch(appActions.updateModalAlert({
            visible: false
          }));
          dispatch(appActions.updateModalAlert({
            visible: true,
            type: 'success',
            data: {
              message: data.msg,
            }
          }));
          callback(false);
          return dispatch(getInfo());
        }
      }
    }).catch(error => {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      if (error.response.data.state) {
        dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'confirmation',
          data: {
            message: error.response.data.msg
          }
        }));
      }
      Promise.reject(error);
    });
};


const deleteExternalWeb = (id = '') => dispatch => {
  apiService.post('system/deleteExternalWeb', id).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
      return dispatch(getExternalWeb({
        page: 1,
        rows: 15
      }));
    }
    if (status === StatusCodes.BAD_REQUEST) {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      return dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};

const getThirdPartyPaySetting = (obj, calllback = () => { }) => dispatch => {
  apiService.get('/system/ThirdPartyPaySetting', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      calllback(false);
      dispatch(setThirdPaySetting(data.result));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    calllback(false);
    Promise.reject(error);
  });
};

const getEditThirdPaySetting = (obj, calllback = () => { }) => dispatch => {
  apiService.get('/system/EditThirdPartyPaySetting', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      calllback(false);
      dispatch(setEditThirdPaySetting(data.result));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    calllback(false);
    Promise.reject(error);
  });
};


const saveThirdPartyPaySetting = (obj = {}, callback = () => { }) => dispatch => {
  apiService.post('system/SaveThirdPartyPaySetting', {
    ...obj
  })
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          dispatch(appActions.updateModalAlert({
            visible: false
          }));
          dispatch(appActions.updateModalAlert({
            visible: true,
            type: 'success',
            data: {
              message: data.msg,
            }
          }));
          callback(false);
          return dispatch(getEditThirdPaySetting({
            id: obj?.id || ''
          }));
        }
      }
    }).catch(error => {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      if (error?.response?.data?.state) {
        dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'confirmation',
          data: {
            message: error.response.data.msg
          }
        }));
      }
      Promise.reject(error);
    });
};
const copyThirdPartyPaySetting = (id = '') => dispatch => {
  apiService.post('system/CopyThirdPartyPaySetting', id).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
      return dispatch(getThirdPartyPaySetting({
        page: 1,
        rows: 15
      }));
    }
    if (status === StatusCodes.BAD_REQUEST) {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      return dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};

const updateThirdPartyPaySorting = (obj = {}, callback = () => { }) => dispatch => {
  apiService.post('system/UpdateThirdPartyPaySorting', {
    ...obj
  }).then(res => {
    const { status } = res || {};

    if (status === StatusCodes.OK) {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      return callback(true);
    }
    if (status === StatusCodes.BAD_REQUEST) {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};

const setThirdPartyPayStatus = (obj = {}, callback = () => { }) => dispatch => {
  apiService.post('system/setThirdPartyPayStatus', {
    ...obj
  }).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
      return callback(true);
    }
    if (status === StatusCodes.BAD_REQUEST) {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};

const getMemberAutoClassify = (obj, calllback = () => { }) => dispatch => {
  apiService.get('/system/MemberAutoClassification', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      calllback(false);
      dispatch(setMemberAutoClassify(data.result));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    calllback(false);
    Promise.reject(error);
  });
};


const deleteMemberClassification = (id = '', callback = () => { }) => dispatch => {
  apiService.post('system/DeleteMemberClassification', id).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
      callback(true);
      return dispatch(getMemberAutoClassify());
    }
    if (status === StatusCodes.BAD_REQUEST) {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      return dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};

const saveMemberClassification = (obj = {}, callback = () => { }) => dispatch => {
  apiService.post('system/SaveMemberClassification', {
    ...obj
  }).then(res => {
    const { status } = res || {};

    if (status === StatusCodes.OK) {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      return callback(true);
    }
    if (status === StatusCodes.BAD_REQUEST) {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};

const getOfflineSetting = (obj, calllback = () => { }) => dispatch => {
  apiService.get('/system/GetOfflineSetting', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      calllback(false);
      dispatch(setPaymentOfflineSetting(data.result));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    calllback(false);
    Promise.reject(error);
  });
};

const setOfflineStatus = (id = '', callback = () => { }) => dispatch => {
  apiService.post('system/setOfflineStatus', id).then(res => {
    const { status } = res || {};

    if (status === StatusCodes.OK) {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      return callback(true);
    }
    if (status === StatusCodes.BAD_REQUEST) {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};


const getEditOfflineSetting = (obj, calllback = () => { }) => dispatch => {
  apiService.get('/system/EditOfflineSetting', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      calllback(false);
      dispatch(setEditOfflineSetting(data.result));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    calllback(false);
    Promise.reject(error);
  });
};

const saveOfflineSetting = (obj = {}, callback = () => { }) => dispatch => {
  apiService.post('system/SaveOfflineSetting', {
    ...obj
  }).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
      getEditOfflineSetting();
      return callback(true);
    }
    if (status === StatusCodes.BAD_REQUEST) {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};

const getSMSSetting = (obj, calllback = () => { }) => dispatch => {
  apiService.get('/system/GetSMSSetting', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      calllback(false);
      dispatch(setSMSSetting(data.result));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    calllback(false);
    Promise.reject(error);
  });
};

const getEmailSetting = (obj, calllback = () => { }) => dispatch => {
  apiService.get('/system/GetEmailSetting', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      calllback(false);
      dispatch(setEmailSetting(data.result));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    calllback(false);
    Promise.reject(error);
  });
};

const getEditSMSSetting = (obj, calllback = () => { }) => dispatch => {
  apiService.get('/system/EditSMSSetting', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      calllback(false);
      dispatch(setEditSMSSetting(data.result));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    calllback(false);
    Promise.reject(error);
  });
};

const getEditEmailSetting = (obj, calllback = () => { }) => dispatch => {
  apiService.get('/system/EditEmailSetting', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      calllback(false);
      dispatch(setEditEmailSetting(data.result));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    calllback(false);
    Promise.reject(error);
  });
};

const setSMSStatus = (id = '', callback = () => { }) => dispatch => {
  apiService.post('system/SetSMSStatus', id).then(res => {
    const { status } = res || {};

    if (status === StatusCodes.OK) {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      return callback(true);
    }
    if (status === StatusCodes.BAD_REQUEST) {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};

const setEmailStatus = (id = '', callback = () => { }) => dispatch => {
  apiService.post('system/SetEmailStatus', id).then(res => {
    const { status } = res || {};

    if (status === StatusCodes.OK) {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      return callback(true);
    }
    if (status === StatusCodes.BAD_REQUEST) {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};

const deleteEmailSetting = (id = '', callback = () => { }) => dispatch => {
  apiService.post('system/DeleteEmailSetting', id).then(res => {
    const { status } = res || {};

    if (status === StatusCodes.OK) {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      return callback(true);
    }
    if (status === StatusCodes.BAD_REQUEST) {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};

const deleteSMSSetting = (id = '', callback = () => { }) => dispatch => {
  apiService.post('system/DeleteSMSSetting', id).then(res => {
    const { status } = res || {};

    if (status === StatusCodes.OK) {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      return callback(true);
    }
    if (status === StatusCodes.BAD_REQUEST) {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};

const saveSMSSetting = (obj = {}, callback = () => { }) => dispatch => {
  apiService.post('system/SaveSMSSetting', {
    ...obj
  }).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
      getSMSSetting({
        page: 1, rows: 30
      });
      return callback(true);
    }
    if (status === StatusCodes.BAD_REQUEST) {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};

const saveEmailSetting = (obj = {}, callback = () => { }) => dispatch => {
  apiService.post('system/SaveEmailSetting', {
    ...obj
  }).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
      getEmailSetting({
        page: 1, rows: 30
      });
      return callback(true);
    }
    if (status === StatusCodes.BAD_REQUEST) {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};

const getWithdrawSetting = (obj, calllback = () => { }) => dispatch => {
  apiService.get('/system/GetWithdrawSetting', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      calllback(false);
      dispatch(setWithdrawSetting(data.result));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    calllback(false);
    Promise.reject(error);
  });
};

const setWithdrawStatus = (id = '', callback = () => { }) => dispatch => {
  apiService.post('system/SetWithdrawStatus', id).then(res => {
    const { status } = res || {};

    if (status === StatusCodes.OK) {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      return callback(true);
    }
    if (status === StatusCodes.BAD_REQUEST) {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};


const getEditWithdrawSetting = (obj, calllback = () => { }) => dispatch => {
  apiService.get('/system/EditWithdrawSetting', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      calllback(false);
      dispatch(setEditWithdrawSetting(data.result));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    calllback(false);
    Promise.reject(error);
  });
};

const saveWithdrawSetting = (obj = {}, callback = () => { }) => dispatch => {
  apiService.post('system/SaveWithdrawSetting', {
    ...obj
  }).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
      callback(true);
      if (obj?.id) {
        return dispatch(getEditWithdrawSetting({
          id: obj?.id || ''
        }));
      }
    }
    if (status === StatusCodes.BAD_REQUEST) {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};


const getOTPList = (obj, calllback = () => { }) => dispatch => {
  apiService.get('/system/GetOTPList', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      calllback(false);
      dispatch(setOTPList(data.result));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    calllback(false);
    Promise.reject(error);
  });
};


const getExRateList = (obj, calllback = () => { }) => dispatch => {
  apiService.get('/system/GetExRateList', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      calllback(false);
      dispatch(setExchangeRateList(data.result));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    calllback(false);
    Promise.reject(error);
  });
};


const saveExrate = (obj = {}, callback = () => { }) => dispatch => {
  apiService.post('system/SaveExrate', {
    ...obj
  }).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
      getExRateList({
        page: 1, rows: 15
      });
      return callback(true);
    }
    if (status === StatusCodes.BAD_REQUEST) {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};
const toggleExrateIsAuto = (id = '', callback = () => { }) => dispatch => {
  apiService.post('system/ToggleExrateIsAuto', id).then(res => {
    const { status } = res || {};

    if (status === StatusCodes.OK) {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      return callback(true);
    }
    if (status === StatusCodes.BAD_REQUEST) {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};
const toggleExrateIsDel = (id = '', callback = () => { }) => dispatch => {
  apiService.post('system/ToggleExrateIsDel', id).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
      callback(true);
      return dispatch(getExRateList());
    }
    if (status === StatusCodes.BAD_REQUEST) {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      return dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};
const getEditExRate = (obj, calllback = () => { }) => dispatch => {
  apiService.get('/system/EditExRate', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      calllback(false);
      dispatch(setEditExchangeRate(data.result));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    calllback(false);
    Promise.reject(error);
  });
};

const getEditBankSetting = (obj, calllback = () => { }) => dispatch => {
  apiService.get('/system/EditBankSetting', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      calllback(false);
      dispatch(setEditBankSetting(data.result));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    calllback(false);
    Promise.reject(error);
  });
};

const deleteBankSetting = (id = '', callback = () => { }) => dispatch => {
  apiService.post('system/DeleteBankSetting', id).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
      callback(true);
      return dispatch(GetBankCardList());
    }
    if (status === StatusCodes.BAD_REQUEST) {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      return dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};

const updateBankSettingSorting = (obj = {}, callback = () => { }) => dispatch => {
  apiService.post('system/UpdateBankSettingSorting', {
    ...obj
  }).then(res => {
    const { status } = res || {};

    if (status === StatusCodes.OK) {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      return callback(true);
    }
    if (status === StatusCodes.BAD_REQUEST) {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};


const saveBankSetting = (obj = {}, callback = () => { }) => dispatch => {
  apiService.post('system/SaveBankSetting', {
    ...obj
  }).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
      GetBankCardList();
      return callback(true);
    }
    if (status === StatusCodes.BAD_REQUEST) {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};


const getLotteryResultSetting = (calllback = () => { }) => dispatch => {
  apiService.get('/system/GetLotteryResultSetting').then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      calllback(false);
      dispatch(setOpenResultSetting(data.result));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    calllback(false);
    Promise.reject(error);
  });
};

const saveLotteryResultSetting = (obj = {}, callback = () => { }) => dispatch => {
  apiService.post('system/SaveLotteryResultSetting', obj).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
      return callback(true);
    }
    if (status === StatusCodes.BAD_REQUEST) {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};

const GetDefaultLotteryDrawSite = (calllback = () => { }) => dispatch => {
  apiService.get('/system/GetDefaultLotteryDrawSite').then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      calllback(false);
      dispatch(setOpenResultSetting(data.result));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    calllback(false);
    Promise.reject(error);
  });
};

const SaveDefaultLotteryDrawSites = (obj = {}, callback = () => { }) => dispatch => {
  apiService.post('system/SaveDefaultLotteryDrawSites', obj).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
      return callback(true);
    }
    if (status === StatusCodes.BAD_REQUEST) {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};

const getLotteryAddressList = (obj, calllback = () => { }) => dispatch => {
  apiService.get('/system/GetLotteryAddressList', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      calllback();
      dispatch(setLotteryAddressList(data.result));
    }
  }).catch(error => {
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};

const editLotteryAddress = (id, calllback = () => { }) => dispatch => {
  apiService.get('/system/EditLotteryAddress', new URLSearchParams({
    id
  })).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      calllback();
      dispatch(setLotteryEditAddress(data.result));
    }
  }).catch(error => {
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};

const deleteLotteryAddress = (id, calllback = () => { }) => dispatch => {
  apiService.post('/system/DeleteLotteryAddress', id).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      calllback();
      dispatch(setModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
    }
  }).catch(error => {
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};

const SaveLotteryAddress = (obj, calllback = () => { }) => dispatch => {
  apiService.post('/system/SaveLotteryAddress', obj).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      calllback();
      dispatch(setModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
    }
  }).catch(error => {
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};


const GetSysServiceInfo = (obj, calllback = () => { }) => dispatch => {
  apiService.get('/system/GetSysServiceInfo', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      calllback();
      dispatch(setSysServiceInfo(data.result));
    }
  }).catch(error => {
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};

const EditSysServiceInfo = (id, calllback = () => { }) => dispatch => {
  apiService.get('/system/EditSysServiceInfo', new URLSearchParams({
    id
  })).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      calllback();
      dispatch(setEditSysServiceInfo(data.result));
    }
  }).catch(error => {
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};

const DeleteSysServiceInfo = (id, calllback = () => { }) => dispatch => {
  apiService.post('/system/DeleteSysServiceInfo', id).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      calllback();
      dispatch(setModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
    }
  }).catch(error => {
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};

const SaveSysServiceInfo = (obj, calllback = () => { }) => dispatch => {
  apiService.post('/system/SaveSysServiceInfo', obj).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      calllback();
      dispatch(setModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
    }
  }).catch(error => {
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};

const GetSysConfigInfo = () => dispatch => {
  apiService.get('/system/GetSysConfigInfo').then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      dispatch(setSysConfigInfo(data.result));
    }
  }).catch(error => {
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};

const SaveSysConfigInfo = (obj, calllback = () => { }) => dispatch => {
  apiService.post('/system/SaveSysConfigInfo', obj).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      calllback();
      return dispatch(setModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
    }
  }).catch(error => {
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};

const GetRoleInfo = (obj, callback = () => { }) => dispatch => {
  apiService.get('/SystemMenu/GetRoleInfo', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      callback();
      dispatch(setRoleInfo(data.result));
    }
  }).catch(error => {
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};

const GetMenuInfo = (obj, callback = () => { }) => dispatch => {
  apiService.get('/SystemMenu/GetMenuInfo', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      callback();
      dispatch(setMenuInfo(data.result));
    }
  }).catch(error => {
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};

const GetEditRoleInfo = (id, callback = () => { }) => dispatch => {
  apiService.get('/SystemMenu/EditRoleInfo', new URLSearchParams({
    id
  })).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      callback();
      dispatch(setEditRoleInfo(data.result));
    }
  }).catch(error => {
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};

const GetEditUserRightsInfo = (obj, callback = () => { }) => dispatch => {
  apiService.get('/SystemMenu/EditUserRightsInfo', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      callback();
      dispatch(setEditRoleInfo(data.result));
    }
  }).catch(error => {
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};

const SaveRoleInfo = (obj, calllback = () => { }) => dispatch => {
  dispatch(appActions.updateModalAlert({
    visible: true,
    type: 'loading',
  }));
  apiService.post('/SystemMenu/SaveRoleInfo', obj).then(res => {
    const { data, status } = res || {};
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (status === StatusCodes.OK) {
      calllback();
      dispatch(setModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};

const SaveUserRightsInfo = (obj, calllback = () => { }) => dispatch => {
  dispatch(appActions.updateModalAlert({
    visible: true,
    type: 'loading',
  }));
  apiService.post('/SystemMenu/SaveUserRightsInfo', obj).then(res => {
    const { data, status } = res || {};
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (status === StatusCodes.OK) {
      calllback();
      dispatch(setModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};

const SetMenuOrder = (obj, calllback = () => { }) => dispatch => {
  dispatch(appActions.updateModalAlert({
    visible: true,
    type: 'loading',
  }));
  apiService.post('/SystemMenu/SetMenuOrder', obj).then(res => {
    const { data, status } = res || {};
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (status === StatusCodes.OK) {
      calllback();
      dispatch(setModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};

const GetLotteryTypeInfo = (callback = () => { }) => dispatch => {
  apiService.get('/System/GetLotteryTypeInfo').then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      callback();
      dispatch(setLotteryTypeInfo(data.result));
    }
  }).catch(error => {
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};

const GetEditLotteryTypeInfo = (code, callback = () => { }) => dispatch => {
  apiService.get('/System/EditLotteryTypeInfo', new URLSearchParams({
    code
  })).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      callback();
      dispatch(setEditLotteryTypeInfo(data.result));
    }
  }).catch(error => {
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};

const SaveLotteryTypeInfo = (obj, calllback = () => { }) => dispatch => {
  dispatch(appActions.updateModalAlert({
    visible: true,
    type: 'loading',
  }));
  apiService.post('/System/SaveLotteryTypeInfo', obj).then(res => {
    const { data, status } = res || {};
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (status === StatusCodes.OK) {
      calllback();
      dispatch(setModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};

const GetUserRightsInfo = (obj, callback = () => {}) => dispatch => {
  apiService.get('/SystemMenu/GetUserRightsInfo', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      callback();
      return dispatch(setCompanyList(data.result));
    }
  }).catch(error => {
    dispatch(setCompanyList());
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg,
          showCancelButton: false
        }
      }));
    }
    Promise.reject(error);
  });
};

const GetSubUserRightsInfo = (obj, callback = () => {}) => dispatch => {
  apiService.get('/SystemMenu/GetSubUserRightsInfo', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      callback();
      return dispatch(setSubAccountList(data.result));
    }
  }).catch(error => {
    dispatch(setSubAccountList());
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg,
          showCancelButton: false
        }
      }));
    }
    Promise.reject(error);
  });
};

const GetControllers = (obj, callback = () => {}) => dispatch => {
  apiService.get('/SystemMenu/GetControllers', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      return callback(data.result ?? {});
    }
  }).catch(error => {
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg,
          showCancelButton: false
        }
      }));
    }
    Promise.reject(error);
  });
};

const CreateController = (obj, callback = () => {}) => dispatch => {
  apiService.post('/SystemMenu/CreateController', obj).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      dispatch(setModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
      return callback(data.result);
    }
  }).catch(error => {
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg,
          showCancelButton: false
        }
      }));
    }
    Promise.reject(error);
  });
};

const UpdateController = (obj, callback = () => {}) => dispatch => {
  apiService.post('/SystemMenu/UpdateController', obj).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      dispatch(setModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
      return callback(data.result);
    }
  }).catch(error => {
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg,
          showCancelButton: false
        }
      }));
    }
    Promise.reject(error);
  });
};

const DeleteController = (obj, callback = () => {}) => dispatch => {
  apiService.post('/SystemMenu/DeleteController', obj).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      dispatch(setModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
      return callback(data.result);
    }
  }).catch(error => {
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg,
          showCancelButton: false
        }
      }));
    }
    Promise.reject(error);
  });
};

const SetControllerStatus = (obj, callback = () => {}) => dispatch => {
  apiService.post('/SystemMenu/SetControllerStatus', obj).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      dispatch(setModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
      return callback(data.result);
    }
  }).catch(error => {
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg,
          showCancelButton: false
        }
      }));
    }
    Promise.reject(error);
  });
};

const GetMenuAvailableControllers = (obj, callback = () => {}) => dispatch => {
  apiService.get('/SystemMenu/GetMenuAvailableControllers', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      return callback(data.result ?? {});
    }
  }).catch(error => {
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg,
          showCancelButton: false
        }
      }));
    }
    Promise.reject(error);
  });
};

const GetMenuControllers = (obj, callback = () => {}) => dispatch => {
  apiService.get('/SystemMenu/GetMenuControllers', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      return callback(data.result ?? {});
    }
  }).catch(error => {
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg,
          showCancelButton: false
        }
      }));
    }
    Promise.reject(error);
  });
};

const DeleteMenuController = (obj, callback = () => {}) => dispatch => {
  apiService.post('/SystemMenu/DeleteMenuController', obj).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      dispatch(setModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
      return callback(data.result);
    }
  }).catch(error => {
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg,
          showCancelButton: false
        }
      }));
    }
    Promise.reject(error);
  });
};

const UpdateMenuController = (obj, callback = () => {}) => dispatch => {
  apiService.post('/SystemMenu/UpdateMenuController', obj).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      dispatch(setModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
      return callback(data.result);
    }
  }).catch(error => {
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg,
          showCancelButton: false
        }
      }));
    }
    Promise.reject(error);
  });
};

export default {
  getInfo,
  cancelBetting,
  cancelBettingByInstallNo,
  saveInfo,
  saveExternalWeb,
  getLotteryItems,
  getEditLotteryItem,
  saveLotteryItem,
  updateLotteryGameStatus,
  updateLotteryOrder,
  GetBankCardList,
  getPayAccountSetting,
  getEditPayAccount,
  savePayAccount,
  deletePayAccount,
  setPayAccountStatus,
  getExternalWeb,
  updateExternalWebStatus,
  deleteExternalWeb,
  getThirdPartyPaySetting,
  getEditThirdPaySetting,
  saveThirdPartyPaySetting,
  copyThirdPartyPaySetting,
  updateThirdPartyPaySorting,
  setThirdPartyPayStatus,
  getMemberAutoClassify,
  deleteMemberClassification,
  saveMemberClassification,
  getOfflineSetting,
  setOfflineStatus,
  getEditOfflineSetting,
  saveOfflineSetting,
  getSMSSetting,
  getEmailSetting,
  getEditEmailSetting,
  getEditSMSSetting,
  setEmailStatus,
  setSMSStatus,
  saveSMSSetting,
  deleteSMSSetting,
  deleteEmailSetting,
  saveEmailSetting,
  getWithdrawSetting,
  setWithdrawStatus,
  getEditWithdrawSetting,
  saveWithdrawSetting,
  getOTPList,
  getExRateList,
  toggleExrateIsAuto,
  saveExrate,
  toggleExrateIsDel,
  getEditExRate,
  getEditBankSetting,
  deleteBankSetting,
  updateBankSettingSorting,
  saveBankSetting,
  getLotteryResultSetting,
  saveLotteryResultSetting,
  getLotteryAddressList,
  editLotteryAddress,
  deleteLotteryAddress,
  SaveLotteryAddress,
  GetSysServiceInfo,
  EditSysServiceInfo,
  DeleteSysServiceInfo,
  SaveSysServiceInfo,
  GetSysConfigInfo,
  SaveSysConfigInfo,
  GetRoleInfo,
  GetMenuInfo,
  GetEditRoleInfo,
  SaveRoleInfo,
  GetEditUserRightsInfo,
  SaveUserRightsInfo,
  SetMenuOrder,
  GetLotteryTypeInfo,
  GetEditLotteryTypeInfo,
  SaveLotteryTypeInfo,
  GetMemberDailyReport,
  GenerateCheckBills,
  BatchRepair,
  GetChangeList,
  GetMemberAmountHistory,
  GetLotteryStyle,
  SaveLotteryStyle,
  EditExternalWeb,
  GetBuhuoBetList,
  GetDefaultLotteryDrawSite,
  SaveDefaultLotteryDrawSites,
  GetUserRightsInfo,
  GetSubUserRightsInfo,

  GetControllers,
  CreateController,
  UpdateController,
  DeleteController,
  SetControllerStatus,

  GetMenuAvailableControllers,
  GetMenuControllers,
  DeleteMenuController,
  UpdateMenuController
};