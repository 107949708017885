import { renderResultDateCell } from '../../utils';

const renderQiu = data => {
  if (data && !data.openResult) return;
  const result = data.openResult.split(',');
  return result.map(item => (
    {
      value: item, className: `circle ${item >= 19 ? 'circle circleRed' : ''}`
    }
  ));
};

const renderZH = data => {
  if (data && !data.openResult) return;
  const result = data.openResult.split(',');
  const tempResult = [];
  let championSum = 0;
  result.forEach(item => (championSum += parseInt(item, 10)));

  tempResult.push({
    value: championSum
  });

  tempResult.push({
    value: championSum > 80 ? '大' : '小',
    className: championSum > 80 ? 'redText' : '',
  });

  tempResult.push({
    value: championSum % 2 ? '单' : '双',
    className: championSum % 2 ? 'redText' : '',
  });

  tempResult.push({
    value: championSum % 10 >= 5 ? '尾大' : '尾小',
    className: championSum % 10 >= 5 ? 'redText' : '',
  });
  return tempResult;
};

const renderLH = data => {
  if (data && !data.openResult) return;
  const result = data.openResult.split(',');
  const tempResult = [];
  for (let i = 0; i < result.length / 2; i++) {
    if ((parseInt(result[i], 10)) > parseInt(result[result.length - 1 - i], 10)) {
      tempResult.push({
        value: '龙',
        className: 'redText'
      });
    } else {
      tempResult.push({
        value: '虎',
      });
    }
  }
  return tempResult;
};

const KL10 = {
  children: [
  ],
  resultTab: {
    headerCell: [{
      attr: '期数',
      wrapperStyle: 'kl10Header'
    }, {
      attr: '日期',
      wrapperStyle: 'kl10Header'
    }, {
      attr: '彩球号码',
      wrapperStyle: 'kl10Header'
    }, {
      attr: '总和',
      wrapperStyle: 'kl10Header'
    }, {
      attr: '1-4球龙虎',
      wrapperStyle: 'kl10Header'
    }],
    bodyCell: [{
      attr: 'installments',
      wrapperStyle: 'd-flex justify-content-center align-items-center'
    }, {
      attr: renderResultDateCell,
      wrapperStyle: 'dateCol'
    }, {
      attr: renderQiu,
      wrapperStyle: 'd-flex justify-content-center align-items-center kl10Num'
    }, {
      attr: renderZH,
      wrapperStyle: 'd-flex justify-content-center align-items-center pk10GYH'
    }, {
      attr: renderLH,
      wrapperStyle: 'd-flex justify-content-center align-items-center pk10LH'
    }],
  }
};

export default KL10;