import { useEffect, useState } from 'react';

import { Checkbox, Input, InputNumber, Radio } from 'antd';
import { Label } from 'reactstrap';
import { connect } from 'react-redux';

import { agentActions, appActions, commonActions } from '../../../../redux/actions';
import { SectionToChinese } from '../../../../constants/utils';
import './styles.scss';

const mapStateToProps = state => ({
  agentEditInfo: state.agentReducers.agentEditInfo,
  isValidLoginIdMsg: state.agentReducers.isValidLoginIdMsg,
  isValidNameMsg: state.agentReducers.isValidNameMsg,
  downlineAgentList: state.commonReducers.downlineAgentList, // 上一个父组件有了，所以当前组件不需要再请求了，直接用
  accountType: state.commonReducers.accountType,
});

const mapDispatchToProps = dispatch => ({
  GetEditAgentInfo: (id, callback) => dispatch(agentActions.GetEditAgentInfo(id, callback)),
  SaveAgentInfo: (obj, callback) => dispatch(agentActions.SaveAgentInfo(obj, callback)),
  isValidName: (userId, callback) => dispatch(agentActions.isValidName(userId, callback)),
  isExistLoginId: (userId, callback) => dispatch(agentActions.isExistLoginId(userId, callback)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  resetReturnMsgData: () => dispatch(agentActions.resetReturnMsgData()),
  resetSubAccountInfo: () => dispatch(agentActions.resetSubAccountInfo()),
  SetAccountQuota: (obj, callback) => dispatch(agentActions.SetAccountQuota(obj, callback)),
  GetAccountType: () => dispatch(commonActions.GetAccountType()),
});

const AgentEdit = ({ GetAccountType, accountType, SetAccountQuota, downlineAgentList, resetReturnMsgData, resetSubAccountInfo, SaveAgentInfo, updateModalAlert, callback = () => { }, onCancel, isValidNameMsg, isValidName, data, agentEditInfo, GetEditAgentInfo }) => {
  const downlineAgent = downlineAgentList.find(i => Number(i.level) === data.level - 1) || {};
  let upAgentName = '';
  if (downlineAgent?.fullName) {
    if (downlineAgent?.fullName.indexOf('代理') !== -1) {
      upAgentName = '代理';
    } else {
      upAgentName = downlineAgent?.fullName;
    }
  }

  const setSelectedItem = useState({})[1]; // 从上级传过来的 数据
  const [info, setInfo] = useState({}); // 表单处理的数据
  const [pkList, setPkList] = useState(['A']);
  const [res, setRes] = useState({});

  const [thirdPartyZC, setThirdPartyZC] = useState([]); // 第三方占城
  const [orgZC, setOrgZC] = useState([]); // 当前占城
  const [lotteryMenu, setLotteryMenu] = useState([]); // 彩种管理
  const [_passwordMsg, setPasswordMsg] = useState('');
  const [_nameReturnMsg, setNameReturnMsg] = useState({});

  const leftLotteryMenu = lotteryMenu.reduce((acc, item) => {
    const exist = acc.find(i => i.categoryCode === item.categoryCode);
    if (!exist) {
      acc.push({
        categoryName: item.categoryName,
        categoryCode: item.categoryCode,
      });
    }
    return acc;
  }, []).sort((a, b) => ((a.orderBy > b.orderBy) ? -1 : 1));

  const handlePasswordChange = text => {
    setInfo({
      ...info,
      password: text
    });
    if (text?.length > 5 && text?.length < 21 && text.match(/^[0-9a-zA-Z]+$/)) {
      if (/[0-9]/.test(text) && /[a-z]/.test(text) && /[A-Z]/.test(text)) {
        setPasswordMsg({
          state: 1,
          msg: '密码可用'
        });
      } else {
        setPasswordMsg({
          state: 99,
          msg: '密码必须由字母数字组成（区分大小写）！'
        });
      }
    } else {
      setPasswordMsg({
        state: 99,
        msg: '密码必须由字母数字组成，6-20位（区分大小写）！'
      });
    }
  };

  const handleNameOnBlur = text => {
    setInfo({
      ...info,
      name: text
    });
    if (text?.length > 3) {
      isValidName(text);
    }
  };
  useEffect(() => {
    setNameReturnMsg(isValidNameMsg);
  }, [isValidNameMsg]);

  useEffect(() => {
    GetAccountType();
    resetSubAccountInfo();
    resetReturnMsgData();
    if (data) {
      setSelectedItem(data);
      GetEditAgentInfo(data.orgId);
    }
  }, []);

  useEffect(() => {
    if (agentEditInfo && agentEditInfo?.editOrg) {
      setRes(agentEditInfo);
      const accountInfo = [];
      if (agentEditInfo?.editOrg?.accountInfo) {
        accountType.forEach(item => {
          const acc = agentEditInfo?.editOrg?.accountInfo.find(x => x.catagoryId === item.accountType);
          let { accName } = item;
          if (agentEditInfo?.info?.accountOption === 0 && item.accountType === 2) {
            accName = '额度';
          }
          if (acc) {
            accountInfo.push({
              ...acc, accName
            });
          }
        });
      }
      setInfo({
        ...(agentEditInfo.editOrg), accountInfo
      }); // 基本信息
      const gameType = agentEditInfo?.editOrg?.gameType || '';
      setPkList(gameType.split('')); // 盘口i选择
      setOrgZC(agentEditInfo?.orgZc ? agentEditInfo?.orgZc.map(item => {
        const temp = {
          ...item, avairableRateForDown: item.availableRateForDown
        };
        delete temp.availableRateForDown;
        return temp;
      }) : []);
      setThirdPartyZC(agentEditInfo?.thirdPartyGamesZc || []);

      const lotteryList = agentEditInfo?.lotteryList || [];
      const _lotteryMenu = (agentEditInfo?.upLotteryList || []).map(item => {
        const checked = lotteryList.find(i => i === item.code);
        return {
          ...item, checked,
        };
      });
      setLotteryMenu(_lotteryMenu);
    }
  }, [agentEditInfo]);

  const userAccList = res?.upOrg?.accountInfo || [];
  const pkOptions = (
    res?.orgParent?.levelValue <= res?.info?.companyLevel
      ? ('ABCD').split('').map(item => ({
        label: item, value: item
      }))
      : (res?.orgParent?.gameType || '').split('').map(item => ({
        label: item, value: item
      }))
  );

  const handleSave = (params = {}, validType, whetherMFA) => {
    updateModalAlert({
      type: 'confirmation',
      visible: true,
      data: {
        message: '请确认是否要设置',
        onConfirmationClick: () => changData()
      }
    });
    const changData = () => {
      const lotteryValues = lotteryMenu.filter(checkbox => checkbox.checked).map(checkbox => checkbox.code);
      updateModalAlert({
        type: 'loading',
        visible: true
      });
      const obj = {
        isUpdate: true,
        org: {
          ...info,
          levelValue: data.level,
          isChild: data.isChild,
          organizationId: data.orgId,
          parentId: data.upOrgId,
          gameType: pkList.join(''),
          useState: info.accountStatus, // 用户状态
          validType,
        },
        sharesList: orgZC,
        // 第三方占成
        thirdPartySharesList: thirdPartyZC.map(item => ({
          gameCode: item.gameCode,
          zc: item.zc
        })),
        // 彩种
        lotteryType: lotteryValues || [],
        ...params,
      };
      SaveAgentInfo(obj, !whetherMFA ? callback : () => GetEditAgentInfo(data.orgId));
    };
  };

  const saveCreditBalance = accType => {
    const newValue = info.accountInfo?.find(x => x.catagoryId === accType)?.balance ?? 0;
    const originValue = agentEditInfo?.editOrg?.accountInfo.find(x => x.catagoryId === accType)?.balance ?? 0;
    const gapValue = Number(newValue) - Number(originValue);
    const obj = {
      userId: data.orgId,
      userType: 2,
      accType,
      updateType: gapValue > 0 ? 0 : 1,
      value: Math.abs(gapValue)
    };
    SetAccountQuota(obj);
  };

  return (
    <div className="agentEditWrapper">
      <div className="sectionWrapper">
        <div className="tableTitleStyle">账户资料</div>
        <div className="d-flex">
          <div className="tableSection labelCellBackground">
            <div className="rowStyle">
              <div>
                上级账号
              </div>
              <div>{data?.upLoginId}</div>
            </div>
            <div className="rowStyle">
              <div>
                账号
              </div>
              <div>{data.loginId}</div>
            </div>
            <div className="rowStyle">
              <div>帐号状态</div>
              <div>
                <Radio.Group
                  value={info?.accountStatus}
                  onChange={e => setInfo({
                    ...info,
                    accountStatus: e.target.value
                  })}
                >
                  <Radio value={1}>启用</Radio>
                  <Radio value={2}>暂停</Radio>
                  <Radio value={0}>停用</Radio>
                </Radio.Group>
              </div>
            </div>
            {/* 有权限控制 */}
            <div className="rowStyle">
              <div>登入密码</div>
              <div>
                <Input
                  value={info?.password}
                  onChange={e => handlePasswordChange(e.target.value)}
                />
                <Label className={`d-inline mx-2 ${_passwordMsg?.state === 1 ? 'text-success' : 'text-danger'}`}>{_passwordMsg?.msg}</Label>
              </div>
            </div>
            <div className="rowStyle">
              <div>
                {data.levelDownName}
                名称
              </div>
              <div>
                <Input
                  value={info?.name}
                  onChange={e => setInfo({
                    ...info,
                    name: e.target.value
                  })}
                  onBlur={({ target: { value: text } }) => handleNameOnBlur(text)}
                />
                <Label className={`d-inline mx-2 ${_nameReturnMsg?.state === 1 ? 'text-success' : 'text-danger'}`}>{_nameReturnMsg?.msg}</Label>
              </div>
            </div>
            <div className="rowStyle">
              <div>额度模式</div>
              <div>
                {
                  (info.limitType === 1 && (
                    <Radio checked={info.limitType === 1}>信用模式</Radio>
                  )) || (
                    <Radio checked={info.limitType === 2}>现金模式</Radio>
                  )
                }
              </div>
            </div>
            {
                 (
                  info && info?.accountInfo && info?.accountInfo?.map(item => (
                    <div className="rowStyle">
                      <div>{item?.accName}</div>
                      <div>
                        <InputNumber
                          className="w-select-value"
                          type="number"
                          value={Number(item?.balance ?? 0)}
                          onChange={value => setInfo({
                            ...info,
                            accountInfo: [].concat(info.accountInfo.map(x => ({
                              ...x, balance: x.catagoryId === item?.catagoryId ? (value > -1 ? value || 0 : item?.balance || 0) : x.balance
                            })))
                          })}
                        />
                        <span className="mx-1">
                          <span className="text-danger mx-1">{SectionToChinese(Math.floor(item?.balance ?? 0))}</span>
                          (上级余额：
                          {(userAccList?.find(x => x?.catagoryId === item.catagoryId) || {})?.balance?.toFixed(2) ?? ''}
                          )
                        </span>
                        <div className="operationBtn ms-2" onClick={() => saveCreditBalance(item?.catagoryId)}>保存</div>
                      </div>
                    </div>
                  ))
                )
            }
            <div className="rowStyle">
              <div>开放盘口</div>
              <div>
                <Checkbox.Group
                  options={pkOptions}
                  value={pkList}
                  onChange={values => setPkList(values)}
                />
              </div>
            </div>
            {
              data.level === 3 && !res?.orgParent?.haveSubAccount && (
                <div className="rowStyle">
                  <div>占成模式</div>
                  <div>
                    <Radio
                      checked={info.zcType === 0}
                      onChange={() => setInfo({
                        ...info,
                        zcType: 0
                      })}
                    >
                      浮动占成
                    </Radio>
                    <Radio
                      checked={info.zcType === 2}
                      onChange={() => setInfo({
                        ...info,
                        zcType: 2
                      })}
                    >
                      浮动占成II
                    </Radio>
                    <Radio
                      checked={info.zcType === 1}
                      onChange={() => setInfo({
                        ...info,
                        zcType: 1
                      })}
                    >
                      固定占成
                    </Radio>
                  </div>
                </div>
              )
            }
            {
              data.level === 3 && (
                <>
                  <div className="rowStyle">
                    <div>占成剩余</div>
                    <div>
                      <Radio
                        disabled={info.zcType !== 1}
                        checked={info.rateSurplus === 1}
                        onChange={() => setInfo({
                          ...info,
                          rateSurplus: 1
                        })}
                      >
                        归
                        {upAgentName}
                      </Radio>
                      <Radio
                        disabled={info.zcType !== 1}
                        checked={info.rateSurplus === 2}
                        onChange={() => setInfo({
                          ...info,
                          rateSurplus: 2
                        })}
                      >
                        归
                        {data.levelName}
                      </Radio>
                    </div>
                  </div>
                  <div className="rowStyle">
                    <div>补货方式</div>
                    <div>
                      <Radio
                        disabled={info.zcType !== 1}
                        checked={info.replenishmentType === 1}
                        onChange={() => setInfo({
                          ...info,
                          replenishmentType: 1
                        })}
                      >
                        全归
                        {upAgentName}
                      </Radio>
                      <Radio
                        disabled={info.zcType !== 1}
                        checked={info.replenishmentType === 2}
                        onChange={() => setInfo({
                          ...info,
                          replenishmentType: 2
                        })}
                      >
                        全归
                        {data.levelName}
                      </Radio>
                      <Radio
                        disabled={info.zcType !== 1}
                        checked={info.replenishmentType === 3}
                        onChange={() => setInfo({
                          ...info,
                          replenishmentType: 3
                        })}
                      >
                        按各级占成
                      </Radio>
                    </div>
                  </div>
                  <div className="rowStyle">
                    <div>补货剩余</div>
                    <div>
                      <Radio
                        disabled={info.zcType !== 1}
                        checked={info.replenishmentSurplus === 1}
                        onChange={() => setInfo({
                          ...info,
                          replenishmentSurplus: 1
                        })}
                      >
                        归
                        {upAgentName}
                      </Radio>
                      <Radio
                        disabled={info.zcType !== 1}
                        checked={info.replenishmentSurplus === 2}
                        onChange={() => setInfo({
                          ...info,
                          replenishmentSurplus: 2
                        })}
                      >
                        归
                        {data.levelName}
                      </Radio>
                    </div>
                  </div>
                </>
              )
            }
            {
              data.level <= 3 && (
                <div className="rowStyle">
                  <div>允许多赔率</div>
                  <div>
                    <Radio
                      checked={info.multiOdd === 1}
                      onChange={() => setInfo({
                        ...info,
                        multiOdd: 1
                      })}
                    >
                      允许
                    </Radio>
                    <Radio
                      checked={info.multiOdd === 0}
                      onChange={() => setInfo({
                        ...info,
                        multiOdd: 0
                      })}
                    >
                      不允许
                    </Radio>
                  </div>
                </div>
              )
            }

            <div className="rowStyle">
              <div>MFA验证</div>
              <div>
                {
                  info?.validType === 1 ? (
                    <div
                      className="operationBtn reset"
                      onClick={() => handleSave({}, 0, 1)}
                    >
                      重置
                    </div>
                  ) : '未设置'
                }
              </div>
            </div>

            {
              res?.editOrg?.upOrgOddsSetting === 1 && res?.editOrg?.levelValue >= res?.info?.zdLevel && res?.info?.earnOddsSett === 1 && (
                <div className="rowStyle">
                  <div>赚赔设置</div>
                  { res?.editOrg?.orgOddsSettingTempStatus === 1
                    ? (
                      <div>
                        <Radio
                          checked={info?.orgOddsSetting === 0}
                          onChange={() => setInfo({
                            ...info,
                            orgOddsSetting: 0
                          })}
                        >
                          禁用
                        </Radio>
                        <Radio
                          checked={info?.orgOddsSetting === 1}
                          onChange={() => setInfo({
                            ...info,
                            orgOddsSetting: 1
                          })}
                        >
                          启用
                        </Radio>
                      </div>
                    )
                    : (
                      <div>
                        <Radio
                          checked={info?.orgOddsSettingTemp === 0}
                          onChange={() => setInfo({
                            ...info,
                            orgOddsSettingTemp: 0
                          })}
                        >
                          禁用
                        </Radio>
                        <Radio
                          checked={info?.orgOddsSettingTemp === 1}
                          onChange={() => setInfo({
                            ...info,
                            orgOddsSettingTemp: 1
                          })}
                        >
                          启用
                        </Radio>
                        <span className="text-danger">[预设]</span>
                      </div>
                    )}

                </div>
              )
            }

            {
              res?.editOrg?.levelValue === res?.info?.zdLevel && res?.info?.earnOddsSett === 1 && (
                <div className="rowStyle">
                  <div>操盘设置</div>
                  <div>
                    <Radio
                      checked={info?.orgCtrlSetting === 0}
                      onChange={() => setInfo({
                        ...info,
                        orgCtrlSetting: 0
                      })}
                    >
                      禁用
                    </Radio>
                    <Radio
                      checked={info?.orgCtrlSetting === 1}
                      onChange={() => setInfo({
                        ...info,
                        orgCtrlSetting: 1
                      })}
                    >
                      启用
                    </Radio>
                  </div>
                </div>
              )
            }

            {/* 有权限设置 */}
            {
              res && res?.info?.outSiteEnable === 1 && (info?.levelValue >= res?.info?.zdLevel) && (
                <div className="rowStyle">
                  <div>第三方游戏</div>
                  <div>
                    <Radio.Group
                      disabled={(data.level > res?.info?.zdLevel)}
                      onChange={e => setInfo({
                        ...info,
                        outSiteEnable: e.target.value
                      })}
                      value={info.outSiteEnable}
                    >
                      <Radio value={0}>禁用</Radio>
                      <Radio value={1}>启用</Radio>
                    </Radio.Group>
                  </div>
                </div>
              )
            }

          </div>
        </div>
      </div>

      {
        res?.orgParent?.lotteryAssigned === 1 && (
          <div className="sectionWrapper">
            <div className="tableTitleStyle">彩种选择</div>
            <div className="tableSection labelCellBackground">
              <div className="rowStyle">
                <div>开放彩种管理给下线</div>
                <div>
                  <Radio
                    checked={info.lotteryAssigned === 1}
                    onChange={() => {
                      setInfo({
                        ...info,
                        lotteryAssigned: 1
                      });
                    }}
                  >
                    允许
                  </Radio>
                  <Radio
                    checked={info.lotteryAssigned === 0}
                    onChange={() => {
                      setInfo({
                        ...info,
                        lotteryAssigned: 0
                      });
                    }}
                  >
                    不允许
                  </Radio>
                </div>
              </div>
              {
                leftLotteryMenu?.map(item => {
                  const _menus = lotteryMenu.filter(x => x.categoryCode === item.categoryCode);
                  const checked = _menus.every(item => item.checked);
                  const { categoryName } = item || {};
                  return (
                    <div className="rowStyle">
                      <div>
                        <span className="mx-2">{categoryName}</span>
                        <Checkbox
                          checked={checked}
                          className="w-all-checkbox"
                          onChange={e => {
                            const { checked } = e.target;
                            setLotteryMenu(
                              lotteryMenu.map(data => {
                                if (item.categoryCode === data.categoryCode) {
                                  return {
                                    ...data, checked,
                                  };
                                }
                                return data;
                              })
                            );
                          }}
                        >
                          所有
                        </Checkbox>
                      </div>
                      <div>
                        {
                          _menus.map(d => (
                            <p key={d.code} className="d-inline border-0 m-1">
                              <Checkbox
                                className="w-single-checkbox"
                                checked={d.checked}
                                onChange={e => {
                                  const index = lotteryMenu.findIndex(i => i.code === d.code);
                                  const item = lotteryMenu[index];
                                  setLotteryMenu([
                                    ...lotteryMenu.slice(0, index),
                                    {
                                      ...item,
                                      checked: e.target.checked
                                    },
                                    ...lotteryMenu.slice(index + 1),
                                  ]);
                                }}
                              >
                                {d.lotteryName}
                              </Checkbox>
                            </p>
                          ))
                        }
                      </div>
                    </div>
                  );
                })
              }
            </div>
          </div>
        )
      }


      <div className="sectionWrapper">
        <div className="tableTitleStyle">
          占成设置 -
          {info.zcType === 0 && (
            <span className="mx-2">
              默认模式
            </span>
          )}
          {info.zcType === 2 && (
            <span className="mx-2">
              浮动占成II
            </span>
          )}
          {info.zcType === 1 && (
            <span className="mx-2">
              固定模式(上级公司占成与本级总代可占成数之和不能超过上级可用占成)
            </span>
          )}
        </div>
        <div className="tableSection">
          <div className="rowStyle bg-grey zhanChengHeader ">
            <div className="bg-grey w10 d-flex justify-content-center align-items-center">类型</div>
            <div className="w30">
              <div className={`w-100 d-flex justify-content-center align-items-center ${info.zcType === 2 ? 'h-50 borderBottom' : 'h-100'} `}>
                {`本级${info.zcType === 0 ? '最高' : ''}占成`}
              </div>
              {info.zcType === 2
              && (
              <>
                <div className="w50 d-flex justify-content-center align-items-center h-50">最低占成 (%)</div>
                <div className="w50 borderLeft d-flex justify-content-center align-items-center h-50">最高成数(%)</div>
              </>
              )}
            </div>
            <div className="w30 ">
              <div className="w-100 d-flex justify-content-center align-items-center h-50 borderBottom">
                下级占成
              </div>
              <div className="w50 d-flex justify-content-center align-items-center h-50">最低占成 (%)</div>
              <div className="w50 borderLeft d-flex justify-content-center align-items-center h-50">最高成数(%)</div>
            </div>
            <div className="flex-1 d-flex justify-content-center align-items-center">
              <div className="rowHeader d-flex justify-content-center align-items-center">允许补货</div>
            </div>
          </div>
          {
            orgZC.filter(item => item.hide === false).map(item => {
              const { categoryName, replenishment = 1, rateForUp, minZc, rate4UpMin, hasBet, upReplenishment, buhuoIndependence } = item || {};
              return (
                <div className="rowStyle">
                  <div className="w10 d-flex justify-content-center align-items-center">
                    {
                      hasBet && (<span className="text-red">【预设】</span>)
                    }
                    {
                      categoryName
                    }
                  </div>
                  <div className="w30 borderRight d-flex justify-content-center align-items-center">
                    { info.zcType === 2
                    && (
                    <div className="rowHeader w-50 d-flex justify-content-center align-items-center">
                      <InputNumber
                        min={0}
                        value={rate4UpMin}
                        onChange={v => {
                          const temp = orgZC.map(data => (data.categoryCode === item.categoryCode ? {
                            ...data, rate4UpMin: v,
                          } : {
                            ...data
                          }));
                          setOrgZC(temp);
                        }}
                      />
                      <span className="mx-1">
                        {`${item.upMinZc || 0}%至${item.upAvailableRateForDown || 0}%`}
                      </span>
                    </div>
                    )}

                    <div className={`rowHeader borderLeft ${info.zcType === 2 ? 'w50' : 'w-100'} d-flex justify-content-center align-items-center`}>
                      <InputNumber
                        min={0}
                        value={rateForUp}
                        onChange={v => {
                          const temp = orgZC.map(data => (data.categoryCode === item.categoryCode ? {
                            ...data, rateForUp: v,
                          } : {
                            ...data
                          }));
                          setOrgZC(temp);
                        }}
                      />
                      <span className="mx-1">
                        {`${item.upMinZc || 0}%至${item.upAvailableRateForDown || 0}%`}
                      </span>
                    </div>

                  </div>
                  <div className="w30 borderRight d-flex justify-content-center align-items-center">
                    <div className="rowHeader borderRight w50 d-flex justify-content-center align-items-center">
                      <InputNumber
                        min={0}
                        value={minZc || 0}
                        onChange={v => {
                          const temp = orgZC.map(data => (data.categoryCode === item.categoryCode ? {
                            ...data, minZc: v,
                          } : {
                            ...data
                          }));
                          setOrgZC(temp);
                        }}
                      />
                    </div>
                    <div className="rowHeader borderRight w50 d-flex justify-content-center align-items-center">
                      <InputNumber
                        min={0}
                        value={item.avairableRateForDown || 0}
                        onChange={v => {
                          const temp = orgZC.map(data => (data.categoryCode === item.categoryCode ? {
                            ...data, avairableRateForDown: v,
                          } : {
                            ...data
                          }));
                          setOrgZC(temp);
                        }}
                      />
                      <span className="mx-1">
                        {`(最大 ${item.upAvailableRateForDown || 0}%)`}
                      </span>
                    </div>

                  </div>
                  <div className="flex-1 d-flex justify-content-center align-items-center">
                    <Radio
                      disabled={upReplenishment === 0 && buhuoIndependence === 0}
                      checked={replenishment === 1}
                      onChange={() => {
                        const temp = orgZC.map(data => (data.categoryCode === item.categoryCode ? {
                          ...data, replenishment: 1,
                        } : {
                          ...data
                        }));
                        setOrgZC(temp);
                      }}
                    >
                      允许
                    </Radio>
                    <Radio
                      disabled={upReplenishment === 0 && buhuoIndependence === 0}
                      checked={replenishment === 0}
                      onChange={() => {
                        const temp = orgZC.map(data => (data.categoryCode === item.categoryCode ? {
                          ...data, replenishment: 0,
                        } : {
                          ...data
                        }));
                        setOrgZC(temp);
                      }}
                    >
                      禁止
                    </Radio>
                  </div>
                </div>
              );
            })
          }
        </div>
      </div>

      {
        (res && res?.info?.outSiteEnable === 1 && thirdPartyZC?.length > 0 && (
          <div className="sectionWrapper">
            <div className="tableTitleStyle">占成设置</div>
            <div className="tableSection">
              <div className="rowStyle bg-grey">
                <div className="d-flex justify-content-center align-items-center">类型</div>
                <div className="d-flex justify-content-center align-items-center">占成</div>
              </div>
              {
                thirdPartyZC?.filter(x => x?.hide === false).map(item => {
                  const { gameName, zc, upZc } = item || {};
                  return (
                    <div className="rowStyle">
                      <div className="d-flex justify-content-center align-items-center">{gameName}</div>
                      <div className="d-flex justify-content-center align-items-center">
                        <InputNumber
                          min={0}
                          value={zc}
                          onChange={v => {
                            const temp = thirdPartyZC.map(data => (data.gameCode === item.gameCode ? {
                              ...data, zc: v,
                            } : {
                              ...data
                            }));
                            setThirdPartyZC(temp);
                          }}
                        />
                        <span className="mx-1">
                          {`（最大${upZc}%）`}
                        </span>
                      </div>
                    </div>
                  );
                })
              }
            </div>
          </div>
        )) || ''
      }

      <div className="d-flex justify-content-center py-3">
        <div className="operationBtn" onClick={() => handleSave()}>保存</div>
        <div className="operationBtn ms-3 cancel" onClick={() => onCancel?.()}>取消</div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AgentEdit);