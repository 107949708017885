import { dxds } from '../Items/dsdx';

import { animal, dot4to17, fullDice } from '../Items/others';

import { doubleNumber, longCard, tripleNumber } from '../Items/numbers';

const CJ_K3 = [{
  hideMenu: 1,
  displayName: '总项盘口',
  midcode: 'SJ1,SJ2,SJ3,DX,QS,WS,DS,CP,DP',
  isStaticRow: 1,
  combineStyle: 1,
  repeatPerRow: 3,
  repeatPerCol: 3,
  prefix: ['SJ', 'WS', 'CP', 'DX', 'DS', 'DP', 'QS'],
  display: [[...animal],
    [...tripleNumber.slice(0, 6)],
    [...longCard],
    [...dxds.slice(2, 4)],
    [...dot4to17],
    [...doubleNumber.slice(1, 6)],
    [...fullDice]],
  firstTableHeader: ['三军', '围骰', '长牌', '大小', '点数', '短牌', '全骰'],
  summary: [[{
    displayName: '三军总注额',
    code: 'item_SJ'
  }], [{
    displayName: '围骰总注额',
    code: 'item_WS'
  }], [{
    displayName: '长牌总注额',
    code: 'item_CP'
  }], [{
    displayName: '大小总注额',
    code: 'item_LM'
  }], [{
    displayName: '点数总注额',
    code: 'item_DS'
  }], [{
    displayName: '短牌总注额',
    code: 'item_DP'
  }], [{
    displayName: '全骰总注额',
    code: 'item_QS'
  }]]
}
];

export default CJ_K3;