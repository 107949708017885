import { SET_GIFT } from '../../actions/actionTypes';

const initialState = {
  gift: undefined,
};

const contestReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_GIFT:
      return {
        ...state, gift: action.value
      };
    default:
      return state;
  }
};

export default contestReducers;