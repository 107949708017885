import { SET_MESSAGE } from '../../actions/actionTypes';

const initialState = {
  message: undefined,
};

const messageReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_MESSAGE:
      return {
        ...state, message: action.value
      };
    default:
      return state;
  }
};

export default messageReducers;