import { useEffect, useState } from 'react';

import { Button, Input, Radio, Select } from 'antd';
import { Col, Label, Row } from 'reactstrap';
import { connect } from 'react-redux';

import { useTranslation } from 'react-i18next';

import { appActions, systemActions } from '../../../redux/actions';
import './styles.scss';


const mapStateToProps = state => ({
  systemSettingInfoData: state.systemReducers.systemSettingInfoData,
});

const mapDispatchToProps = dispatch => ({
  getInfo: () => dispatch(systemActions.getInfo()),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  saveInfo: (obj, callback) => dispatch(systemActions.saveInfo(obj, callback)),
});

const SystemSetting = ({ systemSettingInfoData, getInfo, saveInfo, updateModalAlert }) => {
  const { t } = useTranslation();
  const [_info, setInfo] = useState();

  useEffect(() => {
    if (systemSettingInfoData) {
      setInfo(systemSettingInfoData);
    }
  }, [systemSettingInfoData]);

  useEffect(() => {
    getInfo();
  }, []);

  const saveButton = () => {
    if (_info?.isChange) {
      updateModalAlert({
        type: 'loading',
        visible: true
      });
      const obj = _info;
      saveInfo(obj);
    } else {
      updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: '无变动'
        }
      });
    }
  };

  const onInfoValueChange = (val, field) => {
    const newItems = _info;
    newItems[field] = val;
    newItems.isChange = true;
    setInfo(newItems => ({
      ...newItems, field: val
    }));
  };

  const resetDefault = () => {
    getInfo();
  };

  const AutoReduceOdds = [
    {
      label: '按下注总额',
      value: '0',
    }, {
      label: '按公司占成',
      value: '1',
    }
  ].map(item => ({
    ...item, value: Number(item.value)
  }));

  const DecimalPointOptions = [
    {
      label: '不留小数点',
      value: '0',
    }, {
      label: '保留 1 位',
      value: '1',
    }, {
      label: '保留 2 位',
      value: '2',
    }, {
      label: '保留 3 位',
      value: '3',
    }, {
      label: '保留 4 位',
      value: '4',
    }
  ].map(item => ({
    ...item, value: Number(item.value)
  }));

  const DisplayStatus = [
    {
      label: '显示',
      value: '1',
    },
    {
      label: '不显示',
      value: '0',
    }
  ].map(item => ({
    ...item, value: Number(item.value)
  }));

  const ReportUnit = [
    {
      label: '日',
      value: 'd',
    }, {
      label: '周',
      value: 'w',
    }, {
      label: '月',
      value: 'm',
    }
  ];

  const PasswordValidPeriod = [
    {
      label: '无限',
      value: '0',
    }, {
      label: '一周',
      value: '7',
    }, {
      label: '两周',
      value: '14',
    }, {
      label: '三周',
      value: '21',
    }, {
      label: '一个月',
      value: '30',
    }, {
      label: '两个月',
      value: '60',
    }, {
      label: '三个月',
      value: '90',
    }, {
      label: '半年',
      value: '180',
    }
  ].map(item => ({
    ...item, value: Number(item.value)
  }));

  return (
    <div className="SystemManageWrapper">
      <div className="mx-0 bg-white my-2  border-1">
        <Row className="bigWrapper m-0 my-1 p-3">
          <table className="_table agentDataTable p-3 m-0" cellSpacing="0" cellPadding="0" border="0">
            <tbody>
              <tr>
                <td className="tableHeader col-2">系统维护</td>
                <td>
                  <div
                    className="d-flex p-0 rdSearchOptionsGroup"
                  >
                    <Select
                      value={_info?.isMaintenance}
                      placeholder="请选择"
                      onChange={val => onInfoValueChange(Number(val), 'isMaintenance')}
                      options={[
                        {
                          label: '关闭', value: 1,
                        },
                        {
                          label: '开启', value: 0,
                        }
                      ]}
                    />
                  </div>
                </td>
                <td className="tableHeader col-2">限制赔率不能高于默认赔率</td>
                <td>
                  <span>
                    <Select
                      value={_info?.rateLimit}
                      placeholder="请选择"
                      onChange={val => onInfoValueChange(Number(val), 'rateLimit')}
                      options={[
                        {
                          label: '否', value: 0,
                        },
                        {
                          label: '是', value: 1,
                        }
                      ]}
                    />
                  </span>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-2">开盘中修改自动补货</td>
                <td>
                  <div
                    className="d-flex p-0 rdSearchOptionsGroup"
                  >
                    <Select
                      value={_info?.isAutoBuhuo}
                      placeholder="请选择"
                      onChange={val => onInfoValueChange(Number(val), 'isAutoBuhuo')}
                      options={[{
                        label: '允许(自由修改)',
                        value: 0,
                      },
                      {
                        label: '允许(只允许未有注单的用户)',
                        value: 2,
                      }, {
                        label: '不允许',
                        value: 1,
                      }]}
                    />

                  </div>
                </td>
                <td className="tableHeader col-2">帐户类型</td>
                <td>{(_info?.accountOption === 0 && '单帐户额度') || '多帐户额度'}</td>
              </tr>
              <tr>
                <td className="tableHeader col-2">代理报表查询限制</td>
                <td>
                  <span className="smallInput">
                    <Input
                      className="d-inline"
                      type="number"
                      value={_info?.reportValue}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text === '' ? '' : Number(text), 'reportValue')}
                    />
                    <Select
                      value={_info?.reportUnit}
                      placeholder="请选择"
                      className="mx-2"
                      onChange={val => onInfoValueChange(val, 'reportUnit')}
                      options={ReportUnit}
                    />
                  </span>
                </td>
                <td className="tableHeader col-2">会员报表查询限制</td>
                <td>
                  <span className="smallInput">
                    <Input
                      className="d-inline"
                      type="number"
                      value={_info?.memberReportValue}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text === '' ? '' : Number(text), 'memberReportValue')}
                    />
                    <Select
                      value={_info?.reportUnit}
                      placeholder="请选择"
                      className="mx-2"
                      onChange={val => onInfoValueChange(val, 'reportUnit')}
                      options={ReportUnit}
                    />
                  </span>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-2">用户密码有效期</td>
                <td>
                  <Select
                    value={_info?.passwordLifeCycle}
                    placeholder="请选择"
                    onChange={val => onInfoValueChange(Number(val), 'passwordLifeCycle')}
                    options={PasswordValidPeriod}
                  />
                </td>
                <td className="tableHeader col-2">自动降赔率规则</td>
                <td>
                  <div
                    className="d-flex p-0 rdSearchOptionsGroup"
                  >
                    <Select
                      value={_info?.isAutoReduceOdds || 1}
                      placeholder="请选择"
                      onChange={val => onInfoValueChange(Number(val), 'isAutoReduceOdds')}
                      options={AutoReduceOdds}
                    />
                    <Label className="mx-2 p-1">注意：此项请在开盘前设置！</Label>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-2">统计金额小数点</td>
                <td>
                  <Select
                    value={_info?.sumDecimalPoint}
                    placeholder="请选择"
                    onChange={val => onInfoValueChange(Number(val), 'sumDecimalPoint')}
                    options={DecimalPointOptions}
                  />
                </td>
                <td className="tableHeader col-2">预计盈利小数点</td>
                <td>
                  <div
                    className="d-flex p-0 rdSearchOptionsGroup"
                  >
                    <Select
                      value={_info?.profileDecimalPoint}
                      placeholder="请选择"
                      onChange={val => onInfoValueChange(Number(val), 'profileDecimalPoint')}
                      options={DecimalPointOptions}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-2">报表结果小数点</td>
                <td>
                  <Select
                    value={_info?.reportDecimalPoint}
                    placeholder="请选择"
                    onChange={val => onInfoValueChange(Number(val), 'reportDecimalPoint')}
                    options={DecimalPointOptions}
                  />
                </td>
                <td className="tableHeader col-2">今日输赢报警</td>
                <td>
                  <Label>赢</Label>
                  <Input
                    className="d-inline mx-2"
                    type="number"
                    value={_info?.winLimit}
                    onChange={({ target: { value: text } }) => onInfoValueChange(Number(text), 'winLimit')}
                  />
                  <Label>输</Label>
                  <Input
                    className="d-inline mx-2"
                    type="number"
                    value={_info?.loseLimit}
                    onChange={({ target: { value: text } }) => onInfoValueChange(text === '' ? '' : Number(text), 'loseLimit')}
                  />
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-2">最低注单金额</td>
                <td>
                  <span>
                    <Input
                      className="d-inline"
                      type="number"
                      value={_info?.betMinAmount}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text === '' ? '' : Number(text), 'betMinAmount')}
                    />
                  </span>
                </td>
                <td className="tableHeader col-2">连码显示赔率</td>
                <td>
                  <div className="d-flex p-0 rdSearchOptionsGroup">
                    {
                      DisplayStatus.map(d => (
                        <div key={`rdDisplayStatusGroup_${d.value}`}>
                          <Radio
                            value={_info?.showLMOdds}
                            checked={_info?.showLMOdds === Number(d.value)}
                            onChange={() => onInfoValueChange(Number(d.value), 'showLMOdds')}
                          >
                            {d?.label}
                          </Radio>
                        </div>
                      ))
                    }
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-2">IP显示方式</td>
                <td>
                  <div className="d-flex p-0 ipShow">
                    {
                      [{
                        label: '下级隐藏后两段', value: 0
                      }, {
                        label: '下级隐藏中间两段', value: 1
                      }, {
                        label: '隐藏后两段', value: 3
                      }, {
                        label: '隐藏中间两段', value: 4
                      }, {
                        label: '下级隐藏', value: 5
                      }, {
                        label: '隐藏', value: 6
                      }].map(d => (
                        <div key={`rdMultiPlateGroup_${d.value}`}>
                          <Radio
                            className="w-radio-100"
                            checked={_info?.ipDisplayType === Number(d.value)}
                            onChange={() => onInfoValueChange(Number(d.value), 'ipDisplayType')}
                          >
                            {d?.label}
                          </Radio>
                        </div>
                      ))
                    }
                  </div>
                </td>
                <td className="tableHeader col-2">代理显示分级名称</td>
                <td>
                  <div className="d-flex p-0 rdSearchOptionsGroup">
                    {
                      [{
                        label: '显示', value: 1
                      }, {
                        label: '不显示', value: 0
                      }].map(d => (
                        <div key={`rdMultiPlateGroup_${d.value}`}>
                          <Radio
                            checked={_info?.showLevelName === Number(d.value)}
                            onChange={() => onInfoValueChange(Number(d.value), 'showLevelName')}
                          >
                            {d?.label}
                          </Radio>
                        </div>
                      ))
                    }
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-2">会员多盘口</td>
                <td>
                  <div className="d-flex p-0 rdSearchOptionsGroup">
                    <Radio.Group onChange={e => onInfoValueChange(Number(e.target.value), 'multiGameType')} value={_info?.multiGameType ?? 0}>
                      <Radio value={1}>允许</Radio>
                      <Radio value={0}>不允许</Radio>
                    </Radio.Group>
                  </div>
                </td>
                <td className="tableHeader col-2">是否启用第三方游戏</td>
                <td>
                  <div
                    className="d-flex p-0 rdSearchOptionsGroup"
                  >
                    <Select
                      value={_info?.outsideEnable}
                      placeholder="请选择"
                      onChange={val => onInfoValueChange(Number(val), 'outsideEnable')}
                      options={[{
                        label: '启用', value: 1,
                      }, {
                        label: '不启用', value: 0,
                      }]}
                    />
                  </div>
                </td>

              </tr>
              <tr>
                <td className="tableHeader col-2">六合彩连码-三全中复式投注</td>
                <td>
                  <div
                    className="d-flex p-0 rdSearchOptionsGroup"
                  >
                    <Select
                      value={_info?.multiBet}
                      placeholder="请选择"
                      onChange={val => onInfoValueChange(Number(val), 'multiBet')}
                      options={[{
                        label: '启用', value: 0,
                      }, {
                        label: '不启用', value: 1,
                      }]}
                    />
                  </div>
                </td>
                <td className="tableHeader col-2">相同IP会员预警</td>
                <td className="">
                  <div className="d-flex p-0 rdSearchOptionsGroup">
                    <Radio.Group onChange={e => onInfoValueChange(Number(e.target.value), 'sameIPWarning')} value={_info?.sameIPWarning ?? 0}>
                      <Radio value={1}>启用</Radio>
                      <Radio value={0}>不启用</Radio>
                    </Radio.Group>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-2">开放补货单独设置</td>
                <td>
                  <div className="d-flex p-0 rdSearchOptionsGroup">
                    <Radio.Group onChange={e => onInfoValueChange(Number(e.target.value), 'buhuoIndependence')} value={_info?.buhuoIndependence ?? 0}>
                      <Radio value={1}>启用</Radio>
                      <Radio value={0}>不启用</Radio>
                    </Radio.Group>
                  </div>
                </td>
                <td className="tableHeader col-2">手动补货</td>
                <td>
                  <div className="d-flex p-0 rdSearchOptionsGroup">
                    {
                      [{
                        label: '禁用', value: 1
                      }, {
                        label: '不禁用', value: 0
                      }].map(d => (
                        <div key={`rdMultiPlateGroup_${d.value}`}>
                          <Radio
                            checked={_info?.disableManualBuhuo === Number(d.value)}
                            onChange={() => onInfoValueChange(Number(d.value), 'disableManualBuhuo')}
                          >
                            {d?.label}
                          </Radio>
                        </div>
                      ))
                    }
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-2">关闭报表明细</td>
                <td>
                  <div className="d-flex p-0 rdSearchOptionsGroup">
                    <Radio.Group onChange={e => onInfoValueChange(Number(e.target.value), 'closeReportDetail')} value={_info?.closeReportDetail ?? 0}>
                      <Radio value={1}>关闭</Radio>
                      <Radio value={0}>不关闭</Radio>
                    </Radio.Group>
                  </div>
                </td>
                <td className="tableHeader col-2">隐藏报表会员数</td>
                <td>
                  <div className="d-flex p-0 rdSearchOptionsGroup">
                    {
                      [{
                        label: '显示', value: 0
                      }, {
                        label: '隐藏', value: 1
                      }].map(d => (
                        <div key={`rdMultiPlateGroup_${d.value}`}>
                          <Radio
                            checked={_info?.hideMemCount === Number(d.value)}
                            onChange={() => onInfoValueChange(Number(d.value), 'hideMemCount')}
                          >
                            {d?.label}
                          </Radio>
                        </div>
                      ))
                    }
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-2">支持会员投注Api</td>
                <td>
                  <div className="d-flex p-0 rdSearchOptionsGroup">
                    <Radio.Group onChange={e => onInfoValueChange(Number(e.target.value), 'suportBetApi')} value={_info?.suportBetApi ?? 0}>
                      <Radio value={0}>不支持</Radio>
                      <Radio value={1}>支持</Radio>
                    </Radio.Group>
                  </div>
                </td>
                <td className="tableHeader col-2" />
                <td />
              </tr>
            </tbody>
          </table>
        </Row>
      </div>

      <Row className="mb-5 my-2 p-2">
        <Col className="d-flex justify-content-center py-2 border-top">
          <Button className="button handle mx-1" onClick={() => saveButton()}>{t('CONFIRM')}</Button>
          <Button className="button find mx-1" onClick={() => resetDefault()}>{t('RESET')}</Button>
        </Col>
        <div className="d-flex justify-content-center text-red">
          IP显示方式,不指明下级的表示公司也会被包括。
        </div>
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SystemSetting);