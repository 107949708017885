import { SET_AGENT_DOWNLINE_REPORT, SET_CLASSIFICATION_REPORT, SET_DEPOSITAND_WITH_DRAW_REPORT, SET_DEPOSITAND_WITH_DRAW_STATISTIC, SET_DEPOSITAND_WITH_DRAW_TOTAL_REPORT, SET_FINANCIAL_REPORT, SET_MEMBER_DOWNLINE_REPORT, SET_MEMBER_SUMMATION_DETAIL_REPORT, SET_MEMBER_SUMMATION_REPORT, SET_STATISTIC_REPORT, SET_WIN_LOST_REPORT } from '../../actions/actionTypes';

const initialState = {
  financialReport: undefined,
  depositWithdrawReport: undefined,
  depositWithdrawTotalStatistic: undefined,
  depositWithdrawStatistic: undefined,
  memberSummationReport: undefined,
  memberSummationDetailReport: undefined,
  memberDownlineReport: undefined,
  agentDownlineReport: undefined,
  winLostReport: undefined,
  classificationReport: undefined,
  statisticReport: undefined,
};

const reportReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_FINANCIAL_REPORT:
      return {
        ...state, financialReport: action.value
      };

    case SET_DEPOSITAND_WITH_DRAW_REPORT:
      return {
        ...state, depositWithdrawReport: action.value
      };
    case SET_DEPOSITAND_WITH_DRAW_TOTAL_REPORT:
      return {
        ...state, depositWithdrawTotalStatistic: action.value
      };
    case SET_DEPOSITAND_WITH_DRAW_STATISTIC:
      return {
        ...state, depositWithdrawStatistic: action.value
      };

    case SET_MEMBER_SUMMATION_REPORT:
      return {
        ...state, memberSummationReport: action.value
      };
    case SET_MEMBER_SUMMATION_DETAIL_REPORT:
      return {
        ...state, memberSummationDetailReport: action.value
      };

    case SET_AGENT_DOWNLINE_REPORT:
      return {
        ...state, agentDownlineReport: action.value
      };
    case SET_MEMBER_DOWNLINE_REPORT:
      return {
        ...state, memberDownlineReport: action.value
      };
    case SET_WIN_LOST_REPORT:
      return {
        ...state, winLostReport: action.value
      };
    case SET_CLASSIFICATION_REPORT:
      return {
        ...state, classificationReport: action.value
      };
    case SET_STATISTIC_REPORT:
      return {
        ...state, statisticReport: action.value
      };
    default:
      return state;
  }
};

export default reportReducers;