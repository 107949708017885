
import { bigDs, dxds, extremeDx, smallDs } from '../Items/dsdx';

import { filler, specialNum3, sscType } from '../Items/others';
import { threeColors } from '../Items/colors';

import { PCDD0to27 } from '../Items/numbers';

const CJ_PCDD = [{
  displayName: '两面',
  hideMenu: 1,
  isStaticRow: 1,
  combineStyle: 2,
  repeatPerRow: 2,
  repeatPerCol: 2,
  settingCode: 'DS,DW,DX,HMDZ,JDX,LM,TMBS,TMSB',
  display: [[...PCDD0to27],
    [...dxds, ...extremeDx, ...bigDs, ...smallDs],
    [],
    [...threeColors, ...sscType.slice(0, 1), ...specialNum3, ...filler]
  ],
  firstTableHeader: ['和值', '两面', '', '色波/豹子/包三'],
  prefix: ['HZ', '', '', ''],
  displayRows: [14, 5, 0, 3],
}
];

export default CJ_PCDD;