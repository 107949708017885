import { SET_BET_HISTORY_LIST, SET_REBATE_LIST, SET_THIRD_GAMES_ACCOUNT, SET_THIRD_GAMES_AMOUNTS, SET_THIRD_PARTY_GAMES_EDIT, SET_THIRD_PARTY_GAMES_LIST, SET_THIRD_PARTY_LIST, SET_THIRD_PARTY_REBATE_SETTING, SET_THIRD_PARTY_WIN_LOST_AGENT_REPORT, SET_TRANSACTION_DETAIL, SET_TRANSACTION_FLOW, SET_TRANSACTION_MANAGE, UPDATE_THIRD_GAMES_AMOUNTS } from '../../actions/actionTypes';

const initialState = {
  thirdPartyGamesList: undefined,
  thirdBetHistory: undefined,
  thirdPartyGamesEdit: undefined,
  rebate: undefined,
  thirdPartyList: undefined,
  thirdPartyRebateSetting: undefined,
  thirdGamesAccount: undefined,
  thirdGamesAmounts: [],
  thirdPartyWinLostAgentReport: undefined,

  transactionManage: undefined,
  transactionFlows: undefined,
  transactionDetail: undefined,
};

const thirdPartyGamesReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_TRANSACTION_MANAGE:
      return {
        ...state, transactionManage: action.value
      };
    case SET_TRANSACTION_FLOW:
      return {
        ...state, transactionFlows: action.value
      };
    case SET_TRANSACTION_DETAIL:
      return {
        ...state, transactionDetail: action.value
      };
    case SET_THIRD_PARTY_WIN_LOST_AGENT_REPORT:
      return {
        ...state, thirdPartyWinLostAgentReport: action.value
      };
    case SET_THIRD_PARTY_GAMES_LIST:
      return {
        ...state, thirdPartyGamesList: action.value
      };
    case SET_THIRD_PARTY_LIST:
      return {
        ...state, thirdPartyList: action.value
      };
    case SET_BET_HISTORY_LIST:
      return {
        ...state, thirdBetHistory: action.value
      };
    case SET_REBATE_LIST:
      return {
        ...state, rebate: action.value
      };
    case SET_THIRD_PARTY_GAMES_EDIT:
      return {
        ...state, thirdPartyGamesEdit: action.value
      };
    case SET_THIRD_PARTY_REBATE_SETTING:
      return {
        ...state, thirdPartyRebateSetting: action.value
      };
    case SET_THIRD_GAMES_ACCOUNT:
      return {
        ...state, thirdGamesAccount: action.value
      };
    case SET_THIRD_GAMES_AMOUNTS:
    {
      return {
        ...state,
        thirdGamesAmounts: action?.value ? [...state.thirdGamesAmounts, action.value] : [],
      };
    }
    case UPDATE_THIRD_GAMES_AMOUNTS: {
      const index = state.thirdGamesAmounts.findIndex(thirdGamesAmounts => thirdGamesAmounts.memberId === action.value.memberId && thirdGamesAmounts.gameCode === action.value.gameCode);
      const newArray = [...state.thirdGamesAmounts];
      if (index !== -1) {
        newArray[index].amount = action.value?.amount;
        newArray[index].msg = action.value?.msg;
        newArray[index].status = action.value?.status || '';
      }
      return {
        ...state,
        thirdGamesAmounts: newArray,
      };
    }
    default:
      return state;
  }
};

export default thirdPartyGamesReducers;