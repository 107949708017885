

import { connect } from 'react-redux';

import './styles.scss';

const mapStateToProps = state => ({
  inTimeSheetOdds: state.inTimeSheetReducers.inTimeSheetOdds,
});

const Profit = ({ obj, betItemStyle, _OddsListObj }) => (
  <div className={`${betItemStyle} profitWrapper `}>
    {_OddsListObj && _OddsListObj[obj.code]?.enabled === 1 ? _OddsListObj[obj.code]?.winLost : 0}
  </div>
);

export default connect(mapStateToProps)(Profit);