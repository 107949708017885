import { StatusCodes } from 'http-status-codes';

import { SET_BET_HISTORY_LIST, SET_REBATE_LIST, SET_THIRD_GAMES_ACCOUNT, SET_THIRD_GAMES_AMOUNTS, SET_THIRD_PARTY_GAMES_EDIT, SET_THIRD_PARTY_GAMES_LIST, SET_THIRD_PARTY_LIST, SET_THIRD_PARTY_REBATE_SETTING, SET_THIRD_PARTY_WIN_LOST_AGENT_REPORT, SET_TRANSACTION_DETAIL, SET_TRANSACTION_MANAGE, UPDATE_THIRD_GAMES_AMOUNTS } from '../actionTypes';
import { apiService } from '../../../library';
import appActions from '../appActions';

const setTransactionList = data => ({
  type: SET_TRANSACTION_MANAGE,
  value: data,
});

const setTransactionDetail = data => ({
  type: SET_TRANSACTION_DETAIL,
  value: data,
});

const setThirdPartyList = list => ({
  type: SET_THIRD_PARTY_GAMES_LIST,
  value: list
});

const setThirdPartyDropDownList = list => ({
  type: SET_THIRD_PARTY_LIST,
  value: list
});

const setThirdPartyWinLostAgentReport = data => ({
  type: SET_THIRD_PARTY_WIN_LOST_AGENT_REPORT,
  value: data
});

const setBetHistoryList = list => ({
  type: SET_BET_HISTORY_LIST,
  value: list
});

const setRebateList = list => ({
  type: SET_REBATE_LIST,
  value: list
});

const setThirdPartyEdit = obj => ({
  type: SET_THIRD_PARTY_GAMES_EDIT,
  value: obj
});

const setThirdPartyRebateSetting = obj => ({
  type: SET_THIRD_PARTY_REBATE_SETTING,
  value: obj
});

const setThirdPartyGamesAccount = obj => ({
  type: SET_THIRD_GAMES_ACCOUNT,
  value: obj
});

const setThirdAmount = obj => ({
  type: SET_THIRD_GAMES_AMOUNTS,
  value: obj
});

const updateThirdAmount = obj => ({
  type: UPDATE_THIRD_GAMES_AMOUNTS,
  value: obj
});

const ResetData = () => dispatch => dispatch(setThirdPartyRebateSetting(undefined));
const ResetThirdAmount = () => dispatch => dispatch(setThirdAmount(undefined));

const SyncRebateSetting = (obj = {}, callback = () => {}) => dispatch => {
  dispatch(appActions.updateModalAlert({
    visible: false
  }));
  apiService.post('ThirdPartyGames/SyncRebateSetting', {
    ...obj
  })
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          dispatch(appActions.updateModalAlert({
            visible: true,
            type: 'success',
            data: {
              message: data.msg,
            }
          }));
          return callback(false);
        }
      }
    }).catch(error => {
      if (error?.response?.data?.state) {
        dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'confirmation',
          data: {
            message: error.response.data.msg
          }
        }));
      }
      Promise.reject(error);
    });
};

const GetRebateSetting = (obj, callback = () => {}) => dispatch => {
  apiService.get('ThirdPartyGames/rebateSetting', new URLSearchParams(obj))
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          callback();
          return dispatch(setThirdPartyRebateSetting(data.result));
        }
      }
    }).catch(error => {
      Promise.reject(error);
    });
};

const SaveRebateSetting = (obj = {}, callback = () => {}) => dispatch => {
  dispatch(appActions.updateModalAlert({
    visible: false
  }));
  apiService.post('ThirdPartyGames/SaveRebateSetting', {
    ...obj
  })
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          dispatch(appActions.updateModalAlert({
            visible: true,
            type: 'success',
            data: {
              message: data.msg,
            }
          }));
          return callback();
        }
      }
    }).catch(error => {
      if (error?.response?.data?.state) {
        dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'confirmation',
          data: {
            message: error.response.data.msg
          }
        }));
      }
      Promise.reject(error);
    });
};

const GetInfo = (callback = () => {}) => dispatch => {
  apiService.get('ThirdPartyGames/GetInfo')
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          callback(false);
          return dispatch(setThirdPartyList(data.result));
        }
      }
    }).catch(error => {
      Promise.reject(error);
    });
};

const GetThirdPartyGames = () => dispatch => {
  apiService.get('common/GetThirdPartyGames')
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          return dispatch(setThirdPartyDropDownList(data.result));
        }
      }
    }).catch(error => {
      Promise.reject(error);
    });
};

const EditThirdPartyGame = (id, callback = () => {}) => dispatch => {
  apiService.get('ThirdPartyGames/EditThirdPartyGame', new URLSearchParams({
    id
  }))
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          callback();
          return dispatch(setThirdPartyEdit(data.result));
        }
      }
    }).catch(error => {
      Promise.reject(error);
    });
};

const SaveThirdPartyGame = (obj = {}, callback = () => {}) => dispatch => {
  apiService.post('ThirdPartyGames/SaveThirdPartyGame', {
    ...obj
  })
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          callback();
          return dispatch(setThirdPartyEdit(data.result));
        }
      }
    }).catch(error => {
      Promise.reject(error);
    });
};

const BetHistory = (obj, callback = () => {}) => dispatch => {
  apiService.get('ThirdPartyGames/BetHistory', new URLSearchParams(obj))
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          callback(false);
          return dispatch(setBetHistoryList(data.result));
        }
      }
    }).catch(error => {
      Promise.reject(error);
    });
};

const GetRebate = (obj, callback = () => {}) => dispatch => {
  apiService.get('ThirdPartyGames/Rebate', new URLSearchParams(obj))
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          callback();
          return dispatch(setRebateList(data.result));
        }
      }
    }).catch(error => {
      Promise.reject(error);
    });
};

const SaveRebate = (list, callback = () => {}) => dispatch => {
  apiService.post('ThirdPartyGames/SaveRebate', list)
    .then(res => {
      const { data, status } = res || {};
      dispatch(appActions.updateModalAlert({
        visible: false,
      }));
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          dispatch(appActions.updateModalAlert({
            type: 'success',
            visible: true,
            data: {
              message: data.msg
            }
          }));
          return callback();
        }
      }
    }).catch(error => {
      Promise.reject(error);
    });
};

const CancelRebate = (list, callback = () => {}) => dispatch => {
  dispatch(appActions.updateModalAlert({
    visible: false,
  }));
  apiService.post('ThirdPartyGames/CancelRebate', list)
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          dispatch(appActions.updateModalAlert({
            type: 'success',
            visible: true,
            data: {
              message: data.msg
            }
          }));
          return callback();
        }
      }
    }).catch(error => {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
      Promise.reject(error);
    });
};


const GetGamesAccount = (memberId, callback = () => {}) => dispatch => {
  apiService.get(`ThirdPartyGames/GetGamesAccount/${memberId}`)
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          callback(false);
          return dispatch(setThirdPartyGamesAccount(data.result));
        }
      }
    }).catch(error => {
      Promise.reject(error);
    });
};

const GetAllBalance = (memberId, dataList = []) => dispatch => {
  const apiArr = dataList.map(item => new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_API_URL}/ThirdPartyGames/LoadAmount?memberId=${memberId}&gameCode=${item}`, {
      method: 'GET',
      credentials: 'include',
    }).then(response => response.json())
      .then(data => {
        if (Number(data.state) === 1) {
          dispatch(setThirdAmount({
            memberId,
            gameCode: item,
            amount: data?.result?.amount || data?.result?.msg || 0
          }));
        } else {
          dispatch(setThirdAmount({
            memberId,
            gameCode: item,
            amount: 0,
            msg: data?.msg
          }));
        }
        resolve(data);
      })
      .catch(err => {
        reject(err);
      });
  }));

  Promise.allSettled(apiArr).then(() => {});
};


const ToggleTransferStatus = (obj = {}) => dispatch => {
  apiService.post('ThirdPartyGames/ToggleTransferStatus', {
    ...obj
  })
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          return dispatch(GetGamesAccount(obj?.memberId));
        }
      }
    }).catch(error => {
      if (error.response.data?.state) {
        dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'confirmation',
          data: {
            message: error.response.data.msg
          }
        }));
      }
      Promise.reject(error);
    });
};


const RefreshThirdAmount = (obj = {}) => dispatch => {
  dispatch(updateThirdAmount({
    memberId: obj.memberId,
    gameCode: obj.gameCode,
    amount: 'loading..'
  }));
  apiService.get('ThirdPartyGames/LoadAmount', new URLSearchParams(obj))
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          dispatch(updateThirdAmount({
            memberId: obj.memberId,
            gameCode: obj.gameCode,
            amount: data.result.amount || 0
          }));
        }
      }
    }).catch(error => {
      dispatch(updateThirdAmount({
        memberId: obj.memberId,
        gameCode: obj.gameCode,
        amount: 0
      }));
      Promise.reject(error);
    });
};


const TransferGameAmount = (memberId, dataList = []) => dispatch => {
  const apiArr = dataList.map(item => new Promise((resolve, reject) => {
    dispatch(updateThirdAmount({
      memberId,
      gameCode: item?.gameCode,
      amount: 'loading..'
    }));
    fetch(`${process.env.REACT_APP_API_URL}/ThirdPartyGames/TransferGameAmount`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        memberId,
        gameCode: item?.gameCode,
        acAmount: item?.acAmount
      }),
    }).then(response => response.json())
      .then(data => {
        if (Number(data.state) === 1) {
          dispatch(updateThirdAmount({
            memberId,
            gameCode: item?.gameCode,
            amount: data?.result?.amount || data?.result?.statusInfo
          }));
        } else {
          dispatch(updateThirdAmount({
            memberId,
            gameCode: item?.gameCode,
            amount: '0',
            msg: data?.msg,
            status: 'error',
          }));
        }
        resolve(data);
      })
      .catch(err => {
        reject(err);
      });
  }));

  Promise.allSettled(apiArr).then(() => {});
};

const GenerateRebate = obj => dispatch => {
  apiService.get('ThirdPartyGames/GenerateRebate', new URLSearchParams(obj))
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          return dispatch(appActions.updateModalAlert({
            type: 'success',
            visible: true,
            data: {
              message: data.msg
            }
          }));
        }
      }
    }).catch(error => {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
      Promise.reject(error);
    });
};

const GetWinLostAgentReport = (obj, callback = () => {}) => dispatch => {
  apiService.get('ThirdPartyGames/WinLostAgentReport', new URLSearchParams(obj))
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          callback(false);
          return dispatch(setThirdPartyWinLostAgentReport(data.result));
        }
      }
    }).catch(error => {
      callback(false);
      dispatch(setThirdPartyWinLostAgentReport());
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
      Promise.reject(error);
    });
};

const GetWinLostMemberReport = (obj, callback = () => {}) => dispatch => {
  apiService.get('ThirdPartyGames/WinLostMemberReport', new URLSearchParams(obj))
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          callback(false);
          return dispatch(setThirdPartyWinLostAgentReport(data.result));
        }
      }
    }).catch(error => {
      callback(false);
      dispatch(setThirdPartyWinLostAgentReport());
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
      Promise.reject(error);
    });
};

const GetCompanyBetReport = (obj, callback = () => {}) => dispatch => {
  apiService.get('ThirdPartyGames/CompanyBetReport', new URLSearchParams(obj))
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          return callback(data.result);
        }
      }
    }).catch(error => {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
      Promise.reject(error);
    });
};

const GetMemberBetReport = (obj, callback = () => {}) => dispatch => {
  apiService.get('ThirdPartyGames/MemberBetReport', new URLSearchParams(obj))
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          return callback(data.result);
        }
      }
    }).catch(error => {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
      Promise.reject(error);
    });
};

const GetMemberBetReportByDays = (obj, callback = () => {}) => dispatch => {
  apiService.get('ThirdPartyGames/MemberBetReportByDays', new URLSearchParams(obj))
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          return callback(data.result);
        }
      }
    }).catch(error => {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
      Promise.reject(error);
    });
};

const GetTransactionList = (obj, callback = () => {}) => dispatch => {
  apiService.get('/ThirdPartyGames/GetTransactionList', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      callback(false);
      return dispatch(setTransactionList(data.result));
    }
  }).catch(error => {
    callback(false);
    dispatch(setTransactionList());
    dispatch(appActions.updateModalAlert({
      visible: true,
      type: 'confirmation',
      data: {
        message: error.response.data.msg
      }
    }));
    Promise.reject(error);
  });
};

const GetTransactionDetail = (id, orderId) => dispatch => {
  apiService.get('/ThirdPartyGames/GetTransactionDetail', new URLSearchParams({
    id, orderId
  })).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      return dispatch(setTransactionDetail(data.result));
    }
  }).catch(error => {
    dispatch(setTransactionDetail());
    Promise.reject(error);
  });
};

export default {
  GetInfo,
  EditThirdPartyGame,
  GetRebate,
  SaveThirdPartyGame,
  BetHistory,
  GetThirdPartyGames,
  CancelRebate,
  SaveRebate,
  GetRebateSetting,
  ResetData,
  SaveRebateSetting,
  SyncRebateSetting,
  GetGamesAccount,
  GetAllBalance,
  ResetThirdAmount,
  ToggleTransferStatus,
  TransferGameAmount,
  RefreshThirdAmount,
  GenerateRebate,
  GetWinLostAgentReport,
  GetWinLostMemberReport,

  GetCompanyBetReport,
  GetMemberBetReport,
  GetMemberBetReportByDays,
  GetTransactionList,
  GetTransactionDetail
};