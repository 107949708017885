import { SET_PANKOU_LOTTERY_RESULT, SET_RESULT } from '../../actions/actionTypes';

const initialState = {
  panKouLotteryResult: undefined,
  resultList: undefined,
};

const lotteryResultReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_PANKOU_LOTTERY_RESULT:
      return {
        ...state, panKouLotteryResult: action.value
      };
    case SET_RESULT:
      return {
        ...state, resultList: action.value
      };
    default:
      return state;
  }
};

export default lotteryResultReducers;