import { useEffect, useState } from 'react';

import { Button, Col, Input, Label, Row } from 'reactstrap';
import { connect } from 'react-redux';

import { Select } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { appActions, systemActions } from '../../../redux/actions';
import AgentDataTable from '../../AgentDataTable';
import Pagination from '../../Content/Pagination';
import './styles.scss';


const mapStateToProps = state => ({
  otpList: state.systemReducers.otpList,
  userData: state.memberReducers.userData,
});

const mapDispatchToProps = dispatch => ({
  getOTPList: (obj, callback) => dispatch(systemActions.getOTPList(obj, callback)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  setThirdPartyPayStatus: (obj, callback) => dispatch(systemActions.setThirdPartyPayStatus(obj, callback)),
  copyThirdPartyPaySetting: (obj, callback) => dispatch(systemActions.copyThirdPartyPaySetting(obj, callback)),
  updateThirdPartyPaySorting: (obj, callback) => dispatch(systemActions.updateThirdPartyPaySorting(obj, callback)),
});

const OTPList = ({ otpList, getOTPList }) => {
  const { t } = useTranslation();
  const [_info, setInfo] = useState([]);
  const [_page, setPage] = useState(1);
  const [_rows, setRows] = useState(15);
  const [_totalRecord, setTotalRecord] = useState(0);
  const [_isQueryingDetail, setIsQueryingDetail] = useState(true);
  const [isQueryStatus, setQueryStatus] = useState(true);
  const [_receiver, setsReceiver] = useState('');
  const [_sender, setSender] = useState('');
  const [_type, setType] = useState('');

  useEffect(() => {
    if (otpList && JSON.stringify(otpList?.otpList) !== JSON.stringify(_info)) {
      setInfo(otpList?.otpList);
      setTotalRecord(otpList?.totalRecords);
      setQueryStatus(false);
    }
  }, [otpList]);

  useEffect(() => {
    setIsQueryingDetail(true);
    getOTPList({
      page: _page,
      rows: _rows,
    }, setIsQueryingDetail);
  }, []);

  const findClick = () => {
    // Page number and {row number will follow the pagination component instead
    getOTPList({
      sender: _sender,
      type: _type,
      receiver: _receiver,
      page: 1,
      rows: _rows,
    }, setIsQueryingDetail);
  };

  const onPaginationClick = (pageNum, rows) => {
    setIsQueryingDetail(false);
    // Page number and {row number will follow the pagination component instead
    getOTPList({
      sender: _sender,
      type: _type,
      receiver: _receiver,
      page: pageNum,
      rows: _rows,
    }, setIsQueryingDetail);
    setPage(pageNum);
    setRows(rows);
  };

  const type = [
    {
      label: t('ALL'),
      value: '',
    },
    {
      label: t('EMAIL'),
      value: 1,
    },
    {
      label: t('PHONE'),
      value: 0,
    }
  ];

  const tableHeader = [{
    label: t('OPERATE_TIME'),
  }, {
    label: t('OTP_NUMBER'),
  }, {
    label: t('OTP_TYPE'),
  }, {
    label: t('RECEIVER_ADDRESS'),
  }, {
    label: t('OPERATE_USER'),
  }, {
    label: t('STATEMENT'),
  }];

  const tableContent = [
    data => (<Label className="d-flex justify-content-center">{data?.date && moment.unix(data?.date).format('YYYY-MM-DD hh:mm:ss')}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.otp}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.type}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.receiver}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.sender}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.info}</Label>),
  ];

  return (
    <div className="OTPListWrapper">
      <div className="mx-0 bg-white my-2  border-1">
        <Row className="p-0 m-0 mb-2">
          <Col className="p-1 mb-2 filterWrapper d-flex align-items-center w-100">
            <Col className="d-flex align-items-center">
              <Label className="mx-3">{t('RECEIVER_ADDRESS')}</Label>
              <Input type="text" className="mx-2 justify-content-center" value={_receiver} onChange={({ target: { value: text } }) => setsReceiver(text)} />
              <Label className="px-3 marginLeft5">{t('OPERATE_USER')}</Label>
              <Input type="text" className="mx-2 justify-content-center" value={_sender} onChange={({ target: { value: text } }) => setSender(text)} />
              <Label className="mx-3 marginLeft5">{t('TYPE')}</Label>
              <div className="smallInput">
                <Select
                  value={_type}
                  placeholder="请选择"
                  className="px-2 marginLeft5"
                  onChange={val => setType(val)}
                >
                  {
                                type.map(d => (
                                  <Select.Option key={`setReportUnit_${d.value}`} label={d.label} value={d.value} />
                                ))
                          }
                </Select>
              </div>
              <Button className="button find mx-3" onClick={() => { setIsQueryingDetail(true); findClick(); }}>{t('FIND')}</Button>
            </Col>
          </Col>
        </Row>
        <Row className="bigWrapper m-0 my-1 p-3">
          <AgentDataTable
            isQuerying={_isQueryingDetail}
            tableHeaderTitle={tableHeader}
            tableData={_info || (isQueryStatus ? [1, 2, 3] : [])}
            tableBodyRowProperty={tableContent}
          />
        </Row>
        <Row className="justify-center w-full relative">
          <Pagination totalRecord={_totalRecord || 0} onClick={onPaginationClick} isQuery={_isQueryingDetail} />
        </Row>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(OTPList);