import { useEffect, useState } from 'react';

import { Button, Checkbox, Input, InputNumber, Radio } from 'antd';
import { Col, Label, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { agentActions, appActions, commonActions, memberActions } from '../../../../redux/actions';
import AgentDataTable from '../../../AgentDataTable';
import { SectionToChinese } from '../../../../constants/utils';
import './styles.scss';

const mapStateToProps = state => ({
  userData: state.memberReducers.userData,
  userInfo: state.memberReducers.userInfo,
  isValidNameMsg: state.agentReducers.isValidNameMsg,
  isValidLoginIdMsg: state.agentReducers.isValidLoginIdMsg,
  editMemDetailData: state.memberReducers.editMemDetailData,
  accountType: state.commonReducers.accountType,
});

const mapDispatchToProps = dispatch => ({
  getMemberDetail: (id, callback) => dispatch(memberActions.GetMemberDetail(id, callback)),
  SaveMember: (obj, callback) => dispatch(memberActions.SaveMember(obj, callback)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  isValidName: (userId, callback) => dispatch(agentActions.isValidName(userId, callback)),
  isExistLoginId: (userId, callback) => dispatch(agentActions.isExistLoginId(userId, callback)),
  resetReturnMsgData: () => dispatch(agentActions.resetReturnMsgData()),
  resetSubAccountInfo: () => dispatch(agentActions.resetSubAccountInfo()),
  SetAccountQuota: (obj, callback) => dispatch(agentActions.SetAccountQuota(obj, callback)),
  GenSignKey: (id, callback) => dispatch(memberActions.GenSignKey(id, callback)),
  GetAccountType: () => dispatch(commonActions.GetAccountType()),
});

const MemberEdit = ({ GetAccountType, GenSignKey, accountType, SetAccountQuota, getMemberDetail, SaveMember, userInfo, callback = () => { }, onCancel = () => { }, resetReturnMsgData, resetSubAccountInfo, editMemDetailData, data, updateModalAlert, userData, isValidName, isValidNameMsg, isValidLoginIdMsg }) => {
  const { t } = useTranslation();
  const [_password, setPassword] = useState();
  const [_name, setName] = useState();
  const [_nameReturnMsg, setNameReturnMsg] = useState({});
  const setLoginIdReturnMsg = useState({})[1];
  const [_passwordMsg, setPasswordMsg] = useState('');
  const [isQuery, setIsQuery] = useState(true);

  const [checkAll1, setCheckAll1] = useState(false);

  const [signKey, setSignKey] = useState('');

  // editMemDetailData 数据
  const [pkList, setPkList] = useState(['A']);

  const [UseState, setUseState] = useState(data?.useStateValue); // 账号状态

  // 彩种管理
  const [lotteryMenu, setLotteryMenu] = useState([]);
  const leftLotteryMenu = lotteryMenu.reduce((acc, item) => {
    const exist = acc.find(i => i.categoryCode === item.categoryCode);
    if (!exist) {
      acc.push({
        categoryName: item.categoryName,
        categoryCode: item.categoryCode,
      });
    }
    return acc;
  }, []).sort((a, b) => ((a.orderBy > b.orderBy) ? -1 : 1));


  const [accountInfo, setAccountInfo] = useState([]); // 只放额度

  const [zcList, setZcList] = useState([]);

  useEffect(() => {
    GetAccountType();
    if (data) {
      getMemberDetail(data?.orgId || data?.memberId);
    }
  }, [data]);

  const memInfo = editMemDetailData?.memInfo || {};
  useEffect(() => {
    resetSubAccountInfo();
    resetReturnMsgData();
    if (editMemDetailData && editMemDetailData?.memInfo) {
      const memInfo = editMemDetailData?.memInfo || {};
      setPkList(memInfo?.gameType && memInfo?.gameType?.split(''));
      const accountInfo = [];
      if (memInfo?.accList) {
        accountType.forEach(item => {
          const acc = memInfo?.accList.find(x => x.categoryId === item.accountType);
          let { accName } = item;
          if (userData?.accountOption === 0 && item.accountType === 2) {
            accName = '额度';
          }
          if (acc) {
            accountInfo.push({
              ...acc, accName
            });
          }
        });
      }
      setAccountInfo(accountInfo);
      setName(memInfo?.name);
      setSignKey(memInfo?.signKey ?? '');

      const _lotteryMenu = (editMemDetailData?.parentLotList || []).map(item => {
        const checked = (editMemDetailData?.lotList || []).find(i => i.code === item.code);
        return {
          ...item,
          checked,
        };
      });
      setLotteryMenu(_lotteryMenu);
      setZcList(editMemDetailData?.zcList || []);
      setIsQuery(false);
    }
  }, [editMemDetailData]);

  useEffect(() => {
    setNameReturnMsg(isValidNameMsg);
  }, [isValidNameMsg]);

  useEffect(() => {
    setLoginIdReturnMsg(isValidLoginIdMsg);
  }, [isValidLoginIdMsg]);

  const saveButton = () => {
    updateModalAlert({
      type: 'confirmation',
      visible: true,
      data: {
        message: '请确认是否要设置',
        onConfirmationClick: () => changData()
      }
    });
    const changData = () => {
      const lotteryValues = lotteryMenu.filter(checkbox => checkbox.checked).map(checkbox => checkbox.id);
      updateModalAlert({
        type: 'loading',
        visible: true
      });
      const parentZcListRes = editMemDetailData?.parentZcList || [];
      const DUOYUDE = parentZcListRes.filter(i => !zcList.find(z => z.categoryCode === i.categoryCode));
      const obj = {
        zcList: zcList.map(item => ({
          categoryCode: item.categoryCode,
          rateForMe: item.rateForMe,
          minZc: item.minZc,
          avairableRateForDown: item.availableRateForDown,
          availableRateForDown: item.availableRateForDown,
          rateForUp: item.rateForUp,
          replenishment: item.replenishment
        })).concat(DUOYUDE.map(item => ({
          categoryCode: item.categoryCode,
          rateForMe: item.rateForMe,
          minZc: item.minZc,
          avairableRateForDown: item.avairableRateForDown,
          availableRateForDown: item.avairableRateForDown,
          rateForUp: item.rateForUp,
          replenishment: item.replenishment
        }))),
        isUpdate: true,
        lotteryType: lotteryValues || [],
        memInfo: {
          memberId: data.memberId, // 比创建多的信息
          UseState,
          isChild: userData.isChild,
          type: 1,
          organizationId: userInfo.orgId,
          limitType: editMemDetailData?.memInfo?.limitType,
          loginId: editMemDetailData?.memInfo?.loginId,
          name: _name,
          gameType: pkList.join(''),
          password: _password,
        }
      };
      SaveMember(obj, callback);
    };
  };

  const handleNameOnBlur = text => {
    setName(text);
    if (text?.length > 3) {
      isValidName(text);
    }
  };

  const handlePasswordChange = text => {
    setPassword(text);
    if (text?.length > 5 && text?.length < 21 && text.match(/^[0-9a-zA-Z]+$/)) {
      if (/[0-9]/.test(text) && /[a-z]/.test(text) && /[A-Z]/.test(text)) {
        setPasswordMsg({
          state: 1,
          msg: '密码可用'
        });
      } else {
        setPasswordMsg({
          state: 99,
          msg: '密码必须由字母数字组成（区分大小写）！'
        });
      }
    } else {
      setPasswordMsg({
        state: 99,
        msg: '密码必须由字母数字组成，6-20位（区分大小写）！'
      });
    }
  };

  const tableHeader = [{
    label: '主菜单',
  }, {
    label: (
      <>
        子菜单项
        <Checkbox
          className="mx-1"
          checked={checkAll1}
          onChange={e => {
            setCheckAll1(e.target.checked);
          }}
        >
          选择所有
        </Checkbox>
      </>),
  }];

  const tableContent2 = [
    data => (
      <div className="d-flex justify-content-end no-wrap m-1 p-1">
        <span className="mx-2">{data?.categoryName}</span>
        <Checkbox
          className="w-all-checkbox"
          onChange={e => {
            const { checked } = e.target;
            setLotteryMenu(
              lotteryMenu.map(item => {
                if (item.categoryCode === data.categoryCode) {
                  return {
                    ...item, checked,
                  };
                }
                return item;
              })
            );
          }}
        >
          所有
        </Checkbox>
      </div>
    ),
    data => {
      const _menus = lotteryMenu.filter(x => x.categoryCode === data.categoryCode);
      return (
        <>
          {
            _menus.map(d => (
              <p key={d.code} className="d-inline border-0 m-1">
                <Checkbox
                  className="w-single-checkbox"
                  checked={d.checked}
                  onChange={e => {
                    const index = lotteryMenu.findIndex(i => i.id === d.id);
                    const item = lotteryMenu[index];
                    setLotteryMenu([
                      ...lotteryMenu.slice(0, index),
                      {
                        ...item,
                        checked: e.target.checked
                      },
                      ...lotteryMenu.slice(index + 1),
                    ]);
                  }}
                >
                  {d.lotteryName}
                </Checkbox>
              </p>
            ))
          }
        </>
      );
    }
  ];

  const tableContent3 = [
    data => (
      <div className="d-flex justify-content-center no-wrap m-1 p-1">
        <span className="mx-2">{data?.categoryName}</span>
      </div>
    ),
    data => {
      const memZcItem = zcList.find(i => i.categoryCode === data.categoryCode) || {};
      return (
        <div className="text-red d-flex justify-content-center">
          <InputNumber
            value={memZcItem.rateForMe}
            onChange={value => {
              const index = zcList.findIndex(i => i.categoryCode === data.categoryCode);
              if (index !== -1) {
                setZcList([
                  ...zcList.slice(0, index),
                  {
                    ...(zcList[index] || {}),
                    rateForMe: value
                  },
                  ...zcList.slice(index + 1),
                ]);
              }
            }}
          />
        </div>
      );
    },
    data => (
      <div className="text-red d-flex justify-content-center">
        {
          `（本级占成范围在 ${data.minZc || 0}%与${data.availableRateForDown || 0}% ）之间`
        }
      </div>
    )
  ];

  const pkOptions = (editMemDetailData?.gameType || '').split('').map(item => ({
    label: item, value: item
  }));
  const allowMultiGameType = editMemDetailData?.allowMultiGameType;
  const suportBetApi = editMemDetailData?.suportBetApi;

  const saveCreditBalance = accType => {
    const newValue = accountInfo?.find(x => x.categoryId === accType)?.balance ?? 0;
    const originValue = memInfo?.accList.find(x => x.categoryId === accType)?.balance ?? 0;
    const gapValue = Number(newValue) - Number(originValue);
    const obj = {
      userId: data.memberId,
      userType: 1,
      accType,
      updateType: gapValue > 0 ? 0 : 1,
      value: Math.abs(gapValue)
    };
    SetAccountQuota(obj);
  };

  const creatSignKey = () => {
    GenSignKey(data.memberId, data => setSignKey(data));
  };


  return (
    <div className="MemberEditWrapper">
      <div className="mx-0 bg-white my-3  border-1">
        <Row className="titleWrapper m-0 my-2 p-2">
          账户资料
        </Row>
        <Row className="bigWrapper m-0 my-3 p-3">
          <table className="agentDataTable p-3 m-0" cellSpacing="0" cellPadding="0" border="0">
            <tbody>
              <tr>
                <td className="tableHeader col-5">
                  {memInfo.parentLevelName || ''}
                  帐号
                </td>
                <td>
                  <span>
                    {memInfo?.parentLoginId}
                  </span>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-5">会员帐号</td>
                <td>
                  <span>
                    {data.loginId}
                  </span>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-5">登入密码</td>
                <td>
                  <span>
                    <Input
                      className="d-inline w-select"
                      value={_password}
                      onChange={({ target: { value: text } }) => handlePasswordChange(text)}
                    />
                  </span>
                  <Label className={`d-inline mx-2 ${_passwordMsg?.state === 1 ? 'text-success' : 'text-danger'}`}>{_passwordMsg?.msg}</Label>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-5">会员名称</td>
                <td>
                  <span>
                    <Input
                      className="d-inline w-select"
                      value={_name}
                      onChange={({ target: { value: text } }) => setName(text)}
                      onBlur={({ target: { value: text } }) => handleNameOnBlur(text)}
                    />
                    <Label className={`d-inline mx-2 ${_nameReturnMsg?.state === 1 ? 'text-success' : 'text-danger'}`}>{_nameReturnMsg?.msg}</Label>
                  </span>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-5">可用盘类</td>
                <td>
                  {
                    allowMultiGameType ? (
                      <Checkbox.Group
                        options={pkOptions}
                        value={pkList}
                        onChange={values => setPkList(values)}
                      />
                    ) : (
                      <Radio.Group
                        options={pkOptions.map(item => item.value)}
                        value={pkList && pkList[0]}
                        onChange={e => setPkList([e.target.value])}
                      />
                    )
                  }
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-5">额度模式</td>
                <td>
                  {editMemDetailData?.orgParent?.limitType === 1
                    ? (
                      <span>
                        <Radio disabled checked={memInfo?.limitType === 1}>
                          信用模式
                        </Radio>
                        <Radio disabled checked={memInfo?.limitType === 2}>
                          现金模式
                        </Radio>
                      </span>
                    )
                    : (
                      <span>
                        <Radio disabled checked={memInfo?.limitType === 2}>
                          现金模式
                        </Radio>
                      </span>
                    )}
                </td>
              </tr>
              {
                accountInfo?.length > 0
                    && accountInfo?.map(item => (
                      <tr>
                        <td className="tableHeader col-5">
                          {item?.accName}
                        </td>
                        <td>
                          <InputNumber
                            className="w-select"
                            type="number"
                            value={Number(item?.balance ?? 0)}
                            onChange={value => {
                              setAccountInfo([].concat(accountInfo.map(x => ({
                                ...x, balance: x.categoryId === item?.categoryId ? (value > -1 ? value || 0 : item?.balance || 0) : x.balance
                              }))));
                            }}
                          />
                          <span className="mx-1">
                            <span className="text-danger mx-1">{SectionToChinese(Math.floor(item?.balance ?? 0))}</span>
                            (上级余额：
                            {item?.parentBalance?.toFixed(2) ?? ''}
                            )
                          </span>
                          <span className="operationBtn ms-2" onClick={() => saveCreditBalance(item?.categoryId)}>保存</span>
                        </td>
                      </tr>
                    ))
              }

              <tr>
                <td className="tableHeader col-5">帐号状态</td>
                <td>
                  <span>
                    <Radio
                      checked={UseState === 1}
                      onChange={() => setUseState(1)}
                    >
                      启用
                    </Radio>
                    <Radio
                      checked={UseState === 2}
                      onChange={() => setUseState(2)}
                    >
                      暂停
                    </Radio>
                    <Radio
                      checked={UseState === 0}
                      onChange={() => setUseState(0)}
                    >
                      停用
                    </Radio>
                  </span>
                </td>
              </tr>
              {suportBetApi
                ? (
                  <tr>
                    <td className="tableHeader col-5">Api签名</td>
                    <td>
                      <span>
                        <Input
                          disabled
                          className="d-inline w-select"
                          value={signKey}
                        />
                      </span>
                      <span className="operationBtn ms-2" onClick={() => creatSignKey()}>生成</span>
                    </td>
                  </tr>
                )
                : ''}

            </tbody>
          </table>
        </Row>
        {editMemDetailData && editMemDetailData?.lotteryAssigned === 1 && (
        <>
          <Row className="titleWrapper m-0 my-2 p-2">
            彩种选择
          </Row>
          <Row className="bigWrapper m-0 my-3 p-3">
            {
            isQuery ? (
              <Row>
                <AgentDataTable
                  isQuerying={isQuery}
                  tableHeaderTitle={[]}
                  tableData={[1, 2, 3]}
                  tableBodyRowProperty={tableContent2}
                  tableHeaderStyle={['textRight px-1 col-5', 'textLeft px-1']}
                  tableBodyRowStyle={['backgroundColored p-0 mediumSmallCol', 'textLeft flex-wrap']}
                />
              </Row>
            ) : (
              <AgentDataTable
                tableHeaderTitle={[tableHeader[0]]}
                tableData={leftLotteryMenu}
                tableBodyRowProperty={tableContent2}
                tableHeaderStyle={['textRight px-1 col-5', 'textLeft px-1']}
                tableBodyRowStyle={['backgroundColored p-0 mediumSmallCol', 'textLeft flex-wrap']}
              />
            )
          }
          </Row>
        </>
        )}
        <Row className="titleWrapper m-0 my-2 p-2">
          本级占成
        </Row>
        <Row className="bigWrapper m-0 my-3 p-3">
          {
            isQuery ? (
              <Row>
                <AgentDataTable
                  isQuerying={isQuery}
                  tableHeaderTitle={[]}
                  tableData={[1, 2, 3]}
                  tableBodyRowProperty={tableContent2}
                // tableHeaderStyle={['textRight px-1 col-5', 'textLeft px-1']}
                // tableBodyRowStyle={['backgroundColored p-0 mediumSmallCol', 'textLeft flex-wrap']}
                />
              </Row>
            ) : (
              <AgentDataTable
                tableHeaderTitle={[
                  {
                    label: '类型',
                  },
                  {
                    label: '占成'
                  },
                  {
                    label: '可分配占成'
                  }
                ]}
                tableData={zcList}
                tableBodyRowProperty={tableContent3}
                tableHeaderStyle={['textCenter px-1', 'textCenter px-1', 'textCenter px-1']}
              />

            )
          }
        </Row>
      </div>

      <Row className="mb-5 my-2 p-2">
        <Col className="d-flex justify-content-center py-2 border-top">
          <Button className="button handle mx-1" onClick={() => saveButton()}>{t('SAVE')}</Button>
          <Button className="button find mx-1" onClick={() => onCancel?.()}>{t('CANCEL')}</Button>
        </Col>
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MemberEdit);