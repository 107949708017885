
import { Button, Checkbox, DatePicker, Input, Select } from 'antd';
import { Col, Label, Row } from 'reactstrap';

import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { appActions, commonActions, unusualBetActions } from '../../../redux/actions';
import { LotteryMenu } from '../..';

import AgentDataTable from '../../AgentDataTable';
import Notifications from '../../Content/Notifications';
import Pagination from '../../Content/Pagination';

import './styles.scss';

const decideColor = item => (item >= 0 ? 'blue' : 'red');

const mapStateToProps = state => ({
  lotteryInstallments: state.commonReducers.lotteryInstallments,
  activeLottery: state.commonReducers.activeLottery,
  betQueryData: state.unusualBetReducers.betQueryData,
  downlineAgentList: state.commonReducers.downlineAgentList,
  userData: state.memberReducers.userData,
  lotteryList: state.commonReducers.lotteryList,
});

const mapDispatchToProps = dispatch => ({
  deleteUnusualBet: (obj, callback) => dispatch(unusualBetActions.deleteUnusualBet(obj, callback)),
  toUnusualBet: (obj, callback) => dispatch(unusualBetActions.toUnusualBet(obj, callback)),
  GetDownlineAgentList: obj => dispatch(commonActions.GetDownlineAgentList(obj)),
  QueryBetingByCondiction: (obj, callback, catchback) => dispatch(unusualBetActions.QueryBetingByCondiction(obj, callback, catchback)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  getLotteryInstallments: (lotteryCode, date) => dispatch(commonActions.getLotteryInstallments(lotteryCode, date)),
});

const { RangePicker } = DatePicker;

const AbnormalBetQuery = ({ showNotify = true, lotteryList, downlineAgentList = [], userData, GetDownlineAgentList, deleteUnusualBet, updateModalAlert, toUnusualBet, betQueryData, QueryBetingByCondiction, lotteryInstallments, getLotteryInstallments }) => {
  const { t } = useTranslation(); // i18n
  const [_isQueryingDetail, setIsQueryingDetail] = useState([]); // loading
  const [_detailItem, setDetailItem] = useState([]); // table data
  const [_detailItemSum, setDetailItemSum] = useState([]); // table data sum
  const [_showMenu, setShowMenu] = useState(false); // control lottery selection popup menu
  const [_selectedLottery, setSelectedLottery] = useState(''); // filter lottory
  const [_installmentsResults, setInstallmentResults] = useState([]); // 期数 options
  const [installment, setSelectedInstallments] = useState('-1'); // filter 选中期数
  const [_date, setDate] = useState([moment().startOf('day').toDate(), moment().endOf('day').toDate()]); // filter date
  const [checkedKeys, setCheckedKeys] = useState(new Set());
  const [checkAll, setCheckAll] = useState(false);
  const [betTypeOptions, setBetTypeOptions] = useState([]);
  const [searchKeyOptions, setSearchKeyOptions] = useState([]);

  const totalSearchKeyOptions = [
    {
      label: t('AMOUNT'), value: 'amount'
    },
    {
      label: t('MEMBER_ACCOUNT'), value: 'username'
    },
    {
      label: t('BET_NO'), value: 'num'
    },
    {
      label: t('MEMBER_IP'), value: 'ip'
    },
    {
      label: t('TOP_COMPANY'), value: '1'
    },
    {
      label: t('COMPANY'), value: '2'
    },
    {
      label: t('TOP_AGENT'), value: '3'
    },
    {
      label: t('LEVEL1_AGENT'), value: '4'
    },
    {
      label: t('LEVEL2_AGENT'), value: '5'
    },
    {
      label: t('LEVEL3_AGENT'), value: '6'
    },
    {
      label: t('LEVEL4_AGENT'), value: '7'
    },
    {
      label: t('LEVEL5_AGENT'), value: '8'
    },
    {
      label: t('LEVEL6_AGENT'), value: '9'
    },
    {
      label: t('LEVEL7_AGENT'), value: '10'
    },
    {
      label: t('LEVEL8_AGENT'), value: '11'
    },
  ];

  const totalBetTypeOptions = [
    {
      label: t('ALL'), value: '-1'
    },
    {
      label: t('MEMBER_BET'), value: '0'
    },
    {
      label: `${t('TOP_COMPANY_RESTOCK')})`, value: '1'
    },
    {
      label: `${t('COMPANY_RESTOCK')})`, value: '2'
    },
    {
      label: `${t('TOP_AGENT_RESTOCK')})`, value: '3'
    },
    {
      label: `${t('LEVEL1_RESTOCK')})`, value: '4'
    },
    {
      label: `${t('LEVEL2_RESTOCK')})`, value: '5'
    },
    {
      label: `${t('LEVEL3_RESTOCK')})`, value: '6'
    },
    {
      label: `${t('LEVEL4_RESTOCK')})`, value: '7'
    },
    {
      label: `${t('LEVEL5_RESTOCK')})`, value: '8'
    },
    {
      label: `${t('LEVEL6_RESTOCK')})`, value: '9'
    },
    {
      label: `${t('LEVEL7_RESTOCK')})`, value: '10'
    },
    {
      label: `${t('LEVEL8_RESTOCK')})`, value: '11'
    },
  ];


  useEffect(() => {
    const _downlineAgentList = downlineAgentList.map(item => {
      const option = totalBetTypeOptions.find(i => i.value === item.level);
      if (option) {
        return {
          label: option.label,
          value: option.value
        };
      }
      return null;
    }).filter(Boolean);

    const _downlineAgentListForSearchKey = downlineAgentList.map(item => {
      const option = totalSearchKeyOptions.find(i => i.value === item.level);
      if (option) {
        return {
          label: option.label,
          value: option.value
        };
      }
      return null;
    }).filter(Boolean);

    setBetTypeOptions([
      {
        label: t('ALL'), value: '-1'
      },
      {
        label: t('MEMBER_BET'), value: '0'
      },
    ].concat(_downlineAgentList));

    setSearchKeyOptions([
      {
        label: t('AMOUNT'), value: 'amount'
      },
      {
        label: t('MEMBER_ACCOUNT'), value: 'username'
      },
      {
        label: t('BET_NO'), value: 'num'
      },
      // 1.0 代码，IP
      // //一级才开放按IP查询
      (userData.levelValue <= 3 && {
        label: t('MEMBER_IP'), value: 'ip'
      }) || null,
    ].concat(_downlineAgentListForSearchKey).filter(Boolean));
  }, [downlineAgentList]);

  useEffect(() => {
    GetDownlineAgentList({
      orgId: userData.id
    });
  }, []);


  const statusOptions = [
    {
      label: t('ALL'), value: '-1',
    },
    {
      label: t('UNUSUAL'), value: '0',
    },
    {
      label: t('LOGGED_OUT'), value: '1',
    },
    {
      label: t('SETTLED'), value: '2',
    },
    {
      label: t('UN_SETTLED'), value: '3',
    }
  ];

  const tableHeaderTitle = [
    {
      label: (
        <div>
          <Checkbox
            checked={checkAll}
            onChange={e => {
              const { checked } = e.target;
              if (checked) {
                setCheckedKeys(new Set(_detailItem.map(i => i.id)));
              } else {
                setCheckedKeys(new Set());
              }
              setCheckAll(checked);
            }}
          />
          <Label className="marginLeft1">{t('SELECT')}</Label>
        </div>
      )
    },
    {
      label: t('BET_NUMBER'),
    },
    {
      label: t('BET_TIME'),
    },
    {
      label: t('LOTTERY'),
    },
    {
      label: t('ACCOUNT_NAME'),
    },
    {
      label: t('BET_CONTENT'),
    },
    {
      label: t('BET') + t('AMOUNT'),
    },
    {
      label: `${t('REBATE')}%/${t('AMOUNT')}`,
    },
    {
      label: t('BET_RESULT'),
    },
    {
      label: t('THIS_LVL_PERCENTAGE'),
    },
    {
      label: (
        <span className="red">
          {t('THIS_LVL_RESULT')}
        </span>
      )
    },
    {
      label: t('REMARK')
    },
    {
      label: t('STATUS'),
    },
  ];

  const _content = [
    item => {
      const checked = checkedKeys.has(item.id);
      return (
        <div>
          <Checkbox
            onChange={e => {
              const { checked: ck } = e.target;
              const copySet = new Set();
              checkedKeys.forEach(value => {
                copySet.add(value);
              });
              if (!ck) {
                copySet.delete(item.id);
              } else {
                copySet.add(item.id);
              }
              setCheckedKeys(copySet);
              if (copySet.size < checkedKeys.size) {
                setCheckAll(false);
              }
            }}
            checked={checked}
          />
        </div>
      );
    },
    data => (
      <>
        <Label
          className="lotteryName url"
          onClick={() => {
            if (filter.status !== '0') {
              // 只有非异常注单可以操作
              updateModalAlert({
                type: 'betDetails',
                visible: true,
                data: {
                  id: data?.id,
                }
              });
            }
          }}
        >
          {
            data.oddNo
          }
        </Label>
        <div>
          {
            data.ip && (
              <Label>{`IP: ${data.ip || ''}`}</Label>
            )
          }
        </div>
      </>
    ),
    data => (
      <>
        <Label className="px-1">{moment.unix(data.created).format('YYYY-MM-DD HH:mm:ss')}</Label>
        <Label>{t(`DAY_OF_WEEK_${moment.unix(data.created || 0).weekday()}`)}</Label>
      </>
    ),
    item => ((
      <div>
        <div>{item.lotteryName}</div>
        <div className="installments">
          {
            `${t('DI')} ${item.installments} ${t('INSTALLMENT')}`
          }
        </div>
      </div>
    )),
    item => ((
      <div>
        <div>{item.orgName}</div>
        <div>{`${item.oddsType || ''} ${t('PLATE')}`}</div>
      </div>
    )),
    item => ((
      <div>
        <span className="midType">
          <span>{item.midType}</span>
          <span>
            〖
            {item.displayname}
            〗
          </span>
          <span>
            @
          </span>
        </span>
        <span className="itemOdds">
          {
            item.odds
          }
        </span>
      </div>
    )),
    'betAmount',
    item => ((
      <div>
        <div>{`${item.returnValue || ''}%`}</div>
      </div>
    )),
    item => {
      if (item.status === 5) {
        return (
          <span className="unusualBet">
            {t('UNUSUAL_BET')}
          </span>
        );
      }
      if (item.status === 0) {
        return (
          <span className="unusualBet">
            {t('UN_SETTLED')}
          </span>
        );
      }
      return (
        <span className={decideColor(item.memAmount)}>
          <div>{`${item.memAmount}`}</div>
        </span>
      );
    },
    item => ((
      <div>
        <div>{`${item.zc || ''}%`}</div>
      </div>
    )),
    item => ((
      <span className={decideColor(item.totalResult)}>
        <div>{`${item.totalResult}`}</div>
      </span>
    )),
    'note',
    item => {
      // 异常注单
      if (filter.status === '0') {
        return t('UNUSUAL_BET');
      }
      if (item.deleteFlag === 0) {
        return (
          <div className="green">
            {t('NORMAL')}
          </div>
        );
      }
      if (item.deleteFlag === 1) {
        return (
          <div className="red">
            {t('CANCEL')}
          </div>
        );
      }
    }
  ];

  const [filter, setFilter] = useState({
    status: '3',
    betType: '-1',
    searchKey: 'amount',
    searchValue: '',
    page: 1, // 当前多少页
    rows: 15, // 一页多少内容
  });

  useEffect(() => {
    if (betQueryData) {
      setDetailItem(betQueryData.data || []);
      setDetailItemSum(betQueryData.sum || {});
    }
    setIsQueryingDetail(false);
  }, [betQueryData]);

  useEffect(() => {
    if (_selectedLottery && _selectedLottery.code) {
      getLotteryInstallments(_selectedLottery.code, moment(_date[0]).unix() || moment().unix());
    } else {
      setInstallmentResults([]);
      setSelectedInstallments('-1');
    }
  }, [_selectedLottery]);

  // 选择不同彩种，期数的变化
  useEffect(() => {
    if (lotteryInstallments) {
      setInstallmentResults(
        [
          {
            label: (
              <span>
                {t('PLEASE_SELECT_INSTALLMENTS')}
              </span>
            ),
            value: '-1',
          }
        ].concat(
          lotteryInstallments.map(item => ({
            // label: item.desc,
            label: item.installment,
            value: item.installment
          }))
        )
      );
    }
    if (!_selectedLottery) {
      setInstallmentResults(
        [
          {
            label: t('PLEASE_SELECT_INSTALLMENTS'),
            value: '-1',
          }
        ]
      );
    }
  }, [lotteryInstallments, _selectedLottery]);

  const onLotterySelect = retvalue => {
    getLotteryInstallments(retvalue?.code, moment(_date[0]).unix() || moment().add(-7, 'days').unix());
    setSelectedLottery(retvalue);
    setSelectedInstallments('-1');
    setShowMenu(false);
  };

  useEffect(() => {
    onQuery();
  }, []);

  const onQuery = (params = {}, modifyPage = false) => {
    setIsQueryingDetail(true);
    if (!modifyPage) {
      setFilter({
        ...filter,
        ...params,
        page: 1
      });
      params.page = 1;
    }
    QueryBetingByCondiction(
      {
        lotteryType: _selectedLottery.code || '',
        searchBy: filter.searchKey,
        value: filter.searchValue,
        status: filter.status,
        rows: filter.rows,
        page: filter.page,
        startDate: moment(_date[0]).unix(),
        endDate: moment(_date[1]).unix(),
        betType: filter.betType,
        installments: installment !== '-1' ? installment : '',
        ...params,
      },
      () => {
        setIsQueryingDetail(false);
      },
      () => {
        setIsQueryingDetail(false);
      }
    );
  };

  return (
    <div className="abnormalBetQueryWrapper">
      <Col className="abnormalBetQueryFilterWrapper">
        <Col className="p-2 mb-2 filterWrapper d-flex align-items-center w-100 rounded-top">
          <Label className="px-2">{t('LOTTERY')}</Label>
          <div className="menuRow">
            <div
              className="currentSelected"
              onFocus={() => { }}
              onMouseOver={() => setShowMenu(true)}
              onMouseLeave={() => setShowMenu(false)}
            >
              {(!_selectedLottery && `${t('ALL')}`) || _selectedLottery.lotteryName}
              <span className="arrow_down" />
              {_showMenu && (
                <div className="abnormalBetQueryMenuWrapper" onFocus={() => { }} onMouseOver={() => setShowMenu(true)}>
                  <LotteryMenu
                    onClickItem={item => onLotterySelect(item)}
                    selectedItem={_selectedLottery && _selectedLottery.code}
                    _list={lotteryList ? [].concat(lotteryList).filter(x => x.templateCode === 'HK6') : []}
                  />
                </div>
              )}
            </div>
          </div>
          <Label className="px-2">{t('INSTALLMENTS')}</Label>
          <Select
            value={installment}
            onChange={value => setSelectedInstallments(value)}
            options={_installmentsResults}
          />
          <Label className="px-2 betTotal">
            {/* {`${t('BET_TYPE')} `} */}
            <span className="betText">{t('BET')}</span>
            <span className="betText">{t('TYPE')}</span>
          </Label>
          <Select
            className="m-w-100"
            value={filter.betType}
            onChange={value => (setFilter({
              ...filter, betType: value
            }))}
            options={betTypeOptions}
          />
          <Label className="px-2">{` ${t('STATUS')} `}</Label>
          <Select
            className="statusSelect"
            value={filter.status}
            onChange={value => {
              setFilter({
                ...filter, status: value
              });
              onQuery({
                status: value
              });
            }}
            options={statusOptions}
          />
          <Label className="px-2">{` ${t('SEARCH')} `}</Label>
          <Select
            className="searchSelect"
            value={filter.searchKey}
            onChange={value => (setFilter({
              ...filter, searchKey: value
            }))}
            options={searchKeyOptions}
          />
          <Input
            className="searchInput"
            value={filter.searchValue}
            onChange={e => {
              setFilter({
                ...filter, searchValue: e.target.value
              });
            }}

          />
          <Label type="daterange" value={t('DATE')} className="px-2">{t('DATE')}</Label>
          <div>
            <RangePicker
              showTime={{
                format: 'HH:mm'
              }}
              allowClear={false}
              value={[moment(_date[0]), moment(_date[1])]}
              separator={<span>{` ${t('TO')} `}</span>}
              ranges={{
                Today: [moment().startOf('day'), moment().endOf('day')],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
              }}
              onChange={date => setDate(date)}
            />
          </div>
          <Button
            onClick={() => onQuery()}
            className="searchButton marginLeft5 me-2"
          >
            {
              t('FIND')
            }
          </Button>
          {
            filter.status !== '0' && (
              <Button
                className="searchButton marginLeft1 me-2"
                onClick={() => {
                  const data = [...checkedKeys];
                  toUnusualBet(data, onQuery);
                  setCheckedKeys(new Set());
                }}
              >
                {t('TO_UNUSUAL')}
              </Button>
            )
          }
          {
            filter.status === '0' && (
              <Button
                className="searchButton marginLeft1 me-2"
                onClick={() => {
                  const data = [...checkedKeys];
                  deleteUnusualBet(data, onQuery);
                  setCheckedKeys(new Set());
                }}
              >
                {t('DELETE_UNUSUAL')}
              </Button>
            )
          }
          {
            showNotify && <Notifications />
          }
        </Col>
      </Col>

      <Row className=" mt-1 mx-0 bg-white px-0 border-1">
        <AgentDataTable
          isQuerying={_isQueryingDetail}
          tableHeaderTitle={tableHeaderTitle}
          tableData={_detailItem || []}
          tableBodyRowProperty={_content}
          tableFooter={[
            [
              {
                value: `${t('PAGE_TOTAL')}: ${(_detailItemSum && _detailItemSum.thisBetCount) || 0} ${t('TIMES')}`,
                colspan: 6,
                className: 'textRight pr-1 total tableSum'
              },
              {
                value: (_detailItemSum && _detailItemSum.thisBetAmt) || 0,
                colspan: 1,
                className: 'tableSum'
              },
              {
                colspan: 1,
                className: 'tableSum'
              },
              {
                value: (
                  <span className={decideColor((_detailItemSum && _detailItemSum.thisMemAmt) || 0)}>
                    {
                      (_detailItemSum && _detailItemSum.thisMemAmt) || 0
                    }
                  </span>
                ),
                colspan: 1,
                className: 'tableSum'
              },
              {
                colspan: 1,
                className: 'tableSum'
              },
              {
                value: (
                  <span className={decideColor((_detailItemSum && _detailItemSum.thisResult) || 0)}>
                    {
                      (_detailItemSum && _detailItemSum.thisResult) || 0
                    }
                  </span>
                ),
                colspan: 1,
                className: 'tableSum'
              },
              {
                colspan: 6,
                className: 'tableSum'
              }
            ],
            [
              {
                value: `${t('TOTAL_COUNT')}: ${(_detailItemSum && _detailItemSum.totalRecord) || 0} ${t('TIMES')}`,
                colspan: 6,
                className: 'textRight pr-1 total tableSum'
              },
              {
                value: (_detailItemSum && _detailItemSum.totalBetAmt) || 0,
                colspan: 1,
                className: 'tableSum'
              },
              {
                colspan: 1,
                className: 'tableSum'
              },
              {
                value: (
                  <span className={decideColor((_detailItemSum && _detailItemSum.totalMemAmt) || 0)}>
                    {
                      (_detailItemSum && _detailItemSum.totalMemAmt) || 0
                    }
                  </span>
                ),
                colspan: 1,
                className: 'tableSum'
              },
              {
                colspan: 1,
                className: 'tableSum'
              },
              {
                value: (
                  <span className={decideColor((_detailItemSum && _detailItemSum.totalResult) || 0)}>
                    {
                      (_detailItemSum && _detailItemSum.totalResult) || 0
                    }
                  </span>
                ),
                colspan: 1,
                className: 'tableSum'
              },
              {
                colspan: 6,
                className: 'tableSum'
              }
            ]
          ]}
        />
      </Row>

      <Row className="justify-center w-full relative">
        <Pagination
          customizePage={filter.page}
          onClick={(page, rows) => {
            setFilter({
              ...filter,
              page,
              rows
            });
            onQuery(
              {
                page,
                rows
              },
              true
            );
          }}
          totalRecord={(_detailItemSum && _detailItemSum.totalRecord) || 0}
        />
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AbnormalBetQuery);