
import gameItemIconMap from '../../GameItemIcon';

const number1to49 = [
  {
    displayName: '01', className: 'hk6Ball ball_red',
  }, {
    displayName: '02', className: 'hk6Ball ball_red',
  }, {
    displayName: '03', className: 'hk6Ball ball_blue',
  }, {
    displayName: '04', className: 'hk6Ball ball_blue',
  }, {
    displayName: '05', className: 'hk6Ball ball_green',
  }, {
    displayName: '06', className: 'hk6Ball ball_green',
  }, {
    displayName: '07', className: 'hk6Ball ball_red',
  }, {
    displayName: '08', className: 'hk6Ball ball_red',
  }, {
    displayName: '09', className: 'hk6Ball ball_blue',
  }, {
    displayName: '10', className: 'hk6Ball ball_blue',
  }, {
    displayName: '11', className: 'hk6Ball ball_green',
  }, {
    displayName: '12', className: 'hk6Ball ball_red',
  }, {
    displayName: '13', className: 'hk6Ball ball_red',
  }, {
    displayName: '14', className: 'hk6Ball ball_blue',
  }, {
    displayName: '15', className: 'hk6Ball ball_blue',
  }, {
    displayName: '16', className: 'hk6Ball ball_green',
  }, {
    displayName: '17', className: 'hk6Ball ball_green',
  }, {
    displayName: '18', className: 'hk6Ball ball_red',
  }, {
    displayName: '19', className: 'hk6Ball ball_red',
  }, {
    displayName: '20', className: 'hk6Ball ball_blue',
  }, {
    displayName: '21', className: 'hk6Ball ball_green',
  }, {
    displayName: '22', className: 'hk6Ball ball_green',
  }, {
    displayName: '23', className: 'hk6Ball ball_red',
  }, {
    displayName: '24', className: 'hk6Ball ball_red',
  }, {
    displayName: '25', className: 'hk6Ball ball_blue',
  }, {
    displayName: '26', className: 'hk6Ball ball_blue',
  }, {
    displayName: '27', className: 'hk6Ball ball_green',
  }, {
    displayName: '28', className: 'hk6Ball ball_green',
  }, {
    displayName: '29', className: 'hk6Ball ball_red',
  }, {
    displayName: '30', className: 'hk6Ball ball_red',
  }, {
    displayName: '31', className: 'hk6Ball ball_blue',
  }, {
    displayName: '32', className: 'hk6Ball ball_green',
  }, {
    displayName: '33', className: 'hk6Ball ball_green',
  }, {
    displayName: '34', className: 'hk6Ball ball_red',
  }, {
    displayName: '35', className: 'hk6Ball ball_red',
  }, {
    displayName: '36', className: 'hk6Ball ball_blue',
  }, {
    displayName: '37', className: 'hk6Ball ball_blue',
  }, {
    displayName: '38', className: 'hk6Ball ball_green',
  }, {
    displayName: '39', className: 'hk6Ball ball_green',
  }, {
    displayName: '40', className: 'hk6Ball ball_red',
  }, {
    displayName: '41', className: 'hk6Ball ball_blue',
  }, {
    displayName: '42', className: 'hk6Ball ball_blue',
  }, {
    displayName: '43', className: 'hk6Ball ball_green',
  }, {
    displayName: '44', className: 'hk6Ball ball_green',
  }, {
    displayName: '45', className: 'hk6Ball ball_red',
  }, {
    displayName: '46', className: 'hk6Ball ball_red',
  }, {
    displayName: '47', className: 'hk6Ball ball_blue',
  }, {
    displayName: '48', className: 'hk6Ball ball_blue',
  }, {
    displayName: '49', className: 'hk6Ball ball_green',
  }
];

const GXKL10Number1to21 = [{
  displayName: '1', className: 'gxk10   ball_red'
}, {
  displayName: '2', className: 'gxk10   ball_blue'
}, {
  displayName: '3', className: 'gxk10   ball_green'
}, {
  displayName: '4', className: 'gxk10   ball_red'
}, {
  displayName: '5', className: 'gxk10   ball_blue'
}, {
  displayName: '6', className: 'gxk10   ball_green'
}, {
  displayName: '7', className: 'gxk10   ball_red'
}, {
  displayName: '8', className: 'gxk10   ball_blue'
}, {
  displayName: '9', className: 'gxk10   ball_green'
}, {
  displayName: '10', className: 'gxk10  ball_red'
}, {
  displayName: '11', className: 'gxk10  ball_blue'
}, {
  displayName: '12', className: 'gxk10  ball_green'
}, {
  displayName: '13', className: 'gxk10  ball_red'
}, {
  displayName: '14', className: 'gxk10  ball_blue'
}, {
  displayName: '15', className: 'gxk10  ball_green'
}, {
  displayName: '16', className: 'gxk10  ball_red'
}, {
  displayName: '17', className: 'gxk10  ball_blue'
}, {
  displayName: '18', className: 'gxk10  ball_green'
}, {
  displayName: '19', className: 'gxk10  ball_red'
}, {
  displayName: '20', className: 'gxk10  ball_blue'
}, {
  displayName: '21', className: 'gxk10  ball_green'
},
];

const PK101to10Code = [
  {
    displayName: '1',
    className: 'pk10Square square_1',
    keyCode: 'M'
  }, {
    displayName: '2',
    className: 'pk10Square square_2',
    keyCode: 'M'
  }, {
    displayName: '3',
    className: 'pk10Square square_3',
    keyCode: 'M'
  }, {
    displayName: '4',
    className: 'pk10Square square_4',
    keyCode: 'M'
  }, {
    displayName: '5',
    className: 'pk10Square square_5',
    keyCode: 'M'
  }, {
    displayName: '6',
    className: 'pk10Square square_6',
    keyCode: 'M'
  }, {
    displayName: '7',
    className: 'pk10Square square_7',
    keyCode: 'M'
  }, {
    displayName: '8',
    className: 'pk10Square square_8',
    keyCode: 'M'
  }, {
    displayName: '9',
    className: 'pk10Square square_9',
    keyCode: 'M'
  }, {
    displayName: '10',
    className: 'pk10Square square_10',
    keyCode: 'M'
  }
];


const PK101to10 = [
  {
    displayName: '1',
    className: 'pk10Square square_1',
  }, {
    displayName: '2',
    className: 'pk10Square square_2',
  }, {
    displayName: '3',
    className: 'pk10Square square_3',
  }, {
    displayName: '4',
    className: 'pk10Square square_4',
  }, {
    displayName: '5',
    className: 'pk10Square square_5',
  }, {
    displayName: '6',
    className: 'pk10Square square_6',
  }, {
    displayName: '7',
    className: 'pk10Square square_7',
  }, {
    displayName: '8',
    className: 'pk10Square square_8',
  }, {
    displayName: '9',
    className: 'pk10Square square_9',
  }, {
    displayName: '10',
    className: 'pk10Square square_10',
  }
];
const number1to20 = [{
  displayName: '1',
}, {
  displayName: '2',
}, {
  displayName: '3',
}, {
  displayName: '4',
}, {
  displayName: '5',
}, {
  displayName: '6',
}, {
  displayName: '7',
}, {
  displayName: '8',
}, {
  displayName: '9',
}, {
  displayName: '10',
}, {
  displayName: '11',
}, {
  displayName: '12',
}, {
  displayName: '13',
}, {
  displayName: '14',
}, {
  displayName: '15',
}, {
  displayName: '16',
}, {
  displayName: '17',
}, {
  displayName: '18',
}, {
  displayName: '19',
}, {
  displayName: '20',
}, {
  displayName: '21',
},
];


const ballsLightBlue = [
  {
    displayName: '0', className: 'hk6Ball ball_lightBlue'
  }, {
    displayName: '1', className: 'hk6Ball ball_lightBlue'
  }, {
    displayName: '2', className: 'hk6Ball ball_lightBlue'
  }, {
    displayName: '3', className: 'hk6Ball ball_lightBlue'
  }, {
    displayName: '4', className: 'hk6Ball ball_lightBlue'
  }, {
    displayName: '5', className: 'hk6Ball ball_lightBlue'
  }, {
    displayName: '6', className: 'hk6Ball ball_lightBlue'
  }, {
    displayName: '7', className: 'hk6Ball ball_lightBlue'
  }, {
    displayName: '8', className: 'hk6Ball ball_lightBlue'
  }, {
    displayName: '9', className: 'hk6Ball ball_lightBlue'
  }];

const KL10balls = [
  {
    displayName: '1', className: 'hk6Ball ball_lightBlue'
  }, {
    displayName: '2', className: 'hk6Ball ball_lightBlue'
  }, {
    displayName: '3', className: 'hk6Ball ball_lightBlue'
  }, {
    displayName: '4', className: 'hk6Ball ball_lightBlue'
  }, {
    displayName: '5', className: 'hk6Ball ball_lightBlue'
  }, {
    displayName: '6', className: 'hk6Ball ball_lightBlue'
  }, {
    displayName: '7', className: 'hk6Ball ball_lightBlue'
  }, {
    displayName: '8', className: 'hk6Ball ball_lightBlue'
  }, {
    displayName: '9', className: 'hk6Ball ball_lightBlue'
  }, {
    displayName: '10', className: 'hk6Ball ball_lightBlue'
  }, {
    displayName: '11', className: 'hk6Ball ball_lightBlue'
  }, {
    displayName: '12', className: 'hk6Ball ball_lightBlue'
  }, {
    displayName: '13', className: 'hk6Ball ball_lightBlue'
  }, {
    displayName: '14', className: 'hk6Ball ball_lightBlue'
  }, {
    displayName: '15', className: 'hk6Ball ball_lightBlue'
  }, {
    displayName: '16', className: 'hk6Ball ball_lightBlue'
  }, {
    displayName: '17', className: 'hk6Ball ball_lightBlue'
  }, {
    displayName: '18', className: 'hk6Ball ball_lightBlue'
  }, {
    displayName: '19', className: 'hk6Ball ball_red'
  }, {
    displayName: '20', className: 'hk6Ball ball_red'
  }];

const shengXiaoNumbers = [
  {
    displayName: '0', className: 'hideLabel', img: gameItemIconMap.CJ_SSC_HLSX_BALL0,
  }, {
    displayName: '1', className: 'hideLabel', img: gameItemIconMap.CJ_SSC_HLSX_BALL1,
  }, {
    displayName: '2', className: 'hideLabel', img: gameItemIconMap.CJ_SSC_HLSX_BALL2,
  }, {
    displayName: '3', className: 'hideLabel', img: gameItemIconMap.CJ_SSC_HLSX_BALL3,
  }, {
    displayName: '4', className: 'hideLabel', img: gameItemIconMap.CJ_SSC_HLSX_BALL4,
  }, {
    displayName: '5', className: 'hideLabel', img: gameItemIconMap.CJ_SSC_HLSX_BALL5,
  }, {
    displayName: '6', className: 'hideLabel', img: gameItemIconMap.CJ_SSC_HLSX_BALL6,
  }, {
    displayName: '7', className: 'hideLabel', img: gameItemIconMap.CJ_SSC_HLSX_BALL7,
  }, {
    displayName: '8', className: 'hideLabel', img: gameItemIconMap.CJ_SSC_HLSX_BALL8,
  }, {
    displayName: '9', className: 'hideLabel', img: gameItemIconMap.CJ_SSC_HLSX_BALL9,
  }];


const farmNumbers = [
  {
    displayName: '1', className: 'hideLabel', img: gameItemIconMap.CJ_WATERMELON,
  }, {
    displayName: '2', className: 'hideLabel', img: gameItemIconMap.CJ_COCONUT,
  }, {
    displayName: '3', className: 'hideLabel', img: gameItemIconMap.CJ_DURIAN,
  }, {
    displayName: '4', className: 'hideLabel', img: gameItemIconMap.CJ_POMELO,
  }, {
    displayName: '5', className: 'hideLabel', img: gameItemIconMap.CJ_PINEAPPLE,
  }, {
    displayName: '6', className: 'hideLabel', img: gameItemIconMap.CJ_GRAPES,
  }, {
    displayName: '7', className: 'hideLabel', img: gameItemIconMap.CJ_LYCHEE,
  }, {
    displayName: '8', className: 'hideLabel', img: gameItemIconMap.CJ_CHERRY,
  }, {
    displayName: '9', className: 'hideLabel', img: gameItemIconMap.CJ_STRAWBERRY,
  }, {
    displayName: '10', className: 'hideLabel', img: gameItemIconMap.CJ_TOMATO,
  }, {
    displayName: '11', className: 'hideLabel', img: gameItemIconMap.CJ_PEAR,
  }, {
    displayName: '12', className: 'hideLabel', img: gameItemIconMap.CJ_APPLE,
  }, {
    displayName: '13', className: 'hideLabel', img: gameItemIconMap.CJ_PEACH,
  }, {
    displayName: '14', className: 'hideLabel', img: gameItemIconMap.CJ_ORANGE,
  }, {
    displayName: '15', className: 'hideLabel', img: gameItemIconMap.CJ_WINTERMELON,
  }, {
    displayName: '16', className: 'hideLabel', img: gameItemIconMap.CJ_RADISH,
  }, {
    displayName: '17', className: 'hideLabel', img: gameItemIconMap.CJ_PUMPKIN,
  }, {
    displayName: '18', className: 'hideLabel', img: gameItemIconMap.CJ_EGGPLANT,
  }, {
    displayName: '19', className: 'hideLabel', img: gameItemIconMap.CJ_DOG,
  }, {
    displayName: '20', className: 'hideLabel', img: gameItemIconMap.CJ_COW,
  }];

const numberBlue1to80 = [
  {
    displayName: '1', className: 'blueBall light',
  }, {
    displayName: '2', className: 'blueBall light',
  }, {
    displayName: '3', className: 'blueBall light',
  }, {
    displayName: '4', className: 'blueBall light',
  }, {
    displayName: '5', className: 'blueBall light',
  }, {
    displayName: '6', className: 'blueBall light',
  }, {
    displayName: '7', className: 'blueBall light',
  }, {
    displayName: '8', className: 'blueBall light',
  }, {
    displayName: '9', className: 'blueBall light',
  }, {
    displayName: '10', className: 'blueBall light',
  }, {
    displayName: '11', className: 'blueBall light',
  }, {
    displayName: '12', className: 'blueBall light',
  }, {
    displayName: '13', className: 'blueBall light',
  }, {
    displayName: '14', className: 'blueBall light',
  }, {
    displayName: '15', className: 'blueBall light',
  }, {
    displayName: '16', className: 'blueBall light',
  }, {
    displayName: '17', className: 'blueBall light',
  }, {
    displayName: '18', className: 'blueBall light',
  }, {
    displayName: '19', className: 'blueBall light',
  }, {
    displayName: '20', className: 'blueBall light',
  }, {
    displayName: '21', className: 'blueBall light',
  }, {
    displayName: '22', className: 'blueBall light',
  }, {
    displayName: '23', className: 'blueBall light',
  }, {
    displayName: '24', className: 'blueBall light',
  }, {
    displayName: '25', className: 'blueBall light',
  }, {
    displayName: '26', className: 'blueBall light',
  }, {
    displayName: '27', className: 'blueBall light',
  }, {
    displayName: '28', className: 'blueBall light',
  }, {
    displayName: '29', className: 'blueBall light',
  }, {
    displayName: '30', className: 'blueBall light',
  }, {
    displayName: '31', className: 'blueBall light',
  }, {
    displayName: '32', className: 'blueBall light',
  }, {
    displayName: '33', className: 'blueBall light',
  }, {
    displayName: '34', className: 'blueBall light',
  }, {
    displayName: '35', className: 'blueBall light',
  }, {
    displayName: '36', className: 'blueBall light',
  }, {
    displayName: '37', className: 'blueBall light',
  }, {
    displayName: '38', className: 'blueBall light',
  }, {
    displayName: '39', className: 'blueBall light',
  }, {
    displayName: '40', className: 'blueBall light',
  }, {
    displayName: '41', className: 'blueBall deep',
  }, {
    displayName: '42', className: 'blueBall deep',
  }, {
    displayName: '43', className: 'blueBall deep',
  }, {
    displayName: '44', className: 'blueBall deep',
  }, {
    displayName: '45', className: 'blueBall deep',
  }, {
    displayName: '46', className: 'blueBall deep',
  }, {
    displayName: '47', className: 'blueBall deep',
  }, {
    displayName: '48', className: 'blueBall deep',
  }, {
    displayName: '49', className: 'blueBall deep',
  },
  {
    displayName: '50', className: 'blueBall deep',
  }, {
    displayName: '51', className: 'blueBall deep',
  }, {
    displayName: '52', className: 'blueBall deep',
  }, {
    displayName: '53', className: 'blueBall deep',
  }, {
    displayName: '54', className: 'blueBall deep',
  }, {
    displayName: '55', className: 'blueBall deep',
  }, {
    displayName: '56', className: 'blueBall deep',
  }, {
    displayName: '57', className: 'blueBall deep',
  }, {
    displayName: '58', className: 'blueBall deep',
  }, {
    displayName: '59', className: 'blueBall deep',
  }, {
    displayName: '60', className: 'blueBall deep',
  }, {
    displayName: '61', className: 'blueBall deep',
  }, {
    displayName: '62', className: 'blueBall deep',
  }, {
    displayName: '63', className: 'blueBall deep',
  }, {
    displayName: '64', className: 'blueBall deep',
  }, {
    displayName: '65', className: 'blueBall deep',
  }, {
    displayName: '66', className: 'blueBall deep',
  }, {
    displayName: '67', className: 'blueBall deep',
  }, {
    displayName: '68', className: 'blueBall deep',
  }, {
    displayName: '69', className: 'blueBall deep',
  }, {
    displayName: '70', className: 'blueBall deep',
  }, {
    displayName: '71', className: 'blueBall deep',
  }, {
    displayName: '72', className: 'blueBall deep',
  }, {
    displayName: '73', className: 'blueBall deep',
  }, {
    displayName: '74', className: 'blueBall deep',
  }, {
    displayName: '75', className: 'blueBall deep',
  }, {
    displayName: '76', className: 'blueBall deep',
  }, {
    displayName: '77', className: 'blueBall deep',
  }, {
    displayName: '78', className: 'blueBall deep',
  }, {
    displayName: '79', className: 'blueBall deep',
  }, {
    displayName: '80', className: 'blueBall deep',
  }
];


const doubleNumber = [{
  displayName: '11',
}, {
  displayName: '22',
}, {
  displayName: '33',
}, {
  displayName: '44',
}, {
  displayName: '55',
}, {
  displayName: '66',
}, {
  displayName: '77',
}, {
  displayName: '88',
}, {
  displayName: '99',
}
];
const tripleNumber = [{
  displayName: '111',
}, {
  displayName: '222',
}, {
  displayName: '333',
}, {
  displayName: '444',
}, {
  displayName: '555',
}, {
  displayName: '666',
}, {
  displayName: '777',
}, {
  displayName: '888',
}, {
  displayName: '999',
}
];

const longCard = [{
  displayName: '12',
}, {
  displayName: '13',
}, {
  displayName: '14',
}, {
  displayName: '15',
}, {
  displayName: '16',
}, {
  displayName: '23',
}, {
  displayName: '24',
}, {
  displayName: '25',
}, {
  displayName: '26',
}, {
  displayName: '34',
}, {
  displayName: '35',
}, {
  displayName: '36',
}, {
  displayName: '45',
}, {
  displayName: '46',
}, {
  displayName: '56',
}
];

const PCDD0to27 = [
  {
    displayName: '0', className: 'hk6Ball ball_yellow',
  }, {
    displayName: '1', className: 'hk6Ball ball_green',
  }, {
    displayName: '2', className: 'hk6Ball ball_blue',
  }, {
    displayName: '3', className: 'hk6Ball ball_red',
  }, {
    displayName: '4', className: 'hk6Ball ball_green',
  }, {
    displayName: '5', className: 'hk6Ball ball_blue',
  }, {
    displayName: '6', className: 'hk6Ball ball_red',
  }, {
    displayName: '7', className: 'hk6Ball ball_green',
  }, {
    displayName: '8', className: 'hk6Ball ball_blue',
  }, {
    displayName: '9', className: 'hk6Ball ball_red',
  }, {
    displayName: '10', className: 'hk6Ball ball_green',
  }, {
    displayName: '11', className: 'hk6Ball ball_blue',
  }, {
    displayName: '12', className: 'hk6Ball ball_red',
  }, {
    displayName: '13', className: 'hk6Ball ball_yellow',
  }, {
    displayName: '14', className: 'hk6Ball ball_yellow',
  }, {
    displayName: '15', className: 'hk6Ball ball_green',
  }, {
    displayName: '16', className: 'hk6Ball ball_blue',
  }, {
    displayName: '17', className: 'hk6Ball ball_red',
  }, {
    displayName: '18', className: 'hk6Ball ball_green',
  }, {
    displayName: '19', className: 'hk6Ball ball_blue',
  }, {
    displayName: '20', className: 'hk6Ball ball_red',
  }, {
    displayName: '21', className: 'hk6Ball ball_green',
  }, {
    displayName: '22', className: 'hk6Ball ball_blue',
  }, {
    displayName: '23', className: 'hk6Ball ball_red',
  }, {
    displayName: '24', className: 'hk6Ball ball_green',
  }, {
    displayName: '25', className: 'hk6Ball ball_blue',
  }, {
    displayName: '26', className: 'hk6Ball ball_red',
  }, {
    displayName: '27', className: 'hk6Ball ball_yellow',
  },
];

export {
  number1to49,
  PK101to10,
  number1to20,
  numberBlue1to80,
  doubleNumber,
  tripleNumber,
  longCard,
  farmNumbers,
  ballsLightBlue,
  PCDD0to27,
  PK101to10Code,
  shengXiaoNumbers,
  KL10balls,
  GXKL10Number1to21,
};