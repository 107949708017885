import { Checkbox, Input } from 'antd';
import { Col, Label, Row } from 'reactstrap';
import { FaRegMinusSquare, FaRegPlusSquare } from 'react-icons/fa';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { AgentDataTable } from '../..';

import './styles.scss';

const OddsSettingTable = ({ menu, _selectedMainMenu = 0, _isQueryingDetail, _info, _selectedSubMenu, _isAvailable, changeOdds = () => {}, onValueChange = () => {} }) => {
  const { t } = useTranslation();

  const renderTable = () => {
    // normal data arrangement
    if (menu[_selectedMainMenu]?.isStaticRow !== 1 && menu[_selectedMainMenu]?.horizontalDataAlign !== 1) {
      return (
        <Row>
          {
            [...Array(Math.ceil(Number((menu[_selectedMainMenu]?.display?.length ?? 0) / (menu[_selectedMainMenu]?.displayRows ?? 1))) || 0)].map((d, index) => (
              <Col className="content p-0 m-0" key={`displayRow_${index.toString()}}`}>
                {drawContent(_info?.slice(getstart(index), getend(index)))}
              </Col>
            ))
          }
        </Row>
      );
    } if (menu[_selectedMainMenu]?.horizontalDataAlign === 1) {
      return (
        <Row>
          {
            [...Array(menu[_selectedMainMenu]?.horizontalSet)].map((d, i) => (
              <Col className="content p-0 m-0" key={`horizontalDataAlign_${i.toString()}}`}>
                {drawContent(_info?.filter((item, index) => (index % (menu[_selectedMainMenu]?.horizontalSet ?? 1) === i)))}
              </Col>
            ))
          }
        </Row>
      );
    }
    // arrange by col
    if (menu[_selectedMainMenu]?.isStaticRow === 1 && menu[_selectedMainMenu]?.combineStyle === 1) {
      return (
        <Row key="staticRow2">
          {
            [...Array(menu[_selectedMainMenu]?.repeatPerRow || menu[_selectedMainMenu]?.display?.length)].map((d, i) => (
              <Col className="content p-0 m-0" key={`datadata_${i.toString()}}`}>
                {
                  [...Array(menu[_selectedMainMenu]?.repeatPerCol || 1)].map((d, index) => (
                    drawContent(
                      _info?.filter(item => item?.mark === (countCurrIndex(i, index)).toString()),
                      countCurrIndex(i, index),
                      1,
                      countCurrIndex(i, index) >= menu[_selectedMainMenu]?.display?.length
                      || menu[_selectedMainMenu]?.display[countCurrIndex(i, index)]?.length === 0
                    )
                  ))
                }
              </Col>
            ))
          }
        </Row>
      );
    }
    // nested split table style
    if (menu[_selectedMainMenu]?.isStaticRow === 1 && menu[_selectedMainMenu]?.combineStyle === 2) {
      return (
        <Row key="staticRow2">
          {
            [...Array(menu[_selectedMainMenu]?.repeatPerRow || menu[_selectedMainMenu]?.display?.length)].map((d, i) => (
              <Col className="content p-0 m-0" key={`datadata_${i.toString()}}`}>
                {
                  [...Array(menu[_selectedMainMenu]?.repeatPerCol || 1)].map((d, index) => (
                    drawContent2(
                      _info?.filter(item => item?.mark === (countCurrIndex(i, index)).toString()),
                      countCurrIndex(i, index),
                      1,
                      countCurrIndex(i, index) >= menu[_selectedMainMenu]?.display?.length
                      || menu[_selectedMainMenu]?.display[countCurrIndex(i, index)]?.length === 0
                    )
                  ))
                }
              </Col>
            ))
          }
        </Row>
      );
    }
    // type 2, arrange by row
    if (menu[_selectedMainMenu]?.isStaticRow === 1) {
      return (
        [...Array(menu[_selectedMainMenu]?.repeatPerRow ? Math.ceil((menu[_selectedMainMenu]?.display?.length ?? 0) / (menu[_selectedMainMenu]?.repeatPerRow ?? 1)) : 1)].map((k, i) => (
          <Row key={`firststaticRow_${i.toString()}}`}>
            {
              [...Array(getRepeatTime(i))].map((r, index) => (
                <Col className="content p-0 m-0" key={`staticRow_${index.toString()}}`}>
                  {drawContent(
                    _info?.filter(x => x?.mark === (index + (i * (menu[_selectedMainMenu]?.repeatPerRow || 0))).toString()),
                    index + (i * (menu[_selectedMainMenu]?.repeatPerRow || 0)),
                    0,

                    menu[_selectedMainMenu]?.display[index + (i * (menu[_selectedMainMenu]?.repeatPerRow || 0))]?.length === 0
                  )}
                </Col>
              ))
            }
          </Row>
        ))
      );
    }
  };


  const drawContent2 = (info, i, combineStyle, isExceed) => (
    <>
      {info?.length > 0 && i?.toString() && menu[_selectedMainMenu]?.firstTableHeader
      && (
        <div className={`oddHeader ${combineStyle && i >= menu[_selectedMainMenu]?.repeatPerRow
          ? 'concatUpDown' : ''}`}
        >
          {menu[_selectedMainMenu]?.firstTableHeader[Number(i)] || ''}
        </div>
      )}
      {
        info?.length > 0 && i?.toString() && menu[_selectedMainMenu]?.children && menu[_selectedMainMenu]?.children[_selectedSubMenu]?.needHeader
        && (
          <div className="oddHeader">
            {menu[_selectedMainMenu]?.children[_selectedSubMenu]?.label || ''}
          </div>
        )
      }
      <Col className="d-flex">
        {!isExceed && (
        <>
          {
            [...Array(getComb2RepTime(i) || 1)].map((r, index) => (
              <AgentDataTable
                className={`col-${(12 / getComb2RepTime(i)).toString()}`}
                isQuerying={_isQueryingDetail}
                tableHeaderTitle={(combineStyle && i >= menu[_selectedMainMenu]?.repeatPerRow ? [] : tableheader) || []}
                tableData={_isQueryingDetail ? [1, 2, 3] : (info?.slice(getRepStart(index, i), getRepEnd(index, i)) || [])}
                tableBodyRowProperty={tablecontent}
                tableBodyRowStyle={index === 0 ? ['backgroundColored nopadding col-2', 'nopadding col-2', 'nopadding col-2', 'col-2', 'col-2'] : ['backgroundColored nopadding col-2 leftNoBorder', 'nopadding col-2 leftNoBorder', 'nopadding leftNoBorder col-2', 'col-2 leftNoBorder', 'col-2 leftNoBorder']}
                tableHeaderStyle={index === 0 ? [] : ['leftNoBorder']}
              />
            ))
          }
        </>
        )}
      </Col>
    </>
  );

  const countCurrIndex = (i, index) => i + (index * (menu[_selectedMainMenu]?.repeatPerRow || 0));
  const getstart = d => d * (menu[_selectedMainMenu]?.displayRows ?? 0);
  const getend = d => (d * (menu[_selectedMainMenu]?.displayRows ?? 0)) + (menu[_selectedMainMenu]?.displayRows ?? 0);
  const getRepStart = (d, currInd) => d * (menu[_selectedMainMenu]?.displayRows[currInd] ?? 0);
  const getRepEnd = (d, currInd) => (d * (menu[_selectedMainMenu]?.displayRows[currInd] ?? 0)) + (menu[_selectedMainMenu]?.displayRows[currInd] ?? 0);
  const getComb2RepTime = currInd => Math.ceil((menu[_selectedMainMenu]?.display[currInd]?.length ?? 0) / (menu[_selectedMainMenu]?.displayRows[currInd] ?? 1));
  const getRepeatTime = d => {
    if (!menu[_selectedMainMenu]?.repeatPerRow) {
      return menu[_selectedMainMenu]?.display?.length;
    }
    return ((d + 1) * (menu[_selectedMainMenu]?.repeatPerRow ?? 0) > menu[_selectedMainMenu]?.display?.length)
      ? (menu[_selectedMainMenu]?.display?.length ?? 0) % (menu[_selectedMainMenu]?.repeatPerRow ?? 1)
      : menu[_selectedMainMenu]?.repeatPerRow;
  };
  const drawContent = (info, i, combineStyle, isExceed) => (
    <div>
      {info?.length > 0 && i?.toString() && menu[_selectedMainMenu]?.firstTableHeader
        && (
          <div className={`oddHeader ${combineStyle && i >= menu[_selectedMainMenu]?.repeatPerRow
            ? 'concatUpDown' : ''}`}
          >
            {menu[_selectedMainMenu]?.firstTableHeader[Number(i)] || ''}
          </div>
        )}
      {!isExceed && (
        <AgentDataTable
          isQuerying={_isQueryingDetail}
          tableHeaderTitle={(combineStyle && i >= menu[_selectedMainMenu]?.repeatPerRow ? [] : tableheader) || []}
          tableData={_isQueryingDetail ? [1, 2, 3] : (info || [])}
          tableBodyRowProperty={tablecontent}
          tableBodyRowStyle={(combineStyle ? ['backgroundColored rowSpan nopadding col-2', 'nopadding col-2', 'nopadding col-2', 'col-2', 'col-2'] : ['backgroundColored nopadding', 'nopadding', 'nopadding'])}
          tableHeaderStyle={[]}
          rowSpanCol={[0]}
        />
      )}
    </div>
  );
  const tableheader = [{
    label: t('NUMBER'),
  }, {
    label: t('ODDS'),
  }, {
    label: t('MORATORIUM'),
  }, {
    label: t('EDIT'),
  }, {
    label: t('TOTAL_VALUE'),
  }];

  const tablecontent = [
    data => (data?.rowSpan !== 'nil' ? (data?.img ? (
      <>
        <img alt={data?.displayName} src={data?.img} />
        <br />
        <Label className={data?.className}>{data.displayName}</Label>
      </>
    ) : <Label className={data?.className}>{data.displayName}</Label>) : undefined),
    data => (data?.displayName !== '' && (
      <div className="d-flex nopadding align-items-center justify-content-center">
        <div className={`iconFontSize urlNoUnderline p-0 m-0 active ${!_isAvailable && 'hide'}`} onClick={() => changeOdds('add', data)}>
          <FaRegPlusSquare />
        </div>
        <Label className="mx-1 smallFixWidth negativeValue justify-content-center">
          {data?.odds ? Number(data?.odds) : '--'}
        </Label>
        <div className={`iconFontSize urlNoUnderline p-0 m-0 active ${!_isAvailable && 'hide'}`} onClick={() => changeOdds('minus', data)}>
          <FaRegMinusSquare className="mx-1" />
        </div>
      </div>
    )),
    data => (data?.displayName !== '' && (
      <div className="d-flex align-items-center justify-content-center">
        <Checkbox
          id={data.keyCode}
          key={`main_${data.keyCode}`}
          name={`main_${data.keyCode}`}
          value={data.keyCode}
          onChange={() => onValueChange(data.keyCode, 'enabled', Number(!data?.enabled))}
          checked={data?.enabled !== 1}
        />
      </div>
    )),
    data => (data?.displayName !== '' && (
      <div className="d-flex align-items-center justify-content-center">
        <Input
          id={data.keyCode}
          name={data.keyCode}
          value={_info[Number(_info.findIndex(item => item.keyCode === data.keyCode))]?.minOdds}
          type="Number"
          onChange={({ target: { value: text } }) => onValueChange(data.keyCode, 'minOdds', text)}
        />
      </div>
    )),
    data => (data?.displayName !== '' && (<Label className="d-flex installment justify-content-center">{data?.amount || 0}</Label>)),
  ];

  return (
    <div>
      {menu && renderTable()}
    </div>
  );
};

export default connect()(OddsSettingTable);