import { StatusCodes } from 'http-status-codes';

import { SET_TOPUP_INFO } from '../actionTypes';
import { apiService } from '../../../library';
import appActions from '../appActions';

const setTopUpInfo = data => ({
  type: SET_TOPUP_INFO,
  value: data,
});


const GetInfo = () => dispatch => {
  apiService.get('/Topup/GetInfo').then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      return dispatch(setTopUpInfo(data.result));
    }
  }).catch(error => {
    dispatch(setTopUpInfo());
    dispatch(appActions.updateModalAlert({
      visible: true,
      type: 'confirmation',
      data: {
        message: error.response.data.msg
      }
    }));
    Promise.reject(error);
  });
};
const TopUpSave = (obj, callback = () => {}) => dispatch => {
  apiService.post('/Topup/Save', obj).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      callback();
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'topup',
        data
      }));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: true,
      type: 'confirmation',
      data: {
        message: error.response.data.msg,
        showCancelButton: false
      }
    }));
    Promise.reject(error);
  });
};

const checkStatus = transferId => dispatch => {
  apiService.get('/Topup/CheckStatus', new URLSearchParams({
    transferId
  })).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'success',
          data: {
            message: data.msg,
          }
        }));
      } else if (data.state === 5102 || data.state === 5103) {
        /**
         * 1. 5102, 无效订单, 该订单为非充值订单
         * 2.  5103, 该订单已被处理, 处理结果为: + actionStatus
         */
        dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'confirmation',
          data: {
            message: data.msg,
            showCancelButton: false
          }
        }));
      }
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const verify = transferId => dispatch => {
  apiService.get('/Topup/Verify', new URLSearchParams({
    transferId
  })).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'success',
          data: {
            message: data.msg,
          }
        }));
      } else {
        /**
         * 1. 5102, 无效订单, 该订单为非充值订单
         * 2. 5103, 该订单已被处理, 处理结果为: + actionStatus
         * 3. 5104, 无效订单, 该订单为非线下支付订单
         */
        dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'confirmation',
          data: {
            message: data.msg,
            showCancelButton: false
          }
        }));
      }
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const cancel = transferId => dispatch => {
  apiService.post(`/Topup/Cancel/${transferId}`).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'success',
          data: {
            message: data.msg,
          }
        }));
      } else {
        /**
         * 1. 5102, 无效订单, 该订单为非充值订单
         * 2. 5103, 该订单已被处理, 处理结果为: + actionStatus
         */
        dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'confirmation',
          data: {
            message: data.msg,
            showCancelButton: false
          }
        }));
      }
    }
  }).catch(error => {
    Promise.reject(error);
  });
};


export default {
  GetInfo,
  TopUpSave,
  checkStatus,
  verify,
  cancel
};