import { dxds } from '../Items/dsdx';

import { animal, dot4to17, fullDice } from '../Items/others';

import { doubleNumber, longCard, tripleNumber } from '../Items/numbers';
import { qsK3 } from '../Items/quickset';

const K3 = [{
  hideMenu: 1,
  displayName: '总项盘口',
  midcode: 'SJ1,SJ2,SJ3,DX,QS,WS,DS,CP,DP',
  isStaticRow: 1,
  combineStyle: 1,
  repeatPerRow: 3,
  repeatPerCol: 3,
  quickSet: {
    style: 2,
    variables: [[...qsK3]]
  },
  prefix: ['SJ', 'WS', 'CP', 'DX', 'DS', 'DP', 'QS'],
  display: [[...animal],
    [...tripleNumber.slice(0, 6)],
    [...longCard],
    [...dxds.slice(2, 4)],
    [...dot4to17],
    [...doubleNumber.slice(1, 6)],
    [...fullDice]],
  firstTableHeader: ['三军', '围骰', '长牌', '大小', '点数', '短牌', '全骰'],
}
];

export default K3;