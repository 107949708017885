import { useEffect, useRef, useState } from 'react';

import { connect } from 'react-redux';

import AutoRestockSetting from '../AutoRestockSetting';
import BetQueryDetail from '../BetQueryDetail';
import BetRevoke from '../BetRevoke';
import BetTaskBackup from '../BetTaskBackup';
import BlockBetNumbers from '../BlockBetNumbers';
import RestockOperationLog from '../RestockOperationLog';

import { InTimeSheetBottomMenu } from '../../components';
import InTimeSheetManageTable from '../../components/InTimeSheetManageTable';
import { inTimeSheetActions } from '../../redux/actions';
import './styles.scss';

const mapStateToProps = state => ({
  selectedSubMenu: state.commonReducers.selectedSubMenu,
  userData: state.memberReducers.userData,
  activeLottery: state.commonReducers.activeLottery,
  inTimeSheetInfo: state.inTimeSheetReducers.inTimeSheetInfo,
});

const mapDispatchToProps = dispatch => ({
  getInTimeSheetInfo: (lotteryCode, isTema, callback) => dispatch(inTimeSheetActions.getInTimeSheetInfo(lotteryCode, isTema, callback)),
});

const InTimeSheetManage = ({ passData, selectedSubMenu, activeLottery, getInTimeSheetInfo, inTimeSheetInfo, userData }) => {
  const savedCallback = useRef();
  const [amountMode, setamountMode] = useState('1');
  const [buhuoData, setBuhuoData] = useState();
  const childRef = useRef();

  const [_isAvailable, setAvailability] = useState(false);
  const [_selectedAmount, setAmount] = useState(0);

  const getInTimeSheetInfoData = () => {
    getInTimeSheetInfo(activeLottery.code, childRef?.current?._midCode && childRef?.current?._midCode?.midCode ? childRef?.current?._midCode.midCode.indexOf('TM') > -1 ? 1 : 2 : 0);
  };

  useEffect(() => {
    if (activeLottery && childRef?.current?._midCode) {
      getInTimeSheetInfoData();
    }
  }, [childRef?.current?._midCode, activeLottery]);

  useEffect(() => {
    const tick = () => {
      savedCallback.current();
    };
    const id = setInterval(tick, 10000);
    return () => {
      clearInterval(id);
    };
  }, []);

  useEffect(() => {
    savedCallback.current = () => {
      if (activeLottery) {
        getInTimeSheetInfoData();
      }
    };
  });

  const callOdds = () => {
    childRef?.current?.change();
  };

  return (
    <div className="InTimeSheetManageWrapper">
      { selectedSubMenu && (selectedSubMenu === 'IS_BLOCK_NUMBER_SETTING' || selectedSubMenu === 'IS_BET_TASK_BACKUP' || selectedSubMenu === 'IS_CHECK' || selectedSubMenu === 'IS_AUTO_RESTOCK_SETTINGS' || selectedSubMenu === 'IS_RESTOCK_RECORDS' || selectedSubMenu === 'IS_REVOKE')
        ? (
          <>
            {selectedSubMenu === 'IS_BLOCK_NUMBER_SETTING' && <BlockBetNumbers />}
            {selectedSubMenu === 'IS_BET_TASK_BACKUP' && <BetTaskBackup />}
            {selectedSubMenu === 'IS_CHECK' && <BetQueryDetail />}
            {selectedSubMenu === 'IS_AUTO_RESTOCK_SETTINGS' && <AutoRestockSetting />}
            {selectedSubMenu === 'IS_RESTOCK_RECORDS' && <RestockOperationLog />}
            {selectedSubMenu === 'IS_REVOKE' && <BetRevoke />}
          </>
        )
        : (
          <>
            <InTimeSheetManageTable _selectedAmount={_selectedAmount} _isAvailable={_isAvailable} setAvailability={setAvailability} setBuhuoData={setBuhuoData} passData={passData} childRef={childRef} amountMode={amountMode} setamountMode={setamountMode} />
            {
              ((inTimeSheetInfo?.orgCtrlSetting === 1 && userData?.levelValue === inTimeSheetInfo?.info?.zdLevel) || (userData?.levelValue < inTimeSheetInfo?.info?.zdLevel))
              && <InTimeSheetBottomMenu _selectedAmount={_selectedAmount} setAmount={setAmount} _isAvailable={_isAvailable} buhuoData={buhuoData} callOdds={callOdds} amountMode={amountMode} />
            }
          </>
        )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(InTimeSheetManage);