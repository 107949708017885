import { useEffect, useState } from 'react';
import { Button } from 'antd';
import { Label } from 'reactstrap';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { AgentDataTable, CommonHeader } from '../..';
import { ReactComponent as Tick } from '../../../assets/image/icon_tick.svg';
import { systemActions } from '../../../redux/actions';

import './styles.scss';

const mapDispatchToProps = dispatch => ({
  GetLotteryStyle: calllback => dispatch(systemActions.GetLotteryStyle(calllback)),
  SaveLotteryStyle: (obj, calllback) => dispatch(systemActions.SaveLotteryStyle(obj, calllback)),
});

const LotteryStyle = ({ GetLotteryStyle, SaveLotteryStyle }) => {
  const { t } = useTranslation();
  const [_isQuerying, setIsQuerying] = useState(false);
  const [data, setData] = useState();

  const getData = () => {
    setIsQuerying(true);
    GetLotteryStyle(data => { setData(data); setIsQuerying(false); });
  };

  useEffect(() => {
    getData();
  }, []);

  const onClickSave = () => {
    SaveLotteryStyle(
      data.map(item => {
        const { lotteryType } = item;
        return {
          lotteryType,
          companyId: item.companyId,
          style: item.style,
        };
      }),
      getData
    );
  };

  const saveButton = (
    <Button
      onClick={() => onClickSave()}
      className="operationBtn"
    >
      {
        t('SAVE')
      }
    </Button>
  );

  return (
    <div className="LotteryStyleWrapper">
      <CommonHeader
        headerName={t('LOTTERY_STYLE_MANAGEMENT')}
        rightLocationItem={() => saveButton}
      />

      <div className="mx-0 bg-white px-0 border-1">
        <AgentDataTable
          isQuerying={_isQuerying}
          tableHeaderTitle={
            [
              {
                label: `${t('LOTTERY')}Code`,
              },
              {
                label: t('NAME'),
              },
              {
                label: t('THEME'),
              }
            ]
          }
          tableHeaderStyle={
            []
          }
          tableData={data || []}
          tableBodyRowProperty={
            [
              data => (<Label className="d-flex justify-content-center">{data.lotteryType}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.lotteryName}</Label>),
              (item, index) => (
                <div className="d-flex align-items-center justify-content-center">
                  {
                    ['blue', 'red', 'green', 'yellow'].map(x => (
                      <div
                        className={`colorSelectBox ${x}`}
                        onClick={() => {
                          setData([
                            ...data.slice(0, index),
                            {
                              ...item,
                              style: x,
                            },
                            ...data.slice(index + 1)
                          ]);
                        }}
                      >
                        {item.style === x && <Tick />}
                      </div>
                    ))
                  }
                </div>
              )
            ]
          }
        />
      </div>

      <div className="d-flex justify-content-center py-2">
        {saveButton}
      </div>
    </div>
  );
};

export default connect(null, mapDispatchToProps)(LotteryStyle);