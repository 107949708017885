/* eslint-disable indent */
import { useEffect, useState } from 'react';

import { Button, Input, Popover, Select } from 'antd';
import { FaCircle, FaEdit, FaPlusCircle } from 'react-icons/fa';
import { MdArrowBack } from 'react-icons/md';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { AgentDataTable, CommonHeader } from '../..';
import { agentActions, appActions } from '../../../redux/actions';
import ChangeHisLog from '../components/ChangeHisLog';
import LoginLog from '../components/LoginLog';
import Notifications from '../../Content/Notifications';
import Pagination from '../../Content/Pagination';
import SubAccountAdd from '../components/SubAccountAdd';
import SubAccountEdit from '../components/SubAccountEdit';

import './styles.scss';

const limitTypeOptions = [{
  label: '全部', value: '',
}, {
  label: '信用', value: '1',
}, {
  label: '现金', value: '2',
}];

const statusOptions = [{
  label: '全部', value: '',
}, {
  label: '启用', value: '1',
}, {
  label: '暂停', value: '2',
}, {
  label: '停用', value: '0',
}, {
  label: '锁定', value: '3',
}];

const orderOptions = [{
  label: '降序排序', value: 'DESC'
}, {
  label: '升序排序', value: 'ASC'
}];

const orderByOptions = [{
  label: '请选择', value: ''
}, {
  label: '账号', value: 'LoginId'
}, {
  label: '额度', value: 'MoneyLimit'
}, {
  label: '创建日期', value: 'CreateDate'
}];

const searchByOptions = [{
  label: '账号', value: 'LoginId'
}, {
  label: 'IP', value: 'LoginIp'
}];


const mapStateToProps = state => ({
  userData: state.memberReducers.userData,
});

const mapDispatchToProps = dispatch => ({
  deleteUser: (obj, callback, catchback) => dispatch(agentActions.deleteUser(obj, callback, catchback)),
  getAgentList2: (obj, callback, catchback) => dispatch(agentActions.getAgentList2(obj, callback, catchback)),
  getAgentList: (agentId, levelValue, orderBy, order, page, rows, startDate, endDate, isChild, loginId, status, searchBy, minAmount, maxAmount) => dispatch(agentActions.getAgentList(agentId, levelValue, orderBy, order, page, rows, startDate, endDate, isChild, loginId, status, searchBy, minAmount, maxAmount)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  setAccountStatus: (obj, searchObj) => dispatch(agentActions.setAccountStatus(obj, searchObj)),
  setAccountStatus2: (obj, callback) => dispatch(agentActions.setAccountStatus2(obj, callback)),
  getLoginAs: (obj, callback) => dispatch(agentActions.getLoginAs(obj, callback)),
  unlockMember: (obj, callback) => dispatch(agentActions.unlockMember(obj, callback)),
});

const DirectSubAccount = ({ unlockMember, deleteUser, getLoginAs, userData, getAgentList2, updateModalAlert, setAccountStatus2 }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({
    limitType: '',
    status: '',
    orderBy: 'CreateDate',
    order: 'DESC',
    searchBy: 'LoginId', // 后端参数定义错误，seach？？？
    searchValue: '' // 输入框搜索值
  });
  const [data, setData] = useState({}); // 表格数据 + 分页数据

  const [agentInfo, setAgentInfo] = useState();

  const copyStateData = data;
  const [pagination, setPagination] = useState({
    total: 0,
    pageSize: 15,
    current: 1
  });
  const [selectedItem, setSelectedItem] = useState({});

  const [showDetail, setShowDetail] = useState(false);
  const [tabIndex, setTabIndex] = useState('');

  const [breadcrumbList, setBreadcrumbList] = useState([]);

  useEffect(() => {
    if (userData) {
      setBreadcrumbList([{
        label: `公司-${userData.loginId}`,
        value: userData.loginId,
        orgId: userData.id,
        isChild: userData.isChild,
        userLevel: userData.levelValue,
        active: true,
        clickUp: false // 怎么来的。
      }]);
    }
  }, [userData]);

  useEffect(() => {
    getInfo();
  }, [breadcrumbList]);

  const activeUser = breadcrumbList.find(i => i.active) || {};

  const tableHeaderTitle = [{
    label: t('ONLINE'),
  }, {
    label: t('OPERATION'),
  }, {
    label: t('ACCOUNT'),
  }, {
    label: t('CREATE_DATE'),
  }, {
    label: t('STATUS'),
  }];

  const tableContent = [
    data => (
      <span className={`${data.online === 1 ? 'positiveValue' : 'offline'} justify-content-center smallicon`}>
        <FaCircle className="ml-2" />
      </span>
    ),
    data => (
      <span className="positiveValue urlNoUnderline" onClick={() => setSelectedItem(selectedItem?.orgId ? {} : data)}>
        <FaEdit className="ml-2" />
      </span>
    ),
    data => (
      <>
        <span className="mx-1">
          {data.userId}
        </span>
        <span>
          [
          {data.loginName}
          ]
        </span>
      </>
    ),
    data => (<span className="px-1">{data?.createDate}</span>),
    (data, index) => {
      let content = <Button className="operationBtn editAndSave">{t('PAUSE')}</Button>;
      if (data.useStateValue === 1) {
        content = <Button className="operationBtn add">{t('ACTIVE')}</Button>;
      } else if (data.useStateValue === 0) {
        content = <Button className="operationBtn remove">{t('INACTIVE')}</Button>;
      }
      return (
        <Popover
          content={(
            <div className="p-0 d-flex align-items-center">
              <Button
                className="mx-1 operationBtn add"
                onClick={() => {
                  data.useStateValue === 1 ? false : setAccountStatus2(
                    {
                      userId: data?.orgId,
                      status: 1,
                      userType: 2,
                      orgId: data?.orgId,
                    },
                    () => {
                      setData(
                        [
                          ...copyStateData.slice(0, index),
                          {
                            ...data, useStateValue: 1
                          },
                          ...copyStateData.slice(index + 1),
                        ]
                      );
                    }
                  );
                }}
              >
                {t('ACTIVE')}
              </Button>
              <Button
                className="mx-1 operationBtn remove"
                onClick={() => {
                  data.useStateValue === 0 ? false : setAccountStatus2(
                    {
                      userId: data?.orgId,
                      status: 0,
                      userType: 2,
                      orgId: data?.orgId,
                    },
                    () => {
                      setData(
                        [
                          ...copyStateData.slice(0, index),
                          {
                            ...data, useStateValue: 0
                          },
                          ...copyStateData.slice(index + 1),
                        ]
                      );
                    }
                  );
                }}
              >
                {t('INACTIVE')}
              </Button>
              <Button
                className="mx-1 operationBtn editAndSave"
                onClick={() => {
                  data.useStateValue === 2 ? false : setAccountStatus2(
                    {
                      userId: data?.orgId,
                      status: 2,
                      userType: 2,
                      orgId: data?.orgId,
                    },
                    () => {
                      setData(
                        [
                          ...copyStateData.slice(0, index),
                          {
                            ...data, useStateValue: 2
                          },
                          ...copyStateData.slice(index + 1),
                        ]
                      );
                    }
                  );
                }}
              >
                {t('PAUSE')}
              </Button>
            </div>
          )}
        >
          {content}
        </Popover>
      );
    }
  ];

  const getInfo = async (paginationParams = {}, params = {}) => {
    setLoading(true);
    const newPagiantion = {
      ...pagination,
      ...paginationParams,
    };

    getAgentList2(
      {
        agentId: activeUser?.orgId || '',
        level: 0, // 点击上级账号，level 变成 0
        orderBy: filter.orderBy,
        order: filter.order,
        loginId: filter.searchValue, // 搜索值
        page: newPagiantion.current,
        rows: newPagiantion.pageSize,
        isChild: 3,
        status: filter.status,
        seachBy: filter.searchBy,
        ...params,
        limitType: '', // 这个参数在1.0 也是没用的.
      },
      res => {
        setData(res?.list || []);
        setAgentInfo(res?.info);
        setPagination({
          ...newPagiantion,
          total: res?.total?.records || 0
        });
        setLoading(false);
      },
      () => setLoading(false)
    );
    setSelectedItem({});
  };

  const handleClickControl = (tabIndex = '') => {
    if (tabIndex === 0) {
      setFilter({
        ...filter,
        limitType: '',
        status: '',
        orderBy: 'CreateDate',
        order: 'DESC',
        searchBy: 'LoginId', // 后端参数定义错误，seach？？？
        searchValue: '' // 输入框搜索值
      });
      setPagination({
        ...pagination,
        current: 1
      });
    }
    setShowDetail(true);
    setTabIndex(tabIndex);
  };

  const actions = [
    {
      label: '新增',
      render: data => (
        <SubAccountAdd
          callback={v => {
            if (!v) return;
            setShowDetail(false);
            getInfo();
          }}
          data={data}
          onCancel={() => setShowDetail(false)}
        />
      )
    },
    {
      label: '修改',
      render: data => (
        <SubAccountEdit
          callback={v => {
            if (!v) return;
            setShowDetail(false);
            getInfo();
          }}
          data={data}
          onCancel={() => setShowDetail(false)}
        />
      )
    },
    (selectedItem?.isLocked === 1 && {
      label: '解锁',
      onClick: () => {
        unlockMember({
          memberId: selectedItem?.orgId,
          memberType: 2,
        });
      }
    }) || null,
    {
      label: '登陆日志', render: data => (<LoginLog data={data} />)
    }, {
      label: '变更日志', render: data => (<ChangeHisLog data={data} />)
    },
    (userData?.levelValue <= agentInfo?.companyLevel && userData?.isChild === 0 && {
      label: '登入',
      onClick: () => {
        const openNewWindow = data => {
          const callback = () => { window.open(`${window.location.origin}/?loginId=${data.loginId}&uid=${data.orgId}&data=${data.token}`); };
          getLoginAs({
            loginId: data.loginId, uid: data.orgId, data: data.token
          }, callback);
        };
        openNewWindow(selectedItem);
      },
    }) || null,
    (userData?.levelValue === 1 && userData?.isChild === 0 && {
      label: '删除',
      onClick: () => {
        updateModalAlert({
          visible: true,
          type: 'confirmation',
          showCancelButton: true,
          data: {
            message: '股东下的所有会员及其注单数据都会被删除，确定删除吗？',
            onConfirmationClick: () => {
              deleteUser({
                userType: selectedItem?.memberId ? 1 : 2,
                userId: selectedItem?.memberId ? selectedItem?.memberId : selectedItem?.orgId
              });
            }
          }
        });
      }
    }) || null,
  ].filter(Boolean).map((item, index) => ({
    ...item,
    value: index,
    onClick: item.onClick || (() => handleClickControl(index))
  }));

  const tableControl = [
    data => (
      <>
        {
          actions.slice(1).map(item => (
            <Button
              className="controlButton mx-1"
              value={data?.orgId}
              onClick={() => item.onClick()}
            >
              {
                item.label
              }
            </Button>
          ))
        }
      </>
    )
  ];

  return (
    <div className="DirectSubAccountWrapper1 p-3">
      {
        !showDetail && (
          <>
            <div className="header p-2 mb-2 filterWrapper d-flex align-items-center w-100 rounded-top">
              <div className="filterArea">
                <div>
                  <span className="mx-1">模式</span>
                  <Select
                    value={filter.limitType}
                    onChange={value => {
                      setFilter({
                        ...filter, limitType: value
                      });
                      getInfo({}, {
                        limitType: value
                      });
                    }}
                    options={limitTypeOptions}
                  />
                </div>
                <div>
                  <span className="mx-1">{t('STATUS')}</span>
                  <Select
                    value={filter.status}
                    onChange={value => {
                      setFilter({
                        ...filter, status: value
                      });
                      getInfo({}, {
                        status: value
                      });
                    }}
                    options={statusOptions}
                  />
                </div>
                <div>
                  <span className="mx-1">排序</span>
                  <Select
                    className="w-select"
                    value={filter.orderBy}
                    onChange={value => {
                      setFilter({
                        ...filter, orderBy: value
                      });
                      getInfo({}, {
                        orderBy: value
                      });
                    }}
                    options={orderByOptions}
                  />
                  <Select
                    className="mx-1"
                    value={filter.order}
                    onChange={value => {
                      setFilter({
                        ...filter, order: value
                      });
                      getInfo({}, {
                        order: value
                      });
                    }}
                    options={orderOptions}
                  />
                </div>
                <div className="d-flex align-items-center ">
                  <span className="mx-1">搜索</span>
                  <Select
                    value={filter.searchBy}
                    onChange={value => (setFilter({
                      ...filter, searchBy: value
                    }))}
                    options={searchByOptions}
                  />
                  <Input
                    className="mx-1 w-input-100"
                    value={filter.searchValue}
                    onChange={e => (setFilter({
                      ...filter, searchValue: e.target.value
                    }))}
                  />
                </div>
                <div>
                  <Button className="searchButton marginLeft5 me-2" onClick={() => getInfo()}>{t('FIND')}</Button>
                </div>
              </div>

              <Notifications />
            </div>

            <div className="subMenu mb-2 d-flex align-items-center">
              {
                userData?.isChild === 0 && (
                  <Button
                    className="addButton mx-2"
                    onClick={() => handleClickControl(0)} // 新增 是  action 【0】
                  >
                    <FaPlusCircle className="mx-1" />
                    新增子账号
                  </Button>
                )
              }

            </div>

            <div>
              <AgentDataTable
                tableHeaderTitle={tableHeaderTitle}
                tableControl={tableControl}
                tableBodyRowProperty={tableContent}
                activeControl={(selectedItem?.orgId || undefined)}
                isQuerying={loading}
                tableData={data}
              />
            </div>

            <div>
              <Pagination
                customizePage={pagination?.current}
                totalRecord={pagination?.total || 0}
                isQuery={loading}
                onClick={(pageNum, rows) => {
                  getInfo({
                    pageSize: rows, current: pageNum
                  });
                }}
              />
            </div>
          </>
        )
      }

      {
        showDetail && (
          <>
            <CommonHeader
              headerName={(
                <div className="d-flex align-items-center">
                  <div
                    className="d-flex align-items-center me-2 backIcon"
                    onClick={() => {
                    setShowDetail(false);
                    getInfo();
                    }}
                  >
                    <MdArrowBack className="h5 mb-0 text-white" />
                    返回
                  </div>
                  <div>
                    <span className="mx-1">子账号</span>
                    {
                      selectedItem?.loginId && '/'
                    }
                    <span className="mx-1">{selectedItem?.loginId}</span>
                  </div>
                </div>
              )}
            />
            {
              ![0].includes(tabIndex) && (
                <div className="whiteListMenu">
                  {/* 内容和 control 一样。slice(1) 没有render 的也不能出现，注意 tableIndex 是基于所有的actions */}
                  {
                    actions.map((item, index) => {
                      const { label, onClick, render } = item;
                      if (!render) return false;
                      return (
                        <div onClick={() => onClick()} className={`menu_item ${tabIndex === index && 'selected'}`}>
                          {label}
                        </div>
                      );
                    }).slice(1).filter(Boolean)
                  }
                </div>
              )
            }
            <div>
              {actions.find(i => i.value === tabIndex)?.render?.(selectedItem)}
            </div>
          </>
        )
      }
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DirectSubAccount);