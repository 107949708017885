import { extraDs, totalHdxds, totalSumDS, totalSumDX } from '../Items/dsdx';

import { element, extraFrontBack, total810 } from '../Items/others';

import { numberBlue1to80 } from '../Items/numbers';

import { qs5Element, qsBJK8 } from '../Items/quickset';

const BJK8 = [{
  displayName: '总项盘口',
  midcode: 'LM,ZHH,ZHGG,QHH,DSH,WX',
  isStaticRow: 1,
  combineStyle: 1,
  repeatPerRow: 1,
  repeatPerCol: 5,
  quickSet: {
    style: 2,
    variables: [[...qsBJK8, ...qs5Element]]
  },
  display: [[...totalSumDS, ...totalSumDX, ...total810],
    [...totalHdxds],
    [...extraFrontBack],
    [...extraDs],
    [...element]],
  firstTableHeader: ['总和', '总和过关', '前后和', '单双和', '五行'],
},
{
  displayName: '正码',
  displayRows: 20,
  midcode: 'ZM',
  display: [...numberBlue1to80],
},
];

export default BJK8;