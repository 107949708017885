import { useEffect, useState } from 'react';

import { Input, Radio } from 'antd';
import { Label } from 'reactstrap';
import { MdArrowBack } from 'react-icons/md';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { PlusCircleOutlined } from '@ant-design/icons';

import { AgentDataTable, CommonHeader, Pagination } from '../..';
import { appActions, systemActions } from '../../../redux/actions';

import './styles.scss';

const mapStateToProps = state => ({
  lotteryAddressList: state.systemReducers.lotteryAddressList,
  lotteryEditAddress: state.systemReducers.lotteryEditAddress,
});

const mapDispatchToProps = dispatch => ({
  getLotteryAddressList: (obj, callback) => dispatch(systemActions.getLotteryAddressList(obj, callback)),
  getEditLotteryAddress: (obj, callback) => dispatch(systemActions.editLotteryAddress(obj, callback)),
  SaveLotteryAddress: (obj, callback) => dispatch(systemActions.SaveLotteryAddress(obj, callback)),
  deleteLotteryAddress: (id, callback) => dispatch(systemActions.deleteLotteryAddress(id, callback)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
});

const OpenResultAddress = ({ updateModalAlert, lotteryAddressList, lotteryEditAddress, getLotteryAddressList, getEditLotteryAddress, SaveLotteryAddress, deleteLotteryAddress }) => {
  const { t } = useTranslation();
  const [_isQuerying, setIsQuerying] = useState(false);
  const [_pageFlag, setPageFlag] = useState(0);

  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(15);

  const [webName, setwebName] = useState('');
  const [status, setStatus] = useState(1);
  const [url, setUrl] = useState();

  useEffect(() => {
    setIsQuerying(true);
    const _obj = {
      page,
      rows,
      webName
    };
    getLotteryAddressList(_obj, setIsQuerying);
  }, []);

  const saveAndEdit = () => {
    const invalid = url && !jugeUrl(url);
    if (invalid) {
      return updateModalAlert({
        type: 'confirmation',
        visible: true,
        showCancelButton: true,
        data: {
          message: '请输入正确的网址格式',
        },
      });
    }
    if (_pageFlag === 1) {
      const obj = {
        url,
        webName,
        status
      };
      const callback = () => {
        getLotteryAddressList();
        setPageFlag(0);
      };
      SaveLotteryAddress(obj, callback);
    }
    if (_pageFlag === 2) {
      const obj = {
        id: lotteryEditAddress.id,
        url,
        webName,
        status
      };
      const callback = () => {
        setPageFlag(0);
        getLotteryAddressList();
      };
      SaveLotteryAddress(obj, callback);
    }
  };

  const Remove = id => {
    const _obj = {
      page: 1,
      rows: 15,
      webName
    };
    deleteLotteryAddress(id, () => { setIsQuerying(true); getLotteryAddressList(_obj, setIsQuerying); });
  };


  const onSearchClick = () => {
    setIsQuerying(true);
    const _obj = {
      page: 1,
      rows: 15,
      webName
    };
    getLotteryAddressList(_obj, setIsQuerying);
    setPage(1);
    setRows(15);
  };

  const onPaginationClick = (pageNum, rows) => {
    setIsQuerying(true);
    setPage(pageNum);
    setRows(rows);
    const _obj = {
      page: pageNum,
      rows,
      webName
    };
    getLotteryAddressList(_obj, setIsQuerying);
    (_obj, setIsQuerying);
  };

  useEffect(() => {
    if (_pageFlag === 1 || _pageFlag === 0) {
      setUrl('');
      setStatus(1);
      setwebName('');
    }
    if (_pageFlag === 2 && lotteryEditAddress) {
      setUrl(lotteryEditAddress?.url || '');
      setStatus(lotteryEditAddress ? lotteryEditAddress?.status : 1);
      setwebName(lotteryEditAddress?.webName || '');
    }
  }, [_pageFlag, lotteryEditAddress]);

  function jugeUrl(URL) {
    const valid = URL?.startsWith('http') || URL?.startsWith('https');
    if (valid) {
      return true;
    }
    return false;
  }

  return (
    <div className="OpenResultAddressWrapper">
      <CommonHeader
        headerName={(
          <div className="d-flex align-items-center">
            {_pageFlag !== 0 && (
              <div className="d-flex align-items-center me-2 backIcon" onClick={() => setPageFlag(0)}>
                <MdArrowBack className="h5 mb-0 text-white" />
                返回
              </div>
            )}
            {`${t('OPEN_RESULT_ADDRESS_MANAGEMENT')} ${_pageFlag === 0 ? '' : _pageFlag === 1 ? '/ 新增地址' : '/ 编辑地址'}`}
          </div>
        )}
      />
      {_pageFlag === 0 && (
        <>
          <div className="addNewGameBox my-3">
            <div className="operationBtn add d-flex align-items-center" onClick={() => setPageFlag(1)}>
              <PlusCircleOutlined />
              <span className="ms-2">新增地址</span>
            </div>
            <div className="mx-2 d-flex justify-content-center align-items-center">
              <span className="text-max mx-1 d-flex justify-content-center align-items-center">站点名称</span>
              <Input className="ms-2" value={webName} onChange={e => setwebName(e.target.value)} />
            </div>
            <div className="operationBtn mx-2 add" onClick={() => onSearchClick()}>查询</div>
          </div>

          <div className="mx-0 bg-white mb-3 px-0 border-1">
            <AgentDataTable
              isQuerying={_isQuerying}
              tableHeaderTitle={
                [{
                  label: '站点名称',
                }, {
                  label: '站点Url',
                }, {
                  label: '状态',
                }, {
                  label: t('OPERATION'),
                }]
              }
              tableHeaderStyle={
                []
              }
              tableData={lotteryAddressList ? lotteryAddressList.addressList : []}
              tableBodyRowProperty={
                [
                  data => (<Label className="d-flex justify-content-center">{data.webName}</Label>),
                  data => (<Label className="d-flex justify-content-center">{data.url}</Label>),
                  data => (<Label className={`d-flex justify-content-center ${data.status === 0 && 'text-danger'}`}>{data.status === 0 ? '禁用' : '启用'}</Label>),
                  data => (
                    <Label className="d-flex justify-content-center align-items-center">
                      <div className="operationBtn mx-2" onClick={() => { getEditLotteryAddress(data.id, () => setPageFlag(2)); }}>编辑</div>
                      <div className="operationBtn cancel mx-2" onClick={() => { Remove(data.id); }}>删除</div>
                    </Label>
                  )
                ]
              }
            />
            <div className="d-flex align-items-center justify-content-center w-full relative">
              <Pagination totalRecord={lotteryAddressList ? (lotteryAddressList.totalRecords || 0) : 0} onClick={onPaginationClick} isQuery={false} />
            </div>
          </div>
        </>
      )}
      {_pageFlag !== 0 && (
        <>
          <div className="my-3 thirdPartyGamesTable">
            <div className="thirdPartyGamesTitle">{_pageFlag === 1 ? '新增地址' : '编辑地址'}</div>
            <div className="thirdPartyGamesRow">
              {/* <div>管理员登陆域名: </div> */}
              <div>站点名称: </div>
              <div><Input value={webName} onChange={e => setwebName(e.target.value.trim())} placeholder="请输入内容" /></div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>状态: </div>
              <div>
                <Radio value={1} checked={status === 1} onChange={() => setStatus(1)}>启动</Radio>
                <Radio value={0} checked={status === 0} onChange={() => setStatus(0)}>禁用</Radio>
              </div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>站点Url: </div>
              <div className="d-flex align-items-center">
                <Input.TextArea
                  value={url}
                  onChange={e => {
                    const text = e.target.value;
                    setUrl(text);
                  }}
                  placeholder="请输入内容"
                />
                <span className="mx-2 text-red">
                  {
                    url && !jugeUrl(url) && '请输入正确的网址格式'
                  }
                </span>
              </div>
            </div>
          </div>
          <div className="thirdPartyGamesFooter">
            <div className="operationBtn editAndSave me-3" onClick={() => saveAndEdit()}>{t('CONFIRM')}</div>
            <div className="operationBtn cancel me-3" onClick={() => setPageFlag(0)}>{t('CANCEL')}</div>
          </div>
        </>
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(OpenResultAddress);