import { useEffect, useState } from 'react';

import { Button, Col, Label, Row } from 'reactstrap';
import { IoClose } from 'react-icons/io5';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { appActions, betListActions } from '../../../redux/actions';
import AgentDataTable from '../../AgentDataTable';

import './styles.scss';

const mapStateToProps = state => ({
  betZCDetail: state.betListReducers.betZCDetail,
});

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  getBetZCDetail: id => dispatch(betListActions.getBetZCDetail(id)),
});

const BetDetailsModal = ({ updateModalAlert, data, getBetZCDetail, betZCDetail }) => {
  const { t } = useTranslation();
  const [_detailItem, setDetailItem] = useState();
  const [_header, setHeader] = useState([]);
  const [_content, setContent] = useState([]);
  const lowLevelAgentItems = [0, 1, 3];

  useEffect(() => {
    // Default search when first load
    data && data?.id && getBetZCDetail(data?.id);
  }, [data]);

  useEffect(() => {
    if (betZCDetail) {
      setDetailItem(betZCDetail.betZcList);
    }
    // Hide most of the detail for low level agent
    if (betZCDetail && Number(betZCDetail.levelValue) > 2) {
      const tempHead = [];
      const tempContent = [];
      lowLevelAgentItems.forEach(d => {
        tempHead.push(tableHeader[d]);
        tempContent.push(tableContent[d]);
      });
      setHeader(tempHead);
      setContent(tempContent);
    } else {
      setHeader(tableHeader);
      setContent(tableContent);
    }
  }, [betZCDetail]);

  const tableHeader = [{
    label: `${t('LEVEL')}/${t('NAME')}`,
  }, {
    label: t('ZC'),
  }, {
    label: t('ZC') + t('AMOUNT'),
  }, {
    label: t('REBATE'),
  }, {
    label: t('TO_REBATE') + t('AMOUNT'),
  }, {
    label: t('ODDS'),
  }, {
    label: t('COMMISSION'),
  }, {
    label: t('WIN_LOST'),
  }, {
    label: t('RESULT'),
  }, {
    label: t('HAND_IN') + t('VOLUME'),
  }, {
    label: t('UPPER_LEVEL') + t('REBATE'),
  }, {
    label: t('UPPER_LEVEL') + t('SETTLEMENT'),
  }];

  const tableContent = [
    data => (<Label className="d-flex justify-content-center">{`${data?.levelName}/${data?.orgName}`}</Label>),
    data => (<Label className="d-flex justify-content-center">{`${data?.zc}%`}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.zcAmount}</Label>),
    data => (<Label className="d-flex justify-content-center">{`${data?.rebate}%`}</Label>),
    data => (<Label className={`d-flex justify-content-center ${data?.thisRebate >= 0 ? 'positiveValue' : 'negativeValue'}`}>{data?.thisRebate}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.odds}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.earnCommission}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.earnOdds}</Label>),
    data => (<Label className={`d-flex justify-content-center ${data?.result >= 0 ? 'positiveValue' : 'negativeValue'}`}>{data?.result}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.handInAmt}</Label>),
    data => (<Label className={`d-flex justify-content-center ${data?.handInRebate >= 0 ? 'positiveValue' : 'negativeValue'}`}>{data?.handInRebate}</Label>),
    data => (<Label className={`d-flex justify-content-center ${data?.handInResult >= 0 ? 'positiveValue' : 'negativeValue'}`}>{data?.handInResult}</Label>),
  ];

  return (
    <div className="betDetailModalWrapperWeb">
      <Row className="modalHeader">
        <Col
          className={`${'d-flex align-items-center'}`}
        >
          {t('ZC')}
          {t('DETAILS')}
        </Col>
        <Col className="d-flex justify-content-end align-items-center">
          <IoClose
            onClick={() => {
              updateModalAlert({
                visible: false
              });
              if (data && data.overBet && data.onConfirmationClick) data.onConfirmationClick();
            }}
            className="h4 mb-0 float-right closeIcon"
          />
        </Col>
      </Row>
      <Row className="modalContent d-flex justify-content-center">
        <Row className="py-2 ">
          {
            data && data?.id && betZCDetail && betZCDetail?.levelValue < 3 && (
              <Label className="d-flex relative">
                {`
                    会员金额: ${betZCDetail?.betAmount}
                    会员结果: ${betZCDetail?.betResult}
                    会员退水: ${betZCDetail?.betRebate}/
                    退水金额: ${betZCDetail?.betRebateAmount}`}
              </Label>
            )
          }
        </Row>
        <Row className="modalContent d-flex p-2 justify-content-center">
          <AgentDataTable
            tableHeaderTitle={_header}
            tableData={_detailItem}
            tableBodyRowProperty={_content}
          />
        </Row>
      </Row>
      <Row className="modalFooter d-flex align-items-center">
        <Button
          className={`confirmButton my-2 ${'me-3'}`}
          onClick={() => {
            updateModalAlert({
              visible: false
            });
            if (data && data.onConfirmationClick) data.onConfirmationClick();
          }}
        >
          {(data && data.confirmText) || t('CONFIRM')}
        </Button>
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BetDetailsModal);