import { useEffect, useState } from 'react';

import { Button, Col, Input, Label, Row } from 'reactstrap';
import { connect } from 'react-redux';

import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

import { appActions, systemActions } from '../../../redux/actions';
import './styles.scss';


const mapStateToProps = state => ({
  editNoticeData: state.noticeReducers.editNoticeData,
  userData: state.memberReducers.userData,
  editOfflineSettingData: state.systemReducers.editOfflineSettingData,
});

const mapDispatchToProps = dispatch => ({
  getEditOfflineSetting: (obj, callback) => dispatch(systemActions.getEditOfflineSetting(obj, callback)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  saveOfflineSetting: (obj, callback) => dispatch(systemActions.saveOfflineSetting(obj, callback)),
});

const PaymentOfflineSettingAddEdit = ({ callback = {}, isEdit, data, updateModalAlert, editOfflineSettingData, saveOfflineSetting, getEditOfflineSetting }) => {
  const { t } = useTranslation();
  const [_info, setInfo] = useState([]);

  useEffect(() => {
    if (!isEdit) {
      setInfo([]);
    } else if (data && JSON.stringify(data) !== JSON.stringify(_info)) {
      getEditOfflineSetting({
        id: data?.id
      });
    }
  }, [data]);

  useEffect(() => {
    if (!isEdit) {
      setInfo([]);
    } else if (editOfflineSettingData && JSON.stringify(editOfflineSettingData) !== JSON.stringify(_info)) {
      setInfo(editOfflineSettingData);
    }
  }, [editOfflineSettingData]);

  const saveButton = () => {
    if (_info?.isChange) {
      const updateObj = _info;
      if (!isEdit) {
        updateObj.isUpdate = 0;
      } else {
        updateObj.isUpdate = 1;
      }
      updateModalAlert({
        type: 'loading',
        visible: true,
      });
      saveOfflineSetting(updateObj, callback);
    } else {
      updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: '无变动'
        }
      });
    }
  };

  const onInfoValueChange = (val, field) => {
    const newItems = _info;
    newItems[field] = val;
    newItems.isChange = true;
    setInfo(newItems => ({
      ...newItems, field: val
    }));
  };

  const resetDefault = () => {
    if (!isEdit) {
      callback();
    } else {
      getEditOfflineSetting({
        id: data?.id
      });
    }
  };

  const platforms = [
    {
      label: '后台',
      value: '0',
    }, {
      label: '会员PC版',
      value: '1',
    },
    {
      label: '会员手机版',
      value: '2',
    }
  ];

  const status = [
    {
      label: t('ALLOW'),
      value: '0',
    }, {
      label: t('BAN'),
      value: '1',
    }
  ];
  return (
    <div className="PaymentOfflineSettingAddEditWrapper">
      <div className="mx-0 bg-white my-2  border-1">
        <Row className="m-0 my-1 p-0">
          <table className="p-3 m-0" cellSpacing="0" cellPadding="0" border="0">
            <tbody>
              <tr>
                <td className="titleHeader" colSpan={2}>
                  {isEdit === true ? '编辑收款线下设置' : '新增收款线下设置'}
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">{t('MIN_SINGLE_DEPOSIT_VALUE')}</td>
                <td>
                  <div>
                    <Input
                      key={`min_${_info?.id}`}
                      className="d-inline col-1"
                      type="number"
                      value={_info?.min?.toString() || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text === '' ? '' : Number(text), 'min')}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">{t('MAX_SINGLE_DEPOSIT_VALUE')}</td>
                <td>
                  <div>
                    <Input
                      className="d-inline col-1"
                      type="number"
                      value={_info?.max?.toString() || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text === '' ? '' : Number(text), 'max')}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">{t('CLIENT_PLATFORM_BELONGS')}</td>
                <td>
                  <Select
                    value={_info?.platform?.toString() || ''}
                    placeholder="请选择"
                    onChange={val => { onInfoValueChange(Number(val), 'platform'); }}
                  >
                    {
                            platforms && platforms.map(d => (
                              <Select.Option key={`setName_${d.value}`} label={d.label} value={d.value} />
                            ))
                      }
                  </Select>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">{t('DEPOSIT_STATUS')}</td>
                <td>
                  <div className="d-flex my-1 rdSearchOptionsGroup">
                    {
                      status.map(d => (
                        <div key={`rdTypeGroup_${d.value}`}>
                          <Input
                            id={`rdType_${d.value}`}
                            type="radio"
                            name="rdType"
                            key={`rdType_${data?.id}_${d.value}`}
                            value={_info?.status || ''}
                            checked={_info?.status === Number(d.value)}
                            onChange={() => onInfoValueChange(Number(d.value), 'status')}
                          />
                          <Label
                            for={`rdType_${d}`}
                            className={_info?.status === Number(d.value) ? 'active mx-1 smallLabel' : 'mx-1 smallLabel'}
                          >
                            {d.label}
                          </Label>
                        </div>
                      ))
                    }
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">{t('STATUS_REMARK')}</td>
                <td>
                  <div>
                    <Input
                      className="d-inline col-1"
                      type="text"
                      value={_info?.statusInfo || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text, 'statusInfo')}
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </Row>
      </div>
      <Row className="mb-5 my-2 p-2">
        <Col className="d-flex justify-content-center py-2 border-top">
          <Button className="button handle mx-1" onClick={() => saveButton()}>{t('SAVE')}</Button>
          <Button className="button find mx-1" onClick={() => resetDefault()}>{t('CANCEL')}</Button>
        </Col>
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentOfflineSettingAddEdit);