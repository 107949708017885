
import { renderResultDateCell } from '../../utils';

const renderQiu = data => {
  if (data && !data.openResult) return;
  const result = data.openResult.split(',');
  return result.map(item => (
    {
      value: item, className: 'circle circleBlue'
    }
  ));
};

const renderZH = data => {
  if (data && !data.openResult) return;
  const result = data.openResult.split(',');
  let championSum = 0;
  const tempResult = [];
  result.forEach(item => championSum += parseInt(item, 10));

  tempResult.push({
    value: championSum
  });

  tempResult.push({
    value: championSum > 30 ? '大' : championSum < 30 ? '小' : '和',
    className: championSum > 30 ? 'redText' : '',
  });

  tempResult.push({
    value: championSum % 2 ? '单' : '双',
    className: championSum % 2 ? 'redText' : '',
  });
  return tempResult;
};

const renderLH = data => {
  if (data && !data.openResult) return;
  const result = data.openResult.split(',');
  const tempResult = [];
  if ((parseInt(result[0], 10)) > parseInt(result[result.length - 1], 10)) {
    tempResult.push({
      value: '龙',
      className: 'redText'
    });
  } else {
    tempResult.push({
      value: '虎',
    });
  }

  return tempResult;
};

const render15DX = data => {
  if (data && !data.openResult) return;
  const result = data.openResult.split(',');
  const tempResult = [];

  result.forEach(item => {
    tempResult.push({
      value: (item > 5 && item < 11) ? '大' : (item <= 5) ? '小' : '和',
      className: (item > 5 && item < 11) ? 'redText' : '',
    });
  });
  return tempResult;
};

const CJ_11X5 = {
  children: [],
  resultTab: {
    headerCell: [{
      attr: '期数',
      wrapperStyle: 'pk10Header'
    }, {
      attr: '日期',
      wrapperStyle: 'pk10Header'
    }, {
      attr: '彩球号码',
      wrapperStyle: 'pk10Header'
    }, {
      attr: '总和',
      wrapperStyle: 'pk10Header'
    }, {
      attr: '龙虎',
      wrapperStyle: 'pk10Header'
    }, {
      attr: '1-5球大小',
      wrapperStyle: 'pk10Header'
    }],
    bodyCell: [{
      attr: 'installments',
      wrapperStyle: 'd-flex justify-content-center align-items-center'
    }, {
      attr: renderResultDateCell,
      wrapperStyle: 'dateCol'
    },
    {
      attr: renderQiu,
      wrapperStyle: 'd-flex justify-content-center align-items-center pk10Num'
    }, {
      attr: renderZH,
      wrapperStyle: 'd-flex justify-content-center align-items-center pk10Num'
    }, {
      attr: renderLH,
      wrapperStyle: 'd-flex justify-content-center align-items-center pk10LH'
    }, {
      attr: render15DX,
      wrapperStyle: 'd-flex justify-content-center align-items-center pk10GYH'
    }],
  },
};

export default CJ_11X5;