import { StatusCodes } from 'http-status-codes';

import { SET_DECREASE_ODD } from '../actionTypes';
import { apiService } from '../../../library';
import appActions from '../appActions';


const setDecreaseOdds = modal => ({
  type: SET_DECREASE_ODD,
  value: modal
});

const getInfo = (obj, calllback = () => {}) => dispatch => {
  apiService.get('/DecreaseOddsSetting/GetInfo', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      calllback(false);
      dispatch(setDecreaseOdds(data.result));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    calllback(false);
    Promise.reject(error);
  });
};

const saveSetting = (obj = {}, callback = () => {}) => dispatch => {
  apiService.post('DecreaseOddsSetting/SaveSetting', obj).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
      getInfo({
        lotteryType: obj?.lotteryType
      });
      return callback(true);
    }
    if (status === StatusCodes.BAD_REQUEST) {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};

const getDefaultInfo = (obj, calllback = () => {}) => dispatch => {
  apiService.get('/DecreaseOddsSetting/GetDefaultInfo', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      calllback(false);
      dispatch(setDecreaseOdds(data.result));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    calllback(false);
    Promise.reject(error);
  });
};

const saveDefaultSetting = (obj = {}, callback = () => {}) => dispatch => {
  apiService.post('DecreaseOddsSetting/SaveDefaultSetting', obj).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
      getInfo({
        lotteryType: obj?.lotteryType
      });
      return callback(true);
    }
    if (status === StatusCodes.BAD_REQUEST) {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};

const GetExternalInfo = (calllback = () => {}) => dispatch => {
  apiService.get('/DecreaseOddsSetting/GetExternalInfo').then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      return calllback(data.result);
    }
  }).catch(error => {
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};

const SaveExternalOddsdownSetting = (obj, callback = () => {}) => dispatch => {
  apiService.post('DecreaseOddsSetting/SaveExternalOddsdownSetting', obj).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      return dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
          onConfirmationClick: () => callback()
        }
      }));
    }
  }).catch(error => {
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};

const GetMaxGoods = (obj, calllback = () => {}) => dispatch => {
  dispatch(appActions.updateModalAlert({
    visible: false
  }));
  apiService.get('/DecreaseOddsSetting/GetMaxGoods', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      return calllback(data.result);
    }
  }).catch(error => {
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    calllback(false);
    Promise.reject(error);
  });
};

const SaveMaxGoods = (obj, callback = () => {}) => dispatch => {
  apiService.post('DecreaseOddsSetting/SaveMaxGoods', obj).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      return dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
          onConfirmationClick: () => callback()
        }
      }));
    }
  }).catch(error => {
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};

export default {
  getInfo,
  saveSetting,
  getDefaultInfo,
  saveDefaultSetting,
  GetExternalInfo,
  SaveExternalOddsdownSetting,
  GetMaxGoods,
  SaveMaxGoods,
};