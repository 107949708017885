export default {
  translation: {
    COMPANY_NAME: 'CJCSJ',
    INTIME_SHEET: 'InTime Sheet',
    USER_MANAGEMENT: 'User Management',
    BET_WARNING: 'Bet Warning',
    WALLET_MANAGEMENT: 'Wallet Management',
    INDIVIDUAL_MANAGEMENT: 'Individual Management',
    TRANSACTION_MANAGEMENT: 'Transaction Management',
    ODDS_MANAGEMENT: 'Odds Management',
    REPORT_MANAGEMENT: 'Report Management',
    SYSTEM_SETTINGS: 'System Settings',
    INSTALLMENTS_MANAGEMENT: 'Installments Management',
    GAME_RESULT_MANAGEMENT: 'Game Result Management',
    ONLINE_STATISTICS: 'Online Statistics',
    THIRD_PARTY_GAME_MANAGEMENT: 'Third Party Game Management',
    LOG_OUT: 'Log Out',
    MORE: 'More',
    CURRENT_SELECTED: 'Current Selected',
    LATEST_ANNOUNCEMENT: 'Latest Anouncement',
    CHECK: 'Check',
    AUTO_RESTOCK_SETTINGS: 'Auto Restock Settings',
    RESTOCK_RECORDS: 'Restock Records',
    BET_ORDER_BACK_UP: 'Bet Order Back Up',
    REVOKE: 'Revoke',
    ENTRIES: 'Entries',
    TOTAL: 'Total',
    PAGE: 'Page',
    GO_TO: 'Go to',
    LOTTERY: 'Lottery',
    INSTALLMENTS: 'Installments',
    BET_TYPE: 'Bet Type',
    CATEGORY: 'Category',
    STATUS: 'Status',
    SEARCH: 'Search By',
    DATE: 'Date',
    DATE_RANGE: 'Date Range',
    START_END_DATE: 'Start Date',
    THIS_WEEK: 'This Week',
    PREVIOUS_WEEK: 'Previous Week',
    PREVIOUS_2_WEEK: 'Previous 2 Weeks',
    THIS_MONTH: 'This Month',
    PREVIOUS_MONTH: 'Previous Month',
    TO: 'To',
    ORDER_NUM: 'Order Number',
    BET_NUMBER: 'Bet Number',
    BET_TIME: 'Bet Time',
    ACCOUNT_NAME: 'Account',
    BET_CONTENT: 'Bet Content',
    BET: 'Bet',
    BET_RESULT: 'Bet Result',
    THIS_LVL_PERCENTAGE: 'Current Level %',
    THIS_LVL_RESULT: 'Current Level Result',
    REMAINED_AMOUNT: 'Remained Amount',
    BEFORE: 'Before',
    AFTER: 'After',
    REMARK: 'Remark',
    REBATE: 'Rebate',
    AMOUNT: 'Amount',
    DI: 'di',
    NORMAL: 'Normal',
    CANCEL: 'Cancel',
    PLATE: 'Plate',
    CURRENT_PAGE_TOTAL: 'Current Page Total',
    TOTAL_COUNT: 'Total Count',
    TIMES: 'Times',
    FIND: 'Find',
    ALL: 'ALL',
    TODAY: 'TODAY',
    DAY_OF_WEEK_1: 'Monday',
    DAY_OF_WEEK_2: 'Tuesday',
    DAY_OF_WEEK_3: 'Wednesday',
    DAY_OF_WEEK_4: 'Thursday',
    DAY_OF_WEEK_5: 'Friday',
    DAY_OF_WEEK_6: 'Saturday',
    DAY_OF_WEEK_0: 'Sunday',
    ZSDL: 'ZSDL',
    ZSHY: 'ZSHY',
    XJDL: 'XJDL',
    XJHY: 'XJHY',
    SYDL: 'SYDL',
    QBHY: 'QBHY',
    ZSZZH: 'ZSZZH',
    XJZZH: 'XJZZH',
    ZZH: 'ZZH',
    DEPOSIT: 'Deposit',
    WITHDRAWAL: 'Withdrawal',
    SAFETY_SETTING: 'Safety Setting',
    TRANSFER: 'Transfer',
    CARD_SETTING: 'Card Setting',
    TRANSCTION_HISTORY: 'Transction History',
    GAME_SETTING: 'Game Setting',
    BETTING_QUERY: 'Betting Query',
    CHECK_RETURN: 'Check Return',
    HISTORY_RESULT_MANAGEMENT: 'History Result Management',
    USER_PROFILE: 'Credit Profile',
    OPERATE_RECORD: 'Operate Record',
    LOGIN_LOG: 'Login Log',
    CHANGE_PASSWORD: 'Change Password',
    HISTORY_TASK: 'History Task',
    PROMOTE_LINK: 'Promote Link',
    MFA_SETTING: 'MFA Setting',
    TRANSCTION_QUERY: 'TRANSCTION_QUERY',
    HANDLE_AGENT_RETURN: 'HANDLE_AGENT_RETURN',
    DEPOSIT_AUDIT: 'DEPOSIT_AUDIT',
    WITHDRAWAL_AUDIT: 'WITHDRAWAL_AUDIT',
    AMOUNT_CHANGE: 'AMOUNT_CHANGE',
    MESSAGE_CENTER: 'MESSAGE_CENTER',
    ACCOUNT_DETAIL: 'ACCOUNT_DETAIL',
    VOUCHER: 'VOUCHER',
    GIFT_SETTING: 'GIFT_SETTING',
    GAME_TRANSFER_AUDIT: 'GAME_TRANSFER_AUDIT',
    OFFLINE_AUDIT: 'OFFLINE_AUDIT',
    ONLINE_RECORD: 'ONLINE_RECORD',
    DEPOSIT_RECORD: 'DEPOSIT_RECORD',
    USDT_WITHDRAWAL_AUDIT: 'USDT_WITHDRAWAL_AUDIT',
    CURRENT_ODDS: 'CURRENT_ODDS',
    FUTURE_ODDS: 'Future Odds',
    DEFAULT_ODDS: 'Default Odds',
    DECREASE_ODDS_SETTING: 'DECREASE_ODDS_SETTING',
    DEFAULT_DECREASE_ODDS_SETTING: 'Default Decrease Odds Setting',
    COMPANY_RETURN: 'COMPANY_RETURN',
    WIN_LOSE_REPORT: 'WIN_LOSE_REPORT',
    CATEGORY_REPORT: 'CATEGORY_REPORT',
    BILL_QUERY: 'BILL_QUERY',
    FINANCE_REPORT: 'FINANCE_REPORT',
    DEPOSIT_WITHDRAWAL_REPORT: 'DEPOSIT_WITHDRAWAL_REPORT',
    UNDERLING_DETAIL: 'UNDERLING_DETAIL',
    IN_OUT_STATISTICS: 'IN_OUT_STATISTICS',
    BETTING_TOP_LIST: 'BETTING_TOP_LIST',
    MEMBER_STATISTICS_REPORT: 'MEMBER_STATISTICS_REPORT',
    IN_OUT_STATISTICS_REPORT: 'IN_OUT_STATISTICS_REPORT',
    DEPOSIT_WITHDRAWAL_STATISTICS_REPORT: 'DEPOSIT_WITHDRAWAL_STATISTICS_REPORT',
    MEMBER_GAME_REPORT: 'MEMBER_GAME_REPORT',
    COMPANY_GAME_REPORT: 'COMPANY_GAME_REPORT',
    SYSTEM_SETTING: 'SYSTEM_SETTING',
    LOTTERY_MANAGEMENT: 'LOTTERY_MANAGEMENT',
    COMPANY_INFO: 'Company Info',
    SYSTEM_NOTICE: 'System Notice',
    SYSTEM_LOG: 'System Log',
    RECEIVED_ACCOUNT_SETTING: 'RECEIVED_ACCOUNT_SETTING',
    OUTSIDE_WEB_SETTING: 'OUTSIDE_WEB_SETTING',
    THIRD_PARTY_RECEIVER_SETTING: 'THIRD_PARTY_RECEIVER_SETTING',
    USER_CATEGORY: 'User Category',
    RECEIVER_OFFLINE_SETTING: 'RECEIVER_OFFLINE_SETTING',
    EMAIL_SMS_SETTING: 'Email/Sms Setting',
    GLOBAL_WITHDRAWAL_SETTING: 'Global Withdrawal Setting',
    CAPTCHA_SETTING: 'Captcha Setting',
    FEES_SETTING: 'Fees Setting',
    WITHDRAWAL_BANK: 'Withdrawal Bank',
    DETAILS: 'Details',
    CONFIRM: 'Confirm',
    REMINDER: 'Reminder',
    ZC: 'Percentage',
    NAME: 'Name',
    LEVEL: 'Level',
    ODDS: 'Odds',
    TO_REBATE: 'To Rebate',
    COMMISSION: 'Commission',
    WIN_LOST: 'Win Lost',
    RESULT: 'Result',
    HAND_IN: 'Hand In',
    VOLUME: 'Volume',
    UPPER_LEVEL: 'Upper Level',
    SETTLEMENT: 'Settlement',
    AUTO_RESTOCK_SETTING: 'Auto Restock Setting',
    RESTOCK_CHANGE_HISTORY: 'Restock Setting History',
    TYPE: 'Type',
    BET_AMOUNT: 'Bet Amount',
    AUTO_RESTOCK: 'Auto Restock',
    MANUAL_RESTOCK: 'Manual Restock',
    SAVE: 'Save',
    TIME_TO_CLOSE: 'Time To Close',
    TODAY_PROFIT: 'Today Profit',
    INSTALLMENT: 'Installment',
    INSTALLMENT_DRAW: 'Installment Draw',
    REFRESH: 'Refresh',
    OPERATION_TIME: 'Operation Time',
    OPERATION_TYPE: 'Operation Type',
    VALUE_BEFORE: 'Value Before',
    VALUE_AFTER: 'Value After',
    UPDATED_BY: 'Updated By',
    DEPENDENCY: 'Dependency',
    TARGET_ACCOUNT: 'Target Account ',
    REVERT: 'Revert',
    OPERATION: 'Operation',
    USERNAME: 'User Name',
    PASSWORD: 'Password',
    CAPTCHA: 'Captcha',
    LOGIN: 'Login',
    LOGIN_NAME: 'Login Name',
    CLIENT_PLATFORM: 'Client Platform',
    ACCOUNT_BALANCE: 'Account Balance',
    PAN_TYPE: 'Pan Type',
    ADDRESS: 'Address',
    LINE: 'Line',
    LAST_ACTIVITY_TIME: 'Last Activity Time',
    TOTAL_BALANCE: 'Total Balance',
    TOP_COMPANY: 'Top Company',
    COMPANY: 'Company',
    TOP_AGENT: 'Top Agent',
    LEVEL1_AGENT: 'Level 1 Agent',
    LEVEL2_AGENT: 'Level 2 Agent',
    LEVEL3_AGENT: 'Level 3 Agent',
    LEVEL4_AGENT: 'Level 4 Agent',
    LEVEL5_AGENT: 'Level 5 Agent',
    LEVEL6_AGENT: 'Level 6 Agent',
    LEVEL7_AGENT: 'Level 7 Agent',
    LEVEL8_AGENT: 'Level 8 Agent',
    MEMBER: 'Member',
    AGENT: 'Agent',
    ACTIVE: 'Active',
    INACTIVE: 'Inactive',
    SETTING: 'Setting',
    DEFAULT: 'Default',
    DEFAULT_VISITOR: 'Default Visitor',
    PRIVATE: 'Private',
    THIS_PAGE: 'This Page: ',
    ONLINE: 'Online',
    UPPER_LEVEL_ACCOUNT: 'Upper Level Account',
    USER_TYPE: 'User Type',
    ACCOUNT: 'Account',
    ADD: 'Add',
    CREATE_DATE: 'Create Date',
    ORDER_BY: 'Order by',
    EDIT: 'Edit',
    LOTTERY_REBATE: 'Lottery Rebate',
    GAMES_REBATE: 'Games Rebate',
    ODDS_DIFF: 'Odds Diff',
    RESULT_NOTIFICATION: 'Result Notification',
    EDIT_LOG: 'Edit Log',
    BET_LIST: 'Bet List',
    CHECK_TRANSFER_FLOW: 'Check Transfer Flow',
    BANK_CARD: 'Bank Card',
    BALANCE_MORE_THAN: 'Balance More Than',
    BALANCE_LESS_THAN: 'Balance Less Than',
    ADD_AGENT: 'Add Agent',
    ADD_SUB_AGENT: 'Add Sub Agent',
    GAME_CODE: 'Game Code',
    GAME_NAME: 'Game Name',
    GAME_TYPE: 'Game Type',
    GAME_ACCOUNT: 'Game Account',
    API_ADDRESS: 'Api Address',
    TRANSFER_LIMIT: 'Transfer Limit',
    STATUS_STATEMENT: 'Status Statement',
    UP_AGENT: 'Up Agent',
    XING_MING: 'Name',
    BEFORE_BET_BALANCE: 'BEFORE_BET_BALANCE',
    AFTER_BET_BALANCE: 'AFTER_BET_BALANCE',
    BETS: 'BETS',
    WIN_LOSE: 'WIN_LOSE',
    EFFECTIVE: 'EFFECTIVE',
    PAUSE: 'Pause',
    PUBLIC: 'Public',
    PREVIEW: 'Preview',
    COPY: 'Copy',
    NUMBER: 'Number',
    DIRECT_WX: 'Direct WeiXin',
    PLATE_TYPE: 'Plate Type',
    REGISTER_TIME: 'Register Time',
    TRANSFER_STATUS: 'Transfer Status',
    RECYLE: 'Recyle',
    GAME_BALANCE: 'Game Balance',
    WIN: 'Win',
    LOSE: 'Lose',
    PARENT_ACCOUNT: 'Parent Account',
    SUB_ACCOUNT: 'Sub Account',
    DELETE: 'Delete',
    AMENDED_LOG: 'Amended Log',
    AUDIT_SETTING: 'Audit Setting',
    HANDLED_BY_LOG: 'Handled By Log',
    RESET: 'Reset',
    DISABLED: 'Disabled',
    ORDER_INFORMATION: 'Order Information',
    RECENT_FLOW: 'Recent Flow',
    RECENT_TRANSACTION: 'Recent Transaction',
    LOTTERY_BET: 'Lottery Bet',
    GAME_BET: 'Game Bet',
    SORTING: 'Sorting',
    CURRENT_INSTALLMENTS: 'Current Installments',
    BET_OPEN_TIME: 'Bet Open Time',
    SYSTEM_BET_OPEN_TIME: 'System Bet Open Time',
    BET_CLOSE_TIME: 'Bet Close Time',
    SYSTEM_BET_CLOSE_TIME: 'System Bet Close Time',
    BET_RESULT_TIME: 'Bet Result Time',
    FUNCTION: 'Function',
    CLOSE_PLATE: 'Close Bet',
    RECOVER: 'Recover',
    PUBLISH_ANNOUCEMENT: 'Publish Annoucement',
    TIME: 'Time',
    NOTICE_TITLE: 'Notice Title',
    NOTICE_CONTENT: 'Notice Content',
    RECEIVER: 'Receiver',
    SENDER: 'Sender',
    RECEIVE_METHOD: 'Receive Method',
    ADD_MEMBER: 'Add Member',
    ADD_DIRECT_MEMBER: 'Add Direct Member',
    ACCOUNT_TYPE: 'Account Type',
    OPERATE_TYPE: 'Operate Type',
    OPERATE_CONTENT: 'Operate Content',
    LOG_OLD_VALUE: 'Log Old Value',
    LOG_NEW_VALUE: 'Log New Value',
    ADD_PAYMENT_ACCOUNT: 'Add Payment Account',
    PLATFORM: 'Plateform',
    ACCOUNT_NUMBER: 'Account Number',
    DAILY_LIMIT: 'Daily Limit',
    ACCOUNT_OPEN_ADDRESS: 'Account Open Address',
    ACCOUNT_OPEN_NAME: 'Account Open Name',
    BELONGS_BANK: 'Bank Belongs',
    MAX_SINGLE_TRANSACTION: 'Max Single Transaction',
    MIN_SINGLE_TRANSACTION: 'Min Single Transaction',
    ACCOUNT_CATEGORISE: 'Account Categorise',
    BANK_CARD_NUMBER: 'Bank Card Number',
    BANK_CARD_NAME: 'Bank Card Name',
    EXTERNAL_WEB_NAME: 'External Web Name',
    WEB_NAME: 'Web Name',
    TOGGLE_STATUS: 'Toggle Status',
    WEB_ADDRESS: 'Web Address',
    ADD_EXTERNAL_WEB: 'Add External Web',
    SELECT_ALL: 'Select All',
    MANUAL_AUDIT: 'Manual Audit',
    MANUAL_CONFIRM: 'Manual Confirm',
    TEST: 'Test',
    PAYOUT: 'Payout',
    RELOAD: 'Reload',
    APPLY_STATUS: 'Apply Status',
    PLATFORM_BELONGS: 'Platform Belongs',
    QUOTA_SPEND_TODAY: 'Quota Spend Today',
    DAILY_QUOTA: 'Daily Quota',
    MIN_SINGLE_PAYMENT: 'Min Single Payment',
    MAX_SINGLE_PAYMENT: 'Max Single Payment',
    PAYMENT_CATEGORY: 'payment Category',
    PAYMENT_TYPE: 'Payment Type',
    NAME_REMARK: 'Name Remark',
    PAYMENT_NAME: 'Payment Name',
    RECOGNISE_CODE: 'Recognise Code',
    MOVE_UP: 'Move up',
    MOVE_DOWN: 'Move Down',
    USER_CLASS: 'User Class',
    MIN_ACCUMULATE_VALUE: 'Min Accumulate Value',
    MAX_ACCUMULATE_VALUE: 'Max Accumulate Value',
    MAX_SINGLE_RELOAD_VALUE: 'Max Single Reload Value',
    COMPILE: 'Compile',
    MIN_SINGLE_DEPOSIT_VALUE: 'Min Single Deposit Value',
    MAX_SINGLE_DEPOSIT_VALUE: 'Max Single Deposit Value',
    CLIENT_PLATFORM_BELONGS: 'Client Platform Belongs',
    DEPOSIT_STATUS: 'Deposit Status',
    STATUS_REMARK: 'Deposit Remark',
    ADD_SETTING: 'Add Setting',
    ALLOW: 'Allow',
    BAN: 'Ban',
    ADD_SMS_PORT: 'Add SMS Port',
    ADD_EMAIL_PORT: 'Add Email Port',
    SMS_PLATFORM: 'SMS Platform',
    SEND_INTERVAL_IN_SEC: 'Send Interval (Sec)',
    MAILBOX_ALT_NAME: 'Mailbox Alternate Name',
    PORT: 'Port',
    MIN_SINGLE_WITHDRAW_VALUE: 'Min Single Withdraw Value',
    MAX_SINGLE_WITHDRAW_VALUE: 'Max Single Withdraw Value',
    DAILY_WITHDRAW_LIMIT: 'Max Daily Withdraw Value',
    WITHDRAW_FEES_RATE: 'Rate of Withdarw Fees',
    FOC_WITHDRAW_TIME: 'FOC Withdraw Time',
    CHARGE_RATE_FOR_EXCEED_WITHDRAW: 'Charge rate for Exceed Withdraw',
    DAILY_WITHDRAW_TIME: 'Daily Withdraw Time',
    WITHDRAW_TURNOVER_RATE: 'Withdraw Turnover Rate',
    WITHDRAW_STATUS: 'Withdraw Status',
    PING1: 'Ping1',
    PING2: 'Ping2',
    PING3: 'Ping3',
    PING4: 'Ping4',
    PING5: 'Ping5',
    PING6: 'Ping6',
    PING7: 'Ping7',
    TEMA: 'Tema',
    OPERATE_TIME: 'Operate Time',
    OTP_NUMBER: 'OTP Number',
    OTP_TYPE: 'OTP Type',
    RECEIVER_ADDRESS: 'Receiver Address',
    OPERATE_USER: 'Operate User',
    STATEMENT: 'Statement',
    EMAIL: 'Email',
    PHONE: 'Phone',
    OPERATE_SEND_USER: 'Sender',
    OPERATE_SEND_ADDRESS: 'Receiver',
    RENEW_TIME: 'Renew Time',
    EXCHANGE_RATE_REMARK: 'Exchange Rate Remark',
    WITHDRAW_FLOAT_RATE: 'Withdraw Float Rate',
    DEPOSIT_FLOAT_RATE: 'Deposit Float Rate',
    AUTO_RENEW_INFO: 'Auto Renew Info',
    AUTO_RENEW_EX_RATE: 'Auto Renew Exchange Rate',
    DEPOSIT_RATE: 'Deposit Rate',
    WITHDRAW_RATE: 'Withdraw Rate',
    CURRENCY_NAME: 'Currency Name',
    CURRENCY_CODE: 'Currency Code',
    YES: 'Yes',
    NO: 'No',
    TYPE_NAME: 'Type Name',
    BANK_CARD_PREFIX: 'Bank Card Prefix',
    BANK_CODE: 'Bank Code',
    ACTIVE_COMPANY: 'Active Company',
    DESELECT_ALL: 'Deselect All',
    SS_OPEN_RESULT_SETTING: 'Open Result Setting',
    OFFICIAL_NAME: 'Official Name',
    OFFICIAL_ADDRESS: 'Official Address',
    REFERENCE_NAME: 'Reference Name',
    REFERENCE_ADDRESS: 'Reference Address',
    LOTTERY_RESULT_CANCEL: 'Lottery Cancelled',
    PLEASE_SELECT_DATE: 'Please select date',
    ACCUMULATED_VALUE: 'Accumulated Value',
    DECREASE_ODDS_VALUE: 'Decrease Odds Value',
    LOWEST_ODDS: 'Lowest Odds',
    HIGHEST_QUANTITY: 'Highest Quantity',
    AUTO_ON_OFF: 'Auto On Off',
    MORATORIUM: 'Moratorium',
    TOTAL_VALUE: 'Total Value',
    SEND: 'Send',
    COMPANY_MANAGEMENT: 'Company Management',
    COMPANY_SETTING: 'Company Setting',
    LOTTERY_SETTING: 'Lottery Setting',
    SYSTEM_PERMISSION_SETTING: 'System Permission Setting',
    COMPANY_HOSTNAME_SETTING: 'Company Hostname Setting',
    THIRD_TOPUP_SETTING: 'Third Party Topup Setting',
    GAME_MANAGEMENT: 'Game Management',
    SUPER_USER_LIST: 'Super User List',
    OPEN_RESULT_ADDRESS_MANAGEMENT: 'Open Result Address Management',
    WHITE_LIST_MANAGEMENT: 'Whitelist Management',
    AGENT_BACKUP_WHITE_LIST: 'Agent Backup Whitelist',
    SYSTEM_SERVICE_MANAGEMENT: 'System Service Management',
    ALL_MORATORIUM: 'All Stop',
    ALL_OPEN: 'All Open',
    TIME_TO_OPEN_RESULT: 'Time to Open Result',
    REGISTER: 'Register',
    TOPUP_TYPE: 'Topup Type',
    TOPUP_METHOD: 'TopUp Method',
    SELECT_WALLET: 'Select Wallet',
    REAL_NAME: 'Real Name',
    DEPOASIT_AMOUNT: 'Deposit Amount',
    CONFIRM_TOPUP: 'Confirm Topup',
    PROFIT: 'Profit',
    RESTOCK: 'Restock',
    AGENT_LOGIN: 'Credit Agent Login',
    NO_ACCOUNT: 'No Account',
    PLEASE_REGISTER: 'Please Register',
    AGENT_REGISTER: 'Credit Agent Register',
    HAVE_ACCOUNT: 'Have Account',
    PLEASE_LOGIN: 'Please Login',
    BACK_PAGE: 'Back Page',
    AFF_CODE: 'Aff Code',
    CONFIRM_PASSWORD: 'Confirm Password',
    INCREASEMENT: 'Increasement',
    DEFAULT_COMPANY_RETURN: 'Default Company Return',
    DEFAULT_AUTO_RESTOCK_SETTINGS: 'Default Auto Restock',
    ORDER_ID_GENERATED: 'Order Generated',
    ORDER_ID: 'Order Id',
    WALLET_NAME: 'Wallet Name',
    WALLET_ADDRESS: 'Wallet Address',
    TRANSACTION_QUANTITY: 'Transaction Quantity',
    TOP_UP_REMINDER: 'When top up is successful, account will be reflected with the latest amount. If you have question, feel free to contact customer service.',
    COMPLETE_TOP_UP_ORDER: 'Complete Top Up Order',
    BANK_CARD_BANK: 'Issuing Bank',
    BANK_CARD_ADDRESS: 'Issuing Bank Address',
    TOP_UP_BANK_NUM: 'Bank Number',
    SCAN_ENDPOINT: 'Scan Endpoint',
    ACCOUNT_KEYCODE: 'Account Keycode',
    ATTENTION: 'Attention',
    TOP_UP_ATTENTION_TEXT: 'Top up remarks must include top id, we will not be responsible if the top id did not get reflected from the backend',
    TOP_UP_ISSUEING_BANK: 'Top Up Issueing Bank',
    TOP_UP_NAME: 'Top Up Name',
    CANCEL_TOP_UP_ORDER: 'Cancel Order',
    ARE_YOU_SURE_YOUR_TOP_UP_HAS_SUCCEED: 'Are you sure your top up has succeed?',
    BOGUS_BET_WARNING_MONITOR: 'BOGUS_BET_WARNING_MONITOR',
    SET_SUGGESTION_AMOUNT: 'SET_SUGGESTION_AMOUNT',
    BIG_BET_LIMIT: 'BIG_BET_LIMIT',
    QUICK_BET_MONITOR: 'QUICK_BET_MONITOR',
    BIG_BET_BUHUO_SETTING: 'BIG_BET_BUHUO_SETTING',
    BOGUS_BET_WARNING: 'Bogus Bet Warn',
    ABNORMAL_BET_QUERY: 'Abnormal Bet',
    TOTAL_ONLINE_STATISTICS: 'Total Online Statistics',
    MANUAL: 'Manual',
    SECOND: 'Second',
    MINUTE: 'Minute',
    HOUR: 'hour',
    PLEASE_SELECT: 'Please Select',
    SHOW_RECENT: 'Show Recent',
    PROJECT: 'Project',
    THE_FIRST_AMOUNT_OF_WARNING_VIRTUAL_GOODS: 'The first amount of warning(Virtual Goods)',
    THE_FIRST_AMOUNT_OF_WARNING_ACCOUNTED_FOR: 'The first amount of warning(Accounted For)',
    THE_SECOND_AMOUNT_OF_WARNING_VIRTUAL_GOODS: 'The second amount of warning(Virtual Goods)',
    THE_SECOND_AMOUNT_OF_WARNING_ACCOUNTED_FOR: 'The second amount of warning(Accounted For)',
    SELECT_CATEGORY_AMOUNT_ALERT: 'Select Category Amount Alert',
    BET_TASK_BACKUP: 'Bet Task Backup',
    TASK_NAME: 'Task Name',
    LOTTERY_NAME: 'Lottery Name',
    TASK_INSTALLMENT: 'Task Installment',
    JIESUAN_TYPE: 'jiesuan Type',
    CREATE_TIME: 'Create Time',
    TASK_STATUS: 'Task Status',
    BLOCK_NUMBER_SETTING: 'Block Number Setting',
    BLOCK_NUMBER: 'Block Number',
    SELECT: 'Select',
    UNUSUAL_BET: 'Unusual Bet',
    UNUSUAL: 'Unusual',
    PLEASE_SELECT_INSTALLMENTS: 'Please Select Installments',
    CLASS: 'Class',
    TO_UNUSUAL: 'Set Unusual',
    DELETE_UNUSUAL: 'Delete Unusual',
    TOP_COMPANY_RESTOCK: 'Top Company Restock',
    COMPANY_RESTOCK: 'Company Restock',
    TOP_AGENT_RESTOCK: 'Top Agent Restock',
    LEVEL1_RESTOCK: 'Level 1 Restock',
    LEVEL2_RESTOCK: 'Level 2 Restock',
    LEVEL3_RESTOCK: 'Level 3 Restock',
    LEVEL4_RESTOCK: 'Level 4 Restock',
    LEVEL5_RESTOCK: 'Level 5 Restock',
    LEVEL6_RESTOCK: 'Level 6 Restock',
    LEVEL7_RESTOCK: 'Level 7 Restock',
    LEVEL8_RESTOCK: 'Level 8 Restock',
    LEVEL9_RESTOCK: 'Level 9 Restock',
    LEVEL10_RESTOCK: 'Level 10 Restock',
    LOGGED_OUT: 'Logged Out',
    SETTLED: 'Settled',
    UN_SETTLED: 'UnSettled',
    MEMBER_IP: 'Member IP',
    BET_NO: 'Bet No.',
    MEMBER_ACCOUNT: 'Member Account',
    MEMBER_BET: 'Member Bet',
    PAGE_TOTAL: 'Page Total',
    CONTENT: 'Content',
    NOTE_RECORDS_ARE_RETAINED_FOR_A_MAXIMUM_OF_1_DAY: 'Note: Records are retained for a maximum of 1 day.',
    VIRTUAL_GOODS: 'Virtual Goods',
    ACCOUNTED_FOR: 'Accounted For',
    THE_FIRST_AMOUNT_OF_WARNING: 'The first amount of warning',
    INCREASED_AMOUNT_OF_WARNING: 'Increased amount of warning',
    FAST_SETTING: 'Fast Setting',
    EXTERNAL_DECREASE_SETTING: 'External Decrease Setting',
    BET_LIMIT_TIME: 'Bet Limit Time',
    BIG_BET_LIMIT_TIME: 'Big Bet Limit Time',
    SINGLE_BET_LIMIT: 'Single Bet Limit',
    SINGLE_BET_ACCOUNTED_FOR_LIMIT: 'Single Bet Accounted For Limit',
    NOT_SET: 'Not Set',
    RESTOCK_LIMIT: 'Restock Limit',
    BIG_RESTOCK_TIME: 'Big Restock Time',
    COMPLETED: 'Completed',
    PROCESSING: 'Processing',
    PAUSING: 'Pausing',
    DOWNLOAD: 'Download',
    PAUSE_ALL: 'Pause All',
    START_ALL: 'Start All',
    CREATE_USER: 'Create User',
    START: 'Start',
    EXPORT: 'Export',
    FIX: 'Fix',
    GENERATE: 'Generate',
    MODE: 'Mode',
    QUOTA_TYPE: 'Quota Type',
    REPAIRABLE: 'Repairable',
    USER_ACCOUNT: 'User Account',
    INITIAL_AMOUNT: 'Initial Amount',
    END_AMOUNT: 'End Amount',
    ADDITION_OR_SUBTRACTION: 'Addition or Subtraction',
    INSTALLMENT_DATE: 'Installment Date',
    OUTSTANDING_AMOUNT: 'Outstanding Amount',
    DIFF_TO_BE_RETURNED: 'Diff to be returned',
    OPERATE_AMOUNT: 'Operate Amount',
    OPERATE_REMARK: 'Operate Remark',
    CREDIT: 'Credit',
    CASH: 'Cash',
    MEMBER_DAY_BILL: 'Member Day Bill',
    MEMBER_CREDIT_CHANGE: 'Member Credit Change',
    ABNORMAL_BET_EXPORT: 'Abnormal Bet Export',
    CREDIT_MANAGEMENT: 'Credit Management',
    LOTTERY_STYLE_MANAGEMENT: 'Lottery Style',
    DOMAIN_MANAGEMENT: 'Domain',
    NEW_BALANCE: 'New Balance',
    CHANGE_AMOUNT: 'Change Amount',
    THEME: 'Theme',
    BLUE: 'Blue',
    GOLDEN: 'Golden',
    DARK_YELLOW: 'Dark Yellow',
    SUOYOU: 'All',
    LOTTERY_SELECT: 'Lottery Select',
    AGENT_SELECT: 'Agent Select',
    BET_TO_UNUSUAL: 'Bet To Unusual',
    TEMA_BET_CLOSE_TIME: 'Tema Bet Close Time',
    NON_TEMA_BET_CLOSE_TIME: 'Non Tema Bet Close Time',
    LOADING: 'Loading',
    DESCRIPTION: 'Description',
    DEFAULT_WIN_LOST: 'Default win lost',
    CHANGE_PASSWORD_OLD_PASSWORD: 'Password',
    CHANGE_PASSWORD_NEW_PASSWORD: 'New Password',
    CHANGE_PASSWORD_CONFIRM_PASSWORD: 'Confirm Password',
    CHANGE_PASSWORD_PASSWORD_PLACEHOLDER: 'Please enter current password',
    CHANGE_PASSWORD_NEW_PASSWORD_PLACEHOLDER: '6-20 alphanumeric case-sensitive characters',
    CHANGE_PASSWORD_CONFIRM_PASSWORD_PLACEHOLDER: 'Please enter confirm password, ensuring no errors',
    CHANGE_PASSWORD_PASSWORD_EMPTY_ERROR: 'Please enter password',
    CHANGE_PASSWORD_NEW_PASSWORD_EMPTY_ERROR: 'Please enter new password',
    CHANGE_PASSWORD_NEW_PASSWORD_UNFIT_ERROR: '6-20 alphanumeric case-sensitive characters',
    CHANGE_PASSWORD_CONFIRM_PASSWORD_EMPTY_ERROR: 'Please enter confirm password',
    CHANGE_PASSWORD_NEW_CONFIRM_PASSWORD_MISMATCHED_ERROR: 'New and Confirm passwords do not match',
    SAFETY_CENTER_SAFEPASS: 'Old Passord',
    SAFETY_CENTER_SAFEPASS_PLACEHOLDER: 'Please enter old password',
    SAFETY_CENTER_OLD_SAFEPASS_EMPTY_ERROR: 'Please enter old password',
    SAFETY_CENTER_NEW_SAFEPASS_EMPTY_ERROR: 'Please enter new password',
    SAFETY_CENTER_CONFIRM_SAFEPASS_EMPTY_ERROR: 'Please enter confirm password',
    SAFETY_CENTER_SAFEPASS_MISMATCH_ERROR: 'Passwords do not match',
    SAFETY_CENTER_SAFEPASS_COMBINATION_ERROR: 'Password must be 6 to 20 alphanumeric',
    SAFETY_CENTER_FORGOT_PASSWORD: 'Forgot Password',
    SAFETY_CENTER_NEW_SAFEPASS: 'New Password',
    SAFETY_CENTER_NEW_SAFEPASS_PLACEHOLDER: 'Please enter 8-12 alpha-numeric characters',
    SAFETY_CENTER_CONFIRM_NEW_SAFEPASS: 'Confirm Password',
    SAFETY_CENTER_CONFIRM_NEW_SAFEPASS_PLACEHOLDER: 'Please confirm password',
    RND_NUM: 'Rnd Num',
    EMAIL_LOGIN: 'Login',
    FOLLOW_BET_SETTING: 'Follow Bet',
    FOLLOW_BET_LOGS: 'Follow Bet Logs',
  }
};