import { connect } from 'react-redux';

import { Spin } from 'antd';
import { appActions } from '../../../redux/actions';
import './styles.scss';


const mapStateToProps = state => ({
  platform: state.appReducers.platform,
});

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
});

const LoadingModal = () => (
  <div className="loadingModalWrapper">
    <Spin size="large" tip="加载中..." />
  </div>
);

export default connect(mapStateToProps, mapDispatchToProps)(LoadingModal);