import { bigsmalltail, dxds, hdxds, totalScoreDs, totalScoreDx } from '../Items/dsdx';


import { sixXiao, zodiac } from '../Items/others';
import { threeColors } from '../Items/colors';

import { number1to49 } from '../Items/numbers';

const F36X7LX = (data = '') => {
  let result = [];
  if (data) {
    result = data.split(',').map(item => {
      const _obj = number1to49.find(x => x.displayName === item);
      return {
        ..._obj
      };
    });
  }
  return result;
};

const F36X7ShengXiao = (data = '') => {
  let result = [];
  if (data) {
    result = data.split(',').map(item => {
      const _obj = zodiac.find(x => x.displayName === item);
      return {
        ..._obj
      };
    });
  }
  return result;
};

const CJ_F36X7 = [{
  displayName: '特码',
  displayRows: 13,
  midcode: 'TM',
  // isStaticRow: 1,
  display: [...number1to49.slice(0, 36),
    // [...number1to49.slice(13, 26)],
    // [...number1to49.slice(26, 36)]
  ],
  tabId: 'count_TM'
}, {
  displayName: '两面',
  isStaticRow: 1,
  repeatPerRow: 4,
  settingCode: 'TMLM,ZMLM',
  tabId: 'count_LM',
  display: [
    [...dxds, ...hdxds, ...bigsmalltail, ...threeColors],
    [...dxds, ...hdxds, ...bigsmalltail, ...threeColors],
    [...dxds, ...hdxds, ...bigsmalltail, ...threeColors],
    [...dxds, ...hdxds, ...bigsmalltail, ...threeColors],
    [...dxds, ...hdxds, ...bigsmalltail, ...threeColors],
    [...dxds, ...hdxds, ...bigsmalltail, ...threeColors],
    [...dxds, ...hdxds, ...bigsmalltail, ...threeColors],
    [...dxds, ...hdxds, ...bigsmalltail, ...threeColors]
  ],
  prefix: ['TM', 'ZM1', 'ZM2', 'ZM3', 'ZM4', 'ZM5', 'ZM6', 'ZM7'],
  firstTableHeader: ['特码', '正码一', '正码二', '正码三', '正码四', '正码五', '正码六', '正码七'],
},
{
  displayName: '正码',
  midcode: 'ZM,ZFLM',
  displayRows: 10,
  display: [...number1to49.slice(0, 36), ...totalScoreDs, ...totalScoreDx],
  tabId: 'count_ZM',
},
{
  displayName: '正特码',
  // isStaticRow: 1,
  displayRows: 13,
  display: [...number1to49.slice(0, 36),
    // [...number1to49.slice(9, 18)],
    // [...number1to49.slice(18, 27)],
    // [...number1to49.slice(27, 36)]
  ],
  tabId: 'count_ZTM_ALL',
  children: [
    {
      tabId: 'count_ZTM1',
      label: '正1特',
      midcode: 'Z1T',
    }, {
      tabId: 'count_ZTM2',
      label: '正2特',
      midcode: 'Z2T',
    }, {
      tabId: 'count_ZTM3',
      label: '正3特',
      midcode: 'Z3T',
    }, {
      tabId: 'count_ZTM4',
      label: '正4特',
      midcode: 'Z4T',
    }, {
      tabId: 'count_ZTM5',
      label: '正5特',
      midcode: 'Z5T',
    }, {
      tabId: 'count_ZTM6',
      label: '正6特',
      midcode: 'Z6T',
    }, {
      tabId: 'count_ZTM7',
      label: '正7特',
      midcode: 'Z7T',
    }
  ]
},
{
  displayName: '六肖',
  midcode: 'LX',
  display: [...sixXiao],
  displayRows: 6,
  isLianMa: 1,
  lianMaMode: {
    rowProperty: [F36X7ShengXiao]
  },
  tabId: 'count_LX',
},
{
  displayName: '连码',
  displayRows: 9,
  display: [...number1to49.slice(0, 36)],
  isLianMa: 1,
  lianMaMode: {
    rowProperty: [F36X7LX]
  },
  tabId: 'count_LM_ALL',
  children: [
    {
      tabId: 'count_2QZ',
      label: '二全中',
      settingCode: '2QZ',
    }, {
      tabId: 'count_1ZB',
      label: '特串',
      settingCode: '1ZB',
    }, {
      tabId: 'count_3QZ',
      label: '三全中',
      settingCode: '3QZ',
    }, {
      tabId: 'count_2ZB',
      label: '二字半',
      settingCode: '2ZB',
    }, {
      tabId: 'count_4QZ',
      label: '四全中',
      settingCode: '4QZ',
    }, {
      tabId: 'count_3ZB',
      label: '三字半',
      settingCode: '3ZB',
    }, {
      tabId: 'count_3ZT',
      label: '三中特',
      settingCode: '3ZT',
    }, {
      tabId: 'count_4ZT',
      label: '四拖三',
      settingCode: '4ZT',
    }, {
      tabId: 'count_3Z2',
      label: '三中二',
      settingCode: '3Z2',
    }, {
      tabId: 'count_7PZ',
      label: '七中四',
      settingCode: '7PZ',
    }, {
      tabId: 'count_7TZ',
      label: '七拖四',
      settingCode: '7TZ',
    }
  ]
},
{
  displayName: '不中',
  displayRows: 10,
  display: [...number1to49.slice(0, 36)],
  isLianMa: 1,
  lianMaMode: {
    rowProperty: [F36X7LX]
  },
  tabId: 'count_BZ_ALL',
  children: [
    {
      tabId: 'count_BZ4',
      label: '四不中',
      midcode: 'BZ4BZ',
    }, {
      tabId: 'count_BZ5',
      label: '五不中',
      midcode: 'BZ5BZ',
    }, {
      tabId: 'count_BZ6',
      label: '六不中',
      midcode: 'BZ6BZ',
    }, {
      tabId: 'count_BZ7',
      label: '七不中',
      midcode: 'BZ7BZ',
    }, {
      tabId: 'count_BZ8',
      label: '八不中',
      midcode: 'BZ8BZ',
    }, {
      tabId: 'count_BZ9',
      label: '九不中',
      midcode: 'BZ9BZ',
    }, {
      tabId: 'count_BZ10',
      label: '十不中',
      midcode: 'BZ10BZ',
    }, {
      tabId: 'count_BZ11',
      label: '十一不中',
      midcode: 'BZ11BZ',
    }, {
      tabId: 'count_BZ12',
      label: '十二不中',
      midcode: 'BZ12BZ',
    }, {
      tabId: 'count_BZ13',
      label: '十三不中',
      midcode: 'BZ13BZ',
    }, {
      tabId: 'count_BZ14',
      label: '十四不中',
      midcode: 'BZ14BZ',
    }
  ]
}
];

export default CJ_F36X7;