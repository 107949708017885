import { SET_BANK_CARDS_LIST, SET_CHANGE_LIST, SET_EDIT_BANK_SETTING, SET_EDIT_EMAIL_SETTING, SET_EDIT_EXCHANGE_RATE, SET_EDIT_LOTTERY_ITEM, SET_EDIT_LOTTERY_TYPE_INFO, SET_EDIT_OFFLINE_SETTING, SET_EDIT_PAY_ACC, SET_EDIT_ROLE_INFO, SET_EDIT_SMS_SETTING, SET_EDIT_SYS_SERVICE_INFO, SET_EDIT_THIRD_PAY_SETTING, SET_EDIT_WITHDRAW_SETTING, SET_EMAIL_SETTING, SET_EXCHANGE_RATE_LIST, SET_EXTERNAL_WEB_INFO, SET_LOTTERY_ADDRESS_LIST, SET_LOTTERY_EDIT_ADDRESS, SET_LOTTERY_ITEMS, SET_LOTTERY_TYPE_INFO, SET_MEMBER_AMOUNT_HISTORY, SET_MEMBER_AUTO_CLASSIFY, SET_MEMBER_DAY_REPORT, SET_MENU_INFO, SET_OPEN_RESULT_SETTING_LIST, SET_OTP_LIST, SET_PAYMENT_ACCOUNT_SETTING, SET_PAYMENT_OFFLINE_SETTING, SET_ROLE_INFO, SET_SMS_SETTING, SET_SYSTEM_SETTING_INFO, SET_SYS_CONFIG_INFO, SET_SYS_SERVICE_INFO, SET_THIRD_PAY_SETTING, SET_WITHDRAW_SETTING } from '../../actions/actionTypes';

const initialState = {
  systemSettingInfoData: undefined,
  lotteryItemsData: undefined,
  editLotteryItemData: undefined,
  bankCards: undefined,
  paymentAccSettingData: undefined,
  externalWebInfoData: undefined,
  thirdPaySettingData: undefined,
  editThirdPaySettingData: undefined,
  memberAutoClassifyData: undefined,
  paymentOfflineSettingData: undefined,
  editOfflineSettingData: undefined,
  smsSettingData: undefined,
  emailSettingData: undefined,
  editSMSSettingData: undefined,
  editEmailSettingData: undefined,
  withdrawSettingData: undefined,
  editWithdrawSettingData: undefined,
  otpList: undefined,
  exchangeRateListData: undefined,
  editExchangeRateData: undefined,
  editBankSettingData: undefined,
  openResultListData: undefined,
  lotteryAddressList: undefined,
  lotteryEditAddress: undefined,
  editSysServiceInfo: undefined,
  sysServiceInfo: undefined,
  sysConfigInfo: undefined,
  roleInfo: undefined,
  menuInfo: undefined,
  editRoleInfo: undefined,
  lotteryTypeInfo: undefined,
  editLotteryTypeInfo: undefined,
  memberDayReportData: undefined,
  changeList: undefined,
  memberAmountHistoryData: undefined
};

const systemReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_MEMBER_AMOUNT_HISTORY:
      return {
        ...state, memberAmountHistoryData: action.value
      };
    case SET_CHANGE_LIST:
      return {
        ...state, changeList: action.value
      };
    case SET_MEMBER_DAY_REPORT:
      return {
        ...state, memberDayReportData: action.value
      };
    case SET_SYSTEM_SETTING_INFO:
      return {
        ...state, systemSettingInfoData: action.value
      };
    case SET_LOTTERY_ITEMS:
      return {
        ...state, lotteryItemsData: action.value
      };
    case SET_EDIT_LOTTERY_ITEM:
      return {
        ...state, editLotteryItemData: action.value
      };
    case SET_BANK_CARDS_LIST:
      return {
        ...state, bankCards: action.value
      };
    case SET_PAYMENT_ACCOUNT_SETTING:
      return {
        ...state, paymentAccSettingData: action.value
      };
    case SET_EDIT_PAY_ACC:
      return {
        ...state, editPayAccData: action.value
      };
    case SET_EXTERNAL_WEB_INFO:
      return {
        ...state, externalWebInfoData: action.value
      };
    case SET_THIRD_PAY_SETTING:
      return {
        ...state, thirdPaySettingData: action.value
      };
    case SET_EDIT_THIRD_PAY_SETTING:
      return {
        ...state, editThirdPaySettingData: action.value
      };
    case SET_MEMBER_AUTO_CLASSIFY:
      return {
        ...state, memberAutoClassifyData: action.value
      };
    case SET_PAYMENT_OFFLINE_SETTING:
      return {
        ...state, paymentOfflineSettingData: action.value
      };
    case SET_EDIT_OFFLINE_SETTING:
      return {
        ...state, editOfflineSettingData: action.value
      };
    case SET_SMS_SETTING:
      return {
        ...state, smsSettingData: action.value
      };
    case SET_EMAIL_SETTING:
      return {
        ...state, emailSettingData: action.value
      };
    case SET_EDIT_SMS_SETTING:
      return {
        ...state, editSMSSettingData: action.value
      };
    case SET_EDIT_EMAIL_SETTING:
      return {
        ...state, editEmailSettingData: action.value
      };
    case SET_WITHDRAW_SETTING:
      return {
        ...state, withdrawSettingData: action.value
      };
    case SET_EDIT_WITHDRAW_SETTING:
      return {
        ...state, editWithdrawSettingData: action.value
      };
    case SET_OTP_LIST:
      return {
        ...state, otpList: action.value
      };
    case SET_EXCHANGE_RATE_LIST:
      return {
        ...state, exchangeRateListData: action.value
      };
    case SET_EDIT_EXCHANGE_RATE:
      return {
        ...state, editExchangeRateData: action.value
      };
    case SET_EDIT_BANK_SETTING:
      return {
        ...state, editBankSettingData: action.value
      };
    case SET_OPEN_RESULT_SETTING_LIST:
      return {
        ...state, openResultListData: action.value
      };
    case SET_LOTTERY_ADDRESS_LIST:
      return {
        ...state, lotteryAddressList: action.value
      };
    case SET_LOTTERY_EDIT_ADDRESS:
      return {
        ...state, lotteryEditAddress: action.value
      };

    case SET_EDIT_SYS_SERVICE_INFO:
      return {
        ...state, editSysServiceInfo: action.value
      };
    case SET_SYS_SERVICE_INFO:
      return {
        ...state, sysServiceInfo: action.value
      };
    case SET_SYS_CONFIG_INFO:
      return {
        ...state, sysConfigInfo: action.value
      };
    case SET_ROLE_INFO:
      return {
        ...state, roleInfo: action.value
      };
    case SET_MENU_INFO:
      return {
        ...state, menuInfo: action.value
      };
    case SET_EDIT_ROLE_INFO:
      return {
        ...state, editRoleInfo: action.value
      };
    case SET_LOTTERY_TYPE_INFO:
      return {
        ...state, lotteryTypeInfo: action.value
      };
    case SET_EDIT_LOTTERY_TYPE_INFO:
      return {
        ...state, editLotteryTypeInfo: action.value
      };
    default:
      return state;
  }
};

export default systemReducers;