import { dxds, hdxds, specialBig, specialdSmall, tdxds, thdxds, totalSumDS, totalSumDX, totalZodiacDS, totalds, totaldx, twdx, wdx } from '../Items/dsdx';

import { blueSet, greenSet, redSet, threeColors } from '../Items/colors';
import { drawMatch, element, head, nineTails, tFrontBack, tHouseWild, tSkyEarth, zodiac, zodiac2to7 } from '../Items/others';

import { qsAll, qsColors, qsDxds, qsFiller, qsHead, qsTail, qsZodiac } from '../Items/quickset';

import { number1to49 } from '../Items/numbers';

const HK6 = [{
  displayName: '特码',
  displayRows: 16,
  display: [...number1to49, ...tdxds, ...thdxds, ...twdx, ...threeColors],
  quickSet: {
    style: 1,
    variables: [[...qsZodiac, ...qsFiller, ...qsFiller, ...qsFiller], [...qsColors], [...qsHead, ...qsTail], [...qsAll, ...qsDxds]]
  },
  children: [
    {
      tabId: 'TMA',
      label: '特码A',
      prefix: '',
      midcode: 'TMA,TMDSDX,TMSB',
      midtype: '特A'
    }, {
      tabId: 'TMB',
      label: '特码B',
      midcode: 'TMA,TMDSDX,TMSB',
      midtype: '特B'
    }
  ],
}, {
  displayName: '正码',
  midcode: 'ZMA,ZMLM',
  displayRows: 14,
  display: [...number1to49, ...totalds, ...totaldx],
  quickSet: {
    style: 1,
    variables: [[...qsZodiac, ...qsFiller, ...qsFiller, ...qsFiller], [...qsColors], [...qsHead, ...qsTail], [...qsAll, ...qsDxds]]
  },
},
{
  displayName: '色波',
  midcode: 'TMSB,TBBDS,TBBDX,TMBBB,7SB',
  isStaticRow: 1,
  displayRows: 9,
  display: [[...redSet], [...blueSet], [...greenSet], [...threeColors, ...drawMatch]],
},
{
  displayName: '正码特',
  displayRows: 16,
  display: [...number1to49, ...dxds, ...hdxds, ...wdx, ...threeColors],
  quickSet: {
    style: 1,
    variables: [[...qsZodiac, ...qsFiller, ...qsFiller, ...qsFiller], [...qsColors], [...qsHead, ...qsTail], [...qsAll, ...qsDxds]]
  },
  children: [
    {
      tabId: 'ZMT1',
      label: '正1特',
      midcode: 'ZMT1,ZM1LM,ZM1SB',
    }, {
      tabId: 'ZMT2',
      label: '正2特',
      midcode: 'ZMT2,ZM2LM,ZM2SB',
    }, {
      tabId: 'ZMT3',
      label: '正3特',
      midcode: 'ZMT3,ZM3LM,ZM3SB',
    }, {
      tabId: 'ZMT4',
      label: '正4特',
      midcode: 'ZMT4,ZM4LM,ZM4SB',
    }, {
      tabId: 'ZMT5',
      label: '正5特',
      midcode: 'ZMT5,ZM5LM,ZM5SB',
    }, {
      tabId: 'ZMT6',
      label: '正6特',
      midcode: 'ZMT6,ZM6LM,ZM6SB',
    }
  ]
},
{
  displayName: '两面',
  displayRows: 12,
  midcode: 'TMDSDX,LM',
  display: [...tdxds, ...thdxds, ...twdx, ...tSkyEarth, ...tFrontBack, ...tHouseWild, ...totalSumDS, ...totalSumDX, ...specialBig, ...specialdSmall],
  horizontalDataAlign: 1,
  horizontalSet: 2,
},
{
  displayName: '正码1-6',
  midcode: 'ZM1LM,ZM1SB,ZM2LM,ZM2SB,ZM3LM,ZM3SB,ZM4LM,ZM4SB,ZM5LM,ZM5SB,ZM6LM,ZM6SB',
  isStaticRow: 1,
  // combineStyle: 1,
  repeatPerRow: 3,
  repeatPerCol: 2,
  prefix: ['ZM1', 'ZM2', 'ZM3', 'ZM4', 'ZM5', 'ZM6'],
  display: [[...dxds, ...hdxds, ...wdx, ...threeColors],
    [...dxds, ...hdxds, ...wdx, ...threeColors],
    [...dxds, ...hdxds, ...wdx, ...threeColors],
    [...dxds, ...hdxds, ...wdx, ...threeColors],
    [...dxds, ...hdxds, ...wdx, ...threeColors],
    [...dxds, ...hdxds, ...wdx, ...threeColors]],
  firstTableHeader: ['正码1', '正码2', '正码3', '正码4', '正码5', '正码6'],
},
{
  displayName: '连码',
  displayRows: 10,
  display: [...number1to49],
  isLianMa: 1,
  quickSet: {
    style: 1,
    variables: [[...qsZodiac, ...qsFiller, ...qsFiller, ...qsFiller], [...qsColors], [...qsHead, ...qsTail], [...qsAll, ...qsDxds]]
  },
  children: [
    {
      tabId: 'L4QZ',
      label: '四全中',
      midcode: 'L4QZ',
    }, {
      tabId: 'L3QZ',
      label: '三全中',
      midcode: 'L3QZ',
    }, {
      tabId: 'L3Z2Z2',
      label: '三中二_中二',
      midcode: 'L3Z2Z2',
    }, {
      tabId: 'L3Z2Z3',
      label: '三中二_中三',
      midcode: 'L3Z2Z3',
    }, {
      tabId: 'L2QZ',
      label: '二全中',
      midcode: 'L2QZ',
    }, {
      tabId: 'L2ZT',
      label: '二中特_中特',
      midcode: 'L2ZT',
    }, {
      tabId: 'L2ZTZ2',
      label: '二中特_中二',
      midcode: 'L2ZTZ2',
    }, {
      tabId: 'LTC',
      label: '特串',
      midcode: 'LTC',
    }
  ]
},
{
  displayName: '一肖',
  midcode: 'PTYX',
  display: [...zodiac],
  displayRows: 6,
},
{
  displayName: '一肖不中',
  midcode: 'YXBZ',
  display: [...zodiac],
  displayRows: 6,
},
{
  displayName: '尾数',
  midcode: 'PTWS',
  display: [...nineTails],
  displayRows: 5,
},
{
  displayName: '尾数不中',
  midcode: 'WSBZ',
  display: [...nineTails],
  displayRows: 5,
},
{
  displayName: '特头尾数',
  midcode: 'TMTS,TMWS',
  display: [...nineTails, ...head],
  displayRows: 10,
},
{
  displayName: '五行',
  midcode: 'TMWX',
  display: [...element],
  displayRows: 5,
},
{
  displayName: '特正肖',
  displayRows: 6,
  display: [...zodiac],
  children: [
    {
      tabId: 'TMSX',
      label: '特肖',
      midcode: 'TMSX',
    }, {
      tabId: 'ZENGX',
      label: '正肖',
      midcode: 'ZENGX',
    }
  ]
},
{
  displayName: '总肖',
  midcode: 'ZX,ZXDS',
  displayRows: 4,
  horizontalDataAlign: 1,
  horizontalSet: 2,
  display: [...zodiac2to7, ...totalZodiacDS],
},
{
  displayName: '合肖',
  settingCode: 'TMHX',
  displayRows: 5,
  horizontalSet: 4,
  horizontalDataAlign: 1,
},
{
  displayName: '连肖',

  displayRows: 6,
  display: [...zodiac],
  isLianMa: 1,
  children: [
    {
      tabId: 'L2LX',
      label: '二肖连中',
      midcode: 'L2LX',
    }, {
      tabId: 'L2LXBZ',
      label: '二肖连不中',
      midcode: 'L2LXBZ',
    }, {
      tabId: 'L3LX',
      label: '三肖连中',
      midcode: 'L3LX',
    }, {
      tabId: 'L3LXBZ',
      label: '三肖连不中',
      midcode: 'L3LXBZ',
    }, {
      tabId: 'L4LX',
      label: '四肖连中',
      midcode: 'L4LX',
    }, {
      tabId: 'L4LXBZ',
      label: '四肖连不中',
      midcode: 'L4LXBZ',
    }, {
      tabId: 'L5LX',
      label: '五肖连中',
      midcode: 'L5LX',
    }, {
      tabId: 'L5LXBZ',
      label: '五肖连不中',
      midcode: 'L5LXBZ',
    }
  ]
},
{
  displayName: '连尾',
  displayRows: 5,
  isLianMa: 1,
  display: [...nineTails],
  children: [
    {
      tabId: 'L2LW',
      label: '二尾连中',
      midcode: 'L2LW',
    }, {
      tabId: 'L2LXBZ',
      label: '二尾连不中',
      midcode: 'L2LWBZ',
    }, {
      tabId: 'L3LW',
      label: '三尾连中',
      midcode: 'L3LW',
    }, {
      tabId: 'L3LWBZ',
      label: '三尾连不中',
      midcode: 'L3LWBZ',
    }, {
      tabId: 'L4LW',
      label: '四尾连中',
      midcode: 'L4LW',
    }, {
      tabId: 'L4LWBZ',
      label: '四尾连不中',
      midcode: 'L4LWBZ',
    }
  ]
},
{
  displayName: '自选不中',
  displayRows: 13,
  display: [...number1to49],
  quickSet: {
    style: 1,
    variables: [[...qsZodiac, ...qsFiller, ...qsFiller, ...qsFiller], [...qsColors], [...qsHead, ...qsTail], [...qsAll, ...qsDxds]]
  },
  children: [
    {
      tabId: 'ZX5BZ',
      label: '五不中',
      midcode: 'ZX5BZ',
    }, {
      tabId: 'ZX6BZ',
      label: '六不中',
      midcode: 'ZX6BZ',
    }, {
      tabId: 'ZX7BZ',
      label: '七不中',
      midcode: 'ZX7BZ',
    }, {
      tabId: 'ZX8BZ',
      label: '八不中',
      midcode: 'ZX8BZ',
    }, {
      tabId: 'ZX9BZ',
      label: '九不中',
      midcode: 'ZX9BZ',
    }, {
      tabId: 'ZX10BZ',
      label: '十不中',
      midcode: 'ZX10BZ',
    }, {
      tabId: 'ZX11BZ',
      label: '十一不中',
      midcode: 'ZX11BZ',
    }, {
      tabId: 'ZX12BZ',
      label: '十二不中',
      midcode: 'ZX12BZ',
    }
  ]
}
];

export default HK6;