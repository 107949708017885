import { useEffect, useState } from 'react';

import { Button, Col, Label, Row } from 'reactstrap';
import { FaPlusCircle } from 'react-icons/fa';
import { connect } from 'react-redux';

import { useTranslation } from 'react-i18next';

import { appActions, systemActions } from '../../../redux/actions';
import AgentDataTable from '../../AgentDataTable';
import Pagination from '../../Content/Pagination';
import './styles.scss';


const mapStateToProps = state => ({
  withdrawSettingData: state.systemReducers.withdrawSettingData,
  userData: state.memberReducers.userData,
});

const mapDispatchToProps = dispatch => ({
  getWithdrawSetting: (obj, callback) => dispatch(systemActions.getWithdrawSetting(obj, callback)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  updateExternalWebStatus: (obj, callback) => dispatch(systemActions.updateExternalWebStatus(obj, callback)),
  setWithdrawStatus: (obj, callback) => dispatch(systemActions.setWithdrawStatus(obj, callback)),
});

const WithdrawSetting = ({ callback = () => {}, withdrawSettingData, getWithdrawSetting, data, setWithdrawStatus }) => {
  const { t } = useTranslation();
  const [_info, setInfo] = useState([]);
  const [_page, setPage] = useState(1);
  const [_rows, setRows] = useState(15);
  const [_totalRecord, setTotalRecord] = useState(0);
  const [_isQueryingDetail, setIsQueryingDetail] = useState(true);
  const [isQueryStatus, setQueryStatus] = useState(true);

  useEffect(() => {
    if (withdrawSettingData && JSON.stringify(withdrawSettingData?.settingList) !== JSON.stringify(_info)) {
      setInfo(withdrawSettingData?.settingList);
      setTotalRecord(withdrawSettingData?.totalRecords);
      setQueryStatus(false);
    }
  }, [withdrawSettingData]);

  useEffect(() => {
    setIsQueryingDetail(true);
    getWithdrawSetting({
      page: _page,
      rows: _rows,
    }, setIsQueryingDetail);
  }, []);

  const onPaginationClick = (pageNum, rows) => {
    setIsQueryingDetail(false);
    // Page number and {row number will follow the pagination component instead
    getWithdrawSetting({
      page: pageNum,
      rows: _rows,
    }, setIsQueryingDetail);
    setPage(pageNum);
    setRows(rows);
  };

  const afterSuccessCallBack = () => {
    getWithdrawSetting({
      page: _page,
      rows: _rows,
    }, setIsQueryingDetail);
  };

  const statusClick = id => {
    setWithdrawStatus(id, afterSuccessCallBack);
  };


  const tableHeader = [{
    label: t('MIN_SINGLE_WITHDRAW_VALUE'),
  }, {
    label: t('MAX_SINGLE_WITHDRAW_VALUE'),
  }, {
    label: t('DAILY_WITHDRAW_LIMIT'),
  }, {
    label: t('WITHDRAW_FEES_RATE'),
  }, {
    label: t('FOC_WITHDRAW_TIME'),
  }, {
    label: t('CHARGE_RATE_FOR_EXCEED_WITHDRAW'),
  }, {
    label: t('DAILY_WITHDRAW_TIME'),
  }, {
    label: t('WITHDRAW_TURNOVER_RATE'),
  }, {
    label: t('CLIENT_PLATFORM_BELONGS'),
  }, {
    label: t('WITHDRAW_STATUS'),
  }, {
    label: t('STATUS_REMARK'),
  }, {
    label: t('FUNCTION'),
  }];

  const tableContent = [
    data => (<Label className="d-flex justify-content-center">{data?.min}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.max}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.dayMax}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.fee}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.freeTimes}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.unFreeTimes}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.dailyLimit}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.flowTimes}</Label>),
    data => (<Label className="d-flex justify-content-center">{platforms?.find(x => x.value === data?.platform)?.label}</Label>),
    data => (data?.status === 1 ? <Label className="negativeValue">{t('DISABLED')}</Label> : <Label className="installment">{t('ACTIVE')}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.statusInfo}</Label>),
    data => (
      <>
        <Button className="button handle mx-1" onClick={() => callback('withdrawSettingAddEdit', data, true)}>{t('COMPILE')}</Button>
        <Button className="button yellow mx-1" onClick={() => statusClick(data?.id)}>{data?.status === 1 ? t('ACTIVE') : t('DISABLED')}</Button>
      </>
    ),
  ];
  const platforms = [
    {
      label: '后台',
      value: 0,
    }, {
      label: '会员',
      value: 1,
    },
    {
      label: '手机端',
      value: 2,
    }
  ];
  return (
    <div className="WithdrawSettingWrapper">
      <div className="mx-0 bg-white my-2  border-1">
        <Row className="p-0 m-0 mb-2">
          <Col className="p-1 mb-2 filterWrapper d-flex align-items-center w-100">
            <Col className="d-flex align-items-center">
              <Button
                className="button add justify-content-center"
                onClick={() => callback('withdrawSettingAddEdit', data, false)}
              >
                <FaPlusCircle className="mb-1 mr-1" />
                <Label className="p-0 px-1">{t('ADD_SETTING')}</Label>
              </Button>
            </Col>
          </Col>
        </Row>
        <Row className="bigWrapper m-0 my-1 p-3">
          <AgentDataTable
            isQuerying={isQueryStatus}
            tableHeaderTitle={tableHeader}
            tableData={_info || (isQueryStatus ? [1, 2, 3] : [])}
            tableBodyRowProperty={tableContent}
            tableBodyRowStyle={[]}
          />
        </Row>
        <Row className="justify-center w-full relative">
          <Pagination totalRecord={_totalRecord || 0} onClick={onPaginationClick} isQuery={_isQueryingDetail} />
        </Row>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(WithdrawSetting);