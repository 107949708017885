import moment from 'moment';
import 'moment/locale/zh-cn';

const validateNumber = (text, escapeCharacter = [], skipTrim = true, returnType = 'number') => {
  if (!text) return undefined;
  let tempText = skipTrim ? text : text.trim();
  if ((Number.isNaN(Number(text)) || text.includes('.')) && !escapeCharacter.includes(text)) {
    tempText = tempText.split('').map(x => ((Number.isNaN(Number(x)) || x === '.') && !escapeCharacter.includes(x) ? '' : x)).join('');
  }
  return !Number.isNaN(Number(text)) && returnType === 'number' ? parseInt(tempText, 10) : tempText;
};

const renderResultDateCell = (data, language, unix = false) => {
  moment.locale(language === 'ZH' ? 'zh-cn' : 'en');
  const date = unix ? moment.unix(data.lotteryTime).format('YYYY-MM-DD') : moment(data.lotteryTime).format('YYYY-MM-DD');
  const time = `(${moment.weekdays(unix ? moment.unix(data.lotteryTime).days() : moment(data.lotteryTime).days())}) ${unix ? moment.unix(data.lotteryTime).format('HH:mm:ss') : moment(data.lotteryTime).format('HH:mm')}`;
  return [{
    value: date,
    className: 'd-flex align-items-center justify-content-center'
  }, {
    value: time,
    className: 'd-flex align-items-center justify-content-center'
  }];
};

const renderHeXiaoBalls = (preZodiac = '猪', currentZodiac, zodiacTotalBall = 48) => {
  const REDBALL = ['1', '2', '7', '8', '12', '13', '18', '19', '23', '24', '29', '30', '34', '35', '40', '45', '46'];
  const BLUEBALL = ['3', '4', '9', '10', '14', '15', '20', '25', '26', '31', '36', '37', '41', '42', '47', '48'];
  const GREENBALL = ['5', '6', '11', '16', '17', '21', '22', '27', '28', '32', '33', '38', '39', '43', '44', '49'];
  const zodiacList = ['鼠', '牛', '虎', '兔', '龙', '蛇', '马', '羊', '猴', '鸡', '狗', '猪'];
  const result = [];
  const startIndex = zodiacList.indexOf(preZodiac) - zodiacList.indexOf(currentZodiac) + 1;
  for (let i = startIndex; i <= zodiacTotalBall; i += 12) {
    let ballStyle = '';
    if (i > 0) {
      if (REDBALL.includes(`${i}`)) {
        ballStyle = 'circle circleRed';
      } else if (BLUEBALL.includes(`${i}`)) {
        ballStyle = 'circle circleBlue';
      } else if (GREENBALL.includes(`${i}`)) {
        ballStyle = 'circle circleGreen';
      }
      result.push({
        value: i, className: `m-auto ${ballStyle}`
      });
    }
  }
  return result;
};

const exportCSV = ({
  data = [],
  columns = [],
  fileName = 'simple-js-export',
  delimiter = ',',
} = {}) => {
  const csvNewLine = '\n';

  const browserDownload = (csvContent, fileName) => {
    const blob = new Blob([csvContent], {
      type: 'text/csv;charset=utf-8;'
    });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const download = (csvContent, fileName) => {
    browserDownload(`\ufeff${csvContent}`, fileName);
  };

  if (!Array.isArray(data) || !Array.isArray(columns)) {
    throw new TypeError('Data & Columns should be Array type');
  }
  return {
    options: {
      data, columns, fileName, delimiter
    },
    save: () => {
      if (typeof columns[0] === 'string') {
        const res = data.reduce(
          (acc, item) => {
            acc
              += Object.keys(item)
                .map(i => item[i])
                .join(delimiter) + csvNewLine;
            return acc;
          },
          columns.join(delimiter) + csvNewLine
        );
        download(res, `${fileName}.csv`);
      }
      if (typeof columns[0] === 'object') {
        const res = data.reduce(
          (acc, item) => {
            columns.forEach(col => {
              acc
                += `'${(item[col.dataIndex] || '')}${(col.forceString && '\t') || ''}${delimiter}`;
            });
            return (acc += csvNewLine);
          },
          columns.map(i => i.title).join(delimiter) + csvNewLine
        );
        download(res, `${fileName}.csv`);
      }
    },
  };
};

function isMSbrowser() {
  const { userAgent } = window.navigator;
  return userAgent.indexOf('Edge') !== -1 || userAgent.indexOf('Trident') !== -1;
}

function format(data) {
  return String(data).replace(/"/g, '""').replace(/(^[\s\S]*$)/, '"$1"');
}

const saveCSV = (title, head, data) => {
  const wordSeparator = ',';
  const lineSeparator = '\n';

  const reTitle = `${title}.csv`;
  const headBOM = '\ufeff';
  const headStr = head ? head.map(item => format(item)).join(wordSeparator) + lineSeparator : '';
  const dataStr = data ? data.map(row => row.map(item => format(item)).join(wordSeparator)).join(lineSeparator) : '';

  return isMSbrowser
    ? new Promise(resolve => { // Edge、IE11
      const blob = new Blob([headBOM + headStr + dataStr], {
        type: 'text/plain;charset=utf-8'
      });
      window.navigator.msSaveBlob(blob, reTitle);
      resolve();
    })
    : new Promise(resolve => { // Chrome、Firefox
      const a = document.createElement('a');
      a.href = `data:text/csv;charset=utf-8,${headBOM}${encodeURIComponent(headStr + dataStr)}`;
      a.download = reTitle;
      a.click();
      resolve();
    });
};

// const chnNumChar = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
// const chnUnitSection = ['', '万', '亿', '万亿', '亿亿'];
// const chnUnitChar = ['', '十', '百', '千'];

// const NumberToChinese = section => {
//   let strIns = ''; let
//     chnStr = '';
//   let unitPos = 0;
//   let zero = true;
//   while (section > 0) {
//     const v = section % 10;
//     if (v === 0) {
//       if (!zero) {
//         zero = true;
//         chnStr = chnNumChar[v] + chnStr;
//       }
//     } else {
//       zero = false;
//       strIns = chnNumChar[v];
//       strIns += chnUnitChar[unitPos];
//       chnStr = strIns + chnStr;
//     }
//     unitPos += 1;
//     section = Math.floor(section / 10);
//   }
//   return chnStr;
// };

// const SectionToChinese = num => {
//   let unitPos = 0;
//   let strIns = ''; let
//     chnStr = '';
//   let needZero = false;

//   if (num === 0) {
//     return chnNumChar[0];
//   }

//   while (num > 0) {
//     const section = num % 10000;
//     if (needZero) {
//       chnStr = chnNumChar[0] + chnStr;
//     }
//     strIns = NumberToChinese(section);
//     strIns += (section !== 0) ? chnUnitSection[unitPos] : chnUnitSection[0];
//     chnStr = strIns + chnStr;
//     needZero = (section < 1000) && (section > 0);
//     num = Math.floor(num / 10000);
//     unitPos += 1;
//   }

//   return chnStr;
// };

const NUMBER_LOWER = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九']; // 〇
const NUMBER_UPPER = ['零', '壹', '貳', '參', '肆', '伍', '陸', '柒', '捌', '玖'];
const UNIT_LOWER = ['', '十', '百', '千'];
const UNIT_UPPER = ['', '拾', '佰', '仟'];
const UNIT_GROUP_LOWER = ['', '万', '亿', '兆'];
const UNIT_GROUP_UPPER = ['', '萬', '億', '兆'];

function SectionToChinese(number, upper = false) {
  const NUMBER = upper ? NUMBER_UPPER : NUMBER_LOWER;
  const UNIT = upper ? UNIT_UPPER : UNIT_LOWER;
  const GROUP = upper ? UNIT_GROUP_UPPER : UNIT_GROUP_LOWER;
  const result = [];

  if (number === 0) {
    return NUMBER_LOWER[0];
  }

  // 1234567890 => [ 0 9 8 7 6 5 4 3 2 1 ]
  const str = Math.abs(number).toString().split('').reverse();
  for (let i = 0, part; i < str.length; i++) {
    part = parseInt(i / 4, 10); // 0 9 8 7, 6 5 4 3, 2 1
    result[part] = result[part] || [];
    result[part].push(
      NUMBER[str[i]] // 0 => 零
            + (str[i] !== '0' ? UNIT[i % 4] : '') // + 十 百 千
    );
  }
  for (let ii = 0; ii < result.length; ii++) {
    result[ii] = result[ii].reverse().join('')
            + GROUP[ii]; // + 万 亿 兆
  }
  return (number < 0 ? '负' : '')
        + result.reverse().join('')
          .replace(/([〇零])[〇零]+/g, '$1')
          .replace(/^[一壹](十|拾)/g, '$1')
          .replace(/([十拾])[〇零]+/g, '$1')
          .replace(/[〇零]+$/g, '')
          .replace(/[〇零]([万亿兆萬億兆])/g, '$1')
          .replace(/([兆兆])[亿億]/g, '$1')
          .replace(/([亿億])[万萬]/g, '$1')
          .replace(/([兆兆])[万萬]/g, '$1');
}

export {
  validateNumber, renderResultDateCell, renderHeXiaoBalls, exportCSV, saveCSV, SectionToChinese
};