import { useEffect, useState } from 'react';

import { Button, Col, Label, Row } from 'reactstrap';
import { IoClose } from 'react-icons/io5';
import { Pagination } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { appActions, noticeActions } from '../../../redux/actions';
import AgentDataTable from '../../AgentDataTable';

import './styles.scss';

const mapStateToProps = state => ({
  announcement: state.noticeReducers.announcement,
});

const mapDispatchToProps = dispatch => ({
  getAnnouncement: (page, rows, callback) => dispatch(noticeActions.getAnnouncement(page, rows, callback)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
});

const LatestAnnouncementModal = ({ getAnnouncement, announcement, updateModalAlert, data }) => {
  const { t } = useTranslation();
  const [_isQueryingDetail, setIsQueryingDetail] = useState(false);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (!data) {
      setIsQueryingDetail(true);
      getAnnouncement(page, 15, () => setIsQueryingDetail(false));
    }
  }, [page]);


  return (
    <div className="latestAnnouncementModalWrapperWeb">
      <Row className="modalHeader">
        <Col
          className={`${'d-flex justify-content-start align-items-center'}`}
        >
          {!data ? '全部公告' : '最新公告'}
        </Col>
        <Col className="d-flex justify-content-end align-items-center">
          <IoClose
            onClick={() => {
              updateModalAlert({
                visible: false
              });
              if (data && data.overBet && data.onConfirmationClick) data.onConfirmationClick();
            }}
            className="h4 mb-0 float-right closeIcon"
          />
        </Col>
      </Row>
      <div className="modalContent p-2">
        <AgentDataTable
          isQuerying={_isQueryingDetail}
          tableHeaderTitle={[{
            label: '内容',
          }, {
            label: '时间',
          }]}
          tableData={data || (announcement ? announcement.msgList : Array(5).fill({}))}
          tableBodyRowStyle={['contentNotice', 'dateNotice']}
          tableBodyRowProperty={[
            data => (<Label className="d-flex justify-content-center">{data.content ?? ''}</Label>),
            data => (<Label className="d-flex justify-content-center ">{moment.unix(data.date ?? 0).format('YYYY-MM-DD HH:mm:ss')}</Label>),
          ]}
        />
        {!data
        && (
        <div className="d-flex justify-content-center py-3">
          <Pagination
            defaultPageSize={15}
            current={page}
            onChange={page => {
              setPage(page);
            }}
            total={announcement?.totalRecords ?? 0}
          />
        </div>
        )}
      </div>

      <Row className="modalFooter d-flex align-items-center">
        <Button
          className={`confirmButton my-2 ${'me-3'}`}
          onClick={() => {
            updateModalAlert({
              visible: false
            });
          }}
        >
          {t('CONFIRM')}
        </Button>
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LatestAnnouncementModal);