/* eslint-disable react/jsx-wrap-multilines */
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import AutoRestockSetting from '../AutoRestockSetting';
import ChangePassword from '../../components/IndividualManagement/ChangePassword';
import { CommonHeader } from '../../components';
import CompanyDomainSetting from '../../components/CompanyManagement/CompanyDomainSetting';
import CompanyOddsDiff from '../../components/OddsManage/CompanyOddsDiff';
import CompanySetting from '../../components/CompanyManagement/CompanySetting';
import DecreaseOddsSetting from '../../components/OddsManage/DecreaseOddsSetting';
import DominManage from '../../components/SystemSetting/DominManage';
import GameManagement from '../../components/CompanyManagement/GameManagement';
import LotterySetting from '../../components/CompanyManagement/LotterySetting';
import MFASetting from '../../components/IndividualManagement/MFA';
import SystemDefaultOddsSetting from '../../components/OddsManage/SystemDefaultOddsSetting';
import SystemRoleSetting from '../../components/CompanyManagement/SystemRoleSetting';
import SystemSetting from '../../components/CompanyManagement/SystemSetting';
import ThirdPartyTopUpManagement from '../../components/CompanyManagement/ThirdPartyTopUpManagement';
import './styles.scss';

const mapStateToProps = state => ({
  selectedSubMenu: state.commonReducers.selectedSubMenu,
  userInfo: state.memberReducers.userInfo,
});

const CompanyManagement = ({ selectedSubMenu }) => {
  const { t } = useTranslation();

  return (

    <div className="CompanyManagementWrapper p-3">
      {selectedSubMenu === 'IM_MFA_SETTING' && (
      <>
        <CommonHeader
          headerName={selectedSubMenu ? t(selectedSubMenu.slice(3)) : ''}
        />
        <MFASetting />
      </>) }
      {selectedSubMenu === 'CM_CHANGE_PASSWORD' && (
      <>
        <CommonHeader
          headerName={selectedSubMenu ? t(selectedSubMenu.slice(3)) : ''}
        />
        <ChangePassword />
      </>) }
      {selectedSubMenu === 'CM_SYSTEM_SETTINGS' && (
      <>
        <CommonHeader
          headerName={selectedSubMenu ? t(selectedSubMenu.slice(3)) : ''}
        />
        <SystemSetting />
      </>) }
      {
      selectedSubMenu === 'CM_COMPANY_SETTING' && (
      <CompanySetting />)
      }
      {
      selectedSubMenu === 'CM_COMPANY_HOSTNAME_SETTING' && (
      <CompanyDomainSetting />)
      }
      {
      selectedSubMenu === 'CM_LOTTERY_SETTING' && (
      <LotterySetting />)
      }
      {
      selectedSubMenu === 'CM_SYSTEM_PERMISSION_SETTING' && (
      <SystemRoleSetting />)
      }
      {
      selectedSubMenu === 'CM_THIRD_TOPUP_SETTING' && (
      <ThirdPartyTopUpManagement />)
      }
      {
      selectedSubMenu === 'CM_GAME_MANAGEMENT' && (
        <GameManagement />)
      }
      {
      selectedSubMenu === 'CM_DOMAIN_MANAGEMENT' && (
        <DominManage />)
      }
      {
      selectedSubMenu === 'CM_AUTO_RESTOCK_SETTINGS' && (
        <AutoRestockSetting key="CM_AUTO_RESTOCK_SETTINGS" isAdmin />)
      }
      {
      selectedSubMenu === 'CM_COMPANY_RETURN' && (
        <CompanyOddsDiff key="CM_COMPANY_RETURN" isAdmin />)
      }
      {
      selectedSubMenu === 'CM_DECREASE_ODDS_SETTING' && (
        <DecreaseOddsSetting key="CM_DECREASE_ODDS_SETTING" isAdmin />)
      }
      {
      selectedSubMenu === 'CM_DEFAULT_ODDS' && (
        <SystemDefaultOddsSetting key="CM_DEFAULT_ODDS" isAdmin />)
      }
    </div>
  );
};

export default connect(mapStateToProps, null)(CompanyManagement);