
import { Button, Input, Select } from 'antd';
import { Col, Label, Row } from 'reactstrap';

import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { useTranslation } from 'react-i18next';

import { appActions, bogusBetActions, commonActions } from '../../../redux/actions';

import AgentDataTable from '../../AgentDataTable';
import Notifications from '../../Content/Notifications';
import Pagination from '../../Content/Pagination';

import './styles.scss';

const mapStateToProps = state => ({
  activeLottery: state.commonReducers.activeLottery,
  quickBetQueryData: state.bogusBetReducers.quickBetQueryData,
  lotteryByTemplateCode: state.bogusBetReducers.lotteryByTemplateCode,
});

const mapDispatchToProps = dispatch => ({
  GetLotteryByTemplateCode: obj => dispatch(commonActions.GetLotteryByTemplateCode(obj)),
  QueryQuickBetMonitor: obj => dispatch(bogusBetActions.QueryQuickBetMonitor(obj)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
});

const QuickBetMonitor = ({ lotteryByTemplateCode = [], GetLotteryByTemplateCode, activeLottery, quickBetQueryData, QueryQuickBetMonitor }) => {
  const { t } = useTranslation(); // i18n
  const [_isQueryingDetail, setIsQueryingDetail] = useState([]); // loading
  const [_detailItem, setDetailItem] = useState([]); // table data
  const [_detailItemSum, setDetailItemSum] = useState([]); // table data sum
  const [_lottery, setLottery] = useState('-1');
  const [lotteryOptions, setLotteryOptions] = useState([]);

  const tableHeaderTitle = [
    {
      label: t('LOGIN_NAME'),
    },
    {
      label: 'IP',
    },
    {
      label: t('LOTTERY'),
    },
    {
      label: t('INSTALLMENTS'),
    },
    {
      label: t('CONTENT')
    },
    {
      label: t('TIME')
    },
  ];
  useEffect(() => {
    if (lotteryByTemplateCode) {
      setLotteryOptions(
        [
          {
            label: t('ALL'),
            value: '-1'
          }
        ].concat(
          lotteryByTemplateCode.map(item => ({
            label: item.lotteryName,
            value: item.code
          }))
        )
      );
    }
  }, [lotteryByTemplateCode]);

  useEffect(() => {
    if (activeLottery) {
      GetLotteryByTemplateCode({
        templateCode: activeLottery.templateCode
      });
    }
    setLotteryOptions([
      {
        label: t('ALL'),
        value: '-1'
      }
    ]);
    setLottery('-1');
  }, [activeLottery]);

  const _content = [
    data => (
      <Label>
        {
          data.loginId
        }
      </Label>
    ),
    data => (
      <Label>
        {
          data.ip
        }
      </Label>
    ),
    data => (
      <Label>
        {
          data.lotteryType
        }
      </Label>
    ),
    data => (
      <Label>
        {
          data.installments
        }
      </Label>
    ),
    data => (
      <Label>
        {
          data.betContent
        }
      </Label>
    ),
    data => (
      <Label>
        {
          data.createDate
        }
      </Label>
    ),
  ];

  const [filter, setFilter] = useState({
    status: '-1',
    betType: '-1',
    searchValue: '',
    page: 1, // 当前多少页
    rows: 15, // 一页多少内容
  });

  useEffect(() => {
    if (quickBetQueryData) {
      setDetailItem(quickBetQueryData.quickBetList || []);
      setDetailItemSum(quickBetQueryData.totalRecords || 0);
    }
    setIsQueryingDetail(false);
  }, [quickBetQueryData]);

  useEffect(() => {
    onQuery();
  }, []);

  const onQuery = (params = {}, modifyPage = false) => {
    setIsQueryingDetail(true);
    const selectedLottery = lotteryByTemplateCode.find(i => i.code === _lottery) || {};

    if (!modifyPage) {
      setFilter({
        ...filter,
        ...params,
        page: 1
      });
      params.page = 1;
    }

    QueryQuickBetMonitor({
      lotteryType: selectedLottery.code || '',
      loginId: filter.searchValue,
      rows: filter.rows,
      page: filter.page,
      ...params,
    });
  };

  return (
    <div className="QuickBetMonitorWrapper">
      <Col className="QuickBetMonitorFilterWrapper">
        <Col className="p-2 mb-2 filterWrapper d-flex align-items-center w-100 rounded-top">
          <Label className="px-2">{t('LOGIN_NAME')}</Label>
          <Input
            className="mx-1"
            value={filter.searchValue}
            onChange={e => {
              setFilter({
                ...filter, searchValue: e.target.value
              });
            }}

          />
          <Label className="px-2">{t('LOTTERY')}</Label>
          <Select
            className="w-120"
            value={_lottery}
            onChange={value => setLottery(value)}
            options={lotteryOptions}
          />
          <Button
            onClick={() => onQuery()}
            className="searchButton marginLeft5 me-2"
          >
            {
              t('FIND')
            }
          </Button>
          <Notifications />
        </Col>
      </Col>

      <Row className=" mt-1 mx-0 bg-white px-0 border-1">
        <AgentDataTable
          isQuerying={_isQueryingDetail}
          tableHeaderTitle={tableHeaderTitle}
          tableData={_detailItem || []}
          tableBodyRowProperty={_content}
        />
      </Row>

      <div className="info_tishi mt-2">{t('NOTE_RECORDS_ARE_RETAINED_FOR_A_MAXIMUM_OF_1_DAY')}</div>

      <Row className="justify-center w-full relative">
        <Pagination
          customizePage={filter.page}
          onClick={(page, rows) => {
            setFilter({
              ...filter,
              page,
              rows
            });
            onQuery(
              {
                page,
                rows
              },
              true
            );
          }}
          totalRecord={_detailItemSum || 0}
        />
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(QuickBetMonitor);