import { useEffect, useState } from 'react';

import { Button, Col, Row } from 'reactstrap';
import { DatePicker, Input } from 'antd';
import { IoClose } from 'react-icons/io5';
import { connect } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { appActions, systemActions } from '../../../redux/actions';

import './styles.scss';

const mapStateToProps = state => ({
  activeLottery: state.commonReducers.activeLottery
});

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  GetBankCardList: (obj, callback) => dispatch(systemActions.GetBankCardList(obj, callback)),
});

const PanKouSettingModal = ({ activeLottery, updateModalAlert, data }) => {
  const { t } = useTranslation();
  const [lotteryTime, setlotteryTime] = useState(moment(data.data.lotteryTime).toDate());
  const [openTime, setopenTime] = useState(moment(data.data.openTime).toDate());
  const [closeTime, setcloseTime] = useState(moment(data.data.closeTime).toDate());
  const [res, setRes] = useState();
  const [errorMsg, setErrorMsg] = useState();

  const resultsLen = {
    '11X5': {
      numsOfBalls: 5, min: 1, max: 11, repeatCheck: false
    },
    BJK8: {
      numsOfBalls: 20, min: 1, max: 80, repeatCheck: false
    },
    FANT: {
      numsOfBalls: 10, min: 1, max: 10, repeatCheck: false
    },
    GXK10: {
      numsOfBalls: 5, min: 1, max: 5, repeatCheck: false
    },
    K3: {
      numsOfBalls: 3, min: 1, max: 6, repeatCheck: false
    },
    KL10: {
      numsOfBalls: 8, min: 1, max: 20, repeatCheck: false
    },
    PCDD: {
      numsOfBalls: 3, min: 0, max: 27, repeatCheck: false
    },
    PK10: {
      numsOfBalls: 10, min: 1, max: 10, repeatCheck: true
    },
    SSC: {
      numsOfBalls: 5, min: 0, max: 9, repeatCheck: false
    },
    F31X7: {
      numsOfBalls: 8, min: 1, max: 31, repeatCheck: false
    },
    F36X7: {
      numsOfBalls: 8, min: 1, max: 36, repeatCheck: false
    },
  };

  useEffect(() => {
    if (data?.type === 'update' || data?.type === 'draw') {
      if (activeLottery.templateCode === 'HK6' && activeLottery.code !== 'JSHK6') {
        const { ping1 = '', ping2 = '', ping3 = '', ping4 = '', ping5 = '', ping6 = '', tema = '' } = {
          ...data.data
        };
        setRes({
          ping1, ping2, ping3, ping4, ping5, ping6, tema
        });
      } else {
        const len = resultsLen[activeLottery.templateCode]?.numsOfBalls || 7;
        const res = data.data.openResult ? data.data.openResult.split(',') : [];
        const tempRes = new Array(len).fill('').map((item, index) => res[index] || item);
        setRes(tempRes);
      }
    }
  }, []);

  const validationBalls = obj => {
    if (resultsLen[activeLottery.templateCode]) {
      const { min } = resultsLen[activeLottery.templateCode];
      const { max } = resultsLen[activeLottery.templateCode];
      const { repeatCheck } = resultsLen[activeLottery.templateCode];
      const tempMaxMin = res.filter(item => (item < min || item > max));
      const tempRepeat = new Set(res);
      if ((tempMaxMin.length > 0) || (repeatCheck && (tempRepeat.size !== res.length))) {
        setErrorMsg(`数字范围为${min}-${max} ${repeatCheck ? '且不能重复' : ''}`);
      } else {
        updateModalAlert({
          visible: false
        });
        data.onConfirmationClick(obj);
      }
    } else {
      updateModalAlert({
        visible: false
      });
      data.onConfirmationClick(obj);
    }
  };

  const handleBtn = () => {
    if (data.type === 'time') {
      const _obj = {
        ...data.data, lotteryType: activeLottery.code, lotteryTime: moment(lotteryTime).unix(), openTime: moment(openTime).unix(), closeTime: moment(closeTime).unix()
      };
      updateModalAlert({
        visible: false
      });
      data.onConfirmationClick(_obj);
    }
    if (data?.type === 'update') {
      let _obj;
      if (activeLottery.templateCode === 'HK6' && activeLottery.code !== 'JSHK6') {
        _obj = {
          ...data.data,
          createDate: moment(data.data.createDate).unix(),
          lotteryTime: moment(data.data.lotteryTime).unix(),
          qishuDate: moment(data.data.qishuDate).unix(),
          updateDate: moment(data.data.updateDate).unix(),
          ...res,
        };
      } else {
        _obj = {
          ...data.data,
          createDate: moment(data.data.createDate).unix(),
          lotteryTime: moment(data.data.lotteryTime).unix(),
          qishuDate: moment(data.data.qishuDate).unix(),
          updateDate: moment(data.data.updateDate).unix(),
          lotteryResults: res,
        };
      }
      validationBalls(_obj);
    }
    if (data?.type === 'draw') {
      const _obj = {
        OddsMngId: data.data?.id,
        lottery: {
          ...data.data,
          closeTime: moment(data.data.closeTime).unix(),
          lotteryTime: moment(data.data.lotteryTime).unix(),
          openTime: moment(data.data.openTime).unix(),
          lotteryType: activeLottery.code,
          lotteryResults: res,
        }
      };
      validationBalls(_obj);
    }
  };

  const updateResNumber = (text, index) => {
    let temp;
    if (activeLottery.templateCode === 'HK6' && activeLottery.code !== 'JSHK6') {
      temp = {
        ...res, [index]: text.trim() === '' ? '' : text > -1 ? Number(text) : temp[index]
      };
    } else {
      temp = [].concat(res);
      temp[index] = text.trim() === '' ? '' : text > -1 ? Number(text) : temp[index];
    }
    setRes(temp);
  };

  return (
    <div className="PanKouSettingModalWrapperWeb">
      <Row className="modalHeader">
        <Col
          className="d-flex align-items-center"
        >
          盘口设置
        </Col>
        <Col className="d-flex justify-content-end align-items-center">
          <IoClose
            onClick={() => {
              updateModalAlert({
                visible: false
              });
              if (data && data.overBet && data.onConfirmationClick) data.onConfirmationClick();
            }}
            className="h4 mb-0 float-right closeIcon"
          />
        </Col>
      </Row>
      {
        data?.type === 'time'
          && (
            <div className="modalContent d-flex justify-content-center mx-0 p-2 ">
              <div className="orderInformationTable">
                <div className="evenRowStyle">
                  <div>开奖期数</div>
                  <div>
                    <Input className="inputStyle" value={data.data.installments} disabled />
                  </div>
                </div>
                <div className="evenRowStyle">
                  <div>开奖时间</div>
                  <div>
                    <DatePicker
                      className="betQueryDatepicker"
                      value={moment(lotteryTime)}
                      showTime
                      format="YYYY-MM-DD HH:mm:ss"
                      onChange={_date => {
                        setlotteryTime(_date.toDate());
                      }}
                    />
                  </div>
                </div>
                <div className="evenRowStyle">
                  <div>开盘时间</div>
                  <div>
                    <DatePicker
                      className="betQueryDatepicker"
                      value={moment(openTime)}
                      showTime
                      format="YYYY-MM-DD HH:mm:ss"
                      onChange={_date => {
                        setopenTime(_date.toDate());
                      }}
                    />
                  </div>
                </div>
                <div className="evenRowStyle">
                  <div>封盘时间</div>
                  <div>
                    <DatePicker
                      className="betQueryDatepicker"
                      value={moment(closeTime)}
                      showTime
                      format="YYYY-MM-DD HH:mm:ss"
                      onChange={_date => {
                        setcloseTime(_date.toDate());
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          )
      }
      {
        data?.type === 'draw'
            && (
            <div className="modalContent d-flex flex-column justify-content-center mx-0 p-2 ">
              <div className="orderInformationTable">
                <div className="evenRowStyle">
                  <div>开奖期数</div>
                  <div>
                    <Input className="inputStyle" value={data.data.installments} disabled />
                  </div>
                </div>
                <div className="evenRowStyle">
                  <div>开奖时间</div>
                  <div>
                    <Input className="inputStyle" value={data.data.lotteryTime} disabled />
                  </div>
                </div>
                <div className="evenRowStyle">
                  <div>开盘时间</div>
                  <div>
                    <Input className="inputStyle" value={data.data.openTime} disabled />
                  </div>
                </div>
                <div className="evenRowStyle">
                  <div>封盘时间</div>
                  <div>
                    <Input className="inputStyle" value={data.data.closeTime} disabled />
                  </div>
                </div>
                <div className="evenRowStyle">
                  <div>开奖号码</div>
                  <div className="d-flex align-items-center">
                    {
                    res && res.map((item, index) => <Input className="resultNumCell me-2 my-1" value={item} onChange={({ target: { value: text } }) => updateResNumber(text, index)} />)
                    }
                  </div>
                </div>
              </div>
              {errorMsg && <div className="text-danger pt-2 d-flex justify-content-center">{errorMsg}</div>}
            </div>
            )
      }
      {
        data?.type === 'update'
            && (
            <div className="modalContent d-flex flex-column justify-content-center mx-0 p-2 ">
              <div className="orderInformationTable">
                <div className="evenRowStyle">
                  <div>开奖期数</div>
                  <div>
                    <Input className="inputStyle" value={data.data.installments} disabled />
                  </div>
                </div>
                <div className="evenRowStyle">
                  <div>开奖时间</div>
                  <div>
                    <Input className="inputStyle" value={data.data.lotteryTime} disabled />
                  </div>
                </div>
                <div className="evenRowStyle">
                  <div>开奖号码</div>
                  <div className="d-flex align-items-center flex-wrap">
                    {res
                      ? activeLottery.templateCode === 'HK6' && activeLottery.code !== 'JSHK6'
                        ? (Object.entries(res).map(([key, item]) => (
                          <div className="me-3 my-1">
                            <div>{t(`${key.toUpperCase()}`)}</div>
                            <div className="d-flex align-items-center justify-content-center">
                              <Input className="resultNumCell" value={item} onChange={({ target: { value: text } }) => updateResNumber(text, key)} />
                            </div>
                          </div>
                        )))
                        : (res.map((item, index) => <Input className="resultNumCell me-2 my-1" value={item} onChange={({ target: { value: text } }) => updateResNumber(text, index)} />))
                      : null}
                  </div>
                </div>
              </div>
              {errorMsg && <div className="text-danger pt-2 d-flex justify-content-center">{errorMsg}</div>}
            </div>
            )
      }
      <Row className="modalFooter d-flex align-items-center ">
        <Button
          className="confirmButton my-3"
          onClick={() => {
            handleBtn();
          }}
        >
          {t('CONFIRM')}
        </Button>
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PanKouSettingModal);