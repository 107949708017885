import { Amount, BuHuo, DisplayLabel, InTimeSheetTable, OddsControl, Profit } from '../../../../components';

const FANT_ZONGXIANG = [
  {
    componentType: InTimeSheetTable,
    data: {
      tableTitle: '两面',
      tableTitleStyle: 'text-center py-2',
      tableHeadertitle: ['种类', '赔率', '金额', '输赢', '补货'],
      tableHeadertitleStyle: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      mainColumnLength: 1,
      mainRowLength: 17,
      horizontal: true,
      betItemStyleList: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      betItemList: [DisplayLabel, OddsControl, Amount, Profit, BuHuo],
      summary: [{
        displayName: '两面总注额',
        code: 'item_LM'
      }],
      list: [
        {
          code: 'DS_D',
          displayName: '单',
        }, {
          code: 'DS_S',
          displayName: '双',
        }, {
          code: 'DX_D',
          displayName: '大',
        }, {
          code: 'DX_X',
          displayName: '小',
        }
      ]
    },
  },
  {
    componentType: InTimeSheetTable,
    data: {
      tableTitle: '番',
      tableTitleStyle: 'text-center py-2',
      tableHeadertitle: ['种类', '赔率', '金额', '输赢', '补货'],
      tableHeadertitleStyle: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      mainColumnLength: 1,
      mainRowLength: 17,
      horizontal: true,
      betItemStyleList: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      betItemList: [DisplayLabel, OddsControl, Amount, Profit, BuHuo],
      summary: [{
        displayName: '番总注额',
        code: 'item_FA'
      }],
      list: [
        {
          code: 'FA1',
          displayName: '1番',
        }, {
          code: 'FA2',
          displayName: '2番',
        }, {
          code: 'FA3',
          displayName: '3番',
        }, {
          code: 'FA4',
          displayName: '4番',
        }
      ]
    },
  },
  {
    componentType: InTimeSheetTable,
    data: {
      tableTitle: '角',
      tableTitleStyle: 'text-center py-2',
      tableHeadertitle: ['种类', '赔率', '金额', '输赢', '补货'],
      tableHeadertitleStyle: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      mainColumnLength: 1,
      mainRowLength: 17,
      horizontal: true,
      betItemStyleList: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      betItemList: [DisplayLabel, OddsControl, Amount, Profit, BuHuo],
      summary: [{
        displayName: '角总注额',
        code: 'item_JI'
      }],
      list: [
        {
          code: 'JI1_2',
          displayName: '1-2角',
        }, {
          code: 'JI2_3',
          displayName: '2-3角',
        }, {
          code: 'JI3_4',
          displayName: '3-4角',
        }, {
          code: 'JI1_4',
          displayName: '1-4角',
        }
      ]
    },
  },
  {
    componentType: InTimeSheetTable,
    data: {
      tableTitle: '正',
      tableTitleStyle: 'text-center py-2',
      tableHeadertitle: ['种类', '赔率', '金额', '输赢', '补货'],
      tableHeadertitleStyle: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      mainColumnLength: 1,
      mainRowLength: 17,
      horizontal: true,
      betItemStyleList: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      betItemList: [DisplayLabel, OddsControl, Amount, Profit, BuHuo],
      summary: [{
        displayName: '正总注额',
        code: 'item_ZE'
      }],
      list: [
        {
          code: 'ZE1',
          displayName: '1正',
        }, {
          code: 'ZE2',
          displayName: '2正',
        }, {
          code: 'ZE3',
          displayName: '3正',
        }, {
          code: 'ZE4',
          displayName: '4正',
        }
      ]
    },
  },
  {
    componentType: InTimeSheetTable,
    data: {
      tableTitle: '三门',
      tableTitleStyle: 'text-center py-2',
      tableHeadertitle: ['种类', '赔率', '金额', '输赢', '补货'],
      tableHeadertitleStyle: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      mainColumnLength: 1,
      mainRowLength: 17,
      horizontal: true,
      betItemStyleList: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      betItemList: [DisplayLabel, OddsControl, Amount, Profit, BuHuo],
      summary: [{
        displayName: '三门总注额',
        code: 'item_SM'
      }],
      list: [
        {
          code: 'SM123',
          displayName: '123中',
        }, {
          code: 'SM124',
          displayName: '124中',
        }, {
          code: 'SM134',
          displayName: '134中',
        }, {
          code: 'SM234',
          displayName: '234中',
        }
      ]
    },
  },
  {
    componentType: InTimeSheetTable,
    data: {
      tableTitle: '念',
      tableTitleStyle: 'text-center py-2',
      tableHeadertitle: ['种类', '赔率', '金额', '输赢', '补货'],
      tableHeadertitleStyle: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      mainColumnLength: 1,
      mainRowLength: 17,
      horizontal: true,
      betItemStyleList: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      betItemList: [DisplayLabel, OddsControl, Amount, Profit, BuHuo],
      summary: [{
        displayName: '念总注额',
        code: 'item_NI'
      }],
      list: [
        {
          code: 'NI12',
          displayName: '1念2',
        }, {
          code: 'NI13',
          displayName: '1念3',
        }, {
          code: 'NI14',
          displayName: '1念4',
        }, {
          code: 'NI21',
          displayName: '2念1',
        }, {
          code: 'NI23',
          displayName: '2念3',
        }, {
          code: 'NI24',
          displayName: '2念4',
        }, {
          code: 'NI31',
          displayName: '3念1',
        }, {
          code: 'NI32',
          displayName: '3念2',
        }, {
          code: 'NI34',
          displayName: '3念4',
        }, {
          code: 'NI41',
          displayName: '4念1',
        }, {
          code: 'NI42',
          displayName: '4念2',
        }, {
          code: 'NI43',
          displayName: '4念3',
        }
      ]
    },
  }
];

export default FANT_ZONGXIANG;