
const twoCharacter = [{
  displayName: '万千', keyCode: 'DW2WQ'
}, {
  displayName: '万佰', keyCode: 'DW2WB'
}, {
  displayName: '万拾', keyCode: 'DW2WS'
}, {
  displayName: '万个', keyCode: 'DW2WG'
}, {
  displayName: '千佰', keyCode: 'DW2QB'
}, {
  displayName: '千拾', keyCode: 'DW2QS'
}, {
  displayName: '千个', keyCode: 'DW2QG'
}, {
  displayName: '佰拾', keyCode: 'DW2BS'
}, {
  displayName: '佰个', keyCode: 'DW2BG'
}, {
  displayName: '拾个', keyCode: 'DW2SG'
}
];
const threeCharacter = [{
  displayName: '前三定位'
}, {
  displayName: '中三定位'
}, {
  displayName: '后三定位'
}];

const twoCharacterSumNum = [{
  displayName: '0~4', tabId: 'HS2'
}, {
  displayName: '0', tabId: 'HS2W'
}, {
  displayName: '1', tabId: 'HS2W'
}, {
  displayName: '2', tabId: 'HS2W'
}, {
  displayName: '3', tabId: 'HS2W'
}, {
  displayName: '4', tabId: 'HS2W'
}, {
  displayName: '5',
}, {
  displayName: '6',
}, {
  displayName: '7'
}, {
  displayName: '8'
}, {
  displayName: '9'
}, {
  displayName: '10', tabId: 'HS2'
}, {
  displayName: '11', tabId: 'HS2'
}, {
  displayName: '12', tabId: 'HS2'
}, {
  displayName: '13', tabId: 'HS2'
}, {
  displayName: '14~18', tabId: 'HS2'
}
];
const threeCharacterSumNum = [{
  displayName: '0~6', tabId: 'HS3'
}, {
  displayName: '0', tabId: 'HS3W'
}, {
  displayName: '1', tabId: 'HS3W'
}, {
  displayName: '2', tabId: 'HS3W'
}, {
  displayName: '3', tabId: 'HS3W'
}, {
  displayName: '4', tabId: 'HS3W'
}, {
  displayName: '5', tabId: 'HS3W'
}, {
  displayName: '6', tabId: 'HS3W'
}, {
  displayName: '7'
}, {
  displayName: '8'
}, {
  displayName: '9'
}, {
  displayName: '10', tabId: 'HS3'
}, {
  displayName: '11', tabId: 'HS3'
}, {
  displayName: '12', tabId: 'HS3'
}, {
  displayName: '13', tabId: 'HS3'
}, {
  displayName: '14', tabId: 'HS3'
}, {
  displayName: '15', tabId: 'HS3'
}, {
  displayName: '16', tabId: 'HS3'
}, {
  displayName: '17', tabId: 'HS3'
}, {
  displayName: '18', tabId: 'HS3'
}, {
  displayName: '19', tabId: 'HS3'
}, {
  displayName: '20', tabId: 'HS3'
}, {
  displayName: '21~27', tabId: 'HS3'
}
];

const groupChoose = [{
  displayName: '选4', keyCode: '_4'
}, {
  displayName: '选5', keyCode: '_5'
}, {
  displayName: '选6', keyCode: '_6'
}, {
  displayName: '选7', keyCode: '_7'
}, {
  displayName: '选8', keyCode: '_8'
}, {
  displayName: '选9', keyCode: '_9'
}, {
  displayName: '选10', keyCode: '_10'
}
];

const duplicateChar = [{
  displayName: '前三复式'
}, {
  displayName: '中三复式'
}, {
  displayName: '后三复式'
}];

const cowSSC = [{
  displayName: '无牛'
}, {
  displayName: '牛1'
}, {
  displayName: '牛2'
}, {
  displayName: '牛3'
}, {
  displayName: '牛4'
}, {
  displayName: '牛5'
}, {
  displayName: '牛6'
}, {
  displayName: '牛7'
}, {
  displayName: '牛8'
}, {
  displayName: '牛9'
}, {
  displayName: '牛牛'
}
];

const soha = [{
  displayName: '五梅'
}, {
  displayName: '四带一'
}, {
  displayName: '葫芦'
}, {
  displayName: '顺子'
}, {
  displayName: '三条'
}, {
  displayName: '两对'
}, {
  displayName: '单对'
}, {
  displayName: '五不靠'
}, {
  displayName: '散号'
}
];

export {
  twoCharacter,
  threeCharacter,
  twoCharacterSumNum,
  threeCharacterSumNum,
  groupChoose,
  duplicateChar,
  cowSSC,
  soha,
};