import { useEffect, useState } from 'react';

import { Checkbox, Input, Radio } from 'antd';
import { Label } from 'reactstrap';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { AgentDataTable } from '../../..';
import { autoRestockSettingActions } from '../../../../redux/actions';
import mainGames from '../../../../constants/QuickSetVariable/Games';

import './styles.scss';

const mapStateToProps = state => ({
  activeLottery: state.commonReducers.activeLottery,
});

const mapDispatchToProps = dispatch => ({
  GetMaxBuhuo: (obj, callback) => dispatch(autoRestockSettingActions.GetMaxBuhuo(obj, callback)),
  SaveMaxBuhuo: (obj, callback) => dispatch(autoRestockSettingActions.SaveMaxBuhuo(obj, callback)),
});

const MaxBuHuo = ({ GetMaxBuhuo, SaveMaxBuhuo, data, activeLottery }) => {
  const [buhuoData, setData] = useState();

  const { t } = useTranslation();
  const [lotType, setlotType] = useState();
  const [_isQuerying, setIsQuerying] = useState(false);
  const [lotteryList, setLotteryList] = useState();
  const [oddsList, setOddsList] = useState();
  const [allOddsList, setAllOddsList] = useState();
  const [menuList, setMenuList] = useState();
  const [activeKey, setActiveKey] = useState();
  const [_selectedLottery, setSelectedLottery] = useState();
  const [changAll, setChangeAll] = useState(false);
  const [_mainGames, setMainGames] = useState('');

  useEffect(() => {
    setIsQuerying(true);
    setOddsList([]);
    const obj = {
      orgId: data.orgId,
      userType: data?.orgId ? 2 : 1,
      lotteryType: activeLottery.code
    };
    const callback = data => {
      setData(data);
      setIsQuerying(false);
    };
    GetMaxBuhuo(obj, callback);
  }, []);

  useEffect(() => {
    if (buhuoData) {
      if (JSON.stringify(lotteryList) !== JSON.stringify(buhuoData.lotList)) {
        setLotteryList(buhuoData.lotList);
      }
      setAllOddsList(buhuoData.settingList.map(x => ({
        ...x, maxBuHuoDefault: x.maxBuHuo
      })));
    }
  }, [buhuoData]);

  useEffect(() => {
    if (lotteryList) {
      const tempGames = mainGames.Games.filter(x => lotteryList.some(item => x.code.includes(item.templateCode)));
      setlotType(tempGames[0]?.value);
      setMainGames(tempGames);
    }
  }, [lotteryList]);

  useEffect(() => {
    if (_mainGames && _mainGames.length > 0) {
      const _tab = _mainGames.find(x => x.value === lotType);
      const content = [];
      const temp = new Map();
      if (_tab) {
        lotteryList.filter(x => _tab.code.includes(x.templateCode)).forEach(item => {
          if (temp.has(item.templateCode)) {
            const _tempArr = [...temp.get(item.templateCode), {
              ...item
            }];
            temp.set(item.templateCode, _tempArr);
          } else {
            temp.set(item.templateCode, [{
              ...item,
            }]);
          }
        });
        temp.forEach((item, key) => {
          if (item.length !== 0) {
            content.push({
              label: _tab.name.find(x => x.value === key)?.label,
              key,
              children: item
            });
          }
        });
        setActiveKey(content[0]?.key);
        setMenuList(content);
      }
    }
  }, [_mainGames, lotType]);

  useEffect(() => {
    if (activeKey && _mainGames) {
      const _tab = _mainGames.find(x => x.value === lotType);
      const temp = new Map();
      lotteryList.filter(x => _tab.code.includes(x.templateCode)).forEach(item => {
        if (temp.has(item.templateCode)) {
          const _tempArr = [...temp.get(item.templateCode), item];
          temp.set(item.templateCode, _tempArr);
        } else {
          temp.set(item.templateCode, [item]);
        }
      });
      setSelectedLottery(temp.get(activeKey)[0].code);
    }
  }, [activeKey, _mainGames]);

  useEffect(() => {
    if (_selectedLottery && allOddsList) {
      const temp = allOddsList.filter(x => x.lotteryType === _selectedLottery);
      setOddsList([...temp]);
    }
  }, [_selectedLottery, allOddsList]);

  const onValueChange = (text, code, type) => {
    if (changAll) {
      const _list = menuList.find(x => x.key === activeKey).children;
      const temp = allOddsList.map(x => {
        if (_list.find(item => x.lotteryType === item.code)) {
          if (x.code === code) {
            return ({
              ...x, [type]: Number.isNaN(Number(text.trim())) ? x[type] : text.trim(), [`${type}singleChange`]: false, [`${type}multiChange`]: true
            });
          }
          return ({
            ...x
          });
        }
        return ({
          ...x
        });
      });
      setAllOddsList(temp);
    } else {
      const temp = allOddsList.map(x => {
        if (x.lotteryType === _selectedLottery) {
          if (x.code === code) {
            return ({
              ...x, [type]: Number.isNaN(Number(text.trim())) ? x[type] : text.trim(), [`${type}singleChange`]: true, [`${type}multiChange`]: false
            });
          }
          return ({
            ...x
          });
        }
        return ({
          ...x
        });
      });
      setAllOddsList(temp);
    }
  };

  const onClickSave = () => {
    const temp = allOddsList.filter(x => Object.keys(x).find(a => a.includes('singleChange')) || Object.keys(x).find(a => a.includes('multiChange')));
    const obj = {
      Settings: temp,
      orgId: data.orgId,
      memberType: data?.orgId ? 2 : 1,
    };
    const callback = data => {
      setData(data);
      setIsQuerying(false);
    };

    SaveMaxBuhuo(obj, () => {
      setIsQuerying(true);
      setOddsList([]);
      GetMaxBuhuo({
        orgId: data.orgId,
        userType: data?.orgId ? 2 : 1,
        lotteryType: _selectedLottery
      }, callback);
    });
  };

  const onClickReset = () => {};

  return (
    <div className="MaxBuHuoWrapper">
      <div className="operationSection mb-2">
        <div className="categorySelect">
          <Radio.Group onChange={({ target: { value: text } }) => setlotType(text)} value={lotType} className="squareCheckStyle">
            {
              _mainGames && _mainGames.map(item => <Radio value={item.value}>{item.displayName}</Radio>)
            }
          </Radio.Group>
        </div>
        <div className="d-flex align-items-center">
          <div className="operationButton handle mx-2" onClick={() => onClickSave()}>{t('SAVE')}</div>
          <div className="operationButton find mx-2" onClick={() => onClickReset()}>{t('CANCEL')}</div>
        </div>
      </div>
      <div className="selectSection my-2">
        <div className="d-flex">
          {
            menuList && menuList.map(item => <div className={`templateStyle ${item.key === activeKey && 'selected'}`} onClick={() => setActiveKey(item.key)}>{item.label}</div>)
          }
        </div>
        <div>
          <Radio.Group value={_selectedLottery} onChange={({ target: { value: text } }) => setSelectedLottery(text)} className="squareCheckStyle">
            {menuList && menuList.find(x => x.key === activeKey) && menuList.find(x => x.key === activeKey)?.children.map(item => <Radio value={item.code}>{item.lotteryName}</Radio>)}
          </Radio.Group>
          <Checkbox className="ms-5" checked={changAll} onChange={({ target: { checked: val } }) => setChangeAll(val)}>统一设置</Checkbox>
        </div>
      </div>
      <div className="contentSection">
        <div>
          <AgentDataTable
            isQuerying={_isQuerying}
            tableHeaderTitle={
            [{
              label: '种类',
            }, {
              label: '最大补货量'
            }]
          }
            tableHeaderStyle={
            ['firstCellStyle']
          }
            tableData={oddsList && oddsList.length > 0 ? oddsList.slice(0, oddsList.length / 2) : []}
            tableBodyRowProperty={
            [
              data => (
                <Label className="categoryWrapper">
                  <div>{data.projectName}</div>
                </Label>
              ),
              data => (
                <Label className="pankouContentWarpper">
                  <div>
                    <Input className={`${data?.maxBuHuosingleChange && 'singleChange'} ${data?.maxBuHuomultiChange && 'multiChange'}`} value={data.maxBuHuo} onChange={({ target: { value: text } }) => onValueChange(text, data.code, 'maxBuHuo')} />
                    <div>
                      @
                      {data?.maxBuHuoDefault}
                    </div>
                  </div>
                </Label>
              )
            ]
          }
          />
        </div>
        <div className="ms-3">
          <AgentDataTable
            isQuerying={_isQuerying}
            tableHeaderTitle={
            [{
              label: '种类',
            }, {
              label: '最大补货量'
            }]
          }
            tableHeaderStyle={
            ['firstCellStyle']
          }
            tableData={oddsList && oddsList.length > 0 ? oddsList.slice(oddsList.length / 2) : []}
            tableBodyRowProperty={
            [
              data => (
                <Label className="categoryWrapper">
                  <div>{data.projectName}</div>
                </Label>
              ),
              data => (
                <Label className="pankouContentWarpper">
                  <div>
                    <Input className={`${data?.maxBuHuosingleChange && 'singleChange'} ${data?.maxBuHuomultiChange && 'multiChange'}`} value={data.maxBuHuo} onChange={({ target: { value: text } }) => onValueChange(text, data.code, 'maxBuHuo')} />
                    <div>
                      @
                      {data?.maxBuHuoDefault}
                    </div>
                  </div>
                </Label>
              )
            ]
          }
          />
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center py-3 w-100">
        <div className="operationButton handle mx-2" onClick={() => onClickSave()}>{t('SAVE')}</div>
        <div className="operationButton find mx-2" onClick={() => onClickReset()}>{t('CANCEL')}</div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MaxBuHuo);