import { StatusCodes } from 'http-status-codes';

import { SET_BATCH_DEFAULT_ODDS, SET_COMPANY_EDIT_INFO, SET_COMPANY_INFO, SET_COMPANY_MANAGE_INFO, SET_DEFAULT_ODDS, SET_DOMAIN_EDIT_INFO, SET_DOMAIN_INFO, SET_DOMAIN_NAME_SETTING, SET_GAMES_INFO, SET_LV2_ORGS, SET_ODDS_DIFF, SET_UPORG_FOR_COPY } from '../actionTypes';
import { apiService } from '../../../library';

import appActions from '../appActions';

const setOddsDiffUpOrgs = oddsDiffUpOrgsData => ({
  type: SET_LV2_ORGS,
  value: oddsDiffUpOrgsData,
});

const setUpOrgForCopy = upOrgsCopyData => ({
  type: SET_UPORG_FOR_COPY,
  value: upOrgsCopyData,
});

const setCompanyInfo = data => ({
  type: SET_COMPANY_INFO,
  value: data,
});

const setGamesInfo = data => ({
  type: SET_GAMES_INFO,
  value: data,
});

const setDomainInfo = data => ({
  type: SET_DOMAIN_INFO,
  value: data,
});

const setDomainNameSetting = data => ({
  type: SET_DOMAIN_NAME_SETTING,
  value: data,
});

const setCompanyEditInfo = data => ({
  type: SET_COMPANY_EDIT_INFO,
  value: data,
});

const setEditDomainInfo = data => ({
  type: SET_DOMAIN_EDIT_INFO,
  value: data,
});

const setCompanyManageInfo = data => ({
  type: SET_COMPANY_MANAGE_INFO,
  value: data
});

const setOddsDiff = oddDiffData => ({
  type: SET_ODDS_DIFF,
  value: oddDiffData,
});

const setBatchOddsList = list => ({
  type: SET_BATCH_DEFAULT_ODDS,
  value: list,
});

const setOddsSetting = list => ({
  type: SET_DEFAULT_ODDS,
  value: list,
});


const updateBatchOddsList = list => dispatch => {
  dispatch(setBatchOddsList(list));
};

const resetOddsDiffData = () => dispatch => dispatch(setOddsDiff(undefined));

const getOddsDiffUpOrgs = (userId, orgLevel = 2) => dispatch => {
  apiService.get(
    'company/GetLevel2Orgs',
    `orgId=${userId}&orgLevel=${orgLevel}`
  )
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          return dispatch(setOddsDiffUpOrgs(data.result || {}));
        }
      }
    }).catch(error => {
      Promise.reject(error);
    });
};

const getCopyUpOrgs = (userId, orgLevel = 2) => dispatch => {
  apiService.get(
    'company/GetLevel2Orgs',
    `orgId=${userId}&orgLevel=${orgLevel}`
  )
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          return dispatch(setUpOrgForCopy(data.result || {}));
        }
      }
    }).catch(error => {
      Promise.reject(error);
    });
};

const getInfo = (obj, calllback = () => { }) => dispatch => {
  apiService.get('/company/getInfo', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      calllback(false);
      dispatch(setCompanyInfo(data.result));
    }
  }).catch(error => {
    Promise.reject(error);
  });
};


const saveInfo = (obj = {}, callback = () => { }, newRequestParams = {}) => dispatch => {
  apiService.post('company/SaveInfo', {
    ...obj
  })
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        dispatch(appActions.updateModalAlert({
          visible: false
        }));
        if (data.state === 1) {
          dispatch(appActions.updateModalAlert({
            visible: true,
            type: 'success',
            data: {
              message: data.msg,
            }
          }));
          callback(false);
          return dispatch(getInfo(newRequestParams));
        } if (data?.msg) {
          dispatch(appActions.updateModalAlert({
            visible: true,
            type: 'confirmation',
            data: {
              message: data.msg,
            }
          }));
          callback(false);
          return dispatch(getInfo(newRequestParams));
        }
      }
    }).catch(error => {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      if (error.response.data.state) {
        dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'confirmation',
          data: {
            message: error.response.data.msg,
            showCancelButton: false
          }
        }));
      }
      Promise.reject(error);
    });
};

const getCompanyEditInfo = (id, callback = () => { }) => dispatch => {
  apiService.get('CompanyManage/EditInfo', new URLSearchParams({
    id
  }))
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          callback();
          return dispatch(setCompanyEditInfo(data.result));
        }
      }
    }).catch(error => {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      if (error?.response?.data?.state) {
        dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'confirmation',
          data: {
            message: error.response.data.msg,
            showCancelButton: false
          }
        }));
      }
      Promise.reject(error);
    });
};

const saveCompanyInfo = (obj, callback = () => { }) => dispatch => {
  dispatch(appActions.updateModalAlert({
    visible: true,
    type: 'loading',
  }));
  apiService.post('CompanyManage/SaveInfo', obj)
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        dispatch(appActions.updateModalAlert({
          visible: false
        }));
        if (data.state === 1) {
          callback();
          return dispatch(appActions.updateModalAlert({
            visible: true,
            type: 'success',
            data: {
              message: data.msg,
            }
          }));
        }
      }
    }).catch(error => {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      if (error?.response?.data?.state) {
        dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'confirmation',
          data: {
            message: error.response.data.msg,
            showCancelButton: false
          }
        }));
      }
      Promise.reject(error);
    });
};

const DeleteInfo = (id, callback = () => { }) => dispatch => {
  apiService.post('CompanyManage/DeleteInfo', id)
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          callback();
          return dispatch(appActions.updateModalAlert({
            visible: true,
            type: 'success',
            data: {
              message: data.msg,
            }
          }));
        }
      }
    }).catch(error => {
      callback();
      if (error?.response?.data?.state) {
        dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'confirmation',
          data: {
            message: error.response.data.msg,
            showCancelButton: false
          }
        }));
      }
      Promise.reject(error);
    });
};

const SetStatus = (obj, callback = () => { }) => dispatch => {
  apiService.post('CompanyManage/SetStatus', obj)
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          callback();
          return dispatch(appActions.updateModalAlert({
            visible: true,
            type: 'success',
            data: {
              message: data.msg,
            }
          }));
        }
      }
    }).catch(error => {
      if (error?.response?.data?.state) {
        dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'confirmation',
          data: {
            message: error.response.data.msg,
            showCancelButton: false
          }
        }));
      }
      Promise.reject(error);
    });
};

const DeleteDomainInfo = (id, callback = () => { }) => dispatch => {
  dispatch(appActions.updateModalAlert({
    visible: true,
    type: 'loading',
  }));
  apiService.post('CompanyManage/DeleteDomainInfo', id)
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        dispatch(appActions.updateModalAlert({
          visible: false
        }));
        if (data.state === 1) {
          callback();
          return dispatch(appActions.updateModalAlert({
            visible: true,
            type: 'success',
            data: {
              message: data.msg,
            }
          }));
        }
      }
    }).catch(error => {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      if (error?.response?.data?.state) {
        dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'confirmation',
          data: {
            message: error.response.data.msg,
            showCancelButton: false
          }
        }));
      }
      Promise.reject(error);
    });
};

const GetDomainNameSetting = (obj, callback = () => { }) => dispatch => {
  apiService.get('CompanyManage/GetDomainNameSetting', new URLSearchParams(obj))
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          callback();
          return dispatch(setDomainNameSetting(data.result));
        }
      }
    }).catch(error => {
      setDomainNameSetting();
      if (error?.response?.data?.state) {
        dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'confirmation',
          data: {
            message: error.response.data.msg,
            showCancelButton: false
          }
        }));
      }
      Promise.reject(error);
    });
};

const GetDomainInfo = (id, callback = () => { }) => dispatch => {
  apiService.get('CompanyManage/GetDomainInfo', new URLSearchParams({
    id
  }))
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          callback();
          return dispatch(setDomainInfo(data.result));
        }
      }
    }).catch(error => {
      setDomainInfo();
      if (error?.response?.data?.state) {
        dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'confirmation',
          data: {
            message: error.response.data.msg,
            showCancelButton: false
          }
        }));
      }
      Promise.reject(error);
    });
};

const getEditDomainInfo = (obj, callback = () => { }) => dispatch => {
  apiService.get('CompanyManage/EditDomainInfo', new URLSearchParams(obj))
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          callback();
          return dispatch(setEditDomainInfo(data.result));
        }
      }
    }).catch(error => {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      if (error?.response?.data?.state) {
        dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'confirmation',
          data: {
            message: error.response.data.msg,
            showCancelButton: false
          }
        }));
      }
      Promise.reject(error);
    });
};

const SaveDomainInfo = (obj, callback = () => { }) => dispatch => {
  dispatch(appActions.updateModalAlert({
    visible: true,
    type: 'loading',
  }));
  apiService.post('CompanyManage/SaveDomainInfo', obj)
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        dispatch(appActions.updateModalAlert({
          visible: false
        }));
        if (data.state === 1) {
          callback();
          return dispatch(appActions.updateModalAlert({
            visible: true,
            type: 'success',
            data: {
              message: data.msg,
            }
          }));
        }
      }
    }).catch(error => {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      if (error?.response?.data?.state) {
        dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'confirmation',
          data: {
            message: error.response.data.msg,
            showCancelButton: false
          }
        }));
      }
      Promise.reject(error);
    });
};

const getCompanyManageInfo = (calllback = () => { }) => dispatch => {
  apiService.get('/CompanyManage/getInfo').then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      calllback();
      dispatch(setCompanyManageInfo(data.result));
    }
  }).catch(error => {
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg,
          showCancelButton: false
        }
      }));
    }
    Promise.reject(error);
  });
};

const getGamesInfo = (lotteryType, calllback = () => { }) => dispatch => {
  apiService.get('/GamesManage/GetInfo', new URLSearchParams({
    lotteryType
  })).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      calllback();
      dispatch(setGamesInfo(data.result));
    }
  }).catch(error => {
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg,
          showCancelButton: false
        }
      }));
    }
    Promise.reject(error);
  });
};

const SaveGamesInfo = (obj, callback = () => { }) => dispatch => {
  dispatch(appActions.updateModalAlert({
    visible: true,
    type: 'loading',
  }));
  apiService.post('GamesManage/SaveInfo', obj)
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        dispatch(appActions.updateModalAlert({
          visible: false
        }));
        if (data.state === 1) {
          callback();
          return dispatch(appActions.updateModalAlert({
            visible: true,
            type: 'success',
            data: {
              message: data.msg,
            }
          }));
        }
      }
    }).catch(error => {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      if (error?.response?.data?.state) {
        dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'confirmation',
          data: {
            message: error.response.data.msg,
            showCancelButton: false
          }
        }));
      }
      Promise.reject(error);
    });
};

const getOddsDifferent = (lotteryType, callback = () => { }) => dispatch => {
  apiService.get('CompanyManage/OddsDifference', new URLSearchParams({
    lotteryType
  }))
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          callback(true);
          return dispatch(setOddsDiff(data.result || {}));
        }
      }
    }).catch(error => {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      if (error?.response?.data?.state) {
        dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'confirmation',
          data: {
            message: error.response.data.msg,
            showCancelButton: false
          }
        }));
      }
      Promise.reject(error);
    });
};

const saveOddsDifference = (obj = {}, callback = () => { }) => dispatch => {
  apiService.post('CompanyManage/SaveOddsDifference', {
    ...obj
  })
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          dispatch(appActions.updateModalAlert({
            visible: false
          }));
          dispatch(appActions.updateModalAlert({
            visible: true,
            type: 'success',
            data: {
              message: data.msg,
            }
          }));
          return callback(true);
        }
      }
    }).catch(error => {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      callback(false);
      if (error.response.data.state) {
        dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'confirmation',
          data: {
            message: error.response.data.msg
          }
        }));
      }
      Promise.reject(error);
    });
};


const GetSystemDefaultOdds = (obj, calllback = () => { }) => dispatch => {
  apiService.get('/CompanyManage/GetSystemDefaultOdds', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      calllback(false);
      dispatch(setOddsSetting(data.result));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    calllback(false);
    Promise.reject(error);
  });
};

const SaveOdds = (obj, calllback = () => { }) => dispatch => {
  apiService.post('/CompanyManage/SaveDefaultOdds', obj).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        dispatch(appActions.updateModalAlert({
          visible: false
        }));
        calllback();
      }
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};

const QuickSaveOdds = (obj, calllback = () => { }) => dispatch => {
  apiService.post('/CompanyManage/quicksaveodds', obj).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        dispatch(appActions.updateModalAlert({
          visible: false
        }));
        calllback();
      }
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  }).finally(() => {
    dispatch(updateBatchOddsList());
  });
};

const GenerateALSKey = (id, calllback = () => { }) => dispatch => {
  apiService.post('/CompanyManage/GenerateALSKey', id).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        return calllback(data.result);
      }
    }
  }).catch(error => {
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg,
          showCancelButton: false
        }
      }));
    }
    Promise.reject(error);
  });
};

export default {
  getOddsDiffUpOrgs,
  getInfo,
  saveInfo,
  getCompanyEditInfo,
  saveCompanyInfo,
  DeleteInfo,
  SetStatus,
  DeleteDomainInfo,
  GetDomainInfo,
  getEditDomainInfo,
  SaveDomainInfo,
  getCompanyManageInfo,
  getGamesInfo,
  SaveGamesInfo,
  getOddsDifferent,
  resetOddsDiffData,
  saveOddsDifference,
  GetSystemDefaultOdds,
  QuickSaveOdds,
  SaveOdds,
  getCopyUpOrgs,
  GenerateALSKey,
  GetDomainNameSetting
};