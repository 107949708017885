import { useEffect, useState } from 'react';

import { Button, Checkbox, Input, InputNumber, Radio, Select } from 'antd';
import { Label } from 'reactstrap';
import { MdArrowBack } from 'react-icons/md';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { PlusCircleOutlined } from '@ant-design/icons';

import { AgentDataTable, CommonHeader } from '../..';
import { agentActions, commonActions, companyActions } from '../../../redux/actions';
import Logo from '../../../assets/image/defaultLogo.png';

import './styles.scss';

const mapStateToProps = state => ({
  allCompanyList: state.commonReducers.allCompanyList,
  companyEditInfo: state.companyReducers.companyEditInfo,
  companyManageInfo: state.companyReducers.companyManageInfo,
  lotteryList: state.commonReducers.lotteryList,
});

const mapDispatchToProps = dispatch => ({
  DeleteInfo: (id, callback) => dispatch(companyActions.DeleteInfo(id, callback)),
  getCompanyManageInfo: callback => dispatch(companyActions.getCompanyManageInfo(callback)),
  GetCompanyList: (callback, obj) => dispatch(commonActions.GetCompanyList(callback, obj)),
  getCompanyEditInfo: (id, callback) => dispatch(companyActions.getCompanyEditInfo(id, callback)),
  saveCompanyInfo: (obj, callback) => dispatch(companyActions.saveCompanyInfo(obj, callback)),
  SetStatus: (obj, callback) => dispatch(companyActions.SetStatus(obj, callback)),
  getLoginAs: (obj, callback) => dispatch(agentActions.getLoginAs(obj, callback)),
  GenerateALSKey: (id, callback) => dispatch(companyActions.GenerateALSKey(id, callback)),
});

const { Option } = Select;

const CompanySetting = ({ GenerateALSKey, getLoginAs, getCompanyManageInfo, companyManageInfo, SetStatus, lotteryList, saveCompanyInfo, GetCompanyList, allCompanyList, getCompanyEditInfo, companyEditInfo, DeleteInfo }) => {
  const { t } = useTranslation();
  const [_isQuerying, setIsQuerying] = useState(false);
  const [_pageFlag, setPageFlag] = useState(0);

  const [_activeId, setActiveId] = useState();
  const [selectedMap, setSelectedMap] = useState(new Map());
  const [totalLottery, setTotalLottery] = useState(new Map());

  const [isUpdate, setisUpdate] = useState();
  const [active, setactive] = useState(0);
  const [defaultAdmin, setdefaultAdmin] = useState('');
  const [enableEarnOdds, setenableEarnOdds] = useState(0);
  const [logo, setlogo] = useState('');
  const [name, setname] = useState('');
  const [password, setpassword] = useState('');
  const [companyCode, setcompanyCode] = useState('');
  const [copyCompanyOdds, setcopyCompanyOdds] = useState('-1');
  const [validType, setvalidType] = useState(0);
  const [accountOption, setaccountOption] = useState(1);
  const [templateAgent, settemplateAgent] = useState('');
  const [templateMember, settemplateMember] = useState('');
  const [templateMemberStyle, settemplateMemberStyle] = useState('blue');
  const [templateUpdate, settemplateUpdate] = useState(0);
  const [urlLotteryResults, seturlLotteryResults] = useState('');
  const [urlSearchEngine, seturlSearchEngine] = useState('');
  const [alsKey, setAlsKey] = useState('');
  const [maxOnlineUser, setMaxOnlineUser] = useState(0);

  useEffect(() => {
    setIsQuerying(true);
    getCompanyManageInfo();
    GetCompanyList(() => setIsQuerying(false), {
      levelValue: 1
    });
  }, []);

  const Remove = id => {
    DeleteInfo(id, () => {
      setIsQuerying(true); GetCompanyList(() => setIsQuerying(false), {
        levelValue: 1
      });
    });
  };

  useEffect(() => {
    let { company } = companyEditInfo || {};
    if (!isUpdate) {
      company = {};
    }
    setMaxOnlineUser(company?.maxOnlineUser ?? 0);
    setactive(company?.active ?? 0);
    setdefaultAdmin(company?.defaultAdmin ?? '');
    setenableEarnOdds(company?.enableEarnOdds ?? 0);
    setlogo(company?.logo ?? '');
    setname(company?.name ?? '');
    setcompanyCode(company?.companyCode ?? '');
    setcopyCompanyOdds(company?.copyCompanyOdds ?? '-1');
    setvalidType(company?.validType ?? 0);
    setaccountOption(company?.accountOption ?? 0);

    seturlLotteryResults(company?.urlLotteryResults ?? '');
    seturlSearchEngine(company?.urlSearchEngine ?? '');

    settemplateAgent(company?.templateAgent ?? '');
    settemplateMember(company?.templateMember ?? '');
    setAlsKey(company?.alsKey ?? '');

    if (lotteryList) {
      const mapAll = new Map();
      const mapSelected = new Map();
      const allLs = [].concat(lotteryList);
      if (isUpdate && companyEditInfo?.lotList) {
        const ls = [].concat(companyEditInfo.lotList);
        for (let i = 0; i < ls.length; i++) {
          const temp = allLs.find(x => (x.code === ls[i]));
          if (temp) {
            if (mapSelected.has(temp.categoryCode)) {
              const tempArr = mapSelected.get(temp.categoryCode);
              mapSelected.set(temp.categoryCode, tempArr.concat(temp));
            } else {
              mapSelected.set(temp.categoryCode, [].concat(temp));
            }
          }
        }
      }
      allLs.forEach(item => {
        if (mapAll.has(item.categoryCode)) {
          const temp = mapAll.get(item.categoryCode);
          mapAll.set(item.categoryCode, temp.concat(item));
        } else {
          mapAll.set(item.categoryCode, [].concat(item));
        }
      });
      setTotalLottery(mapAll);
      setSelectedMap(mapSelected);
    }
  }, [companyEditInfo, isUpdate]);

  const toggleAllSelection = code => {
    const temp = new Map(JSON.parse(
      JSON.stringify(Array.from(selectedMap))
    ));
    if (temp.has(code)) {
      temp.delete(code);
    } else {
      const tempItem = totalLottery.get(code);
      temp.set(code, [].concat(tempItem));
    }
    setSelectedMap(temp);
  };

  const toggleSelection = item => {
    const temp = new Map(JSON.parse(
      JSON.stringify(Array.from(selectedMap))
    ));
    if (temp.has(item.categoryCode)) {
      if (temp.get(item.categoryCode).find(x => x.code === item.code)) {
        temp.set(item.categoryCode, temp.get(item.categoryCode).filter(x => x.code !== item.code));
      } else {
        temp.set(item.categoryCode, temp.get(item.categoryCode).concat(item));
      }
    } else {
      temp.set(item.categoryCode, [].concat(item));
    }
    setSelectedMap(temp);
  };

  const save = validTypeVal => {
    const lotteryTypes = [];
    if (selectedMap.size > 0) {
      selectedMap.forEach(item => {
        item.forEach(sub => {
          lotteryTypes.push(sub.code);
        });
      });
    }
    const obj = {
      id: isUpdate ? companyEditInfo.company.id : '',
      isUpdate,
      name,
      defaultAdmin,
      active,
      accountOption,
      password,
      logo,
      companyCode,
      maxOnlineUser,
      urlLotteryResults,
      urlSearchEngine,
      templateMember,
      templateMemberStyle,
      templateAgent,
      templateUpdate,
      validType: validTypeVal === 0 ? validTypeVal : validType,
      enableEarnOdds,
      alsKey,

      lotteryTypes
    };
    saveCompanyInfo(obj, () => {
      setIsQuerying(true);
      GetCompanyList(() => { setIsQuerying(false); setPageFlag(0); }, {
        levelValue: 1
      });
    });
  };

  const control = [
    data => (
      <>
        <Button
          className="controlButton mx-1"
          onClick={() => getCompanyEditInfo(data.companyId, () => { setisUpdate(true); setPageFlag(2); })}
        >
          修改
        </Button>
        <Button className="controlButton mx-1" onClick={() => Remove(data.companyId)}>删除</Button>
        <Button className="controlButton mx-1" onClick={() => openNewWindow(data.defaultAdmin)}>登入</Button>
      </>
    ),
  ];


  const changeState = (username, status) => {
    const obj = {
      username,
      usertype: 'Company',
      status
    };
    SetStatus(obj, () => {
      setIsQuerying(true);
      GetCompanyList(() => { setIsQuerying(false); }, {
        levelValue: 1
      });
    });
  };

  const openNewWindow = loginId => {
    if (companyManageInfo) {
      const { orgList } = companyManageInfo;
      const item = [].concat(orgList).find(x => x.loginId === loginId);
      const callback = () => {
        window.open(`${window.location.origin}/?loginId=${loginId}&uid=${item.organizationId}&data=${String(Date.now()).substring(0, 6) + item.password}`);
      };
      getLoginAs({
        loginId, uid: item.organizationId, data: `${String(Date.now()).substring(0, 6) + item.password}`
      }, callback);
    }
  };

  const onClickAslKey = id => {
    GenerateALSKey(id, data => setAlsKey(data));
  };

  const imageUploaded = async e => {
    const convertBase64 = file => new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = error => {
        reject(error);
      };
    });

    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setlogo(base64);
  };

  return (
    <div className="CompanySettingWrapper">
      <CommonHeader
        headerName={(
          <div className="d-flex align-items-center">
            {_pageFlag !== 0 && (
            <div className="d-flex align-items-center me-2 backIcon" onClick={() => { setisUpdate(false); setPageFlag(0); }}>
              <MdArrowBack className="h5 mb-0 text-white" />
              返回
            </div>
            )}
            {`${t('COMPANY_SETTING')} ${_pageFlag === 0 ? '' : _pageFlag === 1 ? '/ 新增公司' : '/ 编辑公司'}`}
          </div>
)}
      />
      {_pageFlag === 0 && (
      <>
        <div className="addNewGameBox my-3">
          <div className="operationBtn add  d-flex align-items-center" onClick={() => setPageFlag(1)}>
            <PlusCircleOutlined />
            <span className="ms-2">新增总公司</span>
          </div>
        </div>
        <div className="mx-0 bg-white mb-3 px-0 border-1">
          <AgentDataTable
            isQuerying={_isQuerying}
            tableControl={control}
            activeControl={_activeId}
            tableHeaderTitle={
            [{
              label: '公司名称',
            }, {
              label: '公司Logo',
            }, {
              label: '公司账号',
            }, {
              label: '生肖',
            }, {
              label: '是否允许备份',
            }, {
              label: '是否允许看盘',
            }, {
              label: '最低限额',
            }, {
              label: '新增日期',
            }, {
              label: '状态',
            }]
          }
            tableHeaderStyle={
            []
          }
            tableData={allCompanyList || Array(10).fill({})}
            tableBodyRowProperty={
            [
              data => (<Label className="d-flex justify-content-center text-primary detailLink" onClick={() => setActiveId(data.companyId)}>{data.name}</Label>),
              data => (
                <Label className="d-flex justify-content-center align-items-center">
                  <img
                    className="companyLogo"
                    src={data.logo || Logo}
                    alt="logo"
                    onError={ele => ele.target.src = Logo}
                  />
                </Label>
              ),
              data => (<Label className="d-flex justify-content-center">{data.defaultAdmin}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.shengXiao}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.allowBackup === 1 ? '允许' : '不允许'}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.allowLookup === 1 ? '允许' : '不允许'}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.minLimit}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.createDate}</Label>),
              data => (
                <Label className="d-flex justify-content-center mousePointIn">
                  <div className={`statusBtn ${data.active === 1 ? 'activeBtn' : 'nonActiveBtn'}`}>
                    {data.active === 1 ? '启用' : '停用'}
                  </div>
                  <div className={`shiftBtn ${data.active === 1 ? 'nonActiveBtn' : 'activeBtn'}`} onClick={() => changeState(data.defaultAdmin, data.active === 1 ? 0 : 1)}>
                    {data.active === 1 ? '停用' : '启用'}
                  </div>
                </Label>
              )
            ]
          }
          />
        </div>
      </>
      )}
      {_pageFlag !== 0 && (
      <div className="level1CompanyEditWrapper">
        <div className="sectionWrapper">
          <div className="tableTitleStyle">总公司资料</div>
          <div className="tableSection labelCellBackground">
            <div className="rowStyle">
              <div>公司名称</div>
              <div>
                <Input
                  className="w-50"
                  value={name}
                  onChange={({ target: { value: text } }) => setname(text)}
                />
              </div>
            </div>
            <div className="rowStyle">
              <div>账号</div>
              <div>
                <Input
                  disabled={isUpdate}
                  className="w-50"
                  value={defaultAdmin}
                  onChange={({ target: { value: text } }) => setdefaultAdmin(text.trim().toLowerCase())}
                />
              </div>
            </div>
            <div className="rowStyle">
              <div>帐号状态</div>
              <div>
                <Radio.Group onChange={({ target: { value: text } }) => setactive(text)} value={active}>
                  <Radio value={1}>启用</Radio>
                  <Radio value={0}>停用</Radio>
                </Radio.Group>
              </div>
            </div>
            <div className="rowStyle">
              <div>帐户类型</div>
              <div>
                <Radio.Group disabled={isUpdate} onChange={({ target: { value: text } }) => setaccountOption(text)} value={accountOption}>
                  <Radio value={0}>单帐户额度</Radio>
                  <Radio value={1}>多帐户额度</Radio>
                </Radio.Group>
              </div>
            </div>
            <div className="rowStyle">
              <div>登入密码</div>
              <div>
                <Input className="w-50" value={password} onChange={({ target: { value: text } }) => setpassword(text.trim())} />
                <span>(默认123456)</span>
              </div>
            </div>
            <div className="rowStyle">
              <div>公司Logo</div>
              <div>
                {logo
                && <img className="companyLogoPreview me-2" src={logo} alt="" />}
                <label htmlFor="files" className="operationBtn d-flex align-items-center">
                  上传
                  <input className="uploadBtn" type="file" name="" id="files" onChange={imageUploaded} />
                </label>
                {logo
                && <div className="operationBtn cancel ms-2" onClick={() => setlogo('')}>删除</div>}
              </div>
            </div>
            <div className="rowStyle">
              <div>公司Code</div>
              <div>
                <Input className="w-50" value={companyCode} onChange={({ target: { value: text } }) => setcompanyCode(text.trim())} />
              </div>
            </div>
            <div className="rowStyle">
              <div>开奖网</div>
              <div>
                <Input placeholder="如：http://www.baidu.com" className="w-50" value={urlLotteryResults} onChange={({ target: { value: text } }) => seturlLotteryResults(text.trim())} />
                <span>(留空则不显示)</span>
              </div>
            </div>
            <div className="rowStyle">
              <div>导航网址</div>
              <div>
                <Input placeholder="如：http://www.baidu.com" className="w-50" value={urlSearchEngine} onChange={({ target: { value: text } }) => seturlSearchEngine(text.trim())} />
                <span>(留空则不显示)</span>
              </div>
            </div>
            <div className="rowStyle">
              <div>会员模版</div>
              <div>
                <Input className="w-50" value={templateMember} onChange={({ target: { value: text } }) => settemplateMember(text.trim())} />
              </div>
            </div>
            <div className="rowStyle">
              <div>会员样式</div>
              <div>
                <Select value={templateMemberStyle} onChange={val => settemplateMemberStyle(val)}>
                  {
                        [{
                          label: '蓝色', value: 'blue'
                        }, {
                          label: '绿色', value: 'green'
                        }, {
                          label: '红色', value: 'red'
                        }, {
                          label: '黄色', value: 'yellow'
                        }].map(el => <Option key={el.value} value={el.value}>{el.label}</Option>)
                    }
                </Select>
              </div>
            </div>
            <div className="rowStyle">
              <div>代理模版</div>
              <div>
                <Input className="w-50" value={templateAgent} onChange={({ target: { value: text } }) => settemplateAgent(text.trim())} />
              </div>
            </div>
            <div className="rowStyle">
              <div>同时更新上述模版信息</div>
              <div>
                <Select value={templateUpdate} onChange={val => settemplateUpdate(val)}>
                  {
                        [{
                          label: '同时更新模板', value: 1
                        }, {
                          label: '不更新模板', value: 0
                        }].map(el => <Option key={el.value} value={el.value}>{el.label}</Option>)
                    }
                </Select>
              </div>
            </div>
            <div className="rowStyle">
              <div>退水赔率差</div>
              <div>
                <Select value={copyCompanyOdds} onChange={val => setcopyCompanyOdds(val)}>
                  {
                      allCompanyList && [{
                        defaultAdmin: '系统默认', companyId: '-1'
                      }].concat(allCompanyList).map(el => <Option key={el.companyId} value={el.companyId}>{`从${el.defaultAdmin}复制`}</Option>)
                    }
                </Select>
              </div>
            </div>
            <div className="rowStyle">
              <div>MFA验证</div>
              <div>
                {
                    validType === 1 ? (
                      <div
                        className="operationBtn reset"
                        onClick={() => save(0)}
                      >
                        重置
                      </div>
                    ) : '未设置'
                }
              </div>
            </div>
            <div className="rowStyle">
              <div>代理赚赔功能</div>
              <div>
                <Radio.Group onChange={({ target: { value: text } }) => setenableEarnOdds(text)} value={enableEarnOdds}>
                  <Radio value={1}>启动</Radio>
                  <Radio value={0}>禁用</Radio>
                </Radio.Group>
              </div>
            </div>
            <div className="rowStyle">
              <div>最大在线人数</div>
              <div>
                <InputNumber
                  min={0}
                  step={1}
                  className="w-50"
                  value={maxOnlineUser}
                  onChange={value => setMaxOnlineUser(value ?? 0)}
                  precision={0}
                />
              </div>
            </div>
            {isUpdate && companyEditInfo.company.id
            && (
            <div className="rowStyle">
              <div>记录接口密钥</div>
              <div>
                <Input className="w-50" value={alsKey} disabled />
                <div
                  className="operationBtn ms-2"
                  onClick={() => onClickAslKey(companyEditInfo.company.id)}
                >
                  生成
                </div>
                <div
                  className="operationBtn reset ms-2"
                  onClick={() => setAlsKey('')}
                >
                  删除
                </div>
              </div>
            </div>
            )}

          </div>
        </div>

        <div className="sectionWrapper">
          <div className="tableTitleStyle">彩种选择</div>
          <div className="tableSection labelCellBackground">
            {Array.from(totalLottery.values()).map(item => (
              <div className="rowStyle">
                <div>
                  <span className="me-3">{item[0].categoryName}</span>
                  <Checkbox onChange={() => toggleAllSelection(item[0].categoryCode)} checked={(selectedMap.size > 0 && selectedMap.has(item[0].categoryCode)) ? (selectedMap.get(item[0].categoryCode).length === totalLottery.get(item[0].categoryCode).length) : false}>所有</Checkbox>
                </div>
                <div>
                  {
               item.map(ele => <Checkbox checked={selectedMap.size > 0 ? selectedMap.get(ele.categoryCode)?.find(x => x.code === ele.code) : false} onChange={() => toggleSelection(ele)} className="lotterySelection my-1">{ele.lotteryName}</Checkbox>)
             }
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="d-flex justify-content-center py-3">
          <div className="operationBtn" onClick={() => save()}>保存</div>
          <div className="operationBtn ms-3 cancel" onClick={() => { setPageFlag(0); setisUpdate(false); }}>取消</div>
        </div>
      </div>
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanySetting);