import { useEffect, useState } from 'react';

import { Input, Select } from 'antd';
import { Label } from 'reactstrap';
import { MdArrowBack } from 'react-icons/md';
import { connect } from 'react-redux';

import moment from 'moment';

import { useTranslation } from 'react-i18next';

import { AgentDataTable, CommonHeader } from '../..';
import { companyActions } from '../../../redux/actions';

import './styles.scss';

const mapStateToProps = state => ({
  domainNameSetting: state.companyReducers.domainNameSetting,
  domainEditInfo: state.companyReducers.domainEditInfo,
});

const mapDispatchToProps = dispatch => ({
  GetDomainNameSetting: (obj, callback) => dispatch(companyActions.GetDomainNameSetting(obj, callback)),
  getEditDomainInfo: (obj, callback) => dispatch(companyActions.getEditDomainInfo(obj, callback)),
  SaveDomainInfo: (obj, callback) => dispatch(companyActions.SaveDomainInfo(obj, callback)),
  DeleteDomainInfo: (id, callback) => dispatch(companyActions.DeleteDomainInfo(id, callback)),
});

const DominManage = ({ domainNameSetting, domainEditInfo, GetDomainNameSetting, getEditDomainInfo, SaveDomainInfo, DeleteDomainInfo }) => {
  const { t } = useTranslation();
  const [_isQuerying, setIsQuerying] = useState(false);
  const [_pageFlag, setPageFlag] = useState(0);

  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(15);

  const [webType, setwebType] = useState('-1');
  const [level, setLevel] = useState('-1');

  // detail
  const [selectedItem, setSelectedItem] = useState({});

  useEffect(() => {
    setIsQuerying(true);
    const _obj = {
      page,
      rows,
      level,
      webType
    };
    GetDomainNameSetting(_obj, setIsQuerying);
  }, []);

  const saveAndEdit = () => {
    if (_pageFlag === 1) {
      setPageFlag(0);
      SaveDomainInfo({
        ...selectedItem?.domain || {},
        companyId: selectedItem?.company?.companyId
      }, onSearchClick);
    }
    if (_pageFlag === 2) {
      SaveDomainInfo({
        ...selectedItem?.domain || {},
        companyId: selectedItem?.company?.companyId
      }, onSearchClick);
    }
  };

  const Remove = id => {
    DeleteDomainInfo(id, () => {
      setIsQuerying(true);
      onSearchClick();
    });
  };


  const onSearchClick = () => {
    setIsQuerying(true);
    const _obj = {
      page: 1,
      rows: 15,
      webType,
      level,
    };
    GetDomainNameSetting(_obj, setIsQuerying);
    setPage(1);
    setRows(15);
  };

  useEffect(() => {
    if (_pageFlag === 1 || _pageFlag === 0) {
      setSelectedItem({
        webType: '0'
      });
    }
    if (_pageFlag === 2 && domainEditInfo) {
      setSelectedItem(domainEditInfo);
    }
  }, [_pageFlag, domainEditInfo]);

  const typeSelection = domainNameSetting?.typeSelection || {};
  const levelOptions = [{
    label: '全部', value: '-1'
  }].concat(
    Object.keys(typeSelection).map(key => ({
      label: typeSelection[key], value: key
    }))
  );

  const webTypeOptions = [{
    label: '代理端', value: 0
  }, {
    label: '会员端', value: 1
  }];

  return (
    <div className="DominManageWrapper">
      <CommonHeader
        headerName={(
          <div className="d-flex align-items-center">
            {_pageFlag !== 0 && (
              <div className="d-flex align-items-center me-2 backIcon" onClick={() => setPageFlag(0)}>
                <MdArrowBack className="h5 mb-0 text-white" />
                返回
              </div>
            )}
            {`${t('DOMAIN_MANAGEMENT')} ${_pageFlag === 0 ? '' : _pageFlag === 1 ? '/ 新增地址' : '/ 编辑地址'}`}
          </div>
        )}
      />
      {_pageFlag === 0 && (
        <>
          <div className="addNewGameBox my-3">
            <div className="mx-2 d-flex justify-content-center align-items-center">
              <span className="text-max mx-1 d-flex justify-content-center align-items-center">用户类型</span>
              <Select
                options={levelOptions}
                className="ms-2"
                value={level}
                onChange={e => setLevel(e)}
              />
            </div>
            <div className="mx-2 d-flex justify-content-center align-items-center">
              <span className="text-max mx-1 d-flex justify-content-center align-items-center">站点类型</span>
              <Select
                options={[{
                  label: '全部', value: '-1'
                }, {
                  label: '代理端', value: '0'
                }, {
                  label: '会员端', value: '1'
                }]}
                className="ms-2"
                value={webType}
                onChange={e => setwebType(e)}
              />
            </div>
            <div className="operationBtn mx-2 add" onClick={() => onSearchClick()}>查询</div>
          </div>

          <div className="mx-0 bg-white mb-3 px-0 border-1">
            <AgentDataTable
              isQuerying={_isQuerying}
              tableHeaderTitle={
                [{
                  label: '序号',
                }, {
                  label: '名称',
                }, {
                  label: '账号',
                }, {
                  label: '站点类型',
                }, {
                  label: '域名',
                }, {
                  label: '创建时间',
                }, {
                  label: t('OPERATION'),
                }]
              }
              tableHeaderStyle={
                []
              }
              tableData={domainNameSetting ? domainNameSetting.list : []}
              tableBodyRowProperty={
                [
                  data => (<Label className="d-flex justify-content-center">{data.order}</Label>),
                  data => (
                    <Label className="d-flex justify-content-center">
                      {
                        data.name
                      }
                    </Label>
                  ),
                  data => (
                    <Label
                      className="d-flex justify-content-center"
                    >
                      {
                        `${data.loginId} ${data.position && `(${data.position})`}`
                      }
                    </Label>
                  ),
                  data => (
                    <Label
                      className="d-flex justify-content-center"
                    >
                      {
                        webTypeOptions.find(i => Number(i.value) === data.webType)?.label
                      }
                    </Label>
                  ),
                  data => (<Label className="d-flex justify-content-center">{data.domain}</Label>),
                  data => (<Label className="d-flex justify-content-center">{data.createDate && moment.unix(data.createDate).format('YYYY-MM-DD:HH:mm:ss')}</Label>),
                  data => (
                    <Label className="d-flex justify-content-center align-items-center">
                      <div
                        className="operationBtn mx-2"
                        onClick={() => {
                          getEditDomainInfo({
                            companyId: data.companyId,
                            domainId: data.id
                          }, () => setPageFlag(2));
                        }}
                      >
                        编辑
                      </div>
                      <div className="operationBtn cancel mx-2" onClick={() => { Remove(data.id); }}>删除</div>
                    </Label>
                  )
                ]
              }
            />
          </div>
        </>
      )}
      {_pageFlag !== 0 && (
        <>
          <div className="my-3 thirdPartyGamesTable">
            <div className="thirdPartyGamesTitle">{_pageFlag === 1 ? '新增地址' : '编辑地址'}</div>
            <div className="thirdPartyGamesRow">
              <div>总代名称: </div>
              <div>
                <Input
                  disabled
                  value={selectedItem?.company?.name}
                  placeholder="请输入内容"
                />
              </div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>账号: </div>
              <div><Input value={selectedItem?.domain?.loginId} disabled placeholder="请输入内容" /></div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>站点: </div>
              <div>
                <Select
                  className="dropdownSelectStyle"
                  options={webTypeOptions}
                  value={selectedItem?.domain?.webType}
                  onChange={v => setSelectedItem({
                    ...selectedItem,
                    domain: {
                      ...selectedItem?.domain || {},
                      webType: v
                    }
                  })}
                />
              </div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>域名: </div>
              <div>
                <Input
                  value={selectedItem?.domain?.domain}
                  onChange={e => setSelectedItem({
                    ...selectedItem,
                    domain: {
                      ...selectedItem?.domain || {},
                      domain: e.target.value
                    }
                  })}
                  placeholder="请输入内容"
                />
              </div>
            </div>
          </div>
          <div className="thirdPartyGamesFooter">
            <div className="operationBtn editAndSave me-3" onClick={() => saveAndEdit()}>{t('CONFIRM')}</div>
            <div className="operationBtn cancel me-3" onClick={() => setPageFlag(0)}>{t('CANCEL')}</div>
          </div>
        </>
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DominManage);