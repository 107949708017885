import { useEffect, useState } from 'react';

import { Checkbox, Input, InputNumber, Radio, Select } from 'antd';
import { connect } from 'react-redux';

import { useTranslation } from 'react-i18next';

import { MdArrowBack } from 'react-icons/md';

import { appActions, followBetActions } from '../../../redux/actions';

import CommonHeader from '../../Content/CommonHeader';
import './styles.scss';

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  SaveAgentConfig: (obj, callback) => dispatch(followBetActions.SaveAgentConfig(obj, callback)),
  GetSettingCodeList: (lotteryType, callback) => dispatch(followBetActions.GetSettingCodeList(lotteryType, callback)),
  AgentConfig: (configId, callback) => dispatch(followBetActions.AgentConfig(configId, callback)),
});

const FollowBetAddEdit = ({
  setPageName,
  data,
  updateModalAlert,
  AgentConfig,
  GetSettingCodeList,
  SaveAgentConfig
}) => {
  const { t } = useTranslation();
  const originalObj = {
    id: '',
    lotteryTypeOption: 0,
    followedLoginId: '',
    followLoginId: '',
    ip: '',
    followedServiceId: '',
    followServiceId: '',
  };
  const [_info, setInfo] = useState({
    ...originalObj
  });
  const [settingCodeList, setSettingCodeList] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [allLottery, setAllLottery] = useState(new Map());

  const [checkedList, setCheckedList] = useState(new Map()); // selected lottery
  const [settingCode, setSettingCode] = useState([]);

  const getAgentConfigData = () => {
    const callback = data => {
      setInfo({
        ...originalObj,
        ...(data?.config ?? {}),
        lotteryList: data?.config?.lotteryList.map(x => ({
          ...x, createBy: '', updateBy: ''
        }))
      });
      if (data?.config?.settingCode) {
        setSettingCode(data?.config?.settingCode.split(',')); // user select settingcode
      }
      setServiceList(data?.serviceList ?? []); // default dropdownlist
      const lotCategoryList = data?.lotCategoryList ?? [];
      const lotteryList = data?.lotteryList ?? [];
      const SelectLottery = data?.config?.lotteryList ?? [];

      const tempMap = new Map(checkedList);
      SelectLottery.forEach(item => {
        const temp = lotteryList.find(x => x.id === item.lotteryType);
        tempMap.set(temp.categoryCode, [item.lotteryType].concat(tempMap.get(temp.categoryCode) ?? []));
      });
      setCheckedList(tempMap);

      const temp = new Map();
      lotCategoryList.forEach(element => {
        temp.set(element.categoryCode, []);
      });
      lotteryList.forEach(item => {
        const tempItem = temp.get(item.categoryCode);
        if (tempItem) {
          temp.set(item.categoryCode, tempItem.concat(item));
        }
      });
      setAllLottery(temp);
    };
    AgentConfig(data?.id ?? '', callback);
  };

  useEffect(() => {
    getAgentConfigData();
  }, []);

  useEffect(() => {
    if (_info?.lotteryTypeOption === 1) {
      let _list = [];
      const callback = data => {
        setSettingCodeList(data ?? []);
      };
      [...checkedList.values()].forEach(x => _list = _list.concat(x));
      if (_list.length === 1) {
        GetSettingCodeList(_list[0], callback);
      } else {
        setSettingCodeList([]);
      }
    }
  }, [_info, checkedList]);

  const saveButton = () => {
    let lots = [];
    [...checkedList.values()].forEach(x => {
      lots = lots.concat(x);
    });
    const saveDto = {
      config: {
        memberType: _info?.memberType,
        followType: _info?.followType,
        agentLv: _info?.agentLv,
        multiple: _info?.multiple,
        followedMemberId: _info?.followedMemberId,
        followedLoginId: _info?.followedLoginId,
        followedCompanyId: _info?.followedCompanyId,
        followedCompanyName: _info?.followedCompanyName,
        followedServiceId: _info?.followedServiceId,
        followedUrl: _info?.followedUrl,
        followedSericeName: _info?.followedSericeName,

        followMemberId: _info?.followMemberId,
        followLoginId: _info?.followLoginId,
        followCompanyId: _info?.followCompanyId,
        followCompanyName: _info?.followCompanyName,
        followServiceId: _info?.followServiceId,
        followUrl: _info?.followUrl,
        followSericeName: _info?.followSericeName,

        lotteryTypeOption: _info?.lotteryTypeOption,
        apiUrl: _info?.apiUrl,
        approved: _info?.approved,
        id: _info?.id,
        ip: _info?.ip,
        settingCode: settingCode.toString(),
        companyZcAmout: _info?.companyZcAmout,
        localDb: _info?.localDb,

        lotteryList: _info.lotteryList.map(x => ({
          followId: x.followId, lotteryType: x.lotteryType, wholeDay: x.wholeDay
        }))
      },
      lots
    };
    updateModalAlert({
      type: 'loading',
      visible: true,
    });
    SaveAgentConfig(saveDto);
  };

  const onInfoValueChange = (key, value) => {
    setInfo({
      ..._info, [`${key}`]: value
    });
  };

  return (
    <div className="FollowBetAddEditAddEditWrapper">
      <CommonHeader headerName={(
        <div className="d-flex align-items-center">
          <div className="d-flex align-items-center me-2 backIcon" onClick={() => setPageName('FollowBetList')}>
            <MdArrowBack className="h5 mb-0 text-white" />
            返回
          </div>
          <div>{t('FOLLOW_BET_SETTING')}</div>
        </div>
      )}
      />
      <div className="mx-0 bg-white my-2  border-1">
        <div className="m-0 my-1 p-0 d-flex">
          <table className="p-3 m-0 w-100" cellSpacing="0" cellPadding="0" border="0">
            <tbody>
              <tr>
                <td className="titleHeader" colSpan={2}>
                  {data?.id ? '编辑跟单设置' : '新增跟单设置'}
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">跟单类型</td>
                <td>
                  <Radio.Group
                    value={_info?.followType}
                    onChange={({ target: { value } }) => onInfoValueChange('followType', value)}
                  >
                    {
                      [{
                        label: '正跟', value: 0
                      }, {
                        label: '反跟', value: 1
                      }].map(item => <Radio value={item.value}>{item.label}</Radio>)
                    }
                  </Radio.Group>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">跟单比例</td>
                <td>
                  <div className="d-flex align-items-center">
                    <InputNumber
                      className="boxWidth"
                      value={_info?.multiple}
                      onChange={value => onInfoValueChange('multiple', value)}
                    />
                    <div className="mx-2">%</div>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">金额类型</td>
                <td>
                  <Radio.Group
                    value={_info?.companyZcAmout}
                    onChange={({ target: { value } }) => onInfoValueChange('companyZcAmout', value)}
                  >
                    {
                      [{
                        label: '下注金额', value: 0
                      }, {
                        label: '公司占成金额', value: 1
                      }].map(item => <Radio value={item.value}>{item.label}</Radio>)
                    }
                  </Radio.Group>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">被跟单账号网站</td>
                <td>
                  <Select
                    placeholder="请选择"
                    value={_info?.followedServiceId}
                    className="boxWidth"
                    onChange={value => onInfoValueChange('followedServiceId', value)}
                    options={serviceList.map(x => ({
                      value: x.id, label: x.serviceName
                    }))}
                  />
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">被跟单账号类型</td>
                <td>
                  <Radio.Group
                    value={_info?.memberType}
                    onChange={({ target: { value } }) => onInfoValueChange('memberType', value)}
                  >
                    {
                      [{
                        label: '会员', value: 0
                      }, {
                        label: '代理', value: 1
                      }].map(item => <Radio value={item.value}>{item.label}</Radio>)
                    }
                  </Radio.Group>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">被跟单账号</td>
                <td>
                  <div>
                    <Input
                      className="boxWidth"
                      value={_info?.followedLoginId || ''}
                      onChange={({ target: { value } }) => onInfoValueChange('followedLoginId', value)}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">跟单账号网站</td>
                <td>
                  <Select
                    placeholder="请选择"
                    value={_info?.followServiceId}
                    className="boxWidth"
                    onChange={value => onInfoValueChange('followServiceId', value)}
                    options={serviceList.map(x => ({
                      value: x.id, label: x.serviceName
                    }))}
                  />
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">跟单账号</td>
                <td>
                  <div>
                    <Input
                      className="boxWidth"
                      value={_info?.followLoginId || ''}
                      onChange={({ target: { value } }) => onInfoValueChange('followLoginId', value)}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">跟单IP</td>
                <td>
                  <div>
                    <Input
                      className="boxWidth"
                      value={_info?.ip || ''}
                      onChange={({ target: { value } }) => onInfoValueChange('ip', value)}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">彩种选项</td>
                <td>
                  <Radio.Group
                    value={_info?.lotteryTypeOption}
                    onChange={({ target: { value } }) => onInfoValueChange('lotteryTypeOption', value)}
                  >
                    {
                      [{
                        label: '所有彩种', value: 0
                      }, {
                        label: '选定彩种', value: 1
                      }].map(item => <Radio value={item.value}>{item.label}</Radio>)
                    }
                  </Radio.Group>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-2">分类</td>
                <td>
                  <div className="py-1 border-bottom">
                    <Checkbox
                      onChange={({ target: { checked } }) => {
                        if (checked) {
                          let temp = [];
                          settingCodeList.forEach(x => {
                            temp = temp.concat(x.code.split(','));
                          });
                          setSettingCode(temp);
                        } else {
                          setSettingCode([]);
                        }
                      }}
                    >
                      所有
                    </Checkbox>
                  </div>
                  { _info?.lotteryTypeOption === 1
                    ? settingCodeList.map(x => (
                      <Checkbox
                        value={x.code}
                        onChange={({ target: { value } }) => {
                          const temp = value.split(',');
                          const tempSet = new Set(settingCode);
                          temp.forEach(x => {
                            if (tempSet.has(x)) {
                              tempSet.delete(x);
                            } else {
                              tempSet.add(x);
                            }
                          });
                          setSettingCode([...tempSet]);
                        }}
                        checked={x.code.split(',').find(e => settingCode.includes(e))}
                      >
                        {x.projectName}
                      </Checkbox>
                    ))
                    : ''}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {_info?.lotteryTypeOption === 1
      && (
      <div className="w-100">
        <table className="p-3 m-0 w-100" cellSpacing="0" cellPadding="0" border="0">
          <tbody>
            <tr>
              <td className="titleHeader" colSpan={2}>
                彩种选择
              </td>
            </tr>
            {allLottery.size > 0
              ? [...allLottery.values()].map(item => {
                const itemPerRow = item[0];
                return (
                  <tr>
                    <td className="tableHeader col-4">
                      <Checkbox
                        onChange={({ target: { checked } }) => {
                          const temp = new Map(checkedList);
                          temp.set(itemPerRow?.categoryCode, checked ? item.map(x => x.id) : []);
                          setCheckedList(temp);
                        }}
                        checked={
                      checkedList.has(itemPerRow?.categoryCode)
                        ? (checkedList.get(itemPerRow?.categoryCode).length > 0 && checkedList.get(itemPerRow?.categoryCode).length === item.length)
                        : false
                            }
                      >
                        {itemPerRow?.categoryName}
                      </Checkbox>
                    </td>
                    <td>
                      <Checkbox.Group
                        options={item.map(x => ({
                          label: x.lotteryName, value: x.id
                        }))}
                        onChange={data => {
                          const newMap = new Map(checkedList);
                          newMap.set(itemPerRow?.categoryCode, data);
                          setCheckedList(newMap);
                        }}
                        value={
                      checkedList.has(itemPerRow?.categoryCode)
                        ? checkedList.get(itemPerRow?.categoryCode)
                        : []
                    }
                      />
                    </td>
                  </tr>
                );
              })
              : ''}
          </tbody>
        </table>
      </div>
      )}
      <div className="d-flex justify-content-center py-2 border-top">
        <div className="button handle mx-1" onClick={() => saveButton()}>{t('SAVE')}</div>
        <div className="button find mx-1" onClick={() => setPageName('FollowBetList')}>{t('CANCEL')}</div>
      </div>
    </div>
  );
};

export default connect(null, mapDispatchToProps)(FollowBetAddEdit);