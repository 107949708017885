
import { Button, Checkbox, DatePicker, Input, Select } from 'antd';
import { Col, Label, Row } from 'reactstrap';


import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { appActions, commonActions, systemActions } from '../../../redux/actions';

import AgentDataTable from '../../AgentDataTable';
import Notifications from '../../Content/Notifications';
import Pagination from '../../Content/Pagination';
import { exportCSV } from '../../../constants/utils';

import './styles.scss';

const mapStateToProps = state => ({
  memberDayReportData: state.systemReducers.memberDayReportData,
  accountType: state.commonReducers.accountType,
});

const mapDispatchToProps = dispatch => ({
  BatchRepair: (data, callback) => dispatch(systemActions.BatchRepair(data, callback)),
  GenerateCheckBills: (data, callback) => dispatch(systemActions.GenerateCheckBills(data, callback)),
  GetMemberDailyReport: (obj, callback) => dispatch(systemActions.GetMemberDailyReport(obj, callback)),
  GetAccountType: () => dispatch(commonActions.GetAccountType()),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
});

const { RangePicker } = DatePicker;

const MemberDayReport = ({ updateModalAlert, BatchRepair, GenerateCheckBills, accountType, GetAccountType, memberDayReportData, GetMemberDailyReport }) => {
  const { t } = useTranslation(); // i18n
  const [_isQueryingDetail, setIsQueryingDetail] = useState([]); // loading
  const [_detailItem, setDetailItem] = useState([]); // table data
  const [_detailItemSum, setDetailItemSum] = useState([]); // table data sum
  const [checkedKeys, setCheckedKeys] = useState(new Set());
  const [checkAll, setCheckAll] = useState(false);
  const [_date, setDate] = useState([moment().startOf('day'), moment().startOf('day')]); // filter date
  const [dates, setDates] = useState(null);
  const [hackValue, setHackValue] = useState(null);

  useEffect(() => {
    GetAccountType();
  }, []);

  useEffect(() => {
    if (accountType) {
      setFilter({
        ...filter,
        accType: accountType[0] && accountType[0].accountType
      });
    }
  }, [accountType]);

  const [filter, setFilter] = useState({
    mode: '',
    type: '',
    accType: accountType[0] && accountType[0].accountType,
    account: '',
    hasRepair: true,
    hasWinlost: true,
    page: 1, // 当前多少页
    rows: 15, // 一页多少内容
  });

  const tableHeaderTitle = [
    {
      label: (
        <Checkbox
          checked={checkAll}
          onChange={e => {
            const { checked } = e.target;
            if (checked) {
              setCheckedKeys(new Set(_detailItem.map(i => i.id)));
            } else {
              setCheckedKeys(new Set());
            }
            setCheckAll(checked);
          }}
        />
      )
    },
    {
      label: t('ORDER_NUM'),
    },
    {
      label: t('INSTALLMENT_DATE'),
    },
    {
      label: t('RENEW_TIME'),
    },
    {
      label: t('USER_ACCOUNT'),
    },
    {
      label: t('TODAY_PROFIT'),
    },
    {
      label: t('INITIAL_AMOUNT'),
    },
    {
      label: t('END_AMOUNT'),
    },
    {
      label: t('ADDITION_OR_SUBTRACTION'),
    },
    {
      label: t('OUTSTANDING_AMOUNT'),
    },
    {
      label: t('DIFF_TO_BE_RETURNED'),
    },
    {
      label: t('STATUS'),
    },
    {
      label: t('OPERATION'),
    },
  ];

  const _content = [
    item => {
      const checked = checkedKeys.has(item.id);
      return (
        <Checkbox
          disabled={item.status === 1}
          onChange={e => {
            const { checked: ck } = e.target;
            const copySet = new Set();
            checkedKeys.forEach(value => {
              copySet.add(value);
            });
            if (!ck) {
              copySet.delete(item.id);
            } else {
              copySet.add(item.id);
            }
            setCheckedKeys(copySet);
            if (copySet.size < checkedKeys.size) {
              setCheckAll(false);
            }
          }}
          checked={checked}
        />
      );
    },
    data => (
      <Label>{data.no}</Label>
    ),
    data => (
      <Label>{moment.unix(data.installment).format('YYYY-MM-DD')}</Label>
    ),
    data => (
      <Label>{moment.unix(data.updated).format('YYYY-MM-DD HH:mm:ss')}</Label>
    ),
    data => (
      <Label>{data.mem}</Label>
    ),
    data => (
      <Label>{data.winlost}</Label>
    ),
    data => (
      <Label>{data.start}</Label>
    ),
    data => (
      <Label>{data.end}</Label>
    ),
    data => (
      <Label
        className="chargeClass"
        onClick={() => {
          const { mem } = data;
          updateModalAlert({
            type: 'additionSubtractionDetails',
            visible: true,
            data: {
              user: mem,
              userId: data.userId,
              changeDate: data.installment,
              mtype: data.mtype,
              accType: filter.accType,
            }
          });
        }}
      >
        {
          data.charge
        }
      </Label>
    ),
    data => (
      <Label>{data.unCheck}</Label>
    ),
    data => (
      <Label>{data.diff}</Label>
    ),
    data => (
      <Label>
        {data.status === 1 && <CheckCircleOutlined className="passIcon" />}
        {data.status === 0 && <CloseCircleOutlined className="nopassIcon" />}
      </Label>
    ),
  ];

  useEffect(() => {
    if (memberDayReportData) {
      setDetailItem(memberDayReportData.list || []);
      setDetailItemSum(memberDayReportData.totalAmount || 0);
    }
    setIsQueryingDetail(false);
  }, [memberDayReportData]);

  useEffect(() => {
    onQuery();
  }, []);


  const onQuery = (params = {}, modifyPage = false) => {
    setIsQueryingDetail(true);
    if (!modifyPage) {
      setFilter({
        ...filter,
        ...params,
        page: 1
      });
      params.page = 1;
    }
    GetMemberDailyReport(
      {
        hasWinlost: filter.hasWinlost,
        hasRepair: filter.hasRepair,
        limitType: filter.mode,
        userType: filter.type,
        accType: filter.accType,
        loginId: filter.account,
        rows: filter.rows,
        page: filter.page,
        startDate: moment(_date[0]).unix(),
        endDate: moment(_date[1]).unix(),
        ...params,
      },
      () => {
        setIsQueryingDetail(false);
      },
      () => {
        setIsQueryingDetail(false);
      }
    );
  };

  const handleExport = () => {
    const dataSource = (_detailItem || []).map(data => {
      const { installment } = data;
      return {
        time: `\t ${moment.unix(installment).format('YYYY-MM-DD')}`,
        newTime: `\t ${moment.unix(data.updated).format('YYYY-MM-DD HH:mm:ss')}`,
        mem: data.mem,
        winlost: data.winlost,
        start: data.start,
        end: data.end,
        charge: data.charge,
        unCheck: data.unCheck,
        diff: data.diff,
      };
    });

    const columns = tableHeaderTitle.slice(2, 2 + 9).map(i => i.label);
    exportCSV({
      data: dataSource, columns, fileName: '会员日结帐单'
    }).save();
  };

  const disabledDate = current => {
    if (!dates) {
      return false;
    }
    const diff = moment(current).add(3, 'month').diff(current, 'days'); // 计算从当前起三个月 是多少天
    const tooLate = dates[0] && current.diff(dates[0], 'days') > diff;
    const tooEarly = dates[1] && dates[1].diff(current, 'days') > diff;
    const shouldBeforeToday = current && current > moment().endOf('day');
    return !!tooEarly || !!tooLate || !!shouldBeforeToday;
  };

  const onOpenChange = open => {
    if (open) {
      setHackValue([null, null]);
      setDates([null, null]);
    } else {
      setHackValue(null);
    }
  };

  return (
    <div className="memberDayReportWrapper">
      <Col className="memberDayReportFilterWrapper">
        <Col className="p-2 mb-2 filterWrapper d-flex align-items-center w-100 rounded-top">
          <Label className="px-2">{t('MODE')}</Label>
          <Select
            value={filter.mode}
            onChange={value => setFilter({
              ...filter,
              mode: value
            })}
            options={[
              {
                label: t('ALL'), value: ''
              },
              {
                label: t('CREDIT'), value: '1'
              },
              {
                label: t('CASH'), value: '2'
              },
            ]}
          />
          <Label className="px-2">{t('TYPE')}</Label>
          <Select
            value={filter.type}
            onChange={value => (setFilter({
              ...filter, type: value
            }))}
            options={[
              {
                label: t('ALL'), value: ''
              },
              {
                label: t('AGENT'), value: '2'
              },
              {
                label: t('MEMBER'), value: '1'
              },
            ]}
          />
          <Label className="px-2">{t('QUOTA_TYPE')}</Label>
          <Select
            value={filter.accType}
            onChange={value => {
              setFilter({
                ...filter, accType: value
              });
            }}
            options={
              accountType.map(item => {
                const { accName, accountType } = item;
                return {
                  label: accName,
                  value: accountType
                };
              })
            }
          />
          <Label className="px-2">{` ${t('ACCOUNT')} `}</Label>
          <Input
            className="searchInput"
            value={filter.account}
            onChange={e => {
              setFilter({
                ...filter, account: e.target.value
              });
            }}

          />
          <Label type="daterange" value={t('DATE')} className="px-2">{t('DATE')}</Label>
          <div>
            <RangePicker
              suffixIcon={undefined}
              allowClear={false}
              value={hackValue || _date}
              separator={<span>{` ${t('TO')} `}</span>}
              onChange={date => setDate(date)}
              disabledDate={disabledDate}
              onCalendarChange={val => setDates(val)}
              onOpenChange={onOpenChange}
            />
          </div>
          <Checkbox
            className="checkboxText marginLeft2"
            checked={filter.hasWinlost}
            onChange={e => {
              const { checked } = e.target;
              setFilter({
                ...filter, hasWinlost: checked
              });
            }}
          >
            {t('TODAY_PROFIT')}
          </Checkbox>
          <Checkbox
            className="checkboxText"
            checked={filter.hasRepair}
            onChange={e => {
              const { checked } = e.target;
              setFilter({
                ...filter, hasRepair: checked
              });
            }}
          >
            {t('REPAIRABLE')}
          </Checkbox>

          <Col className="actionArea">
            <Button
              className="searchButton marginLeft1"
              onClick={() => onQuery()}
            >
              {
                t('FIND')
              }
            </Button>
            <Button
              className="searchButton marginLeft1"
              onClick={() => handleExport()}
            >
              {
                t('EXPORT')
              }
            </Button>
            <Button
              className="searchButton marginLeft1"
              onClick={() => GenerateCheckBills(
                {
                  accType: filter.accType
                }
              )}
            >
              {
                t('GENERATE')
              }
            </Button>
            <Button
              className="searchButton marginLeft1"
              onClick={() => BatchRepair(
                {
                  idList: [...checkedKeys],
                  accType: filter.accType
                },
                onQuery
              )}
            >
              {t('FIX')}
            </Button>
            <div className=" notify marginLeft2">
              <Notifications />
            </div>
          </Col>

        </Col>
      </Col>

      <Row className=" mt-1 mx-0 bg-white px-0 border-1">
        <AgentDataTable
          isQuerying={_isQueryingDetail}
          tableHeaderTitle={tableHeaderTitle}
          tableData={_detailItem || []}
          tableBodyRowProperty={[
            ..._content,
            data => (
              <Label className="d-flex justify-content-center">
                <div
                  className="operationBtn"
                  onClick={() => BatchRepair(
                    {
                      idList: [data.id],
                      accType: filter.accType
                    },
                    onQuery
                  )}
                >
                  {t('FIX')}
                </div>
              </Label>
            )
          ]}
        />
      </Row>

      <Row className="justify-center w-full relative">
        <Pagination
          customizePage={filter.page}
          onClick={(page, rows) => {
            setFilter({
              ...filter,
              page,
              rows
            });
            onQuery(
              {
                page,
                rows
              },
              true
            );
          }}
          totalRecord={_detailItemSum || 0}
        />
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MemberDayReport);