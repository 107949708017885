import { connect } from 'react-redux';
import { useState } from 'react';

import DirectAgent from '../../components/UserManage/DirectAgent'; // 直属代理
import DirectMember from '../../components/UserManage/DirectMember'; // 直属会员
import DirectSubAccount from '../../components/UserManage/DirectSubAccount'; // 子账号

import './styles.scss';

const mapStateToProps = state => ({
  selectedSubMenu: state.commonReducers.selectedSubMenu,
});

const UserManagement = ({ selectedSubMenu }) => {
  const [showMember, setShowMember] = useState(false);
  const [showMemberAll, setShowMemberAll] = useState(false);
  const [selectedItem, setSelectedItem] = useState();

  const mapPage = menu => {
    if (menu === 'UM_ZSDL') {
      if (showMember) {
        return (
          // 这里需要传入指定账号
          <DirectMember
            propData={selectedItem || {}}
            propOrgId={selectedItem?.orgId}
            onMemberListClose={() => {
              setShowMember(false);
            }}
            showMember
            initialLevel={showMemberAll && 'all'}
          />
        );
      }
      return (
        <DirectAgent
          onMemberListOpen={({ showMemberAll, selectedItem }) => {
            setShowMember(true);
            setShowMemberAll(showMemberAll);
            setSelectedItem(selectedItem);
          }}
        />
      );
    }
    if (menu === 'UM_SYDL') {
      if (showMember) {
        return (
          // 这里需要传入指定账号
          <DirectMember
            propData={selectedItem || {}}
            propOrgId={selectedItem?.orgId}
            onMemberListClose={() => {
              setShowMember(false);
            }}
            showMember
            initialLevel={showMemberAll && 'all'}
          />
        );
      }
      return (
        <DirectAgent
          initialLevel="all"
          onMemberListOpen={({ showMemberAll, selectedItem }) => {
            setShowMember(true);
            setShowMemberAll(showMemberAll);
            setSelectedItem(selectedItem);
          }}
        />
      );
    }
    if (menu === 'UM_ZSHY') {
      return <DirectMember />;
    }
    if (menu === 'UM_QBHY') {
      return <DirectMember initialLevel="all" />;
    }
    if (menu === 'UM_ZZH') {
      return <DirectSubAccount />;
    }
  };

  return (
    <div className="userManagementWrapper">
      {
        mapPage(selectedSubMenu)
      }
    </div>
  );
};

export default connect(mapStateToProps, null)(UserManagement);