import { championDs, championDx, dxds } from '../Items/dsdx';
import { dragonTiger } from '../Items/others';

import { PK101to10, PK101to10Code, number1to20 } from '../Items/numbers';
import { qsBigSmall, qsCar1to10, qsDragonTiger, qsGYH, qsSingleDouble } from '../Items/quickset';

const PK10 = [{
  displayName: '冠、亚军 组合',
  isStaticRow: 1,
  midcode: 'GYJH,GJHM,GJLM,YJHM,YJLM',
  quickSet: {
    style: 2,
    variables: [[...qsBigSmall, ...qsSingleDouble, ...qsDragonTiger, ...qsGYH, ...qsCar1to10]]
  },
  display: [[...number1to20.slice(2, 19)], [...championDx, ...championDs], [...dxds, ...dragonTiger, ...PK101to10Code], [...dxds, ...dragonTiger, ...PK101to10Code]],
  firstTableHeader: ['冠、亚军和指定', '冠、亚军和两面', '冠军', '亚军'],
  prefix: ['GYJH', '', 'GJ', 'YJ']
}, {
  displayName: '三、四、五、六名',
  midcode: 'B3CH,B3LM,B4CH,B4LM,B5CH,B5LM,B6CH,B6LM',
  isStaticRow: 1,
  quickSet: {
    style: 2,
    variables: [[...qsBigSmall, ...qsSingleDouble, ...qsDragonTiger, ...qsCar1to10]]
  },
  display: [[...dxds, ...dragonTiger, ...PK101to10], [...dxds, ...dragonTiger, ...PK101to10], [...dxds, ...dragonTiger, ...PK101to10], [...dxds, ...PK101to10]],
  firstTableHeader: ['第三名', '第四名', '第五名', '第六名'],
  prefix: ['B3', 'B4', 'B5', 'B6']
}, {
  displayName: '七、八、九、十名',
  midcode: 'B7CH,B7LM,B8CH,B8LM,B9CH,B9LM,B10CH,B10LM',
  isStaticRow: 1,
  quickSet: {
    style: 2,
    variables: [[...qsBigSmall, ...qsSingleDouble, ...qsCar1to10]]
  },
  display: [[...dxds, ...PK101to10], [...dxds, ...PK101to10], [...dxds, ...PK101to10], [...dxds, ...PK101to10]],
  firstTableHeader: ['第七名', '第八名', '第九名', '第十名'],
  prefix: ['B7', 'B8', 'B9', 'B10']
}
];

export default PK10;