import { SET_BATCH_ODDS_LIST, SET_DEFAULT_WIN_LOST, SET_ODDS_SETTING } from '../../actions/actionTypes';

const initialState = {
  batchOddsList: undefined,
  oddsSettingData: undefined,
  defaultWinLostData: undefined,
};

const oddsSettingReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_BATCH_ODDS_LIST:
      return {
        ...state, batchOddsList: action.value
      };
    case SET_ODDS_SETTING:
      return {
        ...state, oddsSettingData: action.value
      };
    case SET_DEFAULT_WIN_LOST:
      return {
        ...state, defaultWinLostData: action.value
      };
    default:
      return state;
  }
};

export default oddsSettingReducers;