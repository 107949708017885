// Web View
import BetWarning from './views/BetWarning';
import CompanyManagement from './views/CompanyManagement';
import InTimeSheetManage from './views/InTimeSheetManage';
import IndividualManagement from './views/IndividualManagement';
import InstallmentManagement from './views/InstallmentManagement';
import OddsManage from './views/OddsManage';
import OnlineStatistics from './views/OnlineStatistics';
import ReportManagement from './views/ReportManagement';
import Result from './views/Result';
import SuperUserList from './views/SuperUserList';
import SystemSetting from './views/SystemSetting';
import ThirdPartyGame from './views/ThirdPartyGame';
import TotalOnlineStatistics from './views/TotalOnlineStatistics';
import TransactionManage from './views/TransactionManage';
import UserManagement from './views/UserManagement';

const authWebRoutes = [
  // 即时注单
  {
    path: '/in-time-sheet', name: 'InTimeSheet', component: InTimeSheetManage, id: '1'
  },
  // 用户管理
  {
    path: '/user-management', name: 'user management', component: UserManagement, id: '2'
  },
  // 注单预警
  {
    path: '/bet-warning', name: 'bet warning', component: BetWarning, id: '11'
  },
  // 个人管理
  {
    path: '/individual-management', name: 'Individual Management', component: IndividualManagement, id: '3'
  },
  //  交易管理
  {
    path: '/transaction-management', name: 'Transction Query', component: TransactionManage, id: '60'
  },
  // 赔率设置
  {
    path: '/odds-management', name: 'Current Odds', component: OddsManage, id: '4'
  },
  // 报表查询
  {
    path: '/report-management', name: 'Win Lose Report', component: ReportManagement, id: '6'
  },
  // 系统设置
  {
    path: '/system-settings', name: 'System Setting', component: SystemSetting, id: '5'
  },
  // 盘口管理
  {
    path: '/installment-management', name: 'Installment Management', component: InstallmentManagement, id: '7'
  },
  // 开奖结果
  {
    path: '/game-result-management', name: 'Game Result', component: Result, id: '8'
  },
  // 在线统计
  {
    path: '/online-statistics', name: 'Online Statistics', component: OnlineStatistics, id: '9'
  },
  // 公司管理
  {
    path: '/company-management', name: 'Company Management', component: CompanyManagement, id: '10'
  },
  // 第三方游戏
  {
    path: '/third-party-game-management', name: 'Third Party Game', component: ThirdPartyGame, id: '12'
  },
  // 总在线统计
  {
    path: '/total-online-statistics', name: 'Total Online Statistics', component: TotalOnlineStatistics, id: '901'
  },
  // 超级用户列表
  {
    path: '/super-user-list', name: 'Super User List', component: SuperUserList, id: ''
  },
  // logout
  {
    path: '/logout', name: 'Logout', component: null, id: 'logout'
  }
];

export { authWebRoutes, };