import { useEffect, useRef, useState } from 'react';

import { Label } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import { motion } from 'framer-motion';

import { AgentDataTable } from '../../..';
import ReportCommonSelection from '../../../ReportCommonSelection';
import WinLostReportDetail from '../../WinLostReportDetail';
import { reportActions } from '../../../../redux/actions';
import './styles.scss';


const mapStateToProps = state => ({
  winLostReport: state.reportReducers.winLostReport,
  lotteryList: state.commonReducers.lotteryList,
  classificationReport: state.reportReducers.classificationReport,
  userInfo: state.memberReducers.userInfo,
});

const mapDispatchToProps = dispatch => ({
  getDepositWithdrawTotalStatistic: (obj, callback) => dispatch(reportActions.getDepositWithdrawTotalStatistic(obj, callback)),
});

const LotteryReport = ({
  showQueryMenu,
  setShowQueryMenu,
  winLostReport,
  classificationReport,
  _specialLoginName,
  setSpecialLoginName,
  lotteryList,
  userInfo
}) => {
  const [_isQueryingDetail, setIsQueryingDetail] = useState(true);
  const [list, setList] = useState();
  const [total, setTotal] = useState();
  const [tabIndex, setTabIndex] = useState('');

  const [_date, setDate] = useState([moment().startOf('day').toDate(), moment().toDate()]);

  const ChildRef = useRef();

  const [isSettled, setisSettled] = useState(1);

  const [_type, setType] = useState('jiaoShou');
  const [gamePage, setgamePage] = useState(true);

  const [breadcrumb, setBreadcrumb] = useState([]);

  const [classificationInfo, setClassificationInfo] = useState({});

  const [lotterys, setlotterys] = useState([]);

  const [orgId, setOrgId] = useState('');

  const [currentJiaoShouSelectedUser, setCurrentJiaoShouSelectedUser] = useState('');

  useEffect(() => {
    if (_type === 'jiaoShou') {
      if (winLostReport && winLostReport.reportList) {
        setBreadcrumb(winLostReport?.breadCrumbs);
        setCurrentJiaoShouSelectedUser(winLostReport?.breadCrumbs[winLostReport?.breadCrumbs?.length - 1] ?? '');
        setlotterys(winLostReport?.reportList.lotterys);
        if (tabIndex === '') {
          setList(winLostReport.reportList.total);
          setTotal(winLostReport.reportList);
        } else {
          setList(winLostReport.reportList[`${tabIndex.toLowerCase()}`]);
          setTotal(winLostReport.reportList[`${tabIndex.toLowerCase()}_total`]);
        }
      }
    }
  }, [winLostReport, tabIndex]);

  useEffect(() => {
    if (classificationReport && _type === 'classification') {
      setBreadcrumb(classificationReport?.breadCrumbs);
      setList(classificationReport.reportList.total);
      setTotal(classificationReport.reportList);
      if (classificationReport?.breadCrumbs && classificationReport?.breadCrumbs?.length > 0) {
        setOrgId(classificationReport?.breadCrumbs[0]?.orgId);
      } else {
        setOrgId('');
      }
    }
  }, [classificationReport]);

  useEffect(() => {
    setOrgId('');
    setList();
    setTotal();
    setBreadcrumb([]);
    setlotterys([]);
    setCurrentJiaoShouSelectedUser('');
  }, [_type]);

  const variants = {
    hidden: {
      height: 0,
      overflow: 'hidden',
    },
    show: {
      height: 'auto',
      overflow: 'hidden',
    }
  };

  return (
    <div className="LotteryReportWrapper">
      <motion.div
        variants={variants}
        initial={{
          overflow: 'hidden',
          height: 'auto'
        }}
        animate={showQueryMenu ? 'show' : 'hidden'}
      >
        <ReportCommonSelection
          setShowQueryMenu={setShowQueryMenu}
          _type={_type}
          setgamePage={setgamePage}
          setType={setType}
          setSpecialLoginName={setSpecialLoginName}
          _isShow={!_specialLoginName}
          _setDate={setDate}
          callback={data => setIsQueryingDetail(data)}
          onRef={ChildRef}
          isSettled={isSettled}
          setisSettled={setisSettled}
        />
      </motion.div>
      {
      _type === 'jiaoShou'
        ? (
          <>
            <div className={`winLostReportTableWrapper p-3 ${_specialLoginName && 'd-none'}`}>
              <div className="d-flex align-items-center py-1">
                <span>当前位置：</span>
                {
                  breadcrumb.map((item, index) => (
                    <span
                      onClick={() => {
                        if (index === (breadcrumb?.length - 1)) {
                          return;
                        }
                        ChildRef.current.onSearchClick(item.level, item.orgId, item.isDir);
                      }}
                      className={`mx-1 detailLink ${index === (breadcrumb?.length - 1) && 'fw-bold border-bottom border-dark'}`}
                    >
                      {`${item?.levelName}(${item?.loginId})${index !== (breadcrumb?.length - 1) ? ' /' : ''}`}
                    </span>
                  ))
                }
                <div
                  className="controlMenuBtn"
                  onClick={() => setShowQueryMenu(!showQueryMenu)}
                >
                  {showQueryMenu ? '收起查询' : '展开查询'}
                </div>
              </div>
              <div className="menuWrapper mb-2 ms-2">
                <div className={`menuItem p-2  ${tabIndex === '' && 'selected'}`} onClick={() => setTabIndex('')}>合计</div>
                {
                  lotterys.map(item => <div className={`menuItem p-2  ${tabIndex === item && 'selected'}`} onClick={() => setTabIndex(item)}>{lotteryList.find(x => x.code === String(item).toUpperCase()).lotteryName}</div>)
                }
              </div>
              <div className="mx-0 bg-white">
                <div className="headerTitle d-flex justify-content-center py-2 w-100">合计</div>
                <AgentDataTable
                  isQuerying={_isQueryingDetail}
                  tableHeaderTitle={
              [{
                label: '账号',
              }, {
                label: '名称',
              }, {
                label: '会员数',
              }, {
                label: '笔数',
              }, {
                label: '下注金额',
              }, {
                label: '有效金额',
              }, {
                label: (
                  <div className="combineTitleCell">
                    <div className="h-50 py-1">会员输赢</div>
                    <div className="d-flex h-50">
                      <div className="py-1">输赢</div>
                      <div className="py-1">退水</div>
                      <div className="py-1">盈亏结果</div>
                    </div>
                  </div>
                ),
              }, {
                label: (
                  <div className="combineTitleCell">
                    <div className="h-50 py-1">{`${currentJiaoShouSelectedUser?.levelName ?? '本级'}输赢`}</div>
                    <div className="d-flex h-50">
                      <div className="py-1">应收下线</div>
                      <div className="py-1">占成</div>
                      <div className="py-1">实占金额</div>
                      <div className="py-1">实占结果</div>
                      <div className="py-1">实占退水</div>
                      <div className="py-1">赚水</div>
                      <div className="py-1">赚赔</div>
                      <div className="py-1">盈亏结果</div>
                    </div>
                  </div>
                ),
              }, {
                label: '上交货量',
              }, {
                label: '上级交收',
              }]
            }
                  tableHeaderStyle={['', '', '', '', '', '', '', 'p-0', 'p-0', '', '']}
                  tableData={list || []}
                  tableBodyRowStyle={['', '', '', '', '', '', '', 'p-0', 'p-0', '', '']}
                  tableBodyRowProperty={
              [
                data => (
                  <Label
                    className="d-flex justify-content-center numberBlue detailLink"
                    onClick={() => {
                      if (data.level === 12) {
                        setClassificationInfo(data);
                        setSpecialLoginName(data.orgName);
                      } else {
                        setOrgId(data?.isDirectMem === 1 ? data?.orgId : '');
                        ChildRef.current.onSearchClick(data.level, data.orgId, data.isDirectMem);
                      }
                    }}
                  >
                    {data.orgName}
                  </Label>
                ),
                data => (<Label className="d-flex justify-content-center">{data.loginName}</Label>),
                data => (<Label className="d-flex justify-content-center">{data.memCount}</Label>),
                data => (<Label className="d-flex justify-content-center">{data.count}</Label>),
                data => (<Label className="d-flex justify-content-center numberBlue">{data.amount}</Label>),
                data => (<Label className="d-flex justify-content-center">{data.validAmount}</Label>),
                data => (
                  <Label className="d-flex justify-content-center combineBodyRowCell">
                    <div>{data.winLost}</div>
                    <div>{data.rebate}</div>
                    <div>{data.totalWinLost}</div>
                  </Label>
                ),
                data => (
                  <Label className="d-flex justify-content-center combineBodyRowCell">
                    <div className={`${parseFloat(data.receiveable) < 0 ? 'numberRed' : 'numberBlue'}`}>{data.receiveable}</div>
                    <div>{data.zc}</div>
                    <div>{data.zcAmount}</div>
                    <div>{data.zcWinLost}</div>
                    <div>{data.zcRebate}</div>
                    <div>{data.earnAmount}</div>
                    <div>{data.earnOdds}</div>
                    <div className={`${parseFloat(data.totalResult) < 0 ? 'numberRed' : 'numberBlue'}`}>{data.totalResult}</div>
                  </Label>
                ),
                data => (<Label className="d-flex justify-content-center">{data.uplineAmount}</Label>),
                data => (<Label className="d-flex justify-content-center numberBlue">{data.uplineResult}</Label>),
              ]
            }
                  tableFooter={[
                    [{
                      value: `合计：${list ? list.length : 0}行`,
                      colspan: 2,
                      className: 'pe-1 total tableSum'
                    },
                    {
                      value: (total && total.memCount) || 0,
                      colspan: 1,
                      className: 'tableSum'
                    },
                    {
                      value: (total && total.count) || 0,
                      colspan: 1,
                      className: 'tableSum'
                    },
                    {
                      value: (total && total.amount) || 0,
                      colspan: 1,
                      className: 'tableSum'
                    },
                    {
                      value: (total && total.validAmount) || 0,
                      colspan: 1,
                      className: 'tableSum'
                    },
                    {
                      render: () => {
                        if (total) {
                          return (
                            <Label className="d-flex justify-content-center combineBodyRowCell">
                              <div>{total.winLostAmount}</div>
                              <div>{total.rebate}</div>
                              <div>{total.totalWinLost}</div>
                            </Label>
                          );
                        }

                        return 0;
                      },
                      colspan: 1,
                      className: 'tableSum noPadding'
                    },
                    {
                      render: () => {
                        if (total) {
                          return (
                            <Label className="d-flex justify-content-center combineBodyRowCell">
                              <div className={`${parseFloat(total.receiveable) < 0 ? 'numberRed' : 'numberBlue'}`}>{total.receiveable}</div>
                              <div />
                              <div>{total.zcAmount}</div>
                              <div>{total.zcWinLost}</div>
                              <div>{total.zcRebate}</div>
                              <div>{total.earnAmount}</div>
                              <div>{total.earnOdds}</div>
                              <div className={`${parseFloat(total.totalResult) < 0 ? 'numberRed' : 'numberBlue'}`}>{total.totalResult}</div>
                            </Label>
                          );
                        }

                        return 0;
                      },
                      colspan: 1,
                      className: 'tableSum noPadding'
                    },
                    {
                      value: (total && total.uplineAmount) || 0,
                      colspan: 1,
                      className: 'tableSum'
                    },
                    {
                      value: (total && total.uplineResult) || 0,
                      colspan: 1,
                      className: 'tableSum numberBlue'
                    }]
                  ]}
                />
              </div>
            </div>
            {
              _specialLoginName
              && (
              <div className="mt-2">
                <WinLostReportDetail presetObj={{
                  ...classificationInfo,
                  beginDate: _date[0],
                  endDate: _date[1],
                  lotteryType: tabIndex === '' ? lotterys.toString() : tabIndex,
                  isBuhuo: classificationInfo?.isBuhuo,
                  userId: classificationInfo?.orgId ?? '',
                  memberLoginId: classificationInfo?.orgName ?? '',
                  orgId,
                  settlementStatus: isSettled
                }}
                />
              </div>
              )
            }
          </>
        )
        : (
          <>
            <div className={`classificationReportTableWrapper p-3 ${_specialLoginName && 'd-none'}`}>
              <div className="d-flex align-items-center py-1">
                <span>当前位置：</span>
                {
                  breadcrumb.map((item, index) => (
                    <span
                      onClick={() => {
                        if (index === (breadcrumb?.length - 1)) {
                          return;
                        }
                        if (item?.flLevel === 4) {
                          setgamePage(false);
                        } else {
                          setgamePage(true);
                        }
                        ChildRef.current.onSearchClick(item.level, item.orgId, item.isDir, '', '', item.lotteryType, '', item?.flLevel === 4 ? 1 : 0);
                      }}
                      className={`mx-1 detailLink ${index === (breadcrumb?.length - 1) && 'fw-bold border-bottom border-dark'}`}
                    >
                      {`${item?.levelName}(${item?.loginId})${index !== (breadcrumb?.length - 1) ? ' /' : ''}`}
                    </span>
                  ))
                }
              </div>
              <div className="mx-0 bg-white">
                <div className="headerTitle d-flex justify-content-center py-2 w-100">合计</div>
                { gamePage
                  ? (
                    <AgentDataTable
                      isQuerying={_isQueryingDetail}
                      tableHeaderTitle={
                        [{
                          label: '彩种',
                        }, {
                          label: '下注种类',
                        }, {
                          label: '笔数',
                        }, {
                          label: '下注金额',
                        }, {
                          label: '有效金额',
                        }, {
                          label: '会员输赢',
                        }, {
                          label: (
                            <div className="combineTitleCell">
                              <div className="h-50">{`${userInfo?.levelName ?? '本级'}输赢`}</div>
                              <div className="d-flex h-50">
                                <div>应收下线</div>
                                <div>实占金额</div>
                                <div>实占结果</div>
                                <div>实占退水</div>
                                <div>赚水</div>
                                {userInfo.levelValue >= userInfo?.zdLevel ? <div>赚赔</div> : ''}
                                <div>盈亏结果</div>
                              </div>
                            </div>
                          ),
                        },
                        {
                          label: '上交货量',
                        }, {
                          label: '上级交收',
                        }]
                      }
                      tableHeaderStyle={['', '', '', '', '', '', 'p-0', '', '', '']}
                      tableData={list || []}
                      tableBodyRowStyle={['', '', '', '', '', '', 'p-0', '', '', '']}
                      tableBodyRowProperty={
                        [
                          data => (<Label className="d-flex justify-content-center">{data.lotteryName}</Label>),
                          data => (
                            <Label
                              className="d-flex justify-content-center numberBlue detailLink"
                              onClick={() => {
                                setgamePage(false);
                                ChildRef.current.onSearchClick(2, data.orgId, data.isDirectMem, '', '', data.lotteryType, data.settingCode, 1);
                              }}
                            >
                              {data.orgName}
                            </Label>
                          ),
                          data => (<Label className="d-flex justify-content-center">{data.count}</Label>),
                          data => (<Label className="d-flex justify-content-center text-primary">{data.amount}</Label>),
                          data => (<Label className="d-flex justify-content-center">{data.validAmount}</Label>),
                          data => (<Label className="d-flex justify-content-center">{data.totalWinLost}</Label>),
                          data => (
                            <Label className="d-flex justify-content-center combineBodyRowCell">
                              <div className={`${parseFloat(data.receiveable) < 0 ? 'numberRed' : 'numberBlue'}`}>{data.receiveable}</div>
                              <div>{data.zcAmount}</div>
                              <div>{data.zcWinLost}</div>
                              <div>{data.zcRebate}</div>
                              <div>{data.earnAmount}</div>
                              {
                                userInfo.levelValue >= userInfo?.zdLevel
                                  ? <div>{data.earnOdds}</div>
                                  : ''
                              }
                              <div className={`${parseFloat(data.totalResult) < 0 ? 'numberRed' : 'numberBlue'}`}>{data.totalResult}</div>
                            </Label>
                          ),
                          data => (<Label className="d-flex justify-content-center">{data.uplineAmount}</Label>),
                          data => (<Label className={`d-flex justify-content-center ${parseFloat(data.uplineResult) < 0 ? 'numberRed' : 'numberBlue'}`}>{data.uplineResult}</Label>),
                        ]
                      }
                      tableFooter={[
                        [{
                          value: `合计：${list ? list.length : 0}行`,
                          colspan: 2,
                          className: 'pe-1 total tableSum'
                        },
                        {
                          value: (total && total.count) || 0,
                          colspan: 1,
                          className: 'tableSum'
                        },
                        {
                          value: (total && total.amount) || 0,
                          colspan: 1,
                          className: 'tableSum'
                        },
                        {
                          value: (total && total.validAmount) || 0,
                          colspan: 1,
                          className: 'tableSum'
                        },
                        {
                          value: (total && total.totalWinLost) || 0,
                          colspan: 1,
                          className: 'tableSum'
                        },
                        {
                          render: () => {
                            if (total) {
                              return (
                                <Label className="d-flex justify-content-center combineBodyRowCell">
                                  <div className={`${parseFloat(total.receiveable) < 0 ? 'numberRed' : 'numberBlue'}`}>{total.receiveable}</div>
                                  <div>{total.zcAmount}</div>
                                  <div>{total.zcWinLost}</div>
                                  <div>{total.zcRebate}</div>
                                  <div>{total.earnAmount}</div>
                                  {
                                     userInfo.levelValue >= userInfo?.zdLevel
                                       ? <div>{total.earnOdds}</div>
                                       : ''
                                  }
                                  <div className={`${parseFloat(total?.totalResult || 0) < 0 ? 'numberRed' : 'numberBlue'}`}>{total?.totalResult ?? 0}</div>
                                </Label>
                              );
                            }

                            return 0;
                          },
                          colspan: 1,
                          className: 'tableSum noPadding'
                        },
                        {
                          value: (total && total.uplineAmount) || 0,
                          colspan: 1,
                          className: `tableSum ${parseFloat(total?.uplineAmount || 0) < 0 ? 'numberRed' : 'numberBlue'}`
                        },
                        {
                          value: (total && total.uplineResult) || 0,
                          colspan: 1,
                          className: `tableSum ${parseFloat(total?.uplineResult || 0) < 0 ? 'numberRed' : 'numberBlue'}`
                        }]
                      ]}
                    />
                  )
                  : (
                    <AgentDataTable
                      isQuerying={_isQueryingDetail}
                      tableHeaderTitle={
                        [{
                          label: '账号',
                        }, {
                          label: '名称',
                        }, {
                          label: '笔数',
                        }, {
                          label: '下注金额',
                        }, {
                          label: '有效金额',
                        }, {
                          label: (
                            <div className="combineTitleCell">
                              <div className="h-50 py-1">会员输赢</div>
                              <div className="d-flex h-50">
                                <div className="py-1">输赢</div>
                                <div className="py-1">退水</div>
                                <div className="py-1">盈亏结果</div>
                              </div>
                            </div>
                          ),
                        }, {
                          label: (
                            <div className="combineTitleCell">
                              <div className="h-50 py-1">{`${userInfo?.levelName ?? '本级'}输赢`}</div>
                              <div className="d-flex h-50">
                                <div className="py-1">应收下线</div>
                                <div className="py-1">占成</div>
                                <div className="py-1">实占金额</div>
                                <div className="py-1">实占结果</div>
                                <div className="py-1">实占退水</div>
                                <div className="py-1">赚水</div>
                                {
                                  userInfo.levelValue >= userInfo?.zdLevel
                                    ? <div className="py-1">赚赔</div>
                                    : ''
                                }
                                <div className="py-1">盈亏结果</div>
                              </div>
                            </div>
                          ),
                        }, {
                          label: '上交货量',
                        }, {
                          label: '上级交收',
                        }]
                      }
                      tableHeaderStyle={['', '', '', '', '', '', '', 'p-0', 'p-0', '', '']}
                      tableData={list || []}
                      tableBodyRowStyle={['', '', '', '', '', '', '', 'p-0', 'p-0', '', '']}
                      tableBodyRowProperty={
                        [
                          data => (
                            <Label
                              className="d-flex justify-content-center numberBlue detailLink"
                              onClick={() => {
                                if (data.level === 12) {
                                  setClassificationInfo(data);
                                  setSpecialLoginName(data.loginName);
                                }
                              }}
                            >
                              {data.loginName}
                            </Label>
                          ),
                          data => (<Label className="d-flex justify-content-center">{data.orgName}</Label>),
                          data => (<Label className="d-flex justify-content-center">{data.count}</Label>),
                          data => (<Label className="d-flex justify-content-center numberBlue">{data.amount}</Label>),
                          data => (<Label className="d-flex justify-content-center">{data.validAmount}</Label>),
                          data => (
                            <Label className="d-flex justify-content-center combineBodyRowCell">
                              <div>{data.winLost}</div>
                              <div>{data.rebate}</div>
                              <div>{data.totalWinLost}</div>
                            </Label>
                          ),
                          data => (
                            <Label className="d-flex justify-content-center combineBodyRowCell">
                              <div className={`${parseFloat(data.receiveable) < 0 ? 'numberRed' : 'numberBlue'}`}>{data.receiveable}</div>
                              <div>{data.zc}</div>
                              <div>{data.zcAmount}</div>
                              <div>{data.zcWinLost}</div>
                              <div>{data.zcRebate}</div>
                              <div>{data.earnAmount}</div>
                              {
                                userInfo.levelValue >= userInfo?.zdLevel
                                  ? <div>{data.earnOdds}</div>
                                  : ''
                              }

                              <div className={`${parseFloat(data.totalResult) < 0 ? 'numberRed' : 'numberBlue'}`}>{data.totalResult}</div>
                            </Label>
                          ),
                          data => (<Label className="d-flex justify-content-center">{data.uplineAmount}</Label>),
                          data => (<Label className="d-flex justify-content-center numberBlue">{data.uplineResult}</Label>),
                        ]
                      }
                      tableFooter={[
                        [{
                          value: `合计：${list ? list.length : 0}行`,
                          colspan: 2,
                          className: 'pe-1 total tableSum'
                        },
                        {
                          value: (total && total.count) || 0,
                          colspan: 1,
                          className: 'tableSum'
                        },
                        {
                          value: (total && total.amount) || 0,
                          colspan: 1,
                          className: 'tableSum'
                        },
                        {
                          value: (total && total.validAmount) || 0,
                          colspan: 1,
                          className: 'tableSum'
                        },
                        {
                          render: () => {
                            if (total) {
                              return (
                                <Label className="d-flex justify-content-center combineBodyRowCell">
                                  <div>{total.winLostAmount}</div>
                                  <div>{total.rebate}</div>
                                  <div>{total.totalWinLost}</div>
                                </Label>
                              );
                            }

                            return 0;
                          },
                          colspan: 1,
                          className: 'tableSum noPadding'
                        },
                        {
                          render: () => {
                            if (total) {
                              return (
                                <Label className="d-flex justify-content-center combineBodyRowCell">
                                  <div className={`${parseFloat(total.receiveable) < 0 ? 'numberRed' : 'numberBlue'}`}>{total.receiveable}</div>
                                  <div>{total.amount}</div>
                                  <div>{total.zcAmount}</div>
                                  <div>{total.zcWinLost}</div>
                                  <div>{total.zcRebate}</div>
                                  <div>{total.earnAmount}</div>
                                  {
                                      userInfo.levelValue >= userInfo?.zdLevel
                                        ? <div>{total.earnOdds}</div>
                                        : ''
                                  }
                                  <div className={`${parseFloat(total.totalResult) < 0 ? 'numberRed' : 'numberBlue'}`}>{total.totalResult}</div>
                                </Label>
                              );
                            }

                            return 0;
                          },
                          colspan: 1,
                          className: 'tableSum noPadding'
                        },
                        {
                          value: (total && total.uplineAmount) || 0,
                          colspan: 1,
                          className: 'tableSum'
                        },
                        {
                          value: (total && total.uplineResult) || 0,
                          colspan: 1,
                          className: 'tableSum numberBlue'
                        }]
                      ]}
                    />
                  )}

              </div>
            </div>
            {
              _specialLoginName
              && (
              <div className="mt-2">
                <WinLostReportDetail presetObj={{
                  ...classificationInfo,
                  beginDate: _date[0],
                  endDate: _date[1],
                  lotteryType: classificationInfo?.lotteryType,
                  isBuhuo: classificationInfo?.isBuhuo,
                  userId: classificationInfo?.orgId ?? '',
                  memberLoginId: classificationInfo?.loginName ?? '',
                  settingCode: classificationInfo?.settingCode,
                  orgId,
                  settlementStatus: isSettled
                }}
                />
              </div>
              )
            }
          </>
        )
      }
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LotteryReport);