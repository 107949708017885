import { StatusCodes } from 'http-status-codes';

import { SET_BATCH_ODDS_LIST, SET_ODDS_SETTING } from '../actionTypes';
import { apiService } from '../../../library';

import appActions from '../appActions';

const setBatchOddsList = list => ({
  type: SET_BATCH_ODDS_LIST,
  value: list,
});

const setOddsSetting = list => ({
  type: SET_ODDS_SETTING,
  value: list,
});

const updateBatchOddsList = list => dispatch => {
  dispatch(setBatchOddsList(list));
};


const getInfo = (obj, calllback = () => {}) => dispatch => {
  apiService.get('/futureoddssetting/GetInfo', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      calllback(false);
      dispatch(setOddsSetting(data.result));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    calllback(false);
    Promise.reject(error);
  });
};

const SaveOdds = (obj, calllback = () => {}) => dispatch => {
  apiService.post('/futureoddssetting/saveodds', obj).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        dispatch(appActions.updateModalAlert({
          visible: false
        }));
        return calllback();
      }
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};

const QuickSaveOdds = (obj, calllback = () => {}) => dispatch => {
  apiService.post('/futureoddssetting/quicksaveodds', obj).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        dispatch(appActions.updateModalAlert({
          visible: false
        }));
        return calllback();
      }
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  }).finally(() => {
    dispatch(updateBatchOddsList());
  });
};

export default {
  SaveOdds,
  QuickSaveOdds,
  updateBatchOddsList,
  getInfo,
};