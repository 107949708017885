import { SET_CLICK_RELOAD_FLAG, SET_MODAL_ALERT, SET_MODAL_MESSAGE, SET_THEME_COLOR, TOGGLE_HORIZONTAL } from '../../actions/actionTypes';

const initialState = {
  themeColor: 'blue',
  isHorizontal: true,
  modalAlert: {
    visible: false,
  },
  modalMessage: undefined,
  clickReloadFlag: 1,
};

const appReducers = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_HORIZONTAL:
      return {
        ...state, isHorizontal: !state.isHorizontal
      };

    case SET_MODAL_ALERT:
      return {
        ...state, modalAlert: action.value
      };
    case SET_MODAL_MESSAGE:
      return {
        ...state, modalMessage: action.value
      };
    case SET_THEME_COLOR:
      return {
        ...state, themeColor: action.value
      };

    case SET_CLICK_RELOAD_FLAG:
      return {
        ...state, clickReloadFlag: action.value
      };
    default:
      return state;
  }
};

export default appReducers;