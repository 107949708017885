import { dxds, hdxds, specialBig, specialdSmall, tdxds, thdxds, totalSumDS, totalSumDX, totalZodiacDS, totalds, totaldx, twdx, wdx } from '../Items/dsdx';

import { blueSet, greenSet, redSet, threeColors } from '../Items/colors';
import { drawMatch, element, head, nineTails, tFrontBack, tHouseWild, tSkyEarth, zodiac, zodiac2to7 } from '../Items/others';

import { number1to49 } from '../Items/numbers';

const HK6lianMa = (data = '') => {
  let result = [];
  if (data) {
    result = data.split(',').map(item => {
      const _obj = number1to49.find(x => x.displayName === item);
      return {
        ..._obj
      };
    });
  }
  return result;
};

const HK6ShengXiao = (data = '') => {
  let result = [];
  if (data) {
    result = data.split(',').map(item => {
      const _obj = zodiac.find(x => x.displayName === item);
      return {
        ..._obj
      };
    });
  }
  return result;
};

const TeMaHighlight = (result = '') => {
  if (result?.length > 6) {
    const temp = result.split(',');
    return temp[6];
  }
  return '';
};

const HK6LianWei = (data = '') => {
  let result = [];
  if (data) {
    result = data.split(',').map(item => ({
      displayName: item, className: 'mx-1'
    }));
  }
  return result;
};

const CJ_HK6 = [
  {
    displayName: '特码',
    displayRows: 16,
    display: [...number1to49, ...tdxds, ...thdxds, ...twdx, ...threeColors],
    tabId: 'count_TM',
    children: [
      {
        tabId: 'count_TM',
        label: '全部',
        prefix: '',
        midcode: 'TMA,TMDSDX,TMSB',
        midSubCategory: '-1'
      },
      {
        tabId: 'count_TMA',
        label: '特码A',
        prefix: '',
        midcode: 'TMA,TMDSDX,TMSB',
        midSubCategory: '特A'
      }, {
        tabId: 'count_TMB',
        label: '特码B',
        midcode: 'TMA,TMDSDX,TMSB',
        midSubCategory: '特B'
      }
    ],
    TeMaHighlight
  },
  {
    displayName: '特码总项1',
    displayRows: 16,
    display: [...number1to49, ...tdxds, ...thdxds, ...twdx, ...threeColors],
    midcode: 'TMA,TMDSDX,TMSB',
    type: '111',
    TeMaHighlight
  },
  {
    displayName: '特码总项2',
    displayRows: 49,
    display: [...number1to49],
    midcode: 'TMA',
    type: '110',
    TeMaHighlight
  },
  {
    displayName: '两面',
    displayRows: 12,
    midcode: 'TMDSDX,LM',
    display: [...tdxds, ...thdxds, ...twdx, ...tSkyEarth, ...tFrontBack, ...tHouseWild, ...totalSumDS, ...totalSumDX, ...specialBig, ...specialdSmall],
    horizontalDataAlign: 1,
    horizontalSet: 2,
    tabId: 'count_LM',
  }, {
    displayName: '正码特',
    displayRows: 16,
    display: [...number1to49, ...dxds, ...hdxds, ...wdx, ...threeColors],
    tabId: 'count_ZMT_ALL',
    children: [
      {
        tabId: 'count_Z1T',
        label: '正1特',
        midcode: 'ZMT1,ZM1LM,ZM1SB',
      }, {
        tabId: 'count_Z2T',
        label: '正2特',
        midcode: 'ZMT2,ZM2LM,ZM2SB',
      }, {
        tabId: 'count_Z3T',
        label: '正3特',
        midcode: 'ZMT3,ZM3LM,ZM3SB',
      }, {
        tabId: 'count_Z4T',
        label: '正4特',
        midcode: 'ZMT4,ZM4LM,ZM4SB',
      }, {
        tabId: 'count_Z5T',
        label: '正5特',
        midcode: 'ZMT5,ZM5LM,ZM5SB',
      }, {
        tabId: 'count_Z6T',
        label: '正6特',
        midcode: 'ZMT6,ZM6LM,ZM6SB',
      }
    ]
  }, {
    displayName: '正码',
    midcode: 'ZMA,ZMLM',
    displayRows: 14,
    display: [...number1to49, ...totalds, ...totaldx],
    tabId: 'count_ZM',
  },
  {
    displayName: '正码1-6',
    midcode: 'ZM1LM,ZM1SB,ZM2LM,ZM2SB,ZM3LM,ZM3SB,ZM4LM,ZM4SB,ZM5LM,ZM5SB,ZM6LM,ZM6SB',
    tabId: 'count_ZM16',
    isStaticRow: 1,
    // combineStyle: 1,
    repeatPerRow: 3,
    repeatPerCol: 2,
    prefix: ['ZM1', 'ZM2', 'ZM3', 'ZM4', 'ZM5', 'ZM6'],
    display: [[...dxds, ...hdxds, ...wdx, ...threeColors],
      [...dxds, ...hdxds, ...wdx, ...threeColors],
      [...dxds, ...hdxds, ...wdx, ...threeColors],
      [...dxds, ...hdxds, ...wdx, ...threeColors],
      [...dxds, ...hdxds, ...wdx, ...threeColors],
      [...dxds, ...hdxds, ...wdx, ...threeColors]],
    firstTableHeader: ['正码1', '正码2', '正码3', '正码4', '正码5', '正码6'],
  },
  {
    displayName: '色波',
    tabId: 'count_SB',
    midcode: 'TMSB,TBBDS,TBBDX,TMBBB,7SB',
    isStaticRow: 1,
    displayRows: 9,
    display: [[...redSet], [...blueSet], [...greenSet], [...threeColors, ...drawMatch]],
  },
  {
    displayName: '连码',
    displayRows: 10,
    display: [...number1to49],
    isLianMa: 1,
    lianMaMode: {
      rowProperty: [HK6lianMa]
    },
    tabId: 'count_LM_ALL',
    children: [
      {
        tabId: 'count_3QZ',
        label: '三全中',
        settingCode: '3QZ',
      },
      {
        tabId: 'count_3Z2',
        label: '三中二',
        settingCode: '3Z2',
      }, {
        tabId: 'count_2QZ',
        label: '二全中',
        settingCode: '2QZ',
      }, {
        tabId: 'count_2ZT',
        label: '二中特',
        settingCode: '2ZT',
      }, {
        tabId: 'count_TC',
        label: '特串',
        settingCode: 'TC',
      }
    ]
  },
  {
    isStaticRow: 1,
    displayName: '一肖尾数',
    midcode: 'PTYX,PTWS',
    display: [[...zodiac], [...nineTails]],
    firstTableHeader: ['一肖', '尾数'],
    tabId: 'count_PTYX',
  },
  {
    isStaticRow: 1,
    displayName: '一肖不中',
    midcode: 'YXBZ',
    display: [[...zodiac]],
    firstTableHeader: ['一肖不中'],
    tabId: 'count_YXBZ',
  },
  {
    isStaticRow: 1,
    displayName: '尾数不中',
    midcode: 'WSBZ',
    display: [[...nineTails]],
    firstTableHeader: ['尾数不中'],
    tabId: 'count_WSBZ',
  },
  {
    isStaticRow: 1,
    displayName: '头尾五行',
    midcode: 'TMTS,TMWS,TMWX',
    display: [[...nineTails], [...head], [...element]],
    firstTableHeader: ['特码尾数', '特码头数', '五行'],
    tabId: 'count_TMTS',
  },
  {
    isStaticRow: 1,
    displayName: '特正总肖',
    midcode: 'TMSX,ZENGX,ZX,ZXDS',
    display: [[...zodiac], [...zodiac], [...zodiac2to7, ...totalZodiacDS]],
    prefix: ['TMSX', 'ZENGX', 'ZX'],
    firstTableHeader: ['特肖', '正肖', '总肖'],
    tabId: 'count_TZZX',
  },
  {
    displayName: '合肖',
    settingCode: 'TMHX',
    displayRows: 5,
    horizontalSet: 4,
    horizontalDataAlign: 1,
    tabId: 'count_TMHX',
    isLianMa: 1,
    lianMaMode: {
      rowProperty: [HK6ShengXiao]
    },
  },
  {
    displayName: '连肖',
    displayRows: 6,
    display: [...zodiac],
    isLianMa: 1,
    lianMaMode: {
      rowProperty: [HK6ShengXiao]
    },
    tabId: 'count_LX_ALL',
    children: [
      {
        tabId: 'count_2LX',
        label: '二肖连',
        settingCode: '2LX',
      }, {
        tabId: 'count_2LXBZ',
        label: '二肖不中',
        settingCode: '2LXBZ',
      }, {
        tabId: 'count_3LX',
        label: '三肖连',
        settingCode: '3LX',
      }, {
        tabId: 'count_3LXBZ',
        label: '三肖不中',
        settingCode: '3LXBZ',
      }, {
        tabId: 'count_4LX',
        label: '四肖连',
        settingCode: '4LX',
      }, {
        tabId: 'count_4LXBZ',
        label: '四肖不中',
        settingCode: '4LXBZ',
      }, {
        tabId: 'count_5LX',
        label: '五肖连',
        settingCode: '5LX',
      }, {
        tabId: 'count_5LXBZ',
        label: '五肖不中',
        settingCode: '5LXBZ',
      }
    ]
  },
  {
    displayName: '连尾',
    displayRows: 5,
    isLianMa: 1,
    lianMaMode: {
      rowProperty: [HK6LianWei]
    },
    display: [...nineTails],
    tabId: 'count_LW_ALL',
    children: [
      {
        tabId: 'count_2LW',
        label: '二尾连',
        settingCode: '2LW',
      }, {
        tabId: 'count_2LWBZ',
        label: '二尾不中',
        settingCode: '2LWBZ',
      }, {
        tabId: 'count_3LW',
        label: '三尾连',
        settingCode: '3LW',
      }, {
        tabId: 'count_3LWBZ',
        label: '三尾不中',
        settingCode: '3LWBZ',
      }, {
        tabId: 'count_4LW',
        label: '四尾连',
        settingCode: '4LW',
      }, {
        tabId: 'count_4LWBZ',
        label: '四尾不中',
        settingCode: '4LWBZ',
      }, {
        tabId: 'count_5LW',
        label: '五尾连',
        settingCode: '4LW',
      }, {
        tabId: 'count_5LWBZ',
        label: '五尾不中',
        settingCode: '5LWBZ',
      }
    ]
  },
  {
    displayName: '自选不中',
    displayRows: 13,
    display: [...number1to49],
    isLianMa: 1,
    lianMaMode: {
      rowProperty: [HK6lianMa]
    },
    tabId: 'count_BZ_ALL',
    children: [
      {
        tabId: 'count_5BZ',
        label: '五不中',
        midcode: 'ZX5BZ',
      }, {
        tabId: 'count_6BZ',
        label: '六不中',
        midcode: 'ZX6BZ',
      }, {
        tabId: 'count_7BZ',
        label: '七不中',
        midcode: 'ZX7BZ',
      }, {
        tabId: 'count_8BZ',
        label: '八不中',
        midcode: 'ZX8BZ',
      }, {
        tabId: 'count_9BZ',
        label: '九不中',
        midcode: 'ZX9BZ',
      }, {
        tabId: 'count_10BZ',
        label: '十不中',
        midcode: 'ZX10BZ',
      }, {
        tabId: 'count_11BZ',
        label: '十一不中',
        midcode: 'ZX11BZ',
      }
    ]
  }
];

export default CJ_HK6;