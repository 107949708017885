import { useEffect, useState } from 'react';

import { Button, Col, Label, Row } from 'reactstrap';
import { Checkbox, Select, Spin } from 'antd';
import { IoClose } from 'react-icons/io5';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { agentActions, appActions, companyActions } from '../../../redux/actions';
import AgentDataTable from '../../AgentDataTable';

import './styles.scss';

const mapStateToProps = state => ({
  platform: state.appReducers.platform,
  upOrgsCopyData: state.companyReducers.upOrgsCopyData,
});

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  getCopyUpOrgs: (id, orgLevel) => dispatch(companyActions.getCopyUpOrgs(id, orgLevel)),
  copyOddsDiff: (obj, callback) => dispatch(agentActions.copyOddsDiff(obj, callback)),
});

const { Option } = Select;

const CopyOddsDiffModal = ({ updateModalAlert, data, getCopyUpOrgs, upOrgsCopyData, copyOddsDiff }) => {
  const { t } = useTranslation();
  const [_selectedField, setSelectedField] = useState();
  const [_warningText, setWarningText] = useState([]);
  const [_loading, setIsLoading] = useState(false);
  const [allLotteryList, setAllLotteryList] = useState();
  const [selectedItems, setSelectedItems] = useState(new Map());

  const generateMenu = lotteryList => {
    const content = [];
    const temp = new Map();
    (lotteryList).forEach(item => {
      if (temp.has(item.categoryName)) {
        const _tempArr = [...temp.get(item.categoryName), item];
        temp.set(item.categoryName, _tempArr);
      } else {
        temp.set(item.categoryName, [item]);
      }
    });

    temp.forEach((item, key) => {
      if (item.length !== 0) {
        const title = key;
        const tempList = item.map(item => ({
          ...item
        }));
        content.push(
          {
            title,
            subGmes: tempList
          }
        );
      }
    });
    return content;
  };

  useEffect(() => {
    if (data?.lotteryList && data?.orgId) {
      getCopyUpOrgs(data?.orgId, 2);
      const temp = generateMenu(data.lotteryList);
      setAllLotteryList(temp);
    }
  }, [data]);

  const saveButton = () => {
    let lotteryTypes = [];
    selectedItems.forEach(value => {
      lotteryTypes = lotteryTypes.concat(value);
    });
    if (lotteryTypes?.length > 0 && _selectedField) {
      setIsLoading(true);
      updateModalAlert({
        type: 'loading',
        visible: true
      });
      const obj = {
        fromOrg: _selectedField,
        toOrg: data?.toOrgId,
        lotteryTypes,
      };
      copyOddsDiff(obj, data.onConfirmBtnClick);
      setWarningText('');
    } else {
      setWarningText('请选择代理');
    }
  };

  const onClickToSelect = (category, lotteryCode) => {
    const allTempData = new Map(selectedItems);
    if (Array.isArray(lotteryCode)) {
      if (allTempData.has(category) && allTempData.get(category)?.length > 0) {
        allTempData.set(category, []);
      } else {
        allTempData.set(category, lotteryCode.map(x => x.code));
      }
    } else if (allTempData.has(category)) {
      let temp = [].concat(allTempData.get(category));
      if (temp.includes(lotteryCode)) {
        temp = temp.filter(x => x !== lotteryCode);
      } else {
        temp.push(lotteryCode);
      }
      allTempData.set(category, temp);
    } else {
      allTempData.set(category, [lotteryCode]);
    }
    setSelectedItems(allTempData);
  };

  const tableHeader = [{
    label: '彩种选择',
    colSpan: 2
  }];

  const tableContent = [
    data => (
      <div className="d-flex justify-content-end align-items-center m-1 p-1">
        <Label className="px-2">{data?.title}</Label>
        <Checkbox
          onChange={() => onClickToSelect(data.title, data.subGmes)}
          checked={selectedItems.has(data.title) && (selectedItems.get(data.title)?.length === data.subGmes?.length)}
        >
          所有
        </Checkbox>
      </div>
    ),
    data => (
      <div className="flex-wrap p-0 m-0 d-flex">
        {
          data?.subGmes.map(d => (
            <Checkbox
              onChange={() => onClickToSelect(data.title, d.code)}
              checked={selectedItems.has(data.title) && selectedItems.get(data.title).includes(d.code)}
            >
              {d.lotteryName}
            </Checkbox>
          ))
        }
      </div>
    ),
  ];

  return (
    <div className="copyOddsDiffModalWrapper">
      {
        _loading && (
          <div className="black loadingwrapper justify-content-center">
            <Spin />
          </div>
        )
      }
      <Row className="modalHeader">
        <Col
          className={`${'d-flex align-items-center'}`}
        >
          退水赔率差复制
        </Col>
        <Col className="d-flex justify-content-end align-items-center">
          <IoClose
            onClick={() => {
              updateModalAlert({
                visible: false
              });
              if (data && data.overBet && data.onConfirmationClick) data.onConfirmationClick();
            }}
            className="h4 mb-0 float-right closeIcon"
          />
        </Col>
      </Row>
      <Row className="modalContent d-flex justify-content-center">
        <Row className="p-2 my-2 align-items-left">
          <Col>
            <Label className="justify-content-center">数据来源：</Label>
            <Select
              className="copoyDiffInputStyle"
              value={_selectedField}
              placeholder="请选择"
              onChange={item => setSelectedField(item)}
            >
              {
                upOrgsCopyData && upOrgsCopyData.map(d => d?.organizationId !== data?.orgId && (
                  <Option key={`returnSelection_${d?.organizationId}`} value={d.organizationId}>{`从 ${d.loginId} 复制`}</Option>
                ))
              }
            </Select>
            <Label className="px-2 text-danger">{_warningText}</Label>
          </Col>
        </Row>
        <Row className="tableWrapper d-flex p-2 justify-content-center">
          <AgentDataTable
            rowSpanHeader={tableHeader}
            tableHeaderTitle={[]}
            tableData={allLotteryList || []}
            tableBodyRowProperty={tableContent}
            tableBodyRowStyle={['backgroundGrey', 'align-content-start']}
          />
        </Row>
      </Row>
      <Row className="modalFooter d-flex align-items-center">
        <Button
          className={`confirmButton my-2 ${'me-3'}`}
          onClick={() => saveButton()}
        >
          {(data && data.confirmText) || t('CONFIRM')}
        </Button>
        <Button
          className={`confirmButton my-2 ${'me-3'}`}
          onClick={() => {
            updateModalAlert({
              visible: false
            });
          }}
        >
          {(data && data.confirmText) || t('CANCEL')}
        </Button>
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CopyOddsDiffModal);