import { SET_IN_TIME_SHEET_INFO, SET_LIST_ODDS, SET_PRE_AMOUNT } from '../../actions/actionTypes';

const initialState = {
  inTimeSheetOdds: undefined,
  inTimeSheetInfo: undefined,
  presetAmountList: [0.01, 0.02, 0.1, 0.5, 1],
};

const inTimeSheetReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_LIST_ODDS:
      return {
        ...state, inTimeSheetOdds: action.value
      };
    case SET_IN_TIME_SHEET_INFO:
      return {
        ...state, inTimeSheetInfo: action.value
      };
    case SET_PRE_AMOUNT:
      return {
        ...state, presetAmountList: action.value
      };
    default:
      return state;
  }
};

export default inTimeSheetReducers;