import { SET_TRANSACTION_DETAIL, SET_TRANSACTION_FLOW, SET_TRANSACTION_MANAGE } from '../../actions/actionTypes';

const initialState = {
  transactionManage: undefined,
  transactionFlows: undefined,
  transactionDetail: undefined,
};

const transactionReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_TRANSACTION_MANAGE:
      return {
        ...state, transactionManage: action.value
      };
    case SET_TRANSACTION_FLOW:
      return {
        ...state, transactionFlows: action.value
      };
    case SET_TRANSACTION_DETAIL:
      return {
        ...state, transactionDetail: action.value
      };
    default:
      return state;
  }
};

export default transactionReducers;