import { useEffect, useState } from 'react';

import { Input } from 'antd';
import { Label } from 'reactstrap';
import { MdArrowBack } from 'react-icons/md';
import { connect } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { AgentDataTable, CommonHeader, Pagination } from '../..';
import { appActions, whiteListActions } from '../../../redux/actions';

import './styles.scss';

const mapStateToProps = state => ({
  company: state.whiteListReducers.company,
  editWhite: state.whiteListReducers.editWhite,
  subAccountList: state.whiteListReducers.subAccountList,
  superUser: state.whiteListReducers.superUser,
  userData: state.memberReducers.userData,
});
const mapDispatchToProps = dispatch => ({
  getInfo: (obj, callback) => dispatch(whiteListActions.getInfo(obj, callback)),
  SaveInfo: (obj, callback) => dispatch(whiteListActions.SaveInfo(obj, callback)),
  getEditInfo: (obj, callback) => dispatch(whiteListActions.EditInfo(obj, callback)),
  getSubAccountList: (obj, callback) => dispatch(whiteListActions.getSubAccountList(obj, callback)),
  getSuperUser: callback => dispatch(whiteListActions.getSuperUser(callback)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
});

const WhitelistManagement = ({ updateModalAlert, userData, company, getInfo, SaveInfo, editWhite, getEditInfo, getSubAccountList, subAccountList, superUser, getSuperUser }) => {
  const { t } = useTranslation();
  const [_isQuerying, setIsQuerying] = useState(false);
  const [_pageFlag, setPageFlag] = useState(0);

  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(15);

  const [listType, setListType] = useState(1);
  const [loginId, setloginId] = useState('');
  const [agentId, setagentId] = useState('');
  const [ip, setip] = useState('');

  useEffect(() => {
    setIsQuerying(true);
    setPage(1);
    setRows(15);
    setloginId('');
    if (listType === 1) {
      const _obj = {
        page: 1,
        rows: 15,
        loginId
      };
      getInfo(_obj, () => setIsQuerying(false));
    }
    if (listType === 2) {
      const _obj = {
        page: 1,
        rows: 15,
        agentId
      };
      getSubAccountList(_obj, () => setIsQuerying(false));
    }
    if (listType === 0) {
      getSuperUser(() => setIsQuerying(false));
    }
  }, [listType]);

  const saveAndEdit = () => {
    const invalid = ip.split(',').some(i => i && !ValidateIPaddress(i));
    if (invalid) {
      return updateModalAlert({
        type: 'confirmation',
        visible: true,
        showCancelButton: true,
        data: {
          message: '请输入正确的IP格式',
        },
      });
    }
    if (_pageFlag === 2) {
      const obj = {
        id: editWhite.id,
        ip
      };
      const callback = () => {
        setPageFlag(0);
        if (listType === 1) {
          const _obj = {
            page,
            rows,
            loginId
          };
          getInfo(_obj, () => setIsQuerying(false));
        }
        if (listType === 2) {
          const _obj = {
            page,
            rows,
            loginId,
            agentId
          };
          getSubAccountList(_obj, () => setIsQuerying(false));
        }
        if (listType === 0) {
          getSuperUser(() => setIsQuerying(false));
        }
      };
      SaveInfo(obj, callback);
    }
  };


  const onSearchClick = () => {
    setIsQuerying(true);
    if (listType === 1) {
      const _obj = {
        page: 1,
        rows: 15,
        loginId
      };
      getInfo(_obj, () => setIsQuerying(false));
    }
    if (listType === 2) {
      const _obj = {
        page: 1,
        rows: 15,
        loginId,
        agentId
      };
      getSubAccountList(_obj, () => setIsQuerying(false));
    }
    setPage(1);
    setRows(15);
  };

  const onPaginationClick = (pageNum, rows) => {
    setIsQuerying(true);
    setPage(pageNum);
    setRows(rows);
    if (listType === 1) {
      const _obj = {
        page: pageNum,
        rows,
        loginId
      };
      getInfo(_obj, () => setIsQuerying(false));
    }
    if (listType === 2) {
      const _obj = {
        page,
        rows,
        loginId,
        agentId
      };
      getSubAccountList(_obj, () => setIsQuerying(false));
    }
  };

  useEffect(() => {
    if (_pageFlag === 0) {
      setip('');
    }
    if (_pageFlag === 2 && editWhite) {
      setip(editWhite?.ip || '');
    }
  }, [_pageFlag, editWhite]);

  const onClickGoBack = () => {
    if (listType === 0) {
      setPageFlag(0);
    }
    if (listType === 1) {
      setPageFlag(0);
    }
    if (listType === 2) {
      setloginId('');
      if (_pageFlag === 2) {
        setPageFlag(0);
      } else {
        setListType(1);
      }
    }
  };

  function ValidateIPaddress(ipaddress) {
    if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress)) {
      return true;
    }
    return false;
  }

  return (
    <div className="WhitelistManagementWrapper">
      <CommonHeader
        headerName={(
          <div className="d-flex align-items-center">
            {(_pageFlag !== 0 || listType === 2)
              && (
                <div className="d-flex align-items-center me-2 backIcon" onClick={() => onClickGoBack()}>
                  <MdArrowBack className="h5 mb-0 text-white" />
                  返回
                </div>
              )}
            {`${_pageFlag === 0 ? listType === 2 ? `${agentId} / 子账号` : `${t('WHITE_LIST_MANAGEMENT')}` : (listType === 2 ? `${agentId} / ${loginId} / 编辑` : `${agentId} / 编辑`)}`}

          </div>
        )}
      />
      {_pageFlag === 0 && (
        <>
          <div className="whiteListMenu">
            <div className={`menu_item ${(listType === 1 || listType === 2) && 'selected'}`} onClick={() => setListType(1)}>公司、子账号</div>
            {
              userData?.levelValue === 0 && (
                <div className={`menu_item ${(listType === 0) && 'selected'}`} onClick={() => setListType(0)}>超级用户</div>
              )
            }
          </div>
          <div className="tableWrapper">
            {
              listType !== 0
              && (
                <div className="addNewGameBox mb-3">
                  <div className="d-flex justify-content-between align-items-center">

                    <div className="d-flex align-items-center">
                      <div className="mx-2 d-flex justify-content-center align-items-center">
                        <div className="text-max">
                          {listType === 1 ? '公司账号' : '子账号'}
                        </div>
                        <Input className="ms-2" value={loginId} onChange={e => setloginId(e.target.value)} />
                      </div>
                      <div className="operationBtn mx-2 add" onClick={() => onSearchClick()}>查询</div>
                    </div>
                  </div>
                </div>
              )
            }
            {listType === 1
              && (
                <div className="mx-0 bg-white px-0 border-1">
                  <AgentDataTable
                    isQuerying={_isQuerying}
                    tableHeaderTitle={
                      [{
                        label: '公司名称',
                      }, {
                        label: '公司账号',
                      }, {
                        label: '新增日期',
                      }, {
                        label: t('OPERATION'),
                      }]
                    }
                    tableHeaderStyle={
                      []
                    }
                    tableData={company ? company.whiteList : []}
                    tableBodyRowProperty={
                      [
                        data => (<Label className="d-flex justify-content-center">{data.name}</Label>),
                        data => (<Label className="d-flex justify-content-center">{data.loginId}</Label>),
                        data => (<Label className="d-flex justify-content-center">{moment.unix(data.date).format('YYYY-MM-DD HH:mm:ss')}</Label>),
                        data => (
                          <Label className="d-flex justify-content-center align-items-center">
                            <div
                              className="operationBtn mx-2"
                              onClick={() => {
                                setListType(2);
                                setagentId(data.loginId);
                                setloginId('');
                              }}
                            >
                              子账号
                            </div>
                            <div
                              className="operationBtn mx-2"
                              onClick={() => {
                                getEditInfo({
                                  loginId: data.loginId
                                }, () => { setPageFlag(2); setagentId(data.loginId); });
                              }}
                            >
                              IP白名单
                            </div>
                          </Label>
                        )
                      ]
                    }
                  />
                </div>
              )}
            {listType === 2
              && (
                <div className="mx-0 bg-white px-0 border-1">
                  <AgentDataTable
                    isQuerying={_isQuerying}
                    tableHeaderTitle={
                      [{
                        label: '上级账号',
                      }, {
                        label: '名称',
                      }, {
                        label: '账号',
                      }, {
                        label: t('OPERATION'),
                      }]
                    }
                    tableHeaderStyle={
                      []
                    }
                    tableData={subAccountList ? subAccountList.subList : []}
                    tableBodyRowProperty={
                      [
                        data => (<Label className="d-flex justify-content-center">{data.uplineId}</Label>),
                        data => (<Label className="d-flex justify-content-center">{data.name}</Label>),
                        data => (<Label className="d-flex justify-content-center">{data.loginId}</Label>),
                        data => (
                          <Label className="d-flex justify-content-center align-items-center">
                            <div
                              className="operationBtn mx-2"
                              onClick={() => {
                                getEditInfo({
                                  orgId: data.organizationId
                                }, () => { setPageFlag(2); setloginId(data.loginId); });
                              }}
                            >
                              IP白名单
                            </div>
                          </Label>
                        )
                      ]
                    }
                  />
                </div>
              )}
            {listType === 0
              && (
                <div className="mx-0 bg-white px-0 border-1">
                  <AgentDataTable
                    isQuerying={_isQuerying}
                    tableHeaderTitle={
                      [{
                        label: '账号',
                      }, {
                        label: '新增日期',
                      }, {
                        label: t('OPERATION'),
                      }]
                    }
                    tableHeaderStyle={
                      []
                    }
                    tableData={superUser ? superUser.userList : []}
                    tableBodyRowProperty={
                      [
                        data => (<Label className="d-flex justify-content-center">{data.loginId}</Label>),
                        data => (<Label className="d-flex justify-content-center">{moment.unix(data.date).format('YYYY-MM-DD HH:mm:ss')}</Label>),
                        data => (
                          <Label className="d-flex justify-content-center align-items-center">
                            <div
                              className="operationBtn mx-2"
                              onClick={() => {
                                getEditInfo({
                                  orgId: data.id
                                }, () => { setPageFlag(2); setagentId(data.loginId); });
                              }}
                            >
                              IP白名单
                            </div>
                          </Label>
                        )
                      ]
                    }
                  />
                </div>
              )}
          </div>
          <div className="d-flex align-items-center justify-content-center w-full relative">
            <Pagination totalRecord={(superUser || subAccountList || company) ? (listType === 0 ? superUser?.totalRecords || 0 : listType === 2 ? subAccountList?.totalRecords || 0 : company?.totalRecords || 0) : 0} onClick={onPaginationClick} isQuery={false} />
          </div>
        </>
      )}
      {_pageFlag !== 0 && (
        <>
          <div className="my-3 thirdPartyGamesTable">
            <div className="thirdPartyGamesTitle">{_pageFlag === 1 ? '新增地址' : '编辑地址'}</div>
            <div className="thirdPartyGamesRow">
              <div>名称</div>
              <div><Input disabled value={editWhite ? editWhite.name : ''} placeholder="请输入内容" /></div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>账号</div>
              <div><Input disabled value={editWhite ? editWhite.loginId : ''} placeholder="请输入内容" /></div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>IP</div>
              <div>
                <Input.TextArea
                  className="w-textarea"
                  autoSize={{
                    minRows: 4, maxRows: 8
                  }}
                  value={ip}
                  onChange={e => {
                    setip(e.target.value.trim());
                  }}
                  placeholder="多个IP用逗号隔开(留空则不作登录IP白名单设置)，如 127.0.0.1,127.0.0.2"
                />
                {(ip.split(',').some(i => i && !ValidateIPaddress(i)) && <span className="mx-2 text-red">请输入正确的IP格式</span>) || ''}
              </div>
            </div>
          </div>
          <div className="thirdPartyGamesFooter">
            <div className="operationBtn editAndSave me-3" onClick={() => saveAndEdit()}>{t('SAVE')}</div>
            <div className="operationBtn cancel me-3" onClick={() => onClickGoBack()}>{t('CANCEL')}</div>
          </div>
        </>
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(WhitelistManagement);