import { useState } from 'react';

import { Button, Col, Row } from 'reactstrap';
import { Input } from 'antd';
import { IoClose } from 'react-icons/io5';
import { connect } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { appActions, transactionActions } from '../../../redux/actions';

import './styles.scss';

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  SaveRefund: obj => dispatch(transactionActions.SaveRefund(obj)),
});

const OneTimeWithdrawalModal = ({ updateModalAlert, data, SaveRefund }) => {
  const { t } = useTranslation();
  const [amount, setAmount] = useState(data.result.actionAmount);

  return (
    <div className="OneTimeWithdrawalModalWrapperWeb">
      <Row className="modalHeader">
        <Col
          className="d-flex align-items-center"
        >
          {t('REMINDER')}
        </Col>
        <Col className="d-flex justify-content-end align-items-center">
          <IoClose
            onClick={() => {
              updateModalAlert({
                visible: false
              });
              if (data && data.overBet && data.onConfirmationClick) data.onConfirmationClick();
            }}
            className="h4 mb-0 float-right closeIcon"
          />
        </Col>
      </Row>
      <div className="modalContent d-flex justify-content-center mx-0 p-2 ">
        <div className="orderInformationTable">
          <div className="evenRowStyle">
            <div>用户名</div>
            <div>{data && data.result.mName}</div>
            <div>订单号</div>
            <div>{data && data.result.orderID}</div>
          </div>
          <div className="evenRowStyle">
            <div>交易类型</div>
            <div>{data && data.result.actionTypeName}</div>
            <div>交易状态</div>
            <div>{data && data.result.actionStatusName}</div>
          </div>
          <div className="evenRowStyle">
            <div>交易金额</div>
            <div>{data && (`${data.result.actionAmount} (手续费:${data.result.actionFee})`)}</div>
            <div>实际金额</div>
            <div>{data && (data.result.actionAmount - data.result.actionFee)}</div>
          </div>
          <div className="evenRowStyle">
            <div>交易前金额</div>
            <div>{data && data.result.beforeAmount}</div>
            <div>交易后金额</div>
            <div>{data && data.result.afterAmount}</div>
          </div>
          <div className="evenRowStyle">
            <div>交易描述</div>
            <div>{data && data.result.createRemark}</div>
          </div>
          <div className="evenRowStyle">
            <div>交易时间</div>
            <div>{data && moment.unix(data.result.createTime).format('YYYY-MM-DD HH:mm:ss')}</div>
          </div>
          <div className="evenRowStyle">
            <div>银行名称</div>
            <div>{data && (data.result?.toBankName)}</div>
            <div>银行卡号</div>
            <div>{data && (data.result?.toBankNumber)}</div>
          </div>
          <div className="evenRowStyle">
            <div>开户名</div>
            <div>{data && (data.result?.toBankUName)}</div>
            <div>开户地址</div>
            <div>{data && (data.result?.toBankAddress)}</div>
          </div>
          <div className="orderInformationRowBackGroundColor d-flex justify-content-center align-items-center">{`以下是需要补充的客户资料 【本次代付为:${data.result.thirdPayName}】`}</div>

          <div className="evenRowStyle">
            <div>实际金额</div>
            <div>
              <Input value={amount} onChange={text => { if (!Number.isNaN(text)) { setAmount(text === '' ? 0 : parseInt(text, 10)); } }} />
            </div>
          </div>
        </div>
      </div>
      <Row className="modalFooter d-flex align-items-center ">
        <Button
          className="confirmButton my-3"
          onClick={() => {
            updateModalAlert({
              visible: false
            });
            SaveRefund({
              ...data.obj, amount
            });
          }}
        >
          {t('CONFIRM')}
        </Button>
      </Row>
    </div>
  );
};

export default connect(null, mapDispatchToProps)(OneTimeWithdrawalModal);