import { useEffect, useState } from 'react';

import { Button, Checkbox, Input } from 'antd';
import { Col, Label, Row } from 'reactstrap';
import { FaPlusCircle } from 'react-icons/fa';
import { MdArrowBack } from 'react-icons/md';
import { connect } from 'react-redux';

import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { appActions, systemActions } from '../../../redux/actions';
import AgentDataTable from '../../AgentDataTable';

import { CommonHeader } from '../..';
import Pagination from '../../Content/Pagination';
import './styles.scss';


const mapStateToProps = state => ({
  externalWebInfoData: state.systemReducers.externalWebInfoData,
  userData: state.memberReducers.userData,
});

const mapDispatchToProps = dispatch => ({
  getExternalWeb: (userId, callback) => dispatch(systemActions.getExternalWeb(userId, callback)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  updateExternalWebStatus: (obj, callback) => dispatch(systemActions.updateExternalWebStatus(obj, callback)),
  deleteExternalWeb: (obj, callback) => dispatch(systemActions.deleteExternalWeb(obj, callback)),
  GetBuhuoBetList: (obj, callback) => dispatch(systemActions.GetBuhuoBetList(obj, callback)),
});

const ExternalWebManage = ({ GetBuhuoBetList, callback = () => { }, externalWebInfoData, getExternalWeb, data, updateModalAlert, deleteExternalWeb, updateExternalWebStatus }) => {
  const { t } = useTranslation();
  const [_info, setInfo] = useState([]);
  const [_page, setPage] = useState(1);
  const [_rows, setRows] = useState(15);
  const [_totalRecord, setTotalRecord] = useState(0);
  const [isQueryStatus, setQueryStatus] = useState(true);
  const [_searchText, setSearchText] = useState('');
  const [checkAll, setCheckAll] = useState(false);
  const [checkedKeys, setCheckedKeys] = useState(new Set());
  const [pageFlag, setPageFlag] = useState(0); // 0 是正常页面，1 是补货页面单
  const [buhuoBetList, setBuhuoBetList] = useState({}); // 是个对象，包含 betlist totalAmount totalRecords

  useEffect(() => {
    if (externalWebInfoData && JSON.stringify(externalWebInfoData?.webList) !== JSON.stringify(_info)) {
      setInfo(externalWebInfoData?.webList);
      setTotalRecord(externalWebInfoData?.totalRecords);
      setQueryStatus(false);
    }
  }, [externalWebInfoData]);

  useEffect(() => {
    setQueryStatus(true);
    getExternalWeb({
      webName: _searchText,
      page: _page,
      rows: _rows,
    }, setQueryStatus);
  }, []);

  const findClick = () => {
    setQueryStatus(true);
    // Page number and {row number will follow the pagination component instead
    getExternalWeb({
      webName: _searchText,
      page: 1,
      rows: _rows,
    }, setQueryStatus);
  };

  const onPaginationClick = (pageNum, rows) => {
    setQueryStatus(true);
    // Page number and {row number will follow the pagination component instead
    getExternalWeb({
      webName: _searchText,
      page: pageNum,
      rows: _rows,
    }, setQueryStatus);
    setPage(pageNum);
    setRows(rows);
  };
  const deleteClick = id => {
    if (!id) {
      id = [...checkedKeys];
    } else {
      id = [id];
    }
    updateModalAlert({
      type: 'confirmation',
      visible: true,
      showCancelButton: true,
      data: {
        message: '确认删除',
        onConfirmationClick: () => deleteExternalWeb(id)
      },
    });
  };

  const setStatus = id => {
    if (id) {
      updateExternalWebStatus([id]);
    } else {
      updateExternalWebStatus([...checkedKeys]);
    }
  };

  const tableHeader = [{
    label: (
      <Checkbox
        checked={checkAll}
        onChange={e => {
          const { checked } = e.target;
          if (checked) {
            setCheckedKeys(new Set(_info.map(i => i.id)));
          } else {
            setCheckedKeys(new Set());
          }
          setCheckAll(checked);
        }}
      >
        {t('SELECT_ALL')}
      </Checkbox>
    ),
  }, {
    label: t('WEB_NAME'),
  }, {
    label: t('WEB_ADDRESS'),
  }, {
    label: t('REMARK'),
  }, {
    label: t('CREATE_DATE'),
  }, {
    label: t('STATUS'),
  }, {
    label: t('FUNCTION'),
  }];

  const tableContent = [
    item => {
      const checked = checkedKeys.has(item.id);
      return (
        <Checkbox
          onChange={e => {
            const { checked: ck } = e.target;
            const copySet = new Set();
            checkedKeys.forEach(value => {
              copySet.add(value);
            });
            if (!ck) {
              copySet.delete(item.id);
            } else {
              copySet.add(item.id);
            }
            setCheckedKeys(copySet);
            if (copySet.size < checkedKeys.size) {
              setCheckAll(false);
            }
          }}
          checked={checked}
        />
      );
    },
    data => (<Label className="d-flex justify-content-center">{data?.name}</Label>),
    data => (
      <Label
        className="d-flex justify-content-center"
      >
        <a href={data?.address} target="_blank" rel="noreferrer">
          {data?.address}
        </a>
      </Label>
    ),
    data => (<Label className="d-flex justify-content-center">{data?.remark}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.date && moment.unix(data?.date).format('YYYY-MM-DD HH:mm:ss')}</Label>),
    data => (data?.status === 0 ? <Label className="negativeValue d-flex justify-content-center">{t('INACTIVE')}</Label> : <Label className="d-flex justify-content-center installment">{t('ACTIVE')}</Label>),
    data => (
      <>
        <Button className="button green mx-1" onClick={() => callback('externalWebAddEdit', data, true)}>{t('EDIT')}</Button>
        <Button className="button find mx-1" onClick={() => deleteClick(data?.id)}>{t('DELETE')}</Button>
        <Button className="button handle mx-1" onClick={() => setStatus(data?.id)}>{t('TOGGLE_STATUS')}</Button>
        {
          data.webCode && (
            <Button
              className="button green mx-1"
              onClick={() => {
                GetBuhuoBetList(
                  {
                    id: data?.id
                  },
                  res => {
                    setBuhuoBetList(res);
                  }
                );
                setPageFlag(1);
                setBuhuoBetList({});
              }}
            >
              查看补货注单
            </Button>
          )
        }
      </>
    ),
  ];

  return (
    <div className="ExternalWebManageWrapper">
      <CommonHeader
        headerName={(
          <div className="d-flex align-items-center">
            {pageFlag !== 0 && (
              <div className="d-flex align-items-center me-2 backIcon" onClick={() => setPageFlag(0)}>
                <MdArrowBack className="h5 mb-0 text-white" />
                返回
              </div>
            )}
            {`${t('OUTSIDE_WEB_SETTING')} ${(pageFlag === 1 && ' / 补货注单') || ''} ${(pageFlag === 1 && buhuoBetList?.webName && ` / ${buhuoBetList?.webName || ''}`) || ''}`}
          </div>
        )}
      />
      <div className="mx-0 bg-white my-2  border-1">
        {pageFlag === 0 && (
          <Row className="p-0 m-0 mb-2">
            <Col className="p-1 mb-2 filterWrapper d-flex align-items-center w-100">
              <Col className="d-flex align-items-center">
                <Button
                  className="button add justify-content-center"
                  onClick={() => callback('externalWebAddEdit', data, false)}
                >
                  <FaPlusCircle className="mb-1 mr-1" />
                  <Label className="p-0 px-1">新增外补网站</Label>
                </Button>
                <Label value={t('EXTERNAL_WEB_NAME')} className="px-3 marginLeft5">{t('EXTERNAL_WEB_NAME')}</Label>
                <Input type="text" className="mx-2 justify-content-center" value={_searchText} onChange={({ target: { value: text } }) => setSearchText(text)} />
                <Button className="button find mx-3" onClick={() => findClick()}>{t('FIND')}</Button>
              </Col>
              <Col className="align-items-center justify-content-end">
                <Button className="button handle mx-1 right" onClick={() => setStatus()}>{t('TOGGLE_STATUS')}</Button>
                <Button className="button find mx-1 right" onClick={() => deleteClick()}>{t('DELETE')}</Button>
              </Col>
            </Col>
          </Row>
        )}

        <Row className="bigWrapper m-0 my-1 p-3">
          {
            pageFlag === 0 && (
              <AgentDataTable
                isQuerying={isQueryStatus}
                tableHeaderTitle={tableHeader}
                tableData={_info || (isQueryStatus ? [1, 2, 3] : [])}
                tableBodyRowProperty={tableContent}
                tableBodyRowStyle={[]}
              />
            )
          }
          {
            pageFlag === 1 && (
              <AgentDataTable
                isQuerying={isQueryStatus}
                tableHeaderTitle={[{
                  label: '注单号'
                }, {
                  label: '盘口'
                }, {
                  label: '投注选项'
                }, {
                  label: '时间'
                }, {
                  label: '下注金额'
                }, {
                  label: '赔率'
                }, {
                  label: '退水(%)'
                }, {
                  label: '可赢金额'
                }, {
                  label: '下注状况'
                }
                ]}
                tableData={buhuoBetList?.betlist || (isQueryStatus ? [1, 2, 3] : [])}
                tableBodyRowProperty={[
                  data => (<Label className="d-flex justify-content-center">{data?.oddNo}</Label>),
                  data => (<Label className="d-flex justify-content-center">{data?.oddsType}</Label>),
                  data => (<Label className="d-flex justify-content-center">{data?.displayName}</Label>),
                  data => (<Label className="d-flex justify-content-center">{data?.createDate && moment.unix(data?.createDate).format('YYYY-MM-DD HH:mm:ss')}</Label>),
                  data => (<Label className="d-flex justify-content-center">{data?.bettingAmount}</Label>),
                  data => (<Label className="d-flex justify-content-center">{data?.odds}</Label>),
                  data => (<Label className="d-flex justify-content-center">{data?.returnValue}</Label>),
                  data => (<Label className="d-flex justify-content-center">{data?.result?.Value}</Label>),
                  data => (<Label className="d-flex justify-content-center">{data?.note}</Label>),
                ]}
                tableFooter={[
                  [
                    {
                      value: '合计：',
                      colspan: 2,
                      className: 'textRight pr-1 total tableSum'
                    },
                    {
                      value: `${buhuoBetList?.totalRecords || 0} 笔`,
                      colspan: 2,
                      className: 'tableSum'
                    },
                    {
                      value: '总金额：',
                      colspan: 2,
                      className: 'textRight pr-1 total tableSum'
                    },
                    {
                      value: `${buhuoBetList?.totalAmount || 0}`,
                      colspan: 3,
                      className: 'tableSum'
                    },
                  ]

                ]}
              />
            )
          }
        </Row>
        {
          pageFlag === 0 && (
            <Row className="justify-center w-full relative">
              <Pagination totalRecord={_totalRecord || 0} onClick={onPaginationClick} isQuery={false} />
            </Row>
          )
        }
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ExternalWebManage);