import { ZFunction, dWFunction, dsdx } from '../Items/dw';


const CJ_QXC = [
  {
    displayName: '一字定位',
    displayRows: 10,
    settingCode: 'DW1',
    display: [...dWFunction('YZDW')],
    tabId: 'count_DW1',
  },
  {
    displayName: '二字定位',
    displayRows: 150,
    settingCode: 'DW2',
    display: [...dWFunction('EZDW')],
    tabId: 'count_DW2',
  },
  {
    displayName: '三字定位',
    displayRows: 1000,
    settingCode: 'DW3',
    display: [...dWFunction('SZDW')],
    tabId: 'count_DW3',
  },
  {
    displayName: '四字定位',
    displayRows: 2500,
    settingCode: 'DW4',
    display: [...dWFunction('DW4')],
    tabId: 'count_DW4',
  },
  {
    displayName: '一字',
    displayRows: 5,
    settingCode: 'Z1',
    display: [...ZFunction('Z1')],
    tabId: 'count_Z1',
  },
  {
    displayName: '二字',
    displayRows: 11,
    settingCode: 'Z2',
    display: [...ZFunction('Z2')],
    tabId: 'count_Z2',
  },
  {
    displayName: '三字',
    displayRows: 44,
    settingCode: 'Z3',
    display: [...ZFunction('Z3')],
    tabId: 'count_Z3',
  },
  {
    displayName: '四字',
    displayRows: 179,
    settingCode: 'Z4',
    display: [...ZFunction('Z4')],
    tabId: 'count_Z4',
  },
  {
    displayName: '单双大小',
    displayRows: 10,
    settingCode: 'LM',
    display: [...dsdx],
    tabId: 'count_LM',
  }
];

export default CJ_QXC;