import { useEffect, useState } from 'react';

import { Button, Col, Input, Label, Row } from 'reactstrap';
import { connect } from 'react-redux';

import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

import { appActions, systemActions } from '../../../redux/actions';
import './styles.scss';


const mapStateToProps = state => ({
  userData: state.memberReducers.userData,
  editSMSSettingData: state.systemReducers.editSMSSettingData,
});

const mapDispatchToProps = dispatch => ({
  getEditSMSSetting: (id, callback) => dispatch(systemActions.getEditSMSSetting(id, callback)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  saveSMSSetting: (obj, callback) => dispatch(systemActions.saveSMSSetting(obj, callback)),
});

const SMSAddEdit = ({ callback = {}, isEdit, getEditSMSSetting, data, editSMSSettingData, updateModalAlert, saveSMSSetting }) => {
  const { t } = useTranslation();
  const [_info, setInfo] = useState([]);

  useEffect(() => {
    if (isEdit) {
      getEditSMSSetting({
        id: data?.id || ''
      });
    }
  }, [data]);

  useEffect(() => {
    if (!isEdit) {
      setInfo([]);
    } else if (editSMSSettingData && JSON.stringify(editSMSSettingData) !== JSON.stringify(_info)) {
      setInfo(editSMSSettingData);
    }
  }, [editSMSSettingData]);


  const saveButton = () => {
    if (_info?.isChange) {
      const updateObj = _info;
      updateModalAlert({
        type: 'loading',
        visible: true,
      });
      saveSMSSetting(updateObj, callback);
    } else {
      updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: '无变动'
        }
      });
    }
  };

  const onInfoValueChange = (val, field) => {
    const newItems = _info;
    newItems[field] = val;
    newItems.isChange = true;
    setInfo(newItems => ({
      ...newItems, field: val
    }));
  };

  const resetDefault = () => {
    if (!isEdit) {
      callback();
    } else {
      getEditSMSSetting({
        id: data?.id
      });
    }
  };

  const status = [
    {
      label: '启用',
      value: '0',
    },
    {
      label: '禁用',
      value: '1',
    }
  ];

  const platforms = [
    {
      label: '后台',
      value: '0',
    }, {
      label: '会员',
      value: '1',
    },
    {
      label: '手机端',
      value: '2',
    }
  ];
  return (
    <div className="SMSAddEditAddWrapper">
      <div className="mx-0 bg-white my-2  border-1">
        <Row className="m-0 my-1 p-0">
          <table className="p-3 m-0" cellSpacing="0" cellPadding="0" border="0">
            <tbody>
              <tr>
                <td className="titleHeader" colSpan={2}>
                  {isEdit === true ? '编辑短信管理' : '新增短信管理'}
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">短信平台</td>
                <td>
                  <div>
                    <Input
                      className="d-inline col-1"
                      type="text"
                      value={_info?.name || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text, 'name')}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">url</td>
                <td>
                  <div className="textAreaInput">
                    <Input
                      className="d-inline col-1"
                      type="textarea"
                      value={_info?.url || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text, 'url')}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">短信验证验证码发送时间间隔（秒）</td>
                <td>
                  <div>
                    <Input
                      className="d-inline col-1"
                      type="number"
                      value={_info?.interval?.toString() || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text === '' ? '' : Number(text), 'interval')}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">状态</td>
                <td>
                  <div className="d-flex my-1 rdSearchOptionsGroup">
                    {
                      status.map(d => (
                        <div key={`rdTypeGroup_${d.value}`}>
                          <Input
                            id={`rdType_${d.value}`}
                            type="radio"
                            name="rdType"
                            value={_info?.status}
                            checked={_info?.status === Number(d.value)}
                            onChange={() => onInfoValueChange(Number(d.value), 'status')}
                          />
                          <Label
                            for={`rdType_${d.value}`}
                            className={_info?.status === Number(d.value) ? 'active mx-2 smallLabel' : 'mx-2 smallLabel'}
                          >
                            {d?.label}
                          </Label>
                        </div>
                      ))
                    }
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">客户端</td>
                <td>
                  <div>
                    <Select
                      value={_info?.platform || 0}
                      placeholder="请选择"
                      onChange={val => onInfoValueChange(Number(val), 'platform')}
                    >
                      {
                              platforms.map(d => (
                                <Select.Option key={`setPlatformsSelect_${d.value}`} label={d.label} value={Number(d.value)} />
                              ))
                        }
                    </Select>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </Row>
      </div>
      <Row className="mb-5 my-2 p-2">
        <Col className="d-flex justify-content-center py-2 border-top">
          <Button className="button handle mx-1" onClick={() => saveButton()}>{t('SAVE')}</Button>
          <Button className="button find mx-1" onClick={() => resetDefault()}>{t('CANCEL')}</Button>
        </Col>
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SMSAddEdit);