import { useEffect, useState } from 'react';

import { Button, Col, Row } from 'reactstrap';
import { Input } from 'antd';
import { IoClose } from 'react-icons/io5';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { appActions } from '../../../redux/actions';
import './styles.scss';

const mapStateToProps = state => ({
  platform: state.appReducers.platform,
});

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
});

const RebateModal = ({ updateModalAlert, data }) => {
  const { t } = useTranslation();

  const [remarks, setremarks] = useState(data.data.length > 0 ? data.data[0].remarks : '');
  const [amount, setamount] = useState(0);
  const [arr, setArr] = useState([]);

  useEffect(() => {
    if (data.data) {
      let _amount = 0;
      data.data.forEach(item => {
        _amount += parseFloat(item.amount);
      });
      setamount(_amount.toFixed(2));
    }
  }, []);

  useEffect(() => {
    if (remarks) {
      const _arr = data.data.map(item => ({
        ...item, remarks, amount: parseFloat(item.amount)
      }));
      setArr([..._arr]);
    }
  }, [remarks]);

  return (
    <div className="rebateModalWrapperWeb">
      <Row className="modalHeader">
        <Col
          className={`${'d-flex justify-content-center align-items-center'}`}
          xs={{
            size: 4, offset: 4
          }}
        >
          会员反水
        </Col>
        <Col xs="4" className="d-flex justify-content-end align-items-center">
          <IoClose
            onClick={() => {
              updateModalAlert({
                visible: false
              });
              if (data && data.overBet && data.onConfirmationClick) data.onConfirmationClick();
            }}
            className="h4 mb-0 float-right closeIcon"
          />
        </Col>
      </Row>
      <div className="modalContent p-3">
        <div className="rebateTable p-0">
          <div>
            <div className="w-25">备注</div>
            <div className="w-75">
              <Input type="textarea" value={remarks} rows={3} onChange={text => setremarks(text)} />
            </div>
          </div>
          <div>
            <div className="w-25">金额</div>
            <div className="w-75">{amount}</div>
          </div>
        </div>
      </div>
      <Row className="modalFooter d-flex align-items-center">
        <Button
          className={`confirmButton my-3 ${'me-3'}`}
          onClick={() => {
            updateModalAlert({
              visible: false
            });
            if (data && data.onConfirmationClick) data.onConfirmationClick(arr);
          }}
        >
          {(data && data.confirmText) || t('CONFIRM')}
        </Button>
        {
          data && data.showCancelButton !== false && (
            <Button
              className={`cancelButton my-3 ${'me-3'}`}
              onClick={() => {
                updateModalAlert({
                  visible: false
                });
                if (data && data.onCancelClick) data.onCancelClick();
              }}
            >
              {(data && data.cancelText) || t('CANCEL')}
            </Button>
          )
        }
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(RebateModal);