import { StatusCodes } from 'http-status-codes';

import { apiService } from '../../../library';
import appActions from '../appActions';


const GetInfo = (callback = () => {}) => dispatch => {
  apiService.get('/FollowBet/GetInfo').then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      return callback(data.result);
    }
  }).catch(error => {
    callback(false);
    dispatch(appActions.updateModalAlert({
      visible: true,
      type: 'confirmation',
      data: {
        message: error.response.data.msg
      }
    }));
    Promise.reject(error);
  });
};

const AddEditConfig = (configId, callback = () => {}) => dispatch => {
  apiService.get('/FollowBet/AddEditConfig', new URLSearchParams({
    configId
  })).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      return callback(data.result);
    }
  }).catch(error => {
    callback(false);
    dispatch(appActions.updateModalAlert({
      visible: true,
      type: 'confirmation',
      data: {
        message: error.response.data.msg
      }
    }));
    Promise.reject(error);
  });
};

const AgentConfig = (configId, callback = () => {}) => dispatch => {
  apiService.get('/FollowBet/AgentConfig', new URLSearchParams({
    configId
  })).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      return callback(data.result);
    }
  }).catch(error => {
    callback(false);
    dispatch(appActions.updateModalAlert({
      visible: true,
      type: 'confirmation',
      data: {
        message: error.response.data.msg
      }
    }));
    Promise.reject(error);
  });
};

const GetLogs = (obj, callback = () => {}) => dispatch => {
  apiService.get('/FollowBet/GetLogs', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      return callback(data.result);
    }
  }).catch(error => {
    callback(false);
    dispatch(appActions.updateModalAlert({
      visible: true,
      type: 'confirmation',
      data: {
        message: error.response.data.msg
      }
    }));
    Promise.reject(error);
  });
};

const DeleteConfig = (obj, callback = () => {}) => dispatch => {
  apiService.post('/FollowBet/DeleteConfig', obj).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
      return callback();
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: true,
      type: 'confirmation',
      data: {
        message: error.response.data.msg
      }
    }));
    Promise.reject(error);
  });
};

const SaveAgentConfig = (obj, callback = () => {}) => dispatch => {
  apiService.post('/FollowBet/SaveAgentConfig', obj).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
      return callback();
    }
  }).catch(error => {
    callback();
    dispatch(appActions.updateModalAlert({
      visible: true,
      type: 'confirmation',
      data: {
        message: error.response.data.msg,
        showCancelButton: false
      }
    }));
    Promise.reject(error);
  });
};

const GetSettingCodeList = (lotteryType = '', callback = () => {}) => dispatch => {
  apiService.get('/FollowBet/GetSettingCodeList', new URLSearchParams({
    lotteryType
  })).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      return callback(data.result);
    }
  }).catch(error => {
    callback([]);
    dispatch(appActions.updateModalAlert({
      visible: true,
      type: 'confirmation',
      data: {
        message: error.response.data.msg
      }
    }));
    Promise.reject(error);
  });
};

export default {
  GetInfo,
  AddEditConfig,
  DeleteConfig,
  AgentConfig,
  GetLogs,
  SaveAgentConfig,
  GetSettingCodeList
};