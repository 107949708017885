import { useEffect, useState } from 'react';

import { Col, Label, Row } from 'reactstrap';
import { FaCaretDown } from 'react-icons/fa';
import { connect } from 'react-redux';

import { Checkbox, Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';

import { AgentDataTable, LotteryMenu } from '../..';
import { appActions, decreaseOddsActions } from '../../../redux/actions';

import './styles.scss';

const mapStateToProps = state => ({
  activeLottery: state.commonReducers.activeLottery,
  decreaseOddsData: state.decreaseOddsReducers.decreaseOddsData,
});

const mapDispatchToProps = dispatch => ({
  getInfo: lotteryCode => dispatch(decreaseOddsActions.getInfo(lotteryCode)),
  getDefaultInfo: lotteryCode => dispatch(decreaseOddsActions.getDefaultInfo(lotteryCode)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  saveSetting: (obj, callback) => dispatch(decreaseOddsActions.saveSetting(obj, callback)),
  saveDefaultSetting: (obj, callback) => dispatch(decreaseOddsActions.saveDefaultSetting(obj, callback)),
});

const { Option } = Select;

const DecreaseOddsSetting = ({ getInfo, decreaseOddsData, saveSetting, updateModalAlert, isAdmin, getDefaultInfo, saveDefaultSetting, activeLottery }) => {
  const { t } = useTranslation();
  const [_selectedLottery, setSelectedLottery] = useState(activeLottery?.code || 'HK6');
  const [_selecteLotteryTxt, setSelectedLotteryTxt] = useState();
  const [_displayMenu, setDisplayMenu] = useState(false);
  const [_info, setInfo] = useState();
  const [_selectedQS, setSelectedQS] = useState('moneyLimit');
  const [_qsText, setQsText] = useState('');
  const [_isQueryingDetail, setIsQueryingDetail] = useState(false);
  const [selectAllChecked, setselectAllChecked] = useState(false);

  useEffect(() => {
    setIsQueryingDetail(true);
    // Default search when first load
    isAdmin ? getDefaultInfo({
      lotteryType: _selectedLottery
    }) : getInfo({
      lotteryType: _selectedLottery
    });
    setSelectedLotteryTxt(activeLottery.lotteryName);
  }, []);

  useEffect(() => {
    if (decreaseOddsData) {
      setInfo(decreaseOddsData);
      setIsQueryingDetail(false);
    }
  }, [decreaseOddsData]);

  useEffect(() => {
    if (activeLottery) {
      onLotterySelect(activeLottery);
    }
  }, [activeLottery]);

  const onValueChange = (id, property, value) => {
    const newItems = _info.map(x => (x.id === id ? ({
      ...x, [property]: value, isChange: true
    }) : x));
    setInfo(newItems);
  };

  const onSaveClick = () => {
    const res = _info.filter(item => item?.isChange === true);
    if (res.length > 0) {
      updateModalAlert({
        type: 'loading',
        visible: true,
      });
      isAdmin ? saveDefaultSetting(res) : saveSetting(res);
    } else {
      updateModalAlert({
        type: 'confirmation',
        visible: true,
        data: {
          message: '没有任何改动',
          showCancelButton: false
        }
      });
    }
  };

  const onResetClick = () => {
    setselectAllChecked(false);
    setInfo(decreaseOddsData);
  };

  const updateInput = () => {
    const newItems = _info.map(x => ({
      ...x, [_selectedQS]: _qsText, isChange: true
    }));
    setInfo(newItems);
  };

  const onLotterySelect = retvalue => {
    setIsQueryingDetail(true);
    setSelectedLottery(retvalue?.code);
    setSelectedLotteryTxt(retvalue?.lotteryName);
    isAdmin ? getDefaultInfo({
      lotteryType: retvalue?.code || ''
    }) : getInfo({
      lotteryType: retvalue?.code || ''
    });
    setQsText('');
    setDisplayMenu(false);
    setselectAllChecked(false);
  };

  const QuickSetField = [
    {
      label: t('ACCUMULATED_VALUE'),
      value: 'moneyLimit',
    },
    {
      label: t('DECREASE_ODDS_VALUE'),
      value: 'downValue',
    }, {
      label: t('LOWEST_ODDS'),
      value: 'minOdds',
    }, {
      label: t('HIGHEST_QUANTITY'),
      value: 'maxQuantity',
    }
  ];

  return (
    <Col className="DecreaseOddsSettingWarpper">
      <Col className="content p-0">
        <Col className="subMenu p-1 px-2 mb-2 flex">
          <div
            onMouseEnter={() => setDisplayMenu(!_displayMenu)}
            onClick={() => setDisplayMenu(!_displayMenu)}
            className={`menuWrapper d-flex ${_displayMenu ? 'menuactive' : ''}`}
          >
            <Label className="menuSelectedText">{_selecteLotteryTxt}</Label>
            <div className="float-right">
              <FaCaretDown className="float-right" />
            </div>
          </div>

          <div onMouseLeave={() => setDisplayMenu(false)} className={`subselection ${_displayMenu ? '' : 'hide'}`}>
            <LotteryMenu
              selectedItem={_selectedLottery}
              onClickItem={onLotterySelect}
            />
          </div>
        </Col>
        <Row className="mb-2">
          <Col>
            <AgentDataTable
              isQuerying={_isQueryingDetail}
              tableHeaderTitle={
                [{
                  label: t('TYPE'),
                }, {
                  label: t('ACCUMULATED_VALUE'),
                }, {
                  label: t('DECREASE_ODDS_VALUE'),
                }, {
                  label: t('LOWEST_ODDS'),
                }, {
                  label: t('HIGHEST_QUANTITY'),
                }, {
                  label: t('AUTO_ON_OFF'),
                }]
              }
              tableData={_info ? [].concat(_info.slice(0, _info.length / 2)) : []}
              tableBodyRowProperty={
                [
                  data => (<Label className="d-flex justify-content-center">{data.projectName}</Label>),
                  data => (
                    <div className="d-flex align-items-center justify-content-center">
                      <Input
                        value={data.moneyLimit}
                        onChange={({ target: { value: text } }) => onValueChange(data.id, 'moneyLimit', Number.isNaN(Number(text.trim())) ? data.moneyLimit : text.trim())}
                      />
                    </div>
                  ),
                  data => (
                    <div className="d-flex align-items-center justify-content-center">
                      <Input
                        value={data.downValue}
                        onChange={({ target: { value: text } }) => onValueChange(data.id, 'downValue', Number.isNaN(Number(text.trim())) ? data.downValue : text.trim())}
                      />
                    </div>
                  ),
                  data => (
                    <div className="d-flex align-items-center justify-content-center">
                      <Input
                        value={data.minOdds}
                        onChange={({ target: { value: text } }) => onValueChange(data.id, 'minOdds', Number.isNaN(Number(text.trim())) ? data.minOdds : text.trim())}
                      />
                    </div>
                  ),
                  data => (
                    <div className="d-flex align-items-center justify-content-center">
                      <Input
                        value={data.maxQuantity}
                        onChange={({ target: { value: text } }) => onValueChange(data.id, 'maxQuantity', Number.isNaN(Number(text.trim())) ? data.maxQuantity : text.trim())}
                      />
                    </div>
                  ),
                  data => (
                    <div className="d-flex align-items-center justify-content-center">
                      <Checkbox
                        className="mx-2 px-1 align-items-center"
                        checked={data.enabled}
                        onChange={e => onValueChange(data.id, 'enabled', e.target.checked === true ? 1 : 0)}
                      />
                    </div>
                  ),
                ]
              }
              tableBodyRowStyle={
                ['backgroundColored']
              }
              tableHeaderStyle={
                ['col-2', 'col-2', 'col-2', 'col-2', 'col-2', 'col-2']
              }
            />
          </Col>
          <Col>
            <AgentDataTable
              isQuerying={_isQueryingDetail}
              tableHeaderTitle={
                [{
                  label: t('TYPE'),
                }, {
                  label: t('ACCUMULATED_VALUE'),
                }, {
                  label: t('DECREASE_ODDS_VALUE'),
                }, {
                  label: t('LOWEST_ODDS'),
                }, {
                  label: t('HIGHEST_QUANTITY'),
                }, {
                  label: t('AUTO_ON_OFF'),
                }]
              }
              tableData={_info ? [].concat(_info.slice(_info.length / 2)) : []}
              tableBodyRowProperty={
                [
                  data => (<Label className="d-flex justify-content-center">{data.projectName}</Label>),
                  data => (
                    <div className="d-flex align-items-center justify-content-center">
                      <Input
                        value={data.moneyLimit}
                        onChange={({ target: { value: text } }) => onValueChange(data.id, 'moneyLimit', Number.isNaN(Number(text.trim())) ? data.moneyLimit : text.trim())}
                      />
                    </div>
                  ),
                  data => (
                    <div className="d-flex align-items-center justify-content-center">
                      <Input
                        value={data.downValue}
                        onChange={({ target: { value: text } }) => onValueChange(data.id, 'downValue', Number.isNaN(Number(text.trim())) ? data.downValue : text.trim())}
                      />
                    </div>
                  ),
                  data => (
                    <div className="d-flex align-items-center justify-content-center">
                      <Input
                        value={data.minOdds}
                        onChange={({ target: { value: text } }) => onValueChange(data.id, 'minOdds', Number.isNaN(Number(text.trim())) ? data.minOdds : text.trim())}
                      />
                    </div>
                  ),
                  data => (
                    <div className="d-flex align-items-center justify-content-center">
                      <Input
                        value={data.maxQuantity}
                        onChange={({ target: { value: text } }) => onValueChange(data.id, 'maxQuantity', Number.isNaN(Number(text.trim())) ? data.maxQuantity : text.trim())}
                      />
                    </div>
                  ),
                  data => (
                    <div className="d-flex align-items-center justify-content-center">
                      <Checkbox
                        className="mx-2 px-1 align-items-center"
                        checked={data.enabled}
                        onChange={e => onValueChange(data.id, 'enabled', e.target.checked === true ? 1 : 0)}
                      />
                    </div>
                  ),
                ]
              }
              tableBodyRowStyle={
                ['backgroundColored']
              }
              tableHeaderStyle={
                ['col-2', 'col-2', 'col-2', 'col-2', 'col-2', 'col-2']
              }
            />
          </Col>
        </Row>
        <Row className="mb-5 border-top">
          <Col className="d-flex justify-content-center py-3">
            <div className="operationBtn editAndSave mx-2" onClick={onSaveClick}>{t('SAVE')}</div>
            <div className="operationBtn remove mx-2" onClick={onResetClick}>{t('CANCEL')}</div>
          </Col>
        </Row>
      </Col>
      <Col className="flex align-items-center justify-content-center footer p-0 m-0">
        <Col className="d-flex align-items-center justify-content-start p-2">
          <Label className="flex align-items-center justify-content-center mx-1">快速设置：</Label>
          <Select
            value={_selectedQS}
            onChange={val => setSelectedQS(val)}
          >
            {
                QuickSetField.map(d => <Option value={d.value}>{d.label}</Option>)
            }
          </Select>
          <Input value={_qsText} className="kuaijieInputStyle mx-1" onChange={({ target: { value: text } }) => setQsText(Number.isNaN(Number(text.trim())) ? _qsText : text.trim())} />
          <div className="operationBtn remove mx-2" onClick={() => updateInput(_selectedQS)}>{t('CONFIRM')}</div>
          <div className="operationBtn editAndSave mx-1" onClick={onSaveClick}>{t('SAVE')}</div>
          <Checkbox
            className="mx-2 p-1 align-items-center"
            checked={selectAllChecked}
            onChange={e => {
              setselectAllChecked(e.target.checked);
              const newItems = _info.map(obj => ({
                ...obj, enabled: e.target.checked ? 1 : 0, isChange: true
              }));
              setInfo(newItems);
            }}
          >
            {t('SELECT_ALL')}
          </Checkbox>
        </Col>
      </Col>
    </Col>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DecreaseOddsSetting);