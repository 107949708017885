import { useState } from 'react';

import { MdArrowBack } from 'react-icons/md';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import AccountFlow from '../AccountFlow';
import { CommonHeader } from '../..';
import GameBet from '../GameBet';
import LotteryBet from '../LotteryBet';
import OrderInformation from '../OrderInformation';
import RecentTransaction from '../RecentTransaction';

import './styles.scss';


const mapStateToProps = state => ({
  selectedSubMenu: state.commonReducers.selectedSubMenu,
});

const TransactionMenu = ({ data, selectedSubMenu, onlyOrder, callback = () => {} }) => {
  const { t } = useTranslation();
  const menu = onlyOrder ? [t('ORDER_INFORMATION')] : [t('ORDER_INFORMATION'), t('RECENT_FLOW'), t('RECENT_TRANSACTION'), t('LOTTERY_BET'), t('GAME_BET')];

  const [tabIndex, setTabindex] = useState(0);
  const [_data, setData] = useState();

  const renderTabItem = tabIndex => {
    switch (tabIndex) {
      case 0:
        return <OrderInformation data={data} onlyOrder={onlyOrder} />;
      case 1:
        return <AccountFlow data={data} hide callback={data => setData(data)} />;
      case 2:
        return <RecentTransaction data={data} callback={data => setData(data)} />;
      case 3:
        return <LotteryBet data={data} />;
      case 4:
        return <GameBet data={data} />;
      default:
        break;
    }
  };

  return (
    <div className="transactionMenuWrapper">
      <CommonHeader
        headerName={(
          <div className="d-flex align-items-center">
            <div
              className="d-flex align-items-center me-2 backIcon"
              onClick={() => { if (_data) { setData(); } else { callback(); } }}
            >
              <MdArrowBack className="h5 mb-0 text-white" />
              返回
            </div>
            {data && selectedSubMenu && (`${t(selectedSubMenu.slice(3))} / ${data.mName}${_data ? (` / ${_data.orderID}`) : ''}`)}
          </div>
        )}
      />
      {!_data ? (
        <>
          <div className="transactionMenu">
            {menu.map((item, index) => <div key={`key_${item}`} className={`menu_item ${tabIndex === index && 'selected'}`} onClick={() => setTabindex(index)}>{item}</div>)}
          </div>
          {renderTabItem(tabIndex)}
        </>
      )
        : (
          <OrderInformation
            data={tabIndex === 1 ? {
              id: '', orderID: _data.orderID
            } : _data}
            onlyOrder
          />
        )}
    </div>
  );
};

export default connect(mapStateToProps, null)(TransactionMenu);