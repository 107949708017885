
import { SET_BET_QUERY_DATA } from '../../actions/actionTypes';

const initialState = {
  betQueryData: {}, // 异常注单查询 表格数据 ,{data, sum}
};

const unusualBetReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_BET_QUERY_DATA:
      return {
        ...state, betQueryData: action.value
      };
    default:
      return state;
  }
};

export default unusualBetReducers;