import { Col, Label, Row } from 'reactstrap';
import { useEffect, useRef, useState } from 'react';

import { Button, Input, Select, TimePicker } from 'antd';
import { connect } from 'react-redux';

import { useTranslation } from 'react-i18next';

import moment from 'moment';

import { bigBuhuoLimitActions } from '../../../redux/actions';

import AgentDataTable from '../../AgentDataTable';

import { CommonHeader } from '../..';


import './styles.scss';

const mapStateToProps = state => ({
  bigBuhuoLimitQueryData: state.bigBuhuoLimitReducers.bigBuhuoLimitQueryData,
  lotteryList: state.commonReducers.lotteryList,
});

const mapDispatchToProps = dispatch => ({
  DeleteBigBuhuoLimit: (id, callback) => dispatch(bigBuhuoLimitActions.DeleteBigBuhuoLimit(id, callback)),
  SaveBigBetLimitSetting: (obj, callback) => dispatch(bigBuhuoLimitActions.SaveBigBuhuoLimitSetting(obj, callback)),
  QueryBigBuhuoLimit: (obj, callback, catchCallback) => dispatch(bigBuhuoLimitActions.QueryBigBuhuoLimit(obj, callback, catchCallback)),
});

const BigBuhuoLimit = ({
  showHeader = true,
  DeleteBigBuhuoLimit,
  SaveBigBetLimitSetting,
  lotteryList,
  bigBuhuoLimitQueryData,
  QueryBigBuhuoLimit
}) => {
  const { t } = useTranslation(); // i18n
  const [_isQueryingDetail, setIsQueryingDetail] = useState([]); // loading
  const [_detailItem, setDetailItem] = useState([]); // table data
  const [_lottery, setLottery] = useState(lotteryList[0]);
  const [_lotteryList, setLotteryList] = useState([]); // 比lotterylist 多了sum信息
  const filterRef = useRef();
  const [timeList, setTimeList] = useState([]);
  const [activeTimeIndex, setActiveTimeIndex] = useState(0);
  const [newTime, setNewTime] = useState('');
  const [addItem, setAddItem] = useState({
    singlerealamount: 0,
    settingcode: '-1'
  });

  const [sortedArray, setSortedArray] = useState([]);

  const onQuery = (params = {}) => {
    const newParams = {
      lotteryType: _lottery.code,
      time: newTime,
      ...params,
    };
    if (newParams.time && newParams.lotteryType) {
      setIsQueryingDetail(true);
    }
    if (_lottery && _lottery.code) {
      QueryBigBuhuoLimit(
        newParams,
        () => {
          setIsQueryingDetail(false);
        },
        () => {
          setIsQueryingDetail(false);
        }
      );
    }
  };

  useEffect(() => {
    setDetailItem([]);
    onQuery({
      lotteryType: _lottery.code,
      time: '', // 这个时候 timeList 还没有返回, 这个api 返回数据无效
    });
    setActiveTimeIndex(0);
    setAddItem({
      singlerealamount: 0,
      settingcode: '-1'
    });
  }, [_lottery]);

  useEffect(() => {
    onQuery({
      lotteryType: _lottery.code,
      time: timeList[0]
    });
  }, [timeList]);

  useEffect(() => {
    if (bigBuhuoLimitQueryData) {
      const newData = bigBuhuoLimitQueryData.list || [];
      setSortedArray(bigBuhuoLimitQueryData.oddList.filter(x => !newData.find(y => y.settingcode === x.code)).map(item => ({
        label: item.projectName, value: item.code
      })));
      setDetailItem([...newData, {}]);
      const newTimeList = bigBuhuoLimitQueryData.timeList || [];
      if (newTimeList.join('') !== timeList.join('')) {
        setTimeList(bigBuhuoLimitQueryData.timeList || []);
      }
    }
    setIsQueryingDetail(false);
  }, [bigBuhuoLimitQueryData]);

  useEffect(() => {
    setNewTime(timeList[activeTimeIndex]);
  }, [activeTimeIndex, timeList]);

  useEffect(() => {
    const newData = lotteryList.filter(item => item.templateCode === 'HK6');
    if (!_lottery && newData[0]) {
      setLottery(newData[0]);
    }
    setLotteryList(newData);
  }, [lotteryList]);

  const tableHeaderTitle = [
    {
      label: t('ORDER_NUM'),
    },
    {
      label: t('TYPE'),
    },
    {
      label: t('RESTOCK_LIMIT'),
    },
    {
      label: t('OPERATION'),
    },
  ];

  const _content = [
    (item, index) => (
      <Label>
        {
          item.id ? index + 1 : ''
        }
      </Label>
    ),
    item => {
      if (!item.id) {
        return (
          <Select
            onChange={value => setAddItem({
              ...addItem, settingcode: value
            })}
            value={addItem.settingcode}
            options={
              [
                {
                  label: t('TYPE'), value: '-1'
                }
              ].concat(sortedArray)
            }
            className="categorySelect"
          />
        );
      }
      return (
        <Label>
          {
            item.projname
          }
        </Label>
      );
    },
    item => {
      if (item.id) {
        return (
          <Label>
            {
              item.singlerealamount
            }
          </Label>
        );
      }
      return (
        <Input
          type="number"
          className="input"
          value={addItem.singlerealamount}
          onChange={e => {
            setAddItem({
              ...addItem,
              singlerealamount: parseInt(e.target.value, 10)
            });
          }}
        />
      );
    },
    item => {
      if (!item.id) {
        return (
          <div className="d-flex justify-content-center align-items-center">
            <div
              onClick={() => handleSave()}
              className="operationBtn add ms-3"
            >
              {t('ADD')}
            </div>
          </div>
        );
      }
      return (
        <div className="d-flex justify-content-center align-items-center">
          <div
            className="operationBtn remove ms-3"
            onClick={() => DeleteBigBuhuoLimit(item.id, onQuery)}
          >
            {t('DELETE')}
          </div>
        </div>
      );
    }
  ];

  const transferTime = timeStr => {
    if (!timeStr) return;
    const res = timeStr.split(':');
    const hour = res[0];
    const minute = res[1];
    const time = new Date();
    time.setHours(hour);
    time.setMinutes(minute);
    return new Date(time);
  };

  const handleSave = () => {
    SaveBigBetLimitSetting(
      {
        lotteryType: _lottery.code,
        limitTime: timeList[activeTimeIndex] ? timeList[activeTimeIndex] : newTime,
        newTime: newTime !== timeList[activeTimeIndex] ? newTime : '',
        betLimits: [{
          singlerealamount: addItem.singlerealamount,
          settingcode: addItem.settingcode
        }].filter(i => i.singlerealamount && i.settingcode)
      },
      () => {
        onQuery();
        setAddItem({
          singlerealamount: 0,
          settingcode: '-1'
        });
      }
    );
  };

  return (
    <div className="BigBuhuoLimitWrapper">
      <div ref={filterRef}>
        {
          showHeader && (
            <CommonHeader headerName={t('BIG_BET_BUHUO_SETTING')} />
          )
        }
      </div>

      <Row className="mx-0 px-0 border-1">
        <div className="lotteryList">
          {
            _lotteryList.map(item => {
              const itemCode = item.code;
              return (
                <div
                  onClick={() => setLottery(item)}
                  className={`lotteryItem ${(itemCode === _lottery.code && 'active') || ''}`}
                >
                  <div>
                    {
                      item.lotteryName
                    }
                  </div>
                </div>
              );
            })
          }
        </div>
      </Row>

      <Row className=" mt-2 mx-0 bg-white px-0 border-1">
        <div className="tabList">
          {
            [0, 1, 2].map((item, index) => {
              const time = timeList[item];
              return (
                <div
                  onClick={() => {
                    setActiveTimeIndex(item);
                    onQuery({
                      lotteryType: _lottery.code,
                      time: timeList[item]
                    });
                  }}
                  className={`tabItem ${item === activeTimeIndex && 'active'}`}
                >
                  {t('BET_LIMIT_TIME')}
                  {
                    index + 1
                  }
                  ：
                  {
                    time || t('NOT_SET')
                  }
                </div>
              );
            })
          }
        </div>
      </Row>

      <Row className=" mt-2 mx-0 bg-white px-0 border-1">
        <div className="tableTimeSet">
          <div className="d-flex p-0 align-items-center ">
            <div className="text">
              {t('BIG_RESTOCK_TIME')}
            </div>
            <TimePicker
              value={newTime && moment(transferTime(newTime))}
              onChange={date => {
                const str = date.format('HH:mm');
                setNewTime(str);
              }}
              format="HH:mm"
              className="marginLeft1"
            />
          </div>
          <Button
            onClick={() => handleSave()}
            className="button save mx-2"
          >
            {t('SETTING')}
          </Button>
        </div>
      </Row>

      <Row className=" mt-2 mx-0 bg-white px-0 border-1">
        <Col className="p-0">
          <AgentDataTable
            tableBodyRowStyle={
              ['backgroundColored']
            }
            isQuerying={_isQueryingDetail}
            tableHeaderTitle={tableHeaderTitle}
            tableData={_detailItem}
            tableBodyRowProperty={_content}
          />
        </Col>
      </Row>

    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BigBuhuoLimit);