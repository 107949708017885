import { StatusCodes } from 'http-status-codes';

import { SET_BACKUP_IP, SET_BACKUP_WHITELIST, SET_COMPANY_LIST, SET_EDIT_BACKUP_IP, SET_EDIT_BACKUP_WHITELIST, SET_EDIT_WHITE, SET_SUB_ACCOUNT_LIST, SET_SUPER_USER } from '../actionTypes';
import { apiService } from '../../../library';
import appActions from '../appActions';

const setSuperUser = data => ({
  type: SET_SUPER_USER,
  value: data,
});

const setCompanyList = data => ({
  type: SET_COMPANY_LIST,
  value: data,
});

const setBackUpIP = data => ({
  type: SET_BACKUP_IP,
  value: data,
});

const setBackUpWhitelist = data => ({
  type: SET_BACKUP_WHITELIST,
  value: data,
});

const setEditBackupIP = data => ({
  type: SET_EDIT_BACKUP_IP,
  value: data,
});

const setEditBackupWhitelist = data => ({
  type: SET_EDIT_BACKUP_WHITELIST,
  value: data,
});

const setSubAccountList = data => ({
  type: SET_SUB_ACCOUNT_LIST,
  value: data,
});

const setEditWhite = data => ({
  type: SET_EDIT_WHITE,
  value: data,
});

const getSuperUser = (callback = () => {}) => dispatch => {
  apiService.get('/WhiteList/GetSuperUser').then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      callback();
      return dispatch(setSuperUser(data.result));
    }
  }).catch(error => {
    dispatch(setSuperUser());
    dispatch(appActions.updateModalAlert({
      visible: true,
      type: 'confirmation',
      data: {
        message: error.response.data.msg,
        showCancelButton: false
      }
    }));
    Promise.reject(error);
  });
};

const getInfo = (obj, callback = () => {}) => dispatch => {
  apiService.get('/WhiteList/GetInfo', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      callback();
      return dispatch(setCompanyList(data.result));
    }
  }).catch(error => {
    dispatch(setCompanyList());
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg,
          showCancelButton: false
        }
      }));
    }
    Promise.reject(error);
  });
};

const GetBackupWhiteList = (obj, callback = () => {}) => dispatch => {
  apiService.get('/WhiteList/GetBackupWhiteList', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      callback();
      return dispatch(setBackUpWhitelist(data.result));
    }
  }).catch(error => {
    dispatch(setBackUpWhitelist());
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg,
          showCancelButton: false
        }
      }));
    }
    Promise.reject(error);
  });
};

const getBackupIP = (obj, callback = () => {}) => dispatch => {
  apiService.get('/WhiteList/GetBackupIP', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      callback();
      return dispatch(setBackUpIP(data.result));
    }
  }).catch(error => {
    dispatch(setBackUpIP());
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg,
          showCancelButton: false
        }
      }));
    }
    Promise.reject(error);
  });
};

const getSubAccountList = (obj, callback = () => {}) => dispatch => {
  apiService.get('/WhiteList/GetSubAccountList', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      callback();
      return dispatch(setSubAccountList(data.result));
    }
  }).catch(error => {
    dispatch(setSubAccountList());
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg,
          showCancelButton: false
        }
      }));
    }
    Promise.reject(error);
  });
};

const EditBackupWhiteList = (id, calllback = () => {}) => dispatch => {
  apiService.get('/WhiteList/EditBackupWhiteList', new URLSearchParams({
    orgId: id
  })).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      calllback();
      dispatch(setEditBackupWhitelist(data.result));
    }
  }).catch(error => {
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg,
          showCancelButton: false
        }
      }));
    }
    calllback();
    Promise.reject(error);
  });
};

const editBackupIP = (id, calllback = () => {}) => dispatch => {
  apiService.get('/WhiteList/EditBackupIP', new URLSearchParams({
    id
  })).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      calllback();
      dispatch(setEditBackupIP(data.result));
    }
  }).catch(error => {
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg,
          showCancelButton: false
        }
      }));
    }
    calllback();
    Promise.reject(error);
  });
};

const DelBackupWhiteList = (id, calllback = () => {}) => dispatch => {
  apiService.post(`/WhiteList/DelBackupWhiteList/${id}`).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      calllback(false);
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
    }
  }).catch(error => {
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg,
          showCancelButton: false
        }
      }));
    }
    calllback(false);
    Promise.reject(error);
  });
};

const deleteBackupIP = (id, calllback = () => {}) => dispatch => {
  apiService.post('/WhiteList/DeleteBackupIP', id).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      calllback(false);
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
    }
  }).catch(error => {
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg,
          showCancelButton: false
        }
      }));
    }
    calllback(false);
    Promise.reject(error);
  });
};

const SaveBackupWhiteList = (obj, calllback = () => {}) => dispatch => {
  apiService.post('/WhiteList/SaveBackupWhiteList', obj).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      calllback();
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
    }
  }).catch(error => {
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg,
          showCancelButton: false
        }
      }));
    }
    Promise.reject(error);
  });
};

const SaveBackupIP = (obj, calllback = () => {}) => dispatch => {
  apiService.post('/WhiteList/SaveBackupIP', obj).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      calllback();
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
    }
  }).catch(error => {
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg,
          showCancelButton: false
        }
      }));
    }
    Promise.reject(error);
  });
};

const SetStatus = (obj, calllback = () => {}) => dispatch => {
  apiService.post('/WhiteList/SetStatus', obj).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      calllback();
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
    }
  }).catch(error => {
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg,
          showCancelButton: false
        }
      }));
    }
    calllback(false);
    Promise.reject(error);
  });
};

const SetState = (obj, calllback = () => {}) => dispatch => {
  apiService.post('/WhiteList/SetState', obj).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      calllback();
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
    }
  }).catch(error => {
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg,
          showCancelButton: false
        }
      }));
    }
    calllback(false);
    Promise.reject(error);
  });
};

const EditInfo = (obj, calllback = () => {}) => dispatch => {
  apiService.get('/WhiteList/EditInfo', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      calllback();
      dispatch(setEditWhite(data.result));
    }
  }).catch(error => {
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg,
          showCancelButton: false
        }
      }));
    }
    Promise.reject(error);
  });
};

const SaveInfo = (obj, calllback = () => {}) => dispatch => {
  apiService.post('/WhiteList/SaveInfo', obj).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      calllback();
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
    }
  }).catch(error => {
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg,
          showCancelButton: false
        }
      }));
    }
    Promise.reject(error);
  });
};

export default {
  getSuperUser,
  getInfo,
  EditInfo,
  SaveInfo,
  getBackupIP,
  getSubAccountList,
  editBackupIP,
  deleteBackupIP,
  SaveBackupIP,
  SetState,
  GetBackupWhiteList,
  SetStatus,
  DelBackupWhiteList,
  EditBackupWhiteList,
  SaveBackupWhiteList
};