import { useEffect, useState } from 'react';

import { Button, Col, Label, Row } from 'reactstrap';
import { FaPlusCircle } from 'react-icons/fa';
import { connect } from 'react-redux';

import { useTranslation } from 'react-i18next';

import { appActions, systemActions } from '../../../redux/actions';
import AgentDataTable from '../../AgentDataTable';
import './styles.scss';


const mapStateToProps = state => ({
  emailSettingData: state.systemReducers.emailSettingData,
  userData: state.memberReducers.userData,
  smsSettingData: state.systemReducers.smsSettingData,
});

const mapDispatchToProps = dispatch => ({
  getEmailSetting: (obj, callback) => dispatch(systemActions.getEmailSetting(obj, callback)),
  getSMSSetting: (obj, callback) => dispatch(systemActions.getSMSSetting(obj, callback)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  setSMSStatus: (obj, callback) => dispatch(systemActions.setSMSStatus(obj, callback)),
  setEmailStatus: (obj, callback) => dispatch(systemActions.setEmailStatus(obj, callback)),
  deleteEmailSetting: (obj, callback) => dispatch(systemActions.deleteEmailSetting(obj, callback)),
  deleteSMSSetting: (obj, callback) => dispatch(systemActions.deleteSMSSetting(obj, callback)),
});

const EmailSMSSetting = ({ callback = () => {}, updateModalAlert, emailSettingData, smsSettingData, getEmailSetting, getSMSSetting, data, setEmailStatus, setSMSStatus, deleteEmailSetting, deleteSMSSetting }) => {
  const { t } = useTranslation();
  const [_smsInfo, setSMSInfo] = useState([]);
  const [_emailinfo, setEmailInfo] = useState([]);
  const [_isSMSQueryingDetail, setIsSMSQueryingDetail] = useState(true);
  const [_isEmailQueryingDetail, setIsEmailQueryingDetail] = useState(true);

  useEffect(() => {
    if (emailSettingData && JSON.stringify(emailSettingData?.emailList) !== JSON.stringify(_emailinfo)) {
      setEmailInfo(emailSettingData?.emailList);
    }
  }, [emailSettingData]);

  useEffect(() => {
    if (smsSettingData && JSON.stringify(smsSettingData?.smsList) !== JSON.stringify(_smsInfo)) {
      setSMSInfo(smsSettingData?.smsList);
    }
  }, [smsSettingData]);

  useEffect(() => {
    setIsEmailQueryingDetail(true);
    getEmailSetting({
      page: 1,
      rows: 30,
    }, setIsEmailQueryingDetail);
    getSMSSetting({
      page: 1,
      rows: 30,
    }, setIsSMSQueryingDetail);
  }, []);

  const afterSuccessSMSCallBack = () => {
    getSMSSetting({
      page: 1,
      rows: 30,
    }, setIsSMSQueryingDetail);
  };

  const afterSuccessEmailCallBack = () => {
    getEmailSetting({
      page: 1,
      rows: 30,
    }, setIsEmailQueryingDetail);
  };

  const setSMSClick = id => {
    setSMSStatus(id, afterSuccessSMSCallBack);
  };

  const setEmailClick = id => {
    setEmailStatus(id, afterSuccessEmailCallBack);
  };

  const deleteSMS = id => {
    updateModalAlert({
      type: 'confirmation',
      visible: true,
      showCancelButton: true,
      data: {
        message: '确认删除',
        onConfirmationClick: () => deleteSMSSetting(id, afterSuccessSMSCallBack)
      },
    });
  };

  const deleteEmail = id => {
    updateModalAlert({
      type: 'confirmation',
      visible: true,
      showCancelButton: true,
      data: {
        message: '确认删除',
        onConfirmationClick: () => deleteEmailSetting(id, afterSuccessEmailCallBack)
      },
    });
  };

  const tableSMSHeader = [{
    label: t('SMS_PLATFORM'),
  }, {
    label: 'url',
  }, {
    label: t('SEND_INTERVAL_IN_SEC'),
  }, {
    label: t('STATUS'),
  }, {
    label: t('CLIENT_PLATFORM'),
  }, {
    label: t('OPERATION'),
  }];

  const tableSMSContent = [
    data => (<Label className="d-flex justify-content-center">{data?.name}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.url}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.interval}</Label>),
    data => (data?.status === 1 ? <Label className="negativeValue">{t('BAN')}</Label> : <Label className="installment">{t('ALLOW')}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.platform}</Label>),
    data => (
      <>
        <Button className="button handle mx-1" onClick={() => callback('smsAddEdit', data, true)}>{t('COMPILE')}</Button>
        <Button className="button yellow mx-1" onClick={() => setSMSClick(data?.id)}>{data?.status === 1 ? t('ALLOW') : t('BAN')}</Button>
        <Button className="button find mx-1" onClick={() => deleteSMS(data?.id)}>{t('DELETE')}</Button>
      </>
    ),
  ];

  const tableHeader = [{
    label: t('MAILBOX_ALT_NAME'),
  }, {
    label: 'smtp',
  }, {
    label: t('PORT'),
  }, {
    label: t('SEND_INTERVAL_IN_SEC'),
  }, {
    label: t('ACCOUNT_NAME'),
  }, {
    label: t('STATUS'),
  }, {
    label: t('CLIENT_PLATFORM'),
  }, {
    label: t('FUNCTION'),
  }];

  const tableContent = [
    data => (<Label className="d-flex justify-content-center">{data?.name}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.smtp}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.port}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.interval}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.loginName}</Label>),
    data => (data?.status === 1 ? <Label className="negativeValue">{t('BAN')}</Label> : <Label className="installment">{t('ALLOW')}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.platform}</Label>),
    data => (
      <>
        <Button className="button handle mx-1" onClick={() => callback('emailAddEdit', data, true)}>{t('COMPILE')}</Button>
        <Button className="button yellow mx-1" onClick={() => setEmailClick(data?.id)}>{data?.status === 1 ? t('ALLOW') : t('BAN')}</Button>
        <Button className="button find mx-1" onClick={() => deleteEmail(data?.id)}>{t('DELETE')}</Button>
      </>
    ),
  ];

  return (
    <div className="EmailSMSSettingWrapper">
      <div className="mx-0 bg-white my-2  border-1">
        <Row className="p-0 m-0 mb-2">
          <Col className="p-2 mb-2 filterWrapper d-flex align-items-center w-100">
            <Col className="d-flex align-items-center p-1">
              <Label className="px-2">短信接口管理</Label>
              <Button
                className="button mx-2 find justify-content-center"
                onClick={() => callback('smsAddEdit', data, false)}
              >
                <FaPlusCircle className="mb-1 mr-1" />
                <Label className="p-0 px-1">{t('ADD_SMS_PORT')}</Label>
              </Button>
            </Col>
          </Col>
        </Row>
        <Row className="bigWrapper m-0 my-1 p-3 mb-3">
          <AgentDataTable
            isQuerying={_isSMSQueryingDetail}
            tableHeaderTitle={tableSMSHeader}
            tableData={_smsInfo || (_isSMSQueryingDetail ? [1, 2, 3] : [])}
            tableBodyRowProperty={tableSMSContent}
            tableBodyRowStyle={[]}
          />
        </Row>
        <Row className="p-0 m-0 mb-2">
          <Col className="p-2 mb-2 filterWrapper d-flex align-items-center w-100">
            <Col className="d-flex align-items-center p-1">
              <Label className="px-2">邮箱接口管理</Label>
              <Button
                className="button mx-2 find justify-content-center"
                onClick={() => callback('emailAddEdit', data, false)}
              >
                <FaPlusCircle className="mb-1 mr-1" />
                <Label className="p-0 px-1">{t('ADD_EMAIL_PORT')}</Label>
              </Button>
            </Col>
          </Col>
        </Row>
        <Row className="bigWrapper m-0 my-1 p-3">
          <AgentDataTable
            isQuerying={_isEmailQueryingDetail}
            tableHeaderTitle={tableHeader}
            tableData={_emailinfo || (_isEmailQueryingDetail ? [1, 2, 3] : [])}
            tableBodyRowProperty={tableContent}
            tableBodyRowStyle={[]}
          />
        </Row>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailSMSSetting);