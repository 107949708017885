import { StatusCodes } from 'http-status-codes';

import { SET_BET_WARNING_MONITOR_QUERY_DATA, SET_SET_REMINDER_AMOUNT_QUERY_DATA } from '../actionTypes';

import { apiService } from '../../../library';
import appActions from '../appActions';


const setBetQueryData = val => ({
  type: SET_BET_WARNING_MONITOR_QUERY_DATA,
  value: val //
});

const setSetReminderAmountBetQueryData = val => ({
  type: SET_SET_REMINDER_AMOUNT_QUERY_DATA,
  value: val //
});

const QueryBetingWarningMonitor = (obj, callback, catchCallback) => dispatch => {
  apiService.get('/Betwarning/betwarningdashboard', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        dispatch(setBetQueryData(data.result));
        if (callback) {
          callback();
        }
      }
    }
  }).catch(error => {
    if (catchCallback) {
      catchCallback();
    }
    Promise.reject(error);
  });
};

const QuerySetReminderAmount = obj => dispatch => {
  apiService.get('/BetWarning/getInfo', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        return dispatch(setSetReminderAmountBetQueryData(data.result));
      }
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const SaveBetWarnSetting = (data, callback) => dispatch => {
  apiService.post('/BetWarning/SaveInfo', data).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        dispatch(appActions.updateModalAlert({
          visible: false
        }));
        dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'success',
          data: {
            message: data.msg,
          }
        }));
        if (callback) {
          callback();
        }
      }
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg,
          showCancelButton: false
        }
      }));
    }
    Promise.reject(error);
  });
};

const SaveFastBetWarnSetting = (data, callback) => dispatch => {
  apiService.post('/BetWarning/SaveFastInfo', data).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        dispatch(appActions.updateModalAlert({
          visible: false
        }));
        dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'success',
          data: {
            message: data.msg,
          }
        }));
        if (callback) {
          callback();
        }
      }
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg,
          showCancelButton: false
        }
      }));
    }
    Promise.reject(error);
  });
};

export default {
  QueryBetingWarningMonitor,
  QuerySetReminderAmount,
  SaveBetWarnSetting,
  SaveFastBetWarnSetting
};