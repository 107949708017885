import { championDs, championDx, dxds } from '../Items/dsdx';
import { dragonTiger } from '../Items/others';

import { PK101to10, PK101to10Code, number1to20 } from '../Items/numbers';

const CJ_PK10 = [{
  displayName: '冠军总',
  isStaticRow: 1,
  midcode: 'GYJH,GJHM,GJLM,YJHM,YJLM',
  display: [[...number1to20.slice(2, 19)], [...championDx, ...championDs], [...dxds, ...dragonTiger, ...PK101to10Code], [...dxds, ...dragonTiger, ...PK101to10Code]],
  firstTableHeader: ['冠、亚军和指定', '冠、亚军和两面', '冠军', '亚军'],
  prefix: ['GYJH', '', 'GJ', 'YJ'],
  tabId: 'count_GJHM',
  summary: ['', [{
    displayName: '总投注额',
    code: 'count_SUM_PK10'
  }, {
    displayName: '最高亏损',
    code: 'maxLoss'
  }, {
    displayName: '最高盈利',
    code: 'maxProfit'
  }], [{
    displayName: '冠军总注额',
    code: 'count_GJHM'
  }], [{
    displayName: '亚军总注额',
    code: 'count_YJHM'
  }]]
}, {
  displayName: '亚军总',
  isStaticRow: 1,
  midcode: 'GYJH,GJHM,GJLM,YJHM,YJLM',
  display: [[...number1to20.slice(2, 19)], [...championDx, ...championDs], [...dxds, ...dragonTiger, ...PK101to10Code], [...dxds, ...dragonTiger, ...PK101to10Code]],
  firstTableHeader: ['冠、亚军和指定', '冠、亚军和两面', '冠军', '亚军'],
  prefix: ['GYJH', '', 'GJ', 'YJ'],
  tabId: 'count_YJHM',
  summary: ['', [{
    displayName: '总投注额',
    code: 'count_SUM_PK10'
  }, {
    displayName: '最高亏损',
    code: 'maxLoss'
  }, {
    displayName: '最高盈利',
    code: 'maxProfit'
  }], [{
    displayName: '冠军总注额',
    code: 'count_GJHM'
  }], [{
    displayName: '亚军总注额',
    code: 'count_YJHM'
  }]]
}, {
  displayName: '第三名总',
  midcode: 'B3CH,B3LM,B4CH,B4LM,B5CH,B5LM,B6CH,B6LM',
  isStaticRow: 1,
  display: [[...dxds, ...dragonTiger, ...PK101to10], [...dxds, ...dragonTiger, ...PK101to10], [...dxds, ...dragonTiger, ...PK101to10], [...dxds, ...PK101to10]],
  firstTableHeader: ['第三名', '第四名', '第五名', '第六名'],
  prefix: ['B3', 'B4', 'B5', 'B6'],
  tabId: 'count_B3CH',
  summary: [[{
    displayName: '第三名总注额',
    code: 'count_B3CH'
  }], [{
    displayName: '第四名总注额',
    code: 'count_B4CH'
  }], [{
    displayName: '第五名总注额',
    code: 'count_B5CH'
  }], [{
    displayName: '第六名总注额',
    code: 'count_B6CH'
  }]]
}, {
  displayName: '第四名总',
  midcode: 'B3CH,B3LM,B4CH,B4LM,B5CH,B5LM,B6CH,B6LM',
  isStaticRow: 1,
  display: [[...dxds, ...dragonTiger, ...PK101to10], [...dxds, ...dragonTiger, ...PK101to10], [...dxds, ...dragonTiger, ...PK101to10], [...dxds, ...PK101to10]],
  firstTableHeader: ['第三名', '第四名', '第五名', '第六名'],
  prefix: ['B3', 'B4', 'B5', 'B6'],
  tabId: 'count_B4CH',
  summary: [[{
    displayName: '第三名总注额',
    code: 'count_B3CH'
  }], [{
    displayName: '第四名总注额',
    code: 'count_B4CH'
  }], [{
    displayName: '第五名总注额',
    code: 'count_B5CH'
  }], [{
    displayName: '第六名总注额',
    code: 'count_B6CH'
  }]]
}, {
  displayName: '第五名总',
  midcode: 'B3CH,B3LM,B4CH,B4LM,B5CH,B5LM,B6CH,B6LM',
  isStaticRow: 1,
  display: [[...dxds, ...dragonTiger, ...PK101to10], [...dxds, ...dragonTiger, ...PK101to10], [...dxds, ...dragonTiger, ...PK101to10], [...dxds, ...PK101to10]],
  firstTableHeader: ['第三名', '第四名', '第五名', '第六名'],
  prefix: ['B3', 'B4', 'B5', 'B6'],
  tabId: 'count_B5CH',
  summary: [[{
    displayName: '第三名总注额',
    code: 'count_B3CH'
  }], [{
    displayName: '第四名总注额',
    code: 'count_B4CH'
  }], [{
    displayName: '第五名总注额',
    code: 'count_B5CH'
  }], [{
    displayName: '第六名总注额',
    code: 'count_B6CH'
  }]]
}, {
  displayName: '第六名总',
  midcode: 'B3CH,B3LM,B4CH,B4LM,B5CH,B5LM,B6CH,B6LM',
  isStaticRow: 1,
  display: [[...dxds, ...dragonTiger, ...PK101to10], [...dxds, ...dragonTiger, ...PK101to10], [...dxds, ...dragonTiger, ...PK101to10], [...dxds, ...PK101to10]],
  firstTableHeader: ['第三名', '第四名', '第五名', '第六名'],
  prefix: ['B3', 'B4', 'B5', 'B6'],
  tabId: 'count_B6CH',
  summary: [[{
    displayName: '第三名总注额',
    code: 'count_B3CH'
  }], [{
    displayName: '第四名总注额',
    code: 'count_B4CH'
  }], [{
    displayName: '第五名总注额',
    code: 'count_B5CH'
  }], [{
    displayName: '第六名总注额',
    code: 'count_B6CH'
  }]]
}, {
  displayName: '第七名总',
  midcode: 'B7CH,B7LM,B8CH,B8LM,B9CH,B9LM,B10CH,B10LM',
  isStaticRow: 1,
  display: [[...dxds, ...PK101to10], [...dxds, ...PK101to10], [...dxds, ...PK101to10], [...dxds, ...PK101to10]],
  firstTableHeader: ['第七名', '第八名', '第九名', '第十名'],
  prefix: ['B7', 'B8', 'B9', 'B10'],
  tabId: 'count_B7CH',
  summary: [[{
    displayName: '第七名总注额',
    code: 'count_B7CH'
  }], [{
    displayName: '第八名总注额',
    code: 'count_B8CH'
  }], [{
    displayName: '第九名总注额',
    code: 'count_B9CH'
  }], [{
    displayName: '第十名总注额',
    code: 'count_B10CH'
  }]]
}, {
  displayName: '第八名总',
  midcode: 'B7CH,B7LM,B8CH,B8LM,B9CH,B9LM,B10CH,B10LM',
  isStaticRow: 1,
  display: [[...dxds, ...PK101to10], [...dxds, ...PK101to10], [...dxds, ...PK101to10], [...dxds, ...PK101to10]],
  firstTableHeader: ['第七名', '第八名', '第九名', '第十名'],
  prefix: ['B7', 'B8', 'B9', 'B10'],
  tabId: 'count_B8CH',
  summary: [[{
    displayName: '第七名总注额',
    code: 'count_B7CH'
  }], [{
    displayName: '第八名总注额',
    code: 'count_B8CH'
  }], [{
    displayName: '第九名总注额',
    code: 'count_B9CH'
  }], [{
    displayName: '第十名总注额',
    code: 'count_B10CH'
  }]]
}, {
  displayName: '第九名总',
  midcode: 'B7CH,B7LM,B8CH,B8LM,B9CH,B9LM,B10CH,B10LM',
  isStaticRow: 1,
  display: [[...dxds, ...PK101to10], [...dxds, ...PK101to10], [...dxds, ...PK101to10], [...dxds, ...PK101to10]],
  firstTableHeader: ['第七名', '第八名', '第九名', '第十名'],
  prefix: ['B7', 'B8', 'B9', 'B10'],
  tabId: 'count_B9CH',
  summary: [[{
    displayName: '第七名总注额',
    code: 'count_B7CH'
  }], [{
    displayName: '第八名总注额',
    code: 'count_B8CH'
  }], [{
    displayName: '第九名总注额',
    code: 'count_B9CH'
  }], [{
    displayName: '第十名总注额',
    code: 'count_B10CH'
  }]]
}, {
  displayName: '第十名总',
  midcode: 'B7CH,B7LM,B8CH,B8LM,B9CH,B9LM,B10CH,B10LM',
  isStaticRow: 1,
  display: [[...dxds, ...PK101to10], [...dxds, ...PK101to10], [...dxds, ...PK101to10], [...dxds, ...PK101to10]],
  firstTableHeader: ['第七名', '第八名', '第九名', '第十名'],
  prefix: ['B7', 'B8', 'B9', 'B10'],
  tabId: 'count_B10CH',
  summary: [[{
    displayName: '第七名总注额',
    code: 'count_B7CH'
  }], [{
    displayName: '第八名总注额',
    code: 'count_B8CH'
  }], [{
    displayName: '第九名总注额',
    code: 'count_B9CH'
  }], [{
    displayName: '第十名总注额',
    code: 'count_B10CH'
  }]]
}, {
  displayName: '冠亚和总',
  isStaticRow: 1,
  midcode: 'GYJH,GJHM,GJLM,YJHM,YJLM',
  display: [[...number1to20.slice(2, 19)], [...championDx, ...championDs], [...dxds, ...dragonTiger, ...PK101to10Code], [...dxds, ...dragonTiger, ...PK101to10Code]],
  firstTableHeader: ['冠、亚军和指定', '冠、亚军和两面', '冠军', '亚军'],
  prefix: ['GYJH', '', 'GJ', 'YJ'],
  tabId: 'count_GYJH',
  summary: ['', [{
    displayName: '总投注额',
    code: 'count_SUM_PK10'
  }, {
    displayName: '最高亏损',
    code: 'maxLoss'
  }, {
    displayName: '最高盈利',
    code: 'maxProfit'
  }], [{
    displayName: '冠军总注额',
    code: 'count_GJHM'
  }], [{
    displayName: '亚军总注额',
    code: 'count_YJHM'
  }]]
},
{
  displayName: '冠亚大小',
  isStaticRow: 1,
  midcode: 'GYJH,GJHM,GJLM,YJHM,YJLM',
  display: [[...number1to20.slice(2, 19)], [...championDx, ...championDs], [...dxds, ...dragonTiger, ...PK101to10Code], [...dxds, ...dragonTiger, ...PK101to10Code]],
  firstTableHeader: ['冠、亚军和指定', '冠、亚军和两面', '冠军', '亚军'],
  prefix: ['GYJH', '', 'GJ', 'YJ'],
  tabId: 'count_GYJHDX',
  summary: ['', [{
    displayName: '总投注额',
    code: 'count_SUM_PK10'
  }, {
    displayName: '最高亏损',
    code: 'maxLoss'
  }, {
    displayName: '最高盈利',
    code: 'maxProfit'
  }], [{
    displayName: '冠军总注额',
    code: 'count_GJHM'
  }], [{
    displayName: '亚军总注额',
    code: 'count_YJHM'
  }]]
}, {
  displayName: '冠亚单双',
  isStaticRow: 1,
  midcode: 'GYJH,GJHM,GJLM,YJHM,YJLM',
  display: [[...number1to20.slice(2, 19)], [...championDx, ...championDs], [...dxds, ...dragonTiger, ...PK101to10Code], [...dxds, ...dragonTiger, ...PK101to10Code]],
  firstTableHeader: ['冠、亚军和指定', '冠、亚军和两面', '冠军', '亚军'],
  prefix: ['GYJH', '', 'GJ', 'YJ'],
  tabId: 'count_GYJHDS',
  summary: ['', [{
    displayName: '总投注额',
    code: 'count_SUM_PK10'
  }, {
    displayName: '最高亏损',
    code: 'maxLoss'
  }, {
    displayName: '最高盈利',
    code: 'maxProfit'
  }], [{
    displayName: '冠军总注额',
    code: 'count_GJHM'
  }], [{
    displayName: '亚军总注额',
    code: 'count_YJHM'
  }]]
},
];

export default CJ_PK10;