import { useEffect, useState } from 'react';

import { Col, Label, Row } from 'reactstrap';
import { Input, Radio } from 'antd';
import { FaCaretDown } from 'react-icons/fa';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { AgentDataTable, CommonHeader, LotteryMenu } from '../../components';
import { appActions, autoRestockSettingActions, commonActions } from '../../redux/actions';
import { AutoRestockStatus } from '../../constants/SelectionItems';


import './styles.scss';

const mapStateToProps = state => ({
  activeLottery: state.commonReducers.activeLottery,
  autoRestockList: state.autoRestockSettingReducers.autoRestockList,
  lotteryList: state.commonReducers.lotteryList,
  lotteryInstallments: state.commonReducers.lotteryInstallments,
});

const mapDispatchToProps = dispatch => ({
  getBuhuoListResult: lotteryCode => dispatch(autoRestockSettingActions.getBuhuoListResult(lotteryCode)),
  getDefaultBuhuoList: lotteryCode => dispatch(autoRestockSettingActions.getDefaultBuhuoList(lotteryCode)),
  getLotteryInstallments: (lotteryCode, date) => dispatch(commonActions.getLotteryInstallments(lotteryCode, date)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  saveBuhuoList: (lotteryCode, orgId, buhuoList) => dispatch(autoRestockSettingActions.saveBuhuoList(lotteryCode, orgId, buhuoList)),
  saveDefaultBuhuo: (lotteryCode, orgId, buhuoList) => dispatch(autoRestockSettingActions.saveDefaultBuhuo(lotteryCode, orgId, buhuoList)),
});

const AutoRestockSetting = ({ lotteryList, getBuhuoListResult, getDefaultBuhuoList, autoRestockList, saveBuhuoList, saveDefaultBuhuo, updateModalAlert, activeLottery, isAdmin }) => {
  const { t } = useTranslation();
  const [_selectedLottery, setSelectedLottery] = useState(activeLottery?.code || 'HK6');
  const [_selecteLotteryTxt, setSelectedLotteryTxt] = useState();
  const [_displayMenu, setDisplayMenu] = useState(false);
  const orgId = autoRestockList?.orgId;

  const [quickText, setquickText] = useState(0);
  const [quickStatus, setquickStatus] = useState(0);
  const [_autoRestockList, setAutoRestockList] = useState();

  useEffect(() => {
    if (lotteryList) {
      isAdmin ? getDefaultBuhuoList(_selectedLottery) : getBuhuoListResult(_selectedLottery);
      setSelectedLotteryTxt(lotteryList.find(item => item.code === _selectedLottery)?.lotteryName);
    }
  }, [lotteryList]);

  useEffect(() => {
    if (autoRestockList) {
      const temp = autoRestockList.buhuoList.map(item => ({
        ...item, isChange: false
      }));
      setAutoRestockList(temp);
    }
  }, [autoRestockList]);

  useEffect(() => {
    if (activeLottery) {
      isAdmin ? getDefaultBuhuoList(activeLottery?.code) : getBuhuoListResult(activeLottery?.code);
      setSelectedLotteryTxt(activeLottery?.lotteryName);
      setSelectedLottery(activeLottery?.code);
    }
  }, [activeLottery]);

  const onValueChange = (value, property, code) => {
    const temp = _autoRestockList.map(item => (item.code === code ? ({
      ...item, [property]: value.toString().trim() > -1 ? Number(value.toString().trim()) : item[property], isChange: true
    }) : ({
      ...item
    })));

    setAutoRestockList(temp);
  };

  const onSaveClick = () => {
    const res = _autoRestockList.filter(item => item?.isChange === true);
    if (res.length > 0) {
      updateModalAlert({
        type: 'loading',
        visible: true,
      });
      isAdmin ? saveDefaultBuhuo(_selectedLottery || 'HK6', orgId, res, onResetClick) : saveBuhuoList(_selectedLottery || 'HK6', orgId, res, onResetClick);
    } else {
      updateModalAlert({
        type: 'confirmation',
        visible: true,
        data: {
          message: '没有任何改动',
          showCancelButton: false
        }
      });
    }
  };

  const onResetClick = () => {
    const temp = autoRestockList.buhuoList.map(item => ({
      ...item, isChange: false
    }));
    setAutoRestockList(temp);
    setquickText(0);
  };

  const onLotterySelect = retvalue => {
    setSelectedLottery(retvalue?.code);
    setSelectedLotteryTxt(retvalue?.lotteryName);
    isAdmin ? getDefaultBuhuoList(retvalue?.code) : getBuhuoListResult(retvalue?.code);
    setDisplayMenu(false);
  };

  const onAllChange = () => {
    const temp = _autoRestockList.map(item => ({
      ...item, isChange: true, autoSetting: quickStatus, moneyLimit: quickText
    }));
    setAutoRestockList(temp);
  };

  return (
    <Col className="autoBuhuoSettingWarpper">
      <Col className="content">
        <CommonHeader headerName={t('AUTO_RESTOCK_SETTING')} />
        <Col className="subMenu p-1 px-2 mb-2 flex">
          <div
            onMouseEnter={() => setDisplayMenu(!_displayMenu)}
            onClick={() => setDisplayMenu(!_displayMenu)}
            className={`menuWrapper d-flex ${_displayMenu ? 'menuactive' : ''}`}
          >
            <Label className="menuSelectedText">{_selecteLotteryTxt}</Label>
            <div className="float-right">
              <FaCaretDown className="float-right" />
            </div>
          </div>

          <div onMouseLeave={() => setDisplayMenu(false)} className={`subselection ${_displayMenu ? '' : 'hide'}`}>
            <LotteryMenu
              lotteryList={lotteryList}
              selectedItem={_selectedLottery}
              onClickItem={onLotterySelect}
            />
          </div>
        </Col>
        <Row className="mb-2">
          <Col>
            <AgentDataTable
              tableHeaderTitle={
                [{
                  label: t('TYPE'),
                }, {
                  label: t('BET_AMOUNT'),
                }, {
                  label: t('STATUS'),
                }]
              }
              tableData={_autoRestockList ? _autoRestockList.slice(0, _autoRestockList.length / 2) : []}
              tableBodyRowProperty={
                [
                  data => (<Label className="d-flex justify-content-center">{data.projectName}</Label>),
                  data => (
                    <div className="d-flex align-items-center justify-content-center">
                      <Input
                        className="inputStyle"
                        value={data.moneyLimit}
                        onChange={({ target: { value: text } }) => onValueChange(text, 'moneyLimit', data.code)}
                      />
                    </div>
                  ),
                  data => (
                    <div className="d-flex align-items-center justify-content-center">
                      <Radio.Group onChange={({ target: { value: text } }) => onValueChange(text, 'autoSetting', data.code)} value={data.autoSetting}>
                        {
                          AutoRestockStatus.map(item => <Radio value={item.value}>{item.label}</Radio>)
                        }
                      </Radio.Group>
                    </div>
                  ),
                ]
              }
              tableBodyRowStyle={
                ['backgroundColored']
              }
              tableHeaderStyle={
                ['col-2']
              }
            />
          </Col>
          <Col>
            <AgentDataTable
              tableHeaderTitle={
                [{
                  label: t('TYPE'),
                }, {
                  label: t('BET_AMOUNT'),
                }, {
                  label: t('STATUS'),
                }]
              }
              tableData={_autoRestockList ? _autoRestockList.slice(_autoRestockList.length / 2) : []}
              tableBodyRowProperty={
                [
                  data => (<Label className="d-flex justify-content-center">{data.projectName}</Label>),
                  data => (
                    <div className="d-flex align-items-center justify-content-center">
                      <Input
                        className="inputStyle"
                        value={data.moneyLimit}
                        onChange={({ target: { value: text } }) => onValueChange(text, 'moneyLimit', data.code)}
                      />
                    </div>
                  ),
                  data => (
                    <div className="d-flex align-items-center justify-content-center">
                      <Radio.Group onChange={({ target: { value: text } }) => onValueChange(text, 'autoSetting', data.code)} value={data.autoSetting}>
                        {
                          AutoRestockStatus.map(item => <Radio value={item.value}>{item.label}</Radio>)
                        }
                      </Radio.Group>
                    </div>
                  ),
                ]
              }
              tableBodyRowStyle={
                ['backgroundColored']
              }
              tableHeaderStyle={
                ['col-2']
              }
            />
          </Col>
        </Row>
        <Row className="mb-5 border-top">
          <Col className="d-flex justify-content-center py-3">
            <div className="saveButton mx-2" onClick={onSaveClick}>{t('SAVE')}</div>
            <div className="cancelButton mx-2" onClick={onResetClick}>{t('CANCEL')}</div>
          </Col>
        </Row>
      </Col>
      <Col className="flex align-items-center justify-content-start footer p-2 m-0">
        <Col className="d-flex align-items-center justify-content-start">
          <Label className="flex align-items-center justify-content-center mx-1">快速设置：</Label>
          <Input className="inputStyle" value={quickText} onChange={({ target: { value: text } }) => setquickText(text.trim() > -1 ? Number(text.trim()) : quickText)} />
          <Radio.Group className="mx-1" onChange={({ target: { value: text } }) => setquickStatus(text)} value={quickStatus}>
            {
              AutoRestockStatus.map(item => <Radio value={item.value}>{item.label}</Radio>)
            }
          </Radio.Group>
          <div className="saveButton mx-2" onClick={onAllChange}>{t('CONFIRM')}</div>
          <div className="saveButton mx-1" onClick={onSaveClick}>{t('SAVE')}</div>
        </Col>
      </Col>
    </Col>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AutoRestockSetting);