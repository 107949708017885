import { useEffect, useState } from 'react';

import { Col, Label, Row } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { AgentDataTable, CommonHeader, Pagination } from '../../components';
import { appActions, operationLogAtions } from '../../redux/actions';


import './styles.scss';

const mapStateToProps = state => ({
  operationLogResult: state.operationLogReducers.operationLogResult,
});

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  getOperationLogByType: (opType, rows, page) => dispatch(operationLogAtions.getOperationLogByType(opType, rows, page)),
});


const OperationLog = ({ getOperationLogByType, operationLogResult }) => {
  const { t } = useTranslation();
  const [_isQueryingDetail, setIsQueryingDetail] = useState(true);
  const [_detailItem, setDetailItem] = useState();
  const [_detailItemSum, setDetailItemSum] = useState();
  const [_page, setPage] = useState(1);
  const [_rows, setRows] = useState(15);
  const operationType = 1;

  useEffect(() => {
    getOperationLogByType(operationType, _rows, _page);
  }, []);

  useEffect(() => {
    if (operationLogResult && JSON.stringify(_detailItem) !== JSON.stringify(operationLogResult)) {
      setDetailItem(operationLogResult.data);
      setDetailItemSum(operationLogResult.sum);
      setIsQueryingDetail(false);
    }
  }, [operationLogResult]);

  const onPaginationClick = (pageNum, rows) => {
    setIsQueryingDetail(false);
    // Page number and row number will follow the pagination component instead
    getOperationLogByType(
      operationType,
      rows,
      pageNum,
    );
    setPage(pageNum);
    setRows(rows);
  };

  return (
    <Col className="restockOperationLogWrapper">
      <Col className="content">
        <CommonHeader headerName={t('RESTOCK_CHANGE_HISTORY')} />
      </Col>
      <Row className="mx-0 bg-white border-1">
        <AgentDataTable
          isQuerying={_isQueryingDetail}
          tableHeaderTitle={
            [{
              label: t('ORDER_NUM'),
            }, {
              label: t('OPERATION_TIME'),
            }, {
              label: t('OPERATION_TYPE'),
            }, {
              label: t('VALUE_BEFORE'),
            }, {
              label: t('VALUE_AFTER'),
            }, {
              label: 'IP',
            }, {
              label: `IP ${t('DEPENDENCY')}`,
            }, {
              label: t('UPDATED_BY'),
            }, {
              label: t('TARGET_ACCOUNT'),
            }]
          }
          tableData={_detailItem || []}
          tableBodyRowProperty={
            [
              data => (<Label className="d-flex justify-content-center">{data.rowNo}</Label>),
              data => (
                <Label className="px-1">{moment.unix(data.operationDate).format('YYYY-MM-DD hh:mm:ss')}</Label>
              ),
              data => (<Label className="d-flex justify-content-center">{data.operation}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.oldValue}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.newValue}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.ipAddress}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.ipPlace}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.loginId}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.remark}</Label>),
            ]
          }
          tableHeaderStyle={
            ['smallCol']
          }
        />
      </Row>
      <Row className="justify-center w-full relative">
        <Pagination totalRecord={(_detailItemSum && _detailItemSum?.totalRecords) || 0} onClick={onPaginationClick} isQuery={_isQueryingDetail} />
      </Row>
      <Row className="notice justify-content-center w-full relative py-3">
        注意:修改记录最少保留15天，超过15天部分最多保留最后50笔。
      </Row>
    </Col>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(OperationLog);