import { useEffect, useState } from 'react';

import { Button, Col, Row } from 'reactstrap';
import { Input } from 'antd';
import { IoClose } from 'react-icons/io5';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { appActions, cashActions } from '../../../redux/actions';
import './styles.scss';

const mapStateToProps = state => ({
  platform: state.appReducers.platform,
});

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  SaveSingleAgentComm: (obj, callback) => dispatch(cashActions.SaveSingleAgentComm(obj, callback)),
  SaveMultiAgentComm: (obj, callback) => dispatch(cashActions.SaveMultiAgentComm(obj, callback)),
});

const HandleAgentRebateModal = ({ updateModalAlert, data, SaveSingleAgentComm, SaveMultiAgentComm }) => {
  const { t } = useTranslation();

  const [remark, setremark] = useState('代理赚水');
  const [amount, setamount] = useState(0);

  useEffect(() => {
    if (data && data.type === 'single') {
      setamount(data.dataSet.find(x => x.ids === data.target).commAmount);
    }
    if (data && data.type === 'multi') {
      let _amount = 0;
      data.target.forEach(item => {
        _amount += parseFloat(data.dataSet.find(x => x.ids === item).commAmount);
      });
      setamount(_amount);
    }
  }, []);

  const clickBtn = () => {
    if (data.type === 'single') {
      const obj = {
        remark,
        amount,
        ids: data.target,
        mid: data.dataSet.find(x => x.ids === data.target).mid,
        actionType: 400
      };
      SaveSingleAgentComm(obj, () => data.onConfirmationClick());
    }
    if (data.type === 'multi') {
      const _ls = [];
      data.target.forEach(item => {
        const _data = data.dataSet.find(x => x.ids === item);
        _ls.push({
          amount: _data.commAmount, ids: item, mid: _data.mid
        });
      });
      const obj = {
        remark,
        data: _ls
      };
      SaveMultiAgentComm(obj, () => data.onConfirmationClick());
    }
  };

  return (
    <div className="HandleAgentRebateModalWrapperWeb">
      <Row className="modalHeader">
        <Col
          className={`${'d-flex justify-content-center align-items-center'}`}
          xs={{
            size: 4, offset: 4
          }}
        >
          代理赚水
        </Col>
        <Col xs="4" className="d-flex justify-content-end align-items-center">
          <IoClose
            onClick={() => {
              updateModalAlert({
                visible: false
              });
              if (data && data.overBet && data.onConfirmationClick) data.onConfirmationClick();
            }}
            className="h4 mb-0 float-right closeIcon"
          />
        </Col>
      </Row>
      <div className="modalContent p-3">
        <div className="rebateTable p-0">
          <div className="d-flex align-items-center">
            <div className="w-25">备注</div>
            <div className="w-75">
              <Input
                value={remark}
                onChange={text => setremark(text)}
              />
            </div>
          </div>
          <div className="d-flex align-items-center">
            <div className="w-25">金额</div>
            <div className="w-75">{amount}</div>
          </div>
        </div>
      </div>
      <Row className="modalFooter d-flex align-items-center">
        <Button
          className={`confirmButton my-3 ${'me-3'}`}
          onClick={() => {
            updateModalAlert({
              visible: false
            });
            clickBtn();
            if (data && data.onConfirmationClick) data.onConfirmationClick();
          }}
        >
          {(data && data.confirmText) || t('CONFIRM')}
        </Button>
        {
          data && data.showCancelButton !== false && (
            <Button
              className={`cancelButton my-3 ${'me-3'}`}
              onClick={() => {
                updateModalAlert({
                  visible: false
                });
                if (data && data.onCancelClick) data.onCancelClick();
              }}
            >
              {(data && data.cancelText) || t('CANCEL')}
            </Button>
          )
        }
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(HandleAgentRebateModal);