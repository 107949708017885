import { useEffect, useState } from 'react';

import { DatePicker, Input, Select } from 'antd';
import { Label } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';

import { AgentDataTable, Pagination } from '../..';
import { thirdPartyGamesActions } from '../../../redux/actions';
import './styles.scss';

const mapStateToProps = state => ({
  financialReport: state.reportReducers.financialReport,
});

const mapDispatchToProps = dispatch => ({
  GetCompanyBetReport: (obj, callback) => dispatch(thirdPartyGamesActions.GetCompanyBetReport(obj, callback)),
  GetMemberBetReport: (obj, callback) => dispatch(thirdPartyGamesActions.GetMemberBetReport(obj, callback)),
  GetMemberBetReportByDays: (obj, callback) => dispatch(thirdPartyGamesActions.GetMemberBetReportByDays(obj, callback)),
});

const { RangePicker } = DatePicker;

const { Option } = Select;

const CompanyGameReport = ({ GetCompanyBetReport, GetMemberBetReport, GetMemberBetReportByDays, startLevel = 0 }) => {
  const [_date, setDate] = useState([moment().toDate(), moment().toDate()]);
  const [_isQueryingDetail, setIsQueryingDetail] = useState(false);
  const [_dateFlag, setDateFlag] = useState('1');

  const [loginName, setloginName] = useState('');
  const [parentLoginName, setparentLoginName] = useState('');
  const [orderBy, setorderBy] = useState(0);
  const [desc, setdesc] = useState(0);

  const [data, setData] = useState();

  const [page, setpage] = useState(1);
  const [row, setrow] = useState(15);

  const [level, setLevel] = useState(startLevel);

  useEffect(() => {
    onSearchClick();
  }, []);

  const onSearchClick = (modefiedObj = {}, _level) => {
    const obj = {
      parentLoginName,
      loginName,
      beginTime: moment(_date[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
      endTime: moment(_date[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
      orderBy,
      desc,
      page,
      row,
      ...modefiedObj
    };
    if (!_level && level === 0) {
      const callback = data => {
        setData(data);
        setLevel(_level ?? 0);
      };
      GetCompanyBetReport(obj, data => callback(data));
    }
    if ((!_level && level === 1) || _level === 1) {
      const callback = data => {
        setData(data);
        setLevel(_level ?? 1);
      };
      GetMemberBetReport(obj, data => callback(data));
    }
    if ((!_level && level === 2) || _level === 2) {
      const callback = data => {
        setData(data);
        setLevel(_level ?? 2);
      };
      GetMemberBetReportByDays(obj, data => callback(data));
    }
  };

  const dateSelector = id => {
    setDateFlag(id);
    switch (id) {
      case '1':
        setDate([moment().toDate(), moment().toDate()]);
        break;
      case '2':
        setDate([moment().subtract(1, 'days').startOf('day').toDate(), moment().subtract(1, 'days').endOf('day').toDate()]);
        break;
      case '3':
        setDate([moment().startOf('isoWeek').toDate(), moment().endOf('isoWeek').toDate()]);
        break;
      case '4':
        setDate([moment().subtract(1, 'weeks').startOf('isoWeek').toDate(), moment().subtract(1, 'weeks').endOf('isoWeek').toDate()]);
        break;
      case '5':
        setDate([moment().startOf('month').toDate(), moment().endOf('month').toDate()]);
        break;
      case '6':
        setDate([moment().subtract(1, 'months').startOf('month').toDate(), moment().subtract(1, 'months').endOf('month').toDate()]);
        break;
      default:
        break;
    }
  };

  const onPaginationClick = (pageNum, rows) => {
    setIsQueryingDetail(false);
    setpage(pageNum);
    setrow(rows);
    onSearchClick({
      page: pageNum, row
    });
  };

  const onClickRow = data => {
    if (level === 0) {
      onSearchClick({
        parentLoginName: data.agentLoginName, loginName: '', page: 1, row: 15
      }, 1);
      setpage(1);
      setrow(15);
    }
    if (level === 1) {
      onSearchClick({
        parentLoginName: '', loginName: data.loginName, page: 1, row: 15
      }, 2);
      setpage(1);
      setrow(15);
    }
  };

  const generateDataDetail = (total = '--', list = []) => (
    <div className="gameDetailCell">
      <div className={`${total > 0 ? 'text-primary' : total < 0 ? 'text-danger' : ''}`}>{`总计：${total}`}</div>
      <div>
        {
          list.map(item => {
            const _name = [].concat(data?.gameList || []).find(x => x.gameCode === Object.keys(item)[0])?.gameName || '';
            const _value = Object.values(item)[0];
            return <div className={` me-1 mt-1 ${_value > 0 ? 'text-primary' : _value < 0 ? 'text-danger' : ''}`}>{`${_name}:${_value};`}</div>;
          })
        }
      </div>
    </div>
  );

  return (
    <div className="CompanyGameReportWrapper">
      <div className="CompanyGameReportTableWrapper d-flex p-3">
        <div className="userTable w-100">
          <div className="d-flex">
            <div>日期范围</div>
            <div>
              <RangePicker
                allowClear={false}
                value={[moment(_date[0]), moment(_date[1])]}
                onChange={date => {
                  setDate([date[0].toDate(), date[1].toDate()]);
                }}
              />
              <div onClick={e => dateSelector(e.target.id)} className="d-flex mx-2">
                {
              [{
                label: '今天', value: 1
              }, {
                label: '昨天', value: 2
              }, {
                label: '本星期', value: 3
              }, {
                label: '上星期', value: 4
              }, {
                label: '本月', value: 5
              }, {
                label: '上月', value: 6
              }].map(item => <span id={item.value} className={`mx-1 operationBtn dateSelectBtn ${+_dateFlag === item.value && 'selected'}`}>{item.label}</span>)

                 }
              </div>
              <div className="operationBtn findAndCollapse mx-1" onClick={() => onSearchClick()}>查询</div>
            </div>
          </div>
          <div className="d-flex">
            <div>账号</div>
            <div>
              <Input className="accountName" value={loginName} onChange={({ target: { value: text } }) => setloginName(text.trim())} />
            </div>
          </div>
          {level !== 0
          && (
          <div className="d-flex">
            <div>直属账号</div>
            <div>
              <Input className="accountName" value={parentLoginName} onChange={({ target: { value: text } }) => setparentLoginName(text.trim())} />
            </div>
          </div>
          )}
          <div className="d-flex">
            <div>排序</div>
            <div>
              <Select value={orderBy} onChange={value => setorderBy(value)}>
                {
                [{
                  label: '按注单数排序', value: 0
                }, {
                  label: '按下注金额排序', value: 1
                }, {
                  label: '按有效金额排序', value: 2
                }, {
                  label: '按输赢排序', value: 3
                }].map(item => <Option value={item.value}>{item.label}</Option>)
              }
              </Select>
              <Select className="ms-3" value={desc} onChange={value => setdesc(value)}>
                {
                [{
                  label: '倒序', value: 0
                }, {
                  label: '顺序', value: 1
                }].map(item => <Option value={item.value}>{item.label}</Option>)
              }
              </Select>
            </div>
          </div>
        </div>
      </div>
      <div className="CompanyGameReportTableWrapper p-3">
        <div className="mx-0 bg-white">
          <AgentDataTable
            isQuerying={_isQueryingDetail}
            onRowClick={data => onClickRow(data)}
            tableHeaderTitle={
              [{
                label: '序号',
              }].concat(
                level === 0 ? [] : level === 1 ? [{
                  label: '上级代理',
                }] : [{
                  label: '日期',
                }],
                [{
                  label: '帐户名',
                }, {
                  label: '注单数',
                }, {
                  label: '下注金额',
                }, {
                  label: '有效金额',
                }, {
                  label: '会员输赢',
                }]
              )
          }
            tableData={data ? data.betList.map((x, index) => ({
              ...x, index: index + 1 + (page - 1) * row
            })) : []}
            tableBodyRowProperty={
              [
                data => (<Label className="text-center">{data.index}</Label>)
              ].concat(
                level === 0 ? [] : level === 1 ? [
                  data => (<Label className="d-flex justify-content-center">{data.parentLoginName}</Label>),
                ] : [
                  data => (<Label className="d-flex justify-content-center">{data?.date ? moment.unix(data.date).format('YYYY-MM-DD') : ''}</Label>),
                ],
                [
                  data => (<Label className="d-flex justify-content-center">{data.agentLoginName || data.loginName}</Label>),
                  data => (<Label className="d-flex justify-content-center">{data ? generateDataDetail(data.count, data.countList) : ''}</Label>),
                  data => (<Label className="d-flex justify-content-center">{data ? generateDataDetail(data.amount, data.amountList) : ''}</Label>),
                  data => (<Label className="d-flex justify-content-center">{data ? generateDataDetail(data.validAmount, data.validAmountList) : ''}</Label>),
                  data => (<Label className="d-flex justify-content-center">{data ? generateDataDetail(data.winLost, data.winLostList) : ''}</Label>),
                ]
              )
          }
            tableFooter={[
              [{
                value: '本页总和',
                colspan: level === 0 ? 2 : 3,
                className: 'textRight pr-1 total tableSum'
              },
              {
                value: data ? generateDataDetail(data.subTotal.count, data.subTotal.countList) : 0,
                colspan: 1,
                className: 'tableSum'
              },
              {
                value: data ? generateDataDetail(data.subTotal.amount, data.subTotal.amountList) : 0,
                colspan: 1,
                className: 'tableSum'
              },
              {
                value: data ? generateDataDetail(data.subTotal.validAmount, data.subTotal.validAmountList) : 0,
                colspan: 1,
                className: 'tableSum'
              },
              {
                value: data ? generateDataDetail(data.subTotal.winLost, data.subTotal.winLostList) : 0,
                colspan: 1,
                className: 'tableSum'
              }],
              [{
                value: '所有总和',
                colspan: level === 0 ? 2 : 3,
                className: 'textRight pr-1 total tableSum'
              },
              {
                value: data ? generateDataDetail(data.total.count, data.total.countList) : 0,
                colspan: 1,
                className: 'tableSum'
              },
              {
                value: data ? generateDataDetail(data.total.amount, data.total.amountList) : 0,
                colspan: 1,
                className: 'tableSum'
              },
              {
                value: data ? generateDataDetail(data.total.validAmount, data.total.validAmountList) : 0,
                colspan: 1,
                className: 'tableSum'
              },
              {
                value: data ? generateDataDetail(data.total.winLost, data.total.winLostList) : 0,
                colspan: 1,
                className: 'tableSum'
              }]
            ]}
          />
        </div>
      </div>
      <div className="justify-content-center align-items-center w-full relative d-flex">
        <Pagination totalRecord={data ? data.totalCount : 0} onClick={onPaginationClick} isQuery={_isQueryingDetail} />
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyGameReport);