/* eslint-disable react/jsx-wrap-multilines */
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import AbnormalBetQuery from '../../components/BetWarning/AbnormalBetQuery';
import BetWarningMonitor from '../../components/BetWarning/BetWarningMonitor';
import BigBetLimit from '../../components/BetWarning/BigBetLimit';
import BigBuhuoLimit from '../../components/BetWarning/BigBuhuoLimit';
import BogusBetWarning from '../../components/BetWarning/BogusBetWarning';
import FollowBet from '../../components/BetWarning/FollowBet';
import QuickBetMonitor from '../../components/BetWarning/QuickBetMonitor';
import SetReminderAmount from '../../components/BetWarning/SetReminderAmount';

import { CommonHeader } from '../../components';
import './styles.scss';

const mapStateToProps = state => ({
  selectedSubMenu: state.commonReducers.selectedSubMenu,
  userInfo: state.memberReducers.userInfo,
});

const BetWarning = ({ selectedSubMenu }) => {
  const { t } = useTranslation();
  return (
    <div className="BetWarningWrapper p-3">
      {selectedSubMenu === 'BW_BOGUS_BET_WARNING' && (
      <>
        <CommonHeader
          headerName={selectedSubMenu ? t(selectedSubMenu.slice(3)) : ''}
        />
        <BogusBetWarning />
      </>
      )}
      {selectedSubMenu === 'BW_ABNORMAL_BET_QUERY' && (
      <AbnormalBetQuery />
      )}
      {selectedSubMenu === 'BW_BOGUS_BET_WARNING_MONITOR' && (
      <BetWarningMonitor />
      // <CommonHeader
      //   headerName={selectedSubMenu ? t(selectedSubMenu.slice(3)) : ''}
      // />
      // 注单预警监控
      )}
      {selectedSubMenu === 'BW_SET_SUGGESTION_AMOUNT' && (
      <SetReminderAmount />
      // 设置提示金额
      )}
      {selectedSubMenu === 'BW_BIG_BET_LIMIT' && (
      <BigBetLimit />
      // 大额限注设置
      )}
      {selectedSubMenu === 'BW_QUICK_BET_MONITOR' && (
      // <CommonHeader
      //   headerName={selectedSubMenu ? t(selectedSubMenu.slice(3)) : ''}
      // />
      <QuickBetMonitor />
      // 快捷注单监控
      )}
      {selectedSubMenu === 'BW_BIG_BET_BUHUO_SETTING' && (
      <BigBuhuoLimit />
      // 大额补货设置
      )}
      {selectedSubMenu === 'BW_FOLLOW_BET_SETTING' && (
      <FollowBet />
      // 跟单设置
      )}
    </div>
  );
};

export default connect(mapStateToProps, null)(BetWarning);