import { StatusCodes } from 'http-status-codes';

import { SET_ADD_MEMBER_INFO, SET_CAPTCHA_DATA, SET_EDIT_MEMBER_DETAIL, SET_LOAD_LOGIN_LOGS, SET_MEMBER_LIST, SET_MFA_SETTING, SET_USER_DATA, SET_USER_INFO, USER_LOGOUT } from '../actionTypes';
import { apiService } from '../../../library';
import appActions from '../appActions';
// eslint-disable-next-line import/no-cycle
import commonActions from '../commonActions';

const setUserLogout = () => ({
  type: USER_LOGOUT,
});

const setUserData = appData => ({
  type: SET_USER_DATA,
  value: appData,
});

const setUserInfo = appData => ({
  type: SET_USER_INFO,
  value: appData,
});

const setCaptchaData = captchaData => ({
  type: SET_CAPTCHA_DATA,
  value: captchaData,
});

const setLoadLoginLogs = data => ({
  type: SET_LOAD_LOGIN_LOGS,
  value: data,
});

const setMFASetting = data => ({
  type: SET_MFA_SETTING,
  value: data,
});

const setMemberList = data => ({
  type: SET_MEMBER_LIST,
  value: data,
});

const setAddMemberInfo = data => ({
  type: SET_ADD_MEMBER_INFO,
  value: data,
});

const setMemberDetail = data => ({
  type: SET_EDIT_MEMBER_DETAIL,
  value: data,
});


const login = (loginData, callback = () => {}) => dispatch => {
  dispatch(appActions.updateModalAlert({
    visible: true,
    type: 'loading'
  }));
  apiService.post('/member/login', loginData).then(res => {
    dispatch(appActions.updateModalAlert({
      visible: false,
    }));
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        if (data?.result?.checkPassword === true) {
          dispatch(setUserData(data.result));
          return callback(data.result);
        }
        dispatch(commonActions.getAvailableLottery());
        if (data.result.levelValue !== 0) {
          dispatch(getMyInfo());
        }
        return dispatch(setUserData(data.result));
      }
    }
  }).catch(error => {
    Promise.reject(error);
    dispatch(getCaptcha());
    if (error.response.status === 400 && error.response.data.state === 1100) {
      return dispatch(setMFASetting(true));
    }
    if (error.response.status === 400 && error.response.data.state === 2017) {
      const account = error.response.data.msg?.replace('请不要重复登陆。你已经用帐号', '')?.replace('登陆。如需登陆其它帐号，请登出后重试!', '');
      return dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg,
          showCancelButton: false,
          overBet: 1,
          confirmText: '登出',
          onConfirmationClick: () => dispatch(logout(account))
        }
      }));
    }
    return dispatch(appActions.updateModalAlert({
      visible: true,
      type: 'confirmation',
      data: {
        message: error.response.data.msg,
        showCancelButton: false,
        overBet: 1,
        onConfirmationClick: () => dispatch(getCaptcha())
      }
    }));
  });
};

const getMyInfo = () => dispatch => {
  apiService.get('/member/GetMyInfo').then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        return dispatch(setUserInfo(data.result));
      }
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const GenSignKey = (userId, callback = () => {}) => dispatch => {
  apiService.get('/member/GenSignKey', new URLSearchParams({
    userId
  })).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        return callback(data.result);
      }
    }
  }).catch(error => {
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg,
          showCancelButton: false
        }
      }));
    }
    Promise.reject(error);
  });
};

const logout = (loginid, callback = () => {}) => dispatch => {
  apiService.post(`/member/logout/${loginid}`, {
    refreshToken: ''
  }).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        dispatch(setUserLogout());
        return callback();
      }
    }
  }).catch(error => {
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg,
          showCancelButton: false
        }
      }));
    }
    Promise.reject(error);
  });
};

const getCaptcha = () => dispatch => {
  apiService.get('/member/Captcha/').then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      dispatch(setCaptchaData(data.result));
    }
  }).catch(error => {
    Promise.reject(error);
  });
};


const getLoadLoginLogs = (obj, calllback = () => {}) => dispatch => {
  apiService.get('/member/LoadLoginLogs', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      calllback(false);
      dispatch(setLoadLoginLogs(data.result));
    }
  }).catch(error => {
    Promise.reject(error);
  });
};


const ChangePassword = (obj, callback = () => {}, showModal = true, existingData) => dispatch => {
  apiService.post('/member/ChangePassword', obj).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        if (showModal) {
          callback();
          return dispatch(appActions.updateModalAlert({
            visible: true,
            type: 'success',
            data: {
              message: data.msg
            }
          }));
        }
        if (existingData?.levelValue !== 0) {
          dispatch(getMyInfo());
        }
        return dispatch(commonActions.getAvailableLottery(callback));
      }
    }
  }).catch(error => {
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg,
          showCancelButton: false
        }
      }));
    }
    Promise.reject(error);
  });
};

const GetMFASetting = () => dispatch => {
  apiService.get('/Member/GetMFASetting/').then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      dispatch(setMFASetting(data.result));
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const UpdateMFASetting = (validType, callback = () => {}) => dispatch => {
  apiService.post(`/Member/UpdateMFASetting/${validType}`).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      callback();
      dispatch(GetMFASetting());
      return dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg
        }
      }));
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const RefreshMFA = () => dispatch => {
  apiService.post('/Member/RefreshMFA/').then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      dispatch(GetMFASetting());
      return dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg
        }
      }));
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const GenMyInvitationCode = () => dispatch => {
  apiService.post('/Member/GenMyInvitationCode/').then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      dispatch(getMyInfo());
      return dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg
        }
      }));
    }
  }).catch(error => {
    Promise.reject(error);
  });
};
const GenAgentInvitationCode = () => dispatch => {
  apiService.post('/Member/GenAgentInvitationCode/').then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      dispatch(getMyInfo());
      return dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg
        }
      }));
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const GetAddMemberInfo = (obj, calllback = () => {}) => dispatch => {
  apiService.get('/member/GetAddMemberInfo', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      calllback(false);
      dispatch(setAddMemberInfo(data.result));
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const getMemberList2 = (obj, callback = () => {}, catchback = () => { }) => () => {
  apiService.get('member/MemberList', new URLSearchParams(obj))
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          return callback(data.result);
        }
      }
    }).catch(error => {
      catchback();
      Promise.reject(error);
    });
};

const GetMemberList = (agentId, levelValue, orderby, order, page, rows, startDate, endDate, isChild = '', loginId = '', status = '', searchBy = '', minAmount = '', maxAmount = '', isTest = '0') => dispatch => {
  apiService.get(
    'member/MemberList',
    `agentId=${agentId}&level=${levelValue}&orderby=${orderby}&order=${order}&startDate=${startDate}&endDate=${endDate}&loginId=${loginId}&page=${page}&rows=${rows}&isChild=${isChild}&status=${status}&searchBy=${searchBy}&minAmount=${minAmount}&maxAmount=${maxAmount}&isTest=${isTest}`
  )
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          return dispatch(setMemberList(data.result || {}));
        }
      }
    }).catch(error => {
      Promise.reject(error);
    });
};

const SetBetOnlineNotification2 = (obj = {}, callback = () => {}) => dispatch => {
  apiService.post('member/SetBetOnlineNotification', {
    ...obj
  })
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          dispatch(appActions.updateModalAlert({
            visible: false
          }));
          callback();
          // dispatch(appActions.updateModalAlert({
          //   visible: true,
          //   type: 'success',
          //   data: {
          //     message: data.msg,
          //     onConfirmationClick: () => callback(true)
          //   }
          // }));
        }
      }
    }).catch(error => {
      if (error.response.data.state) {
        dispatch(appActions.updateModalAlert({
          visible: false
        }));
        dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'confirmation',
          data: {
            message: error.response.data.msg
          }
        }));
      }
      Promise.reject(error);
    });
};
const SetBetOnlineNotification = (obj = {}, callback = () => {}) => dispatch => {
  apiService.post('/member/SaveMemberInfo', {
    ...obj
  })
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          dispatch(appActions.updateModalAlert({
            visible: false
          }));
          callback();
          // dispatch(appActions.updateModalAlert({
          //   visible: true,
          //   type: 'success',
          //   data: {
          //     message: data.msg,
          //     onConfirmationClick: () => callback(true)
          //   }
          // }));
        }
      }
    }).catch(error => {
      if (error.response.data.state) {
        dispatch(appActions.updateModalAlert({
          visible: false
        }));
        dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'confirmation',
          data: {
            message: error.response.data.msg
          }
        }));
      }
      Promise.reject(error);
    });
};

const SaveMember = (obj = {}, callback = () => {}) => dispatch => {
  apiService.post('/member/SaveMemberInfo', {
    ...obj
  })
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          dispatch(commonActions.GetOrganizationUserCount());
          dispatch(appActions.updateModalAlert({
            visible: false
          }));
          dispatch(appActions.updateModalAlert({
            visible: true,
            type: 'success',
            data: {
              message: data.msg,
              onConfirmationClick: () => callback(true)
            }
          }));
        }
      }
    }).catch(error => {
      if (error.response.data.state) {
        dispatch(appActions.updateModalAlert({
          visible: false
        }));
        dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'confirmation',
          data: {
            message: error.response.data.msg
          }
        }));
      }
      Promise.reject(error);
    });
};

const GetMemberDetail = obj => dispatch => {
  apiService.get(`/member/GetMemberDetail/${obj}`).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        return dispatch(setMemberDetail(data.result || {}));
      }
    }
  }).catch(error => {
    if (error.response.data.state) {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};

const Register = (obj, callback = () => {}) => dispatch => {
  dispatch(appActions.updateModalAlert({
    visible: true,
    type: 'loading'
  }));
  apiService.post('/member/Register', obj).then(res => {
    dispatch(getCaptcha());
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        return dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'success',
          data: {
            message: data.msg,
            onConfirmationClick: () => callback(),
            confirmText: '立即登陆'
          }
        }));
      }
    }
  }).catch(error => {
    dispatch(getCaptcha());
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg,
          showCancelButton: false
        }
      }));
    }
    Promise.reject(error);
  });
};

export default {
  login,
  logout,
  getCaptcha,
  getMyInfo,
  getLoadLoginLogs,
  ChangePassword,
  GetMFASetting,
  RefreshMFA,
  UpdateMFASetting,
  GenMyInvitationCode,
  GenAgentInvitationCode,
  GetMemberList,
  GetAddMemberInfo,
  SaveMember,
  GetMemberDetail,
  setUserData,
  Register,
  getMemberList2,
  SetBetOnlineNotification,
  SetBetOnlineNotification2,
  setMFASetting,
  setUserLogout,
  GenSignKey
};