import { useEffect, useState } from 'react';

import { Checkbox, Input } from 'antd';
import { Label } from 'reactstrap';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { AgentDataTable, Pagination } from '../..';
import { systemActions } from '../../../redux/actions';

import './styles.scss';

const mapStateToProps = state => ({
  roleInfo: state.systemReducers.roleInfo,
  parentMenuList: state.commonReducers.parentMenuList,
  activeMenu: state.commonReducers.activeMenu,
  menuInfo: state.systemReducers.menuInfo,
  editRoleInfo: state.systemReducers.editRoleInfo,
  company: state.whiteListReducers.company,
  subAccountList: state.whiteListReducers.subAccountList,
});
const mapDispatchToProps = dispatch => ({
  GetMenuAvailableControllers: (obj, callback) => dispatch(systemActions.GetMenuAvailableControllers(obj, callback)),
  GetMenuControllers: (obj, callback) => dispatch(systemActions.GetMenuControllers(obj, callback)),
  DeleteMenuController: (obj, callback) => dispatch(systemActions.DeleteMenuController(obj, callback)),
  UpdateMenuController: (obj, callback) => dispatch(systemActions.UpdateMenuController(obj, callback)),
});

const AccessConfigure = ({ GetMenuAvailableControllers, data, GetMenuControllers, DeleteMenuController, UpdateMenuController }) => {
  const { t } = useTranslation();
  const [_isQueryingLeft, setIsQueryingLeft] = useState(false);
  const [_isQueryingRight, setIsQueryingRight] = useState(false);
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(15);
  const [settingData, setData] = useState();
  const [controller, setcontroller] = useState('');
  const [dataListRight, setDataListRight] = useState();
  const [dataListLeft, setDataListLeft] = useState();

  const [leftpage, setLeftPage] = useState(1);
  const [leftrows, setLeftRows] = useState(15);

  const [selectedItemRight, setSelectedItemRight] = useState([]);

  const [settingLeftData, setLeftData] = useState();

  const getDataRight = data => {
    setIsQueryingRight(true);
    GetMenuAvailableControllers(data, param => {
      setIsQueryingRight(false);
      setDataListRight(param?.list ? param?.list.map((x, index) => ({
        ...x, index
      })) : []);
      setData(param);
    });
  };

  const getDataLeft = data => {
    setIsQueryingLeft(true);
    GetMenuControllers(data, param => {
      setIsQueryingLeft(false);
      setDataListLeft(param?.list ? param?.list.map((x, index) => ({
        ...x, index
      })) : []);
      setLeftData(param);
    });
  };

  useEffect(() => {
    if (data) {
      const _objRight = {
        page,
        rows,
        controller,
        menuId: data.id,
      };
      const _objLeft = {
        page: leftpage,
        rows: leftrows,
        controller,
        menuId: data.id,
      };
      getDataLeft(_objLeft);
      getDataRight(_objRight);
    }
  }, [data]);

  const onSearchClick = () => {
    const _obj = {
      page: 1,
      rows: 15,
      controller
    };
    getDataRight(_obj);
    setPage(1);
    setRows(15);
  };

  const onPaginationClick = (pageNum, rows) => {
    setPage(pageNum);
    setRows(rows);
    const _obj = {
      page: pageNum,
      rows,
      controller
    };
    getDataRight(_obj);
  };

  const onLeftPaginationClick = (pageNum, rows) => {
    setLeftPage(pageNum);
    setLeftRows(rows);
    const _obj = {
      page: pageNum,
      rows,
      controller,
      menuId: data.id,
    };
    getDataLeft(_obj);
  };

  const deleteRecord = id => {
    const obj = {
      MenuId: data.id,
      Controllers: [id]
    };
    DeleteMenuController(obj, () => {
      setLeftPage(1);
      setLeftRows(15);
      const _objLeft = {
        page: 1,
        rows: 15,
        controller,
        menuId: data.id,
      };
      getDataLeft(_objLeft);
    });
  };

  const onClickSave = () => {
    const obj = {
      MenuId: data.id,
      Controllers: selectedItemRight
    };
    UpdateMenuController(obj, () => {
      const _objLeft = {
        page: 1,
        rows: 15,
        controller,
        menuId: data.id,
      };
      getDataLeft(_objLeft);
    });
  };

  return (
    <div className="AccessConfigureWrapper">
      <div className="mx-0 bg-white px-0 border-1 accessSettingContent">
        <div className="me-2">
          <div className="AccessConfigureMenuManagementHeader">
            <div>已选页面</div>
          </div>
          <AgentDataTable
            isQuerying={_isQueryingLeft}
            tableHeaderTitle={
                [{
                  label: 'Controller',
                }, {
                  label: 'ActionName',
                }, {
                  label: 'Url路径',
                }, {
                  label: 'Actiontype',
                }, {
                  label: '状态',
                }, {
                  label: t('OPERATION'),
                }]
              }
            tableHeaderStyle={
                []
              }
            tableData={dataListLeft || []}
            tableBodyRowProperty={
                [
                  data => (
                    <Label className="d-flex justify-content-center">
                      {data?.controller}
                    </Label>
                  ),
                  data => (
                    <Label className="d-flex justify-content-center">
                      {data?.actionName}
                    </Label>
                  ),
                  data => (
                    <Label className="d-flex justify-content-center">
                      {data?.url}
                    </Label>
                  ),
                  data => (
                    <Label className="d-flex justify-content-center">
                      {data?.actionType}
                    </Label>
                  ),
                  data => (<Label className={`d-flex justify-content-center text-nowrap ${data.status === 1 ? 'text-success' : 'text-danger'}`}>{data.status === 1 ? '启用' : '停用'}</Label>),
                  data => (
                    <Label className="d-flex justify-content-center align-items-center">
                      <div
                        className="operationBtn mx-2"
                        onClick={() => {
                          deleteRecord(data.id);
                        }}
                      >
                        删除
                      </div>
                    </Label>
                  )
                ]
              }
          />
          <div className="d-flex align-items-center justify-content-center w-full relative">
            <Pagination totalRecord={settingLeftData?.totalRecords || 0} onClick={onLeftPaginationClick} isQuery={false} />
          </div>
        </div>
        <div>
          <div className="AccessConfigureMenuManagementHeader">
            <div>可选页面</div>
            <div className="mx-1">Controller</div>
            <Input className="mx-1 menuNameInput" value={controller} onChange={({ target: { value: text } }) => setcontroller(text.trim())} />
            <div className="operationBtn mx-1 add" onClick={() => onSearchClick()}>查询</div>
            <div
              className="operationBtn mx-1 add"
              onClick={() => {
                onClickSave();
              }}
            >
              保存
            </div>
          </div>
          <AgentDataTable
            isQuerying={_isQueryingRight}
            tableHeaderTitle={
                [{
                  label: (
                    <Checkbox
                      checked={(selectedItemRight?.length === dataListRight?.length) && selectedItemRight?.length > 0 && dataListRight?.length > 0}
                      className="text-nowrap"
                      onChange={() => {
                        if (selectedItemRight?.length === dataListRight?.length) {
                          setSelectedItemRight([]);
                        } else {
                          setSelectedItemRight(dataListRight.map(x => x.id));
                        }
                      }}
                    >
                      选择所有
                    </Checkbox>),
                },
                {
                  label: 'Controller',
                }, {
                  label: 'ActionName',
                }, {
                  label: 'Url路径',
                }, {
                  label: 'Actiontype',
                }, {
                  label: '状态',
                }]
              }
            tableHeaderStyle={
                []
              }
            tableData={dataListRight || []}
            tableBodyRowProperty={
                [
                  data => (
                    <Label className="d-flex justify-content-center align-items-center">
                      <Checkbox
                        checked={selectedItemRight.includes(data.id)}
                        onChange={() => {
                          if (selectedItemRight.includes(data.id)) {
                            setSelectedItemRight(selectedItemRight.filter(x => x !== data.id));
                          } else {
                            setSelectedItemRight([data.id].concat(selectedItemRight));
                          }
                        }}
                      />
                    </Label>
                  ),
                  data => (
                    <Label className="d-flex justify-content-center">
                      {data?.controller}
                    </Label>
                  ),
                  data => (
                    <Label className="d-flex justify-content-center">
                      {data?.actionName}
                    </Label>
                  ),
                  data => (
                    <Label className="d-flex justify-content-center">
                      {data?.url}
                    </Label>
                  ),
                  data => (
                    <Label className="d-flex justify-content-center">
                      {data?.actionType}
                    </Label>
                  ),
                  data => (<Label className={`d-flex justify-content-center text-nowrap ${data.status === 1 ? 'text-success' : 'text-danger'}`}>{data.status === 1 ? '启用' : '停用'}</Label>),
                ]
              }
          />
          <div className="d-flex align-items-center justify-content-center w-full relative">
            <Pagination totalRecord={settingData?.totalRecords || 0} onClick={onPaginationClick} isQuery={false} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AccessConfigure);