import { Col, Label, Row } from 'reactstrap';
import { useEffect, useRef, useState } from 'react';

import { Button, Input, TimePicker } from 'antd';
import { connect } from 'react-redux';

import { useTranslation } from 'react-i18next';

import moment from 'moment';

import { appActions, bigBetLimitActions } from '../../../redux/actions';

import AgentDataTable from '../../AgentDataTable';

import { CommonHeader } from '../..';


import './styles.scss';

const mapStateToProps = state => ({
  activeLottery: state.commonReducers.activeLottery,
  bigBetLimitQueryData: state.bigBetLimitReducers.bigBetLimitQueryData,
  lotteryList: state.commonReducers.lotteryList,
});

const mapDispatchToProps = dispatch => ({
  SaveBigBetLimitSetting: (obj, callback) => dispatch(bigBetLimitActions.SaveBigBetLimitSetting(obj, callback)),
  QueryBigBetLimit: (obj, callback, catchCallback) => dispatch(bigBetLimitActions.QueryBigBetLimit(obj, callback, catchCallback)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
});

const BigBetLimit = ({ SaveBigBetLimitSetting, lotteryList, bigBetLimitQueryData, QueryBigBetLimit, showHeader = true }) => {
  const { t } = useTranslation(); // i18n
  const [_isQueryingDetail, setIsQueryingDetail] = useState([]); // loading
  const [_detailItem, setDetailItem] = useState([]); // table data
  const [_lottery, setLottery] = useState(lotteryList[0]);
  const [_lotteryList, setLotteryList] = useState([]); // 比lotterylist 多了sum信息
  const filterRef = useRef();
  const [timeList, setTimeList] = useState([]);
  const [activeTimeIndex, setActiveTimeIndex] = useState(0);
  const [newTime, setNewTime] = useState('');

  useEffect(() => {
    setDetailItem([]);
    onQuery({
      lotteryType: _lottery.code,
      time: '', // 这个时候 timeList 还没有返回, 这个api 返回数据无效
    });
    setActiveTimeIndex(0);
  }, [_lottery]);

  useEffect(() => {
    onQuery({
      lotteryType: _lottery.code,
      time: timeList[0]
    });
  }, [timeList]);

  useEffect(() => {
    setNewTime(timeList[activeTimeIndex]);
  }, [activeTimeIndex, timeList]);

  useEffect(() => {
    const newData = lotteryList.filter(item => item.templateCode === 'HK6');
    if (!_lottery && newData[0]) {
      setLottery(newData[0]);
    }
    setLotteryList(newData);
  }, [lotteryList]);

  const tableHeaderTitle = [
    {
      label: t('TYPE'),
    },
    {
      label: t('SINGLE_BET_LIMIT'),
    },
    {
      label: t('SINGLE_BET_ACCOUNTED_FOR_LIMIT'),
    },
  ];

  const _content = [
    item => (
      <Label>
        {
          item.projname
        }
      </Label>
    ),
    item => (
      <Input
        type="number"
        className={`mx-2 px-1 align-items-center ${item.isChange_singleamount && 'isChange'}`}
        value={item.singleamount}
        onChange={e => {
          const selectedIndex = _detailItem.findIndex(i => i.settingcode === item.settingcode && i.projname === item.projname);
          if (selectedIndex !== -1) {
            setDetailItem(
              [
                ..._detailItem.slice(0, selectedIndex),
                {
                  ..._detailItem[selectedIndex],
                  singleamount: parseInt(e.target.value, 10),
                  edited: true,
                  isChange_singleamount: true,
                },
                ..._detailItem.slice(selectedIndex + 1),
              ]
            );
          }
        }}
      />
    ),
    item => (
      <Input
        type="number"
        className={`mx-2 px-1 align-items-center ${item.isChange_singlerealamount && 'isChange'}`}
        value={item.singlerealamount}
        onChange={e => {
          const selectedIndex = _detailItem.findIndex(i => i.settingcode === item.settingcode && i.projname === item.projname);
          if (selectedIndex !== -1) {
            setDetailItem(
              [
                ..._detailItem.slice(0, selectedIndex),
                {
                  ..._detailItem[selectedIndex],
                  singlerealamount: parseInt(e.target.value, 10),
                  edited: true,
                  isChange_singlerealamount: true,
                },
                ..._detailItem.slice(selectedIndex + 1),
              ]
            );
          }
        }}
      />
    ),
  ];

  useEffect(() => {
    if (bigBetLimitQueryData) {
      setDetailItem(bigBetLimitQueryData.list || []);
      const newTimeList = bigBetLimitQueryData.timeList || [];
      if (newTimeList.join('') !== timeList.join('')) {
        setTimeList(bigBetLimitQueryData.timeList || []);
      }
    }
    setIsQueryingDetail(false);
  }, [bigBetLimitQueryData]);

  const onQuery = (params = {}) => {
    const newParams = {
      lotteryType: _lottery.code,
      time: newTime,
      ...params,
    };
    if (newParams.time && newParams.lotteryType) {
      setIsQueryingDetail(true);
    }
    if (_lottery && _lottery.code) {
      QueryBigBetLimit(
        newParams,
        () => {
          setIsQueryingDetail(false);
        },
        () => {
          setIsQueryingDetail(false);
        }
      );
    }
  };

  const transferTime = timeStr => {
    if (!timeStr) return;
    const res = timeStr.split(':');
    const hour = res[0];
    const minute = res[1];
    const time = new Date();
    time.setHours(hour);
    time.setMinutes(minute);
    return new Date(time);
  };

  const handleSave = () => {
    SaveBigBetLimitSetting(
      {
        lotteryType: _lottery.code,
        limitTime: timeList[activeTimeIndex] ? timeList[activeTimeIndex] : newTime,
        newTime: newTime !== timeList[activeTimeIndex] ? newTime : '',
        betLimits: _detailItem.filter(i => i.edited).map(item => {
          const { id, settingcode, singleamount, singlerealamount } = item;
          return {
            id,
            settingcode,
            singleamount,
            singlerealamount
          };
        })
      },
      onQuery
    );
  };

  return (
    <div className="BigBetLimitWrapper">
      <div ref={filterRef}>
        {
          showHeader && (
            <CommonHeader headerName={t('BIG_BET_LIMIT')} />
          )
        }
      </div>

      <Row className="mx-0 px-0 border-1">
        <div className="lotteryList">
          {
            _lotteryList.map(item => {
              const itemCode = item.code;
              return (
                <div
                  onClick={() => setLottery(item)}
                  className={`lotteryItem ${(itemCode === _lottery.code && 'active') || ''}`}
                >
                  <div>
                    {
                      item.lotteryName
                    }
                  </div>
                </div>
              );
            })
          }
        </div>
      </Row>

      <Row className=" mt-2 mx-0 bg-white px-0 border-1">
        <div className="tabList">
          {
            [0, 1, 2].map((item, index) => {
              const time = timeList[item];
              return (
                <div
                  onClick={() => {
                    setActiveTimeIndex(item);
                    onQuery({
                      lotteryType: _lottery.code,
                      time: timeList[item]
                    });
                  }}
                  className={`tabItem ${item === activeTimeIndex && 'active'}`}
                >
                  {t('BET_LIMIT_TIME')}
                  {
                    index + 1
                  }
                  ：
                  {
                    time || t('NOT_SET')
                  }
                </div>
              );
            })
          }
        </div>
      </Row>

      <Row className=" mt-2 mx-0 bg-white px-0 border-1">
        <div className="tableTimeSet">
          <div className="d-flex p-0 align-items-center ">
            <div className="text">
              {t('BIG_BET_LIMIT_TIME')}
            </div>
            <TimePicker
              value={newTime && moment(transferTime(newTime))}
              onChange={date => {
                const str = date.format('HH:mm');
                setNewTime(str);
              }}
              format="HH:mm"
              className="marginLeft1"
            />
          </div>
          <Button
            onClick={() => handleSave()}
            className="button save mx-2"
          >
            {t('EDIT')}
          </Button>
          <Button
            onClick={() => {
              setDetailItem(bigBetLimitQueryData.list || []);
              setNewTime(timeList[activeTimeIndex]);
            }}
            className="button cancel mx-2"
          >
            {t('RESET')}
          </Button>

        </div>
      </Row>

      <Row className=" mt-2 mx-0 bg-white px-0 border-1">
        <Col className="p-0">
          <AgentDataTable
            tableBodyRowStyle={
              ['backgroundColored']
            }
            isQuerying={_isQueryingDetail}
            tableHeaderTitle={tableHeaderTitle}
            tableData={_detailItem.slice(Math.floor(_detailItem.length / 2))}
            tableBodyRowProperty={_content}
          />
        </Col>
        <Col className="p-0 marginLeft1">
          <AgentDataTable
            tableBodyRowStyle={
              ['backgroundColored']
            }
            isQuerying={_isQueryingDetail}
            tableHeaderTitle={tableHeaderTitle}
            tableData={_detailItem.slice(0, Math.floor(_detailItem.length / 2))}
            tableBodyRowProperty={_content}
          />
        </Col>

      </Row>

      <Row className=" mt-2 mx-0 bg-white px-0 border-1">
        <div className="footerAction">
          <Button
            onClick={() => handleSave()}
            className="button save mx-2"
          >
            {t('EDIT')}
          </Button>
          <Button
            onClick={() => {
              setDetailItem(bigBetLimitQueryData.list || []);
              setNewTime(timeList[activeTimeIndex]);
            }}
            className="button cancel mx-2"
          >
            {t('RESET')}
          </Button>
        </div>
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BigBetLimit);