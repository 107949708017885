import { useEffect, useState } from 'react';

import { connect } from 'react-redux';

import { commonActions } from '../../redux/actions';
import './styles.scss';

const mapStateToProps = state => ({
  inTimeSheetOdds: state.inTimeSheetReducers.inTimeSheetOdds,
  selectedSubSelection: state.commonReducers.selectedSubSelection,
  activeLottery: state.commonReducers.activeLottery,
});
const mapDispatchToProps = dispatch => ({
  updateSelectedSubSelection: val => dispatch(commonActions.updateSelectedSubSelection(val)),
});

const SubSelection = ({ list, updateSelectedSubSelection, selectedSubSelection, inTimeSheetOdds, activeLottery }) => {
  const [_MenuOddsListObj, setMenuOddsListObj] = useState();

  useEffect(() => {
    if (!selectedSubSelection) {
      updateSelectedSubSelection(list[0]);
    }
    return () => updateSelectedSubSelection();
  }, []);

  useEffect(() => {
    if (inTimeSheetOdds) {
      const obj = {};
      inTimeSheetOdds.subMenuSum.forEach(element => {
        obj[element.menuId] = element;
      });
      setMenuOddsListObj(obj);
    } else {
      setMenuOddsListObj();
    }
  }, [inTimeSheetOdds]);

  return (
    <div className="subSelectionWrapper">
      {
          activeLottery && list.map(item => (
            <div className="menuItem" onClick={() => updateSelectedSubSelection(item)} key={`subMenuItem_${item.menuId}`}>
              <div className={`menuItemTitle ${item.menuId === selectedSubSelection?.menuId && 'selected'}`}>{item.menuName}</div>
              <div className="menuAmount">{_MenuOddsListObj ? _MenuOddsListObj[item.menuId]?.amt : '--'}</div>
            </div>
          ))
        }
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SubSelection);