import { useEffect, useState } from 'react';

import { Label } from 'reactstrap';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { AgentDataTable, CommonHeader } from '../..';
import { decreaseOddsActions } from '../../../redux/actions';

import './styles.scss';

const mapDispatchToProps = dispatch => ({
  GetExternalInfo: calllback => dispatch(decreaseOddsActions.GetExternalInfo(calllback)),
  SaveExternalOddsdownSetting: (obj, calllback) => dispatch(decreaseOddsActions.SaveExternalOddsdownSetting(obj, calllback)),
});

const ExternalDecreaseSetting = ({ GetExternalInfo, SaveExternalOddsdownSetting }) => {
  const { t } = useTranslation();
  const [_isQuerying, setIsQuerying] = useState(false);
  const [data, setData] = useState();

  const getData = () => {
    setIsQuerying(true);
    GetExternalInfo(data => { setData(data); setIsQuerying(false); });
  };

  useEffect(() => {
    getData();
  }, []);

  const onClickSave = (lotteryType, sync) => {
    SaveExternalOddsdownSetting({
      lotteryType, sync
    }, getData);
  };

  return (
    <div className="ExternalDecreaseSettingWrapper">
      <CommonHeader headerName={t('EXTERNAL_DECREASE_SETTING')} />
      <div className="mx-0 bg-white px-0 border-1">
        <AgentDataTable
          isQuerying={_isQuerying}
          tableHeaderTitle={
            [{
              label: '彩种Code',
            }, {
              label: '名称',
            }, {
              label: '状态',
            }, {
              label: t('OPERATION'),
            }]
          }
          tableHeaderStyle={
            []
          }
          tableData={data || []}
          tableBodyRowProperty={
            [
              data => (<Label className="d-flex justify-content-center">{data.code}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.lotteryName}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.extOddsSync === 1 ? '同步' : '不同步'}</Label>),
              data => (
                <Label className="d-flex justify-content-center">
                  <span onClick={() => onClickSave(data.code, data.extOddsSync === 1 ? 0 : 1)} className="operationBtn">{data.extOddsSync === 1 ? '不启动' : '启动'}</span>
                </Label>
              )
            ]
          }
        />
      </div>
    </div>
  );
};

export default connect(null, mapDispatchToProps)(ExternalDecreaseSetting);