import { useEffect, useState } from 'react';

import { Col, Label, Row } from 'reactstrap';
import { DatePicker, Input, Select } from 'antd';
import { connect } from 'react-redux';

import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { AgentDataTable, CommonHeader, Pagination } from '../..';
import TransactionMenu from '../TransactionMenu';
import { transactionActions } from '../../../redux/actions';

import './styles.scss';

const mapStateToProps = state => ({
  transactionManage: state.transactionReducers.transactionManage,
});

const mapDispatchToProps = dispatch => ({
  GetTransactionList: (obj, callback) => dispatch(transactionActions.GetTransactionList(obj, callback)),
});

const { RangePicker } = DatePicker;

const GameTransferAudit = ({ transactionManage, GetTransactionList }) => {
  const { t } = useTranslation();
  const [_isQueryingDetail, setIsQueryingDetail] = useState(false);
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(15);
  const [_date, setDate] = useState([moment().subtract(1, 'weeks').startOf('day').toDate(), moment().endOf('day').toDate()]);

  const [userName, setloginName] = useState('');
  const [dateType, setDateType] = useState(2);

  const [mType, setMemType] = useState('');
  const [orderID, setorderID] = useState('');
  const [minAcAmount, setMinAmount] = useState('');
  const [maxAcAmount, setMaxAmount] = useState('');
  const [orderType, setOrderType] = useState(2);
  const [actionType, setActionType] = useState(899);
  const [actionStatus, setActionStatus] = useState('');
  const [confirmStatus, setConfirmStatus] = useState('');

  const [_isDetailPage, setDetailPage] = useState(false);
  const [data, setData] = useState();

  useEffect(() => {
    if (!_isDetailPage) {
      setIsQueryingDetail(true);
      const _obj = {
        userName,
        orderID,
        dateType,
        mType,
        minAcAmount,
        maxAcAmount,
        orderType,
        actionStatus,
        confirmStatus,
        actionType,

        page,
        rows,
        startTime: moment(_date[0]).unix(),
        endTime: moment(_date[1]).unix()
      };
      GetTransactionList(_obj, setIsQueryingDetail);
    }
  }, [_isDetailPage]);


  const onSearchClick = () => {
    setIsQueryingDetail(true);
    const _obj = {
      userName,
      orderID,
      dateType,
      mType,
      minAcAmount,
      maxAcAmount,
      orderType,
      actionStatus,
      confirmStatus,
      currencyCode: '',
      actionType,

      page: 1,
      rows: 15,
      startTime: moment(_date[0]).unix(),
      endTime: moment(_date[1]).unix()
    };
    GetTransactionList(_obj, setIsQueryingDetail);
    setPage(1);
    setRows(15);
  };

  const onPaginationClick = (pageNum, rows) => {
    setIsQueryingDetail(true);
    setPage(pageNum);
    setRows(rows);
    const _obj = {
      userName,
      orderID,
      dateType,
      mType,
      minAcAmount,
      maxAcAmount,
      orderType,
      actionStatus,
      confirmStatus,
      currencyCode: '',
      actionType,

      page: pageNum,
      rows,
      startTime: moment(_date[0]).unix(),
      endTime: moment(_date[1]).unix()
    };
    GetTransactionList(_obj, setIsQueryingDetail);
  };

  return (
    <div>
      {
  _isDetailPage === false
    ? (
      <Col className="gameTransferAuditWrapper">
        <CommonHeader headerName={t('GAME_TRANSFER_AUDIT')} />
        <div className="filterWrapper">
          <div className="leftPart">
            <div>
              <div className="mx-2">
                账号
                <Input value={userName} onChange={text => setloginName(text.trim())} placeholder="请输入内容" />
              </div>
              <div className="me-2">
                用户类型
                <Select
                  popper-class="select-popper"
                  value={mType}
                  onChange={value => setMemType(value)}
                >
                  {
                    [{
                      label: '全部', value: ''
                    }, {
                      label: '会员', value: 1
                    }, {
                      label: '代理', value: 2
                    }].map(item => (
                      <Select.Option key={`UserBetStatus_${item.value}`} label={item.label} value={item.value} />
                    ))
                }
                </Select>
              </div>
              <div className="me-2">
                单号
                <Input value={orderID} onChange={text => setorderID(text.trim())} placeholder="请输入内容" />
              </div>
              <div className="me-2">
                交易类型
                <Select value={actionType} placeholder="请选择" disabled onChange={code => setActionType(code)}>
                  {
                [{
                  value: '', label: '全部'
                }, {
                  value: 100, label: '充值'
                }, {
                  value: 200, label: '提款'
                }, {
                  value: 300, label: '红利'
                }, {
                  value: 400, label: '代理赚水'
                }, {
                  value: 401, label: '会员反水'
                }, {
                  value: 600, label: '活动优惠'
                }, {
                  value: 700, label: '人工加额'
                }, {
                  value: 701, label: '人工减额'
                }, {
                  value: 800, label: '代理转账'
                }, {
                  value: 899, label: '游戏转账'
                }, {
                  value: 910, label: '解锁金额'
                }, {
                  value: 911, label: '锁定金额'
                }, {
                  value: 997, label: '试玩加额'
                }, {
                  value: 998, label: '财务加额'
                }, {
                  value: 999, label: '财务扣额'
                }].map(el => <Select.Option key={el.value} label={el.label} value={el.value} />)
              }
                </Select>
              </div>
              <div className="me-2">
                交易状态
                <Select value={actionStatus} placeholder="请选择" onChange={code => setActionStatus(code)}>
                  {
                [{
                  value: '', label: '全部'
                }, {
                  value: 300, label: '成功'
                }, {
                  value: 400, label: '失败'
                }, {
                  value: 500, label: '取消'
                }, {
                  value: 100, label: '待处理'
                }, {
                  value: 200, label: '待审核'
                }].map(el => <Select.Option key={el.value} label={el.label} value={el.value} />)
              }
                </Select>
              </div>
              <div className="me-2">
                确认状态
                <Select value={confirmStatus} placeholder="请选择" onChange={code => setConfirmStatus(code)}>
                  {
                     [{
                       value: '', label: '全部'
                     }, {
                       value: 100, label: '待确认'
                     }, {
                       value: 200, label: '已确认'
                     }].map(el => <Select.Option key={el.value} label={el.label} value={el.value} />)
                }
                </Select>
              </div>
              <div className="me-2">
                <Label type="daterange" value={t('DATE')} className="px-2">{t('DATE')}</Label>
                <RangePicker
                  allowClear={false}
                  value={[moment(_date[0]), moment(_date[1])]}
                  separator={<span>{` ${t('TO')} `}</span>}
                  ranges={{
                    Today: [moment(), moment()],
                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                  }}
                  onChange={date => {
                    setDate([date[0].toDate(), date[1].endOf('day').toDate()]);
                  }}
                />
              </div>
              <div className="me-2">
                <Select
                  popper-class="select-popper"
                  value={dateType}
                  onChange={value => setDateType(value)}
                >
                  {
                    [{
                      label: '审核时间', value: 1
                    }, {
                      label: '交易时间', value: 2
                    }].map(item => (
                      <Select.Option key={`UserBetStatus_${item.value}`} label={item.label} value={item.value} />
                    ))
                }
                </Select>
              </div>
            </div>
            <div>
              <div className="mx-2">
                交易金额
                <div>
                  <Input className="mx-1" value={minAcAmount} onChange={text => { if (!Number.isNaN(text)) { setMinAmount(text === '' ? 0 : parseInt(text, 10)); } }} />
                  至
                  <Input className="mx-1" value={maxAcAmount} onChange={text => { if (!Number.isNaN(text)) { setMaxAmount(text === '' ? 0 : parseInt(text, 10)); } }} />
                </div>
              </div>
              <div className="me-2">
                排序
                <Select
                  popper-class="select-popper"
                  value={orderType}
                  onChange={value => setOrderType(value)}
                >
                  {
                    [{
                      label: '审核时间', value: 1
                    }, {
                      label: '交易时间', value: 2
                    }].map(item => (
                      <Select.Option key={`UserBetStatus_${item.value}`} label={item.label} value={item.value} />
                    ))
                }
                </Select>
              </div>
            </div>
          </div>
          <div className="rightPart"><div className="searchButton" onClick={() => onSearchClick()}>查找</div></div>
        </div>
        <Row className="mx-0 bg-white px-0 border-1 mt-3">
          <AgentDataTable
            isQuerying={_isQueryingDetail}
            tableHeaderTitle={
            [{
              label: t('ORDER_NUM'),
            }, {
              label: '订单号',
            }, {
              label: '交易时间',
            }, {
              label: '用户帐户【类型】',
            }, {
              label: '交易类型',
            }, {
              label: '交易描述',
            }, {
              label: '交易前',
            }, {
              label: '交易金额',
            }, {
              label: '手续费',
            }, {
              label: '实际金额',
            }, {
              label: '交易后',
            }, {
              label: '确认状态',
            }, {
              label: '交易状态',
            }, {
              label: '审核客服',
            }]
          }
            tableHeaderStyle={['headerStyle', 'headerStyle', 'headerStyle', 'headerStyle', 'headerStyle', 'headerStyle', 'headerStyle', 'headerStyle', 'headerStyle', 'headerStyle', 'headerStyle', 'headerStyle', 'headerStyle', 'headerStyle']}
            tableData={transactionManage ? transactionManage.list : []}
            tableBodyRowProperty={
            [
              data => (<Label className="d-flex justify-content-center">{data.no}</Label>),
              data => (<Label className="d-flex justify-content-center text-primary detailLink" onClick={() => { setData(data); setDetailPage(true); }}>{data.orderID}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.createTime}</Label>),
              data => (<Label className="d-flex justify-content-center">{`${data.mName}[${data.mType === 2 ? '代理' : '会员'}]`}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.actionTypeName}</Label>),

              data => (<Label className="d-flex justify-content-center">{`${data.createRemark}`}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.beforeAmount}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.actualAmount}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.actionFee}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.actualAmount}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.afterAmount}</Label>),
              data => (<Label className="d-flex justify-content-center">{`${data.confirmStatusName}`}</Label>),
              data => (<Label className={data.actionStatus === '300' ? 'successValue' : 'negativeValue'}>{data.actionStatusName}</Label>),
              data => (
                <Label className="d-flex flex-column justify-content-center">
                  <div>{data.checkUName}</div>
                  <div>{data.checkTime}</div>
                </Label>
              )
            ]
          }
            tableFooter={[[{
              value: `本页:${transactionManage ? (transactionManage.pageSum.pageTotal || 0) : 0}笔`,
              colspan: 7,
              className: 'text-end total tableSum'
            },
            {
              value: (transactionManage ? (transactionManage.pageSum.pageActualAmount || 0) : 0),
              colspan: 1,
              className: 'total tableSum'
            },
            {
              value: (transactionManage ? (transactionManage.pageSum.pageActionFee || 0) : 0),
              colspan: 1,
              className: 'total tableSum'
            },
            {
              value: (transactionManage ? (transactionManage.pageSum.pageActualAmount || 0) : 0),
              colspan: 1,
              className: 'total tableSum'
            },
            {
              value: '',
              colspan: 1,
              className: 'total tableSum'
            },
            {
              value: '',
              colspan: 1,
              className: 'total tableSum'
            },
            {
              value: '',
              colspan: 1,
              className: 'total tableSum'
            },
            {
              value: '',
              colspan: 1,
              className: 'total tableSum'
            }], [{
              value: `全部:${transactionManage ? (transactionManage.sum.totalRecord || 0) : 0}笔`,
              colspan: 7,
              className: 'text-end total tableSum'
            },
            {
              value: (transactionManage ? (transactionManage.sum.totalActionAmount || 0) : 0),
              colspan: 1,
              className: 'total tableSum'
            },
            {
              value: (transactionManage ? (transactionManage.sum.totalActionFee || 0) : 0),
              colspan: 1,
              className: 'total tableSum'
            },
            {
              value: (transactionManage ? (transactionManage.sum.totalActualAmount || 0) : 0),
              colspan: 1,
              className: 'total tableSum'
            },
            {
              value: '',
              colspan: 1,
              className: 'total tableSum'
            },
            {
              value: '',
              colspan: 1,
              className: 'total tableSum'
            },
            {
              value: '',
              colspan: 1,
              className: 'total tableSum'
            },
            {
              value: '',
              colspan: 1,
              className: 'total tableSum'
            }]]}
          />
        </Row>
        <Row className="justify-center w-full relative">
          <Pagination totalRecord={transactionManage ? (transactionManage.sum.totalRecord || 0) : 0} onClick={onPaginationClick} isQuery={false} />
        </Row>
      </Col>
    )
    : <TransactionMenu callback={() => setDetailPage(false)} data={data} />
        }
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(GameTransferAudit);