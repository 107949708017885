
import { dxds } from '../Items/dsdx';

import { fan1to4, jiao1to4, men1to4, nian1to4, zheng1to4 } from '../Items/others';

const CJ_FANT = [{
  displayName: '总项盘口',
  hideMenu: 1,
  isStaticRow: 1,
  combineStyle: 1,
  repeatPerRow: 3,
  repeatPerCol: 3,
  midcode: 'DS,DX,FA,JI,NI,SM,TO,ZE',
  display: [[...dxds],
    [...zheng1to4],
    [...nian1to4],
    [...fan1to4],
    [...men1to4], [],
    [...jiao1to4]
  ],
  firstTableHeader: ['两面', '正', '念', '番', '三门', '', '角'],
  summary: [[{
    displayName: '两面总注额',
    code: 'item_LM'
  }], [{
    displayName: '正总注额',
    code: 'item_ZE'
  }], [{
    displayName: '念总注额',
    code: 'item_NI'
  }], [{
    displayName: '番总注额',
    code: 'item_FA'
  }], [{
    displayName: '三门总注额',
    code: 'item_SM'
  }], '', [{
    displayName: '角总注额',
    code: 'item_JI'
  }]]
}
];

export default CJ_FANT;