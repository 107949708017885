import { useEffect, useRef, useState } from 'react';

import { Col, Input, Label } from 'reactstrap';
import { IoCalendarOutline } from 'react-icons/io5';

import { Select } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import { useTranslation } from 'react-i18next';

import IconRefresh from '../../../assets/image/icon_refresh.svg';

import { oddsSettingActions } from '../../../redux/actions';

import Notifications from '../Notifications';
import './styles.scss';

const mapStateToProps = state => ({
  activeLottery: state.commonReducers.activeLottery,
  oddsSettingData: state.oddsSettingReducers.oddsSettingData,
  userInfo: state.memberReducers.userInfo,
});

const mapDispatchToProps = dispatch => ({
  getInfo: (obj, callback) => dispatch(oddsSettingActions.getInfo(obj, callback)),
});

const timeoptions = [{
  value: 99,
  label: '99秒'
}, {
  value: 40,
  label: '20秒'
}, {
  value: 30,
  label: '30秒'
}, {
  value: 20,
  label: '20秒'
}];

const zmtoptions = [{
  value: '-1',
  label: '全部'
}, {
  value: '正码特',
  label: '正码特A'
}, {
  value: '正码特B',
  label: '正码特B'
}];

momentDurationFormatSetup(moment);

const { Option } = Select;

const OddsSettingHeader = ({
  _midCode,
  midSubCategory,
  setMidSubCategory,
  activeLottery,
  userInfo,
  onRefreshClick = () => {},
  oddsSettingData,
  checkFunction = () => {},
  setAvailability = () => {}
}) => {
  const { t } = useTranslation();

  const [_timevalue, setTimeValue] = useState(20);
  const [_currentInstallment, setcurrentInstallment] = useState();

  const savedCallback = useRef();
  const countDownRef = useRef();
  const [_countDownTimer, setCountDownTimer] = useState(20);
  const [delayCallFlag, setDelayCallFlag] = useState(false);

  useEffect(() => {
    if (_timevalue) {
      setCountDownTimer(_timevalue || 20);
      countDownRef.current = _timevalue || 20;
    }
  }, [_timevalue]);

  useEffect(() => {
    if (oddsSettingData) {
      setcurrentInstallment(oddsSettingData?.installment);
    } else {
      setcurrentInstallment();
    }
  }, [oddsSettingData]);

  useEffect(() => {
    savedCallback.current = () => {
      if (_currentInstallment && _currentInstallment.openTimeStamp === 0 && _currentInstallment.state !== 3 && !delayCallFlag) {
        setDelayCallFlag(true);
        onRefreshClick();
        setAvailability(true);
        setCountDownTimer(_timevalue);
      }

      if (_currentInstallment && _currentInstallment.state === 1 && _currentInstallment?.closeTimeStamp > 0) {
        setAvailability(true);
      } else {
        setAvailability(false);
      }

      if (_countDownTimer === 0) {
        setDelayCallFlag(false);
        onRefreshClick();
        setCountDownTimer(_timevalue);
        countDownRef.current = _timevalue;
      } else {
        if (_currentInstallment && ((_currentInstallment?.closeTimeStamp ?? 0) - 1) === 0 && _currentInstallment?.openTimeStamp !== 0) {
          setcurrentInstallment();
        }

        countDownRef.current = _countDownTimer - 1;
        setCountDownTimer(_countDownTimer - 1);
        if (_currentInstallment && _currentInstallment.openTimeStamp !== 0) {
          setcurrentInstallment({
            ..._currentInstallment,
            closeTimeStamp: _currentInstallment.closeTimeStamp - 1 < 0 ? 0 : _currentInstallment.closeTimeStamp - 1,
            openTimeStamp: _currentInstallment.openTimeStamp - 1 < 0 ? 0 : _currentInstallment.openTimeStamp - 1,
          });
        } else if (oddsSettingData && _currentInstallment?.openTimeStamp === 0 && _currentInstallment?.closeTimeStamp === 0) {
          setcurrentInstallment(oddsSettingData?.installment);
        }
      }
    };
  });

  useEffect(() => {
    let id;
    const tick = () => {
      savedCallback.current();
    };
    if (!id) {
      id = setInterval(tick, 1000);
    }
    return () => {
      clearInterval(id);
    };
  }, []);


  return (
    <Col className="oddsSettingHeader">
      <Col className="d-flex align-items-center">
        <div className="bellNoti align-items-center p-0 m-0">
          <IoCalendarOutline />
        </div>
        <div className="currentInstallment">
          <span className="mx-1">{_currentInstallment && _currentInstallment?.installments}</span>
          {t('INSTALLMENT')}
        </div>
        <div className="timeToClose">
          {
            (_currentInstallment && _currentInstallment?.closeTimeStamp >= 0) ? (
              <>
                {t('TIME_TO_CLOSE')}
                :
                <span className="mx-1">
                  {moment.duration((_currentInstallment && _currentInstallment.closeTimeStamp >= 0 && _currentInstallment.closeTimeStamp) || 0, 'second').format('hh:mm:ss', {
                    trim: false
                  })}
                </span>
              </>
            ) : (
              <>
                {t('TIME_TO_OPEN_RESULT')}
                :
                <span className="mx-1">
                  {moment.duration((_currentInstallment && _currentInstallment.openTimeStamp >= 0 && _currentInstallment.openTimeStamp) || 0, 'second').format('hh:mm:ss', {
                    trim: false
                  })}
                </span>
              </>
            )
          }
        </div>
        <div className="todayProfit">
          {`${t('TODAY_PROFIT')} : `}
          {_currentInstallment?.todayWinLost || 0}
        </div>
      </Col>
      <Col className="d-flex p-0 align-items-center justify-content-end">
        <>
          <Input
            id="main_selectAll"
            type="checkbox"
            className="mx-2 p-1 align-items-center hide"
            onClick={() => checkFunction(0)}
          />
          <Label for="main_selectAll" className="refreshButton select mx-1">
            {t('ALL_MORATORIUM')}
          </Label>
          <Input
            id="main_deselectAll"
            type="checkbox"
            className="mx-2 p-1 align-items-center hide"
            onClick={() => checkFunction(1)}
          />
          <Label for="main_deselectAll" className="refreshButton deselect mx-1">
            {t('ALL_OPEN')}
          </Label>
        </>
        { userInfo?.supportZmt && activeLottery?.templateCode === 'HK6' && (_midCode?.midCode ?? '').includes('ZMT')
          ? (
            <Select
              className="zmtModeSelect ms-1"
              value={midSubCategory}
              placeholder="请选择"
              onChange={val => setMidSubCategory(val)}
            >
              {
                zmtoptions.map(el => <Option key={el.value} value={el.value}>{el.label}</Option>)
              }
            </Select>
          )
          : ''}
        <>
          <Select
            className="ms-2"
            value={_timevalue}
            placeholder="请选择"
            onChange={val => setTimeValue(val)}
          >
            {
          timeoptions.map(el => <Option key={el.value} value={el.value}>{el.label}</Option>)
        }
          </Select>
          <div className="d-flex align-items-center ms-2">
            <img src={IconRefresh} alt="" className="refreshIcon" onClick={() => {}} />
            <div className="countDownTimer">
              {`${_countDownTimer < 10 ? `0${_countDownTimer}` : _countDownTimer} S`}
            </div>
          </div>
          <div className="refreshButton mx-2" onClick={() => onRefreshClick()}>{t('REFRESH')}</div>
        </>
        <Notifications />
      </Col>
    </Col>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(OddsSettingHeader);