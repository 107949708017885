import { StatusCodes } from 'http-status-codes';

import { SET_PANKOU_DATA } from '../actionTypes';
import { apiService } from '../../../library';
import appActions from '../appActions';

const setPanKouData = data => ({
  type: SET_PANKOU_DATA,
  value: data,
});

const GetInfo = (obj, callback = () => {}) => dispatch => {
  apiService.get('/OddsManage/GetInfo', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      callback();
      return dispatch(setPanKouData(data.result));
    }
  }).catch(error => {
    dispatch(setPanKouData());
    dispatch(appActions.updateModalAlert({
      visible: true,
      type: 'confirmation',
      data: {
        message: error.response.data.msg
      }
    }));
    Promise.reject(error);
  });
};

const GetNextLotteryInstallNo = (obj, callback = () => {}) => dispatch => {
  apiService.get(`/OddsManage/GetNextLotteryInstallNo/${obj.lotteryType}`).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      return callback(data.msg);
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: true,
      type: 'confirmation',
      data: {
        message: error.response.data.msg
      }
    }));
    Promise.reject(error);
  });
};


const GetOddsCurrAndLotteryHis = (id, type, callback = () => {}) => dispatch => {
  apiService.get('/OddsManage/GetOddsCurrAndLotteryHis', new URLSearchParams({
    oddsMngID: id
  })).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        return dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'pankouSetting',
          data: {
            data: data.result,
            type,
            onConfirmationClick: _data => callback(_data)
          }
        }));
      }
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: true,
      type: 'confirmation',
      data: {
        message: error.response.data.msg
      }
    }));
    Promise.reject(error);
  });
};


const SaveOdds = (obj, callback = () => {}) => dispatch => {
  apiService.post('/OddsManage/SaveOdds', obj).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      callback();
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg
        }
      }));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: true,
      type: 'confirmation',
      data: {
        message: error.response.data.msg
      }
    }));
    Promise.reject(error);
  });
};

const SaveOddsAutoOpen = (id, callback = () => {}) => dispatch => {
  apiService.post(`/OddsManage/SaveOddsAutoOpen/${id}`).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      callback();
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg
        }
      }));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: true,
      type: 'confirmation',
      data: {
        message: error.response.data.msg
      }
    }));
    Promise.reject(error);
  });
};

const SaveOddsAutoResult = (id, callback = () => {}) => dispatch => {
  apiService.post(`/OddsManage/SaveOddsAutoResult/${id}`).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      callback();
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg
        }
      }));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: true,
      type: 'confirmation',
      data: {
        message: error.response.data.msg
      }
    }));
    Promise.reject(error);
  });
};

const SaveOddsCurrPk = (id, callback = () => {}) => dispatch => {
  apiService.post(`/OddsManage/SaveOddsCurrPk/${id}`).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      callback();
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg
        }
      }));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: true,
      type: 'confirmation',
      data: {
        message: error.response.data.msg
      }
    }));
    Promise.reject(error);
  });
};

const CancelInstallments = (id, callback = () => {}) => dispatch => {
  apiService.post(`/OddsManage/CancelInstallments/${id}`).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      callback();
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg
        }
      }));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: true,
      type: 'confirmation',
      data: {
        message: error.response.data.msg
      }
    }));
    Promise.reject(error);
  });
};

const SaveAllOddsAutoOpen = (obj, callback = () => {}) => dispatch => {
  apiService.post('/OddsManage/SaveAllOddsAutoOpen', obj).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      callback();
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg
        }
      }));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: true,
      type: 'confirmation',
      data: {
        message: error.response.data.msg
      }
    }));
    Promise.reject(error);
  });
};

const SaveAllOddsAutoResult = (obj, callback = () => {}) => dispatch => {
  apiService.post('/OddsManage/SaveAllOddsAutoResult', obj).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      callback();
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg
        }
      }));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: true,
      type: 'confirmation',
      data: {
        message: error.response.data.msg
      }
    }));
    Promise.reject(error);
  });
};

const BatchCancelInstallments = (obj, callback = () => {}) => dispatch => {
  apiService.post('/OddsManage/BatchCancelInstallments', obj).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      callback();
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg
        }
      }));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: true,
      type: 'confirmation',
      data: {
        message: error.response.data.msg
      }
    }));
    Promise.reject(error);
  });
};

const RestartService = (callback = () => {}) => dispatch => {
  apiService.post('/OddsManage/RestartService').then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      callback();
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg
        }
      }));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: true,
      type: 'confirmation',
      data: {
        message: error.response.data.msg
      }
    }));
    Promise.reject(error);
  });
};

const CancelLotteryHis = (id, callback = () => {}) => dispatch => {
  apiService.post(`/OddsManage/CancelLotteryHis/${id}`).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      callback();
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg
        }
      }));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: true,
      type: 'confirmation',
      data: {
        message: error.response.data.msg
      }
    }));
    Promise.reject(error);
  });
};

const Settlement = (obj, callback = () => {}) => dispatch => {
  apiService.post('/OddsManage/Settlement', obj).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (data.result.type === '2') {
        callback();
        return dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'success',
          data: {
            message: data.msg
          }
        }));
      }
      return dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: data.result.msg,
          showCancelButton: false
        }
      }));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: true,
      type: 'confirmation',
      data: {
        message: error.response.data.result.msg,
        showCancelButton: false
      }
    }));
    Promise.reject(error);
  });
};

const RestoreBetting = (obj, callback = () => {}) => dispatch => {
  apiService.post('/OddsManage/RestoreBetting', obj).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      callback();
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg
        }
      }));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: true,
      type: 'confirmation',
      data: {
        message: error.response.data.msg
      }
    }));
    Promise.reject(error);
  });
};

const OpenForMember = (id, callback = () => {}) => dispatch => {
  apiService.post(`/OddsManage/OpenForMember/${id}`).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      callback();
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg
        }
      }));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: true,
      type: 'confirmation',
      data: {
        message: error.response.data.msg
      }
    }));
    Promise.reject(error);
  });
};

const DeleteLotteryHis = (id, callback = () => {}) => dispatch => {
  apiService.post(`/OddsManage/DeleteLotteryHis/${id}`).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      callback();
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg
        }
      }));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: true,
      type: 'confirmation',
      data: {
        message: error.response.data.msg
      }
    }));
    Promise.reject(error);
  });
};

const CancelLotteryInstallment = (id, callback = () => {}) => dispatch => {
  apiService.post(`/OddsManage/CancelLotteryInstallment/${id}`).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      callback();
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg
        }
      }));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: true,
      type: 'confirmation',
      data: {
        message: error.response.data.msg
      }
    }));
    Promise.reject(error);
  });
};

const GetLotteryHis = (id, callback = () => {}) => dispatch => {
  apiService.get(`/OddsManage/GetLotteryHis/${id}`).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        return dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'pankouSetting',
          data: {
            data: data.result,
            type: 'update',
            onConfirmationClick: _data => callback(_data)
          }
        }));
      }
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: true,
      type: 'confirmation',
      data: {
        message: error.response.data.msg
      }
    }));
    Promise.reject(error);
  });
};

const UpdateLotteryHis = (obj, callback = () => {}) => dispatch => {
  apiService.post('/OddsManage/UpdateLotteryHis', obj).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        dispatch(appActions.updateModalAlert({
          visible: false
        }));
        callback();
        return dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'success',
          data: {
            message: data.msg
          }
        }));
      }
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: true,
      type: 'confirmation',
      data: {
        message: error.response.data.msg
      }
    }));
    Promise.reject(error);
  });
};

const SaveLottery = (obj, callback = () => {}, lastOneNumber) => dispatch => {
  apiService.post('/OddsManage/SaveLottery', obj).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        dispatch(appActions.updateModalAlert({
          visible: false
        }));
        if (lastOneNumber || lastOneNumber === undefined) {
          dispatch(appActions.updateModalAlert({
            visible: true,
            type: 'success',
            data: {
              message: data.msg
            }
          }));
        }
        return callback();
      }
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: true,
      type: 'confirmation',
      data: {
        message: error.response.data.msg,
        showCancelButton: false
      }
    }));
    Promise.reject(error);
  });
};

export default {
  GetInfo,
  GetNextLotteryInstallNo,
  SaveOdds,
  SaveOddsAutoOpen,
  SaveOddsAutoResult,
  SaveOddsCurrPk,
  CancelInstallments,
  SaveAllOddsAutoOpen,
  SaveAllOddsAutoResult,
  BatchCancelInstallments,
  RestartService,
  GetOddsCurrAndLotteryHis,
  CancelLotteryHis,
  Settlement,
  RestoreBetting,
  OpenForMember,
  DeleteLotteryHis,
  CancelLotteryInstallment,
  GetLotteryHis,
  UpdateLotteryHis,
  SaveLottery,
};