import { bigsmalltail, dxds, hdxds, totalScoreDs, totalScoreDx } from '../Items/dsdx';


import { sixXiao } from '../Items/others';
import { threeColors } from '../Items/colors';

import { number1to49 } from '../Items/numbers';

import { qsAll, qsColors, qsDxds, qsFiller, qsHead, qsTail, qsZodiac } from '../Items/quickset';

const F36X7 = [{
  displayName: '特码',
  displayRows: 16,
  midcode: 'TM,TMLM',
  isStaticRow: 1,
  quickSet: {
    style: 1,
    variables: [[...qsZodiac, ...qsFiller, ...qsFiller, ...qsFiller], [...qsColors], [...qsHead, ...qsTail], [...qsAll, ...qsDxds]]
  },
  display: [[...number1to49.slice(0, 13)],
    [...number1to49.slice(13, 26)],
    [...number1to49.slice(26, 36)],
    [...dxds, ...hdxds, ...bigsmalltail, ...threeColors]],
}, {
  displayName: '正码',
  midcode: 'ZM,ZFLM',
  displayRows: 10,
  quickSet: {
    style: 1,
    variables: [[...qsZodiac, ...qsFiller, ...qsFiller, ...qsFiller], [...qsColors], [...qsHead, ...qsTail], [...qsAll, ...qsDxds]]
  },
  display: [...number1to49.slice(0, 36), ...totalScoreDs, ...totalScoreDx],
},
{
  displayName: '正码特',
  isStaticRow: 1,
  quickSet: {
    style: 1,
    variables: [[...qsZodiac, ...qsFiller, ...qsFiller, ...qsFiller], [...qsColors], [...qsHead, ...qsTail], [...qsAll, ...qsDxds]]
  },
  display: [[...number1to49.slice(0, 13)],
    [...number1to49.slice(13, 26)],
    [...number1to49.slice(26, 36)],
    [...dxds, ...hdxds, ...bigsmalltail, ...threeColors]],
  children: [
    {
      tabId: 'ZMT1',
      label: '正1特',
      midcode: 'Z1T,ZM1LM',
    }, {
      tabId: 'ZMT2',
      label: '正2特',
      midcode: 'Z2T,ZM2LM',
    }, {
      tabId: 'ZMT3',
      label: '正3特',
      midcode: 'Z3T,ZM3LM',
    }, {
      tabId: 'ZMT4',
      label: '正4特',
      midcode: 'Z4T,ZM4LM',
    }, {
      tabId: 'ZMT5',
      label: '正5特',
      midcode: 'Z5T,ZM5LM',
    }, {
      tabId: 'ZMT6',
      label: '正6特',
      midcode: 'Z6T,ZM6LM',
    }
  ]
},
{
  displayName: '连码',
  displayRows: 10,
  display: [...number1to49.slice(0, 36)],
  quickSet: {
    style: 1,
    variables: [[...qsZodiac, ...qsFiller, ...qsFiller, ...qsFiller], [...qsColors], [...qsHead, ...qsTail], [...qsAll, ...qsDxds]]
  },
  isLianMa: 1,
  children: [
    {
      tabId: 'L2QZ',
      label: '二全中',
      midcode: 'L2QZ',
    }, {
      tabId: 'L1ZB',
      label: '一字半',
      midcode: 'L1ZB',
    }, {
      tabId: 'L3ZT',
      label: '三中_中特',
      midcode: 'L3ZT',
    }, {
      tabId: 'L3Z2Z3',
      label: '三中_中三',
      midcode: 'L3Z2Z3',
    }, {
      tabId: 'L4ZT',
      label: '四中_中特',
      midcode: 'L4ZT',
    }, {
      tabId: 'L4ZTZ4',
      label: '四中-中四',
      midcode: 'L4ZTZ4',
    }, {
      tabId: 'L3QZ',
      label: '三全中',
      midcode: 'L3QZ',
    }, {
      tabId: 'L2ZB',
      label: '二字半',
      midcode: 'L2ZB',
    }, {
      tabId: 'L4QZ',
      label: '四全中',
      midcode: 'L4QZ',
    }, {
      tabId: 'L3ZB',
      label: '三字半',
      midcode: 'L3ZB',
    }
  ]
},
{
  displayName: '不中',
  displayRows: 10,
  display: [...number1to49.slice(0, 36)],
  quickSet: {
    style: 1,
    variables: [[...qsZodiac, ...qsFiller, ...qsFiller, ...qsFiller], [...qsColors], [...qsHead, ...qsTail], [...qsAll, ...qsDxds]]
  },
  children: [
    {
      tabId: 'BZ4BZ',
      label: '四不中',
      midcode: 'BZ4BZ',
    }, {
      tabId: 'BZ5BZ',
      label: '五不中',
      midcode: 'BZ5BZ',
    }, {
      tabId: 'BZ6BZ',
      label: '六不中',
      midcode: 'BZ6BZ',
    }, {
      tabId: 'BZ7BZ',
      label: '七不中',
      midcode: 'BZ7BZ',
    }, {
      tabId: 'BZ8BZ',
      label: '八不中',
      midcode: 'BZ8BZ',
    }, {
      tabId: 'BZ9BZ',
      label: '九不中',
      midcode: 'BZ9BZ',
    }, {
      tabId: 'BZ10BZ',
      label: '十不中',
      midcode: 'BZ10BZ',
    }, {
      tabId: 'BZ11BZ',
      label: '十一不中',
      midcode: 'BZ11BZ',
    }, {
      tabId: 'BZ12BZ',
      label: '十二不中',
      midcode: 'BZ12BZ',
    }, {
      tabId: 'BZ13BZ',
      label: '十三不中',
      midcode: 'BZ13BZ',
    }, {
      tabId: 'BZ14BZ',
      label: '十四不中',
      midcode: 'BZ14BZ',
    }
  ]
},
{
  displayName: '六肖',
  midcode: 'LX',
  display: [...sixXiao],
  displayRows: 6,
},
];

export default F36X7;