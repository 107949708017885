import axios from 'axios';

import { SET_MODAL_ALERT, USER_LOGOUT } from '../../redux/actions/actionTypes';
import { store } from '../../redux';

const instance = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL : `${window.location.protocol}//${window.location.hostname}/api`,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  }
});

instance.interceptors.request.use(
  config => {
    if (config.url.includes('/system/cancelBetting')) { // set timeout for cancel betting
      config.headers.timeout = 60000 * 30;
    }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

instance.interceptors.response.use(
  response => response,
  error => {
    const originalRequest = error.config;
    if (axios.isCancel(error)) {
      return;
    }

    if (originalRequest) {
      if (error.response) {
        const {
          data,
          status,
        } = error.response || {};

        const { msg, state } = data || {};

        if (status === 401 && msg) {
          return store.dispatch({
            type: SET_MODAL_ALERT,
            value: {
              visible: true,
              type: 'confirmation',
              data: {
                message: '你的账号已在其他地方登入',
                confirmText: '知道了',
                showCancelButton: false,
                onConfirmationClick: () => {
                  store.dispatch({
                    type: USER_LOGOUT
                  });
                }
              }
            }
          });
        }

        if (status === 400 && state === 1019) {
          return store.dispatch({
            type: SET_MODAL_ALERT,
            value: {
              visible: true,
              type: 'confirmation',
              data: {
                message: msg,
                confirmText: '知道了',
                showCancelButton: false,
              }
            }
          });
        }
      }
    }
    if (error.code === 'ECONNABORTED') {
      return store.dispatch({
        type: SET_MODAL_ALERT,
        value: {
          visible: true,
          type: 'confirmation',
          data: {
            message: '请求已超时，请重新尝试',
            confirmText: '知道了',
            showCancelButton: false,
            onConfirmationClick: () => {
              store.dispatch({
                type: SET_MODAL_ALERT,
                value: {
                  visible: false,
                }
              });
            }
          }
        }
      });
    }
    return Promise.reject(error);
  }
);

export default instance;