import { useEffect, useState } from 'react';

import { Input, Select } from 'antd';
import { Label } from 'reactstrap';
import { MdArrowBack } from 'react-icons/md';
import { connect } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { PlusCircleOutlined } from '@ant-design/icons';

import { AgentDataTable, CommonHeader } from '../../components';
import { backupActions } from '../../redux/actions';

import './styles.scss';


const mapStateToProps = state => ({
  activeLottery: state.commonReducers.activeLottery,
  backUpInfo: state.backupReducers.backUpInfo,
  lotteryList: state.commonReducers.lotteryList,
});

const mapDispatchToProps = dispatch => ({
  GetInfo: (params, callback) => dispatch(backupActions.GetInfo(params, callback)),
  DeleteTask: (id, callback) => dispatch(backupActions.DeleteTask(id, callback)),
  GetDownloadUrl: (id, callback) => dispatch(backupActions.GetDownloadUrl(id, callback)),
  addBackUpTask: (data, callback) => dispatch(backupActions.addBackUpTask(data, callback)),
});

const { Option } = Select;

const BetTaskBackup = ({ GetInfo, backUpInfo, DeleteTask, GetDownloadUrl, addBackUpTask, lotteryList, activeLottery }) => {
  const { t } = useTranslation();
  const [_isQuerying, setIsQuerying] = useState(false);
  const [_pageFlag, setPageFlag] = useState(0);

  const [peroidNum, setperoidNum] = useState('');
  const [bettingStatus, setbettingStatus] = useState(0);
  const [lotteryType, setlotteryType] = useState(activeLottery?.code || '');
  const [taskName, settaskName] = useState('');

  const onClickAdd = () => {
    const obj = {
      peroidNum, bettingStatus, lotteryType, taskName
    };
    const callback = () => {
      setPageFlag(0);
    };
    addBackUpTask(obj, callback);
  };

  const onClickDeleteTask = id => {
    DeleteTask(id, () => GetInfo());
  };

  const onClickDownload = id => {
    const callback = url => {
      const element = document.createElement('a');
      element.target = '_blank';
      element.href = url;
      element.download = `backup-${Date.now()}.zip`;
      document.body.appendChild(element);
      element.click();
    };
    GetDownloadUrl(id, url => callback(url));
  };

  useEffect(() => {
    if (_pageFlag === 0) {
      setIsQuerying(true);
      GetInfo({}, () => setIsQuerying(false));
    }
  }, [_pageFlag]);

  return (
    <div className="BetTaskBackupWrapper">
      <CommonHeader
        headerName={(
          <div className="d-flex align-items-center">
            {_pageFlag !== 0 && (
            <div className="d-flex align-items-center me-2 backIcon" onClick={() => setPageFlag(0)}>
              <MdArrowBack className="h5 mb-0 text-white" />
              返回
            </div>
            )}
            {`${t('BET_TASK_BACKUP')} ${_pageFlag === 0 ? '' : '/ 新增任务'}`}
          </div>
)}
      />
      {_pageFlag === 0 && (
      <>
        <div className="addNewGameBox my-3">
          <div className="operationBtn add d-flex align-items-center" onClick={() => setPageFlag(1)}>
            <PlusCircleOutlined />
            <span className="ms-2">新增任务</span>
          </div>
        </div>

        <div className="mx-0 bg-white mb-3 px-0 border-1">
          <AgentDataTable
            isQuerying={_isQuerying}
            tableHeaderTitle={
            [{
              label: t('TASK_NAME'),
            }, {
              label: t('LOTTERY_NAME'),
            }, {
              label: t('TASK_INSTALLMENT'),
            }, {
              label: t('JIESUAN_TYPE'),
            }, {
              label: t('CREATE_TIME'),
            }, {
              label: t('TASK_STATUS'),
            }, {
              label: t('RENEW_TIME'),
            }, {
              label: t('OPERATION'),
            }]
          }
            tableHeaderStyle={
            []
          }
            tableData={backUpInfo || []}
            tableBodyRowProperty={
            [
              data => (<Label className="d-flex justify-content-center">{data.taskName ?? ''}</Label>),
              data => (<Label className="d-flex justify-content-center">{`${data.lotteryName}[${data.lotteryType}]`}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.bettingPeroidNum}</Label>),
              data => (<Label className={`d-flex justify-content-center ${data.bettingStatus === 1 && 'text-danger'}`}>{data.bettingStatus === 0 ? '未结' : '已结'}</Label>),
              data => (<Label className="d-flex justify-content-center">{moment.unix(data.createTime).format('YYYY-MM-DD HH:mm:ss')}</Label>),
              data => (<Label className={`d-flex justify-content-center ${data.updateStatus === 1 && 'text-danger'}`}>{data.updateStatus === 1 ? '已完成' : '进行中'}</Label>),
              data => (<Label className={`d-flex justify-content-center ${data.status === 1 && 'text-danger'}`}>{moment.unix(data.lastUpdateTime).format('YYYY-MM-DD HH:mm:ss')}</Label>),
              data => (
                <Label className="d-flex justify-content-center">
                  <div className="operationBtn cancel" onClick={() => onClickDeleteTask(data.taskID)}>删除</div>
                  {data.updateStatus === 1 && <div className="operationBtn ms-1" onClick={() => onClickDownload(data.taskID)}>下载</div>}
                </Label>
              )
            ]
          }
          />
        </div>
      </>
      )}
      {_pageFlag === 1 && (
        <>
          <div className="my-3 thirdPartyGamesTable">
            <div className="thirdPartyGamesTitle">新增任务</div>
            <div className="thirdPartyGamesRow">
              <div>任务名称: </div>
              <div><Input value={taskName} onChange={({ target: { value: text } }) => settaskName(text.trim())} placeholder="请输入任务名称" /></div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>彩种: </div>
              <div>
                <Select
                  value={lotteryType}
                  placeholder="请选择"
                  onChange={code => {
                    setlotteryType(code);
                  }}
                >
                  {
                        lotteryList && lotteryList.map(el => <Option key={el.code} value={el.code}>{el.lotteryName}</Option>)
                  }
                </Select>
              </div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>注单状态: </div>
              <div>
                <Select
                  value={bettingStatus}
                  placeholder="请选择"
                  onChange={code => {
                    setbettingStatus(code);
                  }}
                >
                  {
                [{
                  value: 0, label: '未结'
                }, {
                  value: 1, label: '已结'
                }].map(el => <Option key={el.value} value={el.value}>{el.label}</Option>)
              }
                </Select>
              </div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>期数: </div>
              <div><Input value={peroidNum} onChange={({ target: { value: text } }) => setperoidNum(text > -1 ? text.trim() : peroidNum)} placeholder="请输入期数" /></div>
            </div>
          </div>
          <div className="thirdPartyGamesFooter">
            <div className="operationBtn editAndSave me-3" onClick={() => onClickAdd()}>确定</div>
            <div className="operationBtn cancel" onClick={() => setPageFlag(0)}>取消</div>
          </div>
        </>
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BetTaskBackup);