import { useEffect, useState } from 'react';

import { Button, Col, Label, Row } from 'reactstrap';
import { Input, Select } from 'antd';
import { IoClose } from 'react-icons/io5';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import AgentDataTable from '../../AgentDataTable';
import { appActions } from '../../../redux/actions';

import './styles.scss';


const mapStateToProps = state => ({
  inTimeSheetInfo: state.inTimeSheetReducers.inTimeSheetInfo,
  activeLottery: state.commonReducers.activeLottery,
  selectedSubMenu: state.commonReducers.selectedSubMenu,
});

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
});

const { Option } = Select;

const BuhuoModal = ({ updateModalAlert, data, inTimeSheetInfo, activeLottery, selectedSubMenu }) => {
  const { t } = useTranslation();
  const [webId, setwebId] = useState(inTimeSheetInfo.buhuoWebList.length > 0 ? inTimeSheetInfo.buhuoWebList[0].webId : '');

  const [betData, setbetData] = useState([]);
  const [IsCompanyLevel, setIsCompanyLevel] = useState(false);

  useEffect(() => {
    if (data.list) {
      setbetData(data.list);
    }
  }, []);

  useEffect(() => {
    if (inTimeSheetInfo && inTimeSheetInfo?.orgLevelValue < inTimeSheetInfo?.info.zdLevel) {
      setIsCompanyLevel(true);
    } else {
      setIsCompanyLevel(false);
    }
  }, [inTimeSheetInfo]);

  const onConfirmationClick = () => {
    const obj = {
      isTmb: ((activeLottery.templateCode === 'HK6' || activeLottery.templateCode === 'F36X7' || activeLottery.templateCode === 'F31X7') && selectedSubMenu === 0) ? 1 : 0,
      webId: IsCompanyLevel ? webId : '',
      lotteryType: activeLottery.code,
      betData,
      oddsType: 'A'
    };
    return data.onConfirmationClick(obj);
  };

  const tableHeader = [{
    label: '项目',
  }, {
    label: '号码',
  }, {
    label: '赔率',
  }, {
    label: '退水',
  }, {
    label: '金额',
  }];

  const tableContent = [
    data => (<Label className="d-flex justify-content-center align-items-center">{data?.midtype}</Label>),
    data => (<Label className="d-flex justify-content-center align-items-center">{data?.displaynName}</Label>),
    data => (
      <Label className="d-flex justify-content-center align-items-center">
        <Input
          value={data.odds}
          disabled={!IsCompanyLevel}
          onChange={({ target: { value: text } }) => {
            const temp = betData.map(el => (el.keyCode === data.keyCode ? ({
              ...el, odds: text > -1 ? Number(text.trim()) : data.odds
            }) : el));
            setbetData(temp);
          }}
        />
      </Label>
    ),
    data => (
      <Label className="d-flex justify-content-center align-items-center">
        <Input
          disabled={!IsCompanyLevel}
          value={data.returnValue}
          className="me-2"
          onChange={({ target: { value: text } }) => {
            const temp = betData.map(el => (el.keyCode === data.keyCode ? ({
              ...el, returnValue: text > -1 ? Number(text.trim()) : data.returnValue
            }) : el));
            setbetData(temp);
          }}
        />
        %
      </Label>
    ),
    data => (
      <Label className="d-flex justify-content-center align-items-center">
        <Input
          value={data.amount}
          onChange={({ target: { value: text } }) => {
            const temp = betData.map(el => (el.keyCode === data.keyCode ? ({
              ...el, amount: text > -1 ? Number(text.trim()) : data.amount
            }) : el));
            setbetData(temp);
          }}
        />
      </Label>
    ),
  ];

  return (
    <div className="BuhuoModalWrapperWeb">
      <Row className="modalHeader">
        <Col />
        <Col
          className={`${'d-flex justify-content-center align-items-center'}`}
        >
          补货计算
        </Col>
        <Col className="d-flex justify-content-end align-items-center">
          <IoClose
            onClick={() => {
              updateModalAlert({
                visible: false
              });
              if (data && data.overBet && data.onConfirmationClick) data.onConfirmationClick();
            }}
            className="h4 mb-0 float-right closeIcon"
          />
        </Col>
      </Row>
      <Row className="modalContent d-flex justify-content-center">
        {IsCompanyLevel
          ? (
            <div className="py-2 d-flex align-items-center">
              <span className="me-2">外补网站：</span>
              <Select className="dropDownListStyle" value={webId} placeholder="请选择" onChange={val => setwebId(val)}>
                {
              inTimeSheetInfo && ([].concat(inTimeSheetInfo.buhuoWebList).map(el => <Option key={el.webId} value={el.webId}>{el.webName}</Option>)
              )
            }
              </Select>
            </div>
          )
          : ''}
        <div className="p-2 tableViewArea">
          <AgentDataTable
            tableHeaderTitle={tableHeader}
            tableData={betData}
            tableBodyRowProperty={tableContent}
          />
        </div>
      </Row>
      <Row className="modalFooter d-flex align-items-center">
        <Button
          className={`confirmButton my-2 ${'me-3'}`}
          onClick={() => {
            if (data && data.onConfirmationClick) onConfirmationClick();
          }}
        >
          {(data && data.confirmText) || t('CONFIRM')}
        </Button>
        {
          data && data.showCancelButton !== false && (
            <Button
              className={`cancelButton my-3 ${'me-3'}`}
              onClick={() => {
                updateModalAlert({
                  visible: false
                });
                if (data && data.onCancelClick) data.onCancelClick();
              }}
            >
              {(data && data.cancelText) || t('CANCEL')}
            </Button>
          )
        }
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BuhuoModal);