import { renderResultDateCell } from '../../utils';

const balls = {
  1: {
    value: 1,
    className: 'square square_1'
  },
  2: {
    value: 2,
    className: 'square square_2'
  },
  3: {
    value: 3,
    className: 'square square_3'
  },
  4: {
    value: 4,
    className: 'square square_4'
  },
  5: {
    value: 5,
    className: 'square square_5'
  },
  6: {
    value: 6,
    className: 'square square_6'
  },
  7: {
    value: 7,
    className: 'square square_7'
  },
  8: {
    value: 8,
    className: 'square square_8'
  },
  9: {
    value: 9,
    className: 'square square_9'
  },
  10: {
    value: 10,
    className: 'square square_10'
  }
};

const renderQiu = data => {
  if (data && !data.openResult) return;
  const result = data.openResult.split(',');
  return result.map(item => (
    {
      value: item, className: balls[item] ? balls[item].className : ''
    }
  ));
};

const renderGYH = data => {
  if (data && !data.openResult) return;
  const result = data.openResult.split(',');
  const tempResult = [];
  const championSum = parseInt(result[0], 10) + parseInt(result[1], 10);

  tempResult.push({
    value: championSum
  });

  tempResult.push({
    value: championSum > 11 ? '大' : '小',
    className: championSum > 11 ? 'redText' : '',
  });

  tempResult.push({
    value: championSum % 2 ? '单' : '双',
    className: championSum % 2 ? 'redText' : '',
  });
  return tempResult;
};

const renderLH = data => {
  if (data && !data.openResult) return;
  const result = data.openResult.split(',');
  const tempResult = [];
  for (let i = 0; i < result.length / 2; i++) {
    if ((parseInt(result[i], 10)) > parseInt(result[result.length - 1 - i], 10)) {
      tempResult.push({
        value: '龙',
        className: 'redText'
      });
    } else {
      tempResult.push({
        value: '虎',
      });
    }
  }
  return tempResult;
};

const PK10 = {
  resultTab: {
    headerCell: [{
      attr: '期数',
      wrapperStyle: 'pk10Header'
    }, {
      attr: '日期',
      wrapperStyle: 'pk10Header'
    }, {
      attr: '彩球号码',
      wrapperStyle: 'pk10Header'
    }, {
      attr: '冠亚和',
      wrapperStyle: 'pk10Header'
    }, {
      attr: '1-5球龙虎',
      wrapperStyle: 'pk10Header'
    }],
    bodyCell: [{
      attr: 'installments',
      wrapperStyle: 'd-flex justify-content-center align-items-center'
    }, {
      attr: renderResultDateCell,
      wrapperStyle: 'dateCol'
    }, {
      attr: renderQiu,
      wrapperStyle: 'd-flex justify-content-center align-items-center pk10Num'
    }, {
      attr: renderGYH,
      wrapperStyle: 'd-flex justify-content-center align-items-center pk10GYH'
    }, {
      attr: renderLH,
      wrapperStyle: 'd-flex justify-content-center align-items-center pk10LH'
    }],
  },
};

export default PK10;