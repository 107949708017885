import { renderResultDateCell } from '../../utils';

const balls = {
  1: {
    value: 1,
    color: '红',
    fls: '福',
    className: 'circle circleRed'
  },
  2: {
    value: 2,
    color: '蓝',
    fls: '福',
    className: 'circle circleBlue'
  },
  3: {
    value: 3,
    color: '绿',
    fls: '福',
    className: 'circle circleGreen'
  },
  4: {
    value: 4,
    color: '红',
    fls: '福',
    className: 'circle circleRed'
  },
  5: {
    value: 5,
    color: '蓝',
    fls: '福',
    className: 'circle circleBlue'
  },
  6: {
    value: 6,
    color: '绿',
    fls: '禄',
    className: 'circle circleGreen'
  },
  7: {
    color: '红',
    value: 7,
    fls: '禄',
    className: 'circle circleRed'
  },
  8: {
    value: 8,
    color: '蓝',
    fls: '禄',
    className: 'circle circleBlue'
  },
  9: {
    value: 9,
    color: '绿',
    fls: '禄',
    className: 'circle circleGreen'
  },
  10: {
    color: '红',
    value: 10,
    fls: '禄',
    className: 'circle circleRed'
  },
  11: {
    value: 11,
    color: '蓝',
    fls: '寿',
    className: 'circle circleBlue'
  },
  12: {
    value: 12,
    color: '绿',
    fls: '寿',
    className: 'circle circleGreen'
  },
  13: {
    color: '红',
    value: 13,
    fls: '寿',
    className: 'circle circleRed'
  },
  14: {
    value: 14,
    color: '蓝',
    fls: '寿',
    className: 'circle circleBlue'
  },
  15: {
    value: 15,
    color: '绿',
    fls: '寿',
    className: 'circle circleGreen'
  },
  16: {
    color: '红',
    value: 16,
    fls: '喜',
    className: 'circle circleRed'
  },
  17: {
    value: 17,
    color: '蓝',
    fls: '喜',
    className: 'circle circleBlue'
  },
  18: {
    value: 18,
    color: '绿',
    fls: '喜',
    className: 'circle circleGreen'
  },
  19: {
    color: '红',
    value: 19,
    fls: '喜',
    className: 'circle circleRed'
  },
  20: {
    value: 20,
    color: '蓝',
    fls: '喜',
    className: 'circle circleBlue'
  },
  21: {
    value: 21,
    color: '绿',
    className: 'circle circleGreen'
  }
};

const renderQiu = data => {
  if (data && !data.openResult) return;
  const result = data.openResult.split(',');
  return result.map(item => (
    {
      value: item, className: balls[item] ? balls[item].className : ''
    }
  ));
};

const renderZH = data => {
  if (data && !data.openResult) return;
  const result = data.openResult.split(',');
  const tempResult = [];
  let championSum = 0;
  result.forEach(item => (championSum += parseInt(item, 10)));
  tempResult.push({
    value: championSum
  });

  tempResult.push({
    value: championSum > 55 ? '大' : championSum < 55 ? '小' : '和',
    className: championSum > 55 ? 'redText' : '',
  });

  tempResult.push({
    value: championSum % 2 ? '单' : '双',
    className: championSum % 2 ? 'redText' : '',
  });

  tempResult.push({
    value: (championSum % 10) >= 5 ? '尾大' : '尾小',
    className: (championSum % 10) >= 5 ? 'redText' : '',
  });
  return tempResult;
};

const renderLH = data => {
  if (data && !data.openResult) return;
  const tempResult = [];
  const result = data.openResult.split(',');
  tempResult.push({
    value: ((parseInt(result[0], 10)) > parseInt(result[result.length - 1], 10)) ? '龙' : '虎',
    className: ((parseInt(result[0], 10)) > parseInt(result[result.length - 1], 10)) ? 'redText' : '',
  });
  return tempResult;
};

const GXK10 = {
  children: [
  ],
  resultTab: {
    headerCell: [{
      attr: '期数',
      wrapperStyle: 'gxk10Header'
    }, {
      attr: '日期',
      wrapperStyle: 'gxk10Header'
    }, {
      attr: '彩球号码',
      wrapperStyle: 'gxk10Header'
    }, {
      attr: '总和',
      wrapperStyle: 'gxk10Header'
    }, {
      attr: '龙虎',
      wrapperStyle: 'gxk10Header'
    }],
    bodyCell: [{
      attr: 'installments',
      wrapperStyle: 'd-flex justify-content-center align-items-center'
    }, {
      attr: renderResultDateCell,
      wrapperStyle: 'dateCol'
    }, {
      attr: renderQiu,
      wrapperStyle: 'd-flex justify-content-center align-items-center gxk10Num'
    }, {
      attr: renderZH,
      wrapperStyle: 'd-flex justify-content-center align-items-center gxk10ZH'
    }, {
      attr: renderLH,
      wrapperStyle: 'd-flex justify-content-center align-items-center gxk10LH'
    }],
  },
};

export default GXK10;