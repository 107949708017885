

import { Button, Col, Row } from 'reactstrap';
import { IoClose } from 'react-icons/io5';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Exclamation } from '../../../assets/image/icon_exclamation.svg';
import Label from '../../Label';
import { appActions } from '../../../redux/actions';
import './styles.scss';

const mapStateToProps = state => ({
  platform: state.appReducers.platform,
});

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
});

const ConfirmationModal = ({ updateModalAlert, data }) => {
  const { t } = useTranslation();
  return (
    <div className="confirmModalWrapperWeb">
      <Row className="modalHeader">
        <Col
          className={`${'d-flex justify-content-center align-items-center'}`}
          xs={{
            size: 4, offset: 4
          }}
        >
          {t('REMINDER')}
        </Col>
        <Col xs="4" className="d-flex justify-content-end align-items-center">
          <IoClose
            onClick={() => {
              updateModalAlert({
                visible: false
              });
              if (data && data.overBet && data.onConfirmationClick) data.onConfirmationClick();
            }}
            className="h4 mb-0 float-right closeIcon"
          />
        </Col>
      </Row>
      <Row className="modalContent">
        <Row className={`d-flex justify-content-center mx-0 ${'mb-4 mt-5'}`}>
          <Exclamation className="exclamationIcon" />
        </Row>
        <Row className={`d-flex justify-content-center mx-0 ${'mb-4'}`}>
          <Label className="confirmText" value={data && data.message} />
        </Row>
      </Row>
      <Row className="modalFooter d-flex align-items-center">
        <Button
          className={`confirmButton my-3 ${'me-3'}`}
          onClick={() => {
            updateModalAlert({
              visible: false
            });
            if (data && data.onConfirmationClick) data.onConfirmationClick();
          }}
        >
          {(data && data.confirmText) || t('CONFIRM')}
        </Button>
        {
          data && data.showCancelButton !== false && (
            <Button
              className={`cancelButton my-3 ${'me-3'}`}
              onClick={() => {
                updateModalAlert({
                  visible: false
                });
                if (data && data.onCancelClick) data.onCancelClick();
              }}
            >
              {(data && data.cancelText) || t('CANCEL')}
            </Button>
          )
        }
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationModal);