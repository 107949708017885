
const UserGameType = [
  {
    label: '正式',
    value: '0',
  },
  {
    label: '试玩',
    value: '1',
  }
];

const _betType = [
  {
    label: '全部', value: -1
  }, {
    label: '会员下注', value: 0
  }, {
    label: '总公司补货', value: 1
  }, {
    label: '公司补货', value: 2
  }, {
    label: '总代补货', value: 3
  }, {
    label: '一级补货', value: 4
  }, {
    label: '二级补货', value: 5
  }, {
    label: '三级补货', value: 6
  }, {
    label: '四级补货', value: 7
  }, {
    label: '五级补货', value: 8
  }, {
    label: '六级补货', value: 9
  }, {
    label: '七级补货', value: 10
  }, {
    label: '八级补货', value: 11
  }];

const UserBetStatus = [
  {
    label: '全部',
    value: '-1',
  },
  {
    label: '注销',
    value: '1',
  },
  {
    label: '结算',
    value: '2',
  },
  {
    label: '未结算',
    value: '3',
  }
];

const PagesList = [{
  label: '15条/页',
  value: 15,
}, {
  label: '20条/页',
  value: 20,
}, {
  label: '50条/页',
  value: 50,
}, {
  label: '100条/页',
  value: 100,
}];

const CommonSearchBy = [
  {
    label: '会员账号',
    value: 'username',
  },
  {
    label: '注单号',
    value: 'num',
  },
  {
    label: '金额',
    value: 'amount',
  },
  {
    label: '会员IP',
    value: 'ip',
  }, {
    label: '总公司', value: 1
  }, {
    label: '公司', value: 2
  }, {
    label: '总代', value: 3
  }, {
    label: '一级代理', value: 4
  }, {
    label: '二级代理', value: 5
  }, {
    label: '三级代理', value: 6
  }, {
    label: '四级代理', value: 7
  }, {
    label: '五级代理', value: 8
  }, {
    label: '六级代理', value: 9
  }, {
    label: '七级代理', value: 10
  }, {
    label: '八级代理', value: 11
  }
];

const AutoRestockStatus = [
  {
    label: '自动补货',
    value: 1,
  },
  {
    label: '手动补货',
    value: 0,
  }
];

const AccountStatus = [
  {
    label: '启用',
    value: '1',
  },
  {
    label: '暂停',
    value: '2',
  },
  {
    label: '停用',
    value: '0',
  },
  {
    label: '锁定',
    value: '3',
  }
];

const OrderBySelection = [
  {
    label: '账号',
    value: 'LoginId',
  },
  {
    label: '用户类型',
    value: 'MLevel',
  },
  {
    label: '余额',
    value: 'Balance',
  },
  {
    label: '创建日期',
    value: 'CreateDate',
  }
];

const OrderSelection = [
  {
    label: '降序排序',
    value: 'DESC',
  },
  {
    label: '升序排序',
    value: 'ASC',
  }
];

const UserSearchBy = [
  {
    label: '账号',
    value: 'username',
  },
  {
    label: 'IP',
    value: 'ip',
  }
];

const lotType = [
  {
    label: '高频彩',
    value: '2',
  },
  {
    label: '六合彩',
    value: '1',
  },
  {
    label: '低频彩',
    value: '0',
  },
];

const HK6ShortCut = [
  {
    label: '号码类（特码、正码、正码特）',
    value: 'HM',
  },
  {
    label: '两面类（特码两面、总肖单双等）',
    value: 'LM',
  },
  {
    label: '多项类（尾数、平特一肖尾数、正码1-6色波）',
    value: 'DuoX',
  },
  {
    label: '连码类（三中二、三全中、二全中等）',
    value: 'LMA',
  },
  {
    label: '其它（半半波特头尾、合肖总肖、连肖连尾等）',
    value: 'QiT',
  }
];

const nonHK6ShortCut = [
  {
    label: '号码类（球号、车号、正码等）',
    value: 'HM',
  },
  {
    label: '两面类（大小、单双、龙虎、三军等）',
    value: 'LM',
  },
  {
    label: '多项类（方位、中发白、总和过关等）',
    value: 'DuoX',
  },
  {
    label: '连码类（任选二、任选三、前二组选等）',
    value: 'LMA',
  },
  {
    label: '其它（冠亚和、前中后三等）',
    value: 'QiT',
  }
];


export {
  AccountStatus,
  AutoRestockStatus,
  UserGameType,
  UserBetStatus,
  CommonSearchBy,
  PagesList,
  OrderBySelection,
  OrderSelection,
  UserSearchBy,
  lotType,
  nonHK6ShortCut,
  HK6ShortCut,
  _betType
};