import { SET_AGENT_REBATE, SET_BANKS, SET_BUSINESS, SET_CHECK_SETTING, SET_MEMBER_INFO, SET_PRO_CODE, SET_THIRD_TOPUP_INFO, SET_TRANSFER_FLOW_DETAIL } from '../../actions/actionTypes';

const initialState = {
  banks: undefined,
  transferFlowsDetail: undefined,
  checkSetting: undefined,
  business: undefined,
  proCode: undefined,
  agentRebate: undefined,
  memberInfo: undefined,
  thirdTopUpInfo: undefined,
};

const cashReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_BANKS:
      return {
        ...state, banks: action.value
      };
    case SET_TRANSFER_FLOW_DETAIL:
      return {
        ...state, transferFlowsDetail: action.value
      };

    case SET_CHECK_SETTING:
      return {
        ...state, checkSetting: action.value
      };
    case SET_BUSINESS:
      return {
        ...state, business: action.value
      };
    case SET_PRO_CODE:
      return {
        ...state, proCode: action.value
      };
    case SET_AGENT_REBATE:
      return {
        ...state, agentRebate: action.value
      };
    case SET_MEMBER_INFO:
      return {
        ...state, memberInfo: action.value
      };
    case SET_THIRD_TOPUP_INFO:
      return {
        ...state, thirdTopUpInfo: action.value
      };

    default:
      return state;
  }
};

export default cashReducers;