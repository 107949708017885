import { direction, dragonTiger, huat, lianmaKL10, middle, white } from '../Items/others';
import { dxds, hdxds, totalSumDS, totalSumDX, totalSumtailDX, wdx } from '../Items/dsdx';

import { KL10balls, farmNumbers } from '../Items/numbers';

const CJ_KL10 = [{
  displayName: '第一球',
  isStaticRow: 1,
  midcode: 'B1M,B1LM,B1FW,B1ZFB',
  display: [[...KL10balls],
    [...dxds, ...wdx, ...hdxds.slice(0, 2), ...dragonTiger, ...direction, ...middle, ...huat, ...white]],
  displayForCQXYNC: [[...farmNumbers],
    [...dxds, ...wdx, ...hdxds.slice(0, 2), ...dragonTiger, ...direction, ...middle, ...huat, ...white]],
  tabId: 'count_HM_1',
}, {
  displayName: '第二球',
  isStaticRow: 1,
  midcode: 'B2M,B2LM,B2FW,B2ZFB',
  display: [[...KL10balls],
    [...dxds, ...wdx, ...hdxds.slice(0, 2), ...dragonTiger, ...direction, ...middle, ...huat, ...white]],
  displayForCQXYNC: [[...farmNumbers],
    [...dxds, ...wdx, ...hdxds.slice(0, 2), ...dragonTiger, ...direction, ...middle, ...huat, ...white]],
  tabId: 'count_HM_2',
}, {
  displayName: '第三球',
  isStaticRow: 1,
  midcode: 'B3HM,B3LM,B3FW,B3ZFB',
  display: [[...KL10balls],
    [...dxds, ...wdx, ...hdxds.slice(0, 2), ...dragonTiger, ...direction, ...middle, ...huat, ...white]],
  displayForCQXYNC: [[...farmNumbers],
    [...dxds, ...wdx, ...hdxds.slice(0, 2), ...dragonTiger, ...direction, ...middle, ...huat, ...white]],
  tabId: 'count_HM_3',
}, {
  displayName: '第四球',
  isStaticRow: 1,
  midcode: 'B4HM,B4LM,B4FW,B4ZFB',
  display: [[...KL10balls],
    [...dxds, ...wdx, ...hdxds.slice(0, 2), ...dragonTiger, ...direction, ...middle, ...huat, ...white]],
  displayForCQXYNC: [[...farmNumbers],
    [...dxds, ...wdx, ...hdxds.slice(0, 2), ...dragonTiger, ...direction, ...middle, ...huat, ...white]],
  tabId: 'count_HM_4',
}, {
  displayName: '第五球',
  isStaticRow: 1,
  midcode: 'B5HM,B5LM,B5FW,B5ZFB',
  display: [[...KL10balls],
    [...dxds, ...wdx, ...hdxds.slice(0, 2), ...direction, ...middle, ...huat, ...white]],
  displayForCQXYNC: [[...farmNumbers],
    [...dxds, ...wdx, ...hdxds.slice(0, 2), ...direction, ...middle, ...huat, ...white]],
  tabId: 'count_HM_5',
}, {
  displayName: '第六球',
  isStaticRow: 1,
  midcode: 'B6HM,B6LM,B6FW,B6ZFB',
  display: [[...KL10balls],
    [...dxds, ...wdx, ...hdxds.slice(0, 2), ...direction, ...middle, ...huat, ...white]],
  displayForCQXYNC: [[...farmNumbers],
    [...dxds, ...wdx, ...hdxds.slice(0, 2), ...direction, ...middle, ...huat, ...white]],
  tabId: 'count_HM_6',
}, {
  displayName: '第七球',
  isStaticRow: 1,
  midcode: 'B7HM,B7LM,B7FW,B7ZFB',
  display: [[...KL10balls],
    [...dxds, ...wdx, ...hdxds.slice(0, 2), ...direction, ...middle, ...huat, ...white]],
  displayForCQXYNC: [[...farmNumbers],
    [...dxds, ...wdx, ...hdxds.slice(0, 2), ...direction, ...middle, ...huat, ...white]],
  tabId: 'count_HM_7',
}, {
  displayName: '第八球',
  isStaticRow: 1,
  midcode: 'B8HM,B8LM,B8FW,B8ZFB',
  display: [[...KL10balls],
    [...dxds, ...wdx, ...hdxds.slice(0, 2), ...direction, ...middle, ...huat, ...white]],
  displayForCQXYNC: [[...farmNumbers],
    [...dxds, ...wdx, ...hdxds.slice(0, 2), ...direction, ...middle, ...huat, ...white]],
  tabId: 'count_HM_8',
}, {
  displayName: '总和大小',
  isStaticRow: 1,
  midcode: 'ZM',
  display: [[...KL10balls], [...totalSumDX, ...totalSumDS, ...totalSumtailDX]],
  displayForCQXYNC: [[...farmNumbers], [...totalSumDX, ...totalSumDS, ...totalSumtailDX]],
  firstTableHeader: ['正码', '总和'],
  tabId: 'count_ZHDX',
}, {
  displayName: '总和单双',
  isStaticRow: 1,
  midcode: 'ZM',
  display: [[...KL10balls], [...totalSumDX, ...totalSumDS, ...totalSumtailDX]],
  displayForCQXYNC: [[...farmNumbers], [...totalSumDX, ...totalSumDS, ...totalSumtailDX]],
  firstTableHeader: ['正码', '总和'],
  tabId: 'count_ZHDS',
}, {
  displayName: '总尾大小',
  isStaticRow: 1,
  midcode: 'ZM',
  display: [[...KL10balls], [...totalSumDX, ...totalSumDS, ...totalSumtailDX]],
  displayForCQXYNC: [[...farmNumbers], [...totalSumDX, ...totalSumDS, ...totalSumtailDX]],
  firstTableHeader: ['正码', '总和'],
  tabId: 'count_ZHDWDX',
}, {
  displayName: '正码',
  isStaticRow: 1,
  midcode: 'ZM',
  display: [[...KL10balls], [...totalSumDX, ...totalSumDS, ...totalSumtailDX]],
  displayForCQXYNC: [[...farmNumbers], [...totalSumDX, ...totalSumDS, ...totalSumtailDX]],
  firstTableHeader: ['正码', '总和'],
  tabId: 'count_ZM',
}, {
  displayName: '连码直选',
  settingCode: 'LIANMA',
  horizontalDataAlign: 1,
  horizontalSet: 2,
  display: [...lianmaKL10],
  tabId: 'count_LMARX2',
},
];

export default CJ_KL10;