import { useEffect, useState } from 'react';

import { Button, FormGroup, Input, Label } from 'reactstrap';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Spin } from 'antd';
import { USER_LOGOUT } from '../../redux/actions/actionTypes';
import { store } from '../../redux';

import { agentActions, appActions, commonActions, memberActions } from '../../redux/actions';
import ModalAlert from '../../components/ModalAlert';
import { authWebRoutes } from '../../routes';
import './styles.scss';

const mapStateToProps = state => ({
  userData: state.memberReducers.userData,
  captchaData: state.memberReducers.captchaData,
  mfaSetting: state.memberReducers.mfaSetting,
  activeLottery: state.commonReducers.activeLottery,
  themeColor: state.appReducers.themeColor,
});

const mapDispatchToProps = dispatch => ({
  login: (data, callback) => dispatch(memberActions.login(data, callback)),
  getCaptcha: () => dispatch(memberActions.getCaptcha()),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  setCrossLogin: data => dispatch(agentActions.setCrossLogin(data)),
  getCrossAgentLogin: (data, callback) => dispatch(commonActions.getCrossAgentLogin(data, callback)),
  setMFASetting: status => dispatch(memberActions.setMFASetting(status)),
});

const Login = ({ platform = 'Web', setMFASetting, themeColor, getCrossAgentLogin, setCrossLogin, login, userData, activeLottery, history, captchaData, getCaptcha, mfaSetting, updateModalAlert }) => {
  const [_username, setUsername] = useState('');
  const [_password, setPassword] = useState('');
  const [_mfa, setMfa] = useState('');
  const [_captcha, setCaptcha] = useState('');
  const [captchaImage, setCaptchaImage] = useState('');
  const { t } = useTranslation();
  const data = useLocation();
  const [params, setparams] = useState(data?.search === '' ? '' : new URLSearchParams(data.search));

  const pageType = data?.search === '' ? 'login' : 'loading';


  const onClickLogin = () => {
    const loginData = {
      username: _username,
      password: _password,
      code: _captcha,
      mfacode: _mfa
    };
    login(loginData, data => {
      if (data && data?.checkPassword === true) {
        return updateModalAlert({
          visible: true,
          type: 'changePassword',
        });
      }
    });
  };

  useEffect(() => {
    document.addEventListener('keydown', keyDownHandler, false);
    return () => {
      document.removeEventListener('keydown', keyDownHandler, false);
    };
  }, [_username, _password, _captcha, _mfa]);

  useEffect(() => {
    if (userData && userData?.checkPassword === true) {
      return;
    }
    if (userData && activeLottery && params === '') {
      let tempList = userData.userMenu;
      if (userData?.status === 2) {
        tempList = tempList.filter(x => (x.id === '3' || x.id === '6' || x.id === '8'));
      }
      const item = [].concat(tempList).find(ele => authWebRoutes.find(x => x.id === ele.id)) || {
        id: 'logout'
      };

      const afterLoginUrl = authWebRoutes.find(x => x.id === item.id)?.path || '';
      updateModalAlert({
        visible: true,
        type: 'latestAnnouncement',
      });
      history.push(`/agent${afterLoginUrl}`);
    }
    if (params !== '') {
      store.dispatch({
        type: USER_LOGOUT
      });
      const uid = params.get('uid');
      const loginId = params.get('loginId');
      const data = params.get('data');
      const obj = {
        uid, loginId, data
      };
      setCrossLogin(obj);
      getCrossAgentLogin(obj, () => setparams(''));
    }
  }, [userData, activeLottery]);

  useEffect(() => {
    if (captchaData && captchaData.base64) {
      setCaptchaImage(`data:image/jpg;base64,${captchaData.base64}`);
    } else {
      getCaptcha();
    }
  }, [captchaData]);

  const onCaptchaClick = () => {
    getCaptcha();
  };

  function keyDownHandler(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      onClickLogin();
    }
  }

  return (
    <div className={`loginRegisterWarpper ${platform} theme-${themeColor}`}>
      <div className={`loginAndRegister ${themeColor}`}>
        {pageType === 'loading' && <Spin size="large" tip="请稍等，正在跳转中..." />}
        {
          pageType === 'login'
          && (platform === 'Web' ? (
            <div className="loginBox">
              <div>
                <div className="loginTitle">
                  <span>{t('EMAIL_LOGIN')}</span>
                  <span onClick={() => setMFASetting(!mfaSetting)}>索取</span>
                </div>
                <div className="loginBoxItem">
                  <div>{t('ACCOUNT')}</div>
                  <Input value={_username} placeholder="请输入您的账号" onChange={({ target: { value: text } }) => setUsername(text)} />
                </div>
                <div className="loginBoxItem">
                  <div>{`${t('PASSWORD')}`}</div>
                  <Input type="password" placeholder="请输入您的密码" value={_password} onChange={({ target: { value: text } }) => setPassword(text)} />
                </div>
                <div className="loginBoxItem">
                  <div>{`${t('CAPTCHA')}`}</div>
                  <Input placeholder="请输入验证码" value={_captcha} onChange={({ target: { value: text } }) => setCaptcha(text)} />
                  <div className="border-0 w-25" onClick={() => onCaptchaClick()}>
                    <img id="captchaImg" src={captchaImage} alt="" className="w-100" />
                  </div>
                </div>
                <div className={`loginBoxItem ${mfaSetting ? 'mfaOpen' : 'mfaClosed'}`}>
                  <div>二次验证码</div>
                  <Input placeholder="二次验证码" value={_mfa} onChange={({ target: { value: text } }) => setMfa(text)} />
                </div>

              </div>
              <div className="loginBoxItem">
                <Button
                  className="loginButton"
                  onClick={() => {
                    onClickLogin();
                  }}
                >
                  {t('LOGIN')}
                </Button>
              </div>
            </div>
          )
            : (
              <div className="mobileLogin w-100">
                <div className="py-3 px-4">
                  <div className="w-100 d-flex">
                    <span className="w-25" />
                    <span className="w-50 text-center loginTitle">{t('EMAIL_LOGIN')}</span>
                    <span className="w-25 d-flex justify-content-center align-items-center" onClick={() => setMFASetting(!mfaSetting)}>
                      <div className="loginMFA">索取</div>
                    </span>
                  </div>
                  <FormGroup className="my-3">
                    <strong className="labelLine">|</strong>
                    <Label for="Account" className="loginLabelText">
                      {t('ACCOUNT')}
                    </Label>
                    <Input value={_username} placeholder="请输入您的账号" onChange={({ target: { value: text } }) => setUsername(text)} />
                  </FormGroup>
                  <FormGroup className="my-3">
                    <strong className="labelLine">|</strong>
                    <Label for="Password" className="loginLabelText">
                      {t('PASSWORD')}
                    </Label>
                    <Input type="password" placeholder="请输入您的密码" value={_password} onChange={({ target: { value: text } }) => setPassword(text)} />
                  </FormGroup>
                  <FormGroup className="my-3">
                    <strong className="labelLine">|</strong>
                    <Label for="Password" className="loginLabelText">
                      验证码
                    </Label>
                    <div className="d-flex align-items-center justify-content-between">
                      <Input placeholder="请输入验证码" className="w-75" value={_captcha} onChange={({ target: { value: text } }) => setCaptcha(text)} />
                      <div className="border-0 w-25" onClick={() => onCaptchaClick()}>
                        <img id="captchaImg" src={captchaImage} alt="" className="w-100" />
                      </div>
                    </div>
                  </FormGroup>
                  <FormGroup className={`mobileLoginBoxItem ${mfaSetting ? 'mfaOpen' : 'mfaClosed'}`}>
                    <strong className="labelLine">|</strong>
                    <Label for="Password" className="loginLabelText">
                      二次验证码*
                    </Label>
                    <Input placeholder="二次验证码" value={_mfa} onChange={({ target: { value: text } }) => setMfa(text)} />
                  </FormGroup>
                  <div className=" d-flex py-3 justify-content-center">
                    <Button type="submit" className="loginConfirmBtn w-100" size="lg" block onClick={onClickLogin}>{t('LOGIN')}</Button>
                  </div>
                </div>
              </div>

            )
          )
        }
        <ModalAlert />
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);