

import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { appActions, commonActions, memberActions } from '../../redux/actions';
import './styles.scss';


const mapStateToProps = state => ({
  userData: state.memberReducers.userData,
  themeColor: state.appReducers.themeColor,
});

const mapDispatchToProps = dispatch => ({
  logout: (loginId, callback) => dispatch(memberActions.logout(loginId, callback)),
  updateThemeColor: themeColor => dispatch(appActions.updateThemeColor(themeColor)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  updateSelectedSubMenu: val => dispatch(commonActions.updateSelectedSubMenu(val)),
  setClickReloadFlag: data => dispatch(appActions.setClickReloadFlag(data)),
});

const HeaderMenu = ({ userData, menulist, history, logout, updateModalAlert, updateSelectedSubMenu, setClickReloadFlag }) => {
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  // const themeColorList = ['blue', 'red', 'green', 'yellow'];

  return (
    <div className="headerMenu">
      <div className="menuWrapper">
        {
          menulist.map(item => (
            <div
              key={`menuitem_${item.label}`}
              className={`menuItem ${path === item.url ? 'selected' : ''}`}
              onClick={() => {
                if (item.url === '/agent/logout') {
                  updateModalAlert({
                    type: 'confirmation',
                    visible: true,
                    data: {
                      message: '确定要退出登录吗?',
                      onConfirmationClick: () => logout(userData.loginId, () => history.push('/')),
                      showCancelButton: true
                    }
                  });
                } else {
                  setClickReloadFlag(+new Date());
                  if (window.location.pathname !== item.url) { updateSelectedSubMenu(); } history.push(item.url);
                }
              }}
            >
              <span className="">{t(item.label.toUpperCase())}</span>
            </div>
          ))
        }
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderMenu);