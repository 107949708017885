import { renderResultDateCell } from '../../utils';


const generateLabel = item => {
  let outputString = '';
  if (item < 14) outputString += '小';
  else outputString += '大';
  if (item % 2) outputString += '单';
  else outputString += '双';
  if (item === 13 || item === 14) outputString += '和';
  return outputString;
};

const renderQiu = data => {
  if (data && !data.openResult) return;
  const result = data.openResult.split(',');
  return result.map(item => (
    {
      value: item, className: 'circle circleBlue'
    }
  ));
};

const ZH = data => {
  if (data && !data.openResult) return;
  const result = data.openResult.split(',');
  const tempResult = [];
  let championSum = 0;
  result.forEach(item => (championSum += parseInt(item === '' ? 0 : item, 10)));
  tempResult.push({
    value: '龙'
  });
  tempResult.push({
    value: championSum === 14 ? '大和' : championSum === 13 ? '小和' : championSum > 14 ? '大' : '小',
    className: championSum === 14 ? 'greenText' : championSum === 13 ? 'greenText' : championSum > 14 ? 'redText' : '',
  });
  tempResult.push({
    value: championSum === 14 ? '双和' : championSum === 13 ? '单和' : championSum % 2 ? '单' : '双',
    className: championSum === 14 ? 'greenText' : championSum === 13 ? 'greenText' : championSum % 2 ? 'redText' : '',
  });
  return tempResult;
};

const renderJZ = data => {
  if (data && !data.openResult) return;
  const result = data.openResult.split(',');
  const tempResult = [];
  let championSum = 0;
  result.forEach(item => (championSum += parseInt(item === '' ? 0 : item, 10)));
  tempResult.push({
    value: championSum > 21 ? '极大' : championSum < 6 ? '极小' : '--',
    className: championSum > 21 ? 'redText' : championSum < 6 ? 'blueText' : '',
  });
  return tempResult;
};

const renderZH = data => {
  if (data && !data.openResult) return;
  const result = data.openResult.split(',');
  const tempResult = [];
  let championSum = 0;
  result.forEach(item => (championSum += parseInt(item === '' ? 0 : item, 10)));
  tempResult.push({
    value: generateLabel(championSum),
    className: generateLabel(championSum).includes('和') ? 'greenText' : generateLabel(championSum).includes('大') ? 'redText' : 'blueText',
  });
  return tempResult;
};

const renderBaoZi = data => {
  if (data && !data.openResult) return;
  const result = data.openResult.split(',');
  const tempResult = [];
  tempResult.push({
    value: result[0] === result[1] && result[1] === result[2] ? '豹子' : '--',
    className: result[0] === result[1] && result[1] === result[2] ? 'redText' : '',
  });
  return tempResult;
};

const PCDD = {
  children: [
  ],
  resultTab: {
    headerCell: [{
      attr: '期数',
      wrapperStyle: 'pk10Header'
    }, {
      attr: '日期',
      wrapperStyle: 'pk10Header'
    }, {
      attr: '彩球号码',
      wrapperStyle: 'pk10Header'
    }, {
      attr: '总和',
      wrapperStyle: 'pk10Header'
    }, {
      attr: '极值',
      wrapperStyle: 'pk10Header'
    }, {
      attr: '组合',
      wrapperStyle: 'pk10Header'
    }, {
      attr: '豹子',
      wrapperStyle: 'pk10Header'
    }],
    bodyCell: [{
      attr: 'installments',
      wrapperStyle: 'd-flex justify-content-center align-items-center'
    }, {
      attr: renderResultDateCell,
      wrapperStyle: 'dateCol'
    }, {
      attr: renderQiu,
      wrapperStyle: 'd-flex justify-content-center align-items-center pk10Num'
    }, {
      attr: ZH,
      wrapperStyle: 'd-flex justify-content-center align-items-center pk10GYH'
    }, {
      attr: renderJZ,
      wrapperStyle: 'd-flex justify-content-center align-items-center pk10LH'
    }, {
      attr: renderZH,
      wrapperStyle: 'd-flex justify-content-center align-items-center pk10LH'
    }, {
      attr: renderBaoZi,
      wrapperStyle: 'd-flex justify-content-center align-items-center pk10LH'
    }],
  },
};

export default PCDD;