import { useEffect, useState } from 'react';

import { Input, Radio } from 'antd';
import { IoClose } from 'react-icons/io5';
import { Row } from 'reactstrap';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { agentActions, appActions } from '../../../redux/actions';

import './styles.scss';

const mapDispatchToProps = dispatch => ({
  ExtractAccountQuota: (obj, callback) => dispatch(agentActions.ExtractAccountQuota(obj, callback)),
  SetAccountQuota: (obj, callback) => dispatch(agentActions.SetAccountQuota(obj, callback)),
  GetAccountQuota: (obj, callback) => dispatch(agentActions.GetAccountQuota(obj, callback)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
});

const BalanceEditModal = ({ ExtractAccountQuota, SetAccountQuota, GetAccountQuota, updateModalAlert, data }) => {
  const { t } = useTranslation();
  const [selectedItem, setSelectedItem] = useState({});
  useEffect(() => {
    GetAccountQuota(
      {
        userId: data.type === 1 ? data.memberId : data.orgId,
        type: data.type || 2, // 默认为2 代理
        accType: data?.balanceItem?.accType,
      },
      res => {
        setSelectedItem({
          ...res,
          modal_type: 0,
          modal_amount: ''
        });
      }
    );
  }, [data]);
  return (
    <div className="BalanceEditWrapperWeb">
      <Row className="modalHeader">
        <div className={`${'d-flex justify-content-center align-items-center'}`}>
          {`${data.loginId} ${data?.balanceItem?.accType === 3 ? '第三方' : ''}额度修改`}
        </div>
        <div className="icon">
          <IoClose
            onClick={() => {
              updateModalAlert({
                visible: false
              });
              if (data && data.overBet && data.onConfirmationClick) data.onConfirmationClick();
            }}
            className="h4 mb-0 float-right closeIcon"
          />
        </div>

      </Row>
      <Row className="modalContent">
        <table className="_table agentDataTable p-3 m-0" cellSpacing="0" cellPadding="0" border="0">
          <tbody>
            <tr>
              <td className="tableHeader col-2">账号</td>
              <td>
                <div className="d-flex p-0 ">
                  {
                    selectedItem.loginId
                  }
                </div>
              </td>
            </tr>
            {
              selectedItem?.credictType === 1 && (
                <>
                  <tr>
                    <td className="tableHeader col-2">信用额度</td>
                    <td>
                      <div className="d-flex p-0 align-items-center ">
                        <div>{selectedItem.credict}</div>
                        <div
                          className="mx-3 confirmButton"
                          onClick={() => {
                            updateModalAlert({
                              visible: true,
                              type: 'confirmation',
                              showCancelButton: true,
                              data: {
                                message: '是否确定抽取额度吗？',
                                onConfirmationClick: () => {
                                  ExtractAccountQuota(
                                    {
                                      userId: data.type === 1 ? data.memberId : data.orgId,
                                      type: data.type || 2,
                                      accType: selectedItem?.accType
                                    },
                                    () => {
                                      if (data && data.onConfirmationClick) {
                                        data.onConfirmationClick();
                                      }
                                    }
                                  );
                                }
                              }
                            });
                          }}
                        >
                          提现全部额度
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="tableHeader col-2">当前余额</td>
                    <td>
                      <div className="d-flex p-0 ">
                        <div>{selectedItem.leftCredict}</div>
                      </div>
                    </td>
                  </tr>
                </>
              )
            }
            {
              selectedItem?.credictType !== 1
              && selectedItem?.userType === 2
              && (
                <tr>
                  <td className="tableHeader col-2">现金额度</td>
                  <td>
                    <div className="d-flex p-0 align-items-center ">
                      <div>{selectedItem.leftCredict}</div>
                      <div
                        className="mx-3 confirmButton"
                        onClick={() => {
                          updateModalAlert({
                            visible: true,
                            type: 'confirmation',
                            showCancelButton: true,
                            data: {
                              message: '是否确定抽取额度吗？',
                              onConfirmationClick: () => {
                                ExtractAccountQuota(
                                  {
                                    userId: data.type === 1 ? data.memberId : data.orgId,
                                    type: data.type || 2,
                                    accType: selectedItem?.accType
                                  },
                                  () => {
                                    if (data && data.onConfirmationClick) {
                                      data.onConfirmationClick();
                                    }
                                  }
                                );
                              }
                            }
                          });
                        }}
                      >
                        提现全部额度
                      </div>
                    </div>
                  </td>
                </tr>
              )
            }
            <tr>
              <td className="tableHeader col-2">上级可用余额</td>
              <td>
                <div className="d-flex p-0 rdSearchOptionsGroup">
                  <div>{selectedItem.upAgentLeftCredict}</div>
                </div>
              </td>
            </tr>
            <tr>
              <td className="tableHeader col-2">类型</td>
              <td>
                <div className="d-flex p-0 rdSearchOptionsGroup">
                  {
                    [{
                      label: '加额(+)', value: 0
                    }, {
                      label: '减额(-)', value: 1
                    }].map(d => (
                      <div key={`rdMultiPlateGroup_${d.value}`}>
                        <Radio
                          checked={selectedItem.modal_type === d.value}
                          onClick={() => setSelectedItem({
                            ...selectedItem, modal_type: d.value
                          })}
                        >
                          {d?.label}
                        </Radio>
                      </div>
                    ))
                  }
                </div>
              </td>
            </tr>
            <tr>
              <td className="tableHeader col-2">金额</td>
              <td>
                <div className="d-flex p-0 ">
                  <Input
                    type="number"
                    value={selectedItem?.modal_amount}
                    onChange={e => {
                      setSelectedItem({
                        ...selectedItem, modal_amount: e.target.value
                      });
                    }}
                  />
                </div>
              </td>
            </tr>

          </tbody>
        </table>
      </Row>
      <Row className="modalFooter d-flex align-items-center">
        <div
          className="confirmButton my-3"
          onClick={() => {
            SetAccountQuota(
              {
                userId: data.type === 1 ? data.memberId : data.orgId,
                userType: data.type === 1 ? 1 : 2,
                accType: selectedItem.accType,
                updateType: selectedItem.modal_type,
                value: selectedItem.modal_amount
              },
              () => {
                if (data && data.onConfirmationClick) {
                  data.onConfirmationClick();
                }
              }
            );
          }}

        >
          {(data && data.confirmText) || t('CONFIRM')}
        </div>
      </Row>
    </div>
  );
};

export default connect(null, mapDispatchToProps)(BalanceEditModal);