/* eslint-disable react/destructuring-assignment */
import { useEffect, useState } from 'react';

import { Button, Col, Label, Row } from 'reactstrap';
import { IoClose } from 'react-icons/io5';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { agentActions, appActions } from '../../../redux/actions';
import AgentDataTable from '../../AgentDataTable';

import './styles.scss';

const mapStateToProps = state => ({
  prevOddDiffData: state.agentReducers.prevOddDiffData,
});

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  getPreviewOddsDifference: obj => dispatch(agentActions.getPreviewOddsDifference(obj)),
});

const PreviewOddDiffModal = ({ updateModalAlert, data, getPreviewOddsDifference, prevOddDiffData }) => {
  const { t } = useTranslation();
  const [_detailItem, setDetailItem] = useState();

  useEffect(() => {
    getPreviewOddsDifference(data.thisData);
  }, [data]);

  useEffect(() => {
    if (prevOddDiffData) {
      setDetailItem(prevOddDiffData);
    }
  }, [prevOddDiffData]);

  const tableHeader = [{
    label: t('NUMBER'),
  }].concat((data?.memberGame ? (data.memberGame + data.memberGame) : 'AABBCCDD').split('').sort().map((item, index) => (index % 2 === 0 ? ({
    label: `${item + t('PLATE') + t('REBATE')}`
  }) : ({
    label: `${item + t('PLATE') + t('ODDS')}`
  }))));

  const tableContent = [
    currentData => (<Label className="d-flex justify-content-center">{`${currentData?.midType} ${currentData?.displayName}`}</Label>),
  ].concat(
    (data?.memberGame ? (data.memberGame + data.memberGame) : 'AABBCCDD').split('').sort().map((item, index) => currentData => {
      const temp = index % 2 === 0
        ? data?.thisData[`${String(item).toLowerCase()}Return`] ?? ''
        : currentData[`odds${item}`] ?? '';
      return (
        <Label className="d-flex justify-content-center">{temp}</Label>
      );
    })
  );

  return (
    <div className="prevOddDiffModalWrapperWeb">
      <Row className="modalHeader">
        <Col
          className={`${'d-flex align-items-center'}`}
        >
          预览赔率
        </Col>
        <Col className="d-flex justify-content-end align-items-center">
          <IoClose
            onClick={() => {
              updateModalAlert({
                visible: false
              });
              if (data && data.overBet && data.onConfirmationClick) data.onConfirmationClick();
            }}
            className="h4 mb-0 float-right closeIcon"
          />
        </Col>
      </Row>
      <Row className="modalContent d-flex justify-content-center">
        <Row className="p-2 justify-content-center">
          {
            data && cancelAnimationFrame && data?.thisData?.id && (
              <>
                <Row className="border d-flex p-2 m-0 justify-content-center">
                  当前输入:&nbsp;&nbsp;
                  {data?.memberGame.includes('A') && (
                    <>
                      &nbsp;&nbsp;&nbsp;A盘退水：&nbsp;
                      {data?.thisData?.aReturn}
                    </>
                  )}
                  {data?.memberGame.includes('B') && (
                    <>
                      &nbsp;&nbsp;&nbsp;B盘退水：&nbsp;
                      {data?.thisData?.bReturn}
                    </>
                  )}
                  {data?.memberGame.includes('C') && (
                    <>
                      &nbsp;&nbsp;&nbsp;C盘退水：&nbsp;
                      {data?.thisData?.cReturn}
                    </>
                  )}
                  {data?.memberGame.includes('D') && (
                    <>
                      &nbsp;&nbsp;&nbsp;D盘退水：&nbsp;
                      {data?.thisData?.dReturn}
                    </>
                  )}
                </Row>
                <Row className="drawhalfbox d-flex p-2 m-0 justify-content-center">
                  {data?.memberGame.includes('A') && (
                    <>
                      A盘赔率差：&nbsp;
                      {data?.thisData?.diffOddsA}
                    </>
                  )}
                  {data?.memberGame.includes('B') && (
                    <>
                      &nbsp;&nbsp;&nbsp;B盘赔率差：&nbsp;
                      {data?.thisData?.diffOddsB}
                    </>
                  )}
                  {data?.memberGame.includes('C') && (
                    <>
                      &nbsp;&nbsp;&nbsp;C盘赔率差：&nbsp;
                      {data?.thisData?.diffOddsC}
                    </>
                  )}
                  {data?.memberGame.includes('D') && (
                    <>
                      &nbsp;&nbsp;&nbsp;D盘赔率差：&nbsp;
                      {data?.thisData?.diffOddsD}
                    </>
                  )}
                  &nbsp;&nbsp;预览情况如下
                </Row>
              </>
            )
          }
        </Row>
        <Row className="tableWrapper d-flex p-2 justify-content-center">
          <AgentDataTable
            tableHeaderTitle={tableHeader}
            tableData={_detailItem}
            tableBodyRowProperty={tableContent}
          />
        </Row>
      </Row>
      <Row className="modalFooter d-flex align-items-center">
        <Button
          className={`confirmButton my-2 ${'me-3'}`}
          onClick={() => {
            updateModalAlert({
              visible: false
            });
            if (data && data.onConfirmationClick) data.onConfirmationClick();
          }}
        >
          {(data && data.confirmText) || t('CONFIRM')}
        </Button>
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PreviewOddDiffModal);