/* eslint-disable react/jsx-wrap-multilines */

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { CommonHeader } from '../../components';
import CompanyGameReport from '../../components/ReportManagement/CompanyGameReport';
import WinLostReport from '../../components/ReportManagement/WinLostReport';
import './styles.scss';

const mapStateToProps = state => ({
  selectedSubMenu: state.commonReducers.selectedSubMenu,
});

const ReportManagement = ({ selectedSubMenu }) => {
  const { t } = useTranslation();
  return (

    <div className="reportManagementWrapper p-3">
      {selectedSubMenu === 'RM_WIN_LOSE_REPORT' && (
        <WinLostReport />
      )}
      {selectedSubMenu === 'RM_MEMBER_GAME_REPORT' && (
      <>
        <CommonHeader
          headerName={selectedSubMenu ? t(selectedSubMenu.slice(3)) : ''}
        />
        <CompanyGameReport startLevel={1} />
      </>)}
      {selectedSubMenu === 'RM_COMPANY_GAME_REPORT' && (
      <>
        <CommonHeader
          headerName={selectedSubMenu ? t(selectedSubMenu.slice(3)) : ''}
        />
        <CompanyGameReport />
      </>)}
    </div>
  );
};

export default connect(mapStateToProps, null)(ReportManagement);