/* eslint-disable indent */
import { useEffect, useState } from 'react';

import { Button, DatePicker, Input, Select } from 'antd';
import { connect } from 'react-redux';

import { useTranslation } from 'react-i18next';

import moment from 'moment';

import { agentActions, appActions, commonActions, reportActions } from '../../../../redux/actions';

import { AgentDataTable } from '../../..';
import './styles.scss';

// import Notifications from '../../../Content/Notifications';

const mapStateToProps = state => ({
  activeLottery: state.commonReducers.activeLottery,
  agentListData: state.agentReducers.agentListData,
  userData: state.memberReducers.userData,
  userInfo: state.memberReducers.userInfo,
  downlineAgentList: state.commonReducers.downlineAgentList,
});

const mapDispatchToProps = dispatch => ({
  CashCheckUp: (obj, callback, catchback) => dispatch(reportActions.CashCheckUp(obj, callback, catchback)),
  GetDownlineAgentList: (obj, callback, catchback) => dispatch(commonActions.GetDownlineAgentList(obj, callback, catchback)),
  deleteUser: (obj, callback, catchback) => dispatch(agentActions.deleteUser(obj, callback, catchback)),
  getAgentList2: (obj, callback, catchback) => dispatch(agentActions.getAgentList2(obj, callback, catchback)),
  getAgentList: (agentId, levelValue, orderBy, order, page, rows, startDate, endDate, isChild, loginId, status, searchBy, minAmount, maxAmount) => dispatch(agentActions.getAgentList(agentId, levelValue, orderBy, order, page, rows, startDate, endDate, isChild, loginId, status, searchBy, minAmount, maxAmount)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  setAccountStatus: (obj, searchObj) => dispatch(agentActions.setAccountStatus(obj, searchObj)),
  setAccountStatus2: (obj, callback) => dispatch(agentActions.setAccountStatus2(obj, callback)),
  setDefaultAgent: (obj, searchObj) => dispatch(agentActions.setDefaultAgent(obj, searchObj)),
  setDefaultVisitorAgent: (obj, searchObj) => dispatch(agentActions.setDefaultVisitorAgent(obj, searchObj)),
  getLoginAs: (obj, callback) => dispatch(agentActions.getLoginAs(obj, callback)),
});

const JiaoDui = ({ data, CashCheckUp, userType = 1, }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({
    loginId: data?.loginId,
    accountType: 2,
    userType,
    checkDate: moment().startOf('day')
  });
  const [res, setRes] = useState({}); // 表格数据 + 分页数据


  const getInfo = async (params = {}) => {
    CashCheckUp(
      {
        loginId: filter.loginId,
        userType: filter.userType, // 会员
        accountType: filter.accountType,
        checkDate: filter.checkDate.unix(),
        ...params,
      },
      res => {
        setRes(res || {});
        setLoading(false);
      },
      () => setLoading(false)
    );
  };

  useEffect(() => {
    getInfo();
  }, []);

  return (
    <div className="JiaoDuiWrapper1">
      <div className="header p-2 mb-2 filterWrapper d-flex align-items-center w-100 rounded-top">
        <div className="filterArea">
          <div>
            <span className="mx-1">日期</span>
            <DatePicker
              value={moment(filter.checkDate)}
              onChange={v => {
                setFilter({
                  ...filter,
                  checkDate: moment(v).startOf('day')
                });
              }}
            />
          </div>
          <div>
            <span className="mx-1">类型</span>
            <Select
              className="w-select"
              value={filter.userType}
              onChange={value => {
                setFilter({
                  ...filter, userType: value
                });
              }}
              options={[{
                label: '请选择', value: ''
              }, {
                label: '代理', value: 2
              }, {
                label: '会员', value: 1
              },]}
            />
          </div>
          <div className="d-flex align-items-center ">
            <span className="mx-1">账号</span>
            <Input
              className="mx-1 w-input-100"
              value={filter.loginId}
              onChange={e => (setFilter({
                ...filter, loginId: e.target.value
              }))}
            />
          </div>
          <div>
            <Button
              className="searchButton marginLeft5 me-2"
              onClick={() => {
                getInfo();
              }}
            >
              {t('FIND')}
            </Button>
          </div>
        </div>
        {/* <Notifications /> */}
      </div>

      <div className="whiteListMenu">
        {
          [{
            label: '低频彩额度', value: 2
          }, {
            label: '高频彩额度', value: 0
          }, {
            label: '第三方额度', value: 3
          }].map(item => {
            const { label, value } = item;
            return (
              <div
                className={`menu_item ${filter.accountType === value && 'selected'}`}
                onClick={() => {
                  if (value === filter.accountType) return;
                  setFilter({
                    ...filter, accountType: value
                  });
                  getInfo({
                    accountType: value
                  });
                }}
              >
                {label}
              </div>
            );
          })
        }
      </div>

      <div className="mb-2">
        <AgentDataTable
          tableHeaderTitle={[{
            label: '账号',
          }, {
            label: '日期',
          }, {
            label: '起始金额',
          }]}
          tableBodyRowProperty={[
            () => (<span>{filter.loginId}</span>), // 传进来的 data
            () => (<span>{((res?.lists || [])[0] || {})?.date}</span>), // 传进来的 data
            () => (<span>{((res?.lists || [])[0] || {})?.start || 0}</span>), // 传进来的 data
          ]}
          tableData={[1]} // 占位
        />
      </div>

      <div>
        <AgentDataTable
          tableHeaderTitle={[{
            label: '日期',
          }, {
            label: '总下注金额',
          }, {
            label: '未结算',
          }, {
            label: '已结算结果',
          }, {
            label: '充值',
          }, {
            label: '提款',
          }, {
            label: '今日输赢',
          }]}
          tableBodyRowProperty={[
            data => (<span>{data?.date || 0}</span>),
            data => (<span>{data?.total || 0}</span>),
            data => (<span>{data?.uncheck || 0}</span>),
            data => (<span>{data?.restore || 0}</span>),
            data => (<span>{data?.recharge || 0}</span>),
            data => (<span>{data?.extract || 0}</span>),
            data => (<span>{data?.winlost || 0}</span>),
          ]}
          tableFooter={[
            [
              {
                value: '合计',
              },
              {
                value: res?.sum?.sumTotal || 0,
              },
              {
                value: res?.sum?.sumUnCheck || 0,
              },
              {
                value: res?.sum?.sumRestore || 0,
              },
              {
                value: res?.sum?.sumRecharge || 0,
              },
              {
                value: res?.sum?.sumExtract || 0,
              },
              {
                value: res?.sum?.sumWinlost || 0,
              },
            ],
            [
              {
                value: `理论余额: ${((res?.lists || [])[res?.lists?.length - 1] || {})?.amount || 0} / 当前余额: ${((res?.lists || [])[res?.lists?.length - 1] || {})?.end || 0} / 误差: ${((res?.lists || [])[res?.lists?.length - 1] || {})?.diff || 0}`,
                colspan: 7
              }
            ]
          ]}
          isQuerying={loading}
          tableData={res?.lists || []}
        />
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(JiaoDui);