
import { Button, DatePicker, Input, Select } from 'antd';
import { Col, Label, Row } from 'reactstrap';


import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { appActions, commonActions, systemActions } from '../../../redux/actions';

import AgentDataTable from '../../AgentDataTable';
import Notifications from '../../Content/Notifications';
import Pagination from '../../Content/Pagination';
import { exportCSV } from '../../../constants/utils';

import './styles.scss';

const mapStateToProps = state => ({
  memberAmountHistoryData: state.systemReducers.memberAmountHistoryData,
  accountType: state.commonReducers.accountType,
});

const mapDispatchToProps = dispatch => ({
  GetMemberAmountHistory: (obj, callback, catchback) => dispatch(systemActions.GetMemberAmountHistory(obj, callback, catchback)),
  GetAccountType: () => dispatch(commonActions.GetAccountType()),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
});

const { RangePicker } = DatePicker;

const MemberAmountHistory = ({ accountType = [], GetAccountType, memberAmountHistoryData, GetMemberAmountHistory }) => {
  const { t } = useTranslation(); // i18n
  const [_isQueryingDetail, setIsQueryingDetail] = useState([]); // loading
  const [_detailItem, setDetailItem] = useState([]); // table data
  const [_detailItemSum, setDetailItemSum] = useState([]); // table data sum
  const [_date, setDate] = useState([moment().startOf('day'), moment().startOf('day')]); // filter date
  const [dates, setDates] = useState(null);
  const [hackValue, setHackValue] = useState(null);

  useEffect(() => {
    GetAccountType();
  }, []);

  useEffect(() => {
    if (accountType) {
      setFilter({
        ...filter,
        accType: accountType[0] && accountType[0].accountType
      });
    }
  }, [accountType]);

  const [filter, setFilter] = useState({
    type: '',
    accType: accountType[0] && accountType[0].accountType,
    account: '',
    page: 1, // 当前多少页
    rows: 15, // 一页多少内容
  });

  const tableHeaderTitle = [
    {
      label: t('ORDER_NUM'),
    },
    {
      label: t('USER_ACCOUNT'),
    },
    {
      label: t('DATE'),
    },
    {
      label: t('REMAINED_AMOUNT'),
    },
    {
      label: t('NEW_BALANCE'),
    },
    {
      label: t('CHANGE_AMOUNT'),
    },
    {
      label: t('OPERATE_REMARK'),
    },
  ];

  const _content = [
    data => (
      <Label>{data.no}</Label>
    ),
    data => (
      <Label>{data.name}</Label>
    ),
    data => (
      <Label>{moment.unix(data.installment).format('YYYY-MM-DD HH:mm:ss')}</Label>
    ),
    data => (
      <Label>{data.start}</Label>
    ),
    data => (
      <Label>{data.end}</Label>
    ),
    data => (
      <Label>{data.change}</Label>
    ),
    data => (
      <Label>{data.remark}</Label>
    ),
  ];

  useEffect(() => {
    if (memberAmountHistoryData) {
      setDetailItem(memberAmountHistoryData.list || []);
      setDetailItemSum(memberAmountHistoryData.totalAmount || 0);
    }
    setIsQueryingDetail(false);
  }, [memberAmountHistoryData]);

  useEffect(() => {
    setDetailItem([]);
    setDetailItemSum(0);
    if (filter.type) {
      onQuery();
    }
  }, []);


  const onQuery = (params = {}, modifyPage = false) => {
    setIsQueryingDetail(true);
    if (!modifyPage) {
      setFilter({
        ...filter,
        ...params,
        page: 1
      });
      params.page = 1;
    }
    GetMemberAmountHistory(
      {
        userType: filter.type,
        accType: filter.accType,
        loginId: filter.account,
        rows: filter.rows,
        page: filter.page,
        startDate: moment(_date[0]).unix(),
        endDate: moment(_date[1]).unix(),
        ...params,
      },
      () => {
        setIsQueryingDetail(false);
      },
      () => {
        setIsQueryingDetail(false);
        setDetailItem([]);
        setDetailItemSum(0);
      }
    );
  };

  const handleExport = () => {
    const dataSource = (_detailItem || []).map(data => {
      const { installment } = data;
      return {
        mem: data.name,
        newTime: `\t ${moment.unix(installment).format('YYYY-MM-DD HH:mm:ss')}`,
        start: data.start,
        end: data.end,
        change: data.change,
        remark: data.remark,
      };
    });

    const columns = tableHeaderTitle.slice(1, 1 + 6).map(i => i.label);
    const name = (_detailItem[0] && _detailItem[0].name) || '';
    exportCSV({
      data: dataSource,
      columns,
      fileName: `${name}额度变更记录`
    }).save();
  };

  const disabledDate = current => {
    if (!dates) {
      return false;
    }
    const diff = moment(current).add(3, 'month').diff(current, 'days'); // 计算从当前起三个月 是多少天
    const tooLate = dates[0] && current.diff(dates[0], 'days') > diff;
    const tooEarly = dates[1] && dates[1].diff(current, 'days') > diff;
    const shouldBeforeToday = current && current > moment().endOf('day');
    return !!tooEarly || !!tooLate || !!shouldBeforeToday;
  };
  const onOpenChange = open => {
    if (open) {
      setHackValue([null, null]);
      setDates([null, null]);
    } else {
      setHackValue(null);
    }
  };

  return (
    <div className="memberAmountHistoryWrapper">
      <Col className="memberAmountHistoryFilterWrapper">
        <Col className="p-2 mb-2 filterWrapper d-flex align-items-center w-100 rounded-top">
          <Label className="px-2">{t('TYPE')}</Label>
          <Select
            value={filter.type}
            onChange={value => (setFilter({
              ...filter, type: value
            }))}
            options={[
              {
                label: t('PLEASE_SELECT'), value: '', disabled: true
              },
              {
                label: t('AGENT'), value: '2'
              },
              {
                label: t('MEMBER'), value: '1'
              },
            ]}
          />
          <Label className="px-2">{t('QUOTA_TYPE')}</Label>
          <Select
            value={filter.accType}
            onChange={value => {
              setFilter({
                ...filter, accType: value
              });
            }}
            options={
              accountType.map(item => {
                const { accName, accountType } = item;
                return {
                  label: accName,
                  value: accountType
                };
              })
            }
          />
          <Label className="px-2">{` ${t('ACCOUNT')} `}</Label>
          <Input
            className="searchInput"
            value={filter.account}
            onChange={e => {
              setFilter({
                ...filter, account: e.target.value
              });
            }}

          />
          <Label type="daterange" value={t('DATE')} className="px-2">{t('DATE')}</Label>
          <div>
            <RangePicker
              suffixIcon={undefined}
              allowClear={false}
              value={hackValue || _date}
              separator={<span>{` ${t('TO')} `}</span>}
              onChange={date => setDate(date)}
              disabledDate={disabledDate}
              onCalendarChange={val => setDates(val)}
              onOpenChange={onOpenChange}
            />
          </div>

          <Button
            className="searchButton marginLeft1"
            onClick={() => onQuery()}
          >
            {
              t('FIND')
            }
          </Button>
          <Button
            className="searchButton marginLeft1"
            onClick={() => handleExport()}
          >
            {
              t('EXPORT')
            }
          </Button>

          <Col className="actionArea">
            <div className=" notify marginLeft2">
              <Notifications />
            </div>
          </Col>

        </Col>
      </Col>

      <Row className=" mt-1 mx-0 bg-white px-0 border-1">
        <AgentDataTable
          isQuerying={_isQueryingDetail}
          tableHeaderTitle={tableHeaderTitle}
          tableData={_detailItem || []}
          tableBodyRowProperty={_content}
        />
      </Row>

      <Row className="justify-center w-full relative">
        <Pagination
          customizePage={filter.page}
          onClick={(page, rows) => {
            setFilter({
              ...filter,
              page,
              rows
            });
            onQuery(
              {
                page,
                rows
              },
              true
            );
          }}
          totalRecord={_detailItemSum || 0}
        />
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MemberAmountHistory);