import { useEffect, useState } from 'react';

import { FaAngleDoubleLeft, FaAngleDoubleRight, FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { InputNumber, Select } from 'antd';
import { useTranslation } from 'react-i18next';

import { PagesList } from '../../../constants/SelectionItems';
import './styles.scss';

const Pagination = ({ onClick, totalRecord, dropdownList = PagesList, isQuery, customizePage }) => {
  const { t } = useTranslation();
  const [_currentPage, setCurrentPage] = useState(1);
  const [_addPage, setAddPage] = useState(1);
  const [_dropdownList, setDropdownList] = useState(dropdownList);
  const [_selectedDisplayRow, setSelectedDisplayRow] = useState(dropdownList[0].value);
  const [_totalPage, setTotalPage] = useState(1);
  const [_pageArray, setPageArray] = useState([]);
  const totalDisplayPageItem = 5;

  useEffect(() => {
    if (isQuery) {
      setCurrentPage(1);
    }
  }, [isQuery]);

  useEffect(() => {
    if (customizePage && customizePage > 0) {
      setCurrentPage(Number(customizePage));
    }
  }, [customizePage]);

  useEffect(() => {
    setTotalPage(Math.floor(totalRecord / _selectedDisplayRow) + (totalRecord % _selectedDisplayRow > 0 ? 1 : 0));
    setPageArray(new Array(totalDisplayPageItem).fill(''));
  }, [totalRecord]);

  useEffect(() => {
    setTotalPage(Math.floor(totalRecord / _selectedDisplayRow) + (totalRecord % _selectedDisplayRow > 0 ? 1 : 0));
    if (JSON.stringify(dropdownList) !== JSON.stringify(_dropdownList)) {
      setDropdownList(dropdownList);
    }
  }, [_selectedDisplayRow]);

  useEffect(() => {
    if (_currentPage === 1) {
      setAddPage(1);
    } else if (_currentPage > 0 && _currentPage <= _totalPage) {
      const test = Math.floor(_currentPage / totalDisplayPageItem);
      if (_currentPage % totalDisplayPageItem > 0) {
        setAddPage((test * totalDisplayPageItem) + 1);
      } else {
        setAddPage((test * totalDisplayPageItem) - (totalDisplayPageItem - 1));
      }
    }
  }, [_currentPage]);

  const onPrevClick = () => {
    if (_currentPage === 1) return;
    const pagecount = _currentPage - 1 <= 1 ? 1 : _currentPage - 1;
    onClick(pagecount, _selectedDisplayRow);
    setCurrentPage(pagecount);
  };

  const onNextClick = () => {
    if (_currentPage >= _totalPage) return;
    const pagecount = _currentPage + 1 >= _totalPage ? _totalPage : _currentPage + 1;
    onClick(pagecount, _selectedDisplayRow);
    setCurrentPage(pagecount);
  };

  const onNextSetClick = () => {
    if (_currentPage >= (_totalPage - totalDisplayPageItem)) return;
    const pagecount = _currentPage + totalDisplayPageItem;
    onClick(pagecount, _selectedDisplayRow);
    setCurrentPage(pagecount);
  };

  const onPrevSetClick = () => {
    if (_currentPage < totalDisplayPageItem) return;
    const pagecount = _currentPage - totalDisplayPageItem;
    onClick(pagecount, _selectedDisplayRow);
    setCurrentPage(pagecount);
  };

  const handlePageNumber = value => {
    if (Number(value) <= _totalPage && Number(value) > 0) {
      onClick(Number(value), _selectedDisplayRow);
      setCurrentPage(Number(value));
    }
  };

  return (
    <div className="pagination align-items-center justify-content-center">
      <div className="py-1 px-3 justify-content-center align-items-center text-center">
        {t('TOTAL')}
        <span className="px-1">{(totalRecord) || 0}</span>
        {t('ENTRIES')}
      </div>
      <Select
        value={_selectedDisplayRow}
        onChange={value => {
          onClick(1, value);
          setSelectedDisplayRow(value);
          setCurrentPage(1);
        }}
        options={_dropdownList.map(item => ({
          label: item.label, value: item.value
        }))}
      />
      <div
        className={`box mx-1 ${_currentPage < totalDisplayPageItem ? 'inactive' : ''}`}
        onClick={onPrevSetClick}
      >
        <FaAngleDoubleLeft className="ml-2" />
      </div>
      <div
        className={`box mx-1 ${_currentPage === 1 ? 'inactive' : ''}`}
        onClick={onPrevClick}
      >
        <FaAngleLeft className="ml-2" />
      </div>
      {
          _pageArray && _pageArray.map((item, index) => (
            <span
              key={`mypagination${item}_${index + _addPage}`}
              onClick={() => {
                onClick(index + _addPage, _selectedDisplayRow);
                setCurrentPage(index + _addPage);
              }}
              className={`box mx-1 ${((index + _addPage) > _totalPage) && 'd-none'} font-bold ${(_currentPage === (index + _addPage)) ? 'paginationActive' : ''}`}
            >
              {index + _addPage}
            </span>
          ))
      }
      <div
        className={`box mx-1 ${_currentPage === _totalPage || _totalPage === 0 ? 'inactive' : ''}`}
        onClick={onNextClick}
      >
        <FaAngleRight className="ml-2" />
      </div>
      <div
        className={`box mx-1 ${_currentPage >= (_totalPage - totalDisplayPageItem) ? 'inactive' : ''}`}
        onClick={onNextSetClick}
      >
        <FaAngleDoubleRight className="ml-2" />
      </div>
      <div className="px-3 d-flex justify-content-center align-items-center">
        {t('GO_TO')}
        <InputNumber
          size="small"
          className="mx-2"
          value={_currentPage}
          onChange={handlePageNumber}
          min={1}
          max={_totalPage}
          placeholder={1}
        />
        /
        <div className="px-1">
          {_totalPage === 0 ? 1 : _totalPage}
        </div>
        {t('PAGE')}
      </div>
    </div>
  );
};

export default Pagination;