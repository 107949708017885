import { useEffect, useState } from 'react';

import { Checkbox, Input, Select } from 'antd';
import { Label } from 'reactstrap';
import { MdArrowBack } from 'react-icons/md';
import { connect } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { AgentDataTable, CommonHeader, Pagination } from '../..';
import { commonActions, systemActions } from '../../../redux/actions';
import AccessConfigure from '../AccessConfigure';
import PageSetting from '../PageSetting';

import './styles.scss';

const mapStateToProps = state => ({
  roleInfo: state.systemReducers.roleInfo,
  parentMenuList: state.commonReducers.parentMenuList,
  activeMenu: state.commonReducers.activeMenu,
  menuInfo: state.systemReducers.menuInfo,
  editRoleInfo: state.systemReducers.editRoleInfo,
  company: state.whiteListReducers.company,
  subAccountList: state.whiteListReducers.subAccountList,
});
const mapDispatchToProps = dispatch => ({
  GetRoleInfo: (obj, callback) => dispatch(systemActions.GetRoleInfo(obj, callback)),
  GetParentMenuList: () => dispatch(commonActions.GetParentMenuList()),
  GetActiveMenu: () => dispatch(commonActions.GetActiveMenu()),
  GetMenuInfo: (obj, callback) => dispatch(systemActions.GetMenuInfo(obj, callback)),
  GetEditRoleInfo: (id, callback) => dispatch(systemActions.GetEditRoleInfo(id, callback)),
  GetEditUserRightsInfo: (obj, callback) => dispatch(systemActions.GetEditUserRightsInfo(obj, callback)),
  GetSubUserRightsInfo: (obj, callback) => dispatch(systemActions.GetSubUserRightsInfo(obj, callback)),
  SaveRoleInfo: (obj, callback) => dispatch(systemActions.SaveRoleInfo(obj, callback)),
  SaveUserRightsInfo: (obj, callback) => dispatch(systemActions.SaveUserRightsInfo(obj, callback)),
  SetMenuOrder: (obj, callback) => dispatch(systemActions.SetMenuOrder(obj, callback)),
  getInfo: (obj, callback) => dispatch(systemActions.GetUserRightsInfo(obj, callback)),
});

const { Option } = Select;

const accountType = [{
  label: '全部', value: -1
}, {
  label: '总公司', value: 1
}, {
  label: '二级公司', value: 2
}, {
  label: '总代', value: 3
}];

const SystemRoleSetting = ({ SetMenuOrder, SaveUserRightsInfo, GetEditUserRightsInfo, SaveRoleInfo, GetEditRoleInfo, editRoleInfo, GetActiveMenu, activeMenu, GetMenuInfo, menuInfo, GetParentMenuList, parentMenuList, GetRoleInfo, roleInfo, company, getInfo, GetSubUserRightsInfo, subAccountList }) => {
  const { t } = useTranslation();
  const [_isQuerying, setIsQuerying] = useState(false);
  const [_pageFlag, setPageFlag] = useState(0);

  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(15);

  const [listType, setListType] = useState(1);
  const [loginId, setloginId] = useState('');
  const [agentId, setagentId] = useState('');
  const [parentId, setparentId] = useState('');
  const [menuName, setmenuName] = useState('');

  const [selectedMap, setSelectedMap] = useState(new Map());
  const [totalLottery, setTotalLottery] = useState(new Map());
  const [organizationId, setorganizationId] = useState('');
  const [levelName, setlevelName] = useState('');

  const [DropDownLevelValue, setDropDownLevelValue] = useState(-1);
  const [levelValue, setLevelValue] = useState(1);

  const [menuItem, setMenuItem] = useState();

  useEffect(() => {
    setIsQuerying(true);
    setPage(1);
    setRows(15);
    setloginId('');
    setparentId('');
    setmenuName('');
    if (listType === 1) {
      const _obj = {
        page: 1,
        rows: 15,
      };
      GetRoleInfo(_obj, () => setIsQuerying(false));
      GetParentMenuList();
      GetActiveMenu();
    }
    if (listType === 2) {
      const _obj = {
        page: 1,
        rows: 15,
        parentId,
        menuName
      };
      GetMenuInfo(_obj, () => setIsQuerying(false));
    }
    if (listType === 3) {
      const _obj = {
        page,
        rows,
        id: loginId,
        levelValue: -1,
      };
      setDropDownLevelValue(-1);
      getInfo(_obj, () => setIsQuerying(false));
    }
    if (listType === 4) {
      const _obj = {
        page: 1,
        rows: 15,
        cid: '',
        id: agentId,
        levelValue
      };
      setloginId('');
      GetSubUserRightsInfo(_obj, () => setIsQuerying(false));
    }
  }, [listType]);


  const changeOrder = (id, dir) => {
    const callback = () => {
      setPage(1);
      setRows(15);
      const _obj = {
        page: 1,
        rows: 15,
        parentId
      };
      GetMenuInfo(_obj, () => setIsQuerying(false));
    };

    SetMenuOrder({
      id, dir
    }, callback);
  };

  useEffect(() => {
    if (parentMenuList && activeMenu) {
      const mapAll = new Map();
      const mapSelected = new Map();
      const allLs = [].concat(activeMenu).filter(x => (x.parentId !== '-1' && x.parentId !== '9999'));
      if (editRoleInfo?.menuList) {
        const ls = [].concat(editRoleInfo?.menuList);
        for (let i = 0; i < ls.length; i++) {
          const temp = allLs.find(x => (x.id === ls[i].id));
          if (temp) {
            if (mapSelected.has(temp.parentId)) {
              const tempArr = mapSelected.get(temp.parentId);
              mapSelected.set(temp.parentId, tempArr.concat(temp));
            } else {
              mapSelected.set(temp.parentId, [].concat(temp));
            }
          }
        }
      }

      allLs.forEach(item => {
        if (mapAll.has(item.parentId)) {
          const temp = mapAll.get(item.parentId);
          mapAll.set(item.parentId, temp.concat(item));
        } else {
          mapAll.set(item.parentId, [].concat(item));
        }
      });
      setTotalLottery(mapAll);
      setSelectedMap(mapSelected);
    }
  }, [parentMenuList, activeMenu, editRoleInfo]);

  const saveAndEdit = () => {
    let menuIds = [];
    Array.from(selectedMap.values()).forEach(arr => {
      const _arr = arr.map(x => x.id);
      menuIds = menuIds.concat(_arr);
    });
    if (listType === 1) {
      const callback = () => {
        setIsQuerying(true);
        setPageFlag(0);
        const _obj = {
          page: 1,
          rows: 15,
        };
        GetRoleInfo(_obj, () => setIsQuerying(false));
      };
      const obj = {
        menuIds, id: editRoleInfo.role.id
      };
      SaveRoleInfo(obj, callback);
    }
    if (listType === 4 || listType === 3) {
      const callback = () => {
        setIsQuerying(true);
        setPageFlag(0);
        setloginId('');
        if (listType === 4) {
          const _obj = {
            page: 1,
            rows: 15,
            cid: '',
            id: agentId,
            levelValue
          };
          GetSubUserRightsInfo(_obj, () => setIsQuerying(false));
        }
        if (listType === 3) {
          const _obj = {
            page,
            rows,
            id: loginId,
            levelValue: -1,
          };
          setDropDownLevelValue(-1);
          getInfo(_obj, () => setIsQuerying(false));
        }
      };
      const obj = {
        menuIds,
        id: organizationId
      };
      SaveUserRightsInfo(obj, callback);
    }
  };

  const onSearchClick = () => {
    setIsQuerying(true);
    if (listType === 2) {
      const _obj = {
        page: 1,
        rows: 15,
        parentId,
        menuName
      };
      GetMenuInfo(_obj, () => setIsQuerying(false));
    }
    if (listType === 3) {
      const _obj = {
        page: 1,
        rows: 15,
        id: loginId,
        levelValue: DropDownLevelValue
      };
      getInfo(_obj, () => setIsQuerying(false));
    }
    if (listType === 4) {
      const _obj = {
        page: 1,
        rows: 15,
        cid: loginId,
        id: agentId,
        levelValue
      };
      GetSubUserRightsInfo(_obj, () => setIsQuerying(false));
    }
    setPage(1);
    setRows(15);
  };

  const onPaginationClick = (pageNum, rows) => {
    setIsQuerying(true);
    setPage(pageNum);
    setRows(rows);
    if (listType === 1) {
      const _obj = {
        page: pageNum,
        rows,
      };
      GetRoleInfo(_obj, () => setIsQuerying(false));
      GetParentMenuList();
    }
    if (listType === 2) {
      const _obj = {
        page: pageNum,
        rows,
        parentId
      };
      GetMenuInfo(_obj, () => setIsQuerying(false));
    }
    if (listType === 3) {
      const _obj = {
        page: pageNum,
        rows,
        id: loginId,
        levelValue: DropDownLevelValue
      };
      getInfo(_obj, () => setIsQuerying(false));
    }
    if (listType === 4) {
      const _obj = {
        page: pageNum,
        rows,
        cid: loginId,
        id: agentId,
        levelValue
      };
      GetSubUserRightsInfo(_obj, () => setIsQuerying(false));
    }
  };

  // listType 1:角色管理 2：菜单管理 3：用户权限 4：子账号列表 5：页面配置 6:菜单管理- 权限设置
  const backBtn = () => {
    if (listType === 1) {
      setPageFlag(0);
    }
    if (listType === 6) {
      setListType(2);
    }
    if (listType === 4 && _pageFlag === 0) {
      setListType(3);
    }
    if (listType === 4 && _pageFlag === 1) {
      setloginId('');
      setPageFlag(0);
    }
    if (listType === 3 && _pageFlag === 1) {
      setPageFlag(0);
    }
  };

  const toggleAllSelection = code => {
    const temp = new Map(JSON.parse(
      JSON.stringify(Array.from(selectedMap))
    ));
    if (temp.has(code)) {
      temp.delete(code);
    } else {
      const tempItem = totalLottery.get(code);
      temp.set(code, [].concat(tempItem));
    }
    setSelectedMap(temp);
  };

  const toggleSelection = item => {
    const temp = new Map(JSON.parse(
      JSON.stringify(Array.from(selectedMap))
    ));
    if (temp.has(item.parentId)) {
      if (temp.get(item.parentId).find(x => x.id === item.id)) {
        temp.set(item.parentId, temp.get(item.parentId).filter(x => x.id !== item.id));
      } else {
        temp.set(item.parentId, temp.get(item.parentId).concat(item));
      }
    } else {
      temp.set(item.parentId, [].concat(item));
    }
    setSelectedMap(temp);
  };

  return (
    <div className="SystemRoleSettingWrapper">
      <CommonHeader
        headerName={(
          <div className="d-flex align-items-center">
            {(_pageFlag !== 0 || (_pageFlag === 0 && listType === 4) || (_pageFlag === 0 && listType === 6))
            && (
            <div className="d-flex align-items-center me-2 backIcon" onClick={() => backBtn()}>
              <MdArrowBack className="h5 mb-0 text-white" />
              返回
            </div>
            )}
            {`${_pageFlag === 0 ? listType === 4 ? `${agentId} / 子账号` : `${t('SYSTEM_PERMISSION_SETTING')}` : (listType === 1 ? '角色管理 / 修改' : listType === 4 ? `${agentId} / ${loginId} / 权限管理` : '')}`}
          </div>
)}
      />
      {_pageFlag === 0 && (
      <>
        <div className="whiteListMenu">
          <div className={`menu_item ${(listType === 1) && 'selected'}`} onClick={() => setListType(1)}>角色管理</div>
          <div className={`menu_item ${(listType === 2 || listType === 6) && 'selected'}`} onClick={() => setListType(2)}>菜单管理</div>
          <div className={`menu_item ${(listType === 3 || listType === 4) && 'selected'}`} onClick={() => setListType(3)}>用户权限</div>
          <div className={`menu_item ${(listType === 5) && 'selected'}`} onClick={() => setListType(5)}>页面配置</div>
        </div>
        <div className="tableWrapper">
          {
            listType === 1 && (
            <div className="mx-0 bg-white px-0 border-1">
              <AgentDataTable
                isQuerying={_isQuerying}
                tableHeaderTitle={
                [{
                  label: '角色编号',
                }, {
                  label: '角色名称',
                }, {
                  label: t('OPERATION'),
                }]
              }
                tableHeaderStyle={
                []
              }
                tableData={roleInfo ? roleInfo.roleList : []}
                tableBodyRowProperty={
                [
                  data => (<Label className="d-flex justify-content-center">{data.id}</Label>),
                  data => (<Label className="d-flex justify-content-center">{data.roleName}</Label>),
                  data => (
                    <Label className="d-flex justify-content-center align-items-center">
                      <div
                        className="operationBtn mx-2"
                        onClick={() => {
                          setlevelName(data.roleName);
                          GetEditRoleInfo(data.id, () => setPageFlag(1));
                        }}
                      >
                        修改
                      </div>
                    </Label>
                  )
                ]
              }
              />
            </div>
            )
          }
          {
            listType === 2 && (
            <>
              <div className="menuManagementHeader mb-3">
                <div>模块</div>
                <Select className="mx-2 moduleSelect" value={parentId} placeholder="请选择" onChange={code => setparentId(code)}>
                  {
                    parentMenuList && [{
                      id: '', menuName: '全部'
                    }, {
                      id: '-1', menuName: '父级'
                    }].concat(parentMenuList).map(el => <Option key={el.id} value={el.id}>{el.menuName}</Option>)
                }
                </Select>
                <div>菜单名称</div>
                <Input className="mx-2 menuNameInput" value={menuName} onChange={({ target: { value: text } }) => setmenuName(text.trim())} />
                <div className="operationBtn mx-2 add" onClick={() => onSearchClick()}>查询</div>
              </div>
              <div className="mx-0 bg-white px-0 border-1">
                <AgentDataTable
                  isQuerying={_isQuerying}
                  tableHeaderTitle={
                [{
                  label: '菜单编号',
                }, {
                  label: '菜单名称',
                }, {
                  label: 'Url路径',
                }, {
                  label: '父菜单',
                }, {
                  label: '状态',
                }, {
                  label: '排序',
                }, {
                  label: t('OPERATION'),
                }]
              }
                  tableHeaderStyle={
                []
              }
                  tableData={menuInfo ? menuInfo.menuList : []}
                  tableBodyRowProperty={
                [
                  data => (<Label className="d-flex justify-content-center">{data.id}</Label>),
                  data => (<Label className="d-flex justify-content-center">{data.menuName}</Label>),
                  data => (<Label className="d-flex justify-content-center">{data.urlPath}</Label>),
                  data => (<Label className="d-flex justify-content-center">{data.parentId}</Label>),
                  data => (<Label className={`d-flex justify-content-center ${data.statusId === 1 ? 'text-success' : 'text-danger'}`}>{data.statusId === 1 ? '启用' : '停用'}</Label>),
                  data => (<Label className="d-flex justify-content-center">{data.orderBy}</Label>),
                  data => (
                    <Label className="d-flex justify-content-center align-items-center">
                      <div
                        className="operationBtn mx-2"
                        onClick={() => {
                          changeOrder(data.id, -1);
                        }}
                      >
                        上升
                      </div>
                      <div
                        className="operationBtn mx-2"
                        onClick={() => {
                          changeOrder(data.id, 1);
                        }}
                      >
                        下降
                      </div>
                      <div
                        className="operationBtn mx-2"
                        onClick={() => {
                          setMenuItem(data);
                          setListType(6);
                        }}
                      >
                        权限设置
                      </div>
                    </Label>
                  )
                ]
              }
                />
              </div>
            </>
            )
          }
          {
            (listType === 3 || listType === 4) && (
            <div className="menuManagementHeader mb-3">
              <div>{listType === 3 ? '账号' : '子账号'}</div>
              {
                listType === 3
                && (
                <Select className="userRightSelect ms-2" value={DropDownLevelValue} onChange={value => setDropDownLevelValue(value)}>
                  {
                  accountType.map(item => <Option value={item.value}>{item.label}</Option>)
                  }
                </Select>
                )
              }
              <Input className="mx-2 menuNameInput" value={loginId} onChange={({ target: { value: text } }) => setloginId(text.trim())} />
              <div className="operationBtn add" onClick={() => onSearchClick()}>查询</div>
            </div>
            )
          }
          {
            listType === 3 && (
            <div className="mx-0 bg-white px-0 border-1">
              <AgentDataTable
                isQuerying={_isQuerying}
                tableHeaderTitle={
                [{
                  label: '公司名称',
                }, {
                  label: '账号',
                }, {
                  label: '类型',
                }, {
                  label: '新增日期',
                }, {
                  label: '操作',
                }]
              }
                tableHeaderStyle={
                []
              }
                tableData={company ? company.userList : []}
                tableBodyRowProperty={
                [
                  data => (<Label className="d-flex justify-content-center">{data.name}</Label>),
                  data => (<Label className="d-flex justify-content-center">{data.defaultAdmin}</Label>),
                  data => (<Label className="d-flex justify-content-center">{accountType.find(x => x.value === data.levelValue)?.label ?? ''}</Label>),
                  data => (<Label className="d-flex justify-content-center">{moment.unix(data.createDate).format('YYYY-MM-DD HH:mm:ss')}</Label>),
                  data => (
                    <Label className="d-flex justify-content-center align-items-center">
                      <div
                        className="operationBtn mx-2"
                        onClick={() => {
                          setorganizationId(data.companyId);
                          setlevelName(data.levelName);
                          GetEditUserRightsInfo({
                            orgId: data.companyId,
                            loginId: data.defaultAdmin
                          }, () => { setloginId(data.defaultAdmin); setPageFlag(1); });
                        }}
                      >
                        菜单
                      </div>
                      <div className="operationBtn mx-2" onClick={() => { setLevelValue(data.levelValue); setListType(4); setagentId(data.defaultAdmin); }}>子账号</div>
                    </Label>
                  )
                ]
              }
              />
            </div>
            )
          }
          {
            listType === 4 && (
            <div className="mx-0 bg-white px-0 border-1">
              <AgentDataTable
                isQuerying={_isQuerying}
                tableHeaderTitle={
                [{
                  label: '上级账号',
                }, {
                  label: '名称',
                }, {
                  label: '账号',
                }, {
                  label: '操作',
                }]
              }
                tableHeaderStyle={
                []
              }
                tableData={subAccountList ? subAccountList.userList : []}
                tableBodyRowProperty={
                [
                  data => (<Label className="d-flex justify-content-center">{data.uplineId}</Label>),
                  data => (<Label className="d-flex justify-content-center">{data.name}</Label>),
                  data => (<Label className="d-flex justify-content-center">{data.loginId}</Label>),
                  data => (
                    <Label className="d-flex justify-content-center align-items-center">
                      <div
                        className="operationBtn mx-2"
                        onClick={() => {
                          setorganizationId(data.organizationId);
                          GetEditUserRightsInfo({
                            orgId: data.organizationId,
                            loginId: data.loginId
                          }, () => { setloginId(data.loginId); setPageFlag(1); });
                        }}
                      >
                        菜单
                      </div>
                    </Label>
                  )
                ]
              }
              />
            </div>
            )
          }
          {
            listType === 5 && <PageSetting />
          }
          {
            listType === 6 && <AccessConfigure data={menuItem} />
          }
        </div>
        {listType !== 5 && listType !== 6
        && (
        <div className="d-flex align-items-center justify-content-center w-full relative">
          <Pagination totalRecord={(roleInfo || menuInfo || subAccountList || company) ? (listType === 1 ? roleInfo?.totalRecords || 0 : listType === 2 ? menuInfo?.totalRecords || 0 : listType === 3 ? company?.totalRecords || 0 : subAccountList?.totalRecords || 0) : 0} onClick={onPaginationClick} isQuery={false} />
        </div>
        )}
      </>
      )}
      {_pageFlag !== 0 && (
        <>
          <div className="sectionWrapper">
            <div className="tableTitleStyle">{listType === 1 || listType === 3 ? `${levelName} ${loginId}` : `子账号 ${loginId}`}</div>
            <div className="tableSection labelCellBackground">
              <div className="rowStyle">
                <div>主菜单</div>
                <div className="justify-content-center addLabelCellBackground">子菜单项</div>
              </div>
              {
                Array.from(totalLottery.values()).map(item => (
                  <div className="rowStyle">
                    <div>
                      <Checkbox onChange={() => toggleAllSelection(item[0].parentId)} checked={(selectedMap.size > 0 && selectedMap.has(item[0].parentId)) ? (selectedMap.get(item[0].parentId).length === totalLottery.get(item[0].parentId).length) : false}>{[].concat(parentMenuList).find(x => x.id === item[0].parentId)?.menuName}</Checkbox>
                    </div>
                    <div>
                      {
                        item.map(ele => <Checkbox checked={selectedMap.size > 0 ? selectedMap.get(ele.parentId)?.find(x => x.id === ele.id) : false} onChange={() => toggleSelection(ele)} className="lotterySelection my-1">{ele.menuName}</Checkbox>)
                      }
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
          <div className="thirdPartyGamesFooter">
            <div className="operationBtn editAndSave me-3" onClick={() => saveAndEdit()}>保存</div>
            <div className="operationBtn cancel me-3" onClick={() => backBtn()}>取消</div>
          </div>
        </>
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SystemRoleSetting);