import { useEffect, useState } from 'react';

import { Input, Radio } from 'antd';
import { Label } from 'reactstrap';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { appActions, thirdPartyGamesActions } from '../../../../redux/actions';
import AgentDataTable from '../../../AgentDataTable';
import './styles.scss';

const mapStateToProps = state => ({
  thirdPartyRebateSetting: state.thirdPartyGamesReducers.thirdPartyRebateSetting,
});

const mapDispatchToProps = dispatch => ({
  getRebateSetting: (obj, callback) => dispatch(thirdPartyGamesActions.GetRebateSetting(obj, callback)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  saveRebateSetting: (obj, callback) => dispatch(thirdPartyGamesActions.SaveRebateSetting(obj, callback)),
  syncRebateSetting: (obj, callback) => dispatch(thirdPartyGamesActions.SyncRebateSetting(obj, callback)),
});

const ThirdPartyRebate = ({ getRebateSetting, data, thirdPartyRebateSetting, updateModalAlert, saveRebateSetting, syncRebateSetting }) => {
  const { t } = useTranslation();
  const [_platetInput, setPlateInput] = useState('');
  const [_selectedSearchOption, setSearchOption] = useState(1);
  const [_tempData, setTempData] = useState([]);
  let bufferData = [];

  const getallRebateData = () => {
    const payload = {
      userType: data?.orgId ? 2 : 1,
      companyId: data?.upOrgId,
      id: data.memberId || data?.orgId
    };
    getRebateSetting(payload);
  };

  useEffect(() => {
    getallRebateData();
  }, []);

  useEffect(() => {
    if (thirdPartyRebateSetting && thirdPartyRebateSetting?.rebateList) {
      const list = [...new Map(thirdPartyRebateSetting?.rebateList.map(item => [JSON.stringify(item), item])).values()];
      const parent = [...new Map(thirdPartyRebateSetting?.parentRebateList.map(item => [JSON.stringify(item), item])).values()];
      [...new Map(thirdPartyRebateSetting?.rebateList.map(item => [JSON.stringify(item), item])).values()].forEach((curr, myInd) => {
        if (parent) {
          const index = parent?.findIndex(x => x.gameCode === curr.gameCode);
          list[myInd].parentSetting = parent[index];
        }
        list[myInd].gameName = thirdPartyRebateSetting?.thirdPartyGames?.filter(x => x?.gameCode === curr?.gameCode)[0]?.gameName;
      });

      const obj = [...new Map(list.map(item => [JSON.stringify(item), item])).values()];
      bufferData = obj;
      setTempData([...obj]);
    }
  }, [thirdPartyRebateSetting]);


  useEffect(() => {
    if (bufferData) {
      bufferData = _tempData;
    }
  }, [_tempData]);

  const onValueChange = (event, data, field, maxValue) => {
    let newItems = [];
    newItems = [...bufferData];
    const valToChange = Number(event.target.value) > maxValue ? maxValue : Number(event.target.value);
    const index = Number(bufferData?.findIndex(item => item.id === data.id));
    if (index !== -1) {
      newItems[index][field] = valToChange;
      newItems = addHighLightField(newItems, field, index);
      newItems[index].isChange = true;
      setTempData(newItems);
    }
  };

  const saveButton = () => {
    const res = _tempData.filter(item => item?.isChange === true);
    if (res.length > 0) {
      const obj = {
        id: data.memberId || data?.orgId,
        settingList: res,
        userType: data?.orgId ? 2 : 1,
        saveType: _selectedSearchOption,
      };
      updateModalAlert({
        type: 'loading',
        visible: true
      });

      saveRebateSetting(obj, getallRebateData);
    } else {
      updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: '没有变更'
        }
      });
    }
  };

  const syncButton = thisId => {
    const res = _tempData.filter(item => item?.id === thisId);
    if (res.length > 0) {
      updateModalAlert({
        type: 'loading',
        visible: true
      });
      const obj = {
        id: data.memberId || data?.orgId,
        settingList: res,
        userType: data?.orgId ? 2 : 1,
        saveType: _selectedSearchOption,
      };
      syncRebateSetting(obj, getallRebateData);
    } else {
      updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: '没有变更'
        }
      });
    }
  };

  const resetDefault = () => {
    const payload = {
      userType: data?.orgId ? 2 : 1,
      companyId: data?.upOrgId,
      id: data.memberId || data?.orgId
    };
    getRebateSetting(payload);
  };

  const setAllPlate = () => {
    const newProjects = [..._tempData];
    const field = 'aReturn';
    _tempData.forEach((curr, myInd) => {
      if (curr.parentSetting) {
        const after = Number(curr?.parentSetting[field]) - Math.abs(Number(_platetInput));
        newProjects[myInd][field] = after > 0 ? (Number.isInteger(after) ? after : after.toFixed(1)) : 0;
        newProjects[myInd].isChange = true;
        addHighLightField(newProjects, field, myInd);
      }
    });
    setTempData(newProjects);
  };

  const header = [{
    label: '项目名称'
  },
  {
    label: `A${t('PLATE')}%`
  }];

  const rdSearchOptions = [{
    label: '不增加',
    value: 1,
  },
  {
    label: '只代理',
    value: 2,
  },
  {
    label: '代理和会员',
    value: 3,
  }];

  const addHighLightField = (item, field, index) => {
    switch (field) {
      case 'aReturn':
        item[index].isChangeA = true;
        break;
      case 'bReturn':
        item[index].isChangeB = true;
        break;
      case 'cReturn':
        item[index].isChangeC = true;
        break;
      case 'dReturn':
        item[index].isChangeD = true;
        break;
      default: break;
    }
    return item;
  };

  const content = [
    data => (<Label className="d-flex justify-content-center">{data.gameName}</Label>),
    data => (
      <div className="d-flex flex-column align-items-center justify-content-center">
        <Input className={`inputStyle ${data?.isChangeA && 'isChange'}`} name={data?.code} onChange={e => onValueChange(e, data, 'aReturn', data?.parentSetting && data?.parentSetting.aReturn)} type="Number" value={data?.aReturn} />
        {data?.parentSetting ? (
          <Label>{`(${data?.parentSetting.aReturn})`}</Label>
        ) : <Label>(0)</Label>}
        {data?.levelValue !== 1 && data?.parentSetting && (
          <Label className="lotteryName negativeValue">
            {`您当前
            ${data?.gameName}
            退水为
            ${data?.parentSetting.aReturn}
            %，您下级
            ${data?.gameName}
            退水为
            ${data?.aReturn}
            %，下级投注10000元，您预期可获得收益10000 * (
            ${data?.parentSetting.aReturn}% - ${data?.aReturn}%) = ${(10000 * ((Number(data?.parentSetting.aReturn) - Number(data?.aReturn)) / 100)).toFixed(2)}元`}
          </Label>
        )}
      </div>
    )
  ];

  return (
    <div className="ThirdPartyRebateWrapper p-0 m-0">
      <div className="d-flex p-2 m-0 operationWrapper align-items-center">
        <Label className="p-1">赚取退水：</Label>
        <Input className="mx-2 inputStyle" value={_platetInput} onChange={({ target: { value: text } }) => setPlateInput(text)} />
        <div className="button generate mx-1" onClick={() => setAllPlate()}>{t('CONFIRM')}</div>
        <div className="button handle mx-1" onClick={() => saveButton()}>{t('SAVE')}</div>
        <div className="button find mx-1" onClick={() => resetDefault()}>{t('CANCEL')}</div>
        {
           data?.orgId
           && (
           <>
             <Label className="justify-content-center mx-1">保存时下线是否等量增加：</Label>
             <Radio.Group onChange={({ target: { value } }) => setSearchOption(value)} value={_selectedSearchOption}>
               {
               rdSearchOptions.map(item => <Radio value={item.value}>{item.label}</Radio>)
             }
             </Radio.Group>
           </>
           )
        }
      </div>
      {
        _tempData && _tempData.map(item => (
          <div key={`games_${item?.gameCode}`}>
            <div className="m-0 p-0 my-2 d-flex align-items-center">
              <div className="px-2 d-flex lotteryMenu">
                <Radio
                  key={`games_${item?.gameCode}`}
                  value={item?.gameCode}
                  type="radio"
                  name="rdGames"
                  checked
                >
                  {item?.gameName}
                </Radio>
              </div>
              {
                data?.orgId
                && <div className="button handle" onClick={() => syncButton(item?.id)}>等量同步下线</div>
              }
            </div>
            <div className="bigWrapper d-flex m-0 my-2 p-2">
              <AgentDataTable
                tableHeaderTitle={header}
                tableData={_tempData.filter(y => y?.id === item.id) || []}
                tableBodyRowProperty={content}
                tableBodyRowStyle={
                    ['backgroundGrey']
                  }
                tableHeaderStyle={
                    ['col-3', 'col-9']
                  }
              />
            </div>
          </div>
        ))
      }
      <div className="d-flex justify-content-center mb-5 my-2 py-2 border-top">
        <div className="button handle mx-1" onClick={() => saveButton()}>{t('SAVE')}</div>
        <div className="button find mx-1" onClick={() => resetDefault()}>{t('CANCEL')}</div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ThirdPartyRebate);